import * as types from '../constants/vasActionTypes';
import { appSettings } from '../../app/appSettings'

const initialState = {
    isGetCustomsCityAPILoading: false,
    GetCustomsCityAPISuccessful: false,

    isCustomsCityMessagesLoading: false,
    CustomsCityMessagesData: [],

    isDashboardSummaryLoading: false,
    DashboardSummaryData: [],

    isMawbLoading: false,
    MawbData: [],

    MawbSaveSuccessful: false,
    MawbEvolveDataSaveSuccessful: false,
    MawbAddSuccessful: false,

    isMawbRecoveryLoading: false,
    MawbRecoveryData: [],

    MawbRecoverySaveSuccessful: false,

    MawbPickupSaveSuccessful: false,

    isMawbStatusLoading: false,
    MawbStatusData: [],

    MawbStatusPostSuccessful: false,

    isMawbCarrierLoading: false,
    MawbCarrierData: [],

    isHawbLoading: false,
    HawbData: [],

    PostHawbSuccessful: false,

    isHawbRecoveryLoading: false,
    HawbRecoveryData: [],

    HawbRecoverySaveSuccessful: false,

    isHawbConsigneeLoading: false,
    HawbConsigneeData: [],

    isHawbDestinationLoading: false,
    HawbDestinationData: [],

    isLoadLoading: false,
    LoadData: [],

    isLoadHawbLoading: false,
    LoadHawbData: [],

    PostLoadSuccessful: false,

    PostLoadRemoveSuccessful: false,

    isLoadHawbHistoryLoading: false,
    LoadHawbHistoryData: [],


    isOutboundLoading: false,
    OutboundData: [],


    PostUserColorSuccessful: false,

    HawbRecoveredShortPostSuccessful: false,

    isXdockMawbFileUploadLoading: false,
    XdockMawbFileUploadData: [],

    XdockMawbFileUploadPostSuccessful: false




};

function checkStatusCode(payload) {
    var errorType = 'None'
    try {
        errorType = payload['errorType']
    }
    catch (e) {
    }
    if (typeof (errorType) !== 'undefined') {
        return false
    }
    else {
        var res = payload['statusCode']
        try {
            if (res === "200" || res == 200 || res == 600 || res === "600") { return true }
            else { return false }
        }
        catch (e) {
            return false
        }
    }
}

function actionOnError(proc) {
    console.log('DB connection error')
    // sessionStorage.clear();
    // window.location.reload();
    // window.alert('Session expired, please log in again.')

    return initialState


}

function checkErrorMessage(msg) {

    console.log('msg', msg)
    // if(res === "200"){ return true }
    // else { return false }
}


export const VasReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.RESET_REDUX_STATE:
            state = initialState;
            return state


        case types.ON_ERROR:
            var errorstate = actionOnError(state)
            return errorstate;




        /* ################################### EXTERNAL API ACTIONS ################################### */
        case types.CUSTOMS_CITY_API_GET:
            return Object.assign({}, state, {
                isGetCustomsCityAPILoading: true,
                GetCustomsCityAPISuccessful: false
            });
        case types.CUSTOMS_CITY_API_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                // actionOnError()
                return Object.assign({}, state, {
                    isGetCustomsCityAPILoading: false,
                    GetCustomsCityAPISuccessful: false
                });
            }
            else {
                return Object.assign({}, state, {
                    isGetCustomsCityAPILoading: false,
                    GetCustomsCityAPISuccessful: true
                });
            }












        /* ################################### VAS ACTIONS ################################### */
        case types.CUSTOMS_CITY_MESSAGES_GET:
            return Object.assign({}, state, {
                isCustomsCityMessagesLoading: true
            });
        case types.CUSTOMS_CITY_MESSAGES_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isCustomsCityMessagesLoading: false,
                CustomsCityMessagesData: newData.data
            });



        case types.DASHBOARD_SUMMARY_GET:
            return Object.assign({}, state, {
                isDashboardSummaryLoading: true
            });
        case types.DASHBOARD_SUMMARY_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isDashboardSummaryLoading: false,
                DashboardSummaryData: newData.data
            });



        case types.MAWB_GET:
            return Object.assign({}, state, {
                isMawbLoading: true
            });
        case types.MAWB_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            try {
                if (msgCode === 400) {
                    return Object.assign({}, state, {
                        isMawbLoading: false,
                        MawbData: []
                    });

                }
                else {
                    return Object.assign({}, state, {
                        isMawbLoading: false,
                        MawbData: newData.data
                    });
                }
            }
            catch (e) {
                return Object.assign({}, state, {
                    isMawbLoading: false,
                    MawbData: newData.data
                });
            }




        case types.POST_MAWB_GET:
            return Object.assign({}, state, {
                MawbSaveSuccessful: false
            });
        case types.POST_MAWB_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                window.alert('Save Successful')
                return Object.assign({}, state, {
                    MawbSaveSuccessful: true
                    // MawbData: newData.data
                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    MawbSaveSuccessful: false
                    // MawbData: newData.data
                });
            }

        case types.POST_MAWB_EVOLVE_DATA_GET:
            return Object.assign({}, state, {
                MawbEvolveDataSaveSuccessful: false
            });
        case types.POST_MAWB_EVOLVE_DATA_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    MawbEvolveDataSaveSuccessful: true
                    // MawbData: newData.data
                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    MawbEvolveDataSaveSuccessful: false
                    // MawbData: newData.data
                });
            }

        case types.POST_MAWB_ADD_GET:
            return Object.assign({}, state, {
                MawbAddSuccessful: false
            });
        case types.POST_MAWB_ADD_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Add Successful')
                return Object.assign({}, state, {
                    MawbAddSuccessful: true
                    // MawbData: newData.data
                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    MawbAddSuccessful: false
                    // MawbData: newData.data
                });
            }


        case types.MAWB_RECOVERY_GET:
            return Object.assign({}, state, {
                isMawbRecoveryLoading: true
            });
        case types.MAWB_RECOVERY_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isMawbRecoveryLoading: false,
                MawbRecoveryData: newData.data
            });

        case types.POST_MAWB_RECOVERY_GET:
            return Object.assign({}, state, {
                MawbRecoverySaveSuccessful: false
            });
        case types.POST_MAWB_RECOVERY_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    MawbRecoverySaveSuccessful: true
                    // MawbData: newData.data
                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    MawbRecoverySaveSuccessful: false
                    // MawbData: newData.data
                });
            }

        case types.POST_MAWB_PICKUP_GET:
            return Object.assign({}, state, {
                MawbPickupSaveSuccessful: false
            });
        case types.POST_MAWB_PICKUP_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    MawbPickupSaveSuccessful: true
                    // MawbData: newData.data
                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    MawbPickupSaveSuccessful: false
                    // MawbData: newData.data
                });
            }

        case types.MAWB_STATUS_GET:
            return Object.assign({}, state, {
                isMawbStatusLoading: true
            });
        case types.MAWB_STATUS_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isMawbStatusLoading: false,
                MawbStatusData: newData.data
            });

        case types.POST_MAWB_STATUS_GET:
            return Object.assign({}, state, {
                MawbStatusPostSuccessful: false
            });
        case types.POST_MAWB_STATUS_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Add Successful')
                return Object.assign({}, state, {
                    MawbStatusPostSuccessful: true
                    // MawbData: newData.data
                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    MawbStatusPostSuccessful: false
                    // MawbData: newData.data
                });
            }

        case types.MAWB_CARRIER_GET:
            return Object.assign({}, state, {
                isMawbCarrierLoading: true
            });
        case types.MAWB_CARRIER_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isMawbCarrierLoading: false,
                MawbCarrierData: newData.data
            });


        case types.HAWB_GET:
            return Object.assign({}, state, {
                isHawbLoading: true
            });
        case types.HAWB_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isHawbLoading: false,
                HawbData: newData.data
            });


        case types.POST_HAWB_GET:
            return Object.assign({}, state, {
                PostHawbSuccessful: false
            });
        case types.POST_HAWB_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    PostHawbSuccessful: true
                });
            }
            else if (msgCode === 600) {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('House Ref already exists in system.')
                }
                return Object.assign({}, state, {
                    PostHawbSuccessful: false
                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    PostHawbSuccessful: false
                });
            }

        case types.HAWB_RECOVERY_GET:
            return Object.assign({}, state, {
                isHawbRecoveryLoading: true
            });
        case types.HAWB_RECOVERY_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isHawbRecoveryLoading: false,
                HawbRecoveryData: newData.data
            });


        case types.POST_HAWB_RECOVERY_GET:
            return Object.assign({}, state, {
                HawbRecoverySaveSuccessful: false
            });
        case types.POST_HAWB_RECOVERY_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    HawbRecoverySaveSuccessful: true
                    // MawbData: newData.data
                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    HawbRecoverySaveSuccessful: false
                    // MawbData: newData.data
                });
            }


        case types.XDOCK_POST_HAWB_RECOVERED_SHORT_GET:
            return Object.assign({}, state, {
                HawbRecoveredShortPostSuccessful: false
            });
        case types.XDOCK_POST_HAWB_RECOVERED_SHORT_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    HawbRecoveredShortPostSuccessful: true
                    // MawbData: newData.data
                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    HawbRecoveredShortPostSuccessful: false
                    // MawbData: newData.data
                });
            }

        case types.HAWB_CONSIGNEE_GET:
            return Object.assign({}, state, {
                isHawbConsigneeLoading: true
            });
        case types.HAWB_CONSIGNEE_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isHawbConsigneeLoading: false,
                HawbConsigneeData: newData.data
            });

        case types.HAWB_DESTINATION_GET:
            return Object.assign({}, state, {
                isHawbDestinationLoading: true
            });
        case types.HAWB_DESTINATION_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isHawbDestinationLoading: false,
                HawbDestinationData: newData.data
            });




        case types.LOAD_GET:
            return Object.assign({}, state, {
                isLoadLoading: true
            });
        case types.LOAD_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }

            if (msgCode === 400) {
                return Object.assign({}, state, {
                    isLoadLoading: false,
                    LoadData: []
                });
            }
            else {
                return Object.assign({}, state, {
                    isLoadLoading: false,
                    LoadData: newData.data
                });
            }


        case types.LOAD_HAWB_GET:
            return Object.assign({}, state, {
                isLoadHawbLoading: true
            });
        case types.LOAD_HAWB_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isLoadHawbLoading: false,
                LoadHawbData: newData.data
            });

        case types.POST_LOAD_GET:
            return Object.assign({}, state, {
                PostLoadSuccessful: false
            });
        case types.POST_LOAD_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    PostLoadSuccessful: true
                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    PostLoadSuccessful: false
                });
            }

        case types.POST_LOAD_REMOVE_GET:
            return Object.assign({}, state, {
                PostLoadRemoveSuccessful: false
            });
        case types.POST_LOAD_REMOVE_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    PostLoadRemoveSuccessful: true
                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    PostLoadRemoveSuccessful: false
                });
            }

        case types.LOAD_HAWB_HISTORY_GET:
            return Object.assign({}, state, {
                isLoadHawbHistoryLoading: true
            });
        case types.LOAD_HAWB_HISTORY_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isLoadHawbHistoryLoading: false,
                LoadHawbHistoryData: newData.data
            });



        case types.OUTBOUND_GET:
            return Object.assign({}, state, {
                isOutboundLoading: true
            });
        case types.OUTBOUND_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isOutboundLoading: false,
                OutboundData: newData.data
            });


        case types.POST_USER_COLOR_GET:
            return Object.assign({}, state, {
                PostUserColorSuccessful: false
            });
        case types.POST_USER_COLOR_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    PostUserColorSuccessful: true
                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    PostUserColorSuccessful: false
                });
            }





        case types.XDOCK_MAWB_FILEUPLOAD_GET:
            return Object.assign({}, state, {
                isXdockMawbFileUploadLoading: true
            });
        case types.XDOCK_MAWB_FILEUPLOAD_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                actionOnError()
                return state;
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isXdockMawbFileUploadLoading: false,
                XdockMawbFileUploadData: newData.data
            });


        case types.XDOCK_POST_MAWB_FILEUPLOAD_GET:
            return Object.assign({}, state, {
                XdockMawbFileUploadPostSuccessful: false
            });
        case types.XDOCK_POST_MAWB_FILEUPLOAD_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                actionOnError()
                return state;
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    XdockMawbFileUploadPostSuccessful: true

                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    XdockMawbFileUploadPostSuccessful: false
                });
            }












        default:
            return state;
    }
};





export default VasReducer;
