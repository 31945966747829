import React from 'react';
import PropTypes from 'prop-types';
import {
    Drawer,
    Paragraph,
    Button,
    ButtonToolbar,
    Checkbox,
    Form,
    FormControl,
    FormGroup,
    ControlLabel,
    HelpBlock,
    DatePicker,

    Row,
    Col,
    Panel,
    InputGroup,
    InputNumber,
    Input,
    Loader,

    InputPicker

} from 'rsuite';
import RFormControl from '../../componentsGeneral/Rsuite/Form/RFormControl';
import { lettersNumbersDashesInputMask, numbersInputMask, textNumbersSymbolsInputmask } from '../../../resources/logic/inputMasks';
import RButtonSmall from '../../componentsGeneral/Rsuite/Button/RButtonSmall';

const styles = {
    width: '100%',
    marginBottom: '10px',
    marginTop: '10px'
};

// style={{ overflowX: 'hidden!important' }} 

const mountRef = React.createRef();

const ReceiveMasterDrawer = ({ onChange, show, placement, confirm, close, onClickAddMissing, masterLabel, state, ...props }) => (

    props.isHawbLoading ?

        <div align="center">
            <section className="panel">
            </section>
            <Loader
                type="TailSpin"
                color="#3396E5"
                height="50"
                width="50"
            />
        </div>
        :

        <Drawer show={state.showReceiveDrawer} onHide={close} placement={placement} style={props.isMobile ? { width: '350px' } : { width: '650px' }}    >
            <Drawer.Header>
                <Drawer.Title style={{ marginBottom: '15px' }}>Master: {masterLabel}</Drawer.Title>


                <FormGroup>
                    <ButtonToolbar >
                        <Button onClick={confirm} appearance="primary">Save</Button>
                        <Button onClick={close} appearance="default">Cancel</Button>

                        {/* <InputPicker
                                preventOverflow
                                // placeholder={label}
                                placeholder={'Select Warehouse...'}
                                value={state.ReceiveDrawerWarehouse}
                                onChange={(e) => { onChangeWarehouse(e) }}
                                data={props.UserWarehouseData}
                            
                                cleanable={true}
                            /> */}

                        {state.unsavedReceiveChanges ? '' : <Button style={{ float: 'right' }} onClick={onClickAddMissing} appearance="default">Add Missing</Button>}

                    </ButtonToolbar>


                </FormGroup>
            </Drawer.Header>

            <Drawer.Body> <div ref={mountRef}>

                <Form fluid>




                    {props.HawbData.map(item => (

                        <Row >
                            <Col md={24} sm={24}>
                                <Panel bordered style={{ marginBottom: '10px' }}>

                                    <Col md={8} sm={24}>
                                        House Ref: <strong>{item.HouseRef}</strong>
                                        <br />
                                        Remaining Cartons: <strong>{item.Cartons - item.RecoveredPCS}</strong>
                                        <br />
                                        <Checkbox onChange={(e, value) => { onChange(value, item, 'Customs') }}> Customs Cleared</Checkbox>
                                    </Col>

                                    <Col md={16} sm={24}>
                                        <FormGroup >
                                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                                <InputGroup.Addon>Recovered:</InputGroup.Addon>
                                                {/* <InputNumber defaultValue={item.Cartons - item.RecoveredPCS } className={'custom-input-number'} onChange={(e) => onChange(e, item, 'Recovered')} scrollable={false} /> */}
                                                <InputNumber defaultValue={0} className={'custom-input-number'} onChange={(e) => onChange(e, item, 'Recovered')} scrollable={false} />
                                            </InputGroup>
                                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                                <InputGroup.Addon>Pallets:</InputGroup.Addon>
                                                <InputNumber defaultValue={0} className={'custom-input-number'} onChange={(e) => onChange(e, item, 'Pallets')} scrollable={false} />
                                            </InputGroup>
                                            <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                <InputGroup.Addon>Notes:</InputGroup.Addon>
                                                <Input onChange={(e) => { onChange(e, item, 'Notes') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>


                                </Panel>
                            </Col>
                        </Row>




                    ))}



                </Form>

            </div> </Drawer.Body>



            <Drawer.Footer>



            </Drawer.Footer>


        </Drawer>

);


ReceiveMasterDrawer.defaultProps = {
    show: false
};

export default ReceiveMasterDrawer;








// <Row>
//                                     <Col md={16} sm={24}>
//                                                     <FormGroup >
//                                                         <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
//                                                             <InputGroup.Addon>Total Recovered Cases:</InputGroup.Addon>
//                                                             {/* <InputNumber defaultValue={item.Cartons - item.RecoveredPCS } className={'custom-input-number'} onChange={(e) => onChange(e, item, 'Recovered')} scrollable={false} /> */}
//                                                             <InputNumber readOnly value={state.ReceiveDict[item.PoID].Cases} className={'custom-input-number'} onChange={(e) => onChange(e, item, 'Recovered')} scrollable={false} />
//                                                         </InputGroup>
//                                                         <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
//                                                             <InputGroup.Addon>Total Pallets:</InputGroup.Addon>
//                                                             <InputNumber readOnly value={state.ReceiveDict[item.PoID].Pallets} className={'custom-input-number'} onChange={(e) => onChange(e, item, 'Pallets')} scrollable={false} />
//                                                         </InputGroup>
//                                                     </FormGroup>
//                                                 </Col>


//                                             </Row>
//                                             <Row>

//                                                 <Col md={24} sm={24}>
//                                                     <FormGroup >
//                                                         <InputGroup style={{ marginTop: '5px', width: '100%' }}>
//                                                             <InputGroup.Addon>Notes:</InputGroup.Addon>
//                                                             <Input onChange={(e) => { onChange(e, item, 'Notes') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
//                                                         </InputGroup>
//                                                     </FormGroup>
//                                                 </Col>

//                                             </Row>

//                                             <Divider />



//                                             </Col>

