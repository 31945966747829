import MinusIcon from '@rsuite/icons/Minus';
import * as moment from 'moment';
import queryString from 'query-string';
import React, {
    PureComponent
} from 'react';
import ReactExport from "react-data-export";
import Loader from 'react-loader-spinner';
import { withRouter } from 'react-router-dom';
import { Button, Col, FormGroup, Input, InputGroup, Panel, PanelGroup, Row, Timeline, Tooltip } from 'rsuite';
import { appAPI } from '../../../app/appConfig';
import { formatStringForAPI } from '../../../resources/logic/functions';
import IconButton from '../../common/IconButton/IconButton';
import RTableGeneral from '../../componentsGeneral/Rsuite/Table/RTableGeneral';
import CtrlTwrPublicShipmentLegsDetailDataElements from '../../dataComponentsControlTower/CtrlTwrPublicShipmentLegsDetailDataElements';
import CtrlTwrShipmentFileUploadsDataElements from '../../dataComponentsControlTower/CtrlTwrShipmentFileUploadsDataElements';
import CtrlTwrShipmentLegsMilestonesDataElements from '../../dataComponentsControlTower/CtrlTwrShipmentLegsMilestonesDataElements';

//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")


const tableIdCol = "ShipmentLegMilestoneID"

const tooltipDelete = (
  <Tooltip>
    Delete
  </Tooltip>
);

const styles = {
  marginTop: '5px',
  width: '100%'
};

const inputStyle = {
  width: '100%'
};


const mountRef = React.createRef();





class ShipmentDetails extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {

      //Warehouse & Customer Arrays
      warehouseArray: {},
      customerArray: {},

      //Ship Management Drawer
      ShipManagementExistingDict: {},
      unsavedShipManagmentChanges: false,
      ShipManagementProRef: null,
      AddNewLegDict: {},
      ShipManagementHbolData: {},
      ShipManagementPoData: {},
      ShipManagementCustRefData: {},

    };

    // Table Sort
    this.handleSortColumn = this.handleSortColumn.bind(this);

    this.onClickShipmentSubscribe = this.onClickShipmentSubscribe.bind(this);

    //File Upload
    this.onClickDownloadFileUpload = this.onClickDownloadFileUpload.bind(this);
  }

  getsessionStorageWarehouse() {

    try {
      let warehouseMenuList = JSON.parse(sessionStorage.getItem('AppConfig.WarehouseArray'));
      if (warehouseMenuList != null && warehouseMenuList != undefined) {
        this.setState({
          warehouseArray: warehouseMenuList
        })
      }
    }
    catch (e) { }

    try {
      let customerMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CustomerArray'));
      if (customerMenuList != null && customerMenuList != undefined) {
        this.setState({
          customerArray: customerMenuList
        })
      }
    }
    catch (e) { }

  }


  componentDidMount() {
    this.getsessionStorageWarehouse() //this will preserve menu selection
    this.loadShipLegData()
  }



  componentDidUpdate(prevProps, prevState) {
    if (prevProps.CtrlTwrShipmentLegsData !== this.props.CtrlTwrShipmentLegsData && this.props.CtrlTwrShipmentLegsData.length > 0) {
      this.preloadShipManagement()
    }

    if (prevProps.CtrlTwrShipmentLegsCustomerRefData !== this.props.CtrlTwrShipmentLegsCustomerRefData) {
      this.preloadHbolPoCustRef()
    }
    if (prevProps.CtrlTwrShipmentLegsPoData !== this.props.CtrlTwrShipmentLegsPoData) {
      this.preloadHbolPoCustRef()
    }
    if (prevProps.CtrlTwrShipmentLegsHbolData !== this.props.CtrlTwrShipmentLegsHbolData) {
      this.preloadHbolPoCustRef()
    }
  }


  preloadHbolPoCustRef() {
    var hbolData = this.props.CtrlTwrShipmentLegsHbolData
    var poData = this.props.CtrlTwrShipmentLegsPoData
    var custRefData = this.props.CtrlTwrShipmentLegsCustomerRefData

    this.setState({
      ShipManagementHbolData: hbolData,
      ShipManagementPoData: poData,
      ShipManagementCustRefData: custRefData
    });
    this.forceUpdate()
  }


  preloadShipManagement() {
    var legsData = this.props.CtrlTwrShipmentLegsData
    var hbolData = this.props.CtrlTwrShipmentLegsHbolData
    var poData = this.props.CtrlTwrShipmentLegsPoData
    var custRefData = this.props.CtrlTwrShipmentLegsCustomerRefData

    this.setState({
      ShipManagementExistingDict: legsData,
      unsavedShipManagmentChanges: false,
      ShipManagementHbolData: hbolData,
      ShipManagementPoData: poData,
      ShipManagementCustRefData: custRefData
    });
    this.forceUpdate()
  }

  loadShipLegData() {
    const values = queryString.parse(this.props.location.search)

    try {
      var paramPro = formatStringForAPI(values.pro)
    }
    catch (e) {
      var paramPro = null
    }

    this.setState({
      //Ship Management Drawer
      ShipManagementExistingDict: {},
      unsavedShipManagmentChanges: false,
      ShipManagementProRef: paramPro,
      addNewLegModalShown: false,
      AddNewLegDict: {},
      multiModalShown: false,
      multiModalValuesSaving: false,
      ShipManagementHbolData: {},
      ShipManagementPoData: {},
      ShipManagementCustRefData: {},
    });
  }





  handleSortColumn(sortColumn, sortType) {
    this.setState({
      loading: true
    });

    setTimeout(() => {
      this.setState({
        sortColumn,
        sortType,
        loading: false
      });
    }, 500);
  }




  //File Upload
  onClickDownloadFileUpload(e, rowData, history) {
    try {
      this.props.downloadFileFromS3(this.props.currentUserEmail, this.props.userSignInData.AuthToken, rowData.S3FileName, rowData.FileName, appAPI.FileDownloadCtrlTwrS3)
    }
    catch (e) {
      window.alert("Error Downloading File.")
    }
  }


  onClickShipmentSubscribe(e, currentStatus, proRef) {
    if (currentStatus) {
      if (window.confirm('Unsubscribe from Shipment?')) {
        try {
          this.props.ctrlTwrShipmentUserSubscriptionPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, proRef, 0)
        }
        catch (e) { }
      }
    }
    else {
      if (window.confirm('Subscribe to Shipment?')) {
        try {
          this.props.ctrlTwrShipmentUserSubscriptionPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, proRef, 1)
        }
        catch (e) { }
      }
    }
  }



  render() {
    const { sortColumn, sortType } = this.state;

    let pageHeader;
    let shipSubscriptionDiv;

    let detailsDiv;
    if (this.props.isCtrlTwrShipmentLoading) {
      detailsDiv =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>



    }
    else if (this.props.isCtrlTwrShipmentFileUploadLoading) {
      detailsDiv =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (typeof (this.props.CtrlTwrShipmentData[0]) !== 'undefined' && this.props.CtrlTwrShipmentData != null) {
      pageHeader = <div style={{ float: 'left' }}>  {'Control Tower - Shipment: ' + this.props.CtrlTwrShipmentData[0].ProRef} </div>



      detailsDiv =
        <div>
          <Row >
            <Col md={12} sm={24}>
              <Panel bordered style={{ marginBottom: 10 }}
              >
                PO: <strong>{this.props.CtrlTwrShipmentData[0].PO}</strong>
                <br />
                Planned Pickup: <strong>{this.props.CtrlTwrShipmentData[0].ETD}</strong>
                <br />
                Customer: <strong>{this.props.CtrlTwrShipmentData[0].CustomerName}</strong>
                <br />
                Origin Country: <strong>{this.props.CtrlTwrShipmentData[0].CountryName}</strong>
                <br />
                Destination Country: <strong>{this.props.CtrlTwrShipmentData[0].DestCountryName}</strong>
                <br />

                Current Mode: <strong>{this.props.CtrlTwrShipmentData[0].ModeName}</strong>
                <br />

                Current Milestone: <strong>{this.props.CtrlTwrShipmentData[0].Milestone}</strong>
                <br />
                Current Milestone DT: <strong>{this.props.CtrlTwrShipmentData[0].MilestoneDT}</strong>
                <br />

              </Panel>
            </Col>
            <Col md={12} sm={24}>
              <Panel bordered style={{ marginBottom: 10 }}
              >

                Pallets: <strong>{this.props.CtrlTwrShipmentData[0].Pallets}</strong>
                <br />
                Cases: <strong>{this.props.CtrlTwrShipmentData[0].Cases}</strong>
                <br />
                Units: <strong>{this.props.CtrlTwrShipmentData[0].UnitCount}</strong>
                <br />
                Gross Weight: <strong>{this.props.CtrlTwrShipmentData[0].GrossWeight} {this.props.CtrlTwrShipmentData[0].WeightUnits}</strong>
                <br />
                Chargeable Weight: <strong>{this.props.CtrlTwrShipmentData[0].ChargeableWeight} {this.props.CtrlTwrShipmentData[0].WeightUnits}</strong>
                <br />
                Special Requirements: <strong>{this.props.CtrlTwrShipmentData[0].SpecialRequirements}</strong>
                <br />
                Commodity: <strong>{this.props.CtrlTwrShipmentData[0].Commodity}</strong>
                <br />
                Dimensions (L x W x H): <strong>{this.props.CtrlTwrShipmentData[0].Length} {this.props.CtrlTwrShipmentData[0].Width !== null ? 'x' : ''} {this.props.CtrlTwrShipmentData[0].Width} {this.props.CtrlTwrShipmentData[0].Height !== null ? 'x' : ''} {this.props.CtrlTwrShipmentData[0].Height} {this.props.CtrlTwrShipmentData[0].DimUnits}</strong>
                <br />
              </Panel>
            </Col>
          </Row>
        </div>


      if (this.props.CtrlTwrShipmentData[0].ShipmentSubscription) {
        shipSubscriptionDiv = <IconButton
          iconLabel={'Shipment Subscription'}
          icon={<i className="fa fa-envelope-open" />}
          iconColor={'green'}
          allowClick={true}
          onClickIconButton={(e) => { this.onClickShipmentSubscribe(e, this.props.CtrlTwrShipmentData[0].ShipmentSubscription, this.props.CtrlTwrShipmentData[0].ProRef) }}
          isMobile={this.props.isMobile}
        />
      }
      else {
        shipSubscriptionDiv = <IconButton
          iconLabel={'Click to Subscribe'}
          icon={<i className="fa fa-envelope-open" />}
          iconColor={'white'}
          allowClick={true}
          onClickIconButton={(e) => { this.onClickShipmentSubscribe(e, this.props.CtrlTwrShipmentData[0].ShipmentSubscription, this.props.CtrlTwrShipmentData[0].ProRef) }}
          isMobile={this.props.isMobile}
        />
      }
    }
    else {
      detailsDiv = <div></div>;
      shipSubscriptionDiv = <div></div>
    }


    let fileUploadsTable;
    if (this.props.isCtrlTwrShipmentFileUploadLoading) {

      fileUploadsTable =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (typeof (this.props.CtrlTwrShipmentFileUploadData) !== 'undefined' && this.props.CtrlTwrShipmentFileUploadData != null && this.props.CtrlTwrShipmentFileUploadData.length > 0) {
      var milestoneTableComponent = RTableGeneral(
        tableIdCol,     //   keyColumn,
        this.props.CtrlTwrShipmentFileUploadData,     //   tableData,
        CtrlTwrShipmentFileUploadsDataElements,       //   DataElements,
        this.props.isCtrlTwrShipmentFileUploadLoading,        //   loading,

        this.props.isMobile,        //   isMobile,
        this.props.history,     //   history,

        sortColumn,     //   sortColumn,
        sortType,       //   sortType,
        this.handleSortColumn,      //   handleSortColumn,

        null, // checkColEntry,
        null,       //   checkedKeys,
        null,       //   handleCheckAll,
        null,       //   handleCheck,

        true,      //   onClickEditMilestone,
        this.onClickDownloadFileUpload,       //   onClickSelect,
        'Download',       //   selectButtonText,
        null,       //   selectHighlightRowKey,

        false,       //   link1bool,
        null,     //   link1text,
        null,     //   link1onClick,

        false,       //   link2bool,
        null,       //   link2text,
        null  //   link2onClick
      )

      fileUploadsTable = <Panel
        bordered style={{ marginBottom: 10 }}
        header='File Uploads'
      >
        {milestoneTableComponent}
      </Panel>


    }
    else {
      fileUploadsTable = <div></div>
    }



    let legsDataDiv;
    if (this.props.isCtrlTwrShipmentLegsDataLoading) {
      legsDataDiv =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (this.props.isCtrlTwrShipmentLegsCustomerRefLoading) {
      legsDataDiv =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (this.props.isCtrlTwrShipmentLegsPoLoading) {
      legsDataDiv =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (this.props.isCtrlTwrShipmentLegsHbolLoading) {
      legsDataDiv =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (typeof (this.props.CtrlTwrShipmentLegsData[0]) !== 'undefined' && this.props.CtrlTwrShipmentLegsData != null && typeof (this.state.ShipManagementExistingDict[0]) !== 'undefined' && this.state.ShipManagementExistingDict != null) {
      legsDataDiv =
        <div>
          <Panel bordered style={{ marginBottom: 10 }} header="Leg Reference Numbers">
            {
              this.state.ShipManagementExistingDict.map(legItem => (

                legItem.ModeID === 1 ? //Ocean
                  <Panel
                    bordered
                    style={{ marginBottom: '10px' }}
                    header={
                      <Row>
                        <Col md={16} sm={24}>
                          <span>Leg: {legItem.LegNumber} - {legItem.ModeName}</span>
                        </Col>
                      </Row>
                    }
                  >

                    <Panel

                      style={{ marginBottom: '10px' }}
                    >
                      {
                        this.state.ShipManagementCustRefData.map(freeCustItem => (
                          freeCustItem["LegID"] === legItem["LegID"] && freeCustItem["HbolID"] === null ?
                            <InputGroup style={styles}>
                              <InputGroup.Addon>Customer Ref</InputGroup.Addon>
                              <Input readOnly style={styles} value={freeCustItem["CustomerRef"]} />
                            </InputGroup>
                            :
                            ''
                        ))
                      }
                      {
                        this.state.ShipManagementPoData.map(freePotem => (
                          freePotem["LegID"] === legItem["LegID"] && freePotem["HbolID"] === null ?
                            <InputGroup style={styles}>
                              <InputGroup.Addon>PO</InputGroup.Addon>
                              <Input readOnly style={styles} value={freePotem["PO"]} />
                            </InputGroup>
                            :
                            ''
                        ))
                      }
                      {
                        this.state.ShipManagementHbolData.map(hbolItem => (
                          hbolItem["LegID"] === legItem["LegID"] ?
                            <FormGroup style={{ marginTop: '5px' }}>
                              <InputGroup style={styles}>
                                <InputGroup.Addon>HBOL</InputGroup.Addon>
                                <Input readOnly style={styles} value={hbolItem["HBOL"]} />
                              </InputGroup>
                              {
                                this.state.ShipManagementCustRefData.map(custItem => (
                                  custItem["LegID"] === legItem["LegID"] && custItem["HbolID"] === hbolItem["HbolID"] ?
                                    <InputGroup style={styles}>
                                      <InputGroup.Addon>
                                        <MinusIcon />
                                      </InputGroup.Addon>
                                      <InputGroup.Addon>Customer Ref</InputGroup.Addon>
                                      <Input readOnly style={styles} value={custItem["CustomerRef"]} />
                                    </InputGroup>
                                    :
                                    ''
                                ))
                              }
                              {
                                this.state.ShipManagementPoData.map(poItem => (
                                  poItem["LegID"] === legItem["LegID"] && poItem["HbolID"] === hbolItem["HbolID"] ?
                                    <InputGroup style={styles}>
                                      <InputGroup.Addon>
                                        <MinusIcon />
                                      </InputGroup.Addon>
                                      <InputGroup.Addon>PO</InputGroup.Addon>
                                      <Input readOnly style={styles} value={poItem["PO"]} />
                                    </InputGroup>
                                    :
                                    ''
                                ))
                              }
                            </FormGroup>
                            :
                            <div></div>
                        ))
                      }
                    </Panel>




                  </Panel>

                  :

                  legItem.ModeID === 2 ? //Air
                    <Panel
                      bordered
                      style={{ marginBottom: '10px' }}
                      header={
                        <Row>
                          <Col md={16} sm={24}>
                            <span>Leg: {legItem.LegNumber} - {legItem.ModeName}</span>
                          </Col>
                        </Row>
                      }
                    >

                      <Panel

                        style={{ marginBottom: '10px' }}
                      >

                        {
                          this.state.ShipManagementCustRefData.map(freeCustItem => (
                            freeCustItem["LegID"] === legItem["LegID"] && freeCustItem["HbolID"] === null ?
                              <InputGroup style={styles}>
                                <InputGroup.Addon>Customer Ref</InputGroup.Addon>
                                <Input readOnly style={styles} value={freeCustItem["CustomerRef"]} />
                              </InputGroup>
                              :
                              ''
                          ))
                        }
                        {
                          this.state.ShipManagementPoData.map(freePotem => (
                            freePotem["LegID"] === legItem["LegID"] && freePotem["HbolID"] === null ?
                              <InputGroup style={styles}>
                                <InputGroup.Addon>PO</InputGroup.Addon>
                                <Input readOnly style={styles} value={freePotem["PO"]} />
                              </InputGroup>
                              :
                              ''
                          ))
                        }
                        {
                          this.state.ShipManagementHbolData.map(hbolItem => (
                            hbolItem["LegID"] === legItem["LegID"] ?
                              <FormGroup style={{ marginTop: '5px' }}>
                                <InputGroup style={styles}>
                                  <InputGroup.Addon>HBOL</InputGroup.Addon>
                                  <Input readOnly style={styles} value={hbolItem["HBOL"]} />
                                </InputGroup>
                                {
                                  this.state.ShipManagementCustRefData.map(custItem => (
                                    custItem["LegID"] === legItem["LegID"] && custItem["HbolID"] === hbolItem["HbolID"] ?
                                      <InputGroup style={styles}>
                                        <InputGroup.Addon>
                                          <MinusIcon />
                                        </InputGroup.Addon>
                                        <InputGroup.Addon>Customer Ref</InputGroup.Addon>
                                        <Input readOnly style={styles} value={custItem["CustomerRef"]} />
                                      </InputGroup>
                                      :
                                      ''
                                  ))
                                }
                                {
                                  this.state.ShipManagementPoData.map(poItem => (
                                    poItem["LegID"] === legItem["LegID"] && poItem["HbolID"] === hbolItem["HbolID"] ?
                                      <InputGroup style={styles}>
                                        <InputGroup.Addon>
                                          <MinusIcon />
                                        </InputGroup.Addon>
                                        <InputGroup.Addon>PO</InputGroup.Addon>
                                        <Input readOnly style={styles} value={poItem["PO"]} />
                                      </InputGroup>
                                      :
                                      ''
                                  ))
                                }
                              </FormGroup>
                              :
                              <div></div>
                          ))
                        }
                      </Panel>




                    </Panel>

                    :

                    legItem.ModeID === 3 ? //Truck Load
                      <Panel
                        bordered
                        style={{ marginBottom: '10px' }}
                        header={
                          <Row>
                            <Col md={16} sm={24}>
                              <span>Leg: {legItem.LegNumber} - {legItem.ModeName}</span>
                            </Col>
                          </Row>
                        }
                      >

                        <Panel

                          style={{ marginBottom: '10px' }}
                        >

                          {
                            this.state.ShipManagementCustRefData.map(freeCustItem => (
                              freeCustItem["LegID"] === legItem["LegID"] && freeCustItem["HbolID"] === null ?
                                <InputGroup style={styles}>
                                  <InputGroup.Addon>Customer Ref</InputGroup.Addon>
                                  <Input readOnly style={styles} value={freeCustItem["CustomerRef"]} />
                                </InputGroup>
                                :
                                ''
                            ))
                          }
                          {
                            this.state.ShipManagementPoData.map(freePotem => (
                              freePotem["LegID"] === legItem["LegID"] && freePotem["HbolID"] === null ?
                                <InputGroup style={styles}>
                                  <InputGroup.Addon>PO</InputGroup.Addon>
                                  <Input readOnly style={styles} value={freePotem["PO"]} />
                                </InputGroup>
                                :
                                ''
                            ))
                          }

                        </Panel>





                      </Panel>

                      :

                      legItem.ModeID === 4 ? //Less Than Truck Load

                        <Panel
                          bordered
                          style={{ marginBottom: '10px' }}
                          header={
                            <Row>
                              <Col md={16} sm={24}>
                                <span>Leg: {legItem.LegNumber} - {legItem.ModeName}</span>
                              </Col>
                            </Row>
                          }
                        >

                          <Panel

                            style={{ marginBottom: '10px' }}
                          >

                            {
                              this.state.ShipManagementCustRefData.map(freeCustItem => (
                                freeCustItem["LegID"] === legItem["LegID"] && freeCustItem["HbolID"] === null ?
                                  <InputGroup style={styles}>
                                    <InputGroup.Addon>Customer Ref</InputGroup.Addon>
                                    <Input readOnly style={styles} value={freeCustItem["CustomerRef"]} />
                                  </InputGroup>
                                  :
                                  ''
                              ))
                            }
                            {
                              this.state.ShipManagementPoData.map(freePotem => (
                                freePotem["LegID"] === legItem["LegID"] && freePotem["HbolID"] === null ?
                                  <InputGroup style={styles}>
                                    <InputGroup.Addon>PO</InputGroup.Addon>
                                    <Input readOnly style={styles} value={freePotem["PO"]} />

                                  </InputGroup>
                                  :
                                  ''
                              ))
                            }

                          </Panel>



                        </Panel>

                        :

                        legItem.ModeID === 5 ? //Rail

                          <Panel
                            bordered
                            style={{ marginBottom: '10px' }}
                            header={
                              <Row>
                                <Col md={16} sm={24}>
                                  <span>Leg: {legItem.LegNumber} - {legItem.ModeName}</span>
                                </Col>

                              </Row>
                            }
                          >

                            <Panel

                              style={{ marginBottom: '10px' }}
                            >

                              {
                                this.state.ShipManagementPoData.map(freePotem => (
                                  freePotem["LegID"] === legItem["LegID"] && freePotem["HbolID"] === null ?
                                    <InputGroup style={styles}>
                                      <InputGroup.Addon>PO</InputGroup.Addon>
                                      <Input readOnly style={styles} value={freePotem["PO"]} />
                                    </InputGroup>
                                    :
                                    ''
                                ))
                              }

                            </Panel>





                          </Panel>

                          :

                          legItem.ModeID === 6 ? //Brokerage
                            <Panel
                              bordered
                              style={{ marginBottom: '10px' }}
                              header={
                                <Row>
                                  <Col md={16} sm={24}>
                                    <span>Leg: {legItem.LegNumber} - {legItem.ModeName}</span>
                                  </Col>
                                </Row>
                              }
                            >


                            </Panel>

                            :

                            legItem.ModeID === 7 ? //Drayage
                              <Panel
                                bordered
                                style={{ marginBottom: '10px' }}
                                header={
                                  <Row>
                                    <Col md={16} sm={24}>
                                      <span>Leg: {legItem.LegNumber} - {legItem.ModeName}</span>
                                    </Col>
                                  </Row>
                                }
                              >

                                <Panel

                                  style={{ marginBottom: '10px' }}
                                >
                                  {
                                    this.state.ShipManagementCustRefData.map(freeCustItem => (
                                      freeCustItem["LegID"] === legItem["LegID"] && freeCustItem["HbolID"] === null ?
                                        <InputGroup style={styles}>
                                          <InputGroup.Addon>Customer Ref</InputGroup.Addon>
                                          <Input readOnly style={styles} value={freeCustItem["CustomerRef"]} />
                                        </InputGroup>
                                        :
                                        ''
                                    ))
                                  }
                                  {
                                    this.state.ShipManagementPoData.map(freePotem => (
                                      freePotem["LegID"] === legItem["LegID"] && freePotem["HbolID"] === null ?
                                        <InputGroup style={styles}>
                                          <InputGroup.Addon>PO</InputGroup.Addon>
                                          <Input readOnly style={styles} value={freePotem["PO"]} />
                                        </InputGroup>
                                        :
                                        ''
                                    ))
                                  }
                                  {
                                    this.state.ShipManagementHbolData.map(hbolItem => (
                                      hbolItem["LegID"] === legItem["LegID"] ?
                                        <FormGroup style={{ marginTop: '5px' }}>
                                          <InputGroup style={styles}>
                                            <InputGroup.Addon>HBOL</InputGroup.Addon>
                                            <Input readOnly style={styles} value={hbolItem["HBOL"]} />
                                          </InputGroup>
                                          {
                                            this.state.ShipManagementCustRefData.map(custItem => (
                                              custItem["LegID"] === legItem["LegID"] && custItem["HbolID"] === hbolItem["HbolID"] ?
                                                <InputGroup style={styles}>
                                                  <InputGroup.Addon>
                                                    <MinusIcon />
                                                  </InputGroup.Addon>
                                                  <InputGroup.Addon>Customer Ref</InputGroup.Addon>
                                                  <Input readOnly style={styles} value={custItem["CustomerRef"]} />
                                                </InputGroup>
                                                :
                                                ''
                                            ))
                                          }
                                          {
                                            this.state.ShipManagementPoData.map(poItem => (
                                              poItem["LegID"] === legItem["LegID"] && poItem["HbolID"] === hbolItem["HbolID"] ?
                                                <InputGroup style={styles}>
                                                  <InputGroup.Addon>
                                                    <MinusIcon />
                                                  </InputGroup.Addon>
                                                  <InputGroup.Addon>PO</InputGroup.Addon>
                                                  <Input readOnly style={styles} value={poItem["PO"]} />
                                                </InputGroup>
                                                :
                                                ''
                                            ))
                                          }
                                        </FormGroup>
                                        :
                                        <div></div>
                                    ))
                                  }
                                </Panel>






                              </Panel>

                              :




                              <Panel
                                bordered
                                style={{ marginBottom: '10px' }}
                                header={
                                  <Row>
                                    <Col md={16} sm={24}>
                                      <span>Leg: {legItem.LegNumber} - {legItem.ModeName}</span>
                                    </Col>

                                  </Row>
                                }
                              >
                              </Panel>



              ))
            }

          </Panel>
        </div>
    }
    else {
      legsDataDiv = <div></div>;
    }




    let iconsDiv = <div>

      <Row>

        <Col md={24} sm={24}>
          {shipSubscriptionDiv}
        </Col>

      </Row>
    </div>



    let downloadMilestoneDiv;
    let milestoneTable;
    if (this.props.isCtrlTwrShipmentLegsMilestonesLoading) {

      downloadMilestoneDiv =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (typeof (this.props.CtrlTwrShipmentLegsMilestonesData) !== 'undefined' && this.props.CtrlTwrShipmentLegsMilestonesData != null) {
      var milestoneTableComponent = RTableGeneral(
        tableIdCol,     //   keyColumn,
        this.props.CtrlTwrShipmentLegsMilestonesData,     //   tableData,
        CtrlTwrShipmentLegsMilestonesDataElements,       //   DataElements,
        this.props.isCtrlTwrShipmentLegsMilestonesLoading,        //   loading,

        this.props.isMobile,        //   isMobile,
        this.props.history,     //   history,

        sortColumn,     //   sortColumn,
        sortType,       //   sortType,
        this.handleSortColumn,      //   handleSortColumn,

        null, // checkColEntry,
        null,       //   checkedKeys,
        null,       //   handleCheckAll,
        null,       //   handleCheck,

        false,      //   onClickEditMilestone,
        null,       //   onClickSelect,
        null,       //   selectButtonText,
        null,       //   selectHighlightRowKey,

        false,       //   link1bool,
        null,     //   link1text,
        null,     //   link1onClick,

        false,       //   link2bool,
        null,       //   link2text,
        null  //   link2onClick
      )

      downloadMilestoneDiv = <div>
        {(this.props.isMobile == true) ? <div></div> :
          <div>
            <Row>
              <ExcelFile element={<Button style={{ marginTop: '10px', float: 'right' }}>Export Excel</Button>} filename={'Milestones_' + currentDateTime}>
                <ExcelSheet data={this.props.CtrlTwrShipmentLegsMilestonesData} name="Milestones">
                  {
                    CtrlTwrShipmentLegsMilestonesDataElements.map(
                      ({ dBKey, displayName }, idx) => {
                        return (
                          <ExcelColumn label={displayName} value={dBKey} />
                        );
                      }
                    )
                  }
                </ExcelSheet>
              </ExcelFile>
            </Row>
          </div>
        }
      </div>


      milestoneTable = <Panel
        bordered style={{ marginBottom: 10 }}
        header='Milestones'

      >
        {milestoneTableComponent}
        {downloadMilestoneDiv}
      </Panel>


    }
    else {
      milestoneTable = <div></div>
      downloadMilestoneDiv = <div></div>
    }




    let legsTableDiv;
    let downloadLegsDiv;
    if (this.props.isCtrlTwrShipmentLegsDataLoading) {
      legsTableDiv =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>

      downloadLegsDiv = <div></div>
    }
    else if (typeof (this.props.CtrlTwrShipmentLegsData) !== 'undefined' && this.props.CtrlTwrShipmentLegsData != null) {
      let legsTableComponent = RTableGeneral(
        'LegID',     //   keyColumn,
        this.props.CtrlTwrShipmentLegsData,     //   tableData,
        CtrlTwrPublicShipmentLegsDetailDataElements,       //   DataElements,
        this.props.isCtrlTwrShipmentLegsDataLoading,        //   loading,

        this.props.isMobile,        //   isMobile,
        this.props.history,     //   history,

        sortColumn,     //   sortColumn,
        sortType,       //   sortType,
        this.handleSortColumn,      //   handleSortColumn,

        null, // checkColEntry,
        null,       //   checkedKeys,
        null,       //   handleCheckAll,
        null,       //   handleCheck,

        false,      //   onClickEditMilestone,
        null,       //   onClickSelect,
        null,       //   selectButtonText,
        null,       //   selectHighlightRowKey,

        false,       //   link1bool,
        null,     //   link1text,
        null,     //   link1onClick,

        false,       //   link2bool,
        null,       //   link2text,
        null  //   link2onClick
      )

      let filename = 'Legs_'
      let sheetName = "Legs"
      downloadLegsDiv = <div>
        {(this.props.isMobile == true) ? <div></div> :
          <div>
            <Row>
              <ExcelFile element={<Button style={{ marginTop: '10px', float: 'right' }}>Export Excel</Button>} filename={filename + currentDateTime}>
                <ExcelSheet data={this.props.CtrlTwrShipmentLegsMilestonesData} name={sheetName}>
                  {
                    CtrlTwrPublicShipmentLegsDetailDataElements.map(
                      ({ dBKey, displayName }, idx) => {
                        return (
                          <ExcelColumn label={displayName} value={dBKey} />
                        );
                      }
                    )
                  }
                </ExcelSheet>
              </ExcelFile>
            </Row>
          </div>
        }
      </div>


      legsTableDiv = <Panel
        bordered style={{ marginBottom: 10 }}
        header='Legs'

      >
        {legsTableComponent}
        {downloadLegsDiv}
      </Panel>
    }
    else {
      downloadLegsDiv = <div></div>
      legsTableDiv = <div></div>
    }




    let publicNotes;
    if (this.props.isCtrlTwrShipmentNotesPublicLoading) {
      publicNotes =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (typeof (this.props.CtrlTwrShipmentNotesPublicData[0]) !== 'undefined' && this.props.CtrlTwrShipmentNotesPublicData != null) {
      publicNotes =
        <div>
          <Row >
            <Col md={24} sm={24}>
              <Panel bordered header="Public Notes" style={{ marginBottom: 10 }}>
                <Timeline>
                  {this.props.CtrlTwrShipmentNotesPublicData.map(item => (
                    <Timeline.Item>
                      <strong>{item.NoteDT}  &nbsp;
                        {item.EmailAddr} : &nbsp; </strong>
                      <l>{item.PublicNote} &nbsp;
                      </l>

                    </Timeline.Item>
                  ))}
                </Timeline>

              </Panel>
            </Col>
          </Row>

        </div>
    }
    else {
      publicNotes = <div></div>
    }


    let privateNotes;
    if (this.props.isCtrlTwrShipmentNotesPrivateLoading) {
      privateNotes =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (typeof (this.props.organizationSelection) !== 'undefined' && this.props.organizationSelection != null && typeof (this.props.CtrlTwrShipmentNotesPrivateData[0]) !== 'undefined' && this.props.CtrlTwrShipmentNotesPrivateData != null) {
      privateNotes =
        <div>
          <Row >
            <Col md={24} sm={24}>
              <Panel bordered header="Private Notes" style={{ marginBottom: 10 }}>
                <Timeline>
                  {this.props.CtrlTwrShipmentNotesPrivateData.map(item => (
                    <Timeline.Item>
                      <strong>{item.NoteDT}  &nbsp;
                        {item.EmailAddr} : &nbsp; </strong>
                      <l>{item.PrivateNote} &nbsp;
                      </l>

                    </Timeline.Item>
                  ))}
                </Timeline>

              </Panel>
            </Col>
          </Row>

        </div>
    }
    else {
      privateNotes = <div></div>
    }


    return (
      <div>
        <h3>
          <Row>{pageHeader} </Row>
        </h3>

        {iconsDiv}

        <PanelGroup>
          {detailsDiv}
        </PanelGroup>

        {legsTableDiv}

        {milestoneTable}

        {publicNotes}
        {privateNotes}

        {fileUploadsTable}

      </div>
    );

  }
}

export default withRouter(ShipmentDetails);



