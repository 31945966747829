import { connect } from 'react-redux';
import React, { Component } from 'react';
import AppConfiguration from './AppConfiguration'
import * as userActions from '../../../redux/actions/actionsUser';
import { reducer } from 'aws-cognito-redux-saga'
import * as ctrlTwrActions from '../../../redux/actions/ctrlTwrActions';

//add props from reducer here
const mapStateToProps = store =>
({
    isUserAccountsLoading: store.userServicesReducer.isUserAccountsLoading,
    userAccountData: store.userServicesReducer.userAccountData,

    userSignInData: store.userServicesReducer.userSignInData,
    currentUserEmail: store.userServicesReducer.currentUserEmail,

    isCtrlTwrAirportLoading: store.ctrlTwrReducer.isCtrlTwrAirportLoading,
    CtrlTwrAirportData: store.ctrlTwrReducer.CtrlTwrAirportData,

    isCtrlTwrSeaportLoading: store.ctrlTwrReducer.isCtrlTwrSeaportLoading,
    CtrlTwrSeaportData: store.ctrlTwrReducer.CtrlTwrSeaportData,

    AdminCustomersAllData: store.userServicesReducer.AdminCustomersAllData,
    isAdminCustomersAllLoading: store.userServicesReducer.isAdminCustomersAllLoading,


    AdminNewSeaportPostSuccessful: store.userServicesReducer.AdminNewSeaportPostSuccessful,

    AdminNewCustomerPostSuccessful: store.userServicesReducer.AdminNewCustomerPostSuccessful,

});

//add actions here
const mapDispatchToProps = dispatch =>
({

    ctrlTwrAirportGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(ctrlTwrActions.ctrlTwrAirportGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
    ctrlTwrSeaportGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(ctrlTwrActions.ctrlTwrSeaportGet(_email, _authToken, _WarehouseID, _CustomerID))
    },

    userAdminCustomersAllGet: (_email, _authToken) => {
        dispatch(userActions.userAdminCustomersAllGet(_email, _authToken))
    },

    AdminNewSeaportPost: (_email, _authToken, _SeaportCode, _Port, _Country, _SeaportCodeID, _delete) => {
        dispatch(userActions.AdminNewSeaportPost(_email, _authToken, _SeaportCode, _Port, _Country, _SeaportCodeID, _delete))
    },
    AdminNewCustomerPost: (_email, _authToken, _CustomerName, _CustomerCode, _CustomerID, _delete) => {
        dispatch(userActions.AdminNewCustomerPost(_email, _authToken, _CustomerName, _CustomerCode, _CustomerID, _delete))
    },

    userCustomersGet: (_email, _otherUsersID) => {
        dispatch(userActions.userCustomersGet(_email, _otherUsersID))
    },
});

class AppConfigurationContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleUrlParam()
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.AdminNewSeaportPostSuccessful !== this.props.AdminNewSeaportPostSuccessful && this.props.AdminNewSeaportPostSuccessful) {
            this.handleUrlParam();
        }
        if (prevProps.AdminNewCustomerPostSuccessful !== this.props.AdminNewCustomerPostSuccessful && this.props.AdminNewCustomerPostSuccessful) {
            this.props.userCustomersGet(this.props.currentUserEmail, null)

            this.handleUrlParam();
        }
    }


    handleUrlParam() {
        // this.props.ctr lTwrSeaportGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)

        this.props.userAdminCustomersAllGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken)
    }

    render() {
        return (
            <AppConfiguration
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}

                isCtrlTwrAirportLoading={this.props.isCtrlTwrAirportLoading}
                CtrlTwrAirportData={this.props.CtrlTwrAirportData}

                isCtrlTwrSeaportLoading={this.props.isCtrlTwrSeaportLoading}
                CtrlTwrSeaportData={this.props.CtrlTwrSeaportData}

                AdminCustomersAllData={this.props.AdminCustomersAllData}
                isAdminCustomersAllLoading={this.props.isAdminCustomersAllLoading}

                AdminNewSeaportPostSuccessful={this.props.AdminNewSeaportPostSuccessful}
                AdminNewSeaportPost={this.props.AdminNewSeaportPost}

                AdminNewCustomerPostSuccessful={this.props.AdminNewCustomerPostSuccessful}
                AdminNewCustomerPost={this.props.AdminNewCustomerPost}
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppConfigurationContainer);




