import PropTypes from 'prop-types';
import React from 'react';
import {
  Route,
  Switch
} from 'react-router-dom';
import { customerConfig } from '../app/appCustomerConfig';

//General Views
import vasContentView from '../views/ViewsVas/vasView/vasContentView';

//User and Misc Views
import PrivateRoute from '../components/componentsGeneral/PrivateRoute';
import UserChangePassword from '../components/componentsUser/UserChangePassword';
import pageNotFound from '../views/ViewsGeneral/pageNotFound/pageNotFound';
import SignInAuthView from '../views/viewsUser/SignInAuthView/SignInAuthView';
import accountDetailsView from '../views/viewsUser/accountDetailsView/accountDetailsView';
import appConfigurationView from '../views/viewsUser/appConfigurationView/appConfigurationView';
import myProfileView from '../views/viewsUser/myProfile/myProfileView';
import userManagementView from '../views/viewsUser/userManagement/userManagementView';
import userSignUpView from '../views/viewsUser/userSignUp/userSignUpView';

//Vas Views
import houseDetailsView from '../views/ViewsVas/houseDetailsView/houseDetailsView';
import loadDetailsView from '../views/ViewsVas/loadDetailsView/loadDetailsView';
import loadView from '../views/ViewsVas/loadView/loadView';
import masterDetailsView from '../views/ViewsVas/masterDetailsView/masterDetailsView';
import outboundView from '../views/ViewsVas/outboundView/outboundView';
import shipmentView from '../views/ViewsVas/shipmentView/shipmentView';
import warehouseView from '../views/ViewsVas/warehouseView/warehouseView';

import dashboardView from '../views/ViewsVas/dashboardView/dashboardView';

//Trans Views
import transContainerDetailsView from '../views/ViewsTrans/transContainerView/transContainerView';
import transLoadDetailsView from '../views/ViewsTrans/transLoadDetailsView/transLoadDetailsView';
import transLoadView from '../views/ViewsTrans/transLoadView/transLoadView';
import transOutboundView from '../views/ViewsTrans/transOutboundView/transOutboundView';
import transPoDetailsView from '../views/ViewsTrans/transPoDetailsView/transPoDetailsView';
import transShipmentView from '../views/ViewsTrans/transShipmentView/transShipmentView';
import transWarehouseView from '../views/ViewsTrans/transWarehouseView/transWarehouseView';

//Transportation Views
import tpPickupView from '../views/ViewsTransportation/tpPickupView/tpPickupView';

//Control Tower Views
import ctrlTwrDashboardView from '../views/ViewsControlTower/ctrlTwrDashboardView/ctrlTwrDashboardView';
import ctrlTwrMilestoneEventEntryView from '../views/ViewsControlTower/ctrlTwrMilestoneEventEntry/ctrlTwrMilestoneEventEntryView';
import ctrlTwrMultiMilestoneEventEntryView from '../views/ViewsControlTower/ctrlTwrMultiMilestoneEventEntry/ctrlTwrMultiMilestoneEventEntryView';
import ctrlTwrQuoteEntryView from '../views/ViewsControlTower/ctrlTwrQuoteEntryView/ctrlTwrQuoteEntryView';
import ctrlTwrQuoteReportView from '../views/ViewsControlTower/ctrlTwrQuoteReportView/ctrlTwrQuoteReportView';
import ctrlTwrQuoteShipmentDetailsView from '../views/ViewsControlTower/ctrlTwrQuoteShipmentDetailsView/ctrlTwrQuoteShipmentDetailsView';
import ctrlTwrQuoteShipmentListView from '../views/ViewsControlTower/ctrlTwrQuoteShipmentListView/ctrlTwrQuoteShipmentListView';
import ctrlTwrShipmentDetailsManagementView from '../views/ViewsControlTower/ctrlTwrShipmentDetailsManagementView/ctrlTwrShipmentDetailsManagementView';
import ctrlTwrShipmentDetailsView from '../views/ViewsControlTower/ctrlTwrShipmentDetailsView/ctrlTwrShipmentDetailsView';
import ctrlTwrShipmentEntryView from '../views/ViewsControlTower/ctrlTwrShipmentEntryView/ctrlTwrShipmentEntryView';
import ctrlTwrShipmentListView from '../views/ViewsControlTower/ctrlTwrShipmentListView/ctrlTwrShipmentListView';
import ctrlTwrShipmentManagementView from '../views/ViewsControlTower/ctrlTwrShipmentManagementView/ctrlTwrShipmentManagementView';

import ctrlTwrLoadTenderDetailsView from '../views/ViewsControlTower/ctrlTwrLoadTenderDetailsView/ctrlTwrLoadTenderDetailsView';
import ctrlTwrLoadTenderView from '../views/ViewsControlTower/ctrlTwrLoadTenderView/ctrlTwrLoadTenderView';



//Label Generation Views
import labelGenBulkLabelGenerationView from '../views/ViewsLabelGeneration/labelGenBulkLabelGenerationView/labelGenBulkLabelGenerationView';
import labelGenConfigurationView from '../views/ViewsLabelGeneration/labelGenConfigurationView/labelGenConfigurationView';
import labelGenHistoryView from '../views/ViewsLabelGeneration/labelGenHistoryView/labelGenHistoryView';
import labelGenLabelGenerationView from '../views/ViewsLabelGeneration/labelGenLabelGenerationView/labelGenLabelGenerationView';
// import labelGenUpsLabelGenerationView from '../views/ViewsLabelGeneration/labelGenUpsLabelGenerationView/labelGenUpsLabelGenerationView';
// import labelGenUpsLabelHistoryView from '../views/ViewsLabelGeneration/labelGenUpsLabelHistoryView/labelGenUpsLabelHistoryView';


//Label Gen External Views
import labelGenExternalParcelTrackingView from '../views/ViewsLabelGenerationExternal/labelGenExternalParcelTrackingView/labelGenExternalParcelTrackingView';


//Inventory Management Views
//Common
import invManContainerDetailView from '../views/ViewsInventoryManagement/NonLotTrackingInvMan/invManContainerDetailView/invManContainerDetailView';
import invManLoadDetailView from '../views/ViewsInventoryManagement/NonLotTrackingInvMan/invManLoadDetailView/invManLoadDetailView';
import invManLoadView from '../views/ViewsInventoryManagement/NonLotTrackingInvMan/invManLoadView/invManLoadView';
import invManOutboundView from '../views/ViewsInventoryManagement/NonLotTrackingInvMan/invManOutboundView/invManOutboundView';
import invManReportingView from '../views/ViewsInventoryManagement/NonLotTrackingInvMan/invManReportingView/invManReportingView';
import invManSkuDetailView from '../views/ViewsInventoryManagement/NonLotTrackingInvMan/invManSkuDetailView/invManSkuDetailView';

//Lot Tracking Views
import invManBarcodeScanningView from '../views/ViewsInventoryManagement/LotTrackingInvMan/invManBarcodeScanningView/invManBarcodeScanningView';
import invManInboundLotView from '../views/ViewsInventoryManagement/LotTrackingInvMan/invManInboundView_Lot/invManInboundView';
import invManInventoryPalletCaseSkuView from '../views/ViewsInventoryManagement/LotTrackingInvMan/invManInventoryPalletCaseSkuView/invManInventoryPalletCaseSkuView';
import invManInventorySkuLevelView from '../views/ViewsInventoryManagement/LotTrackingInvMan/invManInventorySkuLevelView/invManInventorySkuLevelView';
import invManInventoryWarehouseLocationLotView from '../views/ViewsInventoryManagement/LotTrackingInvMan/invManInventoryWarehouseLocationLotView/invManInventoryWarehouseLocationLotView';
import invManLoadBuildByScanningView from '../views/ViewsInventoryManagement/LotTrackingInvMan/invManLoadBuildByScanningView/invManLoadBuildByScanningView';
import invManLotLoadView from '../views/ViewsInventoryManagement/LotTrackingInvMan/invManLotLoadView/invManLotLoadView';
import invManLotOutboundView from '../views/ViewsInventoryManagement/LotTrackingInvMan/invManLotOutboundView/invManLotOutboundView';
import invManLotReportingView from '../views/ViewsInventoryManagement/LotTrackingInvMan/invManLotReportingView/invManLotReportingView';

import invManLotContainerDetailView from '../views/ViewsInventoryManagement/LotTrackingInvMan/invManContainerDetailView/invManContainerDetailView';

import invManCaseDetailView from '../views/ViewsInventoryManagement/LotTrackingInvMan/invManCaseDetailView/invManCaseDetailView';
import invManPalletDetailView from '../views/ViewsInventoryManagement/LotTrackingInvMan/invManPalletDetailView/invManPalletDetailView';

import invManUnitDetailView from '../views/ViewsInventoryManagement/LotTrackingInvMan/invManUnitDetailView/invManUnitDetailView';
// import invManSkuCaseDetailView from '../views/ViewsInventoryManagement/LotTrackingInvMan/invManSkuCaseDetailView/invManSkuCaseDetailView';
import invManLotSkuDetailView from '../views/ViewsInventoryManagement/LotTrackingInvMan/invManLotSkuDetailView/invManLotSkuDetailView';

// import invManWarehouseLocationDetailView from '../views/ViewsInventoryManagement/LotTrackingInvMan/invManWarehouseLocationDetailView/invManWarehouseLocationDetailView';

import invManLotLoadDetailView from '../views/ViewsInventoryManagement/LotTrackingInvMan/invManLotLoadDetailView/invManLotLoadDetailView';




//Non Lot Tracking Views
import invManInboundView from '../views/ViewsInventoryManagement/NonLotTrackingInvMan/invManInboundView/invManInboundView';
import invManInventoryView from '../views/ViewsInventoryManagement/NonLotTrackingInvMan/invManInventoryView/invManInventoryView';
import invManWarehouseLocationDetailsView from '../views/ViewsInventoryManagement/NonLotTrackingInvMan/invManWarehouseLocationDetailsView/invManWarehouseLocationDetailsView';
import invManWarehouseView from '../views/ViewsInventoryManagement/NonLotTrackingInvMan/invManWarehouseView/invManWarehouseView';



//Visibility Views
import visibilityDashboardView from '../views/ViewsVisibility/visibilityDashboardView/visibilityDashboardView';
import visibilityPackageDetailsView from '../views/ViewsVisibility/visibilityPackageDetailsView/visibilityPackageDetailsView';
import visibilityTrackingView from '../views/ViewsVisibility/visibilityTrackingView/visibilityTrackingView';


//Customs Brokerage Views
import customsProductCatalogView from '../views/ViewsCustomsBrokerage/customsProductCatalog/customsProductCatalogView';

const routePathFunc = (admin, role, filter, warehouseArray, customerArray, moduleArray, organizationSelection) => {

  ////////////////1: Create Route from import component:
  //navRoutes:

  // General Routes
  let vasContent = <PrivateRoute exact path={`/${customerConfig.customerUrl}/wms`} component={vasContentView} />;


  //User and Misc Routes
  let usermanagement = <PrivateRoute exact path={`/${customerConfig.customerUrl}/wms/usermanagement`} component={userManagementView} />
  let myprofile = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/myprofile`} component={myProfileView} />
  let signup = <PrivateRoute exact path={`/${customerConfig.customerUrl}/wms/signup`} component={userSignUpView} />
  let userchangepassword = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/userchangepassword`} component={UserChangePassword} />
  let accountDetailsRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/accountdetails`} component={accountDetailsView} />
  let appConfigurationRoute = <PrivateRoute exact path={`/${customerConfig.customerUrl}/wms/appconfiguration`} component={appConfigurationView} />

  //Vas Routes
  let loadRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/vas/load`} component={loadView} />
  let shipmentRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/vas/shipment`} component={shipmentView} />
  let warehouseRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/vas/warehouse`} component={warehouseView} />
  let masterDetailsRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/vas/masterdetails`} component={masterDetailsView} />
  let houseDetailsRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/vas/housedetails`} component={houseDetailsView} />
  let loadDetailsRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/vas/loaddetails`} component={loadDetailsView} />
  let outboundRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/vas/outbound`} component={outboundView} />
  let dashboardRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/vas/dashboard`} component={dashboardView} />

  //Trans Routes
  let transShipmentRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/trans/shipment`} component={transShipmentView} />
  let transContainerDetailsRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/trans/containerdetails`} component={transContainerDetailsView} />
  let transWarehouseRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/trans/warehouse`} component={transWarehouseView} />
  let transPoDetailsRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/trans/podetails`} component={transPoDetailsView} />
  let transLoadRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/trans/load`} component={transLoadView} />
  let transLoadDetailsRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/trans/loaddetails`} component={transLoadDetailsView} />
  let transOutboundRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/trans/outbound`} component={transOutboundView} />

  //Transportation Routes
  let tpPickupRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/transportation/pickup`} component={tpPickupView} />


  //Control Tower Routes
  let ctrlTwrDashboardRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/ctrltwr/dashboard`} component={ctrlTwrDashboardView} />
  let ctrlTwrQuoteEntryRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/ctrltwr/quoteentry`} component={ctrlTwrQuoteEntryView} />
  let ctrlTwrQuoteShipmentListRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/ctrltwr/quoteshipmentlist`} component={ctrlTwrQuoteShipmentListView} />
  let ctrlTwrQuoteShipmentDetailsRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/ctrltwr/quoteshipmentdetails`} component={ctrlTwrQuoteShipmentDetailsView} />
  let ctrlTwrQuoteReportRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/ctrltwr/quotereport`} component={ctrlTwrQuoteReportView} />

  let ctrlTwrShipmentEntryRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/ctrltwr/shipmententry`} component={ctrlTwrShipmentEntryView} />
  let ctrlTwrMilestoneEventEntryRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/ctrltwr/milestoneevententry`} component={ctrlTwrMilestoneEventEntryView} />
  let ctrlTwrMultiMilestoneEventEntryRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/ctrltwr/multimilestoneevententry`} component={ctrlTwrMultiMilestoneEventEntryView} />
  let ctrlTwrShipmentManagementRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/ctrltwr/shipmentmanagement`} component={ctrlTwrShipmentManagementView} />
  let ctrlTwrShipmentListRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/ctrltwr/shipmentlist`} component={ctrlTwrShipmentListView} />
  let ctrlTwrShipmentDetailsRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/ctrltwr/shipmentdetails`} component={ctrlTwrShipmentDetailsView} />
  let ctrlTwrShipmentDetailsManagementRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/ctrltwr/shipmentdetailsmanagement`} component={ctrlTwrShipmentDetailsManagementView} />

  let ctrlTwrLoadTenderRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/ctrltwr/loadtender`} component={ctrlTwrLoadTenderView} />
  let ctrlTwrLoadTenderDetailsRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/ctrltwr/loadtenderdetails`} component={ctrlTwrLoadTenderDetailsView} />


  //Label Generation Routes
  let labelGenLabelGenerationRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/labelgen/labelgeneration`} component={labelGenLabelGenerationView} />
  let labelGenLabelGenHistortyRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/labelgen/labelgenhistory`} component={labelGenHistoryView} />
  let labelGenBulkLabelGenerationRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/labelgen/bulklabelgeneration`} component={labelGenBulkLabelGenerationView} />
  let labelGenLabelConfigurationRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/labelgen/configuration`} component={labelGenConfigurationView} />
  // let labelGenUpsLabelGenerationRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/labelgen/upslabelgeneration`} component={labelGenUpsLabelGenerationView} />
  // let labelGenUpsLabelHistoryRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/labelgen/upslabelhistory`} component={labelGenUpsLabelHistoryView} />

  //Label Gen External Routes
  let labelGenExternalParcelTrackingRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/labelgen/parceltracking`} component={labelGenExternalParcelTrackingView} />



  //Inventory Management Route

  //Lot Tracking
  let invManInboundLotRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/inventorymanagement/inboundlot`} component={invManInboundLotView} />
  let invManBarcodeScanningRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/inventorymanagement/barcodescanning`} component={invManBarcodeScanningView} />
  let invManLotContainerDetailRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/inventorymanagement/lotcontainerdetails`} component={invManLotContainerDetailView} />
  let invManInboundRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/inventorymanagement/inbound`} component={invManInboundView} />
  let invManInventoryRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/inventorymanagement/inventory`} component={invManInventoryView} />
  let invManInventoryPalletCaseSkuRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/inventorymanagement/inventorypcs`} component={invManInventoryPalletCaseSkuView} />
  let invManInventorySkuRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/inventorymanagement/inventorysku`} component={invManInventorySkuLevelView} />
  let invManLoadBuildByScanningRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/inventorymanagement/loadscanning`} component={invManLoadBuildByScanningView} />
  let invManWarehouseRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/inventorymanagement/warehouse`} component={invManWarehouseView} />
  // let invManWarehouseLocationDetailRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/inventorymanagement/warehouselocationlotdetails`} component={invManWarehouseLocationDetailView} />
  let invManWarehouseLocationLotRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/inventorymanagement/warehouselocationlot`} component={invManInventoryWarehouseLocationLotView} />
  let invManLotLoadDetailRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/inventorymanagement/lotloaddetails`} component={invManLotLoadDetailView} />
  let invManLotLoadRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/inventorymanagement/lotload`} component={invManLotLoadView} />
  let invManLotOutboundRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/inventorymanagement/lotoutbound`} component={invManLotOutboundView} />
  let invManLotReportingRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/inventorymanagement/lotreporting`} component={invManLotReportingView} />

  let invManPalletDetailRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/inventorymanagement/palletdetails`} component={invManPalletDetailView} />
  let invManCaseDetailRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/inventorymanagement/casedetails`} component={invManCaseDetailView} />
  let invManUnitDetailRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/inventorymanagement/unitdetails`} component={invManUnitDetailView} />

  let invManLotSkuDetailRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/inventorymanagement/lotskudetails`} component={invManLotSkuDetailView} />


  //Non Lot Tracking
  let invManSkuDetailRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/inventorymanagement/skudetails`} component={invManSkuDetailView} />
  let invManContainerDetailRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/inventorymanagement/containerdetails`} component={invManContainerDetailView} />
  let invManLoadRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/inventorymanagement/load`} component={invManLoadView} />
  let invManOutboundRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/inventorymanagement/outbound`} component={invManOutboundView} />
  let invManLoadDetailRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/inventorymanagement/loaddetails`} component={invManLoadDetailView} />
  let invManWarehouseLocationDetailsRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/inventorymanagement/warehouselocationdetails`} component={invManWarehouseLocationDetailsView} />
  let invManReportingRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/inventorymanagement/reporting`} component={invManReportingView} />


  // let invManSkuCaseDetailRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/inventorymanagement/skucasedetails`} component={invManSkuCaseDetailView} />


  //Visibility Routes
  let visibilityDashboardRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/visibility/dashboard`} component={visibilityDashboardView} />
  let visibilityPackageDetailsRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/visibility/packagedetails`} component={visibilityPackageDetailsView} />
  let visibilityTrackingRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/visibility/tracking`} component={visibilityTrackingView} />
  let visibilityReportingRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/visibility/reporting`} component={visibilityTrackingView} />


  //Customs Brokerage Routes
  let customsProductCatalogRoute = <PrivateRoute path={`/${customerConfig.customerUrl}/wms/customs/productcatalog`} component={customsProductCatalogView} />

  ////////////////2: Create Menu Link for routes with clickable links:
  //navLinks:

  //Vas Links
  let loadLink = {
    name: 'Load',
    linkTo: `/${customerConfig.customerUrl}/wms/vas/load?loadstatus=1`,
    faIconName: 'fa-truck'
  }
  let shipmentLink = {
    name: 'Airline Recovery',
    linkTo: `/${customerConfig.customerUrl}/wms/vas/shipment`,
    faIconName: 'fa-plane'
  }
  let warehouseLink = {
    name: 'Warehouse',
    linkTo: `/${customerConfig.customerUrl}/wms/vas/warehouse?hawbstatusid=1`,
    faIconName: 'fa-home'
  }
  let outboundLink = {
    name: 'Outbound',
    linkTo: `/${customerConfig.customerUrl}/wms/vas/outbound`,
    faIconName: 'fa-paper-plane'
  }

  let dashboardLink = {
    name: 'Dashboard',
    linkTo: `/${customerConfig.customerUrl}/wms/vas/dashboard`,
    faIconName: 'fa-eye'
  }


  //Trans Links
  let transShipmentLink = {
    name: 'Shipment',
    linkTo: `/${customerConfig.customerUrl}/wms/trans/shipment`,
    faIconName: 'fa-ship'
  }
  let transwarehouseLink = {
    name: 'Warehouse',
    linkTo: `/${customerConfig.customerUrl}/wms/trans/warehouse?postatusid=1`,
    faIconName: 'fa-home'
  }
  let transLoadLink = {
    name: 'Load',
    linkTo: `/${customerConfig.customerUrl}/wms/trans/load?loadstatus=1`,
    faIconName: 'fa-truck'
  }
  let transOutboundLink = {
    name: 'Outbound',
    linkTo: `/${customerConfig.customerUrl}/wms/trans/outbound`,
    faIconName: 'fa-paper-plane'
  }

  //Transportation Links 
  let tpPickupLink = {
    name: 'Pickup',
    linkTo: `/${customerConfig.customerUrl}/wms/transportation/pickup`,
    faIconName: 'fa-truck'
  }

  //Control Tower Links 
  let ctrlTwrDashboardLink = {
    name: 'Dashboard',
    linkTo: `/${customerConfig.customerUrl}/wms/ctrltwr/dashboard`,
    faIconName: 'fa-eye'
  }
  let ctrlTwrQuoteEntryLink = {
    name: 'Quote Entry',
    linkTo: `/${customerConfig.customerUrl}/wms/ctrltwr/quoteentry`,
    faIconName: 'fa-usd'
  }
  let ctrlTwrQuoteShipmentListLink = {
    name: 'Quote List',
    linkTo: `/${customerConfig.customerUrl}/wms/ctrltwr/quoteshipmentlist`,
    faIconName: 'fa-list'
  }
  let ctrlTwrQuoteReportLink = {
    name: 'Quote Report',
    linkTo: `/${customerConfig.customerUrl}/wms/ctrltwr/quotereport`,
    faIconName: 'fa-eye'
  }

  let ctrlTwrShipmentEntryLink = {
    name: 'Shipment Entry',
    linkTo: `/${customerConfig.customerUrl}/wms/ctrltwr/shipmententry`,
    faIconName: 'fa-solid fa-tag'
  }
  let ctrlTwrMilestoneEventEntryLink = {
    name: 'Milestone Event Entry',
    linkTo: `/${customerConfig.customerUrl}/wms/ctrltwr/milestoneevententry?status=0`,
    faIconName: 'fa-map-marker'
  }
  let ctrlTwrMultiMilestoneEventEntryLink = {
    name: 'Multi Milestone Event Entry',
    linkTo: `/${customerConfig.customerUrl}/wms/ctrltwr/multimilestoneevententry?status=0`,
    faIconName: 'fa-map-marker'
  }
  let ctrlTwrShipmentManagementLink = {
    name: 'Shipment Management',
    linkTo: `/${customerConfig.customerUrl}/wms/ctrltwr/shipmentmanagement?status=0`,
    faIconName: 'fa-list-alt'
  }
  let ctrlTwrShipmentListLink = {
    name: 'Shipment List',
    linkTo: `/${customerConfig.customerUrl}/wms/ctrltwr/shipmentlist?status=0`,
    faIconName: 'fa-ship'
  }
  let ctrlTwrLoadTenderLink = {
    name: 'Load Tender',
    linkTo: `/${customerConfig.customerUrl}/wms/ctrltwr/loadtender?status=W`,
    faIconName: 'fa-exchange'
  }

  //Label Generation Links 
  let labelGenLabelGenerationLink = {
    name: 'Label Generation',
    linkTo: `/${customerConfig.customerUrl}/wms/labelgen/labelgeneration`,
    faIconName: 'fa-tag'
  }
  // let labelGenBulkLabelGenerationLink = {
  //   name: 'Bulk Label History',
  //   linkTo: `/${customerConfig.customerUrl}/wms/labelgen/bulklabelgeneration`,
  //   faIconName: 'fa-tags'
  // }
  let labelGenLabelGenHistoryLink = {
    name: 'Label History',
    linkTo: `/${customerConfig.customerUrl}/wms/labelgen/labelgenhistory`,
    faIconName: 'fa-history'
  }
  let labelGenLabelConfigurationLink = {
    name: 'Configuration',
    linkTo: `/${customerConfig.customerUrl}/wms/labelgen/configuration`,
    faIconName: 'fa-cogs'
  }

  //Label Gen External Links
  let labelGenExternalParcelTrackingLink = {
    name: 'Parcel Tracking',
    linkTo: `/${customerConfig.customerUrl}/wms/labelgen/parceltracking`,
    faIconName: 'fa-truck'
  }

  //Lot Tracking Inventory Management Route

  let invManInboundLotLink = {
    name: 'Inbound',
    linkTo: `/${customerConfig.customerUrl}/wms/inventorymanagement/inboundlot`,
    faIconName: 'fa-ship'
  }
  let invManInboundLink = {
    name: 'Inbound',
    linkTo: `/${customerConfig.customerUrl}/wms/inventorymanagement/inbound`,
    faIconName: 'fa-ship'
  }
  let invManInventoryPalletCaseSkuLink = {
    name: 'Inventory',
    // linkTo: `/${customerConfig.customerUrl}/wms/inventorymanagement/inventorypcs`,
    linkTo: `/${customerConfig.customerUrl}/wms/inventorymanagement/inventorypcs?statusid=1,2&reportnav=pallet`,
    faIconName: 'fa-solid fa-tag'
  }


  let invManWarehouseLocationLotLink = {
    name: 'Warehouse',
    linkTo: `/${customerConfig.customerUrl}/wms/inventorymanagement/warehouselocationlot?statusid=1,2,&reportnav=pallet`,
    faIconName: 'fa-home'
  }

  let invManInventorySkuLink = {
    name: 'SKU Level',
    // linkTo: `/${customerConfig.customerUrl}/wms/inventorymanagement/inventorypcs`,
    linkTo: `/${customerConfig.customerUrl}/wms/inventorymanagement/inventorysku?statusid=1,2`,
    faIconName: 'fa-solid fa-file'
  }


  let invManBarcodeScanningLink = {
    name: 'Barcode Scanning',
    linkTo: `/${customerConfig.customerUrl}/wms/inventorymanagement/barcodescanning`,
    faIconName: 'fa-solid fa-barcode'
  }

  let invManLoadBuildByScanningLink = {
    name: 'Load Scanning',
    linkTo: `/${customerConfig.customerUrl}/wms/inventorymanagement/loadscanning`,
    faIconName: 'fa-solid fa-barcode'
  }

  let invManLotOutboundLink = {
    name: 'Outbound',
    linkTo: `/${customerConfig.customerUrl}/wms/inventorymanagement/lotoutbound`,
    faIconName: 'fa-paper-plane'
  }
  let invManLotReportingLink = {
    name: 'Reporting',
    linkTo: `/${customerConfig.customerUrl}/wms/inventorymanagement/lotreporting`,
    faIconName: 'fa-eye'
  }

  //Inventory Management Route

  let invManInventoryLink = {
    name: 'Inventory',
    linkTo: `/${customerConfig.customerUrl}/wms/inventorymanagement/inventory`,
    faIconName: 'fa-solid fa-tag'
  }


  let invManLotLoadLink = {
    name: 'Load',
    linkTo: `/${customerConfig.customerUrl}/wms/inventorymanagement/lotload?loadstatus=1`,
    faIconName: 'fa-truck'
  }


  let invManWarehouseLink = {
    name: 'Warehouse',
    linkTo: `/${customerConfig.customerUrl}/wms/inventorymanagement/warehouse`,
    faIconName: 'fa-home'
  }
  let invManLoadLink = {
    name: 'Load',
    linkTo: `/${customerConfig.customerUrl}/wms/inventorymanagement/load?loadstatus=1`,
    faIconName: 'fa-truck'
  }
  let invManOutboundLink = {
    name: 'Outbound',
    linkTo: `/${customerConfig.customerUrl}/wms/inventorymanagement/outbound`,
    faIconName: 'fa-paper-plane'
  }
  let invManReportingLink = {
    name: 'Reporting',
    linkTo: `/${customerConfig.customerUrl}/wms/inventorymanagement/reporting`,
    faIconName: 'fa-eye'
  }


  //Visibility Links 
  let visibilityDashboardLink = {
    name: 'Dashboard',
    linkTo: `/${customerConfig.customerUrl}/wms/visibility/dashboard`,
    faIconName: 'fa-eye'
  }
  let visibilityTrackingLink = {
    name: 'Tracking',
    linkTo: `/${customerConfig.customerUrl}/wms/visibility/tracking`,
    faIconName: 'fa-truck'
  }
  let visibilityReportingLink = {
    name: 'Reporting',
    linkTo: `/${customerConfig.customerUrl}/wms/visibility/reporting`,
    faIconName: 'fa-eye'
  }


  //Customs Brokerage Links 
  let customsProductCatalogLink = {
    name: 'Product Catalog',
    linkTo: `/${customerConfig.customerUrl}/wms/customs/productcatalog`,
    faIconName: 'fa-book'
  }


  //User Links
  let userManagementLink = {
    name: 'User Management',
    linkTo: `/${customerConfig.customerUrl}/wms/usermanagement`,
    faIconName: 'fa-users'
  }
  let myProfileLink = {
    name: 'My Profile',
    linkTo: `/${customerConfig.customerUrl}/wms/myprofile`,
    faIconName: 'fa-user'
  }
  let appConfigurationLink = {
    name: 'App Configuration',
    linkTo: `/${customerConfig.customerUrl}/wms/appconfiguration`,
    faIconName: 'fa-cogs'
  }



  ////////////////3: Add Route and Link to permissions matrix (this controls link order):

  let routePath = [

    // { "navRoute": creates url route, 
    // "navLink": clickable menu link or null,  
    // "module": add module name, 
    // "customerCode": ["All or specify code"], 
    // "warehouseCode": ["All or specify code"], 
    // "siteAdmin": [true or false] },

    //General Routes
    { "navRoute": vasContent, "navLink": null, "module": ["home"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },

    //User Routes
    { "navRoute": myprofile, "navLink": myProfileLink, "module": ["user"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    { "navRoute": usermanagement, "navLink": userManagementLink, "module": ["user"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true], "organization": ["All"] },
    { "navRoute": signup, "navLink": null, "module": ["user"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    { "navRoute": userchangepassword, "navLink": null, "module": ["user"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    { "navRoute": accountDetailsRoute, "navLink": null, "module": ["user"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    { "navRoute": appConfigurationRoute, "navLink": appConfigurationLink, "module": ["user"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true], "organization": [customerConfig.customerName] },


    // //Vas Routes
    // { "navRoute": dashboardRoute, "navLink": dashboardLink, "module": ["XD"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true], "organization": ["All"] },
    // { "navRoute": shipmentRoute, "navLink": shipmentLink, "module": ["XD"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    // { "navRoute": warehouseRoute, "navLink": warehouseLink, "module": ["XD"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    // { "navRoute": loadRoute, "navLink": loadLink, "module": ["XD"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    // { "navRoute": masterDetailsRoute, "navLink": null, "module": ["XD"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    // { "navRoute": houseDetailsRoute, "navLink": null, "module": ["XD"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    // { "navRoute": loadDetailsRoute, "navLink": null, "module": ["XD"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    // { "navRoute": outboundRoute, "navLink": outboundLink, "module": ["XD"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },

    // //Trans Routes
    // { "navRoute": transShipmentRoute, "navLink": transShipmentLink, "module": ["TS"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    // { "navRoute": transContainerDetailsRoute, "navLink": null, "module": ["TS"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    // { "navRoute": transWarehouseRoute, "navLink": transwarehouseLink, "module": ["TS"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    // { "navRoute": transPoDetailsRoute, "navLink": null, "module": ["TS"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    // { "navRoute": transLoadRoute, "navLink": transLoadLink, "module": ["TS"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    // { "navRoute": transLoadDetailsRoute, "navLink": null, "module": ["TS"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    // { "navRoute": transOutboundRoute, "navLink": transOutboundLink, "module": ["TS"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },

    // //Transportation Routes
    // { "navRoute": tpPickupRoute, "navLink": tpPickupLink, "module": ["TP"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },


    // //Control Tower Routes
    // { "navRoute": ctrlTwrDashboardRoute, "navLink": ctrlTwrDashboardLink, "module": ["CT"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },

    // { "navRoute": ctrlTwrQuoteEntryRoute, "navLink": ctrlTwrQuoteEntryLink, "module": ["CT"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true], "organization": [customerConfig.adminAccessCustomer] },
    // { "navRoute": ctrlTwrQuoteShipmentListRoute, "navLink": ctrlTwrQuoteShipmentListLink, "module": ["CT"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true], "organization": [customerConfig.adminAccessCustomer] },
    // { "navRoute": ctrlTwrQuoteShipmentDetailsRoute, "navLink": null, "module": ["CT"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true], "organization": [customerConfig.adminAccessCustomer] },
    // { "navRoute": ctrlTwrQuoteReportRoute, "navLink": ctrlTwrQuoteReportLink, "module": ["CT"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true], "organization": [customerConfig.adminAccessCustomer] },

    // { "navRoute": ctrlTwrShipmentEntryRoute, "navLink": ctrlTwrShipmentEntryLink, "module": ["CT"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true], "organization": ["All"] },
    // { "navRoute": ctrlTwrLoadTenderRoute, "navLink": ctrlTwrLoadTenderLink, "module": ["CT"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true], "organization": ["All"] },
    // { "navRoute": ctrlTwrLoadTenderDetailsRoute, "navLink": null, "module": ["CT"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true], "organization": ["All"] },

    // { "navRoute": ctrlTwrMilestoneEventEntryRoute, "navLink": ctrlTwrMilestoneEventEntryLink, "module": ["CT"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true], "organization": ["All"] },
    // { "navRoute": ctrlTwrMultiMilestoneEventEntryRoute, "navLink": ctrlTwrMultiMilestoneEventEntryLink, "module": ["CT"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true], "organization": [customerConfig.adminAccessCustomer] },

    // { "navRoute": ctrlTwrShipmentManagementRoute, "navLink": ctrlTwrShipmentManagementLink, "module": ["CT"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true], "organization": ["All"] },
    // { "navRoute": ctrlTwrShipmentDetailsManagementRoute, "navLink": null, "module": ["CT"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true], "organization": ["All"] },

    // { "navRoute": ctrlTwrShipmentListRoute, "navLink": ctrlTwrShipmentListLink, "module": ["CT"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    // { "navRoute": ctrlTwrShipmentDetailsRoute, "navLink": null, "module": ["CT"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },



    // //Label Generation Routes
    // { "navRoute": labelGenLabelGenerationRoute, "navLink": labelGenLabelGenerationLink, "module": ["LG"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    // { "navRoute": labelGenLabelGenHistortyRoute, "navLink": labelGenLabelGenHistoryLink, "module": ["LG"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },

    // //Label Gen External Routes
    // { "navRoute": labelGenExternalParcelTrackingRoute, "navLink": labelGenExternalParcelTrackingLink, "module": ["EL"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },


    //Lot Tracking Inventory Management Route
    //Lot Tracking
    { "navRoute": invManInboundLotRoute, "navLink": invManInboundLotLink, "module": ["IL"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    { "navRoute": invManLotContainerDetailRoute, "navLink": null, "module": ["IL"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    { "navRoute": invManWarehouseLocationLotRoute, "navLink": invManWarehouseLocationLotLink, "module": ["IL"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    { "navRoute": invManInventoryPalletCaseSkuRoute, "navLink": invManInventoryPalletCaseSkuLink, "module": ["IL"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    { "navRoute": invManInventorySkuRoute, "navLink": invManInventorySkuLink, "module": ["IL"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    { "navRoute": invManBarcodeScanningRoute, "navLink": invManBarcodeScanningLink, "module": ["IL"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    { "navRoute": invManLoadBuildByScanningRoute, "navLink": invManLoadBuildByScanningLink, "module": ["IL"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    { "navRoute": invManLotLoadRoute, "navLink": invManLotLoadLink, "module": ["IL"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    { "navRoute": invManLotOutboundRoute, "navLink": invManLotOutboundLink, "module": ["IL"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    { "navRoute": invManLotReportingRoute, "navLink": invManLotReportingLink, "module": ["IL"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    { "navRoute": invManLotLoadDetailRoute, "navLink": null, "module": ["IL"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    { "navRoute": invManLotSkuDetailRoute, "navLink": null, "module": ["IL"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    { "navRoute": invManUnitDetailRoute, "navLink": null, "module": ["IL"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    { "navRoute": invManCaseDetailRoute, "navLink": null, "module": ["IL"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    { "navRoute": invManPalletDetailRoute, "navLink": null, "module": ["IL"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },

    { "navRoute": invManSkuDetailRoute, "navLink": null, "module": ["IL"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    { "navRoute": invManLoadDetailRoute, "navLink": null, "module": ["IL"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    { "navRoute": invManWarehouseLocationDetailsRoute, "navLink": null, "module": ["IL"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },

    // //Inventory Management Route
    // { "navRoute": invManInboundRoute, "navLink": invManInboundLink, "module": ["IM"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    // { "navRoute": invManInventoryRoute, "navLink": invManInventoryLink, "module": ["IM"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    // { "navRoute": invManWarehouseRoute, "navLink": invManWarehouseLink, "module": ["IM"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    // { "navRoute": invManLoadRoute, "navLink": invManLoadLink, "module": ["IM"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    // { "navRoute": invManOutboundRoute, "navLink": invManOutboundLink, "module": ["IM"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    // { "navRoute": invManReportingRoute, "navLink": invManReportingLink, "module": ["IM"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    // { "navRoute": invManSkuDetailRoute, "navLink": null, "module": ["IM"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    // { "navRoute": invManContainerDetailRoute, "navLink": null, "module": ["IM"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    // { "navRoute": invManLoadDetailRoute, "navLink": null, "module": ["IM"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },
    // { "navRoute": invManWarehouseLocationDetailsRoute, "navLink": null, "module": ["IM"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },


    // //Visibility Routes
    // { "navRoute": visibilityDashboardRoute, "navLink": visibilityDashboardLink, "module": ["VS"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true], "organization": [customerConfig.adminAccessCustomer] },
    // { "navRoute": visibilityPackageDetailsRoute, "navLink": null, "module": ["VS"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true], "organization": [customerConfig.adminAccessCustomer] },
    // { "navRoute": visibilityTrackingRoute, "navLink": visibilityTrackingLink, "module": ["VS"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true], "organization": [customerConfig.adminAccessCustomer] },
    // { "navRoute": visibilityReportingRoute, "navLink": visibilityReportingLink, "module": ["VS"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true], "organization": [customerConfig.adminAccessCustomer] },



    // //Customs Brokerage Routes
    // { "navRoute": customsProductCatalogRoute, "navLink": customsProductCatalogLink, "module": ["CB"], "customerCode": ["All"], "warehouseCode": ["All"], "siteAdmin": [true, false], "organization": ["All"] },

  ]


  ////////////////////////////////////////////    





  var customerList = [];
  if (typeof (customerArray) !== 'undefined' && customerArray != null) {

    for (var key in customerArray) {
      customerList.push(customerArray[key]['CustomerCode']);
    }
  }

  var warehouseList = [];
  if (typeof (warehouseArray) !== 'undefined' && warehouseArray != null) {

    for (var key in warehouseArray) {
      warehouseList.push(warehouseArray[key]['WarehouseCode']);
    }
  }

  var moduleList = [];
  if (typeof (moduleArray) !== 'undefined' && moduleArray != null) {

    for (var key in moduleArray) {
      moduleList.push(moduleArray[key]['ModuleCode']);
    }
  }

  var organizationList = [];
  if (typeof (customerArray) !== 'undefined' && customerArray != null) {

    for (var key in customerArray) {
      organizationList.push(organizationSelection);
    }
  }




  if (filter == 'route') {
    let filteredRoute = routePath.filter(x => x.siteAdmin.includes(admin)
      && (x.warehouseCode.includes("All") || x.warehouseCode.some(r => warehouseList.includes(r)))
      && (x.customerCode.includes("All") || x.customerCode.some(r => customerList.includes(r)))
      && (x.organization.includes("All") || x.organization.some(r => organizationSelection.includes(r)))
    )
    return filteredRoute
  }

  else if (filter == 'userLink') {
    let filteredUserLink = routePath.filter(x => x.module.includes("user")
      && x.siteAdmin.includes(admin)
      && (x.warehouseCode.includes("All") || x.warehouseCode.some(r => warehouseList.includes(r)))
      && (x.customerCode.includes("All") || x.customerCode.some(r => customerList.includes(r)))
      && (x.organization.includes("All") || x.organization.some(r => organizationSelection.includes(r)))
      && x.navLink != null)
    return filteredUserLink
  }

  else if (filter == 'vasLink') {
    let filteredMenuLink = routePath.filter(x => x.module.includes("XD")
      && x.siteAdmin.includes(admin)
      && x.module.some(r => moduleList.includes(r))
      && (x.warehouseCode.includes("All") || x.warehouseCode.some(r => warehouseList.includes(r)))
      && (x.customerCode.includes("All") || x.customerCode.some(r => customerList.includes(r)))
      && (x.organization.includes("All") || x.organization.some(r => organizationSelection.includes(r)))
      && x.navLink != null)
    return filteredMenuLink
  }


  else if (filter == 'transLink') {
    let filteredMenuLink = routePath.filter(x => x.module.includes("TS")
      && x.siteAdmin.includes(admin)
      && x.module.some(r => moduleList.includes(r))
      && (x.warehouseCode.includes("All") || x.warehouseCode.some(r => warehouseList.includes(r)))
      && (x.customerCode.includes("All") || x.customerCode.some(r => customerList.includes(r)))
      && (x.organization.includes("All") || x.organization.some(r => organizationSelection.includes(r)))
      && x.navLink != null)
    return filteredMenuLink
  }


  else if (filter == 'transportationLink') {
    let filteredMenuLink = routePath.filter(x => x.module.includes("TP")
      && x.siteAdmin.includes(admin)
      && x.module.some(r => moduleList.includes(r))
      && (x.warehouseCode.includes("All") || x.warehouseCode.some(r => warehouseList.includes(r)))
      && (x.customerCode.includes("All") || x.customerCode.some(r => customerList.includes(r)))
      && (x.organization.includes("All") || x.organization.some(r => organizationSelection.includes(r)))
      && x.navLink != null)
    return filteredMenuLink
  }

  else if (filter == 'controlTowerLink') {
    let filteredMenuLink = routePath.filter(x => x.module.includes("CT")
      && x.siteAdmin.includes(admin)
      && x.module.some(r => moduleList.includes(r))
      && (x.warehouseCode.includes("All") || x.warehouseCode.some(r => warehouseList.includes(r)))
      && (x.customerCode.includes("All") || x.customerCode.some(r => customerList.includes(r)))
      && (x.organization.includes("All") || x.organization.some(r => organizationSelection.includes(r)))
      && x.navLink != null)
    return filteredMenuLink
  }

  else if (filter == 'labelGenLink') {
    let filteredMenuLink = routePath.filter(x => x.module.includes("LG")
      && x.siteAdmin.includes(admin)
      && x.module.some(r => moduleList.includes(r))
      && (x.warehouseCode.includes("All") || x.warehouseCode.some(r => warehouseList.includes(r)))
      && (x.customerCode.includes("All") || x.customerCode.some(r => customerList.includes(r)))
      && (x.organization.includes("All") || x.organization.some(r => organizationSelection.includes(r)))
      && x.navLink != null)
    return filteredMenuLink
  }

  else if (filter == 'externalLabelGenLink') {
    let filteredMenuLink = routePath.filter(x => x.module.includes("EL")
      && x.siteAdmin.includes(admin)
      && x.module.some(r => moduleList.includes(r))
      && (x.warehouseCode.includes("All") || x.warehouseCode.some(r => warehouseList.includes(r)))
      && (x.customerCode.includes("All") || x.customerCode.some(r => customerList.includes(r)))
      && (x.organization.includes("All") || x.organization.some(r => organizationSelection.includes(r)))
      && x.navLink != null)
    return filteredMenuLink
  }

  else if (filter == 'lotInvManLink') {
    let filteredMenuLink = routePath.filter(x => x.module.includes("IL")
      && x.siteAdmin.includes(admin)
      && x.module.some(r => moduleList.includes(r))
      && (x.warehouseCode.includes("All") || x.warehouseCode.some(r => warehouseList.includes(r)))
      && (x.customerCode.includes("All") || x.customerCode.some(r => customerList.includes(r)))
      && (x.organization.includes("All") || x.organization.some(r => organizationSelection.includes(r)))
      && x.navLink != null)
    return filteredMenuLink
  }

  else if (filter == 'invManLink') {
    let filteredMenuLink = routePath.filter(x => x.module.includes("IM")
      && x.siteAdmin.includes(admin)
      && x.module.some(r => moduleList.includes(r))
      && (x.warehouseCode.includes("All") || x.warehouseCode.some(r => warehouseList.includes(r)))
      && (x.customerCode.includes("All") || x.customerCode.some(r => customerList.includes(r)))
      && (x.organization.includes("All") || x.organization.some(r => organizationSelection.includes(r)))
      && x.navLink != null)
    return filteredMenuLink
  }

  else if (filter == 'visibilityLink') {
    let filteredMenuLink = routePath.filter(x => x.module.includes("VS")
      && x.siteAdmin.includes(admin)
      && x.module.some(r => moduleList.includes(r))
      && (x.warehouseCode.includes("All") || x.warehouseCode.some(r => warehouseList.includes(r)))
      && (x.customerCode.includes("All") || x.customerCode.some(r => customerList.includes(r)))
      && (x.organization.includes("All") || x.organization.some(r => organizationSelection.includes(r)))
      && x.navLink != null)
    return filteredMenuLink
  }


  else if (filter == 'customsLink') {
    let filteredMenuLink = routePath.filter(x => x.module.includes("CB")
      && x.siteAdmin.includes(admin)
      && x.module.some(r => moduleList.includes(r))
      && (x.warehouseCode.includes("All") || x.warehouseCode.some(r => warehouseList.includes(r)))
      && (x.customerCode.includes("All") || x.customerCode.some(r => customerList.includes(r)))
      && (x.organization.includes("All") || x.organization.some(r => organizationSelection.includes(r)))
      && x.navLink != null)
    return filteredMenuLink
  }

}


const navRoutes = (admin, role, warehouseArray, customerArray, moduleArray, organizationSelection) => {
  let filteredRoute = routePathFunc(admin, role, 'route', warehouseArray, customerArray, moduleArray, organizationSelection)
  return (
    filteredRoute.map((route, index) => (
      route.navRoute
    ))
  );
}



const navLinks = (admin, role, warehouseArray, customerArray, moduleArray, organizationSelection) => {



  let filteredVasLink = routePathFunc(admin, role, 'vasLink', warehouseArray, customerArray, moduleArray, organizationSelection)
  let vasLinks = filteredVasLink.map((route, index) => (route.navLink))

  let filteredTransLink = routePathFunc(admin, role, 'transLink', warehouseArray, customerArray, moduleArray, organizationSelection)
  let transLinks = filteredTransLink.map((route, index) => (route.navLink))

  let filteredTransportationLink = routePathFunc(admin, role, 'transportationLink', warehouseArray, customerArray, moduleArray, organizationSelection)
  let transportationLinks = filteredTransportationLink.map((route, index) => (route.navLink))

  let filteredControlTowerLink = routePathFunc(admin, role, 'controlTowerLink', warehouseArray, customerArray, moduleArray, organizationSelection)
  let controlTowerLinks = filteredControlTowerLink.map((route, index) => (route.navLink))

  let filteredLabelGenLink = routePathFunc(admin, role, 'labelGenLink', warehouseArray, customerArray, moduleArray, organizationSelection)
  let labelGenLinks = filteredLabelGenLink.map((route, index) => (route.navLink))

  let filteredExternalLabelGenLink = routePathFunc(admin, role, 'externalLabelGenLink', warehouseArray, customerArray, moduleArray, organizationSelection)
  let externalLabelGenLinks = filteredExternalLabelGenLink.map((route, index) => (route.navLink))





  let filteredLotInvManLink = routePathFunc(admin, role, 'lotInvManLink', warehouseArray, customerArray, moduleArray, organizationSelection)
  let lotInvManLinks = filteredLotInvManLink.map((route, index) => (route.navLink))

  let filteredInvManLink = routePathFunc(admin, role, 'invManLink', warehouseArray, customerArray, moduleArray, organizationSelection)
  let invManLinks = filteredInvManLink.map((route, index) => (route.navLink))

  let filteredVisibilityLink = routePathFunc(admin, role, 'visibilityLink', warehouseArray, customerArray, moduleArray, organizationSelection)
  let visibilityLinks = filteredVisibilityLink.map((route, index) => (route.navLink))

  let filteredCustomsLink = routePathFunc(admin, role, 'customsLink', warehouseArray, customerArray, moduleArray, organizationSelection)
  let customsLinks = filteredCustomsLink.map((route, index) => (route.navLink))


  let filteredUserLink = routePathFunc(admin, role, 'userLink', warehouseArray, customerArray, moduleArray, organizationSelection)
  let userLinks = filteredUserLink.map((route, index) => (route.navLink))

  let navMenuVas;
  if (vasLinks.length !== 0) {
    navMenuVas = {
      id: 5,
      group: 'Airfreight',
      menus: vasLinks
    }
  }

  let navMenuTrans;
  if (transLinks.length !== 0) {
    navMenuTrans = {
      id: 6,
      group: 'Transload',
      menus: transLinks
    }
  }

  let navMenuTransportation;
  if (transportationLinks.length !== 0) {
    navMenuTransportation = {
      id: 4,
      group: 'Transportation',
      menus: transportationLinks
    }
  }

  let navMenuControlTower;
  if (controlTowerLinks.length !== 0) {
    navMenuControlTower = {
      id: 1,
      group: 'Control Tower',
      menus: controlTowerLinks
    }
  }

  let navMenuLabelGen;
  if (labelGenLinks.length !== 0) {
    navMenuLabelGen = {
      id: 7,
      group: 'Label Generation',
      menus: labelGenLinks
    }
  }

  let navMenuExteranlLabelGen;
  if (externalLabelGenLinks.length !== 0) {
    navMenuExteranlLabelGen = {
      id: 8,
      group: 'Parcel Tracking',
      menus: externalLabelGenLinks
    }
  }



  let navMenuLotInvMan;
  if (lotInvManLinks.length !== 0) {
    navMenuLotInvMan = {
      id: 2,
      group: 'Lot Tracking',
      menus: lotInvManLinks
    }
  }

  let navMenuInvMan;
  if (invManLinks.length !== 0) {
    navMenuInvMan = {
      id: 3,
      group: 'Inventory Management',
      menus: invManLinks
    }
  }

  let navMenuVisibility;
  if (visibilityLinks.length !== 0) {
    navMenuVisibility = {
      id: 9,
      group: 'Visibility',
      menus: visibilityLinks
    }
  }

  let navMenuCustoms;
  if (customsLinks.length !== 0) {
    navMenuCustoms = {
      id: 10,
      group: 'Customs Brokerage',
      menus: customsLinks
    }
  }

  let userDash;
  userDash = {
    id: 11,
    group: 'User',
    menus: userLinks
  }

  let productsMenu;
  productsMenu = [
    navMenuControlTower,
    navMenuLotInvMan,
    navMenuInvMan,
    navMenuTransportation,
    navMenuVas,
    navMenuTrans,
    navMenuLabelGen,
    navMenuExteranlLabelGen,
    navMenuVisibility,
    navMenuCustoms,
    userDash
  ]

  let sidemenu = productsMenu.filter(x => x != undefined)

  return (sidemenu)

}


export const MainRoutes = ({
  admin,
  role,
  warehouseArray,
  customerArray,
  moduleArray,
  organizationSelection
}) => (

  <Switch>
    <PrivateRoute exact path={`/${customerConfig.customerUrl}/auth`} component={SignInAuthView} />
    {navRoutes(admin, role, warehouseArray, customerArray, moduleArray, organizationSelection)}
    <Route path="*" component={pageNotFound} />
  </Switch>

);
MainRoutes.propTypes = {
  admin: PropTypes.bool,     //if user account is pending approval
  role: PropTypes.string     //if user has admin rights
};


export const NavigationLinks = ({
  // navSelection,
  admin,
  role,
  warehouseArray,
  customerArray,
  moduleArray,
  organizationSelection
}) => (

  // navLinks(navSelection, admin, role)
  navLinks(admin, role, warehouseArray, customerArray, moduleArray, organizationSelection)

);
NavigationLinks.propTypes = {
  // navSelection:           PropTypes.string,  
  admin: PropTypes.bool,     //if user account is pending approval
  role: PropTypes.arrayOf(
    PropTypes.shape(
      {
        Admin: PropTypes.string,
        RoleID: PropTypes.string,
        RoleName: PropTypes.string
      }
    )
  ),

};






