import * as moment from 'moment';
import React, {
    PureComponent
} from 'react';
import ReactExport from "react-export-excel";
import { withRouter } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ClearIcon from '@material-ui/icons/Clear';
import { formatStringForAPI } from '../../../resources/logic/functions';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

// const styles = {
//   marginBottom: '10px',
//   width: '100%'
// };

const styles = theme => ({
  header: {
    marginBottom: theme.spacing(2), // Add bottom margin
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: theme.spacing(2),
  },
  textField: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(3),
    width: '100%',
  },
  button: {
    width: '100%',
    margin: theme.spacing(1),
    marginBottom: theme.spacing(3), // Increase bottom margin
  },
  textFieldContainer: {
    position: 'relative',
  },
  clearButton: {
    position: 'absolute',
    right: theme.spacing(1),
    bottom: theme.spacing(1),
    width: 32,
    height: 32,
  },
  paper: {
    margin: theme.spacing(1),
    // padding: theme.spacing(2),
    width: '100%',
  },

});

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")
const tableIdCol = 'TrackingNumber';

class LabelGenExternalParcelTracking extends PureComponent {
  constructor() {
    super();
    this.state = {

      // Table Check Box
      checkedKeys: [],


      trackingNumbers: '',
      downloadUrls: [],


    };

    // Table Check Box
    this.handleCheckAll = this.handleCheckAll.bind(this);
    this.handleCheck = this.handleCheck.bind(this);

    // Table Sort
    this.handleSortColumn = this.handleSortColumn.bind(this);

    //Filter hide Show
    // this.onClickIsFilterShown = this.onClickIsFilterShown.bind(this);
    // this.onClickResetFilters = this.onClickResetFilters.bind(this);


    // this.onClickDownloadLabel = this.onClickDownloadLabel.bind(this);


  }




  componentDidMount() {
    this.loadSortFromStorage()

  }



  componentDidUpdate(prevProps, prevState) {



    // // Add the URL to the state
    // this.setState(prevState => ({
    //   downloadUrls: [...prevState.downloadUrls, { url, msg }]
    // }));


    // Check if LabelGenExternalUpsTrackingData has changed
    if (this.props.LabelGenExternalUpsTrackingData !== prevProps.LabelGenExternalUpsTrackingData) {
      const data = this.props.LabelGenExternalUpsTrackingData;

      // Add the URL to the state
      this.setState(prevState => ({
        downloadUrls: [...prevState.downloadUrls, { url: data.URL, msg: data.MSG }]
      }));
    }


  }









  // Table Check Box
  handleCheckAll(value, checked, data) {
    const checkedKeys = checked ? [] : data.map(item => item[tableIdCol]);
    this.setState({
      checkedKeys
    });
  }
  handleCheck(value, checked) {
    const { checkedKeys } = this.state;
    const nextCheckedKeys = checked
      ? [...checkedKeys, value]
      : checkedKeys.filter(item => item !== value);

    this.setState({
      checkedKeys: nextCheckedKeys
    });
  }

  // Table Sort
  saveSortTosessionStorage(sortColumn, sortType) {
    try {
      sessionStorage.setItem('sortcolumn', sortColumn)
    }
    catch (e) { }
    try {
      sessionStorage.setItem('sortType', sortType)
    }
    catch (e) { }
    try {
      sessionStorage.setItem('sortURLPathname', this.props.location.pathname)
    }
    catch (e) { }
  }
  loadSortFromStorage() {
    var sortColumn = null
    var sortType = null
    var pathName = null
    try {
      sortColumn = sessionStorage.getItem('sortcolumn')
    }
    catch (e) { }
    try {
      sortType = sessionStorage.getItem('sortType')
    }
    catch (e) { }
    try {
      pathName = sessionStorage.getItem('sortURLPathname')
    }
    catch (e) { }
    if (pathName === this.props.location.pathname) {
      this.handleSortColumn(sortColumn, sortType)
    }
  }
  handleSortColumn(sortColumn, sortType) {
    this.setState({
      loading: true
    });
    this.saveSortTosessionStorage(sortColumn, sortType)
    setTimeout(() => {
      this.setState({
        sortColumn,
        sortType,
        loading: false
      });
    }, 100);
  }







  // onClickDownloadLabel(e, rowData, history) {
  //   try {
  //     var fileNameToDownload = rowData['LabelFilename']
  //     var fileNameToSave = rowData['TrackingNumber'] + '.' + rowData['LabelSpec_LabelImageFormat_Code']
  //     this.props.downloadFileFromS3(this.props.currentUserEmail, this.props.userSignInData.AuthToken, fileNameToDownload, fileNameToSave, appAPI.LabelGenDownloadS3)
  //   }
  //   catch (e) { }
  // }











  handleTrackingNumbersChange = (event) => {
    let trackingNumbers = event.target.value;

    // Only allow numbers, letters, spaces, commas, and enters
    trackingNumbers = trackingNumbers.replace(/[^a-zA-Z0-9 ,\n]/g, '');

    // Convert lowercase letters to uppercase
    trackingNumbers = trackingNumbers.toUpperCase();

    this.setState({ trackingNumbers });
  };

  // handleSubmit = () => {
  //   const trackingNumbersJson = JSON.stringify(this.state.trackingNumbers.split(','));
  //   // Pass trackingNumbersJson to the action
  // };



  handleSubmit = () => {
    const { trackingNumbers } = this.state;

    // Format the input
    const formattedInput = trackingNumbers
      .trim() // Remove leading and trailing whitespace
      .replace(/[\s,]+/g, ',') // Replace any sequence of spaces, enters, or commas with a single comma
      .replace(/^,|,$/g, ''); // Remove leading and trailing commas


    // Call the action
    this.props.labelGenExternalUpsTrackingGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, null, formatStringForAPI(formattedInput), null)



    // // Add the URL to the state
    // this.setState(prevState => ({
    //   downloadUrls: [...prevState.downloadUrls, { url, msg }]
    // }));

    // // Auto-download the file
    // const link = document.createElement('a');
    // link.href = url;
    // link.download = msg;
    // link.click();



  };


  handleClear = () => {
    this.setState({ trackingNumbers: '' });
  };

  render() {
    const { classes } = this.props;
    const { trackingNumbers, downloadUrls } = this.state;


    // render() {
    //   const { classes } = this.props;
    //   const { trackingNumbers, downloadUrls } = this.state;

    //   return (
    //     <div className={classes.container}>
    //       {/* Your existing code... */}

    //       <ul>
    //         {downloadUrls.map(({ url, msg }, index) => (
    //           <li key={index}>
    //             <a href={url} download={msg}>{msg}</a>
    //           </li>
    //         ))}
    //       </ul>
    //     </div>
    //   );
    // };


    return (
      <div className={classes.container}>
        <h3 className={classes.header}>Parcel Tracking</h3>

        {this.props.isLabelGenExternalUpsTrackingLoading ? (
          <Box display="flex" flexDirection="column" alignItems="center">
            <CircularProgress />
            <Typography>Loading...</Typography>
          </Box>
        ) : (
          <div>
            <div className={classes.textFieldContainer}>
              <TextField
                className={classes.textField}
                value={trackingNumbers}
                onChange={this.handleTrackingNumbersChange}
                label="Enter Tracking Numbers."
                variant="outlined"
                multiline
                rows={5}
                rowsMax={20}
                InputProps={{
                  endAdornment: trackingNumbers && (
                    <Tooltip title="Clear input">
                      <IconButton
                        className={classes.clearButton}
                        onClick={this.handleClear}
                      >
                        <ClearIcon />
                      </IconButton>
                    </Tooltip>
                  )
                }}
              />
            </div>
            {trackingNumbers && (
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={this.handleSubmit}
              >
                Submit
              </Button>
            )}

            {downloadUrls.length > 0 && (
              <div className={classes.paper}>
                <Paper elevation={3} style={{ padding: '10px' }}>
                  {/* <h3>Download Links</h3> */}
                  <ul>
                    {downloadUrls.map(({ url, msg }, index) => (
                      <li key={index}>
                        <a href={url} download={msg}>{msg}</a>
                      </li>
                    ))}
                  </ul>
                </Paper>
              </div>
            )}


          </div>
        )}




      </div>
    )
  }
}



// export default withRouter(LabelGenExternalParcelTracking);
export default withRouter(withStyles(styles)(LabelGenExternalParcelTracking));

{/* <ul>
{downloadUrls.map(({ url, msg }, index) => (
  <li key={index}>
    <a href={url} download={msg}>{msg}</a>
  </li>
))}
</ul> */}