export const emptyReceiveSkuDict = { 'SkuSeq': 0, 'SkuID': null, 'SKU': null, 'Units': 1, 'LotCode': '', 'SerialNum': '' }

export const emptyReceiveCaseDict = { 'CaseSeq': 1, 'Qty': 1, 'SkuID': null, 'SKU': null, 'LotCode': '', 'SerialNum': '', 'Notes': '', 'Skus': [] }

export const emptyReceivePalletDict = { 'PalletID': null, 'PalletSeq': 1, 'WarehouseLocationID': null, 'Oversize': false,'Length': 48,'Width': 40, 'Qty': 1, 'Notes': '', 'Cases': [emptyReceiveCaseDict] }

// Pallets
// [PalletID]
//       ,[PalletBarcode]
//       ,[PalletStatusID]
//       ,[WarehouseID]
//       ,[CustomerID]
//       ,[CreatedByUserID]
//       ,[PalletCreateDT]
//       ,[Notes]
//       ,[Oversize]
//       ,[WarehouseLocationID]




// Cases
//       [CaseID]
//       ,[CaseBarcode]
//       ,[CaseStatusID]
//       ,[WarehouseID]
//       ,[CustomerID]
//       ,[CreatedByUserID]
//       ,[CaseCreateDT]
//       ,[Notes]


// SKUs
//       [CaseSkuID]
//       ,[CaseID]
//       ,[SkuID]
//       ,[Units]
//       ,[CreatedDT]
//       ,[CreatedByUserID]



