export const InventoryLotReportingStorageDataElements = [
    // {
    //     dBKey: 'DT',
    //     displayName: 'DT',
    //     primaryCol: true,
    //     mobileDisplay: true,
    //     sortable: true,
    //     colWidth: 150
    // },
    {
        dBKey: 'CustomerName',
        displayName: 'Customer',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 140
    },
    {
        dBKey: 'WarehouseName',
        displayName: 'Warehouse',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 140
    },

    {
        dBKey: 'PalletBarcode',
        displayName: 'Pallet',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 230
    },
    {
        dBKey: 'Oversize',
        displayName: 'Oversize',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 140
    },

    {
        dBKey: 'StartDT',
        displayName: 'Start DT',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },

    {
        dBKey: 'EndDT',
        displayName: 'End DT',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'DaysInWhse',
        displayName: 'Days In Whse',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 140
    },

    {
        dBKey: 'SKU',
        displayName: 'SKU',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 300
    },
    {
        dBKey: 'Project',
        displayName: 'Project',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 400
    },

]

export default InventoryLotReportingStorageDataElements