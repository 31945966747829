export const InventoryReportingStorageDataElements = [
    {
        dBKey: 'DT',
        displayName: 'DT',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'CustomerName',
        displayName: 'Customer',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 140
    },
    {
        dBKey: 'WarehouseName',
        displayName: 'Warehouse',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 140
    },
    {
        dBKey: 'SKU',
        displayName: 'SKU',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 140
    },
    {
        dBKey: 'Project',
        displayName: 'Project',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 140
    },
    {
        dBKey: 'WarehouseLocationName',
        displayName: 'Warehouse Location',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'Units',
        displayName: 'Units',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'Cases',
        displayName: 'Cases',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'Pallets',
        displayName: 'Pallets',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'Oversize',
        displayName: 'Oversize',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 140
    },
    {
        dBKey: 'ActualRecoveryDate',
        displayName: 'Actual Recovery Date',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'DateRecoveryEdited',
        displayName: 'Date of Recovery Edit',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
]

export default InventoryReportingStorageDataElements