import React from 'react';
import { ControlLabel, InputPicker, Tooltip } from 'rsuite';
import { queryStringParse } from '../../../../resources/logic/functions';


const styles = {
  // width: 300,
  marginBottom: 10
};

const tooltipSearch = (
  <Tooltip>
    Search
  </Tooltip>
);

const tooltipClear = (
  <Tooltip>
    Clear
  </Tooltip>
);

function onClickSearch(e, history, querySearch, searchValue) {
  if (searchValue != '') {
    var param = null
    param = queryStringParse(searchValue)
    try {
      history.push({
        search: querySearch + '=\'' + param + '\''
      });
    }
    catch (e) { }
  }
  else {
    window.alert('Enter Search Value')
  }
}

// function onClickReset(e, history, onChange) {   
//   onChange('')   
//   try{
//       history.push({
//       }); 
//   }
//   catch(e){}
// }

function RSelectPicker(
  isMobile,
  data,
  value,
  label,
  onChange,
  history,
  mountRef
  // querySearch,
  // searchValue,
  // inputMask
) {

  let rInput;

  try {
    rInput = <div style={styles} >
      <ControlLabel>{label}</ControlLabel>

      <InputPicker
    preventOverflow
        placeholder={'Select...'}
        value={value}
        onChange={(e) => { onChange(e) }}
        data={data}
        block
        cleanable={true}
        container={() => mountRef.current}

      />

    </div>
  }
  catch (e) { }

  return (

    rInput

  )
}


export default RSelectPicker;





// [
//   {
//     "label": "Eugenia",
//     "value": "Eugenia",
//     "role": "Master"
//   },
//   {
//     "label": "Kariane",
//     "value": "Kariane",
//     "role": "Master"
//   },