import React, {
    PureComponent
} from 'react';
import Loader from 'react-loader-spinner';
import { withRouter } from 'react-router-dom';
import { Button, Col, Panel, PanelGroup, Row, Timeline, Tree } from 'rsuite';
// import ReactExport from "react-export-excel";
import * as moment from 'moment';
import ReactExport from "react-data-export";
import { appAPI } from '../../../app/appConfig';
import { convertNanToZero, formatDateForUrlSearch, formatDateTimeForUrlSearch, formatForExcelExport, formatNumberForAPI, formatStringForAPI, formatTrueFalseToBit, isNumeric, replaceNegativeWithZero } from '../../../resources/logic/functions';
import RTableGeneral from '../../componentsGeneral/Rsuite/Table/RTableGeneral';
import RUploader from '../../componentsGeneral/Rsuite/Uploader/RUploader';
import TransContainerFileUploadsDataElements from '../../dataComponentsTrans/TransContainerFileUploadsDataElements';
import TransContainerWarehouseLocationDataElements from '../../dataComponentsTrans/TransContainerWarehouseLocationDataElements';
import WarehouseDataElements from '../../dataComponentsTrans/WarehouseDataElements';
import EditPoDrawer from '../PoDetails/EditPoDrawer';
import EditWarehouseLocationDrawer from '../PoDetails/EditWarehouseLocationsDrawer';
import ReceiveContainerDrawer from '../Shipment/ReceiveContainerDrawer';
import EditContainerDrawer from './EditContainerDrawer';


const tableIdCol = "ContainerID"

//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")


class ContainerDetails extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

            //Add To Container Values
            MBOL: null,
            HBOL: null,
            PO: null,

            selectedType: null,
            selectedLabel: null,
            selectedID: null,
            addNewText: null,

            newPoCases: null,
            newPoPallets: null,
            newPoNotes: null,
            newPoCustomerRef: null,
            newPoProject: null,

            selectedPoCases: null,
            selectedPoPallets: null,
            selectedPoNotes: null,
            selectedPoCustomerRef: null,
            selectedPoProject: null,

            //Edit Container Values
            showEditContainerDrawer: false,
            unsavedEditContainerValues: false,
            Container: null,
            Notes: null,
            LclFcl: null,
            DeliveryAppointment: null,
            ETD: null,
            ETA: null,

            //Edit Po Values
            showEditPoDrawer: false,
            unsavedEditPoValues: false,
            Po: null,
            PoID: null,
            CustomerRef: null,
            Project: null,
            ExpectedCases: null,
            ExpectedPallets: null,
            Notes: null,


            //Receive Container Drawer
            ReceiveDict: {},
            LocationDict: {},
            unsavedReceiveChanges: false,
            showReceiveDrawer: false,
            ReceiveDrawerContainerID: null,
            ReceiveDrawerContainer: null,
            ReceiveDrawerNo: null,
            ReceiveDrawerPMC: null,
            ReceiveDrawerPickupBy: null,
            ReceiveDrawerTrips: null,
            ReceiveDrawerArrivalTime: null,
            ReceiveDrawerLFD: null,
            ReceiveDrawerArrivalAtWarehouse: null,
            ReceiveDrawerDeliveryTime: null,
            ReceiveDrawerNotes: null,


            //Edit Warehouse Location
            showEditWarehouseLocationDrawer: false,
            unsavedEditWarehouseLocationValues: false,
            // LocationDict: {},
            LocationToEditDict: {},
            // Po: null,
            // CustomerRef: null,
            // ExpectedCases: null,
            // ExpectedPallets: null,
            // Notes: null,

        };

        // Table Sort
        this.handleSortColumn = this.handleSortColumn.bind(this);

        //Add To Container Drawer
        this.onClickTree = this.onClickTree.bind(this);

        //Edit Container Drawer
        this.closeEditContainerDrawer = this.closeEditContainerDrawer.bind(this);
        this.onClickConfirmEditContainer = this.onClickConfirmEditContainer.bind(this);
        this.setStateCloseEditContainerDrawer = this.setStateCloseEditContainerDrawer.bind(this)
        this.onChangeEditContainer = this.onChangeEditContainer.bind(this)

        //Receive drawer
        this.onChangeReceiveDrawer = this.onChangeReceiveDrawer.bind(this);

        // this.onClickReceive = this.onClickReceive.bind(this);
        // this.onClickAddMissing = this.onClickAddMissing.bind(this);
        this.closeReceiveDrawer = this.closeReceiveDrawer.bind(this);
        this.onClickReceiveDrawerSave = this.onClickReceiveDrawerSave.bind(this);
        this.onClickReceiveButton = this.onClickReceiveButton.bind(this);

        this.onClickAddLocation = this.onClickAddLocation.bind(this);

        this.handleAddNewLocation = this.handleAddNewLocation.bind(this);

        this.onClickRemoveFromAdd = this.onClickRemoveFromAdd.bind(this)

        //Table buttons
        this.onClickDetails = this.onClickDetails.bind(this);
        this.onClickPoDetails = this.onClickPoDetails.bind(this);


        //Edit Po Drawer
        this.closeEditPoDrawer = this.closeEditPoDrawer.bind(this);
        this.onClickConfirmEditPo = this.onClickConfirmEditPo.bind(this);
        this.setStateCloseAddNewDrawer = this.setStateCloseAddNewDrawer.bind(this)
        this.onChangeEditPo = this.onChangeEditPo.bind(this)
        this.onChangeCustomerRef = this.onChangeCustomerRef.bind(this)
        this.onChangeProject = this.onChangeProject.bind(this)
        // this.openEditPo = this.openEditPo.bind(this)
        this.onClickEditPo = this.onClickEditPo.bind(this)



        //Edit Warehouse Location
        this.onChangeExistingOversize = this.onChangeExistingOversize.bind(this);
        this.onclickEditWarehouseLocation = this.onclickEditWarehouseLocation.bind(this);
        this.closeEditWarehouseLocationDrawer = this.closeEditWarehouseLocationDrawer.bind(this);
        this.setStateCloseEditWarehouseLocationDrawer = this.setStateCloseEditWarehouseLocationDrawer.bind(this);
        this.onClickAddLocationEditDrawer = this.onClickAddLocationEditDrawer.bind(this);
        this.handleAddNewLocationEditDrawer = this.handleAddNewLocationEditDrawer.bind(this);
        this.onClickSaveEditWarehouseLocation = this.onClickSaveEditWarehouseLocation.bind(this);

        //File Upload
        this.onClickDownloadFileUpload = this.onClickDownloadFileUpload.bind(this);
        this.onClickDeleteFileUpload = this.onClickDeleteFileUpload.bind(this);
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.ContainerPostSuccessful !== this.props.ContainerPostSuccessful && this.props.ContainerPostSuccessful) {
            this.setStateCloseEditContainerDrawer()
        }
        if (prevProps.PoPostSuccessful !== this.props.PoPostSuccessful && this.props.PoPostSuccessful) {
            this.setStateCloseAddNewDrawer()
        }
        if (prevProps.ShipmentReceiveData !== this.props.ShipmentReceiveData && this.props.ShipmentReceiveData.length > 0) {
            this.preloadReceiveState()
        }

    }


    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });

        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 500);
    }



    //Container Tree

    onClickTree(e) {

        this.setState({
            addNewText: null,
            newPoCases: null,
            newPoPallets: null,
            newPoNotes: null,
            newPoCustomerRef: null,
            newPoProject: null
        });

        var selectedArray = []
        const findById = (dataset, id) => {
            for (let c of dataset) {
                if (c.value === id) {
                    selectedArray = c
                    return selectedArray
                }
                else {
                    for (let m of c.children) {
                        if (m.value === id) {
                            selectedArray = m
                            return selectedArray
                        }
                        else {
                            for (let h of m.children) {
                                if (h.value === id) {
                                    selectedArray = m
                                    return selectedArray
                                }
                                else {
                                    for (let p of h.children) {
                                        if (p.value === id) {
                                            selectedArray = p
                                            return selectedArray
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        selectedArray = findById(this.props.ContainerJsonTreeData, e['value']);

        if (e['value'].includes('C')) {
            this.setState({
                selectedType: 'container',
                selectedLabel: e['label'],
                selectedID: e['value'].substring(1)
            });
        }
        else if (e['value'].includes('M')) {
            this.setState({
                selectedType: 'mbol',
                selectedLabel: e['label'],
                selectedID: e['value'].substring(1)
            });
        }
        else if (e['value'].includes('H')) {
            this.setState({
                selectedType: 'hbol',
                selectedLabel: e['label'],
                selectedID: e['value'].substring(1)
            });
        }
        else if (e['value'].includes('P')) {
            this.setState({
                selectedType: 'po',
                selectedLabel: e['label'],
                selectedID: e['value'].substring(1),

                selectedPoCases: selectedArray['CaseCount'],
                selectedPoPallets: selectedArray['PalletCount'],
                selectedPoNotes: selectedArray['Notes'],
                selectedPoCustomerRef: selectedArray['custref'][0]['CustomerRef'],
                selectedPoProject: selectedArray['custref'][0]['Project'],
            });
        }
    }

    //Table on click details
    onClickDetails(e, rowData, history) {
        try {
            var paramID = rowData['PoID']
            history.push({
                pathname: 'podetails',
                search: 'poid=\'' + paramID + '\''
            });
        }
        catch (e) {
        }
    }




    onClickPoDetails(e) {
        try {
            var paramID = this.state.selectedID
            this.props.history.push({
                pathname: 'podetails',
                search: 'poid=\'' + paramID + '\''
            });
        }
        catch (e) {
        }

    }


    //BreakdownForm Excel formatting
    formatDataSet(
        bodyPoData
    ) {
        let formattedData = []
        try {
            formattedData = [
                {
                    columns: [
                        { title: "Container", width: { wpx: 220 }, style: { font: { sz: "24", bold: true } } },
                        { title: "PO", width: { wpx: 220 }, style: { font: { sz: "24", bold: true } } },
                        { title: "Total Cases", width: { wpx: 160 }, style: { font: { sz: "24", bold: true } } },
                        { title: "Total Pallets", width: { wpx: 160 }, style: { font: { sz: "24", bold: true } } },
                    ],
                    data:
                        bodyPoData.map((record, index) => {
                            return [
                                { value: formatForExcelExport(record.Container), style: { font: { sz: "24" } } },
                                { value: formatForExcelExport(record.PO), style: { font: { sz: "24" } } },
                                {},
                                {}
                            ];
                        })
                }
            ];
        }
        catch (e) { }

        return (
            formattedData
        )
    }





    //Receive Container drawer
    onClickReceiveButton(e) {
        this.props.transShipmentReceiveGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.ContainerData[0].ContainerID, null, null, null, this.props.warehouseSelection, this.props.customerSelection)


        this.setState({
            ReceiveDict: {},
            LocationDict: {},
            unsavedReceiveChanges: false,
            receiveDataRowData: this.props.PoData[0],
            showReceiveDrawer: true,

        });
        // this.preloadReceiveState()
    }
    preloadReceiveState() {
        var receivedDict = {};
        // var testDict = {}
        for (let k in this.props.PoData) {
            if (this.props.ShipmentReceiveData[k].PoID === null) {
            }
            else {
                // receivedDict[this.props.ShipmentReceiveData[k].HouseID] = { 'Recovered': this.props.ShipmentReceiveData[k].Cartons - this.props.ShipmentReceiveData[k].RecoveredPCS, 'Pallets': null, 'Notes': '', 'Customs': false }
                receivedDict[this.props.PoData[k].PoID] = { 'Recovered': null, 'Pallets': null, 'Notes': '', 'Customs': false }
            }
        }
        var locationDict = {};
        for (let k in this.props.ShipmentReceiveData) {
            if (this.props.ShipmentReceiveData[k].PoID === null) {
            }
            else {
                // locationDict[this.props.ShipmentReceiveData[k].PoID] = [{'WarehouseLocationID': null, 'WarehouseID': null, 'Cases': null, 'Pallets': null,  }]
                locationDict[this.props.ShipmentReceiveData[k].PoID] = [{ 'WarehouseLocationID': null, 'WarehouseID': null, 'Cases': null, 'Pallets': null, 'Oversize': false }]
            }
        }
        if (typeof (receivedDict) === 'undefined' || receivedDict === null) {
            window.alert('No POs exist for Receiving.')
        }
        else {
            this.setState({
                unsavedReceiveChanges: false,
                ReceiveDict: receivedDict,
                LocationDict: locationDict
            });
        }
    }

    onChangeReceiveDrawer = (event, item, label) => {
        var currentReceiveDict = this.state.ReceiveDict
        var poID = item.PoID;
        // ;wfiop928P(*N{P(*NU)})
        currentReceiveDict[poID][label] = event

        this.setState({
            unsavedReceiveChanges: true,
            ReceiveDict: currentReceiveDict
        });

    };

    onChangeReceiveDrawerLocations = (event, item, locationitem, label, existinglocationname) => {

        if (label === 'WarehouseLocationID') {
            // find new warehouse name in existing state:
            //This will be changed to dropdown choice:
            if (this.state.LocationDict[item.PoID].filter(item => item.WarehouseLocationID === event).length > 0) {
                window.alert('Duplicate location entry, please use existing.')
            }
            else {
                //save warehouse name:
                var warehouseLocationDictUpdated = this.state.LocationDict
                var keyToUpdate = warehouseLocationDictUpdated[item.PoID].findIndex(item => item.WarehouseLocationID === existinglocationname)

                warehouseLocationDictUpdated[item.PoID][keyToUpdate][label] = event

                this.setState({
                    LocationDict: warehouseLocationDictUpdated,
                    unsavedReceiveChanges: true
                });
            }
        }
        else {
            var warehouseLocationDictUpdated = this.state.LocationDict
            var keyToUpdate = warehouseLocationDictUpdated[item.PoID].findIndex(item => item.WarehouseLocationID === existinglocationname)

            warehouseLocationDictUpdated[item.PoID][keyToUpdate][label] = event

            this.setState({
                LocationDict: warehouseLocationDictUpdated,
                unsavedReceiveChanges: true
            });

            //Use warehouseLocationDictUpdated to update totals of Pallets and cases
            var currentReceiveDict = this.state.ReceiveDict
            var poID = item.PoID;

            var sumNewValue = 0;

            for (var x in warehouseLocationDictUpdated[poID]) {
                sumNewValue += convertNanToZero(parseInt(warehouseLocationDictUpdated[poID][x][label]));
            }

            currentReceiveDict[poID][label] = sumNewValue

            this.setState({
                ReceiveDict: currentReceiveDict
            });

        }

        this.forceUpdate()
    };



    handleAddNewLocation = (newLocationDict) => {
        this.setState({
            unsavedReceiveChanges: true,
            LocationDict: newLocationDict
        }, () => {
            // Do an API call with this.state.searchTerm

        });
    }

    onClickAddLocation = (event, item) => {
        //Check if any Warehouse names are null
        if (this.state.LocationDict[item.PoID].filter(item => item.WarehouseLocationID === null || item.WarehouseLocationID === '').length > 0) {
            window.alert('Please enter all location values first.')
        }
        else {
            //save warehouse name
            var currentLocationDict = this.state.LocationDict

            currentLocationDict[item.PoID].push({ 'WarehouseLocationID': null, 'WarehouseID': null, 'Cases': null, 'Pallets': null, 'Oversize': false })

            this.handleAddNewLocation(currentLocationDict)

            this.forceUpdate()
        }
    }


    onClickRemoveFromAdd = (e, item, locationitem, warehouseLocationID) => {
        var currentList = JSON.parse(JSON.stringify(this.state.LocationDict));
        var poID = item.PoID

        if (currentList[poID].length === 1) {
            //if only one left then set last row to empty
            currentList[poID][0] = { 'WarehouseLocationID': null, 'WarehouseID': null, 'Cases': 0, 'Pallets': 0, 'Oversize': false }
        }
        else {
            var remainingItems = currentList[poID].filter((item) => { return item.WarehouseLocationID !== warehouseLocationID });
            currentList[poID] = JSON.parse(JSON.stringify(remainingItems));
        }

        var currentBuildLoadDict = this.state.ReceiveDict
        try {
            currentBuildLoadDict[poID]['Cases'] = (currentList[poID].reduce((a, v) => a = convertNanToZero(a) + isNumeric(v.Cases), 0))
            currentBuildLoadDict[poID]['Pallets'] = (currentList[poID].reduce((a, v) => a = convertNanToZero(a) + isNumeric(v.Pallets), 0))
        }
        catch (e) {
            // currentBuildLoadDict[poID]['Cases'] = replaceNegativeWithZero(currentBuildLoadDict['CasesReceived'] - currentBuildLoadDict['CasesLoaded'])
            // currentBuildLoadDict[poID]['Pallets'] = replaceNegativeWithZero(currentBuildLoadDict['PalletsReceived'] - currentBuildLoadDict['PalletsLoaded'])
        }
        this.setState({
            // unsavedBuildLoadChanges: true,
            ReceiveDict: currentBuildLoadDict,
            LocationDict: currentList,
        });

        this.forceUpdate()
    }


    onClickReceiveDrawerSave(e) {
        if (this.props.warehouseSelection === null) {
            window.alert('Please select a warehouse and customer.')
        }
        else if (this.props.customerSelection === null) {
            window.alert('Please select a customer.')
        }
        else if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {

            // if (window.confirm('Save Data?')) {
            for (let k in this.state.ReceiveDict) {
                try { var recoveredNum = this.state.ReceiveDict[k]['Cases'] }
                catch (e) { var recoveredNum = 0 }
                try { var palletNum = this.state.ReceiveDict[k]['Pallets'] }
                catch (e) { var palletNum = 0 }

                if ((recoveredNum === null || recoveredNum == 0) && (palletNum === null || palletNum == 0)) {
                }
                else {
                    try {
                        this.props.transShipmentReceivePost(
                            this.props.currentUserEmail,
                            this.props.userSignInData.AuthToken,
                            formatNumberForAPI(this.state.receiveDataRowData.ContainerID),
                            null,
                            null,
                            formatNumberForAPI(k),
                            null,
                            formatNumberForAPI(this.state.ReceiveDict[k]['Cases']),
                            formatNumberForAPI(this.state.ReceiveDict[k]['Pallets']),
                            formatStringForAPI(this.state.ReceiveDict[k]['Notes']),
                            null,
                            this.props.warehouseSelection,
                            this.props.customerSelection
                        )
                    }
                    catch (e) { }
                }
            }

            for (let l in this.state.LocationDict) {

                try { var poID = l }
                catch (e) { var poID = null }

                for (let i in this.state.LocationDict[l]) {

                    try { var casesLocNum = convertNanToZero(this.state.LocationDict[l][i]['Cases']) }
                    catch (e) { var casesLocNum = 0 }

                    try { var palletsLocNum = convertNanToZero(this.state.LocationDict[l][i]['Pallets']) }
                    catch (e) { var palletsLocNum = 0 }

                    try { var WarehouseLocationIDNum = convertNanToZero(this.state.LocationDict[l][i]['WarehouseLocationID']) }
                    catch (e) { var WarehouseLocationIDNum = 0 }

                    try { var oversizeLoc = formatTrueFalseToBit(this.state.LocationDict[l][i]['Oversize']) }
                    catch (e) { var oversizeLoc = false }

                    if ((casesLocNum === null || casesLocNum == 0) && (palletsLocNum === null || palletsLocNum == 0)) {
                    }
                    else {
                        try {
                            this.props.transPoLocationPost(
                                this.props.currentUserEmail,
                                this.props.userSignInData.AuthToken,
                                poID,
                                WarehouseLocationIDNum,
                                casesLocNum,
                                palletsLocNum,
                                null,
                                this.props.warehouseSelection,
                                this.props.customerSelection,
                                oversizeLoc
                            )
                        }
                        catch (e) { }
                    }
                }
            }

            try {
                // check customs city api for events
                this.props.customsCityAPIMessages(
                    this.props.currentUserEmail,
                    this.props.userSignInData.AuthToken,
                    null,
                    this.state.receiveDataRowData.Container
                )
            }
            catch (e) { }
            this.setState({
                unsavedReceiveChanges: false,
                showReceiveDrawer: false
            });

            // }
        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }

    }


    closeReceiveDrawer() {
        if (this.state.unsavedReceiveChanges) {
            if (window.confirm('Leave without saving changes?')) {
                this.setReceiveDrawerToClose()
            }
        }
        else {
            this.setReceiveDrawerToClose()
        }
    }

    setReceiveDrawerToClose() {
        this.setState({
            ReceiveDict: {},
            LocationDict: {},
            unsavedReceiveChanges: false,

            showReceiveDrawer: false,


            ReceiveDrawerContainerID: null,
            ReceiveDrawerContainer: null,
            ReceiveDrawerNo: null,
            ReceiveDrawerPMC: null,
            ReceiveDrawerPickupBy: null,
            ReceiveDrawerTrips: null,
            ReceiveDrawerArrivalTime: null,
            ReceiveDrawerLFD: null,
            ReceiveDrawerArrivalAtWarehouse: null,
            ReceiveDrawerDeliveryTime: null,
            ReceiveDrawerNotes: null,
        });
    }




    //Edit Container Drawer
    onClickEditContainer() {

        this.openEditContainer()
        this.setState({
            Container: this.props.ContainerData[0].Container,
            Notes: this.props.ContainerData[0].Notes,
            LclFcl: this.props.ContainerData[0].LCL_FCL,
            DeliveryAppointment: this.props.ContainerData[0].DeliveryAppointment,
            ETD: this.props.ContainerData[0].ETD,
            ETA: this.props.ContainerData[0].ETA

        });
    }
    openEditContainer(placement) {
        this.setState({
            placement,
            showEditContainerDrawer: true,
            // unsavedEditContainerValues: true,
        });
    }
    onChangeEditContainer = (event, field) => {
        var dict = {};
        dict[field] = event;
        this.setState(
            dict
        );
        this.setState({
            unsavedEditContainerValues: true,
        });
    };
    onClickConfirmEditContainer(e) {
        if (this.state.Container == null || this.state.Container == '') {
            window.alert('Please enter new Container.')
        }
        else if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {
            // if (window.confirm('Save Changes?')) {
            // else {
            this.props.transContainerPost(
                this.props.currentUserEmail,
                this.props.userSignInData.AuthToken,
                this.props.ContainerData[0].ContainerID,
                formatStringForAPI(this.state.Container),
                formatStringForAPI(this.state.LclFcl),
                formatDateTimeForUrlSearch(this.state.DeliveryAppointment),
                formatDateForUrlSearch(this.state.ETD),
                formatDateForUrlSearch(this.state.ETA),
                null,
                formatStringForAPI(this.state.Notes),
                this.props.warehouseSelection,
                this.props.customerSelection
            )
            // }
        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }

    }
    closeEditContainerDrawer(e) {
        if (this.state.unsavedEditContainerValues) {
            if (window.confirm('Leave without saving changes?')) {
                this.setStateCloseEditContainerDrawer(e)
            }
        }
        else {
            this.setStateCloseEditContainerDrawer(e)
        }
    }
    setStateCloseEditContainerDrawer() {
        this.setState({
            showEditContainerDrawer: false,
            unsavedEditContainerValues: false,
            Container: null,
            LclFcl: null,
            Notes: null,
            DeliveryAppointment: null,
            ETD: null,
            ETA: null
        });
        this.forceUpdate()
    }






    //Edit Po Drawer
    onClickEditPo(e, rowData, history) {
        this.openEditPo()
        this.setState({
            Po: rowData.PO,
            PoID: rowData.PoID,
            CustomerRef: rowData.CustomerRefID,
            Project: rowData.ProjectID,
            ExpectedCases: rowData.CaseCount,
            ExpectedPallets: rowData.PalletCount,
            Notes: rowData.PoNotes

        });
    }
    openEditPo(placement) {
        this.setState({
            placement,
            showEditPoDrawer: true,
            // unsavedEditPoValues: true,
        });
    }
    onChangeEditPo = (event, field) => {
        var dict = {};
        dict[field] = event;
        this.setState(
            dict
        );
        this.setState({
            unsavedEditPoValues: true,
        });
    };

    onChangeCustomerRef = (e, value, item) => {
        this.setState({
            unsavedEditPoValues: true,
            CustomerRef: e
        });
    }

    onChangeProject = (e, value, item) => {
        this.setState({
            unsavedEditPoValues: true,
            Project: e
        });
    }


    onClickConfirmEditPo(e) {
        if (this.state.Po == null || this.state.Po == '') {
            window.alert('Please enter new Po.')
        }
        else if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {

            // if (window.confirm('Save Changes?')) {

            this.props.transPoPost(
                this.props.currentUserEmail,
                this.props.userSignInData.AuthToken,
                this.state.PoID,
                formatStringForAPI(this.state.Po),
                null,
                formatStringForAPI(this.state.CustomerRef),
                this.state.ExpectedCases,
                this.state.ExpectedPallets,
                formatStringForAPI(this.state.Notes),
                formatStringForAPI(this.state.Project),
                0,
                this.props.warehouseSelection,
                this.props.customerSelection)
            // }
        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }
    closeEditPoDrawer() {
        if (this.state.unsavedEditPoValues) {
            if (window.confirm('Leave without saving changes?')) {
                this.setStateCloseAddNewDrawer()
            }
        }
        else {
            this.setStateCloseAddNewDrawer()
        }
    }
    setStateCloseAddNewDrawer() {
        this.setState({
            showEditPoDrawer: false,
            unsavedEditPoValues: false,

            Po: null,
            PoID: null,
            CustomerRef: null,
            Project: null,
            ExpectedCases: null,
            ExpectedPallets: null,
            Notes: null,
        });
    }





    //Warehouse Location Drawer Buttons
    onclickEditWarehouseLocation(e, rowData, history) {
        try{
            var existingOversize = rowData['Oversize']
        }
        catch(e){
            var existingOversize = false
        }

        var locationDict = {};
        locationDict[rowData.PoID] = [{ 'WarehouseLocationID': null, 'WarehouseID': null, 'Cases': 0, 'Pallets': 0, 'Oversize': existingOversize }]

        var newLocationToEdit = rowData
        newLocationToEdit['OriginalCases'] = rowData['Cases']
        newLocationToEdit['OriginalPallets'] = rowData['Pallets']
        newLocationToEdit['OriginalOversize'] = rowData['Oversize']

        this.setState({
            showEditWarehouseLocationDrawer: true,
            LocationDict: locationDict,
            LocationToEditDict: newLocationToEdit,
            unsavedEditWarehouseLocationValues: false,
        });

    }

    onChangeExistingOversize(e, existingData){
        if(window.confirm('Do you wish to update Oversize indicator?')){
            try {
                this.props.transPoLocationPost(
                    this.props.currentUserEmail,
                    this.props.userSignInData.AuthToken,
                    existingData.PoID,
                    existingData.WarehouseLocationID,
                    replaceNegativeWithZero(existingData.OriginalCases),
                    replaceNegativeWithZero(existingData.OriginalPallets),
                    existingData.WarehouseLocationID,
                    this.props.warehouseSelection,
                    this.props.customerSelection,
                    formatTrueFalseToBit(e)
                )
            }
            catch (e) { }

            this.setStateCloseEditWarehouseLocationDrawer()
        }
    }

    onChangeEditLocationDrawer = (event, itemLocation, label, existinglocationname) => {

        if (label === 'WarehouseLocationID') {
            // find new warehouse name in existing state:
            //This will be changed to dropdown choice:
            if (this.state.LocationDict[this.state.LocationToEditDict.PoID].filter(item => item.WarehouseLocationID === event).length > 0) {
                window.alert('Duplicate location entry, please use existing.')
            }
            else if (this.state.LocationToEditDict.WarehouseLocationID === event) {
                window.alert('Location already exists, please choose new location.')
            }
            else {
                //save warehouse name:
                var warehouseLocationDictUpdated = this.state.LocationDict
                var keyToUpdate = warehouseLocationDictUpdated[this.state.LocationToEditDict.PoID].findIndex(item => item.WarehouseLocationID === existinglocationname)

                warehouseLocationDictUpdated[this.state.LocationToEditDict.PoID][keyToUpdate][label] = event

                this.setState({
                    LocationDict: warehouseLocationDictUpdated,
                    unsavedEditWarehouseLocationValues: true
                });
            }
        }
        else {
            var warehouseLocationDictUpdated = this.state.LocationDict
            var keyToUpdate = warehouseLocationDictUpdated[this.state.LocationToEditDict.PoID].findIndex(item => item.WarehouseLocationID === existinglocationname)

            warehouseLocationDictUpdated[this.state.LocationToEditDict.PoID][keyToUpdate][label] = isNumeric(event)

            this.setState({
                LocationDict: warehouseLocationDictUpdated,
                unsavedEditWarehouseLocationValues: true
            });


        }


        var currentBuildLoadDict = this.state.LocationToEditDict

        try {
            currentBuildLoadDict['Cases'] = replaceNegativeWithZero(this.state.LocationToEditDict.OriginalCases) - replaceNegativeWithZero((warehouseLocationDictUpdated[this.state.LocationToEditDict.PoID].reduce((a, v) => a = a + v.Cases, 0)))
            currentBuildLoadDict['Pallets'] = replaceNegativeWithZero(this.state.LocationToEditDict.OriginalPallets) - replaceNegativeWithZero((warehouseLocationDictUpdated[this.state.LocationToEditDict.PoID].reduce((a, v) => a = a + v.Pallets, 0)))
        }
        catch (e) {
            // currentBuildLoadDict['Cases'] = replaceNegativeWithZero(currentBuildLoadDict[currentBuildLoadDictIndex]['CasesReceived'] - currentBuildLoadDict[currentBuildLoadDictIndex]['CasesLoaded'])
            // currentBuildLoadDict['Pallets'] = replaceNegativeWithZero(currentBuildLoadDict[currentBuildLoadDictIndex]['PalletsReceived'] - currentBuildLoadDict[currentBuildLoadDictIndex]['PalletsLoaded'])
        }

        if (currentBuildLoadDict['Cases'] < 0) {

            window.alert('Warning: moving cases than exist at location.')

        }

        this.setState({
            LocationToEditDict: currentBuildLoadDict
        });

        this.forceUpdate()
    };

    handleAddNewLocationEditDrawer = (newLocationDict) => {
        this.setState({
            unsavedEditWarehouseLocationValues: true,
            LocationDict: newLocationDict
        }, () => {
            // Do an API call with this.state.searchTerm
        });
    }

    onClickAddLocationEditDrawer = (event) => {

        //Check if any Warehouse names are null
        if (this.state.LocationDict[this.state.LocationToEditDict.PoID].filter(item => item.WarehouseLocationID === null || item.WarehouseLocationID === '').length > 0) {
            window.alert('Please enter all location values first.')
        }
        else {
            try{
                var existingOversize = this.state.LocationToEditDict['Oversize']
            }
            catch(e){
                var existingOversize = false
            }
            //save warehouse name
            var currentLocationDict = this.state.LocationDict

            currentLocationDict[this.state.LocationToEditDict.PoID].push({ 'WarehouseLocationID': null, 'WarehouseID': null, 'Cases': null, 'Pallets': null, 'Oversize': existingOversize })

            this.handleAddNewLocationEditDrawer(currentLocationDict)

            this.forceUpdate()
        }
    }
    onClickSaveEditWarehouseLocation(e) {
        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {

            try {
                var poID = this.state.LocationToEditDict.PoID
            }
            catch (e) {
                var poID = -1
            }
            try { var PreviousWarehouseLocationID = convertNanToZero(this.state.LocationToEditDict.WarehouseLocationID) }
            catch (e) { var PreviousWarehouseLocationID = 0 }

            var findNullLocations = this.state.LocationDict[poID].filter(locations => locations.WarehouseLocationID === null)
            if (findNullLocations.length > 0) {
                window.alert('Please select new locations.')
            }
            else {

                // if (window.confirm('Save Location Changes?')) {
                //Save update to existing location:
                try { var casesExistingLocNum = convertNanToZero(this.state.LocationToEditDict['Cases']) }
                catch (e) { var casesExistingLocNum = 0 }

                try { var palletsExistingLocNum = convertNanToZero(this.state.LocationToEditDict['Pallets']) }
                catch (e) { var palletsExistingLocNum = 0 }

                try { var oversizeLoc = formatTrueFalseToBit(this.state.LocationToEditDict['Oversize']) }
                catch (e) { var oversizeLoc = false }

                try {
                    this.props.transPoLocationPost(
                        this.props.currentUserEmail,
                        this.props.userSignInData.AuthToken,
                        poID,
                        PreviousWarehouseLocationID,
                        replaceNegativeWithZero(casesExistingLocNum),
                        replaceNegativeWithZero(palletsExistingLocNum),
                        PreviousWarehouseLocationID,
                        this.props.warehouseSelection,
                        this.props.customerSelection,
                        oversizeLoc
                    )
                }
                catch (e) { }

                for (let l in this.state.LocationDict[poID]) {
                    try { var casesLocNum = convertNanToZero(this.state.LocationDict[poID][l]['Cases']) }
                    catch (e) { var casesLocNum = 0 }

                    try { var palletsLocNum = convertNanToZero(this.state.LocationDict[poID][l]['Pallets']) }
                    catch (e) { var palletsLocNum = 0 }

                    try { var WarehouseLocationIDNum = convertNanToZero(this.state.LocationDict[poID][l]['WarehouseLocationID']) }
                    catch (e) { var WarehouseLocationIDNum = 0 }

                    try { var oversizeLoc = formatTrueFalseToBit(this.state.LocationDict[poID][l]['Oversize']) }
                    catch (e) { var oversizeLoc = false }

                    if ((casesLocNum === null || casesLocNum == 0) && (palletsLocNum === null || palletsLocNum == 0)) {
                    }
                    else {
                        try {
                            this.props.transPoLocationPost(
                                this.props.currentUserEmail,
                                this.props.userSignInData.AuthToken,
                                poID,
                                WarehouseLocationIDNum,
                                replaceNegativeWithZero(casesLocNum),
                                replaceNegativeWithZero(palletsLocNum),
                                PreviousWarehouseLocationID,
                                this.props.warehouseSelection,
                                this.props.customerSelection,
                                oversizeLoc
                            )
                        }
                        catch (e) { }
                    }
                }
                this.setStateCloseEditWarehouseLocationDrawer()
                // }
            }

        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }

    }
    closeEditWarehouseLocationDrawer() {
        if (this.state.unsavedEditWarehouseLocationValues) {
            if (window.confirm('Leave without saving changes?')) {
                this.setStateCloseEditWarehouseLocationDrawer()
                window.location.reload();
            }
        }
        else {
            this.setStateCloseEditWarehouseLocationDrawer()
        }
    }
    setStateCloseEditWarehouseLocationDrawer() {
        this.setState({
            showEditWarehouseLocationDrawer: false,
            unsavedEditWarehouseLocationValues: false,
            LocationDict: {},
            LocationToEditDict: {},

        });
    }



    //File Upload
    onClickDownloadFileUpload(e, rowData, history) {
        try {
            this.props.downloadFileFromS3(this.props.currentUserEmail, this.props.userSignInData.AuthToken, rowData.S3FileName, rowData.FileName, appAPI.FileDownloadTransloadS3)
        }
        catch (e) {
            window.alert("Error Downloading File.")
        }
    }

    onClickDeleteFileUpload(e, rowData, history) {
        if (window.confirm('Do you wish to delete this file?'))
            this.props.transContainerFileUploadPost(
                this.props.currentUserEmail,
                this.props.userSignInData.AuthToken,
                this.props.customerSelection,
                formatNumberForAPI(rowData.FileUploadID),
                null,
                null,
                null,
                null,
                1
            )
    }




    render() {
        const { sortColumn, sortType } = this.state;

        let fileUploadDiv;
        let pageHeader;
        let currentContainer;
        let detailsDiv;
        let containerCurrentStatus;
        if (this.props.isContainerLoading) {
            detailsDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (this.props.isTransContainerFileUploadLoading) {
            detailsDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.ContainerData[0]) !== 'undefined' && this.props.ContainerData != null) {

            pageHeader = <div style={{ float: 'left' }}>  {'Transload - Container: ' + this.props.ContainerData[0].Container} </div>
            currentContainer = this.props.ContainerData[0].Container

            containerCurrentStatus = this.props.ContainerData[0].CurrentContainerStatusID

            fileUploadDiv = RUploader(
                this.props.isMobile,                          // isMobile,
                this.props.history,                           // history,
                this.props.currentUserEmail,                  //currentUserEmail,
                this.props.userSignInData,                    //userSignInData
                this.props.customerSelection,                 //customerSelection
                this.props.TransContainerFileUploadData,      //fileList,
                this.props.transContainerFileUploadPost,     //onUploadAction,
                null,                                         // loading,
                this.props.ContainerData[0].ContainerID,     // proRef,
                appAPI.FileDownloadTransloadS3      //s3 Bucket
            )

            detailsDiv =
                <div>

                    <Row >
                        <Col md={12} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }}>
                                {/* {containerCurrentStatus == 2 ? <Button style={{ float: 'right' }} onClick={(e) => { this.onClickCloseStatus(e) }} >Close Container Status</Button> : ''} */}
                                Status: <strong>{this.props.ContainerData[0].ContainerStatusName}</strong>
                                <br />
                                LCL/FCL: <strong>{this.props.ContainerData[0].LCL_FCL}</strong>
                                <br />
                                DeliveryAppointment: <strong>{this.props.ContainerData[0].DeliveryAppointment}</strong>
                                <br />
                                ETD: <strong>{this.props.ContainerData[0].ETD}</strong>
                                <br />
                                ETA: <strong>{this.props.ContainerData[0].ETA}</strong>
                                <br />
                                Notes: <strong>{this.props.ContainerData[0].Notes}</strong>
                                <br />


                            </Panel>
                        </Col>
                        <Col md={12} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }}>
                                Expected Cases: <strong>{this.props.ContainerData[0].CaseCount}</strong>
                                <br />
                                Cases Received: <strong>{this.props.ContainerData[0].CasesReceived}</strong>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                            </Panel>
                        </Col>
                    </Row>
                </div>

        }
        else {
            detailsDiv = <div></div>;
        }


        let fileUploadsTable;
        if (this.props.isTransContainerFileUploadLoading) {

            fileUploadsTable =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.TransContainerFileUploadData) !== 'undefined' && this.props.TransContainerFileUploadData != null && this.props.TransContainerFileUploadData.length > 0) {
            var milestoneTableComponent = RTableGeneral(
                tableIdCol,     //   keyColumn,
                this.props.TransContainerFileUploadData,     //   tableData,
                TransContainerFileUploadsDataElements,       //   DataElements,
                this.props.isTransContainerFileUploadLoading,        //   loading,

                this.props.isMobile,        //   isMobile,
                this.props.history,     //   history,

                sortColumn,     //   sortColumn,
                sortType,       //   sortType,
                this.handleSortColumn,      //   handleSortColumn,

                null, // checkColEntry,
                null,       //   checkedKeys,
                null,       //   handleCheckAll,
                null,       //   handleCheck,

                true,      //   onClickEditMilestone,
                this.onClickDownloadFileUpload,       //   onClickSelect,
                'Download',       //   selectButtonText,
                null,       //   selectHighlightRowKey,

                true,       //   link1bool,
                'Delete',     //   link1text,
                this.onClickDeleteFileUpload,     //   link1onClick,

                false,       //   link2bool,
                null,       //   link2text,
                null  //   link2onClick
            )

            fileUploadsTable = <Panel
                bordered style={{ marginBottom: 10 }}
                header='File Uploads'
            >
                {milestoneTableComponent}
            </Panel>


        }
        else {
            fileUploadsTable = <div></div>
        }

        let ContainerJsonTreeDiv;
        if (this.props.isContainerJsonTreeLoading) {
            ContainerJsonTreeDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.ContainerJsonTreeData[0]) !== 'undefined' && this.props.ContainerJsonTreeData != null) {

            ContainerJsonTreeDiv = <div>
                <Row >
                    <Col md={24} sm={24}>
                        <Panel bordered style={{ marginBottom: 10 }} >
                            <Col md={12} sm={24}>
                                <Tree data={this.props.ContainerJsonTreeData} onSelect={(e) => { this.onClickTree(e) }} />
                            </Col>


                            <Col md={12} sm={24}>

                                {
                                    this.state.selectedType === 'container' ? <div>
                                        {/* <h5 style={{ marginBottom: 5 }} >Add new MBOL to: {this.state.selectedLabel}</h5> */}

                                        {/* <FormGroup>
                            <FormControl placeholder="MBOL" name="MBOL" value={this.state.addNewText} onChange={(e) => { onChangeAddToContainer(e) }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} />
                        </FormGroup> */}

                                        {/* <FormGroup>
                            <ButtonToolbar>
                                <Button onClick={confirm} appearance="primary">Save</Button>
                                <Button onClick={close} appearance="default">Cancel</Button>
                            </ButtonToolbar>
                        </FormGroup> */}

                                    </div> : <div></div>
                                }

                                {
                                    this.state.selectedType === 'mbol' ? <div>
                                        {/* <h5 style={{ marginBottom: 5 }}>Add new HBOL to: {this.state.selectedLabel}</h5> */}

                                        {/* <FormGroup>
                            <FormControl placeholder="HBOL" name="HBOL" value={this.state.addNewText} onChange={(e) => { onChangeAddToContainer(e) }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} />
                        </FormGroup> */}
                                        {/* 
                        <FormGroup>
                            <ButtonToolbar>
                                <Button onClick={confirm} appearance="primary">Save</Button>
                                <Button onClick={close} appearance="default">Cancel</Button>

                                <Button style={{ float: 'right' }} onClick={(e) => { onClickDeleteMbol(e) }} appearance="default">Delete MBOL</Button>
                            </ButtonToolbar>
                        </FormGroup> */}

                                    </div> : <div></div>
                                }
                                {
                                    this.state.selectedType === 'hbol' ? <div>
                                        {/* <h5 style={{ marginBottom: 5 }}>Add new PO to: {this.state.selectedLabel}</h5> */}

                                        {/* <FormGroup>

                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <FormControl placeholder="PO" name="PO" value={this.state.addNewText} onChange={(e) => { onChangeAddToContainer(e) }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} />
                            </InputGroup>

                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>Expected Cases:</InputGroup.Addon>
                                <InputNumber defaultValue={0} className={'custom-input-number'} onChange={(e) => onChangeCases(e)} scrollable={false} />
                            </InputGroup>
                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>Expected Pallets:</InputGroup.Addon>
                                <InputNumber defaultValue={0} className={'custom-input-number'} onChange={(e) => onChangePallets(e)} scrollable={false} />
                            </InputGroup>

                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>Notes:</InputGroup.Addon>
                                <Input onChange={(e) => { onChangeNotes(e) }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                            </InputGroup>

                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>Customer:</InputGroup.Addon>
                                <Input onChange={(e) => { onChangeCustomerRef(e) }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                            </InputGroup>

                        </FormGroup> */}

                                        {/* <FormGroup>
                            <ButtonToolbar>
                                <Button onClick={confirm} appearance="primary">Save</Button>
                                <Button onClick={close} appearance="default">Cancel</Button>

                                <Button style={{ float: 'right' }} onClick={(e) => { onClickDeleteHbol(e) }} appearance="default">Delete HBOL</Button>
                            </ButtonToolbar>
                        </FormGroup> */}

                                    </div> : <div></div>
                                }

                                {
                                    this.state.selectedType === 'po' ? <div>

                                        <h5 style={{ marginBottom: 5 }}>
                                            {this.state.selectedLabel}
                                        </h5>

                                        <div>
                                            <Row >
                                                <Col md={24} sm={24}>
                                                    <Panel bordered style={{ marginBottom: 10 }}>
                                                        <Button size="sm" appearance="primary" style={{ float: 'right' }} onClick={(e) => { this.onClickPoDetails(e) }} >Details</Button>
                                                        Expected Cases: <strong>{this.state.selectedPoCases}</strong>
                                                        <br />
                                                        Expected Pallets: <strong>{this.state.selectedPoPallets}</strong>
                                                        <br />
                                                        Notes: <strong>{this.state.selectedPoNotes}</strong>
                                                        <br />
                                                        Customer Ref: <strong>{this.state.selectedPoCustomerRef}</strong>
                                                        <br />
                                                        Project: <strong>{this.state.selectedPoProject}</strong>
                                                        <br />
                                                    </Panel>
                                                </Col>
                                            </Row>
                                        </div>

                                        {/* <Row>
                            <FormGroup>
                                <ButtonToolbar>
                                    <Button style={{ float: 'right' }} onClick={(e) => { onClickDeletePo(e) }} appearance="default">Delete PO</Button>
                                </ButtonToolbar>
                            </FormGroup>
                        </Row> */}
                                    </div> : <div></div>
                                }
                            </Col>
                        </Panel>
                    </Col>
                </Row>
            </div>
        }

        else {
            ContainerJsonTreeDiv = <div></div>;
        }


        let shipmentPoTable;
        let breakdownFormButton;
        let receiveButton;
        // let receiveDrawer;
        let editButton;
        if (this.props.isPoLoading) {
            shipmentPoTable =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>

            breakdownFormButton = <div></div>
            receiveButton = <div></div>
            // receiveDrawer = <div></div>
            editButton = <div></div>
        }
        else if (typeof (this.props.PoData) !== 'undefined' && this.props.PoData != null && typeof (this.props.ContainerData[0]) !== 'undefined' && this.props.ContainerData != null) {

            if ([1, 2].includes(containerCurrentStatus)) {
                receiveButton = <Button style={{ float: 'right' }} onClick={(e) => { this.onClickReceiveButton(e) }} >Receive</Button>
            }
            else {
                receiveButton = <div></div>
            }

            let shipmentPoTableElement = RTableGeneral(
                tableIdCol,     //   keyColumn,
                this.props.PoData,     //   tableData,
                WarehouseDataElements,       //   DataElements,
                this.props.isPoLoading,        //   loading,

                this.props.isMobile,        //   isMobile,
                this.props.history,     //   history,

                sortColumn,     //   sortColumn,
                sortType,       //   sortType,
                this.handleSortColumn,      //   handleSortColumn,

                null, // checkColEntry,
                null,       //   checkedKeys,
                null,       //   handleCheckAll,
                null,       //   handleCheck,

                false,      //   boolSelectButton,
                null,       //   onClickSelect,
                null,       //   selectButtonText,
                null,       //   selectHighlightRowKey,

                true,       //   link1bool,
                'Details',     //   link1text,
                this.onClickDetails,     //   link1onClick,

                true,       //   link2bool,
                'Edit',       //   link2text,
                this.onClickEditPo  //   link2onClick

            )


            shipmentPoTable =

                <div>

                    <Row >
                        <Col md={24} sm={24}>
                            <Panel bordered header="PO" style={{ marginBottom: 10 }}>
                                <Row>
                                    {receiveButton}
                                </Row>

                                <Row>
                                    {shipmentPoTableElement}
                                </Row>

                            </Panel>
                        </Col>
                    </Row>

                </div>


            breakdownFormButton = <div>
                {(this.props.isMobile == true) ? <div></div> :
                    <div style={{ float: 'right' }}>
                        <ExcelFile element={<Button style={{ float: 'right', marginRight: '10px' }}>Breakdown Form</Button>} filename={this.props.ContainerData[0].Container + '_BreakdownForm_' + currentDateTime}>
                            <ExcelSheet dataSet={this.formatDataSet(this.props.PoData)} name="BreakdownForm">
                            </ExcelSheet>
                        </ExcelFile>
                    </div>
                }
            </div>

            editButton = <div style={{ float: 'right' }}>
                <Button style={{ float: 'right' }} onClick={(e) => { this.onClickEditContainer(e) }} >Edit</Button>
            </div>


            // if (Object.keys(this.state.LocationDict).length > 0 && typeof (this.state.LocationDict) !== 'undefined' && this.state.LocationDict != null) {

            //     receiveDrawer = <ReceiveContainerDrawer {...this.props} state={this.state} onChange={this.onChangeReceiveDrawer} onChangeLocation={this.onChangeReceiveDrawerLocations} onClickAddLocation={this.onClickAddLocation} confirm={this.onClickReceiveDrawerSave} close={this.closeReceiveDrawer} onClickAddMissing={this.onClickAddMissing} masterLabel={currentContainer} />
            // }
            // else {
            //     receiveDrawer = <div></div>
            // }


        }
        else {
            shipmentPoTable = <div></div>
            breakdownFormButton = <div></div>
            receiveButton = <div></div>
            // receiveDrawer = <div></div>
            editButton = <div></div>
        }


        let receiveDrawer;
        if (this.props.isShipmentReceiveLoading) {
            receiveDrawer = <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>
        }
        else if (this.props.isPoLocationLoading) {

            receiveDrawer = <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>
        }
        else if (!this.state.showReceiveDrawer) {
            receiveDrawer = <div></div>
        }
        else if (Object.keys(this.state.LocationDict).length > 0 && typeof (this.state.LocationDict) !== 'undefined' && this.state.LocationDict != null) {
            receiveDrawer = <ReceiveContainerDrawer {...this.props} state={this.state} onChange={this.onChangeReceiveDrawer} onChangeLocation={this.onChangeReceiveDrawerLocations} onClickAddLocation={this.onClickAddLocation} onClickRemoveFromAdd={this.onClickRemoveFromAdd} confirm={this.onClickReceiveDrawerSave} close={this.closeReceiveDrawer} onClickAddMissing={this.onClickAddMissing} masterLabel={currentContainer} />
        }



        let recoveryTimeline;
        if (this.props.isPoRecoveryLoading) {
            recoveryTimeline =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.PoRecoveryData[0]) !== 'undefined' && this.props.PoRecoveryData != null) {
            recoveryTimeline =

                <div>

                    <Row >
                        <Col md={24} sm={24}>
                            <Panel bordered header="Recovery" style={{ marginBottom: 10 }}>
                                <Timeline>
                                    {this.props.PoRecoveryData.map(item => (
                                        <Timeline.Item>
                                            {item.PoRecoveryDT}  &nbsp;
                                            {item.EmailAddr}
                                            <l><br />{item.MBOL} &nbsp; / &nbsp;
                                                {item.HBOL} &nbsp; / &nbsp;
                                                {item.PO}
                                            </l>
                                            {item.CaseCountExpected != null ? <l><br /> Case Count Expected:  {item.CaseCountExpected} </l> : ' '}
                                            {item.CasesReceived != null ? <l><br /> Cases Received:  {item.CasesReceived} </l> : ' '}
                                            {item.PalletCount != null ? <l><br /> Pallet Count:  {item.PalletCount} </l> : ' '}
                                            {item.Notes != null ? <l><br /> Notes:  {item.Notes} </l> : ' '}


                                        </Timeline.Item>
                                    ))}
                                </Timeline>

                            </Panel>
                        </Col>
                    </Row>

                </div>
        }

        let unsavedEditContainerChanges;
        unsavedEditContainerChanges = this.state.unsavedEditContainerValues




        let containerHistoryTimeline;
        if (this.props.isContainerHistoryLoading) {
            containerHistoryTimeline =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.ContainerHistoryData[0]) !== 'undefined' && this.props.ContainerHistoryData != null) {
            containerHistoryTimeline =

                <div>

                    <Row >
                        <Col md={24} sm={24}>
                            <Panel bordered header="History" style={{ marginBottom: 10 }}>
                                <Timeline>
                                    {this.props.ContainerHistoryData.map(item => (
                                        <Timeline.Item>
                                            {item.ContainerHistoryDT}  &nbsp;
                                            {item.EmailAddr} &nbsp;
                                            <l><br />{item.ContainerHistoryNotes} &nbsp;
                                            </l>
                                            {item.Container != null ? <l><br /> Container:  {item.Container} </l> : ' '}
                                            {item.LCL_FCL != null ? <l><br /> LCL/FCL:  {item.LCL_FCL} </l> : ' '}
                                            {item.DeliveryAppointment != null ? <l><br /> Delivery Appointment:  {item.DeliveryAppointment} </l> : ' '}
                                            {item.ETD != null ? <l><br /> ETD:  {item.ETD} </l> : ' '}
                                            {item.ETA != null ? <l><br /> ETA:  {item.ETA} </l> : ' '}
                                            {item.Notes != null ? <l><br /> Notes:  {item.Notes} </l> : ' '}
                                        </Timeline.Item>
                                    ))}
                                </Timeline>

                            </Panel>
                        </Col>
                    </Row>

                </div>
        }
        else {
            containerHistoryTimeline = <div></div>
        }




        let warehouseLocationTable;
        if (this.props.isPoLocationLoading) {
            warehouseLocationTable =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.PoLocationData[0]) !== 'undefined' && this.props.PoLocationData != null) {
            warehouseLocationTable = <div>

                <Row >
                    <Col md={24} sm={24}>
                        <Panel bordered header="Warehouse Locations" style={{ marginBottom: 10 }}>

                            <Row>
                                {RTableGeneral(
                                    tableIdCol,     //   keyColumn,
                                    this.props.PoLocationData,     //   tableData,
                                    TransContainerWarehouseLocationDataElements,       //   DataElements,
                                    this.props.isPoLocationLoading,        //   loading,

                                    this.props.isMobile,        //   isMobile,
                                    this.props.history,     //   history,

                                    sortColumn,     //   sortColumn,
                                    sortType,       //   sortType,
                                    this.handleSortColumn,      //   handleSortColumn,

                                    null, // checkColEntry,
                                    null,       //   checkedKeys,
                                    null,       //   handleCheckAll,
                                    null,       //   handleCheck,

                                    false,      //   boolSelectButton,
                                    null,       //   onClickSelect,
                                    null,       //   selectButtonText,
                                    null,       //   selectHighlightRowKey,

                                    true,       //   link1bool,
                                    'Edit',     //   link1text,
                                    this.onclickEditWarehouseLocation,     //   link1onClick,

                                    false,       //   link2bool,
                                    null,       //   link2text,
                                    null  //   link2onClick

                                )}
                            </Row>

                        </Panel>
                    </Col>
                </Row>

            </div>

        }
        else {
            warehouseLocationTable = <div></div>
        }




        //Edit Po Drawer
        let unsavedEditPoChanges;
        unsavedEditPoChanges = this.state.unsavedEditPoValues

        let editContainerDiv;
        if (!this.state.showEditContainerDrawer) {
            editContainerDiv = <div></div>
        }
        else {
            editContainerDiv = <EditContainerDrawer {...this.props} state={this.state} onChangeEditContainer={this.onChangeEditContainer} confirm={this.onClickConfirmEditContainer} close={this.closeEditContainerDrawer} unsavedChanges={unsavedEditContainerChanges} />
        }


        let editWarehouseLocationDrawerDiv;
        let unsavedLocationChanges
        if (!this.state.showEditWarehouseLocationDrawer) {
            editWarehouseLocationDrawerDiv = <div></div>
            unsavedLocationChanges = false
        }
        else {
            unsavedLocationChanges = this.state.unsavedEditWarehouseLocationValues
            editWarehouseLocationDrawerDiv = <EditWarehouseLocationDrawer {...this.props} state={this.state} onChangeExistingOversize={this.onChangeExistingOversize} onChangeLocation={this.onChangeEditLocationDrawer} onClickAddLocation={this.onClickAddLocationEditDrawer} confirm={this.onClickSaveEditWarehouseLocation} close={this.closeEditWarehouseLocationDrawer} unsavedChanges={unsavedLocationChanges} />
        }

        return (
            <div>
                <h3>
                    <Row>{pageHeader}  {editButton}  {breakdownFormButton}   </Row>
                </h3>

                <PanelGroup>

                    {detailsDiv}
                    {ContainerJsonTreeDiv}
                    {shipmentPoTable}
                    {warehouseLocationTable}
                    {recoveryTimeline}
                    {containerHistoryTimeline}


                </PanelGroup>


                {receiveDrawer}


                <EditPoDrawer {...this.props} state={this.state} onChangeEditPo={this.onChangeEditPo} onChangeCustomerRef={this.onChangeCustomerRef} onChangeProject={this.onChangeProject} confirm={this.onClickConfirmEditPo} close={this.closeEditPoDrawer} unsavedChanges={unsavedEditPoChanges} />

                {editContainerDiv}

                {editWarehouseLocationDrawerDiv}

                {fileUploadsTable}
                {fileUploadDiv}
            </div>
        );

    }
}

export default withRouter(ContainerDetails);
