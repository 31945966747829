import * as moment from 'moment';
import React, {
    PureComponent
} from 'react';
import ReactExport from "react-export-excel";
import { withRouter } from 'react-router-dom';
import {
    Col,
    Loader,
    Panel
} from 'rsuite';
import { appAPI } from '../../../app/appConfig';
import { customerConfig } from '../../../app/appCustomerConfig';
import RTableGeneral from '../../componentsGeneral/Rsuite/Table/RTableGeneral';
import LabelGenResponsePackageLabelsDataElements from '../../dataComponentsLabelGen/LabelGenResponsePackageLabelsDataElements';

const styles = {
  marginBottom: '10px',
  width: '100%'
};




const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")
const tableIdCol = "LabelRequestID"

const bulkTemplateData = [{
  "UniqueKey_ParcelID": "",
  // "MasterCartonID_ContainerID": "",
  "OrderInfo_OrderNo": ""
}]



class LabelGenHistory extends PureComponent {
  constructor() {
    super();
    this.state = {
      //Warehouse & Customer Arrays
      warehouseArray: {},
      customerArray: {},

      // Table Check Box
      checkedKeys: [],

      input: '',


      // formInputParcelID: '',

      // formInputOrderNo: '',
      // formInputOrderDate: '',


      // formInputName: '',
      // formInputAddressLine1: '',
      // formInputAddressLine2: '',
      // formInputCity: '',
      // formInputState: '',
      // formInputPostcode: '',
      // formInputCountryCode: '',
      // formInputEndContactPhone: '',
      // formInputEndContactMobileNumber: '',
      // formInputEndContactEmail: '',
      // formInputReturnName: '',
      // formInputReturnAddressLine1: '',
      // formInputReturnAddressLine2: '',
      // formInputReturnCity: '',
      // formInputReturnState: '',
      // formInputReturnPostcode: '',
      // formInputReturnCountryCode: '',
      // formInputParcelWeight: '',
      // formInputWidth: '',
      // formInputLength: '',
      // formInputHeight: '',
      // formInputParcelCIFValue: '',
      // formInputProductInfo: '',

      envToggleValue: 'Test',

      ProdEnvironment: false,

      inputFormat: 'webform',
      // printFormat: null,

      unsavedChanges: false,

      //Request
      LabelRequestID: null,


      //Input Form
      CustomerID: null,
      WarehouseID: null,

      //Ship Request Ref
      Shipment_Description: '',
      Shipper_Name: '',
      Shipper_AttentionName: '',
      Shipper_CompanyDisplayableName: '',
      Shipper_TaxIdentificationNumber: '',
      Shipper_Phone_Number: '',
      Shipper_Phone_Extension: '',
      Shipper_ShipperNumber: '',
      Shipper_FaxNumber: '',
      Shipper_EMailAddress: '',
      Shipper_Address_AddressLine1: '',
      Shipper_Address_AddressLine2: '',
      Shipper_Address_AddressLine3: '',
      Shipper_Address_City: '',
      Shipper_Address_StateProvinceCode: '',
      Shipper_Address_PostalCode: '',
      Shipper_Address_CountryCode: '',
      ShipTo_Name: '',
      ShipTo_AttentionName: '',
      ShipTo_CompanyDisplayableName: '',
      ShipTo_TaxIdentificationNumber: '',
      ShipTo_Phone_Number: '',
      ShipTo_Phone_Extension: '',
      ShipTo_FaxNumber: '',
      ShipTo_EMailAddress: '',
      ShipTo_AddressLine1: '',
      ShipTo_AddressLine2: '',
      ShipTo_AddressLine3: '',
      ShipTo_Address_City: '',
      ShipTo_Address_StateProvinceCode: '',
      ShipTo_Address_PostalCode: '',
      ShipTo_Address_CountryCode: '',
      ShipTo_Address_ResidentialAddressIndicator: '',
      ShipFrom_Name: '',
      ShipFrom_AttentionName: '',
      ShipFrom_CompanyDisplayableName: '',
      ShipFrom_TaxIdentificationNumber: '',
      ShipFrom_Phone_Number: '',
      ShipFrom_Phone_Extension: '',
      ShipFrom_FaxNumber: '',
      ShipFrom_Address_AddressLine1: '',
      ShipFrom_Address_AddressLine2: '',
      ShipFrom_Address_AddressLine3: '',
      ShipFrom_Address_City: '',
      ShipFrom_Address_StateProvinceCode: '',
      ShipFrom_Address_PostalCode: '',
      ShipFrom_Address_CountryCode: '',
      PayInfo_ShipCharge_Type: '',
      PayInfo_ShipCharge_BillShipper_AccountNumber: '',
      PayInfo_ShipCharge_BillThirdParty_AccountNumber: '',
      PayInfo_ShipCharge_BillThirdParty_Address_PostalCode: '',
      PayInfo_ShipCharge_BillThirdParty_Address_CountryCode: '',
      Service_Code: '',
      Service_Description: '',
      USPSEndorsement: '',
      MILabelCN22Indicator: '',
      CostCenter: '',
      CostCenterBarcodeIndicator: '',
      PackageID: '',
      PackageIDBarcodeIndicator: '',
      SSO_IF_FormType: '',
      SSO_IF_CN22Fm_LabelSize: '',
      SSO_IF_CN22Fm_PrintsPerPage: '',
      SSO_IF_CN22Fm_LabelPrintType: '',
      SSO_IF_CN22Fm_CN22Type: '',
      SSO_IF_CN22Fm_CN22OtherDescription: '',
      SSO_IF_CN22Fm_CN22Cont_CN22ContQuantity: '',
      SSO_IF_CN22Fm_CN22Cont_CN22ContDescription: '',
      SSO_IF_CN22Fm_CN22Cont_CN22ContWeight_UOM_Code: '',
      SSO_IF_CN22Fm_CN22Cont_CN22ContWeight_Weight: '',
      SSO_IF_CN22Fm_CN22Cont_CN22ContTotalValue: '',
      SSO_IF_CN22Fm_CN22Cont_CN22ContCurrencyCode: '',
      SSO_IF_Product_Description: '',
      SSO_IF_CurrencyCode: '',
      LabelSpec_LabelImageFormat_Code: null,
      LabelSpec_LabelStockSize_Height: '',
      LabelSpec_LabelStockSize_Width: '',

      //Package Info
      // PackageDict: {},
      PackageDict: [
        {
          'RequestPackageID': null,
          'LabelRequestID': null,
          'PackageIndex': 1,
          'Package_Description': null,
          'Package_Packaging_Code': null,
          'Package_Dimensions_UOM_Code': null,
          'Package_Dimensions_Length': null,
          'Package_Dimensions_Width': null,
          'Package_Dimensions_Height': null,
          'Package_PackageWeight_UOM_Code': null,
          'Package_PackageWeight_UOM_Description': null,
          'Package_PackageWeight_Weight': null,
          'Package_PkgSO_DeliveryConfirmation_DCISType': null,
          'Package_PkgSO_DeclaredValue_CurrencyCode': null,
          'Package_PkgSO_DeclaredValue_MonetaryValue': null
        }
      ],

      //Product Info
      ProductDict: [
        {
          'RequestProductInfoID': null,
          'LabelRequestID': null,
          'ProductInfoIndex': 1,
          'ProductCode': null,
          'ProductDescription': null,
          'ProductUnitValue': null,
          'CurrencyCode': null,
          'ProductItemOrigin': null,
          'ProductHarmonizedCode': null,
          'ProductUnitWeight': null,
          'ProductQuantity': null,
          'ProductFabricContent': null,
          'Hazmat': null,
          'HazmatCode': null,
          'FDAFlag': null,
          'FDACode': null
        }
      ],


      PrinterIP: '',
      PrinterPort: '',



      //Add new Package Modal
      addNewPackageModalShown: false,
      new_RequestPackageID: null,
      new_PackageIndex: null,
      new_Package_Description: '',
      new_Package_Packaging_Code: '',
      new_Package_Dimensions_UOM_Code: '',
      new_Package_Dimensions_Length: '',
      new_Package_Dimensions_Width: '',
      new_Package_Dimensions_Height: '',
      new_Package_PackageWeight_UOM_Code: '',
      new_Package_PackageWeight_UOM_Description: '',
      new_Package_PackageWeight_Weight: '',
      new_Package_PkgSO_DeliveryConfirmation_DCISType: '',
      new_Package_PkgSO_DeclaredValue_CurrencyCode: '',
      new_Package_PkgSO_DeclaredValue_MonetaryValue: '',

      //Add new Product Modal
      addNewProductModalShown: false,
      new_RequestPackageID: null,
      new_RequestProductInfoID: null,
      new_ProductInfoIndex: null,
      new_ProductCode: '',
      new_ProductDescription: '',
      new_ProductUnitValue: '',
      new_CurrencyCode: 'USD',
      new_ProductItemOrigin: '',
      new_ProductHarmonizedCode: '',
      new_ProductUnitWeight: '',
      new_ProductQuantity: '',
      new_ProductFabricContent: '',
      new_Hazmat: '',
      new_HazmatCode: '',
      new_FDAFlag: '',
      new_FDACode: '',


      //Edit Shipper Modal
      editShipperModalShown: false,

      //Edit ShipFrom Modal
      editShipFromModalShown: false,

      UserSavedValuesID: null,




    };

    // Table Check Box
    this.handleCheckAll = this.handleCheckAll.bind(this);
    this.handleCheck = this.handleCheck.bind(this);

    // Table Sort
    this.handleSortColumn = this.handleSortColumn.bind(this);

    //Filter hide Show
    this.onClickIsFilterShown = this.onClickIsFilterShown.bind(this);
    this.onClickResetFilters = this.onClickResetFilters.bind(this);



    //Existing Label
    this.onClickLoadPendingLabel = this.onClickLoadPendingLabel.bind(this);
    this.onClickLoadRequestedLabel = this.onClickLoadRequestedLabel.bind(this);

    this.onClickDownloadLabel = this.onClickDownloadLabel.bind(this);


  }


  resetState() {
    this.setState({
      input: '',

      envToggleValue: 'Test',
      ProdEnvironment: false,

      inputFormat: 'webform',

      unsavedChanges: false,

      //Request
      LabelRequestID: null,

      //Input Form
      CustomerID: null,
      WarehouseID: null,

      //Ship Request Ref
      Shipment_Description: '',
      Shipper_Name: '',
      Shipper_AttentionName: '',
      Shipper_CompanyDisplayableName: '',
      Shipper_TaxIdentificationNumber: '',
      Shipper_Phone_Number: '',
      Shipper_Phone_Extension: '',
      Shipper_ShipperNumber: '',
      Shipper_FaxNumber: '',
      Shipper_EMailAddress: '',
      Shipper_Address_AddressLine1: '',
      Shipper_Address_AddressLine2: '',
      Shipper_Address_AddressLine3: '',
      Shipper_Address_City: '',
      Shipper_Address_StateProvinceCode: '',
      Shipper_Address_PostalCode: '',
      Shipper_Address_CountryCode: '',
      ShipTo_Name: '',
      ShipTo_AttentionName: '',
      ShipTo_CompanyDisplayableName: '',
      ShipTo_TaxIdentificationNumber: '',
      ShipTo_Phone_Number: '',
      ShipTo_Phone_Extension: '',
      ShipTo_FaxNumber: '',
      ShipTo_EMailAddress: '',
      ShipTo_AddressLine1: '',
      ShipTo_AddressLine2: '',
      ShipTo_AddressLine3: '',
      ShipTo_Address_City: '',
      ShipTo_Address_StateProvinceCode: '',
      ShipTo_Address_PostalCode: '',
      ShipTo_Address_CountryCode: '',
      ShipTo_Address_ResidentialAddressIndicator: '',
      ShipFrom_Name: '',
      ShipFrom_AttentionName: '',
      ShipFrom_CompanyDisplayableName: '',
      ShipFrom_TaxIdentificationNumber: '',
      ShipFrom_Phone_Number: '',
      ShipFrom_Phone_Extension: '',
      ShipFrom_FaxNumber: '',
      ShipFrom_Address_AddressLine1: '',
      ShipFrom_Address_AddressLine2: '',
      ShipFrom_Address_AddressLine3: '',
      ShipFrom_Address_City: '',
      ShipFrom_Address_StateProvinceCode: '',
      ShipFrom_Address_PostalCode: '',
      ShipFrom_Address_CountryCode: '',
      PayInfo_ShipCharge_Type: '',
      PayInfo_ShipCharge_BillShipper_AccountNumber: '',
      PayInfo_ShipCharge_BillThirdParty_AccountNumber: '',
      PayInfo_ShipCharge_BillThirdParty_Address_PostalCode: '',
      PayInfo_ShipCharge_BillThirdParty_Address_CountryCode: '',
      Service_Code: '',
      Service_Description: '',
      USPSEndorsement: '',
      MILabelCN22Indicator: '',
      CostCenter: '',
      CostCenterBarcodeIndicator: '',
      PackageID: '',
      PackageIDBarcodeIndicator: '',
      SSO_IF_FormType: '',
      SSO_IF_CN22Fm_LabelSize: '',
      SSO_IF_CN22Fm_PrintsPerPage: '',
      SSO_IF_CN22Fm_LabelPrintType: '',
      SSO_IF_CN22Fm_CN22Type: '',
      SSO_IF_CN22Fm_CN22OtherDescription: '',
      SSO_IF_CN22Fm_CN22Cont_CN22ContQuantity: '',
      SSO_IF_CN22Fm_CN22Cont_CN22ContDescription: '',
      SSO_IF_CN22Fm_CN22Cont_CN22ContWeight_UOM_Code: '',
      SSO_IF_CN22Fm_CN22Cont_CN22ContWeight_Weight: '',
      SSO_IF_CN22Fm_CN22Cont_CN22ContTotalValue: '',
      SSO_IF_CN22Fm_CN22Cont_CN22ContCurrencyCode: '',
      SSO_IF_Product_Description: '',
      SSO_IF_CurrencyCode: '',
      LabelSpec_LabelImageFormat_Code: null,
      LabelSpec_LabelStockSize_Height: '',
      LabelSpec_LabelStockSize_Width: '',

      //Package Info
      // PackageDict: {},
      PackageDict: [
        {
          'RequestPackageID': null,
          'LabelRequestID': null,
          'PackageIndex': 1,
          'Package_Description': null,
          'Package_Packaging_Code': null,
          'Package_Dimensions_UOM_Code': null,
          'Package_Dimensions_Length': null,
          'Package_Dimensions_Width': null,
          'Package_Dimensions_Height': null,
          'Package_PackageWeight_UOM_Code': null,
          'Package_PackageWeight_UOM_Description': null,
          'Package_PackageWeight_Weight': null,
          'Package_PkgSO_DeliveryConfirmation_DCISType': null,
          'Package_PkgSO_DeclaredValue_CurrencyCode': null,
          'Package_PkgSO_DeclaredValue_MonetaryValue': null
        }
      ],

      //Product Info
      ProductDict: [
        {
          'RequestProductInfoID': null,
          'LabelRequestID': null,
          'ProductInfoIndex': 1,
          'ProductCode': null,
          'ProductDescription': null,
          'ProductUnitValue': null,
          'CurrencyCode': "USD",
          'ProductItemOrigin': null,
          'ProductHarmonizedCode': null,
          'ProductUnitWeight': null,
          'ProductQuantity': null,
          'ProductFabricContent': null,
          'Hazmat': null,
          'HazmatCode': null,
          'FDAFlag': null,
          'FDACode': null
        }
      ],

      PrinterIP: '',
      PrinterPort: '',

      //Add new Package Modal
      addNewPackageModalShown: false,
      new_RequestPackageID: null,
      new_PackageIndex: null,
      new_Package_Description: '',
      new_Package_Packaging_Code: '',
      new_Package_Dimensions_UOM_Code: '',
      new_Package_Dimensions_Length: '',
      new_Package_Dimensions_Width: '',
      new_Package_Dimensions_Height: '',
      new_Package_PackageWeight_UOM_Code: '',
      new_Package_PackageWeight_UOM_Description: '',
      new_Package_PackageWeight_Weight: '',
      new_Package_PkgSO_DeliveryConfirmation_DCISType: '',
      new_Package_PkgSO_DeclaredValue_CurrencyCode: '',
      new_Package_PkgSO_DeclaredValue_MonetaryValue: '',

      //Add new Product Modal
      addNewProductModalShown: false,
      new_RequestPackageID: null,
      new_RequestProductInfoID: null,
      new_ProductInfoIndex: null,
      new_ProductCode: '',
      new_ProductDescription: '',
      new_ProductUnitValue: '',
      new_CurrencyCode: 'USD',
      new_ProductItemOrigin: '',
      new_ProductHarmonizedCode: '',
      new_ProductUnitWeight: '',
      new_ProductQuantity: '',
      new_ProductFabricContent: '',
      new_Hazmat: '',
      new_HazmatCode: '',
      new_FDAFlag: '',
      new_FDACode: '',

      //Edit Shipper Modal
      editShipperModalShown: false,

      //Edit ShipFrom Modal
      editShipFromModalShown: false,

      UserSavedValuesID: null,




    });

  }

  componentDidMount() {
    this.getsessionStorageWarehouse() //this will preserve menu selection
    this.loadSortFromStorage()


    if (typeof (this.props.warehouseSelection) === 'number') {
      this.setState({
        WarehouseID: this.props.warehouseSelection
      });
    }

    if (typeof (this.props.customerSelection) === 'number') {
      this.setState({
        CustomerID: this.props.customerSelection
      });
    }






  }



  componentDidUpdate(prevProps, prevState) {

    if (prevProps.location.search !== this.props.location.search) {
      try {


      }
      catch (e) { }
    }

  }



  getsessionStorageWarehouse() {

    try {
      let warehouseMenuList = JSON.parse(sessionStorage.getItem('AppConfig.WarehouseArray'));
      if (warehouseMenuList != null && warehouseMenuList != undefined) {
        this.setState({
          warehouseArray: warehouseMenuList
        })
      }
    }
    catch (e) { }

    try {
      let customerMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CustomerArray'));
      if (customerMenuList != null && customerMenuList != undefined) {
        this.setState({
          customerArray: customerMenuList
        })
      }
    }
    catch (e) { }

  }





  // Table Check Box
  handleCheckAll(value, checked, data) {
    const checkedKeys = checked ? [] : data.map(item => item[tableIdCol]);
    this.setState({
      checkedKeys
    });
  }
  handleCheck(value, checked) {
    const { checkedKeys } = this.state;
    const nextCheckedKeys = checked
      ? [...checkedKeys, value]
      : checkedKeys.filter(item => item !== value);

    this.setState({
      checkedKeys: nextCheckedKeys
    });
  }

  // Table Sort
  saveSortTosessionStorage(sortColumn, sortType) {
    try {
      sessionStorage.setItem('sortcolumn', sortColumn)
    }
    catch (e) { }
    try {
      sessionStorage.setItem('sortType', sortType)
    }
    catch (e) { }
    try {
      sessionStorage.setItem('sortURLPathname', this.props.location.pathname)
    }
    catch (e) { }
  }
  loadSortFromStorage() {
    var sortColumn = null
    var sortType = null
    var pathName = null
    try {
      sortColumn = sessionStorage.getItem('sortcolumn')
    }
    catch (e) { }
    try {
      sortType = sessionStorage.getItem('sortType')
    }
    catch (e) { }
    try {
      pathName = sessionStorage.getItem('sortURLPathname')
    }
    catch (e) { }
    if (pathName === this.props.location.pathname) {
      this.handleSortColumn(sortColumn, sortType)
    }
  }
  handleSortColumn(sortColumn, sortType) {
    this.setState({
      loading: true
    });
    this.saveSortTosessionStorage(sortColumn, sortType)
    setTimeout(() => {
      this.setState({
        sortColumn,
        sortType,
        loading: false
      });
    }, 100);
  }




  onClickIsFilterShown(e, isFilterShown) {
    if (isFilterShown) {
      this.setState({
        isFilterShown: false
      });
    }
    else {
      this.setState({
        isFilterShown: true
      });
    }
  }

  onClickResetFilters(e) {
    this.setState({
      isFilterShown: false
    });
    this.setState({
      searchPro: '',
      // searchCustomer: null,
      searchCountry: null,
      searchPO: '',
      searchStatus: null,

    });
    this.props.history.push({
      search: ''
    });

    setTimeout(() => {
      this.setState({
        isFilterShown: true
      });
    }, 500);
  }




  //Load Existing Label
  onClickLoadPendingLabel(e, rowData, history) {
    var paramLabelRequestID = rowData['LabelRequestID']

    this.props.labelGenRequestPackageGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, paramLabelRequestID)
    this.props.labelGenRequestPackageProductInfoGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, paramLabelRequestID)

    this.setState({
      LabelRequestID: paramLabelRequestID,
      unsavedChanges: false
    });

    try {
      history.push({
        pathname: `/${customerConfig.customerUrl}/wms/labelgen/labelgeneration/`,
        search: 'labelrequestid=' + paramLabelRequestID
      });
    }
    catch (e) {
    }
    this.loadExistingLabelToState(paramLabelRequestID)
  }

  onClickLoadRequestedLabel(e, rowData, history) {
    var paramID = rowData['LabelRequestID']
    try {
      //TODO: need to load existing label but save with new id
      history.push({
        pathname: `/${customerConfig.customerUrl}/wms/labelgen/labelgeneration/`,
        search: 'labelrequestid=' + paramID
      });
    }
    catch (e) {
    }


    this.loadExistingLabelToState(paramID)
  }

  loadPackageToState() {
    try {
      var currentLabelData = this.props.LabelGenRequestPackageData.slice()
      this.setState({
        PackageDict: currentLabelData
      });
      this.forceUpdate()
    }
    catch (e) { }
  }

  loadProductToState() {
    try {
      var currentLabelData = this.props.LabelGenRequestPackageProductInfoData.slice()
      this.setState({
        ProductDict: currentLabelData
      });
      this.forceUpdate()
    }
    catch (e) { }
  }

  loadExistingLabelToState(idToLoad) {

    try {
      var currentLabelData = this.props.LabelGenResponsePackageLabelsData.filter(x => x.LabelRequestID === idToLoad)
      this.setState({
        unsavedChanges: false,
        CustomerID: currentLabelData[0].CustomerID,
        WarehouseID: currentLabelData[0].WarehouseID,

        Shipment_Description: currentLabelData[0].Shipment_Description,
        Shipper_Name: currentLabelData[0].Shipper_Name,
        Shipper_AttentionName: currentLabelData[0].Shipper_AttentionName,
        Shipper_CompanyDisplayableName: currentLabelData[0].Shipper_CompanyDisplayableName,
        Shipper_TaxIdentificationNumber: currentLabelData[0].Shipper_TaxIdentificationNumber,
        Shipper_Phone_Number: currentLabelData[0].Shipper_Phone_Number,
        Shipper_Phone_Extension: currentLabelData[0].Shipper_Phone_Extension,
        Shipper_ShipperNumber: currentLabelData[0].Shipper_ShipperNumber,
        Shipper_FaxNumber: currentLabelData[0].Shipper_FaxNumber,
        Shipper_EMailAddress: currentLabelData[0].Shipper_EMailAddress,
        Shipper_Address_AddressLine1: currentLabelData[0].Shipper_Address_AddressLine1,
        Shipper_Address_AddressLine2: currentLabelData[0].Shipper_Address_AddressLine2,
        Shipper_Address_AddressLine3: currentLabelData[0].Shipper_Address_AddressLine3,
        Shipper_Address_City: currentLabelData[0].Shipper_Address_City,
        Shipper_Address_StateProvinceCode: currentLabelData[0].Shipper_Address_StateProvinceCode,
        Shipper_Address_PostalCode: currentLabelData[0].Shipper_Address_PostalCode,
        Shipper_Address_CountryCode: currentLabelData[0].Shipper_Address_CountryCode,
        ShipTo_Name: currentLabelData[0].ShipTo_Name,
        ShipTo_AttentionName: currentLabelData[0].ShipTo_AttentionName,
        ShipTo_CompanyDisplayableName: currentLabelData[0].ShipTo_CompanyDisplayableName,
        ShipTo_TaxIdentificationNumber: currentLabelData[0].ShipTo_TaxIdentificationNumber,
        ShipTo_Phone_Number: currentLabelData[0].ShipTo_Phone_Number,
        ShipTo_Phone_Extension: currentLabelData[0].ShipTo_Phone_Extension,
        ShipTo_FaxNumber: currentLabelData[0].ShipTo_FaxNumber,
        ShipTo_EMailAddress: currentLabelData[0].ShipTo_EMailAddress,
        ShipTo_AddressLine1: currentLabelData[0].ShipTo_AddressLine1,
        ShipTo_AddressLine2: currentLabelData[0].ShipTo_AddressLine2,
        ShipTo_AddressLine3: currentLabelData[0].ShipTo_AddressLine3,
        ShipTo_Address_City: currentLabelData[0].ShipTo_Address_City,
        ShipTo_Address_StateProvinceCode: currentLabelData[0].ShipTo_Address_StateProvinceCode,
        ShipTo_Address_PostalCode: currentLabelData[0].ShipTo_Address_PostalCode,
        ShipTo_Address_CountryCode: currentLabelData[0].ShipTo_Address_CountryCode,
        ShipTo_Address_ResidentialAddressIndicator: currentLabelData[0].ShipTo_Address_ResidentialAddressIndicator,
        ShipFrom_Name: currentLabelData[0].ShipFrom_Name,
        ShipFrom_AttentionName: currentLabelData[0].ShipFrom_AttentionName,
        ShipFrom_CompanyDisplayableName: currentLabelData[0].ShipFrom_CompanyDisplayableName,
        ShipFrom_TaxIdentificationNumber: currentLabelData[0].ShipFrom_TaxIdentificationNumber,
        ShipFrom_Phone_Number: currentLabelData[0].ShipFrom_Phone_Number,
        ShipFrom_Phone_Extension: currentLabelData[0].ShipFrom_Phone_Extension,
        ShipFrom_FaxNumber: currentLabelData[0].ShipFrom_FaxNumber,
        ShipFrom_Address_AddressLine1: currentLabelData[0].ShipFrom_Address_AddressLine1,
        ShipFrom_Address_AddressLine2: currentLabelData[0].ShipFrom_Address_AddressLine2,
        ShipFrom_Address_AddressLine3: currentLabelData[0].ShipFrom_Address_AddressLine3,
        ShipFrom_Address_City: currentLabelData[0].ShipFrom_Address_City,
        ShipFrom_Address_StateProvinceCode: currentLabelData[0].ShipFrom_Address_StateProvinceCode,
        ShipFrom_Address_PostalCode: currentLabelData[0].ShipFrom_Address_PostalCode,
        ShipFrom_Address_CountryCode: currentLabelData[0].ShipFrom_Address_CountryCode,
        PayInfo_ShipCharge_Type: currentLabelData[0].PayInfo_ShipCharge_Type,
        PayInfo_ShipCharge_BillShipper_AccountNumber: currentLabelData[0].PayInfo_ShipCharge_BillShipper_AccountNumber,
        PayInfo_ShipCharge_BillThirdParty_AccountNumber: currentLabelData[0].PayInfo_ShipCharge_BillThirdParty_AccountNumber,
        PayInfo_ShipCharge_BillThirdParty_Address_PostalCode: currentLabelData[0].PayInfo_ShipCharge_BillThirdParty_Address_PostalCode,
        PayInfo_ShipCharge_BillThirdParty_Address_CountryCode: currentLabelData[0].PayInfo_ShipCharge_BillThirdParty_Address_CountryCode,
        Service_Code: currentLabelData[0].Service_Code,
        Service_Description: currentLabelData[0].Service_Description,
        USPSEndorsement: currentLabelData[0].USPSEndorsement,
        MILabelCN22Indicator: currentLabelData[0].MILabelCN22Indicator,
        CostCenter: currentLabelData[0].CostCenter,
        CostCenterBarcodeIndicator: currentLabelData[0].CostCenterBarcodeIndicator,
        PackageID: currentLabelData[0].PackageID,
        PackageIDBarcodeIndicator: currentLabelData[0].PackageIDBarcodeIndicator,
        SSO_IF_FormType: currentLabelData[0].SSO_IF_FormType,
        SSO_IF_CN22Fm_LabelSize: currentLabelData[0].SSO_IF_CN22Fm_LabelSize,
        SSO_IF_CN22Fm_PrintsPerPage: currentLabelData[0].SSO_IF_CN22Fm_PrintsPerPage,
        SSO_IF_CN22Fm_LabelPrintType: currentLabelData[0].SSO_IF_CN22Fm_LabelPrintType,
        SSO_IF_CN22Fm_CN22Type: currentLabelData[0].SSO_IF_CN22Fm_CN22Type,
        SSO_IF_CN22Fm_CN22OtherDescription: currentLabelData[0].SSO_IF_CN22Fm_CN22OtherDescription,
        SSO_IF_CN22Fm_CN22Cont_CN22ContQuantity: currentLabelData[0].SSO_IF_CN22Fm_CN22Cont_CN22ContQuantity,
        SSO_IF_CN22Fm_CN22Cont_CN22ContDescription: currentLabelData[0].SSO_IF_CN22Fm_CN22Cont_CN22ContDescription,
        SSO_IF_CN22Fm_CN22Cont_CN22ContWeight_UOM_Code: currentLabelData[0].SSO_IF_CN22Fm_CN22Cont_CN22ContWeight_UOM_Code,
        SSO_IF_CN22Fm_CN22Cont_CN22ContWeight_Weight: currentLabelData[0].SSO_IF_CN22Fm_CN22Cont_CN22ContWeight_Weight,
        SSO_IF_CN22Fm_CN22Cont_CN22ContTotalValue: currentLabelData[0].SSO_IF_CN22Fm_CN22Cont_CN22ContTotalValue,
        SSO_IF_CN22Fm_CN22Cont_CN22ContCurrencyCode: currentLabelData[0].SSO_IF_CN22Fm_CN22Cont_CN22ContCurrencyCode,
        SSO_IF_Product_Description: currentLabelData[0].SSO_IF_Product_Description,
        SSO_IF_CurrencyCode: currentLabelData[0].SSO_IF_CurrencyCode,
        LabelSpec_LabelImageFormat_Code: currentLabelData[0].LabelSpec_LabelImageFormat_Code,
        LabelSpec_LabelStockSize_Height: currentLabelData[0].LabelSpec_LabelStockSize_Height,
        LabelSpec_LabelStockSize_Width: currentLabelData[0].LabelSpec_LabelStockSize_Width
      });
      this.forceUpdate()
    }
    catch (e) { }
  }




  onClickDownloadLabel(e, rowData, history) {
    try {
      var fileNameToDownload = rowData['LabelFilename']
      var fileNameToSave = rowData['TrackingNumber'] + '.' + rowData['LabelSpec_LabelImageFormat_Code']
      this.props.downloadFileFromS3(this.props.currentUserEmail, this.props.userSignInData.AuthToken, fileNameToDownload, fileNameToSave, appAPI.LabelGenDownloadS3)
    }
    catch (e) { }
  }



















  render() {
    const {
      envToggleValue,
      inputFormat,
      checkedKeys, sortColumn, sortType
    } = this.state;



    // let rTablePending;
    // if (this.props.isLabelGenResponsePackageLabelsLoading) {
    //   rTablePending =
    //     <div align="center">
    //       <section className="panel">
    //       </section>
    //       <Loader
    //         type="TailSpin"
    //         color="#3396E5"
    //         height="50"
    //         width="50"
    //       />
    //     </div>
    // }
    // else if (typeof (this.props.LabelGenResponsePackageLabelsData) !== 'undefined' && this.props.LabelGenResponsePackageLabelsData != null) {
    //   let rTable1 =
    //     RTableGeneral(
    //       tableIdCol,     //   keyColumn,
    //       this.props.LabelGenResponsePackageLabelsData.filter(x => x.ResponseSuccess === null),     //   tableData,
    //       LabelGenResponsePackageLabelsDataElements,       //   DataElements,
    //       this.props.isLabelGenResponsePackageLabelsLoading,        //   loading,

    //       this.props.isMobile,        //   isMobile,
    //       this.props.history,     //   history,

    //       sortColumn,     //   sortColumn,
    //       sortType,       //   sortType,
    //       this.handleSortColumn,      //   handleSortColumn,

    //       null, // checkColEntry,
    //       null,       //   checkedKeys,
    //       null,       //   handleCheckAll,
    //       null,       //   handleCheck,

    //       false,      //   onClickAddMilestone,
    //       this.onClickLoadPendingLabel,       //   onClickSelect,
    //       'Load',       //   selectButtonText,
    //       null,       //   selectHighlightRowKey,

    //       false,       //   link1bool,
    //       null,     //   link1text,
    //       null,     //   link1onClick,

    //       false,       //   link2bool,
    //       null,       //   link2text,
    //       null  //   link2onClick
    //     )

    //   rTablePending =
    //     <div>
    //       <Col md={24} sm={24}>
    //         <Panel bordered style={{ marginBottom: '10px' }}>
    //           {rTable1}
    //         </Panel>
    //       </Col>
    //     </div>
    // }
    // else {
    //   rTablePending = <div></div>
    // }






    let rTableRequested;
    if (this.props.isLabelGenResponsePackageLabelsLoading) {

      rTableRequested =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (typeof (this.props.LabelGenResponsePackageLabelsData) !== 'undefined' && this.props.LabelGenResponsePackageLabelsData != null) {
      let rTable2 =
        RTableGeneral(
          tableIdCol,     //   keyColumn,
          this.props.LabelGenResponsePackageLabelsData.filter(x => x.ResponseSuccess !== null),     //   tableData,
          LabelGenResponsePackageLabelsDataElements,       //   DataElements,
          this.props.isLabelGenResponsePackageLabelsLoading,        //   loading,

          this.props.isMobile,        //   isMobile,
          this.props.history,     //   history,

          sortColumn,     //   sortColumn,
          sortType,       //   sortType,
          this.handleSortColumn,      //   handleSortColumn,

          null, // checkColEntry,
          null,       //   checkedKeys,
          null,       //   handleCheckAll,
          null,       //   handleCheck,

          false,      //   onClickAddMilestone,
          this.onClickLoadRequestedLabel,       //   onClickSelect,
          'Load',       //   selectButtonText,
          null,       //   selectHighlightRowKey,

          true,       //   link1bool,
          'Download',     //   link1text,
          this.onClickDownloadLabel,     //   link1onClick,

          false,       //   link2bool,
          null,       //   link2text,
          null  //   link2onClick
        )

      rTableRequested =
        <div>
          <Col md={24} sm={24}>
            <Panel bordered style={{ marginBottom: '10px' }}>
              {rTable2}
            </Panel>
          </Col>
        </div>
    }
    else {
      rTableRequested = <div></div>
    }



    return (
      <div>

        <h3>
          Label History
        </h3>

        <br />
        <br />


        {/* {rTablePending} */}
        {rTableRequested}















      </div>
    );
  }
}


export default withRouter(LabelGenHistory);



  // ToDo: only allow user to submit JSON with their customer name