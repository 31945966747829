import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Menu from './menu/Menu';
import Horloge from './Horloge/Horloge';
import { appConfig } from '../../../../app/appConfig'
// import {navigationSelectionOptions} from '../../../../app/appSettings'
import Select from "react-dropdown-select";
import RSelectPicker from '../../../componentsGeneral/Rsuite/InputPicker/RSelectPicker'
// import {navMenu} from '../../../../routes/MainRoutes'
import { SelectPicker, InputPicker, ControlLabel, Tooltip, CheckPicker, Checkbox, Button, Input } from 'rsuite';

import RCheckPicker from '../../../componentsGeneral/Rsuite/InputPicker/RCheckPicker';


const styles = {
    // width: 300,
    margin: 10,
    marginBottom: '30px'
};

const styles2 = {
    // width: 300,
    margin: 10,
    marginBottom: '5px'
};

const footerStyles = {
    padding: '10px 2px',
    borderTop: '1px solid #e5e5e5'
};

const footerButtonStyle = {
    float: 'right',
    marginRight: 10,
    marginTop: 2
};

//   const allValue = dropDownData.map(item => item.value);

//   var selectedValueLength = 0;
//   try{
//     selectedValueLength = selectedValue.length
//   }
//   catch(e) {}

//vasView --> VasHome --> VasHomeMenu (this)

function indeterminate ( menuSelection, menuArray ){


}

const mountRef = React.createRef();

const VasHomeMenu = ({
    currentView,
    sideMenu,
    isAnimated,
    isCollapsed,
    isMobile,
    //    dropDownOnChange,
    admin,
    //    group, 
    role,
    warehouseArray,
    warehouseSelection,
    organizationSelection,
    warehouseMultiSelection,
    customerArray,
    customerSelection,
    customerMultiSelection,
    onChangeWarehouseSelection,
    onChangeCustomerSelection

    //    navSelection,
    //    languageSelection
}) => (
    <aside
        className={
            cx({
                'no-print': true,
                'left-side': true,
                'aside-left--fixed': true,
                'sidebar-offcanvas': true,
                'sidebar-animated': isAnimated,
                'collapse-left': isCollapsed
            })
        }
        // add overflow to left sidebar:
        style={{
            height: '100%',
            overflowx: 'auto!important',
            position: 'fixed',
            zIndex: '10000',
            marginBottom: '150px'
        }}
    >
        <section className="sidebar">

            {/* <h2 style={{background: '#E0E0E0'}}>
                <img  src={appConfig.largeEvolveIcon}/>
            </h2>
             */}
            <br />
            <div className="left-header" >

            </div>

            <div ref={mountRef}>


                {
                    warehouseArray.length === 1 ?
                        <div style={styles2}>
                            <Input
                                value={warehouseArray[0].label}
                                readOnly
                                block
                            />
                        </div>

                        :
                        // admin === true ?

                            <div style={styles2} >

                                <CheckPicker
                                    data={warehouseArray}
                                    placeholder="Warehouse..."
                                    style={{ width: 224 }}
                                    value={warehouseMultiSelection}
                                    onChange={(e) => { onChangeWarehouseSelection(e) }}
                                    renderExtraFooter={() => (
                                        <div style={footerStyles}>
                                            <Checkbox
                                                inline
                                                // indeterminate={indeterminate( warehouseMultiSelection, warehouseArray )}
                                                indeterminate={warehouseMultiSelection.length > 0 && warehouseMultiSelection.length < warehouseArray.map(item => item.value).length}
                                                checked={warehouseMultiSelection.length === warehouseArray.map(item => item.value).length}
                                                onChange={(e) => { (warehouseMultiSelection.length === warehouseArray.map(item => item.value).length ? onChangeWarehouseSelection([]) : onChangeWarehouseSelection(warehouseArray.map(item => item.value))) }}
                                            >
                                                Select all
                                            </Checkbox>
                                        </div>
                                    )}
                                />

                            </div>



                            // :
                            // <div style={styles2}>
                            //     <InputPicker
    // preventOverflow
                            //         placeholder={'Warehouse...'}
                            //         value={warehouseSelection}
                            //         onChange={(e) => { onChangeWarehouseSelection(e) }}
                            //         data={warehouseArray}
                            //         block
                            //         cleanable={false}
                            //         container={() => mountRef.current}
                            //     />
                            // </div>
                }

                {
                    customerArray.length === 1 ?
                        <div style={styles}>
                            <Input
                                value={customerArray[0].label}
                                readOnly
                                block
                            />
                        </div>
                        :
                        // admin === true ?

                            <div style={styles} >

                                <CheckPicker
                                    data={customerArray}
                                    placeholder="Customer..."
                                    style={{ width: 224 }}
                                    value={customerMultiSelection}
                                    onChange={(e) => { onChangeCustomerSelection(e) }}
                                    renderExtraFooter={() => (
                                        <div style={footerStyles}>
                                            <Checkbox
                                                inline
                                                indeterminate={customerMultiSelection.length > 0 && customerMultiSelection.length < customerArray.map(item => item.value).length}
                                                checked={customerMultiSelection.length === customerArray.map(item => item.value).length}
                                                onChange={(e) => { (customerMultiSelection.length === customerArray.map(item => item.value).length ? onChangeCustomerSelection([]) : onChangeCustomerSelection(customerArray.map(item => item.value))) }}
                                            >
                                                Select all
                                            </Checkbox>
                                        </div>
                                    )}
                                />

                            </div>

                            // :

                            // <div style={styles}>
                            //     <InputPicker
    // preventOverflow
                            //         placeholder={'Customer...'}
                            //         value={customerSelection}
                            //         onChange={(e) => { onChangeCustomerSelection(e) }}
                            //         data={customerArray}
                            //         block
                            //         cleanable={false}
                            //         container={() => mountRef.current}
                            //     />

                            // </div>
                }

















            </div>


            {
                sideMenu.map(
                    ({ id, group, menus }, menuIdx) => {

                        return (
                            <Menu
                                key={menuIdx}
                                initialCollapseState={false} //{menuIdx === 0 ? false : null}
                                headerTitle={group}
                                headerBackColor="#3396E5"
                                activeView={currentView}
                                views={menus}
                                id={id}
                            />
                        );
                    }
                )
            }


            <div>&nbsp;</div>
            <br />
            <br />
            <br />
            <br />
            <br />


        </section>
    </aside>
);

VasHomeMenu.propTypes = {
    isAnimated: PropTypes.bool,
    isCollapsed: PropTypes.bool,
    sideMenu: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            group: PropTypes.string.isRequired,
            menus: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string.isRequired,
                    linkTo: PropTypes.string.isRequired,
                    faIconName: PropTypes.string.isRequired
                })
            )
        })
    ).isRequired,
    currentView: PropTypes.bool,
    isMobile: PropTypes.bool,
    // warehouseSelection: PropTypes.any,
    // dropDownOnChange: PropTypes.func,
    admin: PropTypes.bool,     //if user has admin rights
    // group:                  PropTypes.string,   //this is organizaton (Omni, google)
    // role:           PropTypes.node,   //this is role in organization (operations, sales)

    role: PropTypes.arrayOf(
        PropTypes.shape(
            {
                Admin: PropTypes.string,
                RoleID: PropTypes.string,
                RoleName: PropTypes.string
            }
        )
    ),
    // navSelection:           PropTypes.string,
    // languageSelection:      PropTypes.string

};

VasHomeMenu.defaultProps = {
    isAnimated: false,
    isCollapsed: false
};

export default VasHomeMenu;








// <div style={styles2}>
// <InputPicker
    // preventOverflow
//     placeholder={'Warehouse...'}
//     value={warehouseSelection}
//     onChange={(e) => { onChangeWarehouseSelection(e) }}
//     data={warehouseArray}
//     block
//     cleanable={false}
//     container={() => mountRef.current}
// />
// </div>


// <div style={styles}>
// <InputPicker
    // preventOverflow
//     placeholder={'Customer...'}
//     value={customerSelection}
//     onChange={(e) => { onChangeCustomerSelection(e) }}
//     data={customerArray}
//     block
//     cleanable={false}
//     container={() => mountRef.current}
// />

// </div>













// // This is for multi picker

// {
//     warehouseArray.length === 1 ?
//         <div style={styles2}>
//             <Input
//                 value={warehouseArray[0].label}
//                 readOnly
//                 block
//             />
//         </div>

//         :
//         admin === true ?

//             <div style={styles2} >

//                 <CheckPicker
//                     data={warehouseArray}
//                     placeholder="Warehouse..."
//                     style={{ width: 224 }}
//                     value={warehouseSelection}
//                     onChange={(e) => { onChangeWarehouseSelection(e) }}
//                     renderExtraFooter={() => (
//                         <div style={footerStyles}>
//                             <Checkbox
//                                 inline
//                                 indeterminate={warehouseSelection.length > 0 && warehouseSelection.length < warehouseArray.map(item => item.value).length}
//                                 checked={warehouseSelection.length === warehouseArray.map(item => item.value).length}
//                                 onChange={(e) => { (warehouseSelection.length === warehouseArray.map(item => item.value).length ? onChangeWarehouseSelection([]) : onChangeWarehouseSelection(warehouseArray.map(item => item.value))) }}
//                             >
//                                 Select all
//                             </Checkbox>
//                         </div>
//                     )}
//                 />

//             </div>



//             :
//             <div style={styles2}>
//                 <InputPicker
    // preventOverflow
//                     placeholder={'Warehouse...'}
//                     value={warehouseSelection}
//                     onChange={(e) => { onChangeWarehouseSelection(e) }}
//                     data={warehouseArray}
//                     block
//                     cleanable={false}
//                     container={() => mountRef.current}
//                 />
//             </div>
// }

// {
//     customerArray.length === 1 ?
//         <div style={styles}>
//             <Input
//                 value={customerArray[0].label}
//                 readOnly
//                 block
//             />
//         </div>
//         :
//         admin === true ?

//             <div style={styles} >

//                 <CheckPicker
//                     data={customerArray}
//                     placeholder="Customer..."
//                     style={{ width: 224 }}
//                     value={customerSelection}
//                     onChange={(e) => { onChangeCustomerSelection(e) }}
//                     renderExtraFooter={() => (
//                         <div style={footerStyles}>
//                             <Checkbox
//                                 inline
//                                 indeterminate={customerSelection.length > 0 && customerSelection.length < customerArray.map(item => item.value).length}
//                                 checked={customerSelection.length === customerArray.map(item => item.value).length}
//                                 onChange={(e) => { (customerSelection.length === customerArray.map(item => item.value).length ? onChangeCustomerSelection([]) : onChangeCustomerSelection(customerArray.map(item => item.value))) }}
//                             >
//                                 Select all
//                             </Checkbox>
//                         </div>
//                     )}
//                 />

//             </div>

//             :

//             <div style={styles}>
//                 <InputPicker
    // preventOverflow
//                     placeholder={'Customer...'}
//                     value={customerSelection}
//                     onChange={(e) => { onChangeCustomerSelection(e) }}
//                     data={customerArray}
//                     block
//                     cleanable={false}
//                     container={() => mountRef.current}
//                 />

//             </div>
// }






































// <div style={styles2} >

// <CheckPicker
//     // data={warehouseArray}
//     // data = {(typeof (warehouseArray) !== 'undefined' && warehouseArray != null && typeof (warehouseArray.length) !== 'undefined' ? warehouseArray : []) }
//     data = {warehouseArray}
//     placeholder="Warehouse..."
//     // ref={ref => {
//     //     this.picker = ref;
//     // }}
//     style={{ width: 224 }}
//     // value={(typeof (warehouseSelection.length) !== 'undefined'  ? warehouseSelection : null ) }
//     value={warehouseSelection}
//     // defaultValue={warehouseSelection}
//     // onChange={this.handleChange}
//     onChange={(e) => {onChangeWarehouseSelection(e)}}
//     renderExtraFooter={() => (
//         <div style={footerStyles}>
//         <Checkbox
//             inline

//             // indeterminate={warehouseSelection.length > 0 && warehouseSelection.length < [warehouseArray.map(item => item.value)].length}
//             // checked={warehouseSelection.length === [warehouseArray.map(item => item.value)].length}

//             indeterminate={warehouseSelection.length > 0 && warehouseSelection.length < warehouseArray.map(item => item.value).length}
//             checked={warehouseSelection.length === warehouseArray.map(item => item.value).length}

//             onChange={(e) => { (warehouseSelection.length === warehouseArray.map(item => item.value).length ? onChangeWarehouseSelection([]): onChangeWarehouseSelection(warehouseArray.map(item => item.value)))  }}


//             // indeterminate={indeterminate}
//             // checked={checkAll}
//             // onChange={this.handleCheckAll}

//             // onChange={(e) => { (warehouseSelection.length === [warehouseArray.map(item => item.value)].length ? onChangeWarehouseSelection([]): onChangeWarehouseSelection([warehouseArray.map(item => item.value)]))  }}
//         >
//             Select all
//         </Checkbox>

//         <Button
//             style={footerButtonStyle}
//             appearance="primary"
//             size="sm"
//             // onClick={() => {
//             // this.picker.close();
//             // }}
//         >
//             Ok
//         </Button>
//         </div>
//     )}
//     />

// </div>