import React, {
    PureComponent
} from 'react';
import { withRouter } from 'react-router-dom';
import {
    Form,
    FormGroup,
    ButtonToolbar,
    Col,
    Panel,
    InputGroup,
    InputNumber,
    Input,
    InputPicker,
    Loader,
    Row,
    Button,
    Divider,
    DatePicker,
    List,
    Checkbox
} from 'rsuite';
import { lettersNumbersDashesInputMask, numbersInputMask, hundredthsDecimalInputMask, textStringInputMask, limitCharacterLength, textNumbersSymbolsInputmask, limitUpperCaseLengthMask, zipCodeInputMask } from '../../../resources/logic/inputMasks';
import { isNumeric, replaceNegativeWithZero, formatNumberForAPI, formatStringForAPI, findLowestMissingNumber, formatDateForUrlSearch, formatTextNumbersSymbolsForAPI, formatAllTextNumbersSymbolsForAPI } from '../../../resources/logic/functions';
import { weightUnits, LabelGenLengthUnits } from '../../../app/appConfig';
import QuoteShipmentEmailModal from '../QuoteShipmentDetails/QuoteShipmentEmailModal';


const styles = {
    marginBottom: '10px',
    width: '100%'
};



class QuoteEntry extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

            //Warehouse & Customer Arrays
            warehouseArray: {},
            customerArray: {},

            //Shipment Entry Form
            ShipEntryPO: '',
            ShipEntryCustomer: null,
            ShipEntryOriginCountry: null,
            ShipEntryDestinationCountry: null,
            ShipEntryETD: null,
            ShipEntryLegs: {},
            ShipEntryPallets: null,
            ShipEntryCases: null,
            ShipEntryUnitCount: null,
            // ShipEntryWeight: null,
            ShipEntryWeightUnits: null,

            SpecialRequirements: null,
            GrossWeight: null,
            ChargeableWeight: null,
            Length: null,
            Width: null,
            Height: null,
            DimUnits: null,
            Commodity: null,

            // savingQuoteEntry: false,

            UnsavedShipEntryChanges: false,

            NewlyCreatedPros: [],

            ShipmentNote: '',
            NoteIsPrivate: true,

            //Email Modal
            quoteEmailModalShown: false,
            emailText: ''


        };

        //Shipment Entry Form
        this.onChangeFunc = this.onChangeFunc.bind(this);
        this.onClickAddLeg = this.onClickAddLeg.bind(this);
        this.onChangeLeg = this.onChangeLeg.bind(this);
        this.onChangeLineItem = this.onChangeLineItem.bind(this);
        this.onInputCustom = this.onInputCustom.bind(this);
        this.onClickAddLineItem = this.onClickAddLineItem.bind(this);
        this.onClickDeleteLineItem = this.onClickDeleteLineItem.bind(this);
        this.onClickDeleteLeg = this.onClickDeleteLeg.bind(this);
        this.onClickSaveShipEntry = this.onClickSaveShipEntry.bind(this);
        this.onClickResetShipEntry = this.onClickResetShipEntry.bind(this);

        this.onChangeCheckBoxOrgNote = this.onChangeCheckBoxOrgNote.bind(this);
        this.onChangeNote = this.onChangeNote.bind(this);

        //EMail modal
        this.handleCloseEmailModal = this.handleCloseEmailModal.bind(this);
    }



    componentDidMount() {

        this.getsessionStorageWarehouse() //this will preserve menu selection

        this.setInitalLegsDict()

    }



    getsessionStorageWarehouse() {

        try {
            let warehouseMenuList = JSON.parse(sessionStorage.getItem('AppConfig.WarehouseArray'));
            if (warehouseMenuList != null && warehouseMenuList != undefined) {
                this.setState({
                    warehouseArray: warehouseMenuList
                })
            }
        }
        catch (e) { }

        try {
            let customerMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CustomerArray'));
            if (customerMenuList != null && customerMenuList != undefined) {
                this.setState({
                    customerArray: customerMenuList
                })
            }
        }
        catch (e) { }

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.setState({
                // checkedKeys: []
            });
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.setState({
                // checkedKeys: []
            });
        }
        if (prevProps.CtrlTwrQuotingShipmentPostSuccessful !== this.props.CtrlTwrQuotingShipmentPostSuccessful && this.props.CtrlTwrQuotingShipmentPostSuccessful) {

            var customerObj = this.state.customerArray.filter(x => x.value === this.state.ShipEntryCustomer)
            var newProString = this.props.CtrlTwrGenerateProData[0].ProRef + '-  ' + customerObj[0].label


            setTimeout(() => {
                this.postLineItems()
            }, 500);

            window.alert('Save succcessful.')
            this.setState({ NewlyCreatedPros: [...this.state.NewlyCreatedPros, newProString] })





        }

    }



    //Shipment Entry Form
    //Input Functions
    onChangeFunc(e, statename) {
        this.setState({
            [statename]: e,
            UnsavedShipEntryChanges: true
        });
    }


    onChangeWarehouse(e) {
        this.setState({
            ShipEntryWarehouse: e,
        });
    }

    onChangeCustomer(e) {
        this.setState({
            ShipEntryCustomer: e,
            UnsavedShipEntryChanges: true
        });
    }
    onChangePO(e) {
        this.setState({
            ShipEntryPO: e,
            UnsavedShipEntryChanges: true
        });
    }
    onChangeOriginCountry(e) {
        this.setState({
            ShipEntryOriginCountry: e,
            UnsavedShipEntryChanges: true
        });
    }
    onChangeDestinationCountry(e) {
        this.setState({
            ShipEntryDestinationCountry: e,
            UnsavedShipEntryChanges: true
        });
    }
    onChangeETD(e) {
        this.setState({
            ShipEntryETD: e,
            UnsavedShipEntryChanges: true
        });
    }
    onChangePallets(e) {
        this.setState({
            ShipEntryPallets: e,
            UnsavedShipEntryChanges: true
        });
    }

    onChangeCases(e) {
        this.setState({
            ShipEntryCases: e,
            UnsavedShipEntryChanges: true
        });
    }
    onChangeUnitCount(e) {
        this.setState({
            ShipEntryUnitCount: e,
            UnsavedShipEntryChanges: true
        });
    }
    // onChangeWeight(e) {
    //     this.setState({
    //         ShipEntryWeight: e,
    //         UnsavedShipEntryChanges: true
    //     });
    // }
    onChangeWeightUnits(e) {
        this.setState({
            ShipEntryWeightUnits: e,
            UnsavedShipEntryChanges: true
        });
    }


    setInitalLegsDict() {
        var ShipEntryLegs = {};
        ShipEntryLegs[0] = [{
            'Mode': null, 'Leg': '1', 'Carrier': '', 'SellRate': '', 'BuyRate': '',
            'Shipper_Name': '',
            'Origin_Name': '',
            'Origin_AddressLine': '',
            'Origin_City': '',
            'Origin_State': '',
            'Origin_PostalCode': '',
            'Origin_CountryCode': '',
            'Consignee_Name': '',
            'Destination_Name': '',
            'Destination_AddressLine': '',
            'Destination_City': '',
            'Destination_State': '',
            'Destination_PostalCode': '',
            'Destination_CountryCode': '',
            'LineItems': [
                {
                    'QuoteLineItemID': null,
                    'SellRate': '',
                    'BuyRate': '',
                    'QuoteLineItemUomID': '',
                    'QuoteLineItemRateTypeID': '',
                    'LineItemNotes': '',
                }
            ]
        }]
        // ShipEntryLegs[0] = []

        this.setState({
            ShipEntryLegs: ShipEntryLegs,
        });

    }

    onClickAddLeg(e) {
        var currentLegsDict = this.state.ShipEntryLegs

        const legNumbersArray = currentLegsDict[0].map(item => item.Leg);
        try {
            var newLegNumber = findLowestMissingNumber(legNumbersArray)
        }
        catch (e) {
            var newLegNumber = 1
        }

        currentLegsDict[0].push({
            'Mode': null, 'Leg': String(newLegNumber), 'Carrier': '', 'SellRate': '', 'BuyRate': '',
            'Shipper_Name': '',
            'Origin_Name': '',
            'Origin_AddressLine': '',
            'Origin_City': '',
            'Origin_State': '',
            'Origin_PostalCode': '',
            'Origin_CountryCode': '',
            'Consignee_Name': '',
            'Destination_Name': '',
            'Destination_AddressLine': '',
            'Destination_City': '',
            'Destination_State': '',
            'Destination_PostalCode': '',
            'Destination_CountryCode': '',
            'LineItems': [
                {
                    'QuoteLineItemID': null,
                    'SellRate': '',
                    'BuyRate': '',
                    'QuoteLineItemUomID': '',
                    'QuoteLineItemRateTypeID': '',
                    'LineItemNotes': '',
                }
            ]
        })

        this.handleAddNewLeg(currentLegsDict)

        this.forceUpdate()

    }

    handleAddNewLeg = (newLegsDict) => {
        this.setState({
            ShipEntryLegs: newLegsDict,
            UnsavedShipEntryChanges: true
        }, () => {
            // Do an API call with this.state.searchTerm
        });
    }

    onChangeLeg = (event, index, itemLocation, label, existinglocationname) => {

        if (label === 'Mode') {
            // find new warehouse name in existing state:
            //This will be changed to dropdown choice:
            // if (this.state.ShipEntryLegs[0].filter(item => item.Mode === event).length > 0) {
            //     window.alert('Duplicate mode entry, please use existing.')
            // }
            // else {
            //save warehouse name:
            var warehouseShipEntryLegsUpdated = this.state.ShipEntryLegs


            warehouseShipEntryLegsUpdated[0][index][label] = event

            this.setState({
                ShipEntryLegs: warehouseShipEntryLegsUpdated,
                UnsavedShipEntryChanges: true
            });
            // }
        }

        else if (label === 'Leg') {
            // find new warehouse name in existing state:
            //This will be changed to dropdown choice:
            if (this.state.ShipEntryLegs[0].filter(item => item.Leg === event).length > 0) {
                window.alert('Duplicate leg entry, please use existing.')
            }
            // else if (this.state.LocationToEditDict.Mode === event) {
            //     window.alert('Location already exists, please choose new location.')
            // }
            else {
                //save warehouse name:
                var warehouseShipEntryLegsUpdated = this.state.ShipEntryLegs
                // var keyToUpdate = warehouseShipEntryLegsUpdated.findIndex(item => item.Mode === existinglocationname)

                // warehouseShipEntryLegsUpdated[keyToUpdate][label] = event

                warehouseShipEntryLegsUpdated[0][index][label] = String(event)

                this.setState({
                    ShipEntryLegs: warehouseShipEntryLegsUpdated,
                    UnsavedShipEntryChanges: true
                });
            }
        }

        else {
            var warehouseShipEntryLegsUpdated = this.state.ShipEntryLegs
            warehouseShipEntryLegsUpdated[0][index][label] = event
            this.setState({
                ShipEntryLegs: warehouseShipEntryLegsUpdated,
                UnsavedShipEntryChanges: true
            });
        }

        this.forceUpdate()
    };




    onChangeLineItem = (event, index, lineItemIndex, itemLocation, label, existinglocationname) => {

        var warehouseShipEntryLegsUpdated = this.state.ShipEntryLegs
        warehouseShipEntryLegsUpdated[0][index]['LineItems'][lineItemIndex][label] = event
        this.setState({
            ShipEntryLegs: warehouseShipEntryLegsUpdated,
            UnsavedShipEntryChanges: true
        });
        // }

        this.forceUpdate()
    };

    onInputCustom(string, event, index, lineItemIndex, label) {
        var warehouseShipEntryLegsUpdated = this.state.ShipEntryLegs
        warehouseShipEntryLegsUpdated[0][index]['LineItems'][lineItemIndex][label] = string
        this.setState({
            ShipEntryLegs: warehouseShipEntryLegsUpdated,
            UnsavedShipEntryChanges: true
        });
        // }

        this.forceUpdate()
    }

    onClickAddLineItem(e, index) {
        var currentLegsDict = this.state.ShipEntryLegs



        currentLegsDict[0][index]['LineItems'].push({

            'QuoteLineItemID': null,
            'SellRate': '',
            'BuyRate': '',
            'QuoteLineItemUomID': '',
            'QuoteLineItemRateTypeID': '',
            'LineItemNotes': '',


        })

        this.handleAddNewLeg(currentLegsDict)

        this.forceUpdate()

    }


    onClickDeleteLineItem(e, index, lineItemIndex) {
        // if (this.state.ShipEntryLegs[0][index].length > 1) {

        //     if (window.confirm('Delete Leg?')) {

        var warehouseShipEntryLegsUpdated = this.state.ShipEntryLegs

        warehouseShipEntryLegsUpdated[0][index]['LineItems'].splice(lineItemIndex, 1)

        this.setState({
            ShipEntryLegs: warehouseShipEntryLegsUpdated,
            UnsavedShipEntryChanges: true
        });
        this.forceUpdate()



    }

    onClickDeleteLeg = (e, index) => {

        if (this.state.ShipEntryLegs[0].length > 1) {

            if (window.confirm('Delete Leg?')) {

                var warehouseShipEntryLegsUpdated = this.state.ShipEntryLegs

                warehouseShipEntryLegsUpdated[0].splice(index, 1)

                this.setState({
                    ShipEntryLegs: warehouseShipEntryLegsUpdated,
                    UnsavedShipEntryChanges: true
                });
                this.forceUpdate()

            }
        }
        else {
            window.alert('Error: Cannot delete all legs.')
        }
    }






    onClickSaveShipEntry(e) {


        if (this.state.ShipEntryCustomer === null || this.state.ShipEntryCustomer === '') {
            window.alert('Please select a Customer.')
        }
        else if (this.state.ShipEntryOriginCountry === null || this.state.ShipEntryOriginCountry === '') {
            window.alert('Please select an Origin Country.')
        }
        else if (this.state.ShipEntryETD === null || this.state.ShipEntryETD === '') {
            window.alert('Please enter Planned Pickup.')
        }
        else if (this.state.ShipEntryLegs[0].some(e => e.Mode === null)) {
            window.alert('Please complete Mode selections.')
        }
        else if (this.state.ShipEntryLegs[0].some(e => e.Leg === null || e.Leg === '')) {
            window.alert('Please complete Leg Number selections.')
        }
        else if ((this.state.GrossWeight > 0 || this.state.ChargeableWeight > 0) && (this.state.ShipEntryWeightUnits === null || this.state.ShipEntryWeightUnits === '')) {
            window.alert('Please enter weight units.')
        }
        else {


            for (let l in this.state.ShipEntryLegs[0]) {
                try {
                    this.props.ctrlTwrQuotingShipmentLegsPost(
                        this.props.currentUserEmail,
                        this.props.userSignInData.AuthToken,
                        null,
                        null,
                        formatNumberForAPI(this.props.CtrlTwrGenerateProData[0].ProRef),
                        formatNumberForAPI(this.state.ShipEntryLegs[0][l].Leg),
                        formatNumberForAPI(this.state.ShipEntryLegs[0][l].Mode),
                        formatAllTextNumbersSymbolsForAPI(this.state.ShipEntryLegs[0][l].Carrier),

                        formatAllTextNumbersSymbolsForAPI(this.state.ShipEntryLegs[0][l].Shipper_Name),
                        formatAllTextNumbersSymbolsForAPI(this.state.ShipEntryLegs[0][l].Origin_Name),
                        formatAllTextNumbersSymbolsForAPI(this.state.ShipEntryLegs[0][l].Origin_AddressLine),
                        formatAllTextNumbersSymbolsForAPI(this.state.ShipEntryLegs[0][l].Origin_City),
                        formatStringForAPI(this.state.ShipEntryLegs[0][l].Origin_State),
                        formatStringForAPI(this.state.ShipEntryLegs[0][l].Origin_PostalCode),
                        formatStringForAPI(this.state.ShipEntryLegs[0][l].Origin_CountryCode),
                        formatAllTextNumbersSymbolsForAPI(this.state.ShipEntryLegs[0][l].Consignee_Name),
                        formatAllTextNumbersSymbolsForAPI(this.state.ShipEntryLegs[0][l].Destination_Name),
                        formatAllTextNumbersSymbolsForAPI(this.state.ShipEntryLegs[0][l].Destination_AddressLine),
                        formatAllTextNumbersSymbolsForAPI(this.state.ShipEntryLegs[0][l].Destination_City),
                        formatStringForAPI(this.state.ShipEntryLegs[0][l].Destination_State),
                        formatStringForAPI(this.state.ShipEntryLegs[0][l].Destination_PostalCode),
                        formatStringForAPI(this.state.ShipEntryLegs[0][l].Destination_CountryCode)
                    )
                }
                catch (e) { }
            }


            try {
                if (typeof (this.props.organizationSelection) === 'number' && this.state.NoteIsPrivate) {
                    if (this.state.ShipmentNote !== '') {
                        this.props.ctrlTwrShipmentPrivateNotesPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, formatNumberForAPI(this.props.CtrlTwrGenerateProData[0].ProRef), formatAllTextNumbersSymbolsForAPI(this.state.ShipmentNote), null)
                    }


                }
                else {
                    //not assigned to organization can only write public notes:
                    if (this.state.ShipmentNote !== '') {
                        this.props.ctrlTwrShipmentPublicNotesPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, formatNumberForAPI(this.props.CtrlTwrGenerateProData[0].ProRef), formatAllTextNumbersSymbolsForAPI(this.state.ShipmentNote), null)
                    }
                }
            }
            catch (e) {

            }








            try {
                this.props.ctrlTwrQuotingShipmentPost(
                    this.props.currentUserEmail,
                    this.props.userSignInData.AuthToken,
                    formatNumberForAPI(this.props.CtrlTwrGenerateProData[0].ProRef),

                    formatNumberForAPI(this.state.ShipEntryCustomer),
                    formatNumberForAPI(this.state.ShipEntryOriginCountry),

                    formatAllTextNumbersSymbolsForAPI(this.state.ShipEntryPO),

                    formatDateForUrlSearch(this.state.ShipEntryETD),

                    0,

                    formatNumberForAPI(this.state.ShipEntryPallets),
                    formatNumberForAPI(this.state.ShipEntryCases),
                    formatNumberForAPI(this.state.ShipEntryUnitCount),
                    formatStringForAPI(this.state.ShipEntryWeightUnits),
                    formatNumberForAPI(this.state.ShipEntryDestinationCountry),
                    1,
                    1,

                    formatAllTextNumbersSymbolsForAPI(this.state.SpecialRequirements),
                    formatNumberForAPI(this.state.GrossWeight),
                    formatNumberForAPI(this.state.ChargeableWeight),
                    formatNumberForAPI(this.state.Length),
                    formatNumberForAPI(this.state.Width),
                    formatNumberForAPI(this.state.Height),
                    formatStringForAPI(this.state.DimUnits),
                    formatAllTextNumbersSymbolsForAPI(this.state.Commodity)



                )
            }
            catch (e) { }

        }

    }


    postLineItems() {
        for (let l in this.state.ShipEntryLegs[0]) {
            try {
                for (let i in this.state.ShipEntryLegs[0][l]['LineItems']) {

                    var LineItemSeq = i + 1

                    var quoteLineItemID = '0'
                    if (this.state.ShipEntryLegs[0][l]['LineItems'][i].QuoteLineItemID === null || this.state.ShipEntryLegs[0][l]['LineItems'][i].QuoteLineItemID === '') {
                        quoteLineItemID = '0'
                    }
                    else {
                        quoteLineItemID = formatStringForAPI(this.state.ShipEntryLegs[0][l]['LineItems'][i].QuoteLineItemID)
                    }
                    this.props.ctrlTwrQuotingShipmentLegsLineItemPost(
                        this.props.currentUserEmail,
                        this.props.userSignInData.AuthToken,
                        null,
                        null,
                        quoteLineItemID,
                        formatNumberForAPI(this.props.CtrlTwrGenerateProData[0].ProRef),
                        formatNumberForAPI(this.state.ShipEntryLegs[0][l].Leg),
                        formatNumberForAPI(this.state.ShipEntryLegs[0][l].Mode),
                        null,
                        formatStringForAPI(this.state.ShipEntryLegs[0][l]['LineItems'][i].SellRate),
                        formatStringForAPI(this.state.ShipEntryLegs[0][l]['LineItems'][i].BuyRate),
                        formatStringForAPI(this.state.ShipEntryLegs[0][l]['LineItems'][i].QuoteLineItemUomID),
                        formatStringForAPI(this.state.ShipEntryLegs[0][l]['LineItems'][i].QuoteLineItemRateTypeID),
                        formatStringForAPI(this.state.ShipEntryLegs[0][l]['LineItems'][i].LineItemNotes),
                        formatNumberForAPI(LineItemSeq)


                    )
                }
            }
            catch (e) { }
        }



        this.createEmailText()

        this.resetShipEntry()

    }



    createEmailText(e) {

        try {
            var pickupDt = <><strong>Planned Pickup Date:</strong> {this.state.ShipEntryETD.toLocaleDateString()}<br /></>
        }
        catch (e) {
            var pickupDt = <div></div>
        }




        try {
            var commodity = <><strong>Commodity:</strong> {this.state.Commodity}<br /></>
        }
        catch (e) {
            var commodity = <div></div>
        }


        try {
            var Pallets = <><strong>Pallets:</strong> {this.state.ShipEntryPallets}<br /></>
        }
        catch (e) {
            var Pallets = <div></div>
        }

        try {
            var Cases = <><strong>Cases:</strong> {this.state.ShipEntryCases}<br /></>
        }
        catch (e) {
            var Cases = <div></div>
        }


        try {
            var SpecialRequirements = <><strong>Special Requirements:</strong> {this.state.SpecialRequirements}<br /></>
        }
        catch (e) {
            var SpecialRequirements = <div></div>
        }

        try {
            var GrossWeight = <><strong>Gross Weight:</strong> {this.state.GrossWeight} {this.state.ShipEntryWeightUnits}<br /></>
        }
        catch (e) {
            var GrossWeight = <div></div>
        }

        try {
            var ChargeableWeight = <><strong>Chargeable Weight:</strong> {this.state.ChargeableWeight} {this.state.ShipEntryWeightUnits}<br /></>
        }
        catch (e) {
            var ChargeableWeight = <div></div>
        }



        // var emailText = <div>
        //     Hello,
        //     <br />
        //     May we please get a quote and transit time for the following?
        //     <br />
        //     <br />
        //     {pickupDt}
        //     <br />
        //     {OriginAddress}
        //     <br />
        //     {DestinationAddress}
        //     <br />
        //     {commodity}
        //     <br />
        //     {Pallets}
        //     <br />
        //     {Cases}
        //     <br />
        //     {GrossWeight}
        //     <br />
        //     {ChargeableWeight}
        //     <br />
        //     {SpecialRequirements}
        //     <br />
        //     {ModeName}
        //     <br />
        //     <br />
        //     Thank you,
        //     <br />
        //     Evolve Logistics Team
        //     <br />
        // </div>


        var emailText = this.state.ShipEntryLegs[0].map((legItem, index) => (
            <div>
                Hello,
                <br />
                May we please get a quote and transit time for the following?
                <br />
                <br />
                {pickupDt}
                <strong>Origin Address:</strong> {legItem.Shipper_Name}{(legItem.Shipper_Name !== '' && legItem.Origin_AddressLine !== '' ? ', ' : '')}{legItem.Origin_AddressLine}{(legItem.Origin_City !== '' ? ', ' : '')}{legItem.Origin_City}{(legItem.Origin_State !== '' ? ', ' : '')}{legItem.Origin_State}{(legItem.Origin_PostalCode !== '' ? ' ' : '')}{legItem.Origin_PostalCode}{(legItem.Origin_CountryCode !== '' ? ', ' : '')}{legItem.Origin_CountryCode}
                <br />
                <strong>Destination Address:</strong> {legItem.Consignee_Name}{(legItem.Consignee_Name !== '' && legItem.Destination_AddressLine !== '' ? ', ' : '')}{legItem.Destination_AddressLine}{(legItem.Destination_City !== '' ? ', ' : '')}{legItem.Destination_City}{(legItem.Destination_State !== '' ? ', ' : '')}{legItem.Destination_State}{(legItem.Destination_PostalCode !== '' ? ' ' : '')}{legItem.Destination_PostalCode}{(legItem.Destination_CountryCode !== '' ? ', ' : '')}{legItem.Destination_CountryCode}
                <br />
                {commodity}
                {Pallets}
                {Cases}
                {GrossWeight}
                {ChargeableWeight}
                {SpecialRequirements}
                <strong>Ship Mode:</strong> {(legItem.Mode !== null ? this.props.CtrlTwrModeRefData.filter(x => x.value === legItem.Mode)[0]['label'] : '')}
                <br />
                <br />
                Thank you,
                <br />
                Evolve Logistics Team
                <br />

                <br />

                <br />

                <br />

                <br />
            </div>

        ))


        // Hello,

        // May we please get a quote and transit time for the following?

        // Planned Pickup Date:
        // Origin Address:
        // Destination Address:
        // Commodity:
        // Pallets:
        // Cases:
        // Weight:
        // Special Requirements:
        // Ship Mode:

        // Thank you,

        // Evolve Logistics Team


        this.setState({
            quoteEmailModalShown: true,
            emailText: emailText
        });


    }


    handleCloseEmailModal() {
        this.setState({
            quoteEmailModalShown: false,
            emailText: ''
        });
    }

    onClickResetShipEntry(e) {
        if (this.state.UnsavedShipEntryChanges === true) {
            if (window.confirm('You will lose unsaved changes, reset?')) {
                this.resetShipEntry()
            }
        }
        else {
            this.resetShipEntry()
        }
    }

    resetShipEntry() {
        this.setState({
            ShipEntryPO: '',
            ShipEntryCustomer: null,
            ShipEntryOriginCountry: null,
            ShipEntryDestinationCountry: null,
            ShipEntryETD: null,
            ShipEntryLegs: {},
            ShipEntryPallets: null,
            ShipEntryCases: null,
            ShipEntryUnitCount: null,
            // ShipEntryWeight: null,
            ShipEntryWeightUnits: null,
            SpecialRequirements: null,
            GrossWeight: null,
            ChargeableWeight: null,
            Length: null,
            Width: null,
            Height: null,
            DimUnits: null,
            Commodity: null,
            UnsavedShipEntryChanges: false,
            ShipmentNote: '',
            NoteIsPrivate: true
        });

        this.setInitalLegsDict()
        this.forceUpdate()
    }



    onChangeCheckBoxOrgNote(e, value) {
        this.setState({
            NoteIsPrivate: value
        });
    }
    onChangeNote(e) {
        this.setState({
            ShipmentNote: e
        });
    }



    render() {


        let shipEntryDiv;
        if (this.props.isCtrlTwrGenerateProLoading) {
            shipEntryDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (this.props.isCtrlTwrModeRefLoading) {
            shipEntryDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (this.props.isCtrlTwrCountryLoading) {
            shipEntryDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.CtrlTwrGenerateProData[0]) !== 'undefined' && this.props.CtrlTwrGenerateProData != null) {

            shipEntryDiv =
                <Form fluid>

                    <Col md={24} sm={24}>
                        <Panel bordered style={{ marginBottom: 10 }} >
                            <FormGroup >

                                <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                    <InputGroup.Addon>Quote ID:</InputGroup.Addon>
                                    <Input value={this.props.CtrlTwrGenerateProData[0].ProRef} readOnly />
                                </InputGroup>


                                <div style={{ marginBottom: '10px', width: '100%' }}>
                                    <InputPicker
                                        preventOverflow
                                        placeholder={'Customer (Required)...'}
                                        value={this.state.ShipEntryCustomer}
                                        onChange={(e) => { this.onChangeCustomer(e) }}
                                        data={this.state.customerArray}
                                        cleanable={true}
                                        block
                                    />
                                </div>

                                <Row>
                                    <Col md={12} sm={24}>
                                        <div style={{ marginBottom: '10px', width: '100%' }}>
                                            <InputPicker
                                                preventOverflow
                                                placeholder={'Origin Country (Required)...'}
                                                value={this.state.ShipEntryOriginCountry}
                                                onChange={(e) => { this.onChangeOriginCountry(e) }}
                                                data={this.props.CtrlTwrCountryData}
                                                cleanable={true}
                                                block
                                            />
                                        </div>
                                    </Col>
                                    <Col md={12} sm={24}>
                                        <div style={{ marginBottom: '10px', width: '100%' }}>
                                            <InputPicker
                                                preventOverflow
                                                placeholder={'Destination Country...'}
                                                value={this.state.ShipEntryDestinationCountry}
                                                onChange={(e) => { this.onChangeDestinationCountry(e) }}
                                                data={this.props.CtrlTwrCountryData}
                                                cleanable={true}
                                                block
                                            />
                                        </div>
                                    </Col>
                                </Row>

                                <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                    <InputGroup.Addon>Planned Pickup (Required):</InputGroup.Addon>
                                    <DatePicker
                                        placeholder="Planned Pickup"
                                        onChange={(e) => { this.onChangeETD(e) }}
                                        value={this.state.ShipEntryETD}
                                        format="YYYY-MM-DD"
                                        showMeridian
                                        size="md"
                                        ranges={[
                                            {
                                                label: 'Now',
                                                value: new Date()
                                            }
                                        ]}
                                        style={{ width: '100%' }}
                                        oneTap
                                    />
                                </InputGroup>

                                <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                    <InputGroup.Addon>PO:</InputGroup.Addon>
                                    <Input value={this.state.ShipEntryPO} onChange={(e) => { this.onChangePO(e) }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                </InputGroup>




                                <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                    <InputGroup.Addon>Commodity:</InputGroup.Addon>
                                    <Input value={this.state.Commodity} onChange={(e) => { this.onChangeFunc(e, 'Commodity') }} onInput={(e) => e.target.value = limitCharacterLength(textNumbersSymbolsInputmask(e), 300)} />
                                </InputGroup>

                                <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                    <InputGroup.Addon>Special Requirements:</InputGroup.Addon>
                                    <Input value={this.state.SpecialRequirements} onChange={(e) => { this.onChangeFunc(e, 'SpecialRequirements') }} onInput={(e) => e.target.value = limitCharacterLength(textNumbersSymbolsInputmask(e), 2000)} />
                                </InputGroup>


                                {typeof (this.props.organizationSelection) === 'number' ?

                                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                        <InputGroup.Addon>Notes:</InputGroup.Addon>
                                        <Checkbox checked={this.state.NoteIsPrivate} onChange={(e, value) => this.onChangeCheckBoxOrgNote(e, value)}>{this.state.NoteIsPrivate ? 'Private:  ' : 'Public:  '} </Checkbox>
                                        <Input value={this.state.ShipmentNote} onChange={(e) => { this.onChangeNote(e) }} onInput={(e) => e.target.value = limitCharacterLength(textNumbersSymbolsInputmask(e), 2000)} />
                                    </InputGroup>

                                    :

                                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                        <InputGroup.Addon>Notes:</InputGroup.Addon>
                                        <Input value={this.state.ShipmentNote} onChange={(e) => { this.onChangeNote(e) }} onInput={(e) => e.target.value = limitCharacterLength(textNumbersSymbolsInputmask(e), 2000)} />

                                    </InputGroup>
                                }


                                <Row>
                                    <Col md={8} sm={24}>
                                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                            <InputGroup.Addon>Units:</InputGroup.Addon>
                                            <InputNumber style={{ width: '100%' }} value={this.state.ShipEntryUnitCount} onChange={(e) => this.onChangeUnitCount(e)} className={'custom-input-number'} scrollable={false} step={1} onInput={(e) => e.target.value = numbersInputMask(e)} />
                                        </InputGroup>
                                    </Col>
                                    <Col md={8} sm={24}>
                                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                            <InputGroup.Addon>Cases:</InputGroup.Addon>
                                            <InputNumber style={{ width: '100%' }} value={this.state.ShipEntryCases} onChange={(e) => this.onChangeCases(e)} className={'custom-input-number'} scrollable={false} step={1} onInput={(e) => e.target.value = numbersInputMask(e)} />
                                        </InputGroup>
                                    </Col>
                                    <Col md={8} sm={24}>
                                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                            <InputGroup.Addon>Pallets:</InputGroup.Addon>
                                            <InputNumber style={{ width: '100%' }} value={this.state.ShipEntryPallets} onChange={(e) => this.onChangePallets(e)} className={'custom-input-number'} scrollable={false} step={1} onInput={(e) => e.target.value = numbersInputMask(e)} />
                                        </InputGroup>
                                    </Col>
                                </Row>



                                <Row>
                                    <Col md={12} sm={24}>
                                        <Panel bordered style={{ marginBottom: 10 }} >
                                            <FormGroup>

                                                <Row>
                                                    <Col md={8} sm={24}>
                                                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                            <InputGroup.Addon>Length:</InputGroup.Addon>
                                                            <InputNumber style={{ width: '100%' }} value={this.state.Length} onChange={(e) => this.onChangeFunc(e, 'Length')} className={'custom-input-number'} onInput={(e) => e.target.value = hundredthsDecimalInputMask(e)} scrollable={false} />
                                                        </InputGroup>
                                                    </Col>
                                                    <Col md={8} sm={24}>
                                                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                            <InputGroup.Addon>Width:</InputGroup.Addon>
                                                            <InputNumber style={{ width: '100%' }} value={this.state.Width} onChange={(e) => this.onChangeFunc(e, 'Width')} className={'custom-input-number'} onInput={(e) => e.target.value = hundredthsDecimalInputMask(e)} scrollable={false} />
                                                        </InputGroup>
                                                    </Col>
                                                    <Col md={8} sm={24}>
                                                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                            <InputGroup.Addon>Height:</InputGroup.Addon>
                                                            <InputNumber style={{ width: '100%' }} value={this.state.Height} onChange={(e) => this.onChangeFunc(e, 'Height')} className={'custom-input-number'} onInput={(e) => e.target.value = hundredthsDecimalInputMask(e)} scrollable={false} />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <InputGroup style={styles}>
                                                    <InputGroup.Addon>Units:</InputGroup.Addon>
                                                    <InputPicker
                                                        preventOverflow
                                                        value={this.state.DimUnits}
                                                        onChange={(e) => { this.onChangeFunc(e, 'DimUnits') }}
                                                        data={LabelGenLengthUnits}
                                                        block
                                                        style={{ width: '100%' }}
                                                        cleanable={true}
                                                    />
                                                </InputGroup>

                                            </FormGroup>
                                        </Panel>
                                    </Col>
                                    <Col md={12} sm={24}>
                                        <Panel bordered style={{ marginBottom: 10 }} >
                                            <FormGroup>

                                                <Row>
                                                    <Col md={12} sm={24}>
                                                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                            <InputGroup.Addon>Gross Wt:</InputGroup.Addon>
                                                            <InputNumber style={{ width: '100%' }} value={this.state.GrossWeight} onChange={(e) => this.onChangeFunc(e, 'GrossWeight')} className={'custom-input-number'} onInput={(e) => e.target.value = hundredthsDecimalInputMask(e)} scrollable={false} />
                                                        </InputGroup>
                                                    </Col>
                                                    <Col md={12} sm={24}>
                                                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                            <InputGroup.Addon>Chargeable Wt:</InputGroup.Addon>
                                                            <InputNumber style={{ width: '100%' }} value={this.state.ChargeableWeight} onChange={(e) => this.onChangeFunc(e, 'ChargeableWeight')} className={'custom-input-number'} onInput={(e) => e.target.value = hundredthsDecimalInputMask(e)} scrollable={false} />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <InputGroup style={styles}>
                                                    <InputGroup.Addon>Weight Units:</InputGroup.Addon>
                                                    <InputPicker
                                                        preventOverflow
                                                        // placeholder={label}
                                                        // placeholder={'Weight Units...'}
                                                        value={this.state.ShipEntryWeightUnits}
                                                        onChange={(e) => { this.onChangeWeightUnits(e) }}
                                                        data={weightUnits}
                                                        block
                                                        style={{ width: '100%' }}
                                                        cleanable={true}
                                                    />
                                                </InputGroup>


                                            </FormGroup>
                                        </Panel>
                                    </Col>

                                </Row>







                                <Divider>Legs</Divider>



                                {(typeof (this.state.ShipEntryLegs[0]) !== 'undefined' && this.state.ShipEntryLegs != null) ?

                                    this.state.ShipEntryLegs[0].map((locationitem, index) => (

                                        <div style={{ marginBottom: '10px', width: '100%' }}>
                                            <Panel bordered style={{ marginBottom: 10 }} >
                                                <Row>
                                                    <Col md={6} sm={24}>

                                                        <InputGroup style={{ width: '100%' }}>
                                                            <InputGroup.Addon>Leg:</InputGroup.Addon>
                                                            <InputNumber
                                                                placeholder={'Leg...'}
                                                                value={locationitem['Leg']}
                                                                onChange={(e) => { this.onChangeLeg(e, index, locationitem, 'Leg', locationitem['Leg']) }}
                                                                // onInput={(e) => e.target.value = numbersInputMask(e)}
                                                                min={1}
                                                                block
                                                                step={1}
                                                                // defaultValue={1}
                                                                cleanable={false}
                                                                style={{ width: '100%' }}
                                                            />
                                                        </InputGroup>

                                                    </Col>

                                                    <Col md={6} sm={24}>

                                                        <InputPicker
                                                            preventOverflow
                                                            // placeholder={label}
                                                            placeholder={'Mode...'}
                                                            value={locationitem['Mode']}
                                                            onChange={(e) => { this.onChangeLeg(e, index, locationitem, 'Mode', locationitem['Mode']) }}
                                                            data={this.props.CtrlTwrModeRefData}
                                                            block
                                                            cleanable={true}
                                                        />
                                                    </Col>


                                                    <Col md={6} sm={24}>
                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Carrier:</InputGroup.Addon>
                                                            <Input style={{ width: '100%' }} value={locationitem['Carrier']} onChange={(e) => { this.onChangeLeg(e, index, locationitem, 'Carrier', locationitem['Carrier']) }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                        </InputGroup>
                                                    </Col>


                                                    <Col md={6} sm={24}>
                                                        <Button block onClick={(e) => { this.onClickDeleteLeg(e, index) }} appearance="default">Delete Leg</Button>
                                                    </Col>

                                                </Row>

                                                <Panel bordered style={{ marginBottom: 10 }} >

                                                    {locationitem.LineItems.map((lineItems, lineItemIndex) => (
                                                        <div>
                                                            <Panel bordered style={{ marginBottom: 10 }} >
                                                                <Row  >

                                                                    <Col md={2} sm={2}>
                                                                        <ButtonToolbar >
                                                                            <Button block onClick={(e) => { this.onClickDeleteLineItem(e, index, lineItemIndex) }} appearance="secondary">Delete</Button>
                                                                        </ButtonToolbar>
                                                                    </Col>



                                                                    <Col md={10} sm={10}>
                                                                        <div style={{ marginBottom: '10px', width: '100%' }}>
                                                                            <InputPicker
                                                                                preventOverflow
                                                                                placeholder={'Line Item...'}
                                                                                value={lineItems['QuoteLineItemID']}
                                                                                creatable
                                                                                onChange={(e) => { this.onChangeLineItem(e, index, lineItemIndex, lineItems, 'QuoteLineItemID', lineItems['QuoteLineItemID']) }}

                                                                                data={this.props.CtrlTwrQuotingLineItemData.filter(x => x.ModeID === locationitem['Mode'])}

                                                                                cleanable={true}
                                                                                block
                                                                            />
                                                                        </div>
                                                                    </Col>


                                                                    <Col md={6} sm={6}>
                                                                        <InputGroup style={styles}>
                                                                            <InputGroup.Addon>Buy Rate ($):</InputGroup.Addon>
                                                                            <Input style={{ width: '100%' }} value={lineItems['BuyRate']} onChange={(e) => { this.onChangeLineItem(e, index, lineItemIndex, lineItems, 'BuyRate', lineItems['BuyRate']) }} className={'custom-input-number'} onInput={(e) => e.target.value = hundredthsDecimalInputMask(e)} />
                                                                        </InputGroup>
                                                                    </Col>

                                                                    <Col md={6} sm={6}>
                                                                        <InputGroup style={styles}>
                                                                            <InputGroup.Addon>Sell Rate ($):</InputGroup.Addon>
                                                                            <Input style={{ width: '100%' }} value={lineItems['SellRate']} onChange={(e) => { this.onChangeLineItem(e, index, lineItemIndex, lineItems, 'SellRate', lineItems['SellRate']) }} className={'custom-input-number'} onInput={(e) => e.target.value = hundredthsDecimalInputMask(e)} />
                                                                        </InputGroup>
                                                                    </Col>

                                                                </Row>
                                                                <Row>

                                                                    <Col md={6} sm={6}>
                                                                        <div style={{ marginBottom: '10px', width: '100%' }}>
                                                                            <InputPicker
                                                                                preventOverflow
                                                                                placeholder={'Unit of Measure...'}
                                                                                value={lineItems['QuoteLineItemUomID']}
                                                                                creatable
                                                                                onChange={(e) => { this.onChangeLineItem(e, index, lineItemIndex, lineItems, 'QuoteLineItemUomID', lineItems['QuoteLineItemUomID']) }}

                                                                                onSearch={(string, e) => { this.onInputCustom(string, e, index, lineItemIndex, 'QuoteLineItemUomID') }}

                                                                                data={this.props.CtrlTwrQuotingLineItemUomData}

                                                                                cleanable={true}
                                                                                block
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={6} sm={6}>
                                                                        <div style={{ marginBottom: '10px', width: '100%' }}>
                                                                            <InputPicker
                                                                                preventOverflow
                                                                                placeholder={'Rate Type...'}
                                                                                value={lineItems['QuoteLineItemRateTypeID']}
                                                                                creatable
                                                                                onChange={(e) => { this.onChangeLineItem(e, index, lineItemIndex, lineItems, 'QuoteLineItemRateTypeID', lineItems['QuoteLineItemRateTypeID']) }}

                                                                                data={this.props.CtrlTwrQuotingLineItemRateTypeData}

                                                                                cleanable={true}
                                                                                block
                                                                            />
                                                                        </div>
                                                                    </Col>

                                                                    <Col md={12} sm={24}>
                                                                        <InputGroup style={styles}>
                                                                            <InputGroup.Addon>Notes:</InputGroup.Addon>
                                                                            <Input style={{ width: '100%' }} value={lineItems['LineItemNotes']} onChange={(e) => { this.onChangeLineItem(e, index, lineItemIndex, lineItems, 'LineItemNotes', lineItems['LineItemNotes']) }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                        </InputGroup>
                                                                    </Col>




                                                                </Row>
                                                            </Panel>
                                                        </div>

                                                    ))}


                                                    <Row>
                                                        <Col md={24} sm={24}>
                                                            <ButtonToolbar >
                                                                <Button onClick={(e) => { this.onClickAddLineItem(e, index) }} appearance="primary">Add Line Item</Button>
                                                            </ButtonToolbar>
                                                        </Col>
                                                    </Row>


                                                </Panel>



                                                <Row>
                                                    <Col md={12} sm={24}>
                                                        <Panel bordered style={{ marginBottom: 10 }} >
                                                            <FormGroup>
                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>Shipper Name:</InputGroup.Addon>
                                                                    <Input style={{ width: '100%' }} value={locationitem['Shipper_Name']} onChange={(e) => { this.onChangeLeg(e, index, locationitem, 'Shipper_Name', locationitem['Shipper_Name']) }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                </InputGroup>

                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>Origin Address:</InputGroup.Addon>
                                                                    <Input style={{ width: '100%' }} value={locationitem['Origin_AddressLine']} onChange={(e) => { this.onChangeLeg(e, index, locationitem, 'Origin_AddressLine', locationitem['Origin_AddressLine']) }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                </InputGroup>

                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>Origin City:</InputGroup.Addon>
                                                                    <Input style={{ width: '100%' }} value={locationitem['Origin_City']} onChange={(e) => { this.onChangeLeg(e, index, locationitem, 'Origin_City', locationitem['Origin_City']) }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                </InputGroup>

                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>Origin State:</InputGroup.Addon>
                                                                    <Input style={{ width: '100%' }} value={locationitem['Origin_State']} onChange={(e) => { this.onChangeLeg(e, index, locationitem, 'Origin_State', locationitem['Origin_State']) }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                </InputGroup>

                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>Origin Zip:</InputGroup.Addon>
                                                                    <Input style={{ width: '100%' }} value={locationitem['Origin_PostalCode']} onChange={(e) => { this.onChangeLeg(e, index, locationitem, 'Origin_PostalCode', locationitem['Origin_PostalCode']) }} onInput={(e) => e.target.value = limitCharacterLength(textStringInputMask(e), 9)} />
                                                                </InputGroup>

                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>Origin Country:</InputGroup.Addon>
                                                                    <Input style={{ width: '100%' }} value={locationitem['Origin_CountryCode']} onChange={(e) => { this.onChangeLeg(e, index, locationitem, 'Origin_CountryCode', locationitem['Origin_CountryCode']) }} onInput={(e) => e.target.value = limitUpperCaseLengthMask(e, 2)} />
                                                                </InputGroup>

                                                            </FormGroup>
                                                        </Panel>
                                                    </Col>
                                                    <Col md={12} sm={24}>
                                                        <Panel bordered style={{ marginBottom: 10 }} >
                                                            <FormGroup>

                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>Consignee Name:</InputGroup.Addon>
                                                                    <Input style={{ width: '100%' }} value={locationitem['Consignee_Name']} onChange={(e) => { this.onChangeLeg(e, index, locationitem, 'Consignee_Name', locationitem['Consignee_Name']) }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                </InputGroup>

                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>Destination Address:</InputGroup.Addon>
                                                                    <Input style={{ width: '100%' }} value={locationitem['Destination_AddressLine']} onChange={(e) => { this.onChangeLeg(e, index, locationitem, 'Destination_AddressLine', locationitem['Destination_AddressLine']) }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                </InputGroup>

                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>Destination City:</InputGroup.Addon>
                                                                    <Input style={{ width: '100%' }} value={locationitem['Destination_City']} onChange={(e) => { this.onChangeLeg(e, index, locationitem, 'Destination_City', locationitem['Destination_City']) }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                </InputGroup>

                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>Destination State:</InputGroup.Addon>
                                                                    <Input style={{ width: '100%' }} value={locationitem['Destination_State']} onChange={(e) => { this.onChangeLeg(e, index, locationitem, 'Destination_State', locationitem['Destination_State']) }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                </InputGroup>

                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>Destination Zip:</InputGroup.Addon>
                                                                    <Input style={{ width: '100%' }} value={locationitem['Destination_PostalCode']} onChange={(e) => { this.onChangeLeg(e, index, locationitem, 'Destination_PostalCode', locationitem['Destination_PostalCode']) }} onInput={(e) => e.target.value = limitCharacterLength(textStringInputMask(e), 9)} />
                                                                </InputGroup>

                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>Destination Country:</InputGroup.Addon>
                                                                    <Input style={{ width: '100%' }} value={locationitem['Destination_CountryCode']} onChange={(e) => { this.onChangeLeg(e, index, locationitem, 'Destination_CountryCode', locationitem['Destination_CountryCode']) }} onInput={(e) => e.target.value = limitUpperCaseLengthMask(e, 2)} />
                                                                </InputGroup>

                                                            </FormGroup>
                                                        </Panel>
                                                    </Col>
                                                </Row>



                                            </Panel>
                                            {/*        

                                            Consignee_Name,
                                            Destination_Name,
                                            Destination_AddressLine,
                                            Destination_City,
                                            Destination_State,
                                            Destination_PostalCode,
                                            Destination_CountryCode, */}





                                        </div>

                                    ))

                                    :

                                    <div></div>
                                }


                                < Row >
                                    <Col md={8} sm={8}>
                                        <Button style={{ marginBottom: '10px' }} onClick={(e) => this.onClickAddLeg(e)} appearance="default">+  Add Leg</Button>
                                    </Col>
                                </Row>

                                <Divider />

                                <FormGroup>
                                    <ButtonToolbar >
                                        <Button onClick={(e) => { this.onClickSaveShipEntry(e) }} appearance="primary">Save</Button>
                                        <Button onClick={(e) => { this.onClickResetShipEntry(e) }} appearance="default">Reset</Button>

                                        {/* <Button onClick={(e) => { this.createEmailText(e) }} appearance="default">Email Text</Button> */}
                                    </ButtonToolbar>
                                </FormGroup>


                            </FormGroup>
                        </Panel>

                    </Col>

                </Form >



        }


        let newlyCreatedProsList;
        if (this.state.NewlyCreatedPros.length > 0) {
            newlyCreatedProsList =
                <Form fluid>

                    <Col md={24} sm={24}>
                        <Panel bordered style={{ marginBottom: '15px' }} header="New Quote's:" >
                            <List>
                                {this.state.NewlyCreatedPros.map(item => (
                                    <List.Item>{item}</List.Item>
                                ))
                                }
                            </List>
                        </Panel>
                    </Col>
                </Form>
        }
        else {
            newlyCreatedProsList = <div></div>
        }

        return (
            <div>
                <h3>
                    Control Tower - Quote Entry
                </h3>

                <br />
                <br />


                {shipEntryDiv}

                {newlyCreatedProsList}

                <QuoteShipmentEmailModal {...this.props} state={this.state} quoteEmailModalShown={this.state.quoteEmailModalShown} handleCloseEmailModal={this.handleCloseEmailModal} />


            </div >
        );

    }
}

export default withRouter(QuoteEntry);





// <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
// <InputGroup.Addon>Cartons Recovered:</InputGroup.Addon>
// <InputNumber defaultValue={0} className={'custom-input-number'} onChange={(e) => this.onChange(e, 'AirlineRecoveryCartonsRecovered')} scrollable={false} />
// </InputGroup>