import React from 'react';
import PropTypes from 'prop-types';
import {
    Drawer,
    Paragraph,
    Button,
    ButtonToolbar,
    Form,
    FormControl,
    FormGroup,
    ControlLabel,
    HelpBlock,
    DatePicker,
    InputPicker,
    InputNumber,
    InputGroup,
    Input
} from 'rsuite';
import RFormControl from '../../componentsGeneral/Rsuite/Form/RFormControl';
import { lettersNumbersDashesInputMask, numbersInputMask, textNumbersSymbolsInputmask, textStringInputMask } from '../../../resources/logic/inputMasks';

const styles = {
    // width: 300,
    marginBottom: 10,
    width: '100%'
};

const dropDownLclFcl = [
    {
        "label": "LCL",
        "value": "LCL"
    },
    {
        "label": "FCL",
        "value": "FCL"
    }
]

const mountRef = React.createRef();

const EditPoDrawer = ({ onChangeEditPo, onChangeCustomerRef, onChangeProject, show, placement, confirm, close, unsavedChanges, state, ...props }) => (

    <Drawer show={state.showEditPoDrawer} onHide={close} placement={placement} style={props.isMobile ? { width: '350px' } : { width: '650px' }}    >
        <Drawer.Header>
            <Drawer.Title>Edit PO</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body> <div ref={mountRef}>

            <Form fluid>

                <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                    <InputGroup.Addon>PO:</InputGroup.Addon>
                    <Input value={state.Po} onChange={(e) => { onChangeEditPo(e, 'Po') }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} />
                </InputGroup>

                <div style={{ marginBottom: '10px', width: '100%' }}>
                    <InputPicker
    preventOverflow
                        container={() => mountRef.current}
                        block
                        creatable
                        placeholder={'Customer Ref...'}
                        value={state.CustomerRef}
                        onChange={(e, value, label) => { onChangeCustomerRef(e, value, label) }}
                        data={props.TransCustomerRefData}
                        cleanable={true}
                    />
                </div>
                <div style={{ marginBottom: '10px', width: '100%' }}>
                    <InputPicker
    preventOverflow
                        container={() => mountRef.current}
                        block
                        creatable
                        placeholder={'Project...'}
                        value={state.Project}
                        onChange={(e, value, label) => { onChangeProject(e, value, label) }}
                        data={props.TransProjectData}
                        cleanable={true}
                    />
                </div>
                <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                    <InputGroup.Addon>Expected Cases:</InputGroup.Addon>
                    {/* <InputNumber defaultValue={item.Cartons - item.RecoveredPCS } className={'custom-input-number'} onChange={(e) => onChange(e, item, 'Recovered')} scrollable={false} /> */}
                    <InputNumber defaultValue={state.ExpectedCases} className={'custom-input-number'} onChange={(e) => onChangeEditPo(e, 'ExpectedCases')} scrollable={false} />
                </InputGroup>
                <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                    <InputGroup.Addon>Expected Pallets:</InputGroup.Addon>
                    <InputNumber defaultValue={state.ExpectedPallets} className={'custom-input-number'} onChange={(e) => onChangeEditPo(e, 'ExpectedPallets')} scrollable={false} />
                </InputGroup>

                <InputGroup style={{ marginBottom: '50px', width: '100%' }}>
                    <InputGroup.Addon>Notes:</InputGroup.Addon>
                    <Input defaultValue={state.Notes} onChange={(e) => { onChangeEditPo(e, 'Notes') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                </InputGroup>



                <FormGroup>
                    <ButtonToolbar>
                        {unsavedChanges ? <Button onClick={confirm} appearance="primary">Save</Button> : <div></div>}
                        <Button onClick={close} appearance="default">Cancel</Button>
                    </ButtonToolbar>
                </FormGroup>
            </Form>

        </div> </Drawer.Body>
        <Drawer.Footer>
            <br />
        </Drawer.Footer>

    </Drawer>

);



EditPoDrawer.defaultProps = {
    show: false
};

export default EditPoDrawer;


