import React from 'react';
import PropTypes from 'prop-types';
import {
    Drawer,
    Radio,
    RadioGroup,
    Button,
    ButtonToolbar,
    Form,
    FormControl,
    FormGroup,
    ControlLabel,
    HelpBlock,
    DatePicker,
    InputPicker,
    InputGroup,
    Checkbox

} from 'rsuite';
import RFormControl from '../../../componentsGeneral/Rsuite/Form/RFormControl';
import { lettersNumbersDashesInputMask, numbersInputMask, lettersNumbersDashesSpacesInputMask, textStringInputMask } from '../../../../resources/logic/inputMasks';
import DebouncedButton from '../../../componentsGeneral/Rsuite/Button/DebouncedButton';

const styles = {
    // width: 300,
    marginBottom: 10,
    width: '100%'
};

const dropDownLclFcl = [
    {
        "label": "LCL",
        "value": "LCL"
    },
    {
        "label": "FCL",
        "value": "FCL"
    }
]

const mountRef = React.createRef();

const EditContainerDrawer = ({ onChangeAddNewContainer, show, placement, confirm, close, state, ...props }) => (

    <Drawer show={state.showEditContainerDrawer} onHide={close} placement={placement} style={props.isMobile ? { width: '350px' } : { width: '650px' }}    >
        <Drawer.Header>
            <Drawer.Title>Edit Container</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body> <div ref={mountRef}>

            <Form fluid>

                <FormGroup>
                    <ControlLabel>Container</ControlLabel>
                    <FormControl name="Container" value={state.Container} onChange={(e) => { onChangeAddNewContainer(e, 'Container') }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} />
                </FormGroup>

                <FormGroup>
                    <ControlLabel>LCL/FCL</ControlLabel>
                    {/* <FormControl name="CarrierName" value={state.CarrierName} onChange={(e) => {onChangeAddNewContainer(e,'CarrierName')}}  onInput={(e) =>  e.target.value = lettersNumbersDashesSpacesInputMask(e)} /> */}
                    <InputPicker
    preventOverflow
                        container={() => mountRef.current}

                        placeholder={'Select...'}
                        value={state.LclFcl}
                        onChange={(e) => { onChangeAddNewContainer(e, 'LclFcl') }}
                        data={dropDownLclFcl}
                        block
                        cleanable={false}
                    />
                </FormGroup>

                {state.LclFcl === 'FCL' && (
                    <FormGroup>
                        <ControlLabel>Container Size</ControlLabel>
                        <RadioGroup name="containerSize" inline value={String(state.ContainerSize)} onChange={(e) => { onChangeAddNewContainer(e, 'ContainerSize') }}>
                            <Radio value="20">20'</Radio>
                            <Radio value="40">40'</Radio>
                            <Radio value="45">45'</Radio>
                            <Radio value="53">53'</Radio>
                        </RadioGroup>
                    </FormGroup>
                )}

                <FormGroup>
                    <ControlLabel>Delivery Appointment</ControlLabel>
                    <DatePicker
                        container={() => mountRef.current}
                        placeholder="Delivery Appointment"
                        onChange={(e) => { onChangeAddNewContainer(e, 'DeliveryAppointment') }}
                        value={state.DeliveryAppointment}
                        format="YYYY-MM-DD hh:mm aa"
                        defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                        showMeridian
                        ranges={[
                            {
                                label: 'Now',
                                value: new Date()
                            }
                        ]}
                        style={styles}
                    />
                </FormGroup>

                <FormGroup>
                    <ControlLabel>ETD</ControlLabel>
                    <DatePicker
                        container={() => mountRef.current} oneTap style={styles} placeholder="ETD" value={state.ETD} onChange={(e) => { onChangeAddNewContainer(e, 'ETD') }} />
                </FormGroup>

                <FormGroup>
                    <ControlLabel>ETA</ControlLabel>
                    <DatePicker
                        container={() => mountRef.current} oneTap style={styles} placeholder="ETA" value={state.ETA} onChange={(e) => { onChangeAddNewContainer(e, 'ETA') }} />
                </FormGroup>


                <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                    <Checkbox checked={state.Palletized} onChange={(e, checked) => onChangeAddNewContainer(checked, 'Palletized')} >Palletized</Checkbox>
                </InputGroup>

                <FormGroup>
                    <ControlLabel>Notes</ControlLabel>
                    <FormControl name="Notes" value={state.Notes} onChange={(e) => { onChangeAddNewContainer(e, 'Notes') }} onInput={(e) => e.target.value = textStringInputMask(e)} />
                </FormGroup>

                <FormGroup>
                    <ButtonToolbar>
                        <DebouncedButton onClick={confirm} buttonText="Submit" appearance="primary" />
                        <Button onClick={close} appearance="default">Cancel</Button>
                    </ButtonToolbar>
                </FormGroup>
            </Form>

        </div> </Drawer.Body>
        <Drawer.Footer>
            <br />
        </Drawer.Footer>

    </Drawer>

);



EditContainerDrawer.defaultProps = {
    show: false
};

export default EditContainerDrawer;


