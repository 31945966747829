import React, {
    PureComponent
} from 'react';
import AnimatedView from '../../../components/componentsGeneral/AnimatedView/AnimatedView';
import UserManagementContainer from '../../../components/componentsUser/UserManagement/UserManagementContainer';

class userManagementView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {



          };
      }



    render() {

        return(
            <AnimatedView>

                <UserManagementContainer isMobile={this.props.isMobile}/>
                
            </AnimatedView>

        );
    }
}

export default userManagementView;
