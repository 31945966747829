import React from 'react';
import PropTypes from 'prop-types';
import {
    Drawer,
    Paragraph,
    Button,
    ButtonToolbar,
    Checkbox,
    Form,
    FormControl,
    FormGroup,
    ControlLabel,
    HelpBlock,
    DatePicker,

    Row,
    Col,
    Panel,
    InputGroup,
    InputNumber,
    Input,
    Loader,
    InputPicker,

    Divider,
    IconButton,
    Tooltip,
    Whisper

} from 'rsuite';
import RFormControl from '../../../componentsGeneral/Rsuite/Form/RFormControl';
import { lettersNumbersDashesInputMask, numbersInputMask, textNumbersSymbolsInputmask } from '../../../../resources/logic/inputMasks';
import RButtonSmall from '../../../componentsGeneral/Rsuite/Button/RButtonSmall';
import PlusIcon from '@rsuite/icons/Plus';
import { convertNanToZero, isNumericWithZero } from '../../../../resources/logic/functions';
import CloseIcon from '@rsuite/icons/Close';
import * as moment from 'moment'
// import { debounceClick } from '../../../resources/logic/useability';
import debounceClick from '../../../../resources/logic/useability';

const tooltipReceiveDate = (
    <Tooltip>
        Enter Receive Date only if different than current datetime.
    </Tooltip>
);

const styles = {
    width: '100%',
    marginBottom: '10px',
    marginTop: '10px'
};



// function caseCounts(receiveData) {
//     if (Object.keys(receiveData).length > 0 && typeof (receiveData) !== 'undefined' && receiveData != null) {
//         const
//             casekeys = ['Qty'],
//             caseCounts = receiveData.reduce((acc, curr) => {
//                 curr.Cases.forEach((cases) => {
//                     acc[cases.SkuID] = acc[cases.SkuID] || Object.assign(...casekeys.map(k => ({ [k]: 0 })));
//                     casekeys.forEach(k => acc[cases.SkuID][k] += isNumericWithZero(cases[k]));
//                     return acc;
//                 }
//                 )
//                 return acc;
//             }, Object.create(null));
//         return caseCounts
//     }
//     else {
//         return {}
//     }
// }

// function unitCounts(receiveData) {
//     if (Object.keys(receiveData).length > 0 && typeof (receiveData) !== 'undefined' && receiveData != null) {
//         const
//             keys = ['Units'],
//             unitCounts = receiveData.reduce((acc, curr) => {
//                 curr.Cases.forEach((cases) => {
//                     cases.Skus.forEach((skus) => {
//                         acc[skus.SkuID] = acc[skus.SkuID] || Object.assign(...keys.map(k => ({ [k]: 0 })));
//                         keys.forEach(k => acc[skus.SkuID][k] += isNumericWithZero(skus[k]));
//                         return acc;
//                     })
//                     return acc;
//                 }
//                 )
//                 return acc;
//             }, Object.create(null));
//         return unitCounts
//     }
//     else {
//         return {}
//     }
// }



const mountRef = React.createRef();


const ReceiveContainerDrawer = ({ onChange, onClickAddPallet, onChangePallet, onClickAddCase, onClickAddSku, onChangeCase, onChangeCaseSKU, onChangeSku, onClickDuplicatePallet, onClickDuplicateCase, onClickDeletePallet, onClickDeleteCase, onClickDeleteSku, onChangeLocation, onChangeReceiveDate, onClickAddLocation, onClickRemoveFromAdd, show, placement, confirm, close, onClickAddMissing, onChangePalletized, masterLabel, caseCounts, unitCounts, palletCount, state, ...props }) => {
    // Prepare data for InputPicker
    const palletdata = props.InventoryPalletWarehouseData2.map(item => ({
        label: item.PalletBarcode,
        value: item.PalletID
    }));
    return (
        // Your component JSX here
        // <div>
        state.showReceiveDrawer === false ?
            <div align="center">
            </div>
            :
            props.InventoryRecoveryPostLoading ?
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
                :
                state.receiveDrawerLoading === true ?
                    <Drawer show={state.showReceiveDrawer} onHide={close} placement={placement} style={props.isMobile ? { width: '30px' } : { width: '800px' }}    >
                        <Drawer.Header>
                            <Drawer.Title style={{ marginBottom: '15px' }}>Container: {masterLabel}</Drawer.Title>
                        </Drawer.Header>

                        <Drawer.Body> <div ref={mountRef} style={{ overflow: 'hidden' }}>

                            <div align="center">
                                <section className="panel">
                                </section>
                                <Loader
                                    type="TailSpin"
                                    color="#3396E5"
                                    height="50"
                                    width="50"
                                />
                            </div>

                        </div> </Drawer.Body>

                        <Drawer.Footer>
                        </Drawer.Footer>

                    </Drawer>

                    :

                    <Drawer show={state.showReceiveDrawer} onHide={close} placement={placement} style={props.isMobile ? { width: '30px' } : { width: '900px' }}    >
                        <Drawer.Header>
                            <Drawer.Title style={{ marginBottom: '15px' }}>Container: {masterLabel}</Drawer.Title>

                            <FormGroup>
                                <ButtonToolbar >
                                    <Button onClick={debounceClick(confirm)} appearance="primary">Save</Button>
                                    <Button onClick={close} appearance="default">Cancel</Button>
                                </ButtonToolbar>

                            </FormGroup>
                        </Drawer.Header>

                        <Drawer.Body> <div ref={mountRef} style={{ overflow: 'hidden' }}>

                            <Form fluid>

                                <Whisper placement="top" trigger="hover" speaker={tooltipReceiveDate}>
                                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                        <InputGroup.Addon>Receive Date (Optional):</InputGroup.Addon>
                                        <DatePicker
                                            container={() => mountRef.current}
                                            placeholder="Edit Receive Date"
                                            onChange={(e) => { onChangeReceiveDate(e) }}
                                            value={state.ReceiveDate}
                                            format="YYYY-MM-DD hh:mm aa"
                                            defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                                            showMeridian
                                            ranges={[
                                                {
                                                    label: 'Now',
                                                    value: new Date()
                                                }
                                            ]}
                                            style={{ width: '100%' }}
                                        />
                                    </InputGroup>
                                </Whisper>

                                <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                    <Checkbox checked={state.receiveDataRowData['Palletized']} onChange={(e, checked) => onChangePalletized(checked)} >Palletized</Checkbox>
                                </InputGroup>

                                <Row >
                                    <Col md={24} sm={24}>
                                        <Panel bordered style={{ marginBottom: '10px' }}>

                                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                                <InputGroup.Addon>Uncategorized Units:</InputGroup.Addon>
                                                <InputNumber readOnly value={typeof (unitCounts['null']) !== 'undefined' ? unitCounts['null'].Units : 0} className={'custom-input-number'} scrollable={false} />
                                            </InputGroup>

                                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                                <InputGroup.Addon>Uncategorized Cases:</InputGroup.Addon>
                                                <InputNumber readOnly value={typeof (caseCounts['null']) !== 'undefined' ? caseCounts['null'].Qty : 0} className={'custom-input-number'} scrollable={false} />
                                            </InputGroup>

                                        </Panel>
                                    </Col>
                                </Row>

                                <Row >
                                    <Col md={24} sm={24}>
                                        <Panel bordered style={{ marginBottom: '10px' }}>
                                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                                <InputGroup.Addon>Pallet Count:</InputGroup.Addon>
                                                <InputNumber readOnly value={palletCount} className={'custom-input-number'} scrollable={false} />
                                            </InputGroup>
                                        </Panel>
                                    </Col>
                                </Row>

                                {props.InventorySkuRecoveryData.map(item => (
                                    <Row >
                                        <Col md={24} sm={24}>
                                            <Panel bordered style={{ marginBottom: '10px' }}>

                                                <Row>
                                                    <Col md={8} sm={24}>
                                                        SKU: <strong>{item.SKU}</strong>
                                                        <br />
                                                        Remaining Units: <strong>{convertNanToZero(item.Units) - convertNanToZero(item.UnitsReceived) - convertNanToZero(typeof (unitCounts[item.SkuID]) !== 'undefined' ? unitCounts[item.SkuID].Units : 0)}</strong>
                                                        <br />
                                                        Remaining Cases: <strong>{convertNanToZero(item.Cases) - convertNanToZero(item.CasesReceived) - convertNanToZero(typeof (caseCounts[item.SkuID]) !== 'undefined' ? caseCounts[item.SkuID].Qty : 0)}</strong>
                                                        <br />
                                                        {/* <Checkbox onChange={(e, value) => { onChange(value, item, 'Customs') }}> Customs Cleared</Checkbox> */}
                                                    </Col>

                                                    <Col md={16} sm={24}>
                                                        <FormGroup >
                                                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                                                <InputGroup.Addon>Total Recovered Units:</InputGroup.Addon>
                                                                {/* <InputNumber defaultValue={item.Cartons - item.RecoveredPCS } className={'custom-input-number'} onChange={(e) => onChange(e, item, 'Recovered')} scrollable={false} /> */}
                                                                <InputNumber readOnly value={typeof (unitCounts[item.SkuID]) !== 'undefined' ? unitCounts[item.SkuID].Units : 0} className={'custom-input-number'} scrollable={false} />
                                                            </InputGroup>
                                                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                                                <InputGroup.Addon>Total Recovered Cases:</InputGroup.Addon>
                                                                {/* <InputNumber defaultValue={item.Cartons - item.RecoveredPCS } className={'custom-input-number'} onChange={(e) => onChange(e, item, 'Recovered')} scrollable={false} /> */}
                                                                <InputNumber readOnly value={typeof (caseCounts[item.SkuID]) !== 'undefined' ? caseCounts[item.SkuID].Qty : 0} className={'custom-input-number'} scrollable={false} />
                                                            </InputGroup>

                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Panel>
                                        </Col>
                                    </Row>
                                ))}


                                <Panel bordered style={{ marginBottom: '10px' }}>

                                    <Row >

                                        {state.ReceivePalletDict.map(pallet => (
                                            // Pallet
                                            <Panel bordered style={{ marginBottom: '10px' }}>

                                                <Row>
                                                    <Col md={7} sm={24} >

                                                        <Row style={{ paddingRight: '10px' }}>
                                                            <Button block active style={{ marginBottom: '10px', width: '100%', cursor: 'default' }} >Pallet Type {pallet.PalletSeq}</Button>
                                                        </Row>



                                                        <Row style={{ paddingRight: '10px' }}>
                                                            <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                                {/* <InputGroup.Addon>Location:</InputGroup.Addon> */}
                                                                <InputPicker
                                                                    style={{ width: '100%' }}
                                                                    container={() => mountRef.current}
                                                                    // placeholder={label}
                                                                    placeholder={'Existing Pallet...'}
                                                                    value={pallet['PalletID']}
                                                                    onChange={(e) => { onChangePallet(e, pallet, 'PalletID') }}
                                                                    data={palletdata}
                                                                    block
                                                                    cleanable={true}
                                                                />
                                                            </InputGroup>
                                                        </Row>





                                                        <Row style={{ paddingRight: '10px' }}>
                                                            <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                                {/* <InputGroup.Addon>Location:</InputGroup.Addon> */}
                                                                {pallet['PalletID'] === null ? (
                                                                    <InputPicker
                                                                        style={{ width: '100%' }}
                                                                        container={() => mountRef.current}
                                                                        // placeholder={label}
                                                                        placeholder={'Location...'}
                                                                        value={pallet['WarehouseLocationID']}
                                                                        onChange={(e) => { onChangePallet(e, pallet, 'WarehouseLocationID') }}
                                                                        data={props.WarehouseLocationsData}
                                                                        block
                                                                        cleanable={true}
                                                                    />
                                                                ) : (
                                                                    <InputPicker
                                                                        style={{ width: '100%' }}
                                                                        container={() => mountRef.current}
                                                                        placeholder={'Location...'}
                                                                        value={pallet['WarehouseLocationID']}
                                                                        data={props.WarehouseLocationsData}
                                                                        block
                                                                        // readOnly
                                                                        disabled
                                                                        cleanable={false}
                                                                    />
                                                                )}
                                                            </InputGroup>
                                                        </Row>

                                                        <Row style={{ paddingRight: '10px' }}>
                                                            <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                                <InputGroup.Addon>Qty:</InputGroup.Addon>

                                                                {pallet['PalletID'] === null ? (
                                                                    <InputNumber value={pallet.Qty} className={'custom-input-number'} onChange={(e) => onChangePallet(e, pallet, 'Qty')} scrollable={false} />
                                                                ) : (
                                                                    <InputNumber disabled value={pallet.Qty} className={'custom-input-number'} scrollable={false} />
                                                                )}

                                                            </InputGroup>
                                                        </Row>




                                                        <Row style={{ paddingRight: '10px' }}>
                                                            <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                                {pallet['PalletID'] === null ? (
                                                                    <Checkbox checked={pallet['Oversize']} onChange={(e, checked) => { onChangePallet(checked, pallet, 'Oversize') }} >Oversize</Checkbox>
                                                                ) : (
                                                                    <Checkbox disabled checked={pallet['Oversize']}  >Oversize</Checkbox>
                                                                )}


                                                            </InputGroup>
                                                        </Row>

                                                        {pallet['PalletID'] === null && (
                                                            <Row style={{ paddingRight: '10px' }}>
                                                                <Button block style={{ marginBottom: '10px', width: '100%' }} onClick={(e) => onClickDuplicatePallet(e, pallet)} >Duplicate Pallet</Button>
                                                            </Row>
                                                        )}

                                                        <Row style={{ paddingRight: '10px' }}>
                                                            <Button block style={{ marginBottom: '10px', width: '100%' }} onClick={(e) => onClickDeletePallet(e, pallet)} >Delete Pallet</Button>
                                                        </Row>


                                                    </Col>

                                                    <Col md={17} sm={24}>

                                                        {pallet.Cases.map(cases => (
                                                            <Row >

                                                                <Panel bordered style={{ marginBottom: '10px' }}>

                                                                    <Row >
                                                                        <Button block active style={{ marginBottom: '10px', width: '100%', cursor: 'default' }} >Case Type {cases.CaseSeq}</Button>
                                                                    </Row>

                                                                    {/* <Row>
                                                                <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                                    <InputGroup.Addon>SKU:</InputGroup.Addon>
                                                                    <Input value={cases.SKU} className={'custom-input-number'} onChange={(e) => onChangeCase(e, pallet, cases, 'SKU')} scrollable={false} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                </InputGroup>
                                                            </Row> */}

                                                                    <Row >
                                                                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                                            {/* <InputGroup.Addon>Location:</InputGroup.Addon> */}
                                                                            <InputPicker
                                                                                container={() => mountRef.current}
                                                                                // placeholder={label}
                                                                                placeholder={'SKU...'}
                                                                                value={cases['SkuID']}
                                                                                onChange={(e) => { onChangeCase(e, pallet, cases, 'SkuID') }}
                                                                                data={props.InventorySkuRecoveryData}
                                                                                style={{ width: '100%' }}
                                                                                block
                                                                                cleanable={true}

                                                                            // creatable

                                                                            // onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)}
                                                                            // onInput={(e) => { onChangeCaseSKU(e, pallet, cases, 'SKU') }}

                                                                            />
                                                                        </InputGroup>
                                                                    </Row>

                                                                    <Row>
                                                                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                                            <InputGroup.Addon>Case Qty:</InputGroup.Addon>
                                                                            <InputNumber value={cases.Qty} className={'custom-input-number'} onChange={(e) => onChangeCase(e, pallet, cases, 'Qty')} scrollable={false} />
                                                                        </InputGroup>
                                                                    </Row>

                                                                    <Row>
                                                                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                                            <InputGroup.Addon>Lot Code:</InputGroup.Addon>
                                                                            <Input value={cases.LotCode} className={'custom-input-number'} onChange={(e) => onChangeCase(e, pallet, cases, 'LotCode')} scrollable={false} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                        </InputGroup>
                                                                    </Row>

                                                                    <Row>
                                                                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                                            <InputGroup.Addon>Serial Number:</InputGroup.Addon>
                                                                            <Input value={cases.SerialNum} className={'custom-input-number'} onChange={(e) => onChangeCase(e, pallet, cases, 'SerialNum')} scrollable={false} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                        </InputGroup>
                                                                    </Row>


                                                                    {cases.Skus.filter(x => x.SkuSeq !== 0).length > 0 ?
                                                                        <div>
                                                                            <Divider>Units</Divider>

                                                                            {cases.Skus.map(skus => (

                                                                                // cases.SkuSeq
                                                                                <Panel bordered style={{ backgroundColor: '#D8D8D8', marginBottom: '10px' }}>


                                                                                    <Row style={{ paddingRight: '10px' }}>
                                                                                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                                                            {/* <InputGroup.Addon>Location:</InputGroup.Addon> */}
                                                                                            <InputPicker
                                                                                                container={() => mountRef.current}
                                                                                                // placeholder={label}
                                                                                                placeholder={'SKU...'}
                                                                                                value={skus['SkuID']}
                                                                                                onChange={(e) => { onChangeSku(e, pallet, cases, skus, 'SkuID') }}
                                                                                                data={props.InventorySkuRecoveryData}
                                                                                                style={{ width: '100%' }}
                                                                                                block
                                                                                                cleanable={true}
                                                                                            />
                                                                                        </InputGroup>
                                                                                    </Row>




                                                                                    <Row>
                                                                                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                                                            <InputGroup.Addon>Units:</InputGroup.Addon>
                                                                                            <InputNumber value={skus.Units} className={'custom-input-number'} onChange={(e) => onChangeSku(e, pallet, cases, skus, 'Units')} scrollable={false} />
                                                                                        </InputGroup>
                                                                                    </Row>


                                                                                    <Row>
                                                                                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                                                            <InputGroup.Addon>Lot Code:</InputGroup.Addon>
                                                                                            <Input value={skus.LotCode} className={'custom-input-number'} onChange={(e) => onChangeSku(e, pallet, cases, skus, 'LotCode')} scrollable={false} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                                        </InputGroup>
                                                                                    </Row>

                                                                                    <Row>
                                                                                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                                                            <InputGroup.Addon>Serial Number:</InputGroup.Addon>
                                                                                            <Input value={skus.SerialNum} className={'custom-input-number'} onChange={(e) => onChangeSku(e, pallet, cases, skus, 'SerialNum')} scrollable={false} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                                        </InputGroup>
                                                                                    </Row>

                                                                                    <Row >
                                                                                        <Button block style={{ marginBottom: '10px', width: '100%' }} onClick={(e) => onClickDeleteSku(e, pallet, cases, skus)} >Delete Unit</Button>
                                                                                    </Row>


                                                                                </Panel>

                                                                            ))}
                                                                        </div>
                                                                        :
                                                                        <div> </div>
                                                                    }

                                                                    <Row>
                                                                        <Button style={{ float: 'right', marginBottom: '10px', }} onClick={(e) => onClickAddSku(e, pallet, cases)} appearance="default">+  Add Unit</Button>
                                                                    </Row>

                                                                    <Row >
                                                                        <Button block style={{ marginBottom: '10px', width: '100%' }} onClick={(e) => onClickDuplicateCase(e, pallet, cases)} >Duplicate Case</Button>
                                                                    </Row>

                                                                    <Row >
                                                                        <Button block style={{ width: '100%' }} onClick={(e) => onClickDeleteCase(e, pallet, cases)} >Delete Case</Button>
                                                                    </Row>




                                                                </Panel>

                                                            </Row>

                                                        ))}

                                                        <Row>
                                                            <Button style={{ float: 'right', marginBottom: '10px', }} onClick={(e) => onClickAddCase(e, pallet)} appearance="default">+  Add Case</Button>
                                                        </Row>

                                                    </Col>
                                                </Row>



                                            </Panel>



                                        ))}
                                    </Row>


                                    <Row>
                                        <Button style={{ float: 'right' }} onClick={(e) => onClickAddPallet(e)} appearance="default">+  Add Pallet</Button>
                                    </Row>





                                </Panel>




                            </Form>

                        </div> </Drawer.Body>



                        <Drawer.Footer>



                        </Drawer.Footer>


                    </Drawer>
        // </div>
    );
}




// const ReceiveContainerDrawer = ({ onChange, onClickAddPallet, onChangePallet, onClickAddCase, onClickAddSku, onChangeCase, onChangeCaseSKU, onChangeSku, onClickDuplicatePallet, onClickDuplicateCase, onClickDeletePallet, onClickDeleteCase, onClickDeleteSku, onChangeLocation, onChangeReceiveDate, onClickAddLocation, onClickRemoveFromAdd, show, placement, confirm, close, onClickAddMissing, onChangePalletized, masterLabel, caseCounts, unitCounts, palletCount, state, ...props }) => (

//     state.showReceiveDrawer === false ?
//         <div align="center">
//         </div>
//         :
//         props.InventoryRecoveryPostLoading ?
//             <div align="center">
//                 <section className="panel">
//                 </section>
//                 <Loader
//                     type="TailSpin"
//                     color="#3396E5"
//                     height="50"
//                     width="50"
//                 />
//             </div>
//             :
//             state.receiveDrawerLoading === true ?
//                 <Drawer show={state.showReceiveDrawer} onHide={close} placement={placement} style={props.isMobile ? { width: '30px' } : { width: '800px' }}    >
//                     <Drawer.Header>
//                         <Drawer.Title style={{ marginBottom: '15px' }}>Container: {masterLabel}</Drawer.Title>
//                     </Drawer.Header>

//                     <Drawer.Body> <div ref={mountRef} style={{ overflow: 'hidden' }}>

//                         <div align="center">
//                             <section className="panel">
//                             </section>
//                             <Loader
//                                 type="TailSpin"
//                                 color="#3396E5"
//                                 height="50"
//                                 width="50"
//                             />
//                         </div>

//                     </div> </Drawer.Body>

//                     <Drawer.Footer>
//                     </Drawer.Footer>

//                 </Drawer>

//                 :

//                 <Drawer show={state.showReceiveDrawer} onHide={close} placement={placement} style={props.isMobile ? { width: '30px' } : { width: '800px' }}    >
//                     <Drawer.Header>
//                         <Drawer.Title style={{ marginBottom: '15px' }}>Container: {masterLabel}</Drawer.Title>

//                         <FormGroup>
//                             <ButtonToolbar >
//                                 <Button onClick={debounceClick(confirm)} appearance="primary">Save</Button>
//                                 <Button onClick={close} appearance="default">Cancel</Button>
//                             </ButtonToolbar>

//                         </FormGroup>
//                     </Drawer.Header>

//                     <Drawer.Body> <div ref={mountRef} style={{ overflow: 'hidden' }}>

//                         <Form fluid>

//                             <Whisper placement="top" trigger="hover" speaker={tooltipReceiveDate}>
//                                 <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
//                                     <InputGroup.Addon>Receive Date (Optional):</InputGroup.Addon>
//                                     <DatePicker
//                                         container={() => mountRef.current}
//                                         placeholder="Edit Receive Date"
//                                         onChange={(e) => { onChangeReceiveDate(e) }}
//                                         value={state.ReceiveDate}
//                                         format="YYYY-MM-DD hh:mm aa"
//                                         defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
//                                         showMeridian
//                                         ranges={[
//                                             {
//                                                 label: 'Now',
//                                                 value: new Date()
//                                             }
//                                         ]}
//                                         style={{ width: '100%' }}
//                                     />
//                                 </InputGroup>
//                             </Whisper>

//                             <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
//                                 <Checkbox checked={state.receiveDataRowData['Palletized']} onChange={(e, checked) => onChangePalletized(checked)} >Palletized</Checkbox>
//                             </InputGroup>

//                             <Row >
//                                 <Col md={24} sm={24}>
//                                     <Panel bordered style={{ marginBottom: '10px' }}>

//                                         <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
//                                             <InputGroup.Addon>Uncategorized Units:</InputGroup.Addon>
//                                             <InputNumber readOnly value={typeof (unitCounts['null']) !== 'undefined' ? unitCounts['null'].Units : 0} className={'custom-input-number'} scrollable={false} />
//                                         </InputGroup>

//                                         <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
//                                             <InputGroup.Addon>Uncategorized Cases:</InputGroup.Addon>
//                                             <InputNumber readOnly value={typeof (caseCounts['null']) !== 'undefined' ? caseCounts['null'].Qty : 0} className={'custom-input-number'} scrollable={false} />
//                                         </InputGroup>

//                                     </Panel>
//                                 </Col>
//                             </Row>

//                             <Row >
//                                 <Col md={24} sm={24}>
//                                     <Panel bordered style={{ marginBottom: '10px' }}>
//                                         <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
//                                             <InputGroup.Addon>Pallet Count:</InputGroup.Addon>
//                                             <InputNumber readOnly value={palletCount} className={'custom-input-number'} scrollable={false} />
//                                         </InputGroup>
//                                     </Panel>
//                                 </Col>
//                             </Row>

//                             {props.InventorySkuRecoveryData.map(item => (
//                                 <Row >
//                                     <Col md={24} sm={24}>
//                                         <Panel bordered style={{ marginBottom: '10px' }}>

//                                             <Row>
//                                                 <Col md={8} sm={24}>
//                                                     SKU: <strong>{item.SKU}</strong>
//                                                     <br />
//                                                     Remaining Units: <strong>{convertNanToZero(item.Units) - convertNanToZero(item.UnitsReceived) - convertNanToZero(typeof (unitCounts[item.SkuID]) !== 'undefined' ? unitCounts[item.SkuID].Units : 0)}</strong>
//                                                     <br />
//                                                     Remaining Cases: <strong>{convertNanToZero(item.Cases) - convertNanToZero(item.CasesReceived) - convertNanToZero(typeof (caseCounts[item.SkuID]) !== 'undefined' ? caseCounts[item.SkuID].Qty : 0)}</strong>
//                                                     <br />
//                                                     {/* <Checkbox onChange={(e, value) => { onChange(value, item, 'Customs') }}> Customs Cleared</Checkbox> */}
//                                                 </Col>

//                                                 <Col md={16} sm={24}>
//                                                     <FormGroup >
//                                                         <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
//                                                             <InputGroup.Addon>Total Recovered Units:</InputGroup.Addon>
//                                                             {/* <InputNumber defaultValue={item.Cartons - item.RecoveredPCS } className={'custom-input-number'} onChange={(e) => onChange(e, item, 'Recovered')} scrollable={false} /> */}
//                                                             <InputNumber readOnly value={typeof (unitCounts[item.SkuID]) !== 'undefined' ? unitCounts[item.SkuID].Units : 0} className={'custom-input-number'} scrollable={false} />
//                                                         </InputGroup>
//                                                         <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
//                                                             <InputGroup.Addon>Total Recovered Cases:</InputGroup.Addon>
//                                                             {/* <InputNumber defaultValue={item.Cartons - item.RecoveredPCS } className={'custom-input-number'} onChange={(e) => onChange(e, item, 'Recovered')} scrollable={false} /> */}
//                                                             <InputNumber readOnly value={typeof (caseCounts[item.SkuID]) !== 'undefined' ? caseCounts[item.SkuID].Qty : 0} className={'custom-input-number'} scrollable={false} />
//                                                         </InputGroup>

//                                                     </FormGroup>
//                                                 </Col>
//                                             </Row>
//                                         </Panel>
//                                     </Col>
//                                 </Row>
//                             ))}


//                             <Panel bordered style={{ marginBottom: '10px' }}>

//                                 <Row >

//                                     {state.ReceivePalletDict.map(pallet => (
//                                         // Pallet
//                                         <Panel bordered style={{ marginBottom: '10px' }}>

//                                             <Row>
//                                                 <Col md={6} sm={24} >

//                                                     <Row style={{ paddingRight: '10px' }}>
//                                                         <Button block active style={{ marginBottom: '10px', width: '100%', cursor: 'default' }} >Pallet Type {pallet.PalletSeq}</Button>
//                                                     </Row>

//                                                     <Row style={{ paddingRight: '10px' }}>
//                                                         <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
//                                                             {/* <InputGroup.Addon>Location:</InputGroup.Addon> */}
//                                                             <InputPicker
//                                                                 container={() => mountRef.current}
//                                                                 // placeholder={label}
//                                                                 placeholder={'Location...'}
//                                                                 value={pallet['WarehouseLocationID']}
//                                                                 onChange={(e) => { onChangePallet(e, pallet, 'WarehouseLocationID') }}
//                                                                 data={props.WarehouseLocationsData}
//                                                                 block
//                                                                 cleanable={true}
//                                                             />
//                                                         </InputGroup>
//                                                     </Row>

//                                                     <Row style={{ paddingRight: '10px' }}>
//                                                         <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
//                                                             <InputGroup.Addon>Qty:</InputGroup.Addon>
//                                                             <InputNumber value={pallet.Qty} className={'custom-input-number'} onChange={(e) => onChangePallet(e, pallet, 'Qty')} scrollable={false} />
//                                                         </InputGroup>
//                                                     </Row>




//                                                     <Row style={{ paddingRight: '10px' }}>
//                                                         <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
//                                                             <Checkbox checked={pallet['Oversize']} onChange={(e, checked) => { onChangePallet(checked, pallet, 'Oversize') }} >Oversize</Checkbox>
//                                                         </InputGroup>
//                                                     </Row>

//                                                     <Row style={{ paddingRight: '10px' }}>
//                                                         <Button block style={{ marginBottom: '10px', width: '100%' }} onClick={(e) => onClickDuplicatePallet(e, pallet)} >Duplicate Pallet</Button>
//                                                     </Row>


//                                                     <Row style={{ paddingRight: '10px' }}>
//                                                         <Button block style={{ marginBottom: '10px', width: '100%' }} onClick={(e) => onClickDeletePallet(e, pallet)} >Delete Pallet</Button>
//                                                     </Row>


//                                                 </Col>

//                                                 <Col md={18} sm={24}>

//                                                     {pallet.Cases.map(cases => (
//                                                         <Row >

//                                                             <Panel bordered style={{ marginBottom: '10px' }}>

//                                                                 <Row >
//                                                                     <Button block active style={{ marginBottom: '10px', width: '100%', cursor: 'default' }} >Case Type {cases.CaseSeq}</Button>
//                                                                 </Row>

//                                                                 {/* <Row>
//                                                                 <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
//                                                                     <InputGroup.Addon>SKU:</InputGroup.Addon>
//                                                                     <Input value={cases.SKU} className={'custom-input-number'} onChange={(e) => onChangeCase(e, pallet, cases, 'SKU')} scrollable={false} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
//                                                                 </InputGroup>
//                                                             </Row> */}

//                                                                 <Row >
//                                                                     <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
//                                                                         {/* <InputGroup.Addon>Location:</InputGroup.Addon> */}
//                                                                         <InputPicker
//                                                                             container={() => mountRef.current}
//                                                                             // placeholder={label}
//                                                                             placeholder={'SKU...'}
//                                                                             value={cases['SkuID']}
//                                                                             onChange={(e) => { onChangeCase(e, pallet, cases, 'SkuID') }}
//                                                                             data={props.InventorySkuRecoveryData}
//                                                                             style={{ width: '100%' }}
//                                                                             block
//                                                                             cleanable={true}

//                                                                             // creatable

//                                                                         // onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)}
//                                                                         // onInput={(e) => { onChangeCaseSKU(e, pallet, cases, 'SKU') }}

//                                                                         />
//                                                                     </InputGroup>
//                                                                 </Row>

//                                                                 <Row>
//                                                                     <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
//                                                                         <InputGroup.Addon>Case Qty:</InputGroup.Addon>
//                                                                         <InputNumber value={cases.Qty} className={'custom-input-number'} onChange={(e) => onChangeCase(e, pallet, cases, 'Qty')} scrollable={false} />
//                                                                     </InputGroup>
//                                                                 </Row>

//                                                                 <Row>
//                                                                     <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
//                                                                         <InputGroup.Addon>Lot Code:</InputGroup.Addon>
//                                                                         <Input value={cases.LotCode} className={'custom-input-number'} onChange={(e) => onChangeCase(e, pallet, cases, 'LotCode')} scrollable={false} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
//                                                                     </InputGroup>
//                                                                 </Row>

//                                                                 <Row>
//                                                                     <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
//                                                                         <InputGroup.Addon>Serial Number:</InputGroup.Addon>
//                                                                         <Input value={cases.SerialNum} className={'custom-input-number'} onChange={(e) => onChangeCase(e, pallet, cases, 'SerialNum')} scrollable={false} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
//                                                                     </InputGroup>
//                                                                 </Row>


//                                                                 {cases.Skus.filter(x => x.SkuSeq !== 0).length > 0 ?
//                                                                     <div>
//                                                                         <Divider>Units</Divider>

//                                                                         {cases.Skus.map(skus => (

//                                                                             // cases.SkuSeq
//                                                                             <Panel bordered style={{ backgroundColor: '#D8D8D8', marginBottom: '10px' }}>


//                                                                                 <Row style={{ paddingRight: '10px' }}>
//                                                                                     <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
//                                                                                         {/* <InputGroup.Addon>Location:</InputGroup.Addon> */}
//                                                                                         <InputPicker
//                                                                                             container={() => mountRef.current}
//                                                                                             // placeholder={label}
//                                                                                             placeholder={'SKU...'}
//                                                                                             value={skus['SkuID']}
//                                                                                             onChange={(e) => { onChangeSku(e, pallet, cases, skus, 'SkuID') }}
//                                                                                             data={props.InventorySkuRecoveryData}
//                                                                                             style={{ width: '100%' }}
//                                                                                             block
//                                                                                             cleanable={true}
//                                                                                         />
//                                                                                     </InputGroup>
//                                                                                 </Row>




//                                                                                 <Row>
//                                                                                     <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
//                                                                                         <InputGroup.Addon>Units:</InputGroup.Addon>
//                                                                                         <InputNumber value={skus.Units} className={'custom-input-number'} onChange={(e) => onChangeSku(e, pallet, cases, skus, 'Units')} scrollable={false} />
//                                                                                     </InputGroup>
//                                                                                 </Row>


//                                                                                 <Row>
//                                                                                     <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
//                                                                                         <InputGroup.Addon>Lot Code:</InputGroup.Addon>
//                                                                                         <Input value={skus.LotCode} className={'custom-input-number'} onChange={(e) => onChangeSku(e, pallet, cases, skus, 'LotCode')} scrollable={false} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
//                                                                                     </InputGroup>
//                                                                                 </Row>

//                                                                                 <Row>
//                                                                                     <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
//                                                                                         <InputGroup.Addon>Serial Number:</InputGroup.Addon>
//                                                                                         <Input value={skus.SerialNum} className={'custom-input-number'} onChange={(e) => onChangeSku(e, pallet, cases, skus, 'SerialNum')} scrollable={false} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
//                                                                                     </InputGroup>
//                                                                                 </Row>

//                                                                                 <Row >
//                                                                                     <Button block style={{ marginBottom: '10px', width: '100%' }} onClick={(e) => onClickDeleteSku(e, pallet, cases, skus)} >Delete Unit</Button>
//                                                                                 </Row>


//                                                                             </Panel>

//                                                                         ))}
//                                                                     </div>
//                                                                     :
//                                                                     <div> </div>
//                                                                 }

//                                                                 <Row>
//                                                                     <Button style={{ float: 'right', marginBottom: '10px', }} onClick={(e) => onClickAddSku(e, pallet, cases)} appearance="default">+  Add Unit</Button>
//                                                                 </Row>

//                                                                 <Row >
//                                                                     <Button block style={{ marginBottom: '10px',  width: '100%' }} onClick={(e) => onClickDuplicateCase(e, pallet, cases)} >Duplicate Case</Button>
//                                                                 </Row>

//                                                                 <Row >
//                                                                     <Button block style={{ width: '100%' }} onClick={(e) => onClickDeleteCase(e, pallet, cases)} >Delete Case</Button>
//                                                                 </Row>




//                                                             </Panel>

//                                                         </Row>

//                                                     ))}

//                                                     <Row>
//                                                         <Button style={{ float: 'right', marginBottom: '10px', }} onClick={(e) => onClickAddCase(e, pallet)} appearance="default">+  Add Case</Button>
//                                                     </Row>

//                                                 </Col>
//                                             </Row>



//                                         </Panel>



//                                     ))}
//                                 </Row>


//                                 <Row>
//                                     <Button style={{ float: 'right' }} onClick={(e) => onClickAddPallet(e)} appearance="default">+  Add Pallet</Button>
//                                 </Row>





//                             </Panel>




//                         </Form>

//                     </div> </Drawer.Body>



//                     <Drawer.Footer>



//                     </Drawer.Footer>


//                 </Drawer>

// );


ReceiveContainerDrawer.defaultProps = {
    show: false
};

export default ReceiveContainerDrawer;








// Pallets
// [PalletID]
// ,[PalletBarcode]
// ,[PalletStatusID]
// ,[WarehouseID]
// ,[CustomerID]
// ,[CreatedByUserID]
// ,[PalletCreateDT]
// ,[Notes]
// ,[Oversize]
// ,[WarehouseLocationID]




// Cases
// [CaseID]
// ,[CaseBarcode]
// ,[CaseStatusID]
// ,[WarehouseID]
// ,[CustomerID]
// ,[CreatedByUserID]
// ,[CaseCreateDT]
// ,[Notes]


// SKUs
// [CaseSkuID]
// ,[CaseID]
// ,[SkuID]
// ,[Units]
// ,[CreatedDT]
// ,[CreatedByUserID]


















// {props.InventorySkuRecoveryData.map(item => (

//     <Row >
//         <Col md={24} sm={24}>
//             <Panel bordered style={{ marginBottom: '10px' }}>

//                 <Row>
//                     <Col md={8} sm={24}>
//                         SKU: <strong>{item.SKU}</strong>
//                         <br />
//                         Remaining Units: <strong>{convertNanToZero(item.Units) - convertNanToZero(item.UnitsReceived) - convertNanToZero(state.ReceiveDict[item.SkuID].Units)}</strong>
//                         <br />
//                         Remaining Cases: <strong>{convertNanToZero(item.Cases) - convertNanToZero(item.CasesReceived) - convertNanToZero(state.ReceiveDict[item.SkuID].Cases)}</strong>
//                         <br />
//                         {/* <Checkbox onChange={(e, value) => { onChange(value, item, 'Customs') }}> Customs Cleared</Checkbox> */}
//                     </Col>

//                     <Col md={16} sm={24}>
//                         <FormGroup >
//                             <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
//                                 <InputGroup.Addon>Total Recovered Cases:</InputGroup.Addon>
//                                 {/* <InputNumber defaultValue={item.Cartons - item.RecoveredPCS } className={'custom-input-number'} onChange={(e) => onChange(e, item, 'Recovered')} scrollable={false} /> */}
//                                 <InputNumber readOnly value={state.ReceiveDict[item.SkuID].Cases} className={'custom-input-number'} onChange={(e) => onChange(e, item, 'Cases')} scrollable={false} />
//                             </InputGroup>
//                             <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
//                                 <InputGroup.Addon>Total Pallets:</InputGroup.Addon>
//                                 <InputNumber readOnly value={state.ReceiveDict[item.SkuID].Pallets} className={'custom-input-number'} onChange={(e) => onChange(e, item, 'Pallets')} scrollable={false} />
//                             </InputGroup>
//                         </FormGroup>
//                     </Col>


//                 </Row>
//                 <Row>

//                     <Col md={24} sm={24}>
//                         <FormGroup >
//                             <InputGroup style={{ marginTop: '5px', width: '100%' }}>
//                                 <InputGroup.Addon>Notes:</InputGroup.Addon>
//                                 <Input onChange={(e) => { onChange(e, item, 'Notes') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
//                             </InputGroup>
//                         </FormGroup>
//                     </Col>

//                 </Row>

//                 <Divider />


//                 {state.LocationDict[item.SkuID].map(locationitem => (
//                     <div>

//                         <Row>
//                             <Col md={4} sm={4}>
// <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
//     {/* <InputGroup.Addon>Location:</InputGroup.Addon> */}
//     <InputPicker
//         container={() => mountRef.current}
//         // placeholder={label}
//         placeholder={'Location...'}
//         value={locationitem['WarehouseLocationID']}
//         onChange={(e) => { onChangeLocation(e, item, locationitem, 'WarehouseLocationID', locationitem['WarehouseLocationID']) }}
//         data={props.WarehouseLocationsData}
//         block
//         cleanable={true}
//     />
// </InputGroup>
//                             </Col>
//                             <Col md={5} sm={5}>
//                                 <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
//                                     <InputGroup.Addon>Units:</InputGroup.Addon>
//                                     <InputNumber value={locationitem['Units']} className={'custom-input-number'} onChange={(e) => onChangeLocation(e, item, locationitem, 'Units', locationitem['WarehouseLocationID'])} scrollable={false} />
//                                 </InputGroup>
//                             </Col>
//                             <Col md={5} sm={5}>
//                                 <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
//                                     <InputGroup.Addon>Cases:</InputGroup.Addon>
//                                     <InputNumber value={locationitem['Cases']} className={'custom-input-number'} onChange={(e) => onChangeLocation(e, item, locationitem, 'Cases', locationitem['WarehouseLocationID'])} scrollable={false} />
//                                 </InputGroup>
//                             </Col>
//                             <Col md={5} sm={5}>
//                                 <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
//                                     <InputGroup.Addon>Pallets:</InputGroup.Addon>
//                                     <InputNumber value={locationitem['Pallets']} className={'custom-input-number'} onChange={(e) => onChangeLocation(e, item, locationitem, 'Pallets', locationitem['WarehouseLocationID'])} scrollable={false} />
//                                 </InputGroup>
//                             </Col>


//                             <Col md={4} sm={4}>
//                                 <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
//                                     {/* <InputGroup.Addon>Oversize:</InputGroup.Addon> */}
//                                     <Checkbox checked={locationitem['Oversize']} onChange={(e, checked) => onChangeLocation(checked, item, locationitem, 'Oversize', locationitem['WarehouseLocationID'])} >Oversize</Checkbox>


//                                 </InputGroup>
//                             </Col>

//                             <Col md={1} sm={1}>

//                                 <IconButton size="md" icon={<CloseIcon />} onClick={(e) => onClickRemoveFromAdd(e, item, locationitem, locationitem['WarehouseLocationID'])} />

//                             </Col>

//                         </Row>
//                         {/* <Row>
//                         <Col md={8} sm={8}>
//                             <Button onClick={close} appearance="default">+  Add Location</Button>
//                         </Col>
//                     </Row> */}
//                     </div>


//                 ))}


//                 <Row>
//                     <Col md={8} sm={8}>
//                         <Button onClick={(e) => onClickAddLocation(e, item)} appearance="default">+  Add Location</Button>
//                     </Col>
//                 </Row>

//             </Panel>


//         </Col>
//     </Row>





// ))}