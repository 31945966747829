import React, {
  PureComponent
} from 'react';
import { Table, Checkbox, Button, InputPicker } from 'rsuite';
import { sortData } from '../../../../resources/logic/functions';
import { colorColumnsValues } from '../../../../app/appConfig';
import RTable_HeightFunction from './RTable_HeightFunction';
const { Column, HeaderCell, Cell } = Table;



const CheckCell = ({ rowData, onChange, checkedKeys, dataKey, ...props }) => (
  <Cell {...props} style={{ padding: 0 }}>
    {/* <div style={{ lineHeight: '46px' }}> */}
    <div style={{ marginTop: '-7px' }}>
      <Checkbox
        value={rowData[dataKey]}
        inline
        onChange={onChange}
        checked={checkedKeys.some(item => item === rowData[dataKey])}
      />
    </div>
  </Cell>
);

function RTableContainer(
  tableData,
  DataElements,
  sortColumn,
  sortType,
  loading,
  handleSortColumn,
  onClickDetails,
  checkColEntry,
  keyColumn,
  checkedKeys,
  handleCheckAll,
  handleCheck,
  boolSelectButton,
  onClickSelect,
  selectButtonText,
  onClickSelect2,
  selectButtonText2,
  isMobile,
  history,
  highlightRowKey,
  editLink,
  editLinkText,
  editLinkOnClick,
  onChangeColor,
  userColorArray
) {

  let rTable;



  if (typeof (tableData) !== 'undefined' && tableData != null) {

    if (sortColumn && sortType) {
      sortData(tableData, sortColumn, sortType)
    }

    let selectButtonCol;
    if (boolSelectButton) {
      try {
        selectButtonCol =
          <Column width={(isMobile ? 70 : 100)} fixed="left" >
            <HeaderCell></HeaderCell>
            <Cell>
              {rowData => {
                return (
                  <span>
                    {[1, 2, 3, 4].includes(rowData['CurrentContainerStatusID']) ? <Button appearance={rowData[keyColumn] == highlightRowKey ? "selected" : "primary"} size={isMobile ? 'xs' : 'sm'} style={{ marginTop: '-6px' }} onClick={(e) => onClickSelect(e, rowData, history)}>{selectButtonText}</Button>
                      : <Button appearance={rowData[keyColumn] == highlightRowKey ? "selected" : "primary"} size={isMobile ? 'xs' : 'sm'} style={{ marginTop: '-6px' }} onClick={(e) => onClickSelect2(e, rowData, history)}>{selectButtonText2}</Button>
                    }

                  </span>
                );
              }}
            </Cell>
          </Column>
      }
      catch (e) { }
    }
    else { }



    let colorColumns;
    if (tableData.length > 0) {
      if (tableData.some(item => item.hasOwnProperty('MawbColorCode'))) {
        colorColumns = <Column width={100} fixed="left" sortable={false}>
          <HeaderCell >
            {/* Color */}
          </HeaderCell>
          <Cell >
            {rowData => {
              return (

                <span>
                  <InputPicker
    preventOverflow
                    style={{ marginTop: '-6px', width: 80 }}
                    // placeholder={label}
                    placeholder={<div style={{ background: 'white' }}> &nbsp; </div>}
                    // value={userColorArray[] rowData['MawbColorCode']}
                    // value={findColor(userColorArray, rowData['MasterID'])}
                    value={userColorArray[rowData['MasterID']]}
                    // onChange={(e) => {onChange(e)}}
                    onChange={(e) => onChangeColor(e, rowData)}


                    data={colorColumnsValues}
                    // block
                    cleanable={false}
                    size="sm"
                  />
                </span>

              );
            }}
          </Cell>

        </Column >
      }
      else {
        colorColumns = ''
      }
    }
    else {
      colorColumns = ''
    }




    let checkColumn;
    if (checkColEntry) {
      try {
        let checked = false;
        let indeterminate = false;
        if (checkedKeys.length === tableData.length) {
          checked = true;
        } else if (checkedKeys.length === 0) {
          checked = false;
        } else if (checkedKeys.length > 0 && checkedKeys.length < tableData.length) {
          indeterminate = true;
        }

        checkColumn =
          <Column width={50} align="center" fixed="left">
            <HeaderCell style={{ padding: 0 }}>
              <div style={{ marginTop: '-9px' }}>
                <Checkbox
                  inline
                  checked={checked}
                  indeterminate={indeterminate}
                  onChange={(e, value) => handleCheckAll(value, checked, tableData)}
                />
              </div>
            </HeaderCell>
            <CheckCell
              dataKey={keyColumn}
              checkedKeys={checkedKeys}
              onChange={handleCheck}
            />
          </Column>
      }
      catch (e) { }

    }
    else { }



    let tableColumns;
    tableColumns = Object.keys(DataElements).map(function (key) {

      var primaryCol = false
      var sortableCol = false
      try {
        if (DataElements[key]["primaryCol"]) {
          primaryCol = true
        }
      }
      catch (e) { }
      try {
        if (DataElements[key]["sortable"]) {
          sortableCol = true
        }
      }
      catch (e) { }

      if (!DataElements[key]["mobileDisplay"] && isMobile) {

      }
      else {
        return (
          <Column width={DataElements[key]["colWidth"]} fixed={primaryCol} sortable={sortableCol}>
            <HeaderCell>{DataElements[key]["displayName"]}</HeaderCell>
            <Cell dataKey={DataElements[key]["dBKey"]} >
              {rowData => (rowData[DataElements[key]["dBKey"]] === true ? "True" : (rowData[DataElements[key]["dBKey"]] === false ? "False" : rowData[DataElements[key]["dBKey"]]))}
            </Cell>


          </Column>
        )
      }
    })

    var tableLength = tableData.length
    var tableHeight = RTable_HeightFunction(tableLength)


    rTable = <div>

      <Table
        wordWrap
        height={tableHeight}
        // autoHeight
        data={tableData}

        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        loading={loading}

        virtualized
      >

        {selectButtonCol}

        {colorColumns}

        {checkColumn}



        {tableColumns}

        <Column width={(isMobile ? 60 : 150)} fixed="right">
          <HeaderCell></HeaderCell>
          <Cell>
            {rowData => {
              return (
                <span>
                  <a style={{ cursor: 'pointer' }} onClick={(e) => onClickDetails(e, rowData, history)}> Details </a>
                  {editLink ? '|' : ''}
                  {editLink ? <a style={{ cursor: 'pointer' }} onClick={() => { editLinkOnClick(rowData, keyColumn); }}> {editLinkText} </a> : ''}
                </span>
              );
            }}
          </Cell>
        </Column>
      </Table>
    </div>

    try {
      if (tableData[0]['MSG']) {
        rTable = <div></div>
      }
    }
    catch (e) { }


  }
  else {
    rTable = <div></div>
  }


  // if (tableData.length == 0 ) {
  //   rTable = <div></div>
  // }


  return (

    rTable

  )
}


export default RTableContainer;
