import smallEvolveLogoIMG from '../resources/img/evolveLogoPng.png'; //evolve
import evolveLogoIMG from '../resources/img/evolveLogoPngWords.png'; //evolve

var prod = 'prod'
var dev = 'dev'
var demo = 'demo'

////////////////////////////////////////////////////////////////////////////////////////////////
////Set environment here////////////////////////////////////////////////////////////////////////

    // Update version in WebApp_User_App_Signin
    var _prodVersion = 36
    var _devVersion = 16


    var versions = {
        'EFW': 1,
        'Evolve': 1,
        'Customer1': 1
    };
    

    // var environment = dev 
    var environment = prod  


    var customer = 'EFW'
    // var customer = 'Evolve'
    // var customer = 'Customer1'




    //*********remove console.logs before deploy*****************
    /////////////////////////////////////////////////////////////


//Default Production settings
var _loggerDisabled = true  //deploy with set to true
// var _loggerDisabled = false  //*************************deploy with set to true use false to see console

var _devEnvironment = false
var _autoReloadIdleTime = 1000 * 60 * 5 //5 minutes
var _warningTimeLogout =  1000 * 60 * 59
var _signoutTimeLogout =  1000 * 60 * 60  //logout after 1 hour
var _fullSizeLogo = evolveLogoIMG
var _smallLogo = smallEvolveLogoIMG


//Use these if statements to change any variables different than the default settings

//Set development settings here
if(environment === dev){
    _loggerDisabled = false
    _devEnvironment = true
    _autoReloadIdleTime = 1000 * 60 * 30 //30 minutes 
    _warningTimeLogout =  1000 * 60 * 59 //20000 //20 seconds
    _signoutTimeLogout =  1000 * 60 * 60 //25000 //25 seconds
}


else if(environment === demo){


}





//Export settings here
export const appSettings = {
    prodVersion: _prodVersion,
    devVersion: _devVersion,

    version: versions[customer],

    wmsCustomer: customer,

    mobileWidth: 500,
    
    // Footer_CopyWrite: '2024 - All Rights Reserved - Evolve Supply Chain Solutions, LLC',
    Footer_CopyWrite: `${new Date().getFullYear()} - All Rights Reserved - Evolve Supply Chain Solutions, LLC`,

    devEnvironment: _devEnvironment,
    loggerDisabled: _loggerDisabled,
    // autoReloadIdleTime: _autoReloadIdleTime,
    warningTimeLogout:  _warningTimeLogout,
    signoutTimeLogout:  _signoutTimeLogout,

    logo: _fullSizeLogo,
    smallLogo: _smallLogo,


    colorPalatte: {
        //Primary
        EerieBlack: '#252524',
        TuftsBlue: '#3396E5',

        //Secondary
        CaribbeanGreen: '#16CAAF',
        Fulvous: '#E78200',
        FireOpal: '#EA664E',

        //Neutral
        GrayWeb: '#7E7E7E',
        Gainsboro: '#E0E0E0',
        White: '#FFFFFF',
        Black: '#000000',
        
    },

    

}

