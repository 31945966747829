import React from 'react';
import { Button } from 'rsuite';



function RButtonSmall(
    isMobile,
    label,
    onClick,
    item
    
  ) {

    let rButton;

    try{
        rButton =    
        <div >
            <Button appearance="primary" block onClick={(e) => onClick(e, item)} size={isMobile ? 'sm' : '' } style={ isMobile ? {marginBottom:'5px'} : {marginBottom:'5px'} } >{label}</Button>
        </div>
    }
    catch(e){}

    return (

        rButton

    )
    }


export default RButtonSmall;


