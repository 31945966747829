import { connect } from 'react-redux';
import React, { Component } from 'react';
import MyProfile from './MyProfile'
import * as actions from '../../../redux/actions/actionsUser';
import { reducer } from 'aws-cognito-redux-saga'
import { customerConfig } from '../../../app/appCustomerConfig';



//add props from reducer here
const mapStateToProps = store =>
({
    // isUserAccountsLoading:          store.userServicesReducer.isUserAccountsLoading,
    // userAccountData:                store.userServicesReducer.userAccountData,

    userSignInData: store.userServicesReducer.userSignInData,
    currentUserEmail: store.userServicesReducer.currentUserEmail,

    PostDeleteUserSuccessful: store.userServicesReducer.PostDeleteUserSuccessful,

    isUserDeleteCognitoPostLoading: store.userServicesReducer.isUserDeleteCognitoPostLoading,
    UserDeleteCognitoPostSuccessful: store.userServicesReducer.UserDeleteCognitoPostSuccessful,
});

//add actions here
const mapDispatchToProps = dispatch =>
({


    postDeleteUser: (_email, _authToken, _userIdToDelete) => {
        dispatch(actions.postDeleteUser(_email, _authToken, _userIdToDelete))
    },

    //change this to AWS delete user account
    //look at HeaderContainer
    // deleteUser: (username, password) => {
    //     dispatch(reducer.signUp(username, password))
    // },

    userAwsCognitoDeleteUserPost: (_usernameToDelete) => {
        dispatch(actions.userAwsCognitoDeleteUserPost(_usernameToDelete))
    },


    appSignOut: () => {
        dispatch(actions.appSignOut())
    },


    signOut: () => {
        dispatch(reducer.signOut())
    },


});

class MyProfileContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

        // this.props.getUserAccounts( this.props.currentUserEmail, this.props.userSignInData.AuthToken );

    }



    signOut = () => {
        // this.props.signOut()
        // this.props.appSignOut()
        this.props.history.push(`/${customerConfig.customerUrl}/signin`)
        window.location.reload();
      };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.PostDeleteUserSuccessful !== this.props.PostDeleteUserSuccessful && this.props.PostDeleteUserSuccessful) {
            this.signOut()
        }
        else {
        }
    }


    render() {
        return (
            <MyProfile
                // isUserAccountsLoading={ this.props.isUserAccountsLoading }
                // userAccountData={ this.props.userAccountData }
                isMobile={this.props.isMobile}
                postDeleteUser={this.props.postDeleteUser}
                deleteUser={this.props.deleteUser}
                userSignInData={this.props.userSignInData}
                currentUserEmail={this.props.currentUserEmail}


                userAwsCognitoDeleteUserPost={this.props.userAwsCognitoDeleteUserPost}
                isUserDeleteCognitoPostLoading={this.props.isUserDeleteCognitoPostLoading}
                UserDeleteCognitoPostSuccessful={this.props.UserDeleteCognitoPostSuccessful}


                appSignOut={this.props.appSignOut}
                signOut={this.props.signOut}
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProfileContainer);




