import pako from 'pako';
import { appSettings } from '../../app/appSettings';
import * as types from '../constants/actionsUserTypes';

const initialState = {
        isAppSigningInLoading: false,
        userSignInData: [],
        currentUserEmail: '',

        isUserInviteNewUserPostLoading: false,
        UserInviteNewUserPostSuccessful: false,

        isUserModuleLoading: false,
        // UserModuleData: []

        isUserOrganizationLoading: false,
        // UserOrganizationData: [],

        isUserWarehouseLoading: false,
        // UserWarehouseData: [],

        isUserCustomerLoading: false,
        // UserCustomerData: [],

        AdminCustomersAllData: [],
        isAdminCustomersAllLoading: false,

        PostDeleteUserSuccessful: false,
        isPostDeleteUserLoading: false,


        userCompanyData: [],
        isGetUserCompanyLoading: false,

        isGetPageConfigLoading: false,
        pageConfigData: [],

        // userSettingsLanguage: 'English'

        userSignUpLoading: false,
        userSignUpComplete: false,

        userAccountData: [],
        isUserAccountsLoading: false,

        PostApproveUserSuccessful: false,

        isAllUsersLoading: false,
        AllUsersData: [],

        userStoreData: [],
        isGetUserStoreLoading: false,

        userPOStatusData: [],
        isGetUserPOStatusLoading: false,

        UserAccountEditSuccessful: false,

        isWarehouseLocationsLoading: false,
        WarehouseLocationsData: [],

        PostUserWarehouseSuccessful: false,
        PostUserCustomerSuccessful: false,
        PostUserModuleSuccessful: false,


        isAccountDetailsUserWarehouseLoading: false,
        AccountDetailsUserWarehouseData: [],

        AccountDetailsUserModuleData: [],
        isAccountDetailsUserModuleLoading: false,

        AccountDetailsUserCustomerData: [],
        isAccountDetailsUserCustomerLoading: false,


        isUserCarrierLoading: false,
        UserCarrierData: [],

        PostUserCarrierSuccessful: false,

        isUserCarrierServiceLoading: false,
        UserCarrierServiceData: [],



        AdminNewSeaportPostSuccessful: false,

        AdminNewCustomerPostSuccessful: false,



        isUserDeleteCognitoPostLoading: false,
        UserDeleteCognitoPostSuccessful: false,


        isUserInviteCognitoPostLoading: false,
        UserInviteCognitoPostSuccessful: false,
        UserInviteCognitoResponseMSG: '',


};
//Compressed Data Helper Functions
function decodeAndDecompress(data) {
        try {
                var binaryString = atob(data);
                var len = binaryString.length;
                var bytes = new Uint8Array(len);
                for (var i = 0; i < len; i++) {
                        bytes[i] = binaryString.charCodeAt(i);
                }
                var decompressedData = pako.inflate(bytes, { to: 'string' });
                return JSON.parse(decompressedData);
        } catch (error) {
                console.error('Error decoding base64 data:', error);
                return [];
        }
}




function handlePostAction(state, action, loadingKey, successKey) {
        if (!checkStatusCode(action.payload)) {
                return actionOnError()
        }
        // add this code to convert to compressed api
        try {
                var compressedBody = JSON.parse(action.payload.body);
                var newData = decodeAndDecompress(compressedBody.compressed_data);
        }
        catch (e) {
                var newData = JSON.parse(action.payload.body);
        }
        var msgCode;
        try {
                msgCode = newData[0].MsgCode
        }
        catch (e) {
                // msgCode = 200
        }
        if (msgCode === 200) {
                return Object.assign({}, state, {
                        [successKey]: true,
                        [loadingKey]: false
                });
        }
        else {
                try {
                        window.alert(newData[0].MSG)
                }
                catch (e) {
                        window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                        [successKey]: false,
                        [loadingKey]: false
                });
        }
}

// function handlePostAction(state, action, loadingKey, successKey) {
//         if (!checkStatusCode(action.payload)) {
//                 return actionOnError()
//         }
//         // add this code to convert to compressed api
//         try {
//                 var compressedBody = JSON.parse(action.payload.body);
//                 var newData = decodeAndDecompress(compressedBody.compressed_data);
//         }
//         catch (e) {
//                 var newData = JSON.parse(action.payload.body);
//         }

//         var msgCode;
//         try {
//                 msgCode = newData[0].MsgCode
//         }
//         catch (e) {
//                 // msgCode = 200
//         }

//         if (msgCode === 200) {
//                 return Object.assign({}, state, {
//                         [successKey]: true,
//                         [loadingKey]: false
//                 });
//         }
//         else {
//                 try {
//                         window.alert(newData[0].MSG)
//                 }
//                 catch (e) {
//                         window.alert('Error, please try again.')
//                 }
//                 return Object.assign({}, state, {
//                         [successKey]: false,
//                         [loadingKey]: false
//                 });
//         }
// }



function checkStatusCode(payload) {
        var errorType = 'None'
        try {
                errorType = payload['errorType']
        }
        catch (e) {
        }
        if (typeof (errorType) !== 'undefined') {
                return false
        }
        else {
                var res = payload['statusCode']
                try {
                        if (res === "200" || res == 200 || res == 600 || res === "600") { return true }
                        else { return false }
                }
                catch (e) {
                        return false
                }
        }
}

function actionOnError(proc) {
        console.log('DB connection error')


        // if (appSettings.devEnvironment === true) {
        //         window.alert("Please reload and try again.")
        // } 
        // else {
        //         // window.alert("Please reload and try again.")

        // }
}


function saveArrayTosessionStorage(keyID, arrayToSave) {
        try {
                for (let key in arrayToSave) {
                        // save to sessionStorage
                        sessionStorage.setItem(`${keyID}.` + key, JSON.stringify(arrayToSave[key]));
                }
        }
        catch (e) {
                console.log('error', e)
        }
}

function saveArrayAsStringTosessionStorage(keyID, arrayToSave) {
        try {
                sessionStorage.setItem(keyID, JSON.stringify(arrayToSave));
        }
        catch (e) {
                console.log('error', e)
        }
}

function saveStringTosessionStorage(key, stringToSave) {
        try {
                sessionStorage.setItem(key, stringToSave)
        }
        catch (e) {
                console.log('error', e)
        }

}

export const ErrorReducer = (state = initialState, action) => {
        switch (action.type) {
                case types.APP_SIGNIN_ERROR:
                        actionOnError()
                        return state;

                case types.USER_SIGNUP_ERROR:
                        actionOnError()
                        return state;
                default:
                        return state;
        }
};

export const SignOutReducer = (state = initialState, action) => {
        switch (action.type) {
                case types.RESET_REDUX_STATE:
                        sessionStorage.clear();
                        state = initialState;
                        return state;

                default:
                        return state;
        }
};

export const UserServicesReducer = (state = initialState, action) => {
        switch (action.type) {
                case types.RESET_REDUX_STATE:
                        state = initialState;
                        return state



                case types.USER_INVITE_NEW_USER_POST:
                        return Object.assign({}, state, {
                                isUserInviteNewUserPostLoading: true,
                                UserInviteNewUserPostSuccessful: false

                        });
                case types.USER_INVITE_NEW_USER_POST_COMPLETE:
                        return handlePostAction(state, action, 'isUserInviteNewUserPostLoading', 'UserInviteNewUserPostSuccessful');



                //This does not send back a compressed response:
                case types.USER_AWS_COGNITO_DELETE_USER_POST_GET:
                        return Object.assign({}, state, {
                                isUserDeleteCognitoPostLoading: true,
                                UserDeleteCognitoPostSuccessful: false
                        });
                case types.USER_AWS_COGNITO_DELETE_USER_POST_COMPLETE:
                        // if (!checkStatusCode(action.payload)) {
                        //         return initialState
                        // }

                        var msgCode;

                        try {
                                // msgCode = newData.data[0].statusCode
                                msgCode = action.payload.statusCode
                        }
                        catch (e) {
                                msgCode = 200
                        }

                        if (msgCode == 200) {
                                // window.alert('Save Successful')
                                return Object.assign({}, state, {
                                        isUserDeleteCognitoPostLoading: false,
                                        UserDeleteCognitoPostSuccessful: true
                                });
                        }
                        else {

                                try {
                                        // window.alert(newData.data[0].body)
                                        // window.alert(action.payload.body)
                                        // window.alert('Error, please try again.')
                                }
                                catch (e) {
                                        // window.alert('Error, please try again.')
                                }
                                return Object.assign({}, state, {
                                        isUserDeleteCognitoPostLoading: false,
                                        UserDeleteCognitoPostSuccessful: false
                                });
                        }


                case types.USER_AWS_COGNITO_DELETE_USER_POST_ERROR:
                        return {
                                ...state,
                                isUserDeleteCognitoPostLoading: false,
                                UserDeleteCognitoPostSuccessful: false,
                        };









                //This does not send back a compressed response:
                case types.USER_AWS_COGNITO_INVITE_USER_POST_GET:
                        return Object.assign({}, state, {
                                isUserInviteCognitoPostLoading: true,
                                UserInviteCognitoPostSuccessful: false,
                                UserInviteCognitoResponseMSG: '',
                        });
                case types.USER_AWS_COGNITO_INVITE_USER_POST_COMPLETE:

                        var msgCode;
                        var msg;

                        // const responseBody = JSON.parse(response.data.body);
                        var msgJSON = JSON.parse(action.payload.body)
                        var msg = msgJSON['MSG']


                        try {
                                // msgCode = newData.data[0].statusCode
                                msgCode = action.payload.statusCode

                                // var msgJSON = JSON.parse(action.payload.body)
                                // var msg = errorMsgJSON['MSG']

                        }
                        catch (e) {
                                msgCode = 200
                        }

                        if (msgCode == 200) {
                                // window.alert('Save Successful')
                                return Object.assign({}, state, {
                                        isUserInviteCognitoPostLoading: false,
                                        UserInviteCognitoPostSuccessful: true,
                                        UserInviteCognitoResponseMSG: msg,
                                });
                        }
                        else {

                                try {
                                        // window.alert(newData.data[0].body)
                                        // window.alert(action.payload.body)
                                        // window.alert('Error, please try again.')
                                }
                                catch (e) {
                                        // window.alert('Error, please try again.')
                                }
                                return Object.assign({}, state, {
                                        isUserInviteCognitoPostLoading: false,
                                        UserInviteCognitoPostSuccessful: false,
                                        UserInviteCognitoResponseMSG: msg,
                                });
                        }


                case types.USER_AWS_COGNITO_INVITE_USER_POST_ERROR:

                        return {
                                ...state,
                                isUserInviteCognitoPostLoading: false,
                                UserInviteCognitoPostSuccessful: false,
                        };






                case types.POST_DELETE_USER_GET:
                        return Object.assign({}, state, {
                                PostDeleteUserSuccessful: false,
                                isPostDeleteUserLoading: true
                        });
                case types.POST_DELETE_USER_COMPLETE:
                        const newState = handlePostAction(state, action, 'isPostDeleteUserLoading', 'PostDeleteUserSuccessful');
                        return newState !== undefined ? newState : state;
                case types.POST_DELETE_USER_ERROR:
                        return {
                                ...state,
                                isPostDeleteUserLoading: false,
                                PostDeleteUserSuccessful: false,
                        };




                case types.USER_SIGNUP_POST:
                        return Object.assign({}, state, {
                                userSignUpLoading: true
                        });



                case types.USER_SIGNUP_COMPLETE:
                        if (!checkStatusCode(action.payload)) {
                                actionOnError()
                                return state;
                        }
                        var newData = JSON.parse(action.payload.body);
                        var msgCode;
                        try {
                                msgCode = newData.data[0].MsgCode
                        }
                        catch (e) {
                                msgCode = 200
                        }
                        if (msgCode === 200) {
                                // window.alert('Add Successful')
                                return Object.assign({}, state, {
                                        userSignUpLoading: false,
                                        userSignUpComplete: true
                                        // MawbData: newData
                                });
                        }
                        else {
                                try {
                                        window.alert(newData.data[0].MSG)
                                }
                                catch (e) {
                                        window.alert('Error, please try again.')
                                }
                                return Object.assign({}, state, {
                                        userSignUpComplete: false
                                        // MawbData: newData
                                });
                        }


                case types.APP_SIGNIN:
                        return Object.assign({}, state, {
                                isAppSigningInLoading: true
                        });
                case types.APP_SIGNIN_ERROR:
                        console.log('app sign in error')
                case types.APP_SIGNIN_COMPLETE:
                        if (!checkStatusCode(action.payload)) {
                                actionOnError(action.payload)
                                return state;
                        }
                        // var newSignIndata = JSON.parse(action.payload.body)
                        var compressedBody = JSON.parse(action.payload.body);
                        var newSignIndata = decodeAndDecompress(compressedBody.compressed_data);
                        try {

                                // if(newSignIndata[0].Active == true){
                                // }

                                if (newSignIndata[0].PendingApproval == false && newSignIndata[0].Active == false) {

                                }

                                else if (newSignIndata[0].PendingApproval == true) {
                                        window.alert("Your account is new and pending approval.")
                                        sessionStorage.clear();
                                        // window.location.reload();
                                        return initialState
                                }
                                else if (newSignIndata[0].Active != true) {
                                        window.alert("Your account is not active.")
                                        sessionStorage.clear();
                                        // window.location.reload();
                                        return initialState
                                }
                                else {

                                        if (newSignIndata[0].AppVersion !== appSettings.version) {
                                                window.alert("Application update available, please reload browser.")
                                                window.location.reload();
                                        }


                                        // if (appSettings.devEnvironment) {
                                        //         if (newSignIndata[0].AppVersionDev !== appSettings.devVersion) {
                                        //                 window.alert("Application update available, please reload browser.")
                                        //                 window.location.reload();
                                        //         }
                                        // }
                                        // else {
                                        //         if (newSignIndata[0].AppVersionProd !== appSettings.prodVersion) {
                                        //                 window.alert("Application update available, please reload browser.")
                                        //                 window.location.reload();
                                        //         }
                                        // }
                                }
                        }
                        catch (e) {
                                window.alert("Account error, contact your administrator.")
                                sessionStorage.clear();
                                // window.location.reload();
                                return initialState
                        }




                        // //log user out if they do not have access to use product as specified in app settings
                        // var parseNewProdData  = newSignIndata[0].ProductName
                        // var parsedProdData = JSON.parse(parseNewProdData)
                        // try{
                        //         if(parsedProdData.filter( item => item.ProductName === appSettings.product ).length === 0 ){
                        //                 window.alert("Your account is not active.")
                        //                 sessionStorage.clear();
                        //                 window.location.reload();  
                        //                 return initialState
                        //         }
                        // }
                        // catch(e)
                        // {
                        //         window.alert("Your account is not active.")
                        //         sessionStorage.clear();
                        //         window.location.reload();  
                        //         return initialState
                        // }


                        return Object.assign({}, state, {
                                userSignInData: newSignIndata[0],
                                currentUserEmail: newSignIndata[0].EmailAddr,
                                isAppSigningInLoading: false
                        });




                case types.GET_ALL_USERS_GET:
                        return Object.assign({}, state, {
                                isAllUsersLoading: true,
                        });
                case types.GET_ALL_USERS_COMPLETE:
                        if (!checkStatusCode(action.payload)) {
                                actionOnError()
                                return state;
                        }
                        // var newData = JSON.parse(action.payload.body);
                        var compressedBody = JSON.parse(action.payload.body);
                        var newData = decodeAndDecompress(compressedBody.compressed_data);
                        return Object.assign({}, state, {
                                isAllUsersLoading: false,
                                AllUsersData: newData
                        });


                case types.POST_APPROVE_USER_GET:
                        return Object.assign({}, state, {
                                PostApproveUserSuccessful: false
                        });
                case types.POST_APPROVE_USER_COMPLETE:
                        if (!checkStatusCode(action.payload)) {
                                actionOnError()
                                return state;
                        }
                        // var newData = JSON.parse(action.payload.body);
                        var compressedBody = JSON.parse(action.payload.body);
                        var newData = decodeAndDecompress(compressedBody.compressed_data);

                        if (newData[0].MsgCode === 200) {
                                // window.alert('Save Successful')
                                return Object.assign({}, state, {
                                        PostApproveUserSuccessful: true
                                });
                        }
                        else {
                                try {
                                        window.alert(newData[0].MSG)
                                }
                                catch (e) {
                                        window.alert('Error, please try again.')
                                }
                                return Object.assign({}, state, {
                                        PostApproveUserSuccessful: false
                                });
                        }





                // case types.POST_DELETE_USER_GET:
                //         return Object.assign({}, state, {
                //                 isAllUsersLoading: true,
                //         });
                // case types.POST_DELETE_USER_COMPLETE:
                //         if (!checkStatusCode(action.payload)) {
                //                 actionOnError()
                //                 return state;
                //         }
                //         var newData = JSON.parse(action.payload.body);
                //         return Object.assign({}, state, {
                //                 isAllUsersLoading: false,
                //                 AllUsersData: newData
                //         });



                case types.POST_USER_ACCOUNT_EDIT_GET:
                        return Object.assign({}, state, {
                                UserAccountEditSuccessful: false
                        });
                case types.POST_USER_ACCOUNT_EDIT_COMPLETE:
                        if (!checkStatusCode(action.payload)) {
                                actionOnError()
                                return state;
                        }
                        // var newData = JSON.parse(action.payload.body);
                        var compressedBody = JSON.parse(action.payload.body);
                        var newData = decodeAndDecompress(compressedBody.compressed_data);
                        if (newData[0].MsgCode === 200) {
                                return Object.assign({}, state, {
                                        UserAccountEditSuccessful: true
                                });
                        }
                        else {
                                try {
                                        window.alert(newData[0].MSG)
                                }
                                catch (e) {
                                        window.alert('Error, please try again.')
                                }
                                return Object.assign({}, state, {
                                        UserAccountEditSuccessful: false
                                });
                        }



                case types.ACCOUNT_DETAILS_USER_WAREHOUSES_GET:
                        return Object.assign({}, state, {
                                isAccountDetailsUserWarehouseLoading: true
                        });

                case types.ACCOUNT_DETAILS_USER_WAREHOUSES_COMPLETE:
                        if (!checkStatusCode(action.payload)) {
                                actionOnError()
                                return state;
                        }
                        // var newUserdata = JSON.parse(action.payload.body);
                        var compressedBody = JSON.parse(action.payload.body);
                        var newUserdata = decodeAndDecompress(compressedBody.compressed_data);
                        return Object.assign({}, state, {
                                AccountDetailsUserWarehouseData: newUserdata,
                                isAccountDetailsUserWarehouseLoading: false
                        });


                case types.ACCOUNT_DETAILS_USER_MODULES_GET:
                        return Object.assign({}, state, {
                                isAccountDetailsUserModuleLoading: true
                        });

                case types.ACCOUNT_DETAILS_USER_MODULES_COMPLETE:
                        if (!checkStatusCode(action.payload)) {
                                actionOnError()
                                return state;
                        }
                        // var newUserdata = JSON.parse(action.payload.body);
                        var compressedBody = JSON.parse(action.payload.body);
                        var newUserdata = decodeAndDecompress(compressedBody.compressed_data);
                        return Object.assign({}, state, {
                                AccountDetailsUserModuleData: newUserdata,
                                isAccountDetailsUserModuleLoading: false
                        });


                case types.ACCOUNT_DETAILS_USER_CUSTOMERS_GET:
                        return Object.assign({}, state, {
                                isAccountDetailsUserCustomerLoading: true
                        });

                case types.ACCOUNT_DETAILS_USER_CUSTOMERS_COMPLETE:
                        if (!checkStatusCode(action.payload)) {
                                actionOnError()
                                return state;
                        }
                        // var newUserdata = JSON.parse(action.payload.body);
                        var compressedBody = JSON.parse(action.payload.body);
                        var newUserdata = decodeAndDecompress(compressedBody.compressed_data);
                        return Object.assign({}, state, {
                                AccountDetailsUserCustomerData: newUserdata,
                                isAccountDetailsUserCustomerLoading: false
                        });

                case types.POST_USER_WAREHOUSES_GET:
                        return Object.assign({}, state, {
                                PostUserWarehouseSuccessful: false
                        });
                case types.POST_USER_WAREHOUSES_COMPLETE:
                        if (!checkStatusCode(action.payload)) {
                                actionOnError()
                                return state;
                        }
                        // var newData = JSON.parse(action.payload.body);
                        var compressedBody = JSON.parse(action.payload.body);
                        var newData = decodeAndDecompress(compressedBody.compressed_data);
                        if (newData[0].MsgCode === 200) {
                                // window.alert('Save Successful')
                                return Object.assign({}, state, {
                                        PostUserWarehouseSuccessful: true
                                });
                        }
                        else {
                                try {
                                        window.alert(newData[0].MSG)
                                }
                                catch (e) {
                                        window.alert('Error, please try again.')
                                }
                                return Object.assign({}, state, {
                                        PostUserWarehouseSuccessful: false
                                });
                        }

                case types.USER_ADMIN_CUSTOMERS_ALL_GET:
                        return Object.assign({}, state, {
                                isAdminCustomersAllLoading: true
                        });

                case types.USER_ADMIN_CUSTOMERS_ALL_COMPLETE:
                        if (!checkStatusCode(action.payload)) {
                                actionOnError()
                                return state;
                        }
                        // var newUserdata = JSON.parse(action.payload.body);
                        var compressedBody = JSON.parse(action.payload.body);
                        var newUserdata = decodeAndDecompress(compressedBody.compressed_data);
                        return Object.assign({}, state, {
                                AdminCustomersAllData: newUserdata,
                                isAdminCustomersAllLoading: false
                        });



                case types.POST_USER_CUSTOMERS_GET:
                        return Object.assign({}, state, {
                                PostUserCustomerSuccessful: false
                        });
                case types.POST_USER_CUSTOMERS_COMPLETE:
                        if (!checkStatusCode(action.payload)) {
                                actionOnError()
                                return state;
                        }
                        // var newData = JSON.parse(action.payload.body);
                        var compressedBody = JSON.parse(action.payload.body);
                        var newData = decodeAndDecompress(compressedBody.compressed_data);
                        if (newData[0].MsgCode === 200) {
                                // window.alert('Save Successful')
                                return Object.assign({}, state, {
                                        PostUserCustomerSuccessful: true
                                });
                        }
                        else {
                                try {
                                        window.alert(newData[0].MSG)
                                }
                                catch (e) {
                                        window.alert('Error, please try again.')
                                }
                                return Object.assign({}, state, {
                                        PostUserCustomerSuccessful: false
                                });
                        }



                case types.POST_USER_MODULES_GET:
                        return Object.assign({}, state, {
                                PostUserModuleSuccessful: false
                        });
                case types.POST_USER_MODULES_COMPLETE:
                        if (!checkStatusCode(action.payload)) {
                                actionOnError()
                                return state;
                        }
                        // var newData = JSON.parse(action.payload.body);
                        var compressedBody = JSON.parse(action.payload.body);
                        var newData = decodeAndDecompress(compressedBody.compressed_data);
                        if (newData[0].MsgCode === 200) {
                                // window.alert('Save Successful')
                                return Object.assign({}, state, {
                                        PostUserModuleSuccessful: true
                                });
                        }
                        else {
                                try {
                                        window.alert(newData[0].MSG)
                                }
                                catch (e) {
                                        window.alert('Error, please try again.')
                                }
                                return Object.assign({}, state, {
                                        PostUserModuleSuccessful: false
                                });
                        }


                case types.USER_ORGANIZATION_GET:
                        return Object.assign({}, state, {
                                isUserOrganizationLoading: true
                        });
                case types.USER_ORGANIZATION_COMPLETE:
                        if (!checkStatusCode(action.payload)) {
                                actionOnError()
                                return state;
                        }

                        // var newData = JSON.parse(action.payload.body);
                        var compressedBody = JSON.parse(action.payload.body);
                        var newData = decodeAndDecompress(compressedBody.compressed_data);
                        try {
                                var OrganizationData = newData

                                var newArrayOrganizationName = []

                                //this filters customers to be shown according to the app settings customer/product matrix
                                for (let key in OrganizationData) {
                                        newArrayOrganizationName.push({ label: OrganizationData[key]["label"], value: OrganizationData[key]["value"], id: OrganizationData[key]["OrganizationID"], Admin: OrganizationData[key]["Admin"], OrganizationName: OrganizationData[key]["OrganizationName"], ReadWrite: OrganizationData[key]["ReadWrite"], })
                                }
                                saveArrayAsStringTosessionStorage("AppConfig.OrganizationArray", newArrayOrganizationName)
                        }
                        catch (e) {
                                console.log('error', e)
                        }


                        var defaultOrganization = null
                        var defaultOrganizationName = null
                        try {
                                var OrganizationData = newData
                                if (OrganizationData.length == 1) {
                                        defaultOrganization = OrganizationData[0]['value']
                                        defaultOrganizationName = OrganizationData[0]['label']
                                        saveStringTosessionStorage("currentOrganization", defaultOrganization)
                                        saveStringTosessionStorage("currentOrganizationName", defaultOrganizationName)
                                }
                                else {
                                        defaultOrganization = null
                                        defaultOrganizationName = null
                                        saveStringTosessionStorage("currentOrganization", defaultOrganization)
                                        saveStringTosessionStorage("currentOrganizationName", defaultOrganizationName)
                                }
                        }
                        catch (e) {

                        }

                        return Object.assign({}, state, {
                                isUserOrganizationLoading: false,
                                UserOrganizationData: newData
                        });


                case types.USER_WAREHOUSES_GET:
                        return Object.assign({}, state, {
                                isUserWarehouseLoading: true
                        });
                case types.USER_WAREHOUSES_COMPLETE:
                        if (!checkStatusCode(action.payload)) {
                                actionOnError()
                                return state;
                        }

                        // var newData = JSON.parse(action.payload.body);
                        var compressedBody = JSON.parse(action.payload.body);
                        var newData = decodeAndDecompress(compressedBody.compressed_data);

                        try {
                                var WarehouseData = newData

                                var newArrayWarehouseName = []

                                //this filters customers to be shown according to the app settings customer/product matrix
                                for (let key in WarehouseData) {
                                        newArrayWarehouseName.push({ label: WarehouseData[key]["label"], value: WarehouseData[key]["value"], id: WarehouseData[key]["WarehouseCode"], Admin: WarehouseData[key]["Admin"], WarehouseName: WarehouseData[key]["WarehouseName"], WarehousePort: WarehouseData[key]["WarehousePort"], WarehouseCode: WarehouseData[key]["WarehouseCode"], ReadWrite: WarehouseData[key]["ReadWrite"], })
                                }
                                saveArrayAsStringTosessionStorage("AppConfig.WarehouseArray", newArrayWarehouseName)
                        }
                        catch (e) {
                                console.log('error', e)
                        }


                        var defaultWarehouse = null
                        try {
                                var WarehouseData = newData
                                if (WarehouseData.length == 1) {
                                        defaultWarehouse = WarehouseData[0]['value']
                                        saveStringTosessionStorage("currentWarehouse", defaultWarehouse)
                                }
                                else {
                                        defaultWarehouse = null
                                        saveStringTosessionStorage("currentWarehouse", defaultWarehouse)
                                }
                        }
                        catch (e) {

                        }

                        return Object.assign({}, state, {
                                isUserWarehouseLoading: false,
                                UserWarehouseData: newData
                        });



                case types.USER_WAREHOUSE_LOCATIONS_GET:
                        return Object.assign({}, state, {
                                isWarehouseLocationsLoading: true
                        });
                case types.USER_WAREHOUSE_LOCATIONS_COMPLETE:
                        if (!checkStatusCode(action.payload)) {
                                actionOnError()
                                return state;
                        }
                        var newData = JSON.parse(action.payload.body);
                        var compressedBody = JSON.parse(action.payload.body);
                        var newData = decodeAndDecompress(compressedBody.compressed_data);
                        return Object.assign({}, state, {
                                isWarehouseLocationsLoading: false,
                                WarehouseLocationsData: newData
                        });




                case types.USER_CUSTOMERS_GET:
                        return Object.assign({}, state, {
                                isUserCustomerLoading: true
                        });
                case types.USER_CUSTOMERS_COMPLETE:
                        if (!checkStatusCode(action.payload)) {
                                actionOnError()
                                return state;
                        }

                        // var newData = JSON.parse(action.payload.body);
                        var compressedBody = JSON.parse(action.payload.body);
                        var newData = decodeAndDecompress(compressedBody.compressed_data);
                        try {
                                var CustomerData = newData

                                var newArrayCustomerName = []

                                //this filters customers to be shown according to the app settings customer/product matrix
                                for (let key in CustomerData) {
                                        newArrayCustomerName.push({ label: CustomerData[key]["label"], value: CustomerData[key]["value"], id: CustomerData[key]["CustomerID"], Admin: CustomerData[key]["Admin"], CustomerName: CustomerData[key]["CustomerName"], CustomerCode: CustomerData[key]["CustomerCode"], ReadWrite: CustomerData[key]["ReadWrite"], })
                                }
                                saveArrayAsStringTosessionStorage("AppConfig.CustomerArray", newArrayCustomerName)
                        }
                        catch (e) {
                                console.log('USER_CUSTOMERS error', e)
                        }


                        var defaultCustomer = null
                        try {
                                var CustomerData = newData
                                if (CustomerData.length == 1) {
                                        defaultCustomer = CustomerData[0]['value']
                                        saveStringTosessionStorage("currentCustomer", defaultCustomer)
                                }
                                else {
                                        defaultCustomer = null
                                        saveStringTosessionStorage("currentCustomer", defaultCustomer)
                                }
                        }
                        catch (e) {

                        }

                        return Object.assign({}, state, {
                                isUserCustomerLoading: false,
                                UserCustomerData: newData
                        });





                case types.USER_LABELGEN_CARRIER_GET:
                        return Object.assign({}, state, {
                                isUserCarrierLoading: true
                        });
                case types.USER_LABELGEN_CARRIER_COMPLETE:
                        if (!checkStatusCode(action.payload)) {
                                actionOnError()
                                return state;
                        }

                        // var newData = JSON.parse(action.payload.body);
                        var compressedBody = JSON.parse(action.payload.body);
                        var newData = decodeAndDecompress(compressedBody.compressed_data);

                        try {
                                var CarrierData = newData

                                var newArrayCarrierName = []

                                //this filters customers to be shown according to the app settings customer/product matrix
                                for (let key in CarrierData) {
                                        newArrayCarrierName.push({ label: CarrierData[key]["label"], value: CarrierData[key]["value"], id: CarrierData[key]["CarrierID"], Admin: CarrierData[key]["Admin"], CarrierName: CarrierData[key]["CarrierName"], CarrierCode: CarrierData[key]["CarrierCode"], ReadWrite: CarrierData[key]["ReadWrite"], })
                                }
                                saveArrayAsStringTosessionStorage("AppConfig.CarrierArray", newArrayCarrierName)
                        }
                        catch (e) {
                                console.log('USER_CARRIER error', e)
                        }


                        var defaultCarrier = null
                        try {
                                var CarrierData = newData
                                if (CarrierData.length == 1) {
                                        defaultCarrier = CarrierData[0]['value']
                                        saveStringTosessionStorage("currentCarrier", defaultCarrier)
                                }
                                else {
                                        defaultCarrier = null
                                        saveStringTosessionStorage("currentCarrier", defaultCarrier)
                                }
                        }
                        catch (e) {

                        }

                        return Object.assign({}, state, {
                                isUserCarrierLoading: false,
                                UserCarrierData: newData
                        });




                case types.POST_USER_LABELGEN_CARRIER_GET:
                        return Object.assign({}, state, {
                                PostUserCarrierSuccessful: false
                        });
                case types.POST_USER_LABELGEN_CARRIER_COMPLETE:
                        if (!checkStatusCode(action.payload)) {
                                actionOnError()
                                return state;
                        }
                        // var newData = JSON.parse(action.payload.body);
                        var compressedBody = JSON.parse(action.payload.body);
                        var newData = decodeAndDecompress(compressedBody.compressed_data);
                        if (newData[0].MsgCode === 200) {
                                // window.alert('Save Successful')
                                return Object.assign({}, state, {
                                        PostUserCarrierSuccessful: true
                                });
                        }
                        else {
                                try {
                                        window.alert(newData[0].MSG)
                                }
                                catch (e) {
                                        window.alert('Error, please try again.')
                                }
                                return Object.assign({}, state, {
                                        PostUserCarrierSuccessful: false
                                });
                        }




                case types.USER_LABELGEN_CARRIER_SERVICE_GET:
                        return Object.assign({}, state, {
                                isUserCarrierServiceLoading: true
                        });
                case types.USER_LABELGEN_CARRIER_SERVICE_COMPLETE:
                        if (!checkStatusCode(action.payload)) {
                                actionOnError()
                                return state;
                        }

                        // var newData = JSON.parse(action.payload.body);
                        var compressedBody = JSON.parse(action.payload.body);
                        var newData = decodeAndDecompress(compressedBody.compressed_data);

                        try {
                                var CarrierServiceData = newData

                                var newArrayCarrierServiceName = []

                                //this filters customers to be shown according to the app settings customer/product matrix
                                for (let key in CarrierServiceData) {
                                        newArrayCarrierServiceName.push({ label: CarrierServiceData[key]["label"], value: CarrierServiceData[key]["value"], id: CarrierServiceData[key]["CarrierServiceID"], CarrierServiceName: CarrierServiceData[key]["CarrierServiceName"], CarrierID: CarrierServiceData[key]["CarrierID"], })
                                }
                                saveArrayAsStringTosessionStorage("AppConfig.CarrierServiceArray", newArrayCarrierServiceName)
                        }
                        catch (e) {
                                console.log('USER_CARRIER error', e)
                        }
                        var defaultCarrierService = null
                        try {
                                var CarrierServiceData = newData
                                if (CarrierServiceData.length == 1) {
                                        defaultCarrierService = CarrierServiceData[0]['value']
                                        saveStringTosessionStorage("currentCarrierService", defaultCarrierService)
                                }
                                else {
                                        defaultCarrierService = null
                                        saveStringTosessionStorage("currentCarrierService", defaultCarrierService)
                                }
                        }
                        catch (e) {

                        }

                        return Object.assign({}, state, {
                                isUserCarrierServiceLoading: false,
                                UserCarrierServiceData: newData
                        });



                case types.USER_MODULES_GET:
                        return Object.assign({}, state, {
                                isUserModuleLoading: true
                        });
                case types.USER_MODULES_COMPLETE:
                        if (!checkStatusCode(action.payload)) {
                                actionOnError()
                                return state;
                        }

                        // var newData = JSON.parse(action.payload.body);
                        var compressedBody = JSON.parse(action.payload.body);
                        var newData = decodeAndDecompress(compressedBody.compressed_data);
                        try {
                                var ModuleData = newData

                                var newArrayModuleName = []

                                //this filters customers to be shown according to the app settings customer/product matrix
                                for (let key in ModuleData) {
                                        newArrayModuleName.push({ label: ModuleData[key]["label"], value: ModuleData[key]["value"], id: ModuleData[key]["ModuleID"], Admin: ModuleData[key]["Admin"], ModuleName: ModuleData[key]["ModuleName"], ModuleCode: ModuleData[key]["ModuleCode"], ReadWrite: ModuleData[key]["ReadWrite"], })
                                }
                                saveArrayAsStringTosessionStorage("AppConfig.ModuleArray", newArrayModuleName)
                        }
                        catch (e) {
                                console.log('error', e)
                        }


                        var defaultModule = null
                        try {
                                var ModuleData = newData
                                if (ModuleData.length == 1) {
                                        defaultModule = ModuleData[0]['value']
                                        saveStringTosessionStorage("currentModule", defaultModule)
                                }
                                else {
                                        defaultModule = null
                                        saveStringTosessionStorage("currentModule", defaultModule)
                                }
                        }
                        catch (e) {

                        }

                        return Object.assign({}, state, {
                                isUserModuleLoading: false,
                                UserModuleData: newData
                        });




                case types.USER_COMPANY_GET:
                        return Object.assign({}, state, {
                                isGetUserCompanyLoading: true
                        });
                case types.USER_COMPANY_COMPLETE:
                        if (!checkStatusCode(action.payload)) {
                                actionOnError()
                                return state;
                        }

                        // var newUserCompanyData = JSON.parse(action.payload.body);
                        var compressedBody = JSON.parse(action.payload.body);
                        var newUserCompanyData = decodeAndDecompress(compressedBody.compressed_data);
                        var newUserCompany = newUserCompanyData

                        try {
                                var newArrayCompanyName = []

                                //this filters customers to be shown according to the app settings customer/product matrix
                                for (let key in newUserCompany) {
                                        // newArrayCompanyName.push({label: newUserCompany[key]["CompanyName"], value: newUserCompany[key]["CompanyName"].replace(/[^\w]|_/g, ""), id: newUserCompany[key]["CompanyID"], admin: newUserCompany[key]["Admin"] })
                                        newArrayCompanyName.push({ label: newUserCompany[key]["CompanyName"], value: newUserCompany[key]["CompanyName"].replace(/[^\w]|_/g, ""), id: newUserCompany[key]["CompanyID"], admin: newUserCompany[key]["Admin"] })
                                }
                                saveArrayAsStringTosessionStorage("PageConfig.CompanyName", newArrayCompanyName)
                        }
                        catch (e) {
                                console.log('error', e)
                        }


                        return Object.assign({}, state, {
                                userCompanyData: newUserCompany,
                                isGetUserCompanyLoading: false
                        });


                case types.USER_STORE_GET:
                        return Object.assign({}, state, {
                                isGetUserStoreLoading: true
                        });
                case types.USER_STORE_COMPLETE:
                        if (!checkStatusCode(action.payload)) {
                                actionOnError()
                                return state;
                        }

                        // var newUserStoreData = JSON.parse(action.payload.body);
                        var compressedBody = JSON.parse(action.payload.body);
                        var newUserStoreData = decodeAndDecompress(compressedBody.compressed_data);
                        var newUserStore = newUserStoreData

                        try {
                                var newArrayStoreName = []

                                //this filters customers to be shown according to the app settings customer/product matrix
                                for (let key in newUserStore) {
                                        // newArrayStoreName.push({label: newUserStore[key]["StoreName"], value: newUserStore[key]["StoreName"].replace(/[^\w]|_/g, ""), id: newUserStore[key]["StoreID"], admin: newUserStore[key]["Admin"] })
                                        newArrayStoreName.push({ label: newUserStore[key]["StoreName"], value: newUserStore[key]["StoreID"], id: newUserStore[key]["StoreID"], admin: newUserStore[key]["Admin"] })
                                }
                                saveArrayAsStringTosessionStorage("PageConfig.StoreName", newArrayStoreName)
                        }
                        catch (e) {
                                console.log('error', e)
                        }


                        return Object.assign({}, state, {
                                userStoreData: newUserStore,
                                isGetUserStoreLoading: false
                        });

                case types.USER_POSTATUS_GET:
                        return Object.assign({}, state, {
                                isGetUserPOStatusLoading: true
                        });
                case types.USER_POSTATUS_COMPLETE:
                        if (!checkStatusCode(action.payload)) {
                                actionOnError()
                                return state;
                        }

                        // var newUserPOStatusData = JSON.parse(action.payload.body);
                        var compressedBody = JSON.parse(action.payload.body);
                        var newUserPOStatusData = decodeAndDecompress(compressedBody.compressed_data);
                        var newUserPOStatus = newUserPOStatusData

                        try {
                                var newArrayPOStatusName = []

                                //this filters customers to be shown according to the app settings customer/product matrix
                                for (let key in newUserPOStatus) {
                                        // newArrayPOStatusName.push({label: newUserPOStatus[key]["POStatusName"], value: newUserPOStatus[key]["POStatusName"].replace(/[^\w]|_/g, ""), id: newUserPOStatus[key]["POStatusID"], admin: newUserPOStatus[key]["Admin"] })
                                        newArrayPOStatusName.push({ label: newUserPOStatus[key]["POStatusName"], value: newUserPOStatus[key]["POStatusID"], id: newUserPOStatus[key]["POStatusID"] })
                                }
                                saveArrayAsStringTosessionStorage("PageConfig.POStatus", newArrayPOStatusName)
                        }
                        catch (e) {
                                console.log('error', e)
                        }

                        return Object.assign({}, state, {
                                userPOStatusData: newUserPOStatus,
                                isGetUserPOStatusLoading: false
                        });
                // case types.USER_ROLE_GET:
                //         return Object.assign({}, state, {
                //                 isGetUserRoleLoading: true
                //         });
                // case types.USER_ROLE_COMPLETE:   
                //         if(!checkStatusCode(action.payload)) {
                //                 actionOnError()
                //                 return state;
                //         }                

                //         var newUserRoleData = JSON.parse(action.payload.body);
                //         var newUserRole = newUserRoleData

                //         try{
                //                 var newArrayRoleName = []

                //                 //this filters customers to be shown according to the app settings customer/product matrix
                //                 for (let key in newUserRole) {    


                //                         newArrayRoleName.push({label: newUserRole[key]["RoleName"], value: newUserRole[key]["RoleName"].replace(/[^\w]|_/g, ""), id: newUserRole[key]["RoleID"], admin: newUserRole[key]["Admin"] })

                //                         // if(  appSettings.customerProductMatrix.filter(x => x.customer.includes(CustomerData[key]["RoleName"]) && x.product.includes(appSettings.product)).length > 0 ){
                //                         //         // newArrayCustName.push({label: CustomerData[key]["CustName"], value: CustomerData[key]["CustName"], id: CustomerData[key]["CustCode"], admin: CustomerData[key]["Admin"] })
                //                         //         newArrayRoleName.push({label: newUserRole[key]["RoleName"], value: newUserRole[key]["RoleName"].replace(/[^\w]|_/g, ""), id: newUserRole[key]["RoleID"], admin: newUserRole[key]["Admin"] })
                //                         // }



                //                 }
                //                 saveArrayAsStringTosessionStorage("PageConfig.RoleName", newArrayRoleName)
                //                 }
                //         catch(e){
                //                 
                //         }


                //         return Object.assign({}, state, {
                //                 userRoleData: newUserRole,
                //                 isGetUserRoleLoading: false
                // });   



                case types.GET_PAGE_CONFIG:
                        return Object.assign({}, state, {
                                isGetPageConfigLoading: true
                        });
                case types.COMPLETE_PAGE_CONFIG:
                        if (!checkStatusCode(action.payload)) {
                                actionOnError()
                                return state;
                        }

                        // var newPageConfigData = JSON.parse(action.payload.body);
                        var compressedBody = JSON.parse(action.payload.body);
                        var newPageConfigData = decodeAndDecompress(compressedBody.compressed_data);
                        var parseNewPageConfigData = newPageConfigData[0].PageConfig
                        var parseNewUserIDConfigData = JSON.parse(parseNewPageConfigData)
                        var configDataToSave = parseNewUserIDConfigData.UserID[0]


                        // /////////////save ProductName
                        // try{
                        //         var prodData = configDataToSave["ProductName"]
                        //         var newArrayProdName = []
                        //         for (let key in prodData) {
                        //                 newArrayProdName.push({label: prodData[key]["ProductName"], value: prodData[key]["ProductName"] , id: prodData[key]["ProductID"]})
                        //         }
                        //         saveArrayAsStringTosessionStorage("PageConfig.ProductName", newArrayProdName)
                        //         }
                        // catch(e){
                        // 
                        // }


                        /////////////save LanguageName
                        try {
                                saveStringTosessionStorage("PageConfig.UserSettings.Language", "English")
                                var userSettingData = configDataToSave["UserSettings"]
                                for (let key in userSettingData) {
                                        saveStringTosessionStorage("PageConfig.UserSettings.Language", userSettingData[key]["LanguageName"])
                                }

                        }
                        catch (e) {
                                saveStringTosessionStorage("PageConfig.UserSettings.Language", "English")
                        }




                        /////////////save CustName
                        try {
                                var CustomerData = configDataToSave["CustomerName"]
                                var newArrayCustName = []

                                //this filters customers to be shown according to the app settings customer/product matrix
                                for (let key in CustomerData) {
                                        // if(  appSettings.customerProductMatrix.filter(x => x.customer.includes(CustomerData[key]["CustName"]) && x.product.includes(appSettings.product)).length > 0 ){                      
                                        if (appSettings.customerProductMatrix.filter(x => x.customer.includes(CustomerData[key]["CustCode"]) && x.product.includes(appSettings.product)).length > 0) {
                                                // newArrayCustName.push({label: CustomerData[key]["CustName"], value: CustomerData[key]["CustName"], id: CustomerData[key]["CustCode"], admin: CustomerData[key]["Admin"] })
                                                newArrayCustName.push({ label: CustomerData[key]["CustCode"], value: CustomerData[key]["CustCode"], id: CustomerData[key]["CustCode"], admin: CustomerData[key]["Admin"] })
                                        }
                                }
                                saveArrayAsStringTosessionStorage("PageConfig.CustomerName", newArrayCustName)
                        }
                        catch (e) {
                                console.log('error', e)
                        }

                        return Object.assign({}, state, {
                                pageConfigData: configDataToSave,
                                isGetPageConfigLoading: false
                        });

                case types.USER_UPDATE_POST:
                        return Object.assign({}, state, {
                                isPendingUserRequestsLoading: true
                        });

                case types.USER_ACCOUNT_COMPLETE:
                        if (!checkStatusCode(action.payload)) {
                                actionOnError()
                                return state;
                        }
                        // var newUserdata = JSON.parse(action.payload.body);
                        var compressedBody = JSON.parse(action.payload.body);
                        var newUserdata = decodeAndDecompress(compressedBody.compressed_data);
                        return Object.assign({}, state, {
                                userAccountData: newUserdata,
                                isUserAccountsLoading: false
                        });











                case types.ADMIN_POST_NEW_SEAPORT_GET:
                        return Object.assign({}, state, {
                                AdminNewSeaportPostSuccessful: false,
                        });
                case types.ADMIN_POST_NEW_SEAPORT_COMPLETE:
                        if (!checkStatusCode(action.payload)) {
                                actionOnError()
                                return state;
                        }
                        // var newData = JSON.parse(action.payload.body);
                        var compressedBody = JSON.parse(action.payload.body);
                        var newData = decodeAndDecompress(compressedBody.compressed_data);
                        var msgCode;
                        try {
                                msgCode = newData[0].MsgCode
                        }
                        catch (e) {
                                msgCode = 200
                        }
                        if (msgCode === 200) {
                                // window.alert('Save Successful')
                                return Object.assign({}, state, {
                                        AdminNewSeaportPostSuccessful: true,
                                });
                        }
                        else {
                                try {
                                        window.alert(newData[0].MSG)
                                }
                                catch (e) {
                                        window.alert('Error, please try again.')
                                }
                                return Object.assign({}, state, {
                                        AdminNewSeaportPostSuccessful: false,
                                });
                        }


                case types.ADMIN_POST_NEW_CUSTOMER_GET:
                        return Object.assign({}, state, {
                                AdminNewCustomerPostSuccessful: false,
                        });
                case types.ADMIN_POST_NEW_CUSTOMER_COMPLETE:
                        if (!checkStatusCode(action.payload)) {
                                actionOnError()
                                return state;
                        }
                        // var newData = JSON.parse(action.payload.body);
                        var compressedBody = JSON.parse(action.payload.body);
                        var newData = decodeAndDecompress(compressedBody.compressed_data);
                        var msgCode;
                        try {
                                msgCode = newData[0].MsgCode
                        }
                        catch (e) {
                                msgCode = 200
                        }
                        if (msgCode === 200) {
                                // window.alert('Save Successful')
                                return Object.assign({}, state, {
                                        AdminNewCustomerPostSuccessful: true,
                                });
                        }
                        else {
                                try {
                                        window.alert(newData[0].MSG)
                                }
                                catch (e) {
                                        window.alert('Error, please try again.')
                                }
                                return Object.assign({}, state, {
                                        AdminNewCustomerPostSuccessful: false,
                                });
                        }








                // case types.USER_DELETE_COMPLETE:
                //         return Object.assign({}, state, {
                //                 isUserAccountsLoading: true
                //         });


                default:
                        return state;
        }
};





