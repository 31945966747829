import * as types from '../constants/labelGenActionTypes';
import { appSettings } from '../../app/appSettings'

const initialState = {


    LabelGenApiRequestSuccessful: false,
    isLabelGenApiRequestLoading: false,
    LabelGenApiRequestErrorCode: false,
    LabelGenApiRequestErrorMessage: null,
    LabelGenApiRequestJson: [],


    isBulkShipRequestLoading: false,
    BulkLabelGenApiRequestJson: [],
    BulkShipRequestResponseCounter: 0,
    isLabelCreateHistoryLoading: false,
    LabelCreateHistoryData: [],

    isLabelGenRequestRefLoading: false,
    LabelGenRequestRefData: [],

    isLabelGenRequestRefInWorkLoading: false,
    LabelGenRequestRefInWorkData: [],

    isLabelGenRequestPackageLoading: false,
    LabelGenRequestPackageData: [],

    LabelGenRequestPackagePostSuccessful: false,
    RequestPackageID: '',

    isLabelGenRequestPackageProductInfoLoading: false,
    LabelGenRequestPackageProductInfoData: [],

    LabelGenRequestPackageProductInfoPostSuccessful: false,
    RequestProductInfoID: '',


    LabelGenRequestPostSuccessful: false,
    LabelRequestID: null,

    isLabelGenRequestCustomerWarehouseDefaultValuesLoading: false,
    LabelGenRequestCustomerWarehouseDefaultValuesData: [],

    isLabelGenRequestCustomerWarehouseUserSavedValuesLoading: false,
    LabelGenRequestCustomerWarehouseUserSavedValuesData: [],

    LabelGenRequestCustomerWarehouseUserSavedValuesPostSuccessful: false,
    UserSavedValuesID: '',

    isLabelGenPortRefLoading: false,
    LabelGenPortRefData: [],

    isLabelGenResponsePackageLabelsLoading: false,
    LabelGenResponsePackageLabelsData: [],


    isLabelGenExternalUpsTrackingLoading: false,
    LabelGenExternalUpsTrackingData: [],

};

function checkStatusCode(payload) {
    var errorType = 'None'
    try {
        errorType = payload['errorType']
    }
    catch (e) {
    }
    if (typeof (errorType) !== 'undefined') {
        return false
    }
    else {
        var res = payload['statusCode']
        try {
            if (res === "200" || res == 200 || res == 600 || res === "600") { return true }
            else { return false }
        }
        catch (e) {
            return false
        }
    }
}

function actionOnError(proc) {
    // console.log('DB connection error')
    // sessionStorage.clear();
    // window.location.reload();
    // // window.alert('Session expired, please log in again.')

    return initialState


}

function checkErrorMessage(msg) {

    console.log('msg', msg)
    // if(res === "200"){ return true }
    // else { return false }
}

function handleGet(state, action, isLoading, loadingPropName, dataPropName, data = null) {
    return Object.assign({}, state, {
        [loadingPropName]: isLoading,
        [dataPropName]: data
    });
}


export const LabelGenReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.RESET_REDUX_STATE:
            state = initialState;
            return state


        case types.ON_ERROR:
            var errorstate = actionOnError(state)
            return errorstate;









        /* ################################### LABEL GEN ACTIONS ################################### */



        case types.LABELGEN_API_REQUEST_MAIN_GET:
            return Object.assign({}, state, {
                LabelGenApiRequestSuccessful: false,
                isLabelGenApiRequestLoading: true,
                LabelGenApiRequestErrorCode: false,
                LabelGenApiRequestErrorMessage: null,
                LabelGenApiRequestJson: []
            });
        case types.LABELGEN_API_REQUEST_MAIN_ERROR:
            try {
                var jsonBody = JSON.parse(action.payload.body);
            }
            catch (e) {
                jsonBody = {}
            }
            try {
                var errorCode = jsonBody.data.errorCode;
                var errorMessage = jsonBody.data.errorMessage;
            }
            catch (e) {
                var errorCode = null;
                var errorMessage = 'Request error, please try again.';
            }

            try {
                window.alert('Error: ' + errorMessage)
            }
            catch (e) {
                window.alert('Error creating label.')
            }
            // actionOnError()
            return Object.assign({}, state, {
                LabelGenApiRequestSuccessful: false,
                isLabelGenApiRequestLoading: false,
                LabelGenApiRequestErrorCode: true,
                LabelGenApiRequestErrorMessage: errorMessage,
                LabelGenApiRequestJson: newLabelCreationError
            });
        case types.LABELGEN_API_REQUEST_MAIN_COMPLETE:
            try {
                var jsonBody = JSON.parse(action.payload.body);
            }
            catch (e) {
                jsonBody = {}
            }
            try {
                var statusCodeToCheck = action.payload.statusCode
            }
            catch (e) {
                var statusCodeToCheck = jsonBody.data.errorMessage
            }

            if (!checkStatusCode(statusCodeToCheck)) { //if error
                try {
                    var errorCode = jsonBody.data.errorCode;
                    var errorMessage = jsonBody.data.errorMessage;
                }
                catch (e) {
                    try {
                        var newLabelCreationError = JSON.parse(action.payload.body);
                        var errorMessage = newLabelCreationError.data
                        var errorCode = null;
                    }
                    catch (e) {

                        var errorCode = null;
                        var errorMessage = 'Error creating label';
                    }
                }

                if (typeof (action.payload.errorMessage) !== 'undefined') {
                    var newLabelCreationError = JSON.parse(action.payload.errorMessage);
                    // actionOnError()
                    try {
                        window.alert('Error: ' + errorMessage)
                    }
                    catch (e) {
                        window.alert('Error creating label.')
                    }
                    return Object.assign({}, state, {
                        LabelGenApiRequestSuccessful: false,
                        isLabelGenApiRequestLoading: false,
                        LabelGenApiRequestErrorCode: true,
                        LabelGenApiRequestErrorMessage: errorMessage,
                        LabelGenApiRequestJson: newLabelCreationError
                    });
                }
                else if (typeof (errorMessage) === 'undefined') {
                    try {
                        var newLabelCreationError = JSON.parse(action.payload.body);
                        errorMessage = newLabelCreationError.data
                    }
                    catch (e) {
                        var errorMessage = 'Error creating label';
                    }

                    try {
                        window.alert('Error: ' + errorMessage)
                    }
                    catch (e) {
                        window.alert('Error creating label.')
                    }
                    // actionOnError()
                    return Object.assign({}, state, {
                        LabelGenApiRequestSuccessful: false,
                        isLabelGenApiRequestLoading: false,
                        LabelGenApiRequestErrorCode: true,
                        LabelGenApiRequestErrorMessage: errorMessage,
                        LabelGenApiRequestJson: newLabelCreationError
                    });
                }

                else {
                    var newLabelCreationError = JSON.parse(action.payload.body);
                    // actionOnError()
                    try {
                        window.alert('Error: ' + errorMessage)
                    }
                    catch (e) {
                        window.alert('Error creating label.')
                    }
                    return Object.assign({}, state, {
                        LabelGenApiRequestSuccessful: false,
                        isLabelGenApiRequestLoading: false,
                        LabelGenApiRequestErrorCode: true,
                        LabelGenApiRequestErrorMessage: errorMessage,
                        LabelGenApiRequestJson: newLabelCreationError
                    });
                }





            }
            else { // if no error
                var newLabelCreationData = jsonBody.data.newLabelDict;
                var newLabelFilename = jsonBody.data.labelUploadedFilename;
                return Object.assign({}, state, {
                    LabelGenApiRequestSuccessful: true,
                    isLabelGenApiRequestLoading: false,
                    LabelGenApiRequestErrorCode: false,
                    LabelGenApiRequestErrorMessage: null,
                    LabelGenApiRequestJson: newLabelCreationData
                });
            }










        case types.LABELGEN_REQUEST_GET:
            return Object.assign({}, state, {
                isLabelGenRequestRefLoading: true,
            });
        case types.LABELGEN_REQUEST_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isLabelGenRequestRefLoading: false,
                LabelGenRequestRefData: newData.data
            });


        case types.LABELGEN_RESPONSE_PACKAGE_LABELS_GET:
            return Object.assign({}, state, {
                isLabelGenResponsePackageLabelsLoading: true,
            });
        case types.LABELGEN_RESPONSE_PACKAGE_LABELS_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isLabelGenResponsePackageLabelsLoading: false,
                LabelGenResponsePackageLabelsData: newData.data
            });


        case types.LABELGEN_REQUEST_INWORK_GET:
            return Object.assign({}, state, {
                isLabelGenRequestRefInWorkLoading: true
            });
        case types.LABELGEN_REQUEST_INWORK_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);

            try {
                var newLabelID = newData.data[0].LabelRequestID

            }
            catch (e) {
                var newLabelID = null
            }
            return Object.assign({}, state, {
                LabelRequestID: newLabelID,
                isLabelGenRequestRefInWorkLoading: false,
                LabelGenRequestRefInWorkData: newData.data
            });




        case types.LABELGEN_POST_REQUEST_GET:
            return Object.assign({}, state, {
                LabelGenRequestPostSuccessful: false,
                LabelRequestID: null
            });
        case types.LABELGEN_POST_REQUEST_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            var newLabelRequestID;
            try {
                msgCode = newData.data[0].MsgCode
                newLabelRequestID = newData.data[0].LabelRequestID
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    LabelGenRequestPostSuccessful: true,
                    LabelRequestID: newLabelRequestID
                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    LabelGenRequestPostSuccessful: false,
                    LabelRequestID: null
                });
            }





        case types.LABELGEN_REQUEST_PACKAGE_GET:
            return Object.assign({}, state, {
                isLabelGenRequestPackageLoading: true
            });
        case types.LABELGEN_REQUEST_PACKAGE_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isLabelGenRequestPackageLoading: false,
                LabelGenRequestPackageData: newData.data
            });




        case types.LABELGEN_POST_REQUEST_PACKAGE_GET:
            return Object.assign({}, state, {
                LabelGenRequestPackagePostSuccessful: false,
                RequestPackageID: ''
            });
        case types.LABELGEN_POST_REQUEST_PACKAGE_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            var newRequestPackageID;
            try {
                msgCode = newData.data[0].MsgCode
                newRequestPackageID = newData.data[0].RequestPackageID
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    LabelGenRequestPackagePostSuccessful: true,
                    RequestPackageID: newRequestPackageID

                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    LabelGenRequestPackagePostSuccessful: false,
                    RequestPackageID: ''
                });
            }

        case types.LABELGEN_REQUEST_PACKAGE_PRODUCTINFO_GET:
            return Object.assign({}, state, {
                isLabelGenRequestPackageProductInfoLoading: true
            });
        case types.LABELGEN_REQUEST_PACKAGE_PRODUCTINFO_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isLabelGenRequestPackageProductInfoLoading: false,
                LabelGenRequestPackageProductInfoData: newData.data
            });


        case types.LABELGEN_POST_REQUEST_PACKAGE_PRODUCTINFO_GET:
            return Object.assign({}, state, {
                LabelGenRequestPackageProductInfoPostSuccessful: false,
                RequestProductInfoID: ''
            });
        case types.LABELGEN_POST_REQUEST_PACKAGE_PRODUCTINFO_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            var newRequestProductInfoID;
            try {
                msgCode = newData.data[0].MsgCode
                newRequestProductInfoID = newData.data[0].RequestProductInfoID
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    LabelGenRequestPackageProductInfoPostSuccessful: true,
                    RequestProductInfoID: newRequestProductInfoID

                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    LabelGenRequestPackageProductInfoPostSuccessful: false,
                    RequestProductInfoID: ''
                });
            }


        case types.LABELGEN_CUSTOMERWAREHOUSE_DEFAULTVALUES_GET:
            return Object.assign({}, state, {
                isLabelGenRequestCustomerWarehouseDefaultValuesLoading: true
            });
        case types.LABELGEN_CUSTOMERWAREHOUSE_DEFAULTVALUES_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isLabelGenRequestCustomerWarehouseDefaultValuesLoading: false,
                LabelGenRequestCustomerWarehouseDefaultValuesData: newData.data
            });

        case types.LABELGEN_PORT_REF_GET:
            return Object.assign({}, state, {
                isLabelGenPortRefLoading: true
            });
        case types.LABELGEN_PORT_REF_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isLabelGenPortRefLoading: false,
                LabelGenPortRefData: newData.data
            });


        case types.LABELGEN_CUSTOMERWAREHOUSE_USERSAVEDVALUES_GET:
            return Object.assign({}, state, {
                isLabelGenRequestCustomerWarehouseUserSavedValuesLoading: true
            });
        case types.LABELGEN_CUSTOMERWAREHOUSE_USERSAVEDVALUES_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isLabelGenRequestCustomerWarehouseUserSavedValuesLoading: false,
                LabelGenRequestCustomerWarehouseUserSavedValuesData: newData.data
            });


        case types.LABELGEN_POST_CUSTOMERWAREHOUSE_USERSAVEDVALUES_GET:
            return Object.assign({}, state, {
                LabelGenRequestCustomerWarehouseUserSavedValuesPostSuccessful: false,
                UserSavedValuesID: ''
            });
        case types.LABELGEN_POST_CUSTOMERWAREHOUSE_USERSAVEDVALUES_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            var newUserSavedValuesID;
            try {
                msgCode = newData.data[0].MsgCode
                newUserSavedValuesID = newData.data[0].UserSavedValuesID
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    LabelGenRequestCustomerWarehouseUserSavedValuesPostSuccessful: true,
                    UserSavedValuesID: newUserSavedValuesID

                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    LabelGenRequestCustomerWarehouseUserSavedValuesPostSuccessful: false,
                    UserSavedValuesID: ''
                });
            }















        // case types.LABELGEN_POST_UPS_LABEL_CREATE_GET:
        //     return Object.assign({}, state, {
        //         isLabelGenUpsLabelCreateLoading: true,
        //         LabelGenUpsLabelCreateErrorCode: false,
        //         LabelGenUpsLabelCreateText: ''
        //     });
        // case types.LABELGEN_POST_UPS_LABEL_CREATE_COMPLETE:
        //     if (!checkStatusCode(action.payload)) {
        //         if (typeof (action.payload.errorMessage) !== 'undefined') {

        //             var newLabelCreationError = action.payload.errorMessage;
        //             // actionOnError()
        //             return Object.assign({}, state, {
        //                 isLabelGenUpsLabelCreateLoading: false,
        //                 LabelGenUpsLabelCreateErrorCode: true,
        //                 LabelGenUpsLabelCreateText: newLabelCreationError
        //             });
        //         }
        //         else {
        //             var newLabelCreationError = action.payload.body;
        //             // actionOnError()
        //             return Object.assign({}, state, {
        //                 isLabelGenUpsLabelCreateLoading: false,
        //                 LabelGenUpsLabelCreateErrorCode: true,
        //                 LabelGenUpsLabelCreateText: newLabelCreationError
        //             });
        //         }
        //     }
        //     else {

        //         var newLabelCreationData = action.payload.body;
        //         return Object.assign({}, state, {
        //             isLabelGenUpsLabelCreateLoading: false,
        //             LabelGenUpsLabelCreateErrorCode: false,
        //             LabelGenUpsLabelCreateText: newLabelCreationData
        //         });
        //     }





        // case types.CTRLTWR_GENERATE_PRO_GET:
        //     return Object.assign({}, state, {
        //         isCtrlTwrGenerateProLoading: true
        //     });
        // case types.CTRLTWR_GENERATE_PRO_COMPLETE:
        //     if (!checkStatusCode(action.payload)) {
        //         actionOnError()
        //         return state;
        //     }
        //     var newData = JSON.parse(action.payload.body);
        //     return Object.assign({}, state, {
        //         isCtrlTwrGenerateProLoading: false,
        //         CtrlTwrGenerateProData: newData.data
        //     });


        // case types.LABELGEN_POST_UPS_LABEL_CREATE_GET:
        //     return Object.assign({}, state, {
        //         LabelGenLabelCreatePostSuccessful: false
        //     });
        // case types.LABELGEN_POST_UPS_LABEL_CREATE_COMPLETE:
        //     if (!checkStatusCode(action.payload)) {
        //         actionOnError()
        //         return state;
        //     }
        //     var newData = JSON.parse(action.payload.body);
        //     var msgCode;
        //     try {
        //         msgCode = newData.data[0].MsgCode
        //     }
        //     catch (e) {
        //         msgCode = 200
        //     }
        //     if (msgCode === 200) {
        //         // window.alert('Save Successful')
        //         return Object.assign({}, state, {
        //             LabelGenLabelCreatePostSuccessful: true

        //         });
        //     }
        //     else {
        //         try {
        //             window.alert(newData.data[0].MSG)
        //         }
        //         catch (e) {
        //             window.alert('Error, please try again.')
        //         }
        //         return Object.assign({}, state, {
        //             LabelGenLabelCreatePostSuccessful: false
        //         });
        //     }













        case types.SHIP_REQUEST_GET:
            return Object.assign({}, state, {
                isLabelGenApiRequestLoading: true,
                LabelGenApiRequestErrorCode: false,
                LabelGenApiRequestJson: []
            });
        case types.SHIP_REQUEST_ERROR:
            try {
                var errorResponse = JSON.parse(action.payload.request.response)
                var errorResponseBody = errorResponse.body
            }
            catch (e) {
                var errorResponseBody = { "statusCode": 400, "message": "Error", "RequestID": "" }
            }
            return Object.assign({}, state, {
                isLabelGenApiRequestLoading: false,
                LabelGenApiRequestErrorCode: true,
                LabelGenApiRequestJson: errorResponseBody
            });
        case types.SHIP_REQUEST_COMPLETE:
            if (!checkStatusCode(action.payload.body.statusCode)) {
                if (typeof (action.payload.errorMessage) !== 'undefined') {
                    var newLabelCreationError = action.payload.errorMessage;
                    // actionOnError()
                    return Object.assign({}, state, {
                        isLabelGenApiRequestLoading: false,
                        LabelGenApiRequestErrorCode: true,
                        LabelGenApiRequestJson: newLabelCreationError
                    });
                }
                else {

                    var newLabelCreationError = action.payload.body;
                    // actionOnError()
                    return Object.assign({}, state, {
                        isLabelGenApiRequestLoading: false,
                        LabelGenApiRequestErrorCode: true,
                        LabelGenApiRequestJson: newLabelCreationError
                    });
                }
            }
            // else if (typeof(action.payload.errorMessage) !== 'undefined' ) {

            //     var newLabelCreationError = action.payload.errorMessage;
            //     // actionOnError()
            //     return Object.assign({}, state, {
            //         isLabelGenApiRequestLoading: false,
            //         LabelGenApiRequestErrorCode: true,
            //         LabelGenApiRequestJson: newLabelCreationError
            //     });   
            // }
            else {

                var newLabelCreationData = action.payload.body;
                return Object.assign({}, state, {
                    isLabelGenApiRequestLoading: false,
                    LabelGenApiRequestErrorCode: false,
                    LabelGenApiRequestJson: newLabelCreationData
                });
            }





        case types.BULK_SHIP_REQUEST_GET:
            return Object.assign({}, state, {
                isBulkShipRequestLoading: true
            });

        case types.BULK_SHIP_REQUEST_ERROR:
            var errorResponse = JSON.parse(action.payload.request.response)
            var errorResponseBody = errorResponse.body
            var errorResponseBodyMessage = errorResponseBody.message
            var errorStatusCode = errorResponseBody.statusCode
            var rowsWithErrors = {}
            var rowsWithErrorsOriginalData = JSON.parse(action.payload.config.data)
            // rowsWithErrors["message"] = errorResponseBodyMessage;
            // rowsWithErrors["statusCode"] = errorStatusCode;
            var errorParcelID = rowsWithErrorsOriginalData["UniqueKey"]["ParcelID"]

            var prevCount = state.BulkShipRequestResponseCounter
            var newCount = prevCount + 1


            rowsWithErrors["statusCode"] = errorResponseBody.statusCode
            rowsWithErrors["Carrier"] = ''
            rowsWithErrors["CustomerID"] = ''
            rowsWithErrors["LabelCreateDate"] = ''
            rowsWithErrors["OrderDate"] = ''
            rowsWithErrors["OrderNo"] = ''
            rowsWithErrors["ParcelID"] = errorParcelID
            rowsWithErrors["RequestID"] = ''
            rowsWithErrors["Service"] = ''
            rowsWithErrors["message"] = errorResponseBody.message
            rowsWithErrors["TrackingNumber"] = ''
            rowsWithErrors["ShippingLabel"] = ''



            var bulkArrayLength
            try {
                var arrayLength = Object.keys(state.BulkLabelGenApiRequestJson).length
                if (arrayLength != undefined && arrayLength > 0) {
                    bulkArrayLength = arrayLength
                }
                else {
                    bulkArrayLength = 0
                }
            }
            catch (e) {
                bulkArrayLength = 0
            }
            try {
                var newStateArray = state.BulkLabelGenApiRequestJson
            }
            catch (e) {
                var newStateArray = {}
            }
            newStateArray[bulkArrayLength] = rowsWithErrors

            return Object.assign({}, state, {
                isBulkShipRequestLoading: false,
                BulkLabelGenApiRequestJson: newStateArray,
                BulkShipRequestResponseCounter: newCount
            });



        case types.BULK_SHIP_REQUEST_COMPLETE:
            // Carrier: "UPS"
            // CustomerID: "3"
            // LabelCreateDate: "2021-04-05T22:27:01.190"
            // OrderDate: "2021-01-05T00:00:00"
            // OrderNo: "3"
            // ParcelID: "3"
            // RequestID: "VCN322"
            // Service: "M4"
            // ShippingLabel: "↵^XA^LRN^MNY^MFN,N^LH10,12^MCY^POI^PW812^CI27↵^FT10,47^A0N,22,26^FVTEST^FS↵^FT10,69^A0N,22,26^FV1 ST^FS↵^FT10,92^A0N,22,26^FVSAN DIEGO  CA 92109^FS↵^FT100,183^A0N,26,30^FV                              ^FS↵^FT573,35^A0N,18,22^FVPARCEL SELECT^FS↵^FT551,61^A0N,18,22^FVU.S. POSTAGE PAID^FS↵^FT535,87^A0N,18,22^FVUPS MAIL INNOVATIONS^FS↵^FT628,113^A0N,18,22^FVeVS^FS↵^FO527,125^GB243,2,2^FS↵↵^FO527,10^GB243,1,1^FS↵↵^FO525,10^GB2,117,2^FS↵↵^FO770,10^GB1,117,1^FS↵↵^FT10,267^A0N,30,34^FVSHIP^FS↵^FT10,300^A0N,30,34^FVTO:^FS↵^FT87,263^A0N,26,30^FVTEST^FS↵^FT87,291^A0N,26,30^FV1 ST^FS↵^FT87,331^A0N,39,42^FVSAN DIEGO  CA 92109-^FS↵^FO0,475^GB800,4,4^FS↵↵^FT17,633^BXN,3,200^FD3	802541309599636374	025413	LAX	1 ST		SAN DIEGO	CA	92109	840	3	1.0000	5,0	4209210992612909900863543475769147^FS↵^FO0,649^GB800,4,4^FS↵↵^FO171,475^GB4,174,4^FS↵↵^FT203,536^A0N,45,44^FVUPS MAIL INNOVATIONS^FS↵^FT181,575^A0N,22,26^FVMMID #: 802541309599636374^FS↵^FT181,601^A0N,22,26^FVPACKAGE ID #: 3^FS↵^FT181,627^A0N,22,26^FVCOST CENTER #: LAX^FS↵^FT490,674^A0N,14,20^FV    12.67.89                ^FS↵^FO0,680^GB800,15,15^FS↵↵^FT229,751^A0N,30,34^FVUSPS TRACKING # eVS^FS↵^FT170,987^A0N,26,30^FV9261 2909 9008 6354 3475 7691 47^FS↵^FT30,938^BY3^BCN,157,N,N,N,D,,A^FV42092109>892612909900863543475769147^FS↵↵^FT230,897^A0N,80,70^FVSAMPLE^FS↵^FO0,1000^GB800,15,15^FS↵↵^FT10,1059^A0N,22,26^FVRef1:3^FS↵^FT10,1081^A0N,22,26^FVRef2:LAX^FS↵^XZ^XZ↵"
            // TrackingNumber: "92612909900863543475769147"
            // message: "Success"
            // statusCode: 200

            var prevCount = state.BulkShipRequestResponseCounter
            var newCount = prevCount + 1

            var newLabelData = action.payload.body
            var newRows = {}
            newRows["statusCode"] = newLabelData.statusCode
            newRows["Carrier"] = newLabelData.Carrier
            newRows["CustomerID"] = newLabelData.CustomerID
            newRows["LabelCreateDate"] = newLabelData.LabelCreateDate
            newRows["OrderDate"] = newLabelData.OrderDate
            newRows["OrderNo"] = newLabelData.OrderNo
            newRows["ParcelID"] = newLabelData.ParcelID
            newRows["RequestID"] = newLabelData.RequestID
            newRows["Service"] = newLabelData.Service
            newRows["message"] = newLabelData.message
            newRows["TrackingNumber"] = newLabelData.TrackingNumber
            newRows["ShippingLabel"] = newLabelData.ShippingLabel

            newRows = newLabelData


            // var newTrackingNumber = newLabelData.TrackingNumber
            // var newStatusCode = newLabelData.statusCode
            // var newRequestID = newLabelData.RequestID
            // var newShippingLabel = newLabelData.ShippingLabel

            // var newRows = {}
            // newRows = JSON.parse(action.payload.config.data)
            // newRows["TrackingNumber"] = newTrackingNumber;
            // newRows["statusCode"] = newStatusCode;
            // newRows["RequestID"] = newRequestID;
            // newRows["ShippingLabel"] = newShippingLabel;

            var bulkArrayLength
            try {
                var arrayLength = Object.keys(state.BulkLabelGenApiRequestJson).length
                if (arrayLength != undefined && arrayLength > 0) {
                    bulkArrayLength = arrayLength
                }
                else {
                    bulkArrayLength = 0
                }
            }
            catch (e) {
                bulkArrayLength = 0
            }
            try {
                var newStateArray = state.BulkLabelGenApiRequestJson
            }
            catch (e) {
                var newStateArray = {}
            }
            newStateArray[bulkArrayLength] = newRows

            return Object.assign({}, state, {
                isBulkShipRequestLoading: false,
                BulkLabelGenApiRequestJson: newStateArray,
                BulkShipRequestResponseCounter: newCount
            });

        case types.GET_LABEL_CREATE_HISTORY:
            return Object.assign({}, state, {
                isLabelCreateHistoryLoading: true
            });
        case types.COMPLETE_LABEL_CREATE_HISTORY:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newLabelCreateHistoryData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isLabelCreateHistoryLoading: false,
                LabelCreateHistoryData: newLabelCreateHistoryData.data
            });




        // case types.LABELGEN_EXTERNAL_UPS_TRACKING_GET:
        //     return handleGet(state, action, true, 'isLabelGenExternalUpsTrackingLoading', 'LabelGenExternalUpsTrackingData');
        // case types.LABELGEN_EXTERNAL_UPS_TRACKING_COMPLETE:
        //     if (!checkStatusCode(action.payload)) {
        //         return actionOnError();
        //     }
        //     var newData = JSON.parse(action.payload.body);
        //     return handleGet(state, action, false, 'isLabelGenExternalUpsTrackingLoading', 'LabelGenExternalUpsTrackingData', newData.data);





            case types.LABELGEN_EXTERNAL_UPS_TRACKING_GET:
                return Object.assign({}, state, {
                    isLabelGenExternalUpsTrackingLoading: true
                });
            case types.LABELGEN_EXTERNAL_UPS_TRACKING_COMPLETE:
                if (!checkStatusCode(action.payload)) {
                    return actionOnError()
                }
                var newData = JSON.parse(action.payload.body);
                return Object.assign({}, state, {
                    // This returns download URL: 
                    isLabelGenExternalUpsTrackingLoading: false,
                    LabelGenExternalUpsTrackingData: newData // Download URL  //newData.data
                });
    












        default:
            return state;
    }
};





export default LabelGenReducer;
