import React, {
    PureComponent
} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Panel, Row } from 'rsuite';

const mapStateToProps = store =>
({
    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData
});


class vasContentView extends PureComponent {
    constructor(props) {
        super(props);
    }

    componentDidMount() {



    }



    render() {
        let welcome;
        if (typeof (this.props.currentUserEmail) !== 'undefined' && this.props.currentUserEmail != null) {
            welcome = 'Welcome, ' + this.props.currentUserEmail + '!'
        }
        else {
            welcome = 'Welcome!'
        }

        // var warehouseList = []
        // warehouseList = JSON.parse(sessionStorage.getItem('AppConfig.WarehouseArray'))



        // let warehouseCards;
        // warehouseCards = <div></div>
        // if (this.props.warehouseSelection != null) {

        // }
        // else {
        // warehouseCards = warehouseList.map(
        //     ({ label, value }, idx) => {
        //         return (


        //             <div style={{ cursor: 'pointer' }} onClick={(e) => this.onChangeWarehouseSelection(e, value)}>
        //                 <Col md={6} sm={12}>
        //                     <Panel bordered>
        //                         <h3>
        //                         {label}
        //                         </h3>
        //                         {/* <i style={{
        //                             display: 'flex',
        //                             flexdirection: 'column',
        //                             justifycontent: 'center'
        //                         }} className="fa fa-home"></i> */}
        //                     </Panel>
        //                 </Col>

        //             </div >
        //         );
        //     }
        // )
        // }







        return (

            <div style={{ margin: '15px' }}>
                <Row >

                    <Col md={24} sm={24}>
                        <Panel style={{ marginBottom: 15 }}>
                            <h5>
                                {welcome}
                            </h5>
                            {/* <br/> */}
                            {/* <h6>You have access to:</h6> */}
                        </Panel>
                    </Col>
                </Row>

                {/* {warehouseCards} */}
            </div >

        );
    }
}



export default connect(mapStateToProps)(withRouter(vasContentView));