import React, {
    PureComponent
} from 'react';
import { withRouter } from 'react-router-dom';

import _ from "lodash"; // cool kids know _ is low-dash
import * as moment from 'moment';
import queryString from 'query-string';
import ReactExport from "react-export-excel";
import { Button, Col, ControlLabel, InputGroup, Loader, Panel, Row } from 'rsuite';
import { containerStatus } from '../../../../app/appConfig';
import { convertNanToZero, findLowestMissingNumber, formatAllTextNumbersSymbolsForAPI, formatDateForUrlSearch, formatDateTimeForUrlSearch, formatNumberForAPI, formatStringForAPI, formatTrueFalseToBit, isNumeric, queryStringParse } from '../../../../resources/logic/functions';
import RButtonHideShowFilter from '../../../componentsGeneral/Rsuite/Button/RButtonHideShowFilter';
import RSelectPicker from '../../../componentsGeneral/Rsuite/InputPicker/RSelectPicker';
import RSearchInputNumberAsString from '../../../componentsGeneral/Rsuite/SearchInput/RSearchInputNumberAsString';
import RTableGeneral from '../../../componentsGeneral/Rsuite/Table/RTableGeneral';
import { InventoryContainerDataElements } from '../../../dataComponentsInventory/InventoryContainerDataElements';
import { emptyReceiveCaseDict, emptyReceivePalletDict, emptyReceiveSkuDict } from '../../_References/DataStructures';
import AddNewContainerDrawer from './AddNewContainerDrawer';
import AddNewContainerEditableDrawer from './AddNewContainerEditableDrawer';
import AddToContainerDrawer from './AddToContainerDrawer';
import NewBarcodesModal from './NewBarcodesModal';
import ReceiveContainerDrawer from './ReceiveContainerDrawer';

//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")

const tableIdCol = "ContainerID"

const mountRef = React.createRef();

class Inbound extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            //Warehouse & Customer Arrays
            warehouseArray: {},
            customerArray: {},

            // Table Check Box
            checkedKeys: [],

            //Search Values
            searchContainer: '',
            searchSKU: '',
            searchStatus: null,

            isFilterShown: true,

            //Add New Container Values
            unsavedNewContainerChanges: false,
            Container: null,
            Notes: null,
            LclFcl: null,
            ContainerSize: null,

            DeliveryAppointment: null,
            ETD: null,
            ETA: null,

            CustomerID: null,
            WarehouseID: null,

            //Add To Container Values
            EditContainerID: null,
            SKU: null,

            selectedType: null,
            selectedLabel: null,
            selectedID: null,
            // selectedCustomerID: null,
            // selectedWarehouseID: null,
            addNewText: null,

            newSkuUnits: null,
            newSkuCases: null,
            newSkuPallets: null,
            newSkuNotes: null,
            newSkuCustomerRef: null,
            newSkuProject: null,

            selectedSkuUnits: null,
            selectedSkuCases: null,
            selectedSkuPallets: null,
            selectedSkuNotes: null,
            selectedSkuCustomerRef: null,
            selectedSkuProject: null,



            //Edit Container SKU
            showSkuEdit: false,
            skuEditContainerID: null,
            skuEditSkuID: null,
            skuEditSKU: null,
            skuEditPalletsOrig: null,
            skuEditPallets: null,
            skuEditCasesOrig: null,
            skuEditCases: null,
            skuEditUnitsOrig: null,
            skuEditUnits: null,
            skuEditWarehouseID: null,
            skuEditCustomerID: null,




            //Receive Container Drawer
            ReceivePalletDict: {},

            ReceiveDict: {},
            LocationDict: {},
            unsavedReceiveChanges: false,
            receiveDrawerLoading: false,
            showReceiveDrawer: false,
            ReceiveDrawerContainerID: null,
            ReceiveDrawerContainer: null,
            ReceiveDrawerNo: null,
            ReceiveDrawerPMC: null,
            ReceiveDrawerPickupBy: null,
            ReceiveDrawerTrips: null,
            ReceiveDrawerArrivalTime: null,
            ReceiveDrawerLFD: null,
            ReceiveDrawerArrivalAtWarehouse: null,
            ReceiveDrawerDeliveryTime: null,
            ReceiveDrawerNotes: null,
            ReceiveDate: null,

            //Print Barcodes
            newBarcodeModalShown: false,


        };
        // Table Check Box
        this.handleCheckAll = this.handleCheckAll.bind(this);
        this.handleCheck = this.handleCheck.bind(this);

        // Table Sort
        this.handleSortColumn = this.handleSortColumn.bind(this);

        //Filter hide Show
        this.onClickIsFilterShown = this.onClickIsFilterShown.bind(this);
        this.onClickResetFilters = this.onClickResetFilters.bind(this);

        //Add new drawer
        this.closeAddNewDrawer = this.closeAddNewDrawer.bind(this);
        this.onClickConfirmAddNewContainer = this.onClickConfirmAddNewContainer.bind(this);
        this.setStateCloseAddNewDrawer = this.setStateCloseAddNewDrawer.bind(this)
        this.onClickAddNewContainer = this.onClickAddNewContainer.bind(this);

        //Add To Container Drawer
        this.resetAddToContainerState = this.resetAddToContainerState.bind(this);
        this.closeAddToContainerDrawer = this.closeAddToContainerDrawer.bind(this);
        this.onClickConfirmAddToContainer = this.onClickConfirmAddToContainer.bind(this);
        this.onClickDeleteSku = this.onClickDeleteSku.bind(this);
        this.onClickEditSku = this.onClickEditSku.bind(this);
        this.onChangeEditSku = this.onChangeEditSku.bind(this);
        this.onConfirmEditSku = this.onConfirmEditSku.bind(this);
        this.onCancelEditSku = this.onCancelEditSku.bind(this);


        this.onChangeUnits = this.onChangeUnits.bind(this);
        this.onChangeCases = this.onChangeCases.bind(this);
        this.onChangePallets = this.onChangePallets.bind(this);
        this.onChangeNotes = this.onChangeNotes.bind(this);
        this.onChangeCustomerRef = this.onChangeCustomerRef.bind(this);
        this.onChangeProject = this.onChangeProject.bind(this);

        this.onClickTableLinkEditContainer = this.onClickTableLinkEditContainer.bind(this);

        this.setNewContainerID = this.setNewContainerID.bind(this)

        //Receive drawer
        // this.onClickAdd = this.onClickAdd.bind(this);
        this.setToLoading = this.setToLoading.bind(this);
        this.setToNotLoading = this.setToNotLoading.bind(this);

        this.onChangeReceiveDrawer = this.onChangeReceiveDrawer.bind(this);

        this.onClickAddPallet = this.onClickAddPallet.bind(this);
        this.onChangePallet = this.onChangePallet.bind(this);
        this.onClickAddCase = this.onClickAddCase.bind(this);
        this.onClickAddSku = this.onClickAddSku.bind(this);
        this.onChangeCase = this.onChangeCase.bind(this);
        this.onChangeCaseSKU = this.onChangeCaseSKU.bind(this);
        this.onChangeSku = this.onChangeSku.bind(this);

        this.onClickDuplicatePallet = this.onClickDuplicatePallet.bind(this);
        this.onClickDuplicateCase = this.onClickDuplicateCase.bind(this);

        this.onClickDeletePalletRecieve = this.onClickDeletePalletRecieve.bind(this);
        this.onClickDeleteCaseRecieve = this.onClickDeleteCaseRecieve.bind(this);
        this.onClickDeleteSkuRecieve = this.onClickDeleteSkuRecieve.bind(this);

        this.handleSetStatePallet = this.handleSetStatePallet.bind(this);
        this.onClickReceive = this.onClickReceive.bind(this);
        this.onClickAddMissing = this.onClickAddMissing.bind(this);
        this.onChangePalletized = this.onChangePalletized.bind(this);
        this.closeReceiveDrawer = this.closeReceiveDrawer.bind(this);
        this.onClickReceiveDrawerSave = this.onClickReceiveDrawerSave.bind(this);
        this.onChangeReceiveDate = this.onChangeReceiveDate.bind(this);

        this.onClickAddLocation = this.onClickAddLocation.bind(this);

        this.handleAddNewLocation = this.handleAddNewLocation.bind(this);
        this.onClickRemoveFromAdd = this.onClickRemoveFromAdd.bind(this);


        //Search Values
        this.onChangeSearchContainer = this.onChangeSearchContainer.bind(this);
        this.onChangeSearchSKU = this.onChangeSearchSKU.bind(this);
        this.onChangeSearchStatus = this.onChangeSearchStatus.bind(this);


        //Print Barcodes
        this.openPrintBarcodes = this.openPrintBarcodes.bind(this);
        this.closePrintBarcodes = this.closePrintBarcodes.bind(this);
        // this.onPrintBarcodes = this.onPrintBarcodes.bind(this);


    }


    // Table Check Box
    handleCheckAll(value, checked, data) {
        const checkedKeys = checked ? [] : data.map(item => item[tableIdCol]);
        this.setState({
            checkedKeys
        });
    }
    handleCheck(value, checked) {
        const { checkedKeys } = this.state;
        const nextCheckedKeys = checked
            ? [...checkedKeys, value]
            : checkedKeys.filter(item => item !== value);

        this.setState({
            checkedKeys: nextCheckedKeys
        });
    }

    // Table Sort
    saveSortTosessionStorage(sortColumn, sortType) {
        try {
            sessionStorage.setItem('sortcolumn', sortColumn)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortType', sortType)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortURLPathname', this.props.location.pathname)
        }
        catch (e) { }
    }
    loadSortFromStorage() {
        var sortColumn = null
        var sortType = null
        var pathName = null
        try {
            sortColumn = sessionStorage.getItem('sortcolumn')
        }
        catch (e) { }
        try {
            sortType = sessionStorage.getItem('sortType')
        }
        catch (e) { }
        try {
            pathName = sessionStorage.getItem('sortURLPathname')
        }
        catch (e) { }
        if (pathName === this.props.location.pathname) {
            
            this.handleSortColumn(sortColumn, sortType)
        }
    }
    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });
        this.saveSortTosessionStorage(sortColumn, sortType)
        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 100);
    }



    componentDidMount() {
        this.loadSortFromStorage()
        this.updateParamsFromURL()
        this.getsessionStorageData() //this will preserve menu selection
        this.loadWarehouseToState()
        this.loadCustomerToState()

        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.state.WarehouseID) !== 'number') {
            this.setState({
                WarehouseID: this.props.warehouseSelection
            })
        }

        if (typeof (this.props.customerSelection) === 'number' && typeof (this.state.CustomerID) !== 'number') {
            this.setState({
                CustomerID: this.props.customerSelection
            })
        }

        this.props.userWarehouseLocationsGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection);
    }

    updateParamsFromURL = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramContainer = queryStringParse(values.container)
        }
        catch (e) {
            var paramContainer = null
        }
        if (paramContainer != null) {
            this.onChangeSearchContainer(paramContainer)
        }


        try {
            var paramSKU = queryStringParse(values.sku)
        }
        catch (e) {
            var paramSKU = null
        }
        if (paramSKU != null) {
            this.onChangeSearchSKU(paramSKU)
        }

        try {
            var paramStatus = queryStringParse(values.containerstatus)
        }
        catch (e) {
            var paramStatus = null
        }
        if (paramStatus != null) {
            this.onChangeSearchStatus(paramStatus)
        }

    }


    setNewContainerID(newID) {
        this.setState({
            EditContainerID: newID
        });
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.setState({
                checkedKeys: []
            });
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.setState({
                checkedKeys: []
            });
        }

        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.state.WarehouseID) !== 'number') {
                this.setState({
                    WarehouseID: this.props.warehouseSelection
                })
            }
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            if (typeof (this.props.customerSelection) === 'number' && typeof (this.state.CustomerID) !== 'number') {
                this.setState({
                    CustomerID: this.props.customerSelection
                })
            }
        }


        if (prevState.CustomerID !== this.state.CustomerID) {
            if (typeof (this.state.CustomerID) === 'number' && typeof (this.state.WarehouseID) === 'number') {
                this.props.inventoryCustomerRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.WarehouseID, this.state.CustomerID)
                this.props.inventoryProjectGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.WarehouseID, this.state.CustomerID)

                this.props.userWarehouseLocationsGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.WarehouseID, this.state.CustomerID);
            }
        }

        if (prevState.WarehouseID !== this.state.WarehouseID) {
            if (typeof (this.state.CustomerID) === 'number' && typeof (this.state.WarehouseID) === 'number') {
                this.props.inventoryCustomerRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.WarehouseID, this.state.CustomerID)
                this.props.inventoryProjectGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.WarehouseID, this.state.CustomerID)

                this.props.userWarehouseLocationsGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.WarehouseID, this.state.CustomerID);
            }
        }






        if (prevProps.InventorySkuRecoveryData !== this.props.InventorySkuRecoveryData) {
            if (this.props.InventorySkuRecoveryData.length > 0) {
                this.preloadReceiveState()
            }
            else {
                window.alert('Nothing to receive.')
            }
        }

        if (prevProps.isInventoryLotContainerLoading === true && this.props.isInventoryLotContainerLoading === false && this.state.searchContainer !== '' && this.props.InventoryLotContainerData.length == 0) {
            if (this.state.showAddToContainerDrawer) {
            }
            else {
                this.addContainer(this.state.searchContainer)
            }
        }

        if (prevProps.InventoryLotContainerPostSuccessful !== this.props.InventoryLotContainerPostSuccessful && this.props.InventoryLotContainerPostSuccessful && this.props.isInventoryLotContainerLoading === false && this.state.showReceiveDrawer === false) {
            // try 
            this.props.inventoryContainerSkuGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.NewLotContainerId, null)
            this.setNewContainerID(this.props.NewLotContainerId)
            // }
            // catch (e) { }

            this.setStateCloseAddNewDrawer()

            try {
                var searchCont = formatStringForAPI(this.state.Container)
                this.props.history.push({
                    search:
                        'container=' + searchCont

                });
            }
            catch (e) { }

            // if (window.confirm('Build new container?')) {
            this.onClickAddToContainer()
            // }

            this.setInitialTreeSelection(this.props.NewLotContainerId, this.state.Container)
        }

        if (prevProps.InventoryContainerSkuPostSuccessful !== this.props.InventoryContainerSkuPostSuccessful && this.props.InventoryContainerSkuPostSuccessful) {
            this.onCloseEditSku()
            try {
                this.props.inventoryContainerSkuGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.EditContainerID, null)
                // this.props.transCustomerRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection);
                // this.props.transProjectGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection);
            }
            catch (e) { }

            
        }


        if (prevProps.InventorySkuWarehouseLocationPostSuccessful !== this.props.InventorySkuWarehouseLocationPostSuccessful && this.props.InventorySkuWarehouseLocationPostSuccessful) {
            this.setReceiveDrawerToClose()
        }

        if (prevProps.InventoryRecoveryPostSuccessful !== this.props.InventoryRecoveryPostSuccessful && this.props.InventoryRecoveryPostSuccessful) {
            this.setReceiveDrawerToClose()
            this.openPrintBarcodes()
        }


    }




    loadWarehouseToState() {
        if (typeof (this.props.warehouseSelection) === 'number') {
            this.setState({
                WarehouseID: this.props.warehouseSelection
            });
        }
    }

    loadCustomerToState() {
        if (typeof (this.props.customerSelection) === 'number') {
            this.setState({
                CustomerID: this.props.customerSelection
            });
        }
    }

    getsessionStorageData() {
        try {
            let warehouseMenuList = JSON.parse(sessionStorage.getItem('AppConfig.WarehouseArray'));
            if (warehouseMenuList != null && warehouseMenuList != undefined) {
                this.setState({
                    warehouseArray: warehouseMenuList
                })
            }
        }
        catch (e) { }
        try {
            let customerMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CustomerArray'));
            if (customerMenuList != null && customerMenuList != undefined) {
                this.setState({
                    customerArray: customerMenuList
                })
            }
        }
        catch (e) { }
        try {
            let carrierMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CarrierArray'));
            if (carrierMenuList != null && carrierMenuList != undefined) {
                this.setState({
                    carrierArray: carrierMenuList
                })
            }
        }
        catch (e) { }
        try {
            let carrierServiceMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CarrierServiceArray'));
            if (carrierServiceMenuList != null && carrierServiceMenuList != undefined) {
                this.setState({
                    carrierServiceArray: carrierServiceMenuList
                })
            }
        }
        catch (e) { }
    }


    onClickIsFilterShown(e, isFilterShown) {
        if (isFilterShown) {
            this.setState({
                isFilterShown: false
            });
        }
        else {
            this.setState({
                isFilterShown: true
            });
        }
    }

    onClickResetFilters(e) {
        this.setState({
            isFilterShown: false
        });
        this.setState({
            searchContainer: '',
            searchSKU: '',
            searchStatus: null,
            searchCarrier: null,
            searchMinETADischargeStart: null,
            searchMinETADischargeEnd: null,
            searchPlannedPickupStart: null,
            searchPlannedPickupEnd: null,
            serachAssignedDriver: ''

        });
        this.props.history.push({
            search: ''
        });

        setTimeout(() => {
            this.setState({
                isFilterShown: true
            });
        }, 500);
    }

    onClickApplySearch(e) {
        this.setState({
            searchContainer: '',
            searchSKU: '',
        });


        if (this.props.warehouseSelection == null) {
            window.alert('Please select a warehouse and customer.')
        }
        else if (this.props.customerSelection == null) {
            window.alert('Please select a customer.')
        }
        else {
            try {
                var searchStatus = this.state.searchStatus
                this.props.history.push({
                    search:
                        'containerstatus=' + searchStatus

                });
            }
            catch (e) { }
        }
    }


    onSearchClearOtherFilters() {
        this.setState({
            searchStatus: null,
            searchCarrier: null,
            searchMinETADischargeStart: null,
            searchMinETADischargeEnd: null,
            searchPlannedPickupStart: null,
            searchPlannedPickupEnd: null,
            serachAssignedDriver: ''
        });

    }

    onChangeSearchContainer(e) {
        this.onSearchClearOtherFilters()
        this.setState({
            searchContainer: e,
            searchSKU: ''
        });
    }

    onChangeSearchSKU(e) {
        this.onSearchClearOtherFilters()
        this.setState({
            searchSKU: e,
            searchContainer: ''
        });
    }

    onChangeSearchStatus(e) {
        this.setState({
            searchStatus: e
        });
    }

    onChangeSearchCarrier(e) {
        this.setState({
            searchCarrier: e
        });
    }


    onClickDetails(e, rowData, history) {
        try {
            var paramID = rowData[tableIdCol]
            history.push({
                pathname: 'lotcontainerdetails',
                search: 'containerid=\'' + paramID + '\''
            });
        }
        catch (e) {
        }
    }







    //Receive Container drawer
    onClickReceive(e, rowData, history) {

        const warehouseId = rowData['WarehouseID'];
        const customerID = rowData['CustomerID'];

        this.props.inventoryPalletWarehouseLocationGet2(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, warehouseId, customerID, "'1,2'", null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)


        this.props.inventorySkuRecoverGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, null, null, rowData.ContainerID, null)


        this.setState({
            ReceiveDict: {},
            LocationDict: {},
            ReceivePalletDict: {},
            unsavedReceiveChanges: false,
            receiveDataRowData: rowData,
            showReceiveDrawer: true,
            receiveDrawerLoading: false,
            ReceiveDrawerContainerID: rowData.ContainerID,
            WarehouseID: rowData.WarehouseID,
            CustomerID: rowData.CustomerID,
            ReceiveDate: null,

            //Add Evolve Data Values
            ReceiveDrawerContainer: rowData.Container,
            ReceiveDrawerNotes: rowData.Notes
        });
    }
    preloadReceiveState() {
        var receivedDict = {};
        for (let k in this.props.InventorySkuRecoveryData) {
            // receivedDict[this.props.InventorySkuRecoveryData[k].HouseID] = { 'Cases': this.props.InventorySkuRecoveryData[k].Cartons - this.props.InventorySkuRecoveryData[k].RecoveredPCS, 'Pallets': null, 'Notes': '', 'Customs': false }
            if (this.props.InventorySkuRecoveryData[k].SkuID === null) {
            }
            else {
                receivedDict[this.props.InventorySkuRecoveryData[k].SkuID] = { 'Units': null, 'Cases': null, 'Pallets': null, 'Notes': '', 'Customs': false, 'Oversize': false, 'PalletID': null, 'Length': 48, 'Width': 40 }
            }
        }

        var locationDict = {};
        for (let k in this.props.InventorySkuRecoveryData) {
            if (this.props.InventorySkuRecoveryData[k].SkuID === null) {
            }
            else {
                // locationDict[this.props.InventorySkuRecoveryData[k].SkuID] = [{'WarehouseLocationID': null, 'WarehouseID': null, 'Cases': null, 'Pallets': null,  }]
                locationDict[this.props.InventorySkuRecoveryData[k].SkuID] = [{ 'WarehouseLocationID': null, 'WarehouseID': null, 'Units': null, 'Cases': null, 'Pallets': null, 'Oversize': false, 'PalletID': null, 'Length': 48, 'Width': 40 }]
            }
        }

        try {
            var receivedDictLength = Object.keys(receivedDict).length
        }
        catch (e) {
            var receivedDictLength = 0
        }
        if (typeof (receivedDict) === 'undefined' || receivedDict === null || receivedDictLength === 0) {
            window.alert('No SKUs exist for Receiving.')
        }
        else {

            var newPalletDict = _.cloneDeep(emptyReceivePalletDict);

            this.setState({
                unsavedReceiveChanges: false,
                ReceiveDict: receivedDict,
                LocationDict: locationDict,

                ReceivePalletDict: [newPalletDict]
            });
        }
    }
    onChangeReceiveDrawer = (event, item, label) => {
        var currentReceiveDict = this.state.ReceiveDict
        var skuID = item.SkuID;

        currentReceiveDict[skuID][label] = event

        this.setState({
            unsavedReceiveChanges: true,
            ReceiveDict: currentReceiveDict
        });

    };

    handleSetStatePallet = (newPalletDict) => {
        this.setState({
            unsavedReceiveChanges: true,
            ReceivePalletDict: newPalletDict
        }, () => {
            this.forceUpdate()
        });
    }

    onClickAddPallet(e) {
        try {
            var currentPalletDict = this.state.ReceivePalletDict
            const palletSeqArray = currentPalletDict.filter(x => typeof (x.PalletSeq) !== 'undefined').map(item => item.PalletSeq);
            var newID = findLowestMissingNumber(palletSeqArray)
            var newPallet = _.cloneDeep(emptyReceivePalletDict);
            newPallet['PalletSeq'] = newID
            currentPalletDict.push(newPallet)
            this.handleSetStatePallet(currentPalletDict)
        }
        catch (e) {
            var newPallet = _.cloneDeep(emptyReceivePalletDict);
            this.handleSetStatePallet([newPallet])
        }
    }

    onChangePallet(e, pallet, label) {

        var palletSeq = pallet['PalletSeq']
        var currentPalletDict = this.state.ReceivePalletDict
        var keyToUpdate = currentPalletDict.findIndex(item => item.PalletSeq === palletSeq)
        currentPalletDict[keyToUpdate][label] = e

        //if existing pallet is chosen
        if (label === 'PalletID') {
            const palletLocation = this.props.InventoryPalletWarehouseData2.find(item => item.PalletID === e);
            if (palletLocation) {
                const warehouseLocationID = palletLocation.WarehouseLocationID;
                const existingOversize = palletLocation.Oversize;
                const existingLength = palletLocation.Length;
                const existingWidth = palletLocation.Width;

                currentPalletDict[keyToUpdate]['WarehouseLocationID'] = warehouseLocationID
                currentPalletDict[keyToUpdate]['Qty'] = 1
                currentPalletDict[keyToUpdate]['Oversize'] = existingOversize

                currentPalletDict[keyToUpdate]['Length'] = existingLength
                currentPalletDict[keyToUpdate]['Width'] = existingWidth
            }
        }

        this.handleSetStatePallet(currentPalletDict)

        if (label === 'PalletID') {
            this.forceUpdate()
        }
    }

    onClickAddCase(e, pallet) {
        try {
            var currentPalletDict = this.state.ReceivePalletDict
            var palletSeq = pallet['PalletSeq']
            var palletKeyToUpdate = currentPalletDict.findIndex(item => item.PalletSeq === palletSeq)
            var currentCasesDict = currentPalletDict[palletKeyToUpdate]['Cases']
            const caseSeqArray = currentCasesDict.filter(x => typeof (x.CaseSeq) !== 'undefined').map(item => item.CaseSeq);
            var newID = findLowestMissingNumber(caseSeqArray)
            var newCase = _.cloneDeep(emptyReceiveCaseDict);
            newCase['CaseSeq'] = newID
            currentPalletDict[palletKeyToUpdate]['Cases'].push(newCase)
            this.handleSetStatePallet(currentPalletDict)

        }
        catch (e) {
            // var newPallet = _.cloneDeep(emptyReceivePalletDict);
            // this.handleSetStatePallet([newPallet])
        }
    }

    onClickAddSku(e, pallet, cases) {

        try {
            var currentPalletDict = this.state.ReceivePalletDict
            var palletSeq = pallet['PalletSeq']
            var palletKeyToUpdate = currentPalletDict.findIndex(item => item.PalletSeq === palletSeq)

            var currentCasesDict = currentPalletDict[palletKeyToUpdate]['Cases']
            var caseSeq = cases['CaseSeq']
            var caseKeyToUpdate = currentCasesDict.findIndex(item => item.CaseSeq === caseSeq)

            var currentSkuDict = currentCasesDict[caseKeyToUpdate]['Skus']
            const skuSeqArray = currentSkuDict.filter(x => typeof (x.SkuSeq) !== 'undefined').map(item => item.SkuSeq);



            var newID = findLowestMissingNumber(skuSeqArray)
            var newSku = _.cloneDeep(emptyReceiveSkuDict);
            newSku['SkuSeq'] = newID
            currentPalletDict[palletKeyToUpdate]['Cases'][caseKeyToUpdate]['Skus'].push(newSku)
            this.handleSetStatePallet(currentPalletDict)

        }
        catch (e) {
            // var newPallet = _.cloneDeep(emptyReceivePalletDict);
            // this.handleSetStatePallet([newPallet])
        }
    }

    onChangeCase(e, pallet, cases, label) {
        var palletSeq = pallet['PalletSeq']
        var caseSeq = cases['CaseSeq']
        var currentPalletDict = this.state.ReceivePalletDict
        var palletKeyToUpdate = currentPalletDict.findIndex(item => item.PalletSeq === palletSeq)
        var caseKeyToUpdate = currentPalletDict[palletKeyToUpdate]['Cases'].findIndex(item => item.CaseSeq === caseSeq)
        currentPalletDict[palletKeyToUpdate]['Cases'][caseKeyToUpdate][label] = e

        this.handleSetStatePallet(currentPalletDict)
    }


    onChangeCaseSKU(e, pallet, cases, label) {
        var palletSeq = pallet['PalletSeq']
        var caseSeq = cases['CaseSeq']
        var currentPalletDict = this.state.ReceivePalletDict
        var palletKeyToUpdate = currentPalletDict.findIndex(item => item.PalletSeq === palletSeq)
        var caseKeyToUpdate = currentPalletDict[palletKeyToUpdate]['Cases'].findIndex(item => item.CaseSeq === caseSeq)
        currentPalletDict[palletKeyToUpdate]['Cases'][caseKeyToUpdate][label] = e
        currentPalletDict[palletKeyToUpdate]['Cases'][caseKeyToUpdate]['SkuID'] = e
        this.handleSetStatePallet(currentPalletDict)
    }


    onChangeSku(e, pallet, cases, skus, label) {


        var palletSeq = pallet['PalletSeq']
        var caseSeq = cases['CaseSeq']
        var skuSeq = skus['SkuSeq']
        var currentPalletDict = this.state.ReceivePalletDict
        var palletKeyToUpdate = currentPalletDict.findIndex(item => item.PalletSeq === palletSeq)
        var caseKeyToUpdate = currentPalletDict[palletKeyToUpdate]['Cases'].findIndex(item => item.CaseSeq === caseSeq)

        var skuKeyToUpdate = currentPalletDict[palletKeyToUpdate]['Cases'][caseKeyToUpdate]['Skus'].findIndex(item => item.SkuSeq === skuSeq)

        currentPalletDict[palletKeyToUpdate]['Cases'][caseKeyToUpdate]['Skus'][skuKeyToUpdate][label] = e
        this.handleSetStatePallet(currentPalletDict)


    }

    onClickDuplicatePallet(e, pallet) {
        try {
            var currentPalletDict = this.state.ReceivePalletDict

            var palletSeq = pallet['PalletSeq']
            var palletKeyToUpdate = currentPalletDict.findIndex(item => item.PalletSeq === palletSeq)

            const palletSeqArray = currentPalletDict.filter(x => typeof (x.PalletSeq) !== 'undefined').map(item => item.PalletSeq);
            var newID = findLowestMissingNumber(palletSeqArray)

            var newPallet = _.cloneDeep(currentPalletDict[palletKeyToUpdate]);

            newPallet['PalletSeq'] = newID
            currentPalletDict.push(newPallet)
            this.handleSetStatePallet(currentPalletDict)
        }
        catch (e) {
            var newPallet = _.cloneDeep(emptyReceivePalletDict);
            this.handleSetStatePallet([newPallet])
        }
    }

    onClickDuplicateCase(e, pallet, cases) {
        try {
            var currentPalletDict = this.state.ReceivePalletDict

            var palletSeq = pallet['PalletSeq']
            var palletKeyToUpdate = currentPalletDict.findIndex(item => item.PalletSeq === palletSeq)

            var caseSeq = cases['CaseSeq']
            var caseKeyToUpdate = currentPalletDict[palletKeyToUpdate]['Cases'].findIndex(item => item.CaseSeq === caseSeq)

            var currentCasesDict = currentPalletDict[palletKeyToUpdate]['Cases']

            const caseSeqArray = currentCasesDict.filter(x => typeof (x.CaseSeq) !== 'undefined').map(item => item.CaseSeq);
            var newID = findLowestMissingNumber(caseSeqArray)
            var newCase = _.cloneDeep(currentPalletDict[palletKeyToUpdate]['Cases'][caseKeyToUpdate]);
            newCase['CaseSeq'] = newID
            currentPalletDict[palletKeyToUpdate]['Cases'].push(newCase)
            this.handleSetStatePallet(currentPalletDict)

        }
        catch (e) {
            // var newPallet = _.cloneDeep(emptyReceivePalletDict);
            // this.handleSetStatePallet([newPallet])
        }
    }

    onClickDeletePalletRecieve(e, pallet) {
        if (window.confirm('Confirm Delete Pallet.')) {
            var currentPalletDict = this.state.ReceivePalletDict

            var palletSeq = pallet['PalletSeq']
            var palletKeyToUpdate = currentPalletDict.findIndex(item => item.PalletSeq === palletSeq)

            currentPalletDict.splice(palletKeyToUpdate, 1)
            this.handleSetStatePallet(currentPalletDict)
        }
    }
    onClickDeleteCaseRecieve(e, pallet, cases) {
        if (window.confirm('Confirm Delete Case.')) {
            var currentPalletDict = this.state.ReceivePalletDict

            var palletSeq = pallet['PalletSeq']
            var palletKeyToUpdate = currentPalletDict.findIndex(item => item.PalletSeq === palletSeq)

            var caseSeq = cases['CaseSeq']
            var caseKeyToUpdate = currentPalletDict[palletKeyToUpdate]['Cases'].findIndex(item => item.CaseSeq === caseSeq)

            currentPalletDict[palletKeyToUpdate]['Cases'].splice(caseKeyToUpdate, 1)
            this.handleSetStatePallet(currentPalletDict)
        }
    }
    onClickDeleteSkuRecieve(e, pallet, cases, skus) {
        if (window.confirm('Confirm Delete SKU.')) {
            var currentPalletDict = this.state.ReceivePalletDict

            var palletSeq = pallet['PalletSeq']
            var palletKeyToUpdate = currentPalletDict.findIndex(item => item.PalletSeq === palletSeq)

            var caseSeq = cases['CaseSeq']
            var caseKeyToUpdate = currentPalletDict[palletKeyToUpdate]['Cases'].findIndex(item => item.CaseSeq === caseSeq)

            var skuSeq = skus['SkuSeq']
            var skuKeyToUpdate = currentPalletDict[palletKeyToUpdate]['Cases'][caseKeyToUpdate]['Skus'].findIndex(item => item.SkuSeq === skuSeq)

            currentPalletDict[palletKeyToUpdate]['Cases'][caseKeyToUpdate]['Skus'].splice(skuKeyToUpdate, 1)
            this.handleSetStatePallet(currentPalletDict)
        }
    }




    onChangeReceiveDate(e) {
        this.setState({
            unsavedReceiveChanges: true,
            ReceiveDate: e
        });

    }

    // check if same location but oversize
    onChangeReceiveDrawerLocations = (event, item, locationitem, label, existinglocationname) => {

        if (label === 'WarehouseLocationID') {
            // find new warehouse name in existing state:
            //This will be changed to dropdown choice:
            if (this.state.LocationDict[item.SkuID].filter(item => item.WarehouseLocationID === event).length > 1) {
                window.alert('Duplicate location entry, please use existing.')
            }
            else if (this.state.LocationDict[item.SkuID].filter(item => item.WarehouseLocationID === event).length > 0) {
                var existingLoc = this.state.LocationDict[item.SkuID].filter(item => item.WarehouseLocationID === event)
                if (existingLoc[0]['Oversize'] === true) {
                    var newOversize = false
                }
                else {
                    var newOversize = true
                }
                //save warehouse name:
                var warehouseLocationDictUpdated = this.state.LocationDict
                var keyToUpdate = warehouseLocationDictUpdated[item.SkuID].findIndex(item => item.WarehouseLocationID === existinglocationname && item.Oversize === locationitem['Oversize'])

                warehouseLocationDictUpdated[item.SkuID][keyToUpdate][label] = event
                warehouseLocationDictUpdated[item.SkuID][keyToUpdate]['Oversize'] = newOversize

                this.setState({
                    LocationDict: warehouseLocationDictUpdated,
                    unsavedReceiveChanges: true
                });
            }
            else {
                //save warehouse name:
                var warehouseLocationDictUpdated = this.state.LocationDict
                var keyToUpdate = warehouseLocationDictUpdated[item.SkuID].findIndex(item => item.WarehouseLocationID === existinglocationname && item.Oversize === locationitem['Oversize'])

                warehouseLocationDictUpdated[item.SkuID][keyToUpdate][label] = event

                this.setState({
                    LocationDict: warehouseLocationDictUpdated,
                    unsavedReceiveChanges: true
                });
            }
        }
        else if (label === 'Oversize' && (this.state.LocationDict[item.SkuID].filter(item => item.WarehouseLocationID === existinglocationname).length > 1)) {
            window.alert('Duplicate location and oversize entry, please use existing.')
        }
        else {
            var warehouseLocationDictUpdated = this.state.LocationDict
            var keyToUpdate = warehouseLocationDictUpdated[item.SkuID].findIndex(item => item.WarehouseLocationID === existinglocationname && item.Oversize === locationitem['Oversize'])

            warehouseLocationDictUpdated[item.SkuID][keyToUpdate][label] = event

            this.setState({
                LocationDict: warehouseLocationDictUpdated,
                unsavedReceiveChanges: true
            });

            //Use warehouseLocationDictUpdated to update totals of Pallets and cases
            var currentReceiveDict = this.state.ReceiveDict
            var skuID = item.SkuID;

            var sumNewValue = 0;

            for (var x in warehouseLocationDictUpdated[skuID]) {
                sumNewValue += convertNanToZero(parseInt(warehouseLocationDictUpdated[skuID][x][label]));
            }

            currentReceiveDict[skuID][label] = sumNewValue

            this.setState({
                ReceiveDict: currentReceiveDict
            });
        }
        this.forceUpdate()
    };



    handleAddNewLocation = (newLocationDict) => {
        this.setState({
            unsavedReceiveChanges: true,
            LocationDict: newLocationDict
        }, () => {
            // Do an API call with this.state.searchTerm

        });
    }
    onClickAddLocation = (event, item) => {
        //Check if any Warehouse names are null
        if (this.state.LocationDict[item.SkuID].filter(item => item.WarehouseLocationID === null || item.WarehouseLocationID === '').length > 0) {
            window.alert('Please enter all location values first.')
        }
        else {
            //save warehouse name
            var currentLocationDict = this.state.LocationDict

            currentLocationDict[item.SkuID].push({ 'WarehouseLocationID': null, 'WarehouseID': null, 'Units': null, 'Cases': null, 'Pallets': null, 'Oversize': false, 'PalletID': null, 'Length': 48, 'Width': 40 })

            this.handleAddNewLocation(currentLocationDict)

            this.forceUpdate()
        }
    }

    onClickRemoveFromAdd = (e, item, locationitem, warehouseLocationID) => {
        var currentList = JSON.parse(JSON.stringify(this.state.LocationDict));
        var skuID = item.SkuID
        if (currentList[skuID].length === 1) {
            currentList[skuID][0] = { 'WarehouseLocationID': null, 'WarehouseID': null, 'Units': 0, 'Cases': 0, 'Pallets': 0, 'Oversize': false, 'PalletID': null, 'Length': 48, 'Width': 40 }
        }
        else {
            var remainingItems = currentList[skuID].filter((item) => { return !(item.WarehouseLocationID === warehouseLocationID && item.Oversize === locationitem['Oversize']) });
            currentList[skuID] = JSON.parse(JSON.stringify(remainingItems));
        }

        var currentBuildLoadDict = this.state.ReceiveDict
        try {
            currentBuildLoadDict[skuID]['Units'] = (currentList[skuID].reduce((a, v) => a = convertNanToZero(a) + isNumeric(v.Units), 0))
            currentBuildLoadDict[skuID]['Cases'] = (currentList[skuID].reduce((a, v) => a = convertNanToZero(a) + isNumeric(v.Cases), 0))
            currentBuildLoadDict[skuID]['Pallets'] = (currentList[skuID].reduce((a, v) => a = convertNanToZero(a) + isNumeric(v.Pallets), 0))
        }
        catch (e) {
            // currentBuildLoadDict[skuID]['Cases'] = replaceNegativeWithZero(currentBuildLoadDict['CasesReceived'] - currentBuildLoadDict['CasesLoaded'])
            // currentBuildLoadDict[skuID]['Pallets'] = replaceNegativeWithZero(currentBuildLoadDict['PalletsReceived'] - currentBuildLoadDict['PalletsLoaded'])
        }
        this.setState({
            // unsavedBuildLoadChanges: true,
            ReceiveDict: currentBuildLoadDict,
            LocationDict: currentList,
        });

        this.forceUpdate()
    }
    onChangePalletized(checked) {
        var containerDataUpdated = this.state.receiveDataRowData
        containerDataUpdated['Palletized'] = checked
        this.setState({
            receiveDataRowData: containerDataUpdated
        });
        this.forceUpdate()
    }

    setToLoading() {
        this.setState({
            receiveDrawerLoading: true,
        })
        this.forceUpdate()
    }

    setToNotLoading() {
        this.setState({
            receiveDrawerLoading: false,
        })
        this.forceUpdate()

    }




    onClickReceiveDrawerSave(e) {

        const { ReceivePalletDict } = this.state;

        const allCasesHaveSkuID = ReceivePalletDict.every(pallet =>
            pallet.Cases.every(caseItem => caseItem.SkuID !== null)
        );

        if (!allCasesHaveSkuID) {
            alert('All cases must have a SKU selected.');
            return;
        }

        const allUnitsHaveSkuID = ReceivePalletDict.every(pallet =>
            pallet.Cases.every(caseItem =>
                caseItem.Skus.every(sku => sku.SkuID !== null)
            )
        );

        if (!allUnitsHaveSkuID) {
            alert('All units must have a SKU selected.');
            return;
        }

        if (this.state.ReceivePalletDict.filter(item => item.WarehouseLocationID === null || item.WarehouseLocationID === '').length > 0) {
            window.alert('Please enter all warehouse location values first.')
        }
        else {

            this.setToLoading()

            try {

                var currentPalletDict = this.state.ReceivePalletDict.map(pallet => {
                    return {
                        P: pallet.PalletID,
                        S: pallet.PalletSeq,
                        W: pallet.WarehouseLocationID,
                        O: pallet.Oversize,
                        LT: pallet.Length,
                        WT: pallet.Width,
                        Q: pallet.Qty,
                        N: pallet.Notes,
                        C: pallet.Cases.map(caseItem => {
                            return {
                                CS: caseItem.CaseSeq,
                                Q: caseItem.Qty,
                                I: caseItem.SkuID,
                                // SKU: caseItem.SKU,
                                L: caseItem.LotCode,
                                E: caseItem.SerialNum,
                                N: caseItem.Notes,
                                K: caseItem.Skus.map(skuItem => {
                                    return {
                                        SS: skuItem.SkuSeq,
                                        I: skuItem.SkuID,
                                        // SKU: skuItem.SKU,
                                        U: skuItem.Units,
                                        L: skuItem.LotCode,
                                        E: skuItem.SerialNum
                                    }
                                })
                            }
                        })
                    }
                });



                // var  = this.state.ReceivePalletDict
                // Duplicate any cases with qty > 1
                for (let p in currentPalletDict) {

                    for (let c in currentPalletDict[p]['C']) {

                        if (currentPalletDict[p]['C'][c]['Q'] > 1) {
                            var qty = currentPalletDict[p]['C'][c]['Q']
                            var qtyToLoop = qty - 1
                            for (var i = 0; i < qtyToLoop; i++) {

                                var palletSeq = currentPalletDict[p]['S']
                                var palletKeyToUpdate = p

                                var caseSeq = currentPalletDict[p]['C'][c]['CS']
                                var caseKeyToUpdate = c

                                var currentCasesDict = currentPalletDict[palletKeyToUpdate]['C']

                                const caseSeqArray = currentCasesDict.filter(x => typeof (x.CS) !== 'undefined').map(item => item.CS);
                                var newID = findLowestMissingNumber(caseSeqArray)
                                var newCase = _.cloneDeep(currentPalletDict[palletKeyToUpdate]['C'][caseKeyToUpdate]);
                                newCase['CS'] = newID
                                newCase['Q'] = 1

                                currentPalletDict[palletKeyToUpdate]['C'].push(newCase)

                            }

                            currentPalletDict[p]['C'][c]['Q'] = 1

                        }
                    }

                }



                // Duplicate any pallets with qty > 1
                for (let p in currentPalletDict) {

                    if (currentPalletDict[p]['Q'] > 1) {

                        var qty = currentPalletDict[p]['Q']
                        var qtyToLoop = qty - 1
                        for (var i = 0; i < qtyToLoop; i++) {

                            var palletSeq = currentPalletDict[p]['S']
                            var palletKeyToUpdate = p

                            const palletSeqArray = currentPalletDict.filter(x => typeof (x.S) !== 'undefined').map(item => item.S);
                            var newID = findLowestMissingNumber(palletSeqArray)

                            var newPallet = _.cloneDeep(currentPalletDict[palletKeyToUpdate]);

                            newPallet['S'] = newID
                            newPallet['Q'] = 1

                            currentPalletDict.push(newPallet)

                        }

                        currentPalletDict[p]['Q'] = 1

                    }

                }

                // this.handleSetStatePallet(currentPalletDict)

                var ReceivePalletDictString = JSON.stringify(currentPalletDict)

                try {
                    var paramPostReceivePalletDict = "'" + ReceivePalletDictString + "'"
                }
                catch (e) {
                    var paramPostReceivePalletDict = null
                }

                this.props.inventoryRecoveryPost(
                    this.props.currentUserEmail,
                    this.props.userSignInData.AuthToken,
                    formatNumberForAPI(this.state.receiveDataRowData.WarehouseID),
                    formatNumberForAPI(this.state.receiveDataRowData.CustomerID),
                    paramPostReceivePalletDict,
                    formatNumberForAPI(this.state.receiveDataRowData.ContainerID),
                    null,
                    formatTrueFalseToBit(this.state.receiveDataRowData.Palletized),
                    formatDateTimeForUrlSearch(this.state.ReceiveDate)
                )
                this.setToNotLoading()
            }
            catch (e) {
                this.setToNotLoading()
            }
        }
    }



    // onClickReceiveDrawerSave(e) {

    //     const { ReceivePalletDict } = this.state;

    //     const allCasesHaveSkuID = ReceivePalletDict.every(pallet =>
    //         pallet.Cases.every(caseItem => caseItem.SkuID !== null)
    //     );

    //     if (!allCasesHaveSkuID) {
    //         alert('All cases must have a SKU selected.');
    //         return;
    //     }

    //     const allUnitsHaveSkuID = ReceivePalletDict.every(pallet =>
    //         pallet.Cases.every(caseItem =>
    //             caseItem.Skus.every(sku => sku.SkuID !== null)
    //         )
    //     );

    //     if (!allUnitsHaveSkuID) {
    //         alert('All units must have a SKU selected.');
    //         return;
    //     }

    //     if (this.state.ReceivePalletDict.filter(item => item.WarehouseLocationID === null || item.WarehouseLocationID === '').length > 0) {
    //         window.alert('Please enter all warehouse location values first.')
    //     }
    //     else {

    //         this.setToLoading()

    //         try {

    //             var newPalletDict = this.state.ReceivePalletDict.map(pallet => {
    //                 return {
    //                     P: pallet.PalletID,
    //                     S: pallet.PalletSeq,
    //                     W: pallet.WarehouseLocationID,
    //                     O: pallet.Oversize,
    //                     Q: pallet.Qty,
    //                     N: pallet.Notes,
    //                     C: pallet.Cases.map(caseItem => {
    //                         return {
    //                             CS: caseItem.CaseSeq,
    //                             Q: caseItem.Qty,
    //                             I: caseItem.SkuID,
    //                             // SKU: caseItem.SKU,
    //                             L: caseItem.LotCode,
    //                             E: caseItem.SerialNum,
    //                             N: caseItem.Notes,
    //                             K: caseItem.Skus.map(skuItem => {
    //                                 return {
    //                                     SS: skuItem.SkuSeq,
    //                                     I: skuItem.SkuID,
    //                                     // SKU: skuItem.SKU,
    //                                     U: skuItem.Units,
    //                                     L: skuItem.LotCode,
    //                                     E: skuItem.SerialNum
    //                                 }
    //                             })
    //                         }
    //                     })
    //                 }
    //             });



    //             var currentPalletDict = this.state.ReceivePalletDict
    //             // Duplicate any cases with qty > 1
    //             for (let p in currentPalletDict) {

    //                 for (let c in currentPalletDict[p]['Cases']) {

    //                     if (currentPalletDict[p]['Cases'][c]['Qty'] > 1) {
    //                         var qty = currentPalletDict[p]['Cases'][c]['Qty']
    //                         var qtyToLoop = qty - 1
    //                         for (var i = 0; i < qtyToLoop; i++) {

    //                             var palletSeq = currentPalletDict[p]['PalletSeq']
    //                             var palletKeyToUpdate = p

    //                             var caseSeq = currentPalletDict[p]['Cases'][c]['CaseSeq']
    //                             var caseKeyToUpdate = c

    //                             var currentCasesDict = currentPalletDict[palletKeyToUpdate]['Cases']

    //                             const caseSeqArray = currentCasesDict.filter(x => typeof (x.CaseSeq) !== 'undefined').map(item => item.CaseSeq);
    //                             var newID = findLowestMissingNumber(caseSeqArray)
    //                             var newCase = _.cloneDeep(currentPalletDict[palletKeyToUpdate]['Cases'][caseKeyToUpdate]);
    //                             newCase['CaseSeq'] = newID
    //                             newCase['Qty'] = 1

    //                             currentPalletDict[palletKeyToUpdate]['Cases'].push(newCase)

    //                         }

    //                         currentPalletDict[p]['Cases'][c]['Qty'] = 1

    //                     }
    //                 }

    //             }



    //             // Duplicate any pallets with qty > 1
    //             for (let p in currentPalletDict) {

    //                 if (currentPalletDict[p]['Qty'] > 1) {

    //                     var qty = currentPalletDict[p]['Qty']
    //                     var qtyToLoop = qty - 1
    //                     for (var i = 0; i < qtyToLoop; i++) {

    //                         var palletSeq = currentPalletDict[p]['PalletSeq']
    //                         var palletKeyToUpdate = p

    //                         const palletSeqArray = currentPalletDict.filter(x => typeof (x.PalletSeq) !== 'undefined').map(item => item.PalletSeq);
    //                         var newID = findLowestMissingNumber(palletSeqArray)

    //                         var newPallet = _.cloneDeep(currentPalletDict[palletKeyToUpdate]);

    //                         newPallet['PalletSeq'] = newID
    //                         newPallet['Qty'] = 1

    //                         currentPalletDict.push(newPallet)

    //                     }

    //                     currentPalletDict[p]['Qty'] = 1

    //                 }

    //             }

    //             this.handleSetStatePallet(currentPalletDict)

    //             var ReceivePalletDictString = JSON.stringify(currentPalletDict)

    //             try {
    //                 var paramPostReceivePalletDict = "'" + ReceivePalletDictString + "'"
    //             }
    //             catch (e) {
    //                 var paramPostReceivePalletDict = null
    //             }

    //             // this.props.inventoryRecoveryPost(
    //             //     this.props.currentUserEmail,
    //             //     this.props.userSignInData.AuthToken,
    //             //     formatNumberForAPI(this.state.receiveDataRowData.WarehouseID),
    //             //     formatNumberForAPI(this.state.receiveDataRowData.CustomerID),
    //             //     paramPostReceivePalletDict,
    //             //     formatNumberForAPI(this.state.receiveDataRowData.ContainerID),
    //             //     null,
    //             //     formatTrueFalseToBit(this.state.receiveDataRowData.Palletized),
    //             //     formatDateTimeForUrlSearch(this.state.ReceiveDate)
    //             // )
    //             this.setToNotLoading()
    //         }
    //         catch (e) {
    //             this.setToNotLoading()
    //         }
    //     }
    // }


    openPrintBarcodes() {
        this.setState({
            newBarcodeModalShown: true
        })
    }

    closePrintBarcodes() {
        this.setState({
            newBarcodeModalShown: false
        })
    }

    closeReceiveDrawer() {
        if (this.state.unsavedReceiveChanges) {
            if (window.confirm('Leave without saving changes?')) {
                this.setReceiveDrawerToClose()
            }
        }
        else {
            this.setReceiveDrawerToClose()
        }
    }

    setReceiveDrawerToClose() {
        this.setState({
            ReceiveDict: {},
            LocationDict: {},
            ReceivePalletDict: {},
            unsavedReceiveChanges: false,

            showReceiveDrawer: false,
            receiveDrawerLoading: false,
            ReceiveDrawerContainerID: null,
            ReceiveDrawerContainer: null,
            ReceiveDrawerNo: null,
            ReceiveDrawerPMC: null,
            ReceiveDrawerPickupBy: null,
            ReceiveDrawerTrips: null,
            ReceiveDrawerArrivalTime: null,
            ReceiveDrawerLFD: null,
            ReceiveDrawerArrivalAtWarehouse: null,
            ReceiveDrawerDeliveryTime: null,
            ReceiveDrawerNotes: null,

            WarehouseID: null,
            CustomerID: null,
        });
    }





    //Add new HAWB modal
    onClickAddMissing() {
        this.handleOpenAddNewModal()
    }
    handleOpenAddNewModal() {
        this.setState({
            addNewModalShown: true,

            newModalHouseRef: null,
            newModalConsigneeID: null,

            newModalCartons: null,

        });
    }
    onChangeAddNewModal = (event, field) => {
        var dict = {};
        dict[field] = event;
        this.setState(
            dict
        );
    };
    handleCloseAddNewModal() {
        this.setState({
            addNewModalShown: false,

            newModalHouseRef: null,
            newModalConsigneeID: null,

            newModalCartons: null,

        });
    }
    // handleSaveAddNewModal() {
    //     if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {

    //         if (window.confirm('Do you wish to add missing SKU?')) {
    //             this.props.hawbPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.receiveDataRowData.ContainerID, formatStringForAPI(this.state.newModalHouseRef), null, this.state.newModalConsigneeID, formatStringForAPI('Manual Add'), null, null, null, null, null, null, null, null, null, this.state.newModalCartons, null, null, null, null, this.props.warehouseSelection, this.props.customerSelection)
    //         }
    //         this.handleCloseAddNewModal()
    //     }
    //     else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    // }

    // if in the middle of building container do not prompt




    //Add new Container drawer

    onClickAddNewContainer(e) {
        this.addContainer(-1)
    }


    addContainer(searchValue) {
        this.loadWarehouseToState()
        this.loadCustomerToState()

        if (typeof (this.props.warehouseSelection) === 'number') {
            this.setState({
                WarehouseID: this.props.warehouseSelection
            });
        }
        else {
            this.setState({
                WarehouseID: null
            });
        }

        if (typeof (this.props.customerSelection) === 'number') {
            this.setState({
                CustomerID: this.props.customerSelection
            });
        }
        else {
            this.setState({
                CustomerID: null
            });
        }

        if (this.state.showAddToContainerDrawer) {

        }
        else if (searchValue === null || searchValue == 'null') {

        }
        else if (this.props.warehouseSelection == null) {
            window.alert('Please select a warehouse and customer.')
        }
        else if (this.props.customerSelection == null) {
            window.alert('Please select a customer.')
        }
        else if (searchValue === -1) {
            this.onClickAddNewEditable()
            this.setState({
                Container: '',
                unsavedNewContainerChanges: true,
            });

        }
        else if (window.confirm(searchValue + ' not found, do you wish to add?')) {
            this.onClickAddNew()
            this.setState({
                Container: searchValue,
                unsavedNewContainerChanges: true,
            });
        }
        this.forceUpdate()
    }
    onClickAddNew(placement) {
        this.setState({
            placement,
            showAddNewContainerDrawer: true,
            showAddNewContainerEditableDrawer: false,
            unsavedNewContainerChanges: true,
        });
    }
    onClickAddNewEditable(placement) {
        this.setState({
            placement,
            showAddNewContainerDrawer: false,
            showAddNewContainerEditableDrawer: true,
            unsavedNewContainerChanges: true,
        });
    }
    onChangeAddNewContainer = (event, field) => {
        if (field === 'LclFcl' && (event === 'LCL' || event == null)) {
            this.setState({
                ContainerSize: null,
            });
        }

        var dict = {};
        dict[field] = event;
        this.setState(
            dict
        );
        this.setState({
            unsavedNewContainerChanges: true,
        });
    };
    onClickConfirmAddNewContainer(e) {

        if (typeof (this.state.WarehouseID) === 'number' && typeof (this.state.CustomerID) === 'number') {
            if (this.state.Container == null || this.state.Container == '') {
                window.alert('Please enter new Container.')
            }
            else if (this.state.LclFcl == null || this.state.LclFcl == '') {
                window.alert('Please enter LCL/FCL.')
            }
            else if (this.state.LclFcl == 'FCL' && (this.state.ContainerSize == null || this.state.ContainerSize == '')) {
                window.alert('Please enter Container Size.')
            }
            // else if (window.confirm('Add New Container?')) {
            else {
                //add ContainerSize
                // this.props.inventoryLotContainerPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, formatStringForAPI(this.state.Container), null, formatNumberForAPI(this.state.TotWeight), formatNumberForAPI(this.state.TotChargeable), formatNumberForAPI(this.state.TotVolume), formatDateForUrlSearch(this.state.MinETADischarge), formatStringForAPI(this.state.CarrierName), formatStringForAPI(this.state.FlightVoyage), formatStringForAPI(this.state.AgentNote), formatNumberForAPI(this.state.CurrentMawbStatusID ))
                this.props.inventoryLotContainerPost(
                    this.props.currentUserEmail,
                    this.props.userSignInData.AuthToken,
                    null,
                    formatStringForAPI(this.state.Container),
                    formatStringForAPI(this.state.LclFcl),
                    formatDateTimeForUrlSearch(this.state.DeliveryAppointment),
                    formatDateForUrlSearch(this.state.ETD),
                    formatDateForUrlSearch(this.state.ETA),
                    1,
                    formatStringForAPI(this.state.Notes),
                    this.state.WarehouseID,
                    this.state.CustomerID,
                    1,
                    this.state.ContainerSize
                    // this.props.warehouseSelection,
                    // this.props.customerSelection
                )
            }
        }
        else { window.alert('Please select one Warehouse and one Customer to save data.') }

    }
    closeAddNewDrawer() {
        if (this.state.unsavedNewContainerChanges) {
            if (window.confirm('Leave without saving changes?')) {
                this.setStateCloseAddNewDrawer()
            }
        }
        else {
            this.setStateCloseAddNewDrawer()
        }
    }
    setStateCloseAddNewDrawer() {
        this.setState({
            showAddNewContainerDrawer: false,
            showAddNewContainerEditableDrawer: false,
            unsavedNewContainerChanges: false,
            Container: null,
            LclFcl: null,
            ContainerSize: null,
            Notes: null,
            DeliveryAppointment: null,
            ETD: null,
            ETA: null,
            // CustomerID: null,
            // WarehouseID: null
        });
    }
    setInitialTreeSelection(NewLotContainerId, NewContainerLabel) {
        this.setState({
            selectedType: 'container',
            selectedLabel: NewContainerLabel,
            selectedID: NewLotContainerId,
            addNewText: null,

        });
    }

    //Add To Container Drawer
    onClickAddToContainer(placement) {

        this.setState({
            placement,

            showAddToContainerDrawer: true,

            SKU: null,

            selectedType: null,
            selectedLabel: null,
            selectedID: null,
            // selectedWarehouseID: null,
            // selectedCustomerID: null,
            addNewText: null,

            newSkuUnits: null,
            newSkuCases: null,
            newSkuPallets: null,
            newSkuNotes: null,
            newSkuCustomerRef: null,
            newSkuProject: null,

            selectedSkuUnits: null,
            selectedSkuCases: null,
            selectedSkuPallets: null,
            selectedSkuNotes: null,
            selectedSkuCustomerRef: null,
            selectedSkuProject: null
        });
    }

    onClickTableLinkEditContainer(e, rowData, history) {
        var newContainerID = null
        var newContainer = null
        var newWarehouse = null
        var newCustomer = null
        try {
            newContainerID = rowData['ContainerID']
            newContainer = rowData['Container']
            newWarehouse = rowData['WarehouseID']
            newCustomer = rowData['CustomerID']

            this.props.inventoryContainerSkuGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, newContainerID, null)
        }
        catch (e) { }

        this.setState({
            // placement,
            EditContainerID: newContainerID,
            showAddToContainerDrawer: true,

            SKU: null,

            WarehouseID: newWarehouse,
            CustomerID: newCustomer,

            selectedType: 'container',
            selectedLabel: newContainer,
            selectedID: newContainerID,
            // selectedWarehouseID: newWarehouse,
            // selectedCustomerID: newCustomer,
            addNewText: null,

            newSkuUnits: null,
            newSkuCases: null,
            newSkuPallets: null,
            newSkuNotes: null,
            newSkuCustomerRef: null,
            newSkuProject: null,

            selectedSkuUnits: null,
            selectedSkuCases: null,
            selectedSkuPallets: null,
            selectedSkuNotes: null,
            selectedSkuCustomerRef: null,
            selectedSkuProject: null,
        });

    }
    onChangeAddToContainer = (e) => {
        this.setState({
            addNewText: e
        });
    }

    onChangeUnits = (e) => {
        this.setState({
            newSkuUnits: e
        });
    }
    onChangeCases = (e) => {
        this.setState({
            newSkuCases: e
        });
    }
    onChangePallets = (e) => {
        this.setState({
            newSkuPallets: e
        });
    }
    onChangeNotes = (e) => {
        this.setState({
            newSkuNotes: e
        });
    }
    onChangeCustomerRef = (e, value, item) => {
        this.setState({
            newSkuCustomerRef: e
        });
    }
    onChangeProject = (e, value, item) => {
        this.setState({
            newSkuProject: e
        });
    }


    onClickConfirmAddToContainer(e) {

        if (this.state.addNewText == null || this.state.addNewText == '') {
            window.alert('Please enter data.')
        }
        // else if (window.confirm('Confirm Save?')) {
        else {
            // save child of selected type
            // if (this.state.selectedType === 'container') {

            this.props.inventoryContainerSkuPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.WarehouseID, this.state.CustomerID, this.state.selectedID, null, formatStringForAPI(this.state.addNewText), this.state.newSkuUnits, this.state.newSkuCases, this.state.newSkuPallets, null, null, formatStringForAPI(this.state.newSkuCustomerRef), null, formatStringForAPI(this.state.newSkuProject), formatAllTextNumbersSymbolsForAPI(this.state.newSkuNotes))

            this.resetAddToContainerState()

        }
        // }

    }

    onClickEditSku(e, rowData, history) {
        this.setState({
            showSkuEdit: true,
            skuEditContainerID: rowData['ContainerID'],
            skuEditSkuID: rowData['SkuID'],
            skuEditSKU: rowData['SKU'],
            skuEditPalletsOrig: rowData['Pallets'],
            skuEditPallets: rowData['Pallets'],
            skuEditCasesOrig: rowData['Cases'],
            skuEditCases: rowData['Cases'],
            skuEditUnitsOrig: rowData['Units'],
            skuEditUnits: rowData['Units'],
            skuEditWarehouseID: rowData['WarehouseID'],
            skuEditCustomerID: rowData['CustomerID'],
        });

    // need to find differnce from exsiting  since proc adds new number to existing
    }
    onChangeEditSku(e, label){
        this.setState({
            [label]: e,
        });
    }



    onConfirmEditSku(){
        var newPallets = formatNumberForAPI( this.state.skuEditPallets) - formatNumberForAPI(this.state.skuEditPalletsOrig)
        var newCases = formatNumberForAPI( this.state.skuEditCases) - formatNumberForAPI(this.state.skuEditCasesOrig)
        var newUnits = formatNumberForAPI( this.state.skuEditUnits) - formatNumberForAPI(this.state.skuEditUnitsOrig)

        this.props.inventoryContainerSkuPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.skuEditWarehouseID, this.state.skuEditCustomerID, this.state.skuEditContainerID, this.state.skuEditSkuID, null, newUnits, newCases, newPallets, null, null, null, null, null, null)


    }
    onCancelEditSku(){
        this.onCloseEditSku()
    }
    
    onCloseEditSku(){
        this.setState({
            showSkuEdit: false,
            skuEditContainerID: null,
            skuEditSkuID: null,
            skuEditSKU: null,
            skuEditPalletsOrig: null,
            skuEditPallets: null,
            skuEditCasesOrig: null,
            skuEditCases: null,
            skuEditUnitsOrig: null,
            skuEditUnits: null,
            skuEditWarehouseID: null,
            skuEditCustomerID: null,
        });

    }

    onClickDeleteSku(e, rowData, history) {
        if (window.confirm('Confirm Delete?')) {
            // this.setState({
            //     selectedType: null,
            //     selectedLabel: null,
            //     selectedID: null,
            // });
            this.props.inventoryContainerSkuPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.WarehouseID, this.state.CustomerID, rowData['ContainerID'], rowData['SkuID'], null, null, null, null, 1, null, null, null, null, null)
        }
    }



    resetAddToContainerState() {
        this.setState({
            addNewText: null,
            newSkuUnits: null,
            newSkuCases: null,
            newSkuPallets: null,
            newSkuNotes: null,
            newSkuCustomerRef: null,
            newSkuProject: null,
            selectedSkuUnits: null,
            selectedSkuCases: null,
            selectedSkuPallets: null,
            selectedSkuNotes: null,
            selectedSkuCustomerRef: null,
            selectedSkuProject: null
        });
    }

    closeAddToContainerDrawer() {
        this.setState({
            EditContainerID: null,
            showAddToContainerDrawer: false,

            SKU: null,

            selectedType: null,
            selectedLabel: null,
            selectedID: null,
            // selectedCustomerID: null,
            // selectedWarehouseID: null,
            addNewText: null,
            newSkuUnits: null,
            newSkuCases: null,
            newSkuPallets: null,
            newSkuNotes: null,
            newSkuCustomerRef: null,
            newSkuProject: null,
            selectedSkuUnits: null,
            selectedSkuCases: null,
            selectedSkuPallets: null,
            selectedSkuNotes: null,
            selectedSkuCustomerRef: null,
            selectedSkuProject: null
        });
    }



    render() {


        if (this.props.InventoryRecoveryPostLoading) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                    <p style={{ marginTop: '20px', fontSize: '20px', color: '#3396E5' }}>Please wait, receiving...</p>
                </div>
            );
        }


        if (this.props.isInventorySkuRecoveryLoading) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                    <p style={{ marginTop: '20px', fontSize: '20px', color: '#3396E5' }}>Please wait, receiving...</p>
                </div>
            );
        }






        const { checkedKeys, sortColumn, sortType } = this.state;
        let hideShowSearch;
        hideShowSearch = <Row style={{ marginBottom: '5px' }}>

            <Col md={24} sm={24} >

                <p>
                    {RButtonHideShowFilter(this.props.isMobile, this.state.isFilterShown, 'Filters', this.onClickIsFilterShown, this.onClickResetFilters)}
                </p>

            </Col>
        </Row>

        let searchDiv;
        if (this.state.isFilterShown) {
            searchDiv = <Row  >
                <Col md={24} sm={24} >
                    <Panel bordered style={{ marginBottom: '10px' }}>

                        <Row>
                            <Col md={20} sm={20} >
                                {RSearchInputNumberAsString(
                                    this.props.isMobile,
                                    'Container',
                                    this.onChangeSearchContainer,
                                    this.props.history,
                                    'container',
                                    this.state.searchContainer
                                )}
                            </Col>
                            <Col md={4} sm={4} >
                                <div >
                                    <ControlLabel>&nbsp;</ControlLabel>
                                    <InputGroup style={{ marginBottom: 10 }}>
                                        <Button block onClick={(e) => { this.onClickAddNewContainer(e) }} > Add New </Button>
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={24} sm={24} >
                                {RSearchInputNumberAsString(
                                    this.props.isMobile,
                                    'SKU',
                                    this.onChangeSearchSKU,
                                    this.props.history,
                                    'sku',
                                    this.state.searchSKU
                                )}
                            </Col>
                        </Row>
                    </Panel>
                    <Panel bordered style={{ marginBottom: '10px' }}>
                        <Row>
                            {RSelectPicker(
                                this.props.isMobile,
                                containerStatus,
                                this.state.searchStatus,
                                'Status',
                                this.onChangeSearchStatus,
                                this.props.history,
                                mountRef
                            )}
                        </Row>

                        <Row>
                            <Button onClick={(e) => { this.onClickApplySearch(e) }} style={{ marginRight: '10px' }}> Apply </Button>
                            <Button onClick={(e) => { this.onClickResetFilters(e) }}> Reset </Button>
                        </Row>
                    </Panel>
                </Col>
            </Row>
        }
        else if (!this.state.isFilterShown) {
            searchDiv = <div></div>
        }

        let mawbDiv;
        let rTable;
        if (this.props.isInventoryLotContainerLoading) {

            mawbDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventoryLotContainerData) !== 'undefined' && this.props.InventoryLotContainerData != null) {



            rTable = RTableGeneral(
                tableIdCol,     //   keyColumn,
                this.props.InventoryLotContainerData,     //   tableData,
                InventoryContainerDataElements,       //   DataElements,
                this.props.isInventoryLotContainerLoading,        //   loading,

                this.props.isMobile,        //   isMobile,
                this.props.history,     //   history,

                sortColumn,     //   sortColumn,
                sortType,       //   sortType,
                this.handleSortColumn,      //   handleSortColumn,

                false, // checkColEntry,
                null,       //   checkedKeys,
                null,       //   handleCheckAll,
                null,       //   handleCheck,

                true,      //   boolSelectButton,
                this.onClickReceive,       //   onClickSelect,
                'Receive',       //   selectButtonText,
                null,       //   selectHighlightRowKey,

                true,       //   link1bool,
                'Details',     //   link1text,
                this.onClickDetails,     //   link1onClick,

                true,       //   link2bool,
                'Edit',       //   link2text,
                this.onClickTableLinkEditContainer  //   link2onClick

            )


            mawbDiv = <div>

                {(this.props.isMobile == true) ? <div></div> :
                    <div>
                        <Row>
                            <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Shipment_' + currentDateTime}>
                                <ExcelSheet data={this.props.InventoryLotContainerData} name="Shipment">
                                    {
                                        InventoryContainerDataElements.map(
                                            ({ dBKey, displayName }, idx) => {
                                                return (
                                                    <ExcelColumn label={displayName} value={dBKey} />
                                                );
                                            }
                                        )
                                    }
                                </ExcelSheet>
                            </ExcelFile>
                        </Row>
                    </div>
                }

            </div>
        }
        else {
            rTable = <div></div>
            mawbDiv = <div></div>
        }


        let currentReceiveContainer;
        try {
            if (typeof (this.state.receiveDataRowData.Container) !== 'undefined' && this.props.InventoryLotContainerData != null) {

                currentReceiveContainer = this.state.receiveDataRowData.Container
            }
            else {
                currentReceiveContainer = ''
            }
        }
        catch (e) {
            currentReceiveContainer = ''
        }



        let receiveDrawer;
        if (this.props.isInventorySkuRecoveryLoading) {
            receiveDrawer = <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>
        }

        else if (!this.state.showReceiveDrawer) {
            receiveDrawer = <div></div>
        }
        else if (Object.keys(this.state.LocationDict).length > 0 && typeof (this.state.LocationDict) !== 'undefined' && this.state.LocationDict != null) {
            let caseCountArray;
            let unitCountArray;
            let palletCount = 0;

            if (Object.keys(this.state.ReceivePalletDict).length > 0 && typeof (this.state.ReceivePalletDict) !== 'undefined' && this.state.ReceivePalletDict != null) {

                this.state.ReceivePalletDict.forEach(pallet => {
                    let palletQty = Number(pallet.Qty);
                    palletCount += palletQty;

                });

                const caseKeys = ['Qty'];
                const caseCounts = this.state.ReceivePalletDict.reduce((acc, curr) => {
                    let palletQty = Number(curr.Qty);

                    curr.Cases.forEach(caseItem => {
                        let caseQty = Number(caseItem.Qty);
                        let caseSkuID = caseItem.SkuID;

                        acc[caseSkuID] = acc[caseSkuID] || Object.assign(...caseKeys.map(k => ({ [k]: 0 })));
                        caseKeys.forEach(k => acc[caseSkuID][k] += caseQty * palletQty);
                    });

                    return acc;
                }, {});

                caseCountArray = caseCounts;

                const skuKeys = ['Units'];
                const skuCounts = this.state.ReceivePalletDict.reduce((acc, curr) => {
                    let palletQty = Number(curr.Qty);

                    curr.Cases.forEach(caseItem => {
                        let caseQty = Number(caseItem.Qty);

                        caseItem.Skus.forEach(sku => {
                            let skuQty = Number(sku.Units);
                            let skuSkuID = sku.SkuID;

                            acc[skuSkuID] = acc[skuSkuID] || Object.assign(...skuKeys.map(k => ({ [k]: 0 })));
                            skuKeys.forEach(k => acc[skuSkuID][k] += skuQty * caseQty * palletQty);
                        });
                    });

                    return acc;
                }, {});

                unitCountArray = skuCounts;

            }
            else {
                palletCount = 0
                caseCountArray = [{}]
                unitCountArray = {}
            }


            receiveDrawer = <ReceiveContainerDrawer {...this.props} state={this.state} onChange={this.onChangeReceiveDrawer} onClickAddPallet={this.onClickAddPallet} onChangePallet={this.onChangePallet} onClickAddCase={this.onClickAddCase} onClickAddSku={this.onClickAddSku} onChangeCase={this.onChangeCase} onChangeCaseSKU={this.onChangeCaseSKU} onChangeSku={this.onChangeSku} onClickDuplicatePallet={this.onClickDuplicatePallet} onClickDuplicateCase={this.onClickDuplicateCase} onClickDeletePallet={this.onClickDeletePalletRecieve} onClickDeleteCase={this.onClickDeleteCaseRecieve} onClickDeleteSku={this.onClickDeleteSkuRecieve} onChangeReceiveDate={this.onChangeReceiveDate} onChangeLocation={this.onChangeReceiveDrawerLocations} onClickAddLocation={this.onClickAddLocation} onClickRemoveFromAdd={this.onClickRemoveFromAdd} confirm={this.onClickReceiveDrawerSave} close={this.closeReceiveDrawer} onClickAddMissing={this.onClickAddMissing} onChangePalletized={this.onChangePalletized} masterLabel={currentReceiveContainer} caseCounts={caseCountArray} unitCounts={unitCountArray} palletCount={palletCount} />
        }



        let containerDrawer;
        if (this.props.isInventoryContainerSkuLoading) {
            containerDrawer = <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>
        }
        else {
            containerDrawer = <AddToContainerDrawer {...this.props} state={this.state} onChangeAddToContainer={this.onChangeAddToContainer} onClickTree={this.onClickTree} onClickEditSku={this.onClickEditSku} onChangeEditSku={this.onChangeEditSku} onConfirmEditSku={this.onConfirmEditSku} onCancelEditSku={this.onCancelEditSku} onClickDeleteSku={this.onClickDeleteSku} onChangeUnits={this.onChangeUnits} onChangeCases={this.onChangeCases} onChangePallets={this.onChangePallets} onChangeNotes={this.onChangeNotes} onChangeCustomerRef={this.onChangeCustomerRef} onChangeProject={this.onChangeProject} confirm={this.onClickConfirmAddToContainer} close={this.closeAddToContainerDrawer} />
        }

        return (
            <div>
                <h3>
                    Inventory Management - Inbound
                </h3>
                {hideShowSearch}
                {searchDiv}
                {mawbDiv}
                {rTable}



                <AddNewContainerDrawer {...this.props} state={this.state} onChangeAddNewContainer={this.onChangeAddNewContainer} confirm={this.onClickConfirmAddNewContainer} close={this.closeAddNewDrawer} />

                <AddNewContainerEditableDrawer {...this.props} state={this.state} onChangeAddNewContainer={this.onChangeAddNewContainer} confirm={this.onClickConfirmAddNewContainer} close={this.closeAddNewDrawer} />


                {containerDrawer}

                {receiveDrawer}


                <NewBarcodesModal {...this.props} state={this.state}
                    newBarcodeModalShown={this.state.newBarcodeModalShown}
                    closePrintBarcodes={this.closePrintBarcodes}
                />

            </div>
        );

    }
}

export default withRouter(Inbound);