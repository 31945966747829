import React, {
    PureComponent
} from 'react';
import { Modal, Button, ControlLabel, Col, FormGroup, InputGroup, InputPicker, Panel, Divider, Input, IconButton, Checkbox } from 'rsuite';
import DebouncedButton from '../../../componentsGeneral/Rsuite/Button/DebouncedButton';

const mountRef = React.createRef();

function MovePalletModal({
    movePalletModalShown,
    handleSavePalletModal,
    handleClosePalletModal,
    onChangePalletModal,
    state, ...props

}) {
    return (

        <div className="modal-container">

            <Modal backdrop={'static'} keyboard={false} show={movePalletModalShown} onHide={handleClosePalletModal} style={{ zIndex: '9999999999999' }}>
                <Modal.Header>
                    <Modal.Title>Move Warehouse Location</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <FormGroup>
                        <InputPicker
    preventOverflow
                            container={() => mountRef.current}
                            // placeholder={label}
                            placeholder={'Location...'}
                            value={state.movePalletWarehouseLocationID}
                            onChange={(e) => { onChangePalletModal(e) }}
                            data={props.WarehouseLocationsData}
                            block
                            cleanable={true}
                        />
                    </FormGroup>




                </Modal.Body>
                <Modal.Footer>
                    <DebouncedButton onClick={handleSavePalletModal} buttonText="Save" />
                    <Button onClick={handleClosePalletModal} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>



    );
}


export default MovePalletModal;

