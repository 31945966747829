import { connect } from 'react-redux';
import React, { Component } from 'react';
import LotSkuDetail from './LotSkuDetail'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as userActions from '../../../../redux/actions/actionsUser';
import * as inventoryActions from '../../../../redux/actions/inventoryActions'
import { queryStringParse, formatDateForAPI, formatStringForAPI } from '../../../../resources/logic/functions';


const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isInventorySkuWarehouseLocationLoading: store.inventoryReducer.isInventorySkuWarehouseLocationLoading,
    InventorySkuWarehouseLocationData: store.inventoryReducer.InventorySkuWarehouseLocationData,

    isInventorySkuRecoveryLoading: store.inventoryReducer.isInventorySkuRecoveryLoading,
    InventorySkuRecoveryData: store.inventoryReducer.InventorySkuRecoveryData,


    isInventorySkuRecoveryHistoryLoading: store.inventoryReducer.isInventorySkuRecoveryHistoryLoading,
    InventorySkuRecoveryHistoryData: store.inventoryReducer.InventorySkuRecoveryHistoryData,

    isInventorySkuWarehouseLocationHistoryLoading: store.inventoryReducer.isInventorySkuWarehouseLocationHistoryLoading,
    InventorySkuWarehouseLocationHistoryData: store.inventoryReducer.InventorySkuWarehouseLocationHistoryData,

    isInventoryLoadSkuLoading: store.inventoryReducer.isInventoryLoadSkuLoading,
    InventoryLoadSkuData: store.inventoryReducer.InventoryLoadSkuData,


    isWarehouseLocationsLoading: store.userServicesReducer.isWarehouseLocationsLoading,
    WarehouseLocationsData: store.userServicesReducer.WarehouseLocationsData,

    InventorySkuWarehouseLocationPostSuccessful: store.inventoryReducer.InventorySkuWarehouseLocationPostSuccessful,

    InventorySkuPostSuccessful: store.inventoryReducer.InventorySkuPostSuccessful,
    SkuID: store.inventoryReducer.SkuID,

    isInventoryCustomerRefLoading: store.inventoryReducer.isInventoryCustomerRefLoading,
    InventoryCustomerRefData: store.inventoryReducer.InventoryCustomerRefData,

    isInventoryProjectLoading: store.inventoryReducer.isInventoryProjectLoading,
    InventoryProjectData: store.inventoryReducer.InventoryProjectData,


    isInventorySkuWarehouseLoading: store.inventoryReducer.isInventorySkuWarehouseLoading,
    InventorySkuWarehouseData: store.inventoryReducer.InventorySkuWarehouseData,

    isInventorySkuWarehouseGroupedLoading: store.inventoryReducer.isInventorySkuWarehouseGroupedLoading,
    InventorySkuWarehouseGroupedData: store.inventoryReducer.InventorySkuWarehouseGroupedData,


    isInventoryLoadLotLoading: store.inventoryReducer.isInventoryLoadLotLoading,
    InventoryLoadLotData: store.inventoryReducer.InventoryLoadLotData,


    isInventorySkuRefLoading: store.inventoryReducer.isInventorySkuRefLoading,
    InventorySkuRefData: store.inventoryReducer.InventorySkuRefData,


    isInventoryCaseWarehouseLoading: store.inventoryReducer.isInventoryCaseWarehouseLoading,
    InventoryCaseWarehouseData: store.inventoryReducer.InventoryCaseWarehouseData,



    isInventoryCaseHistoryLoading: store.inventoryReducer.isInventoryCaseHistoryLoading,
    InventoryCaseHistoryData: store.inventoryReducer.InventoryCaseHistoryData,

    isInventoryCaseSkusHistoryLoading: store.inventoryReducer.isInventoryCaseSkusHistoryLoading,
    InventoryCaseSkusHistoryData: store.inventoryReducer.InventoryCaseSkusHistoryData,

    isInventoryLoadCasesHistoryLoading: store.inventoryReducer.isInventoryLoadCasesHistoryLoading,
    InventoryLoadCasesHistoryData: store.inventoryReducer.InventoryLoadCasesHistoryData,



    isInventoryUnitWarehouseGroupedLoading: store.inventoryReducer.isInventoryUnitWarehouseGroupedLoading,
    InventoryUnitWarehouseGroupedData: store.inventoryReducer.InventoryUnitWarehouseGroupedData,

    isInventoryCaseWarehouseGroupedLoading: store.inventoryReducer.isInventoryCaseWarehouseGroupedLoading,
    InventoryCaseWarehouseGroupedData: store.inventoryReducer.InventoryCaseWarehouseGroupedData,

    isInventoryPalletWarehouseGroupedLoading: store.inventoryReducer.isInventoryPalletWarehouseGroupedLoading,
    InventoryPalletWarehouseGroupedData: store.inventoryReducer.InventoryPalletWarehouseGroupedData,


});

const mapDispatchToProps = dispatch =>
({
    userWarehouseLocationsGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(userActions.userWarehouseLocationsGet(_email, _authToken, _WarehouseID, _CustomerID))
    },

    inventorySkuWarehouseLocGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID) => {
        dispatch(inventoryActions.inventorySkuWarehouseLocGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID))
    },
    inventorySkuRecoverGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container,) => {
        dispatch(inventoryActions.inventorySkuRecoverGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container,))
    },
    inventorySkuRecoverHistoryGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container,) => {
        dispatch(inventoryActions.inventorySkuRecoverHistoryGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container,))
    },
    inventorySkuWarehouseLocationHistoryGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID) => {
        dispatch(inventoryActions.inventorySkuWarehouseLocationHistoryGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID))
    },
    inventoryLoadSkuGet: (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _SkuID) => {
        dispatch(inventoryActions.inventoryLoadSkuGet(_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _SkuID))
    },
    inventorySkuWarehouseLocationPost: (_email, _authToken, _SkuID, _SkuWarehouseLocationID, _WarehouseLocationID, _Units, _Cases, _Pallets, _Oversize, _PreviousWarehouseLocationID, _WarehouseID, _CustomerID, _SkuRecovery, _RecoveryContainerID, _RecoveryDate, _SkuDescription) => {
        dispatch(inventoryActions.inventorySkuWarehouseLocationPost(_email, _authToken, _SkuID, _SkuWarehouseLocationID, _WarehouseLocationID, _Units, _Cases, _Pallets, _Oversize, _PreviousWarehouseLocationID, _WarehouseID, _CustomerID, _SkuRecovery, _RecoveryContainerID, _RecoveryDate, _SkuDescription))
    },

    inventorySkuPost: (_email, _authToken, _SkuID, _SKU, _ContainerID, _WarehouseID, _CustomerID, _CurrentSkuStatusID, _CustomerRefID, _CustomerRef, _ProjectID, _Project, _Notes) => {
        dispatch(inventoryActions.inventorySkuPost(_email, _authToken, _SkuID, _SKU, _ContainerID, _WarehouseID, _CustomerID, _CurrentSkuStatusID, _CustomerRefID, _CustomerRef, _ProjectID, _Project, _Notes))
    },

    inventoryCustomerRefGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(inventoryActions.inventoryCustomerRefGet(_email, _authToken, _WarehouseID, _CustomerID))
    },


    inventoryProjectGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(inventoryActions.inventoryProjectGet(_email, _authToken, _WarehouseID, _CustomerID))
    },



    inventoryLotSkuWarehouseLocationGet: (_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _CaseOrUnitSkuID) => {
        dispatch(inventoryActions.inventoryLotSkuWarehouseLocationGet(_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _CaseOrUnitSkuID))
    },

    inventorySkuWarehouseLocationGroupedGet: (_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _CaseOrUnitSkuID) => {
        dispatch(inventoryActions.inventorySkuWarehouseLocationGroupedGet(_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _CaseOrUnitSkuID))
    },


    inventoryLoadLotGet: (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _LoadStatusID, _ContainerID, _Container, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _CustomerRefID, _PickupDateStart, _PickupDateEnd, _DeliveryDateStart, _DeliveryDateEnd, _Carrier, _BOL, _Trailer, _Seal, _DestinationID) => {
        dispatch(inventoryActions.inventoryLoadLotGet(_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _LoadStatusID, _ContainerID, _Container, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _CustomerRefID, _PickupDateStart, _PickupDateEnd, _DeliveryDateStart, _DeliveryDateEnd, _Carrier, _BOL, _Trailer, _Seal, _DestinationID))
    },




    inventorySkuRefGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _SkuStatusID, _ProjectID, _SkuLotCode, _SkuSerialNumber) => {
        dispatch(inventoryActions.inventorySkuRefGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _SkuStatusID, _ProjectID, _SkuLotCode, _SkuSerialNumber))
    },

    inventoryCaseWarehouseLocationGet: (_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd) => {
        dispatch(inventoryActions.inventoryCaseWarehouseLocationGet(_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd))
    },




    inventoryCaseHistoryGet: (_email, _authToken, _WarehouseID, _CustomerID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _ContainerID) => {
        dispatch(inventoryActions.inventoryCaseHistoryGet(_email, _authToken, _WarehouseID, _CustomerID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _ContainerID))
    },

    inventoryLoadCasesHistoryGet: (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID,) => {
        dispatch(inventoryActions.inventoryLoadCasesHistoryGet(_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID,))
    },

    inventoryCaseSkusHistoryGet: (_email, _authToken, _WarehouseID, _CustomerID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _UnitSkuID, _ContainerID) => {
        dispatch(inventoryActions.inventoryCaseSkusHistoryGet(_email, _authToken, _WarehouseID, _CustomerID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _UnitSkuID, _ContainerID))
    },


    inventoryUnitWarehouseLocationGroupedGet: (_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _CaseOrUnitSkuID) => {
        dispatch(inventoryActions.inventoryUnitWarehouseLocationGroupedGet(_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _CaseOrUnitSkuID))
    },

    inventoryCaseWarehouseLocationGroupedGet: (_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd) => {
        dispatch(inventoryActions.inventoryCaseWarehouseLocationGroupedGet(_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd))
    },

    inventoryPalletWarehouseLocationGroupedGet: (_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _PalletOversize) => {
        dispatch(inventoryActions.inventoryPalletWarehouseLocationGroupedGet(_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _PalletOversize))
    },


});

class LotSkuDetailContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleUrlParam();

        // this.props.inventoryCustomerRefGet( this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection )
        // this.props.inventoryProjectGet( this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection )
    }

    componentDidUpdate(prevProps, prevState) {
        let shouldHandleUrlParam = false;

        if (prevProps.location.search !== this.props.location.search) {
            shouldHandleUrlParam = true;
        }
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            shouldHandleUrlParam = true;
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            shouldHandleUrlParam = true;
        }
        if (prevProps.InventorySkuWarehouseLocationPostSuccessful !== this.props.InventorySkuWarehouseLocationPostSuccessful && this.props.InventorySkuWarehouseLocationPostSuccessful) {
            shouldHandleUrlParam = true;
        }
        if (prevProps.InventorySkuPostSuccessful !== this.props.InventorySkuPostSuccessful && this.props.InventorySkuPostSuccessful) {
            shouldHandleUrlParam = true;
        }

        if (shouldHandleUrlParam) {
            this.handleUrlParam();
        }

    }



    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramSku = formatStringForAPI(values.sku)
        }
        catch (e) {
            var paramSku = null
        }
        try {
            var paramSkuID = formatStringForAPI(values.skuid)
        }
        catch (e) {
            var paramSkuID = null
        }

        // try {
        //     var paramCaseSkuID = formatStringForAPI(values.caseskuid)
        // }
        // catch (e) {
        //     var paramCaseSkuID = null
        // }

        this.props.inventorySkuRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, paramSkuID, null, null, null, null, null)
        this.props.inventorySkuWarehouseLocationGroupedGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, paramSkuID)
        this.props.inventoryLotSkuWarehouseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, paramSkuID)

        this.props.inventoryCaseWarehouseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, null, null, null, null, null, null, null, null, null, null, paramSkuID, null, null, null, null, null, null, null)

        this.props.userWarehouseLocationsGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection);




        this.props.inventoryPalletWarehouseLocationGroupedGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, null, null, null, null, null, null, null, null, null, null, paramSkuID, null, null, null, null, null, null, null, null)
        this.props.inventoryCaseWarehouseLocationGroupedGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, null, null, null, null, null, null, null, null, null, null, paramSkuID, null, null, null, null, null, null, null)
        this.props.inventoryUnitWarehouseLocationGroupedGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, null, null, null, null, null, null, null, null, null, null, null, paramSkuID, null, null, null, null, null, null, null, null)



    }



    render() {

        return (

            <LotSkuDetail
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}

                userWarehouseLocationsGet={this.props.userWarehouseLocationsGet}
                isWarehouseLocationsLoading={this.props.isWarehouseLocationsLoading}
                WarehouseLocationsData={this.props.WarehouseLocationsData}

                isInventorySkuWarehouseLocationLoading={this.props.isInventorySkuWarehouseLocationLoading}
                InventorySkuWarehouseLocationData={this.props.InventorySkuWarehouseLocationData}

                isInventorySkuRecoveryLoading={this.props.isInventorySkuRecoveryLoading}
                InventorySkuRecoveryData={this.props.InventorySkuRecoveryData}

                isInventorySkuRecoveryHistoryLoading={this.props.isInventorySkuRecoveryHistoryLoading}
                InventorySkuRecoveryHistoryData={this.props.InventorySkuRecoveryHistoryData}

                isInventorySkuWarehouseLocationHistoryLoading={this.props.isInventorySkuWarehouseLocationHistoryLoading}
                InventorySkuWarehouseLocationHistoryData={this.props.InventorySkuWarehouseLocationHistoryData}

                inventoryLoadSkuGet={this.props.inventoryLoadSkuGet}
                isInventoryLoadSkuLoading={this.props.isInventoryLoadSkuLoading}
                InventoryLoadSkuData={this.props.InventoryLoadSkuData}

                InventorySkuWarehouseLocationPostSuccessful={this.props.InventorySkuWarehouseLocationPostSuccessful}
                inventorySkuWarehouseLocationPost={this.props.inventorySkuWarehouseLocationPost}


                inventorySkuPost={this.props.inventorySkuPost}
                InventorySkuPostSuccessful={this.props.InventorySkuPostSuccessful}

                inventoryCustomerRefGet={this.props.inventoryCustomerRefGet}
                isInventoryCustomerRefLoading={this.props.isInventoryCustomerRefLoading}
                InventoryCustomerRefData={this.props.InventoryCustomerRefData}

                inventoryProjectGet={this.props.inventoryProjectGet}
                isInventoryProjectLoading={this.props.isInventoryProjectLoading}
                InventoryProjectData={this.props.InventoryProjectData}




                isInventorySkuWarehouseLoading={this.props.isInventorySkuWarehouseLoading}
                InventorySkuWarehouseData={this.props.InventorySkuWarehouseData}

                isInventorySkuWarehouseGroupedLoading={this.props.isInventorySkuWarehouseGroupedLoading}
                InventorySkuWarehouseGroupedData={this.props.InventorySkuWarehouseGroupedData}


                isInventoryLoadLotLoading={this.props.isInventoryLoadLotLoading}
                InventoryLoadLotData={this.props.InventoryLoadLotData}



                isInventorySkuRefLoading={this.props.isInventorySkuRefLoading}
                InventorySkuRefData={this.props.InventorySkuRefData}


                isInventoryCaseWarehouseLoading={this.props.isInventoryCaseWarehouseLoading}
                InventoryCaseWarehouseData={this.props.InventoryCaseWarehouseData}


                isInventoryCaseHistoryLoading={this.props.isInventoryCaseHistoryLoading}
                InventoryCaseHistoryData={this.props.InventoryCaseHistoryData}


                isInventoryCaseSkusHistoryLoading={this.props.isInventoryCaseSkusHistoryLoading}
                InventoryCaseSkusHistoryData={this.props.InventoryCaseSkusHistoryData}

                isInventoryLoadCasesHistoryLoading={this.props.isInventoryLoadCasesHistoryLoading}
                InventoryLoadCasesHistoryData={this.props.InventoryLoadCasesHistoryData}


                isInventoryUnitWarehouseGroupedLoading={this.props.isInventoryUnitWarehouseGroupedLoading}
                InventoryUnitWarehouseGroupedData={this.props.InventoryUnitWarehouseGroupedData}

                isInventoryCaseWarehouseGroupedLoading={this.props.isInventoryCaseWarehouseGroupedLoading}
                InventoryCaseWarehouseGroupedData={this.props.InventoryCaseWarehouseGroupedData}

                isInventoryPalletWarehouseGroupedLoading={this.props.isInventoryPalletWarehouseGroupedLoading}
                InventoryPalletWarehouseGroupedData={this.props.InventoryPalletWarehouseGroupedData}

            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LotSkuDetailContainer));



