/* ################################### SIGNOUT ACTIONS TYPES ################################### */
export const RESET_REDUX_STATE = 'RESET_REDUX_STATE';




/* ################################### VAS ACTIONS TYPES ################################### */
export const ON_ERROR = 'ON_ERROR';


// export const LABELGEN_POST_UPS_LABEL_CREATE_GET = 'LABELGEN_POST_UPS_LABEL_CREATE_GET';
// export const LABELGEN_POST_UPS_LABEL_CREATE_COMPLETE = 'LABELGEN_POST_UPS_LABEL_CREATE_COMPLETE';
// export const LABELGEN_POST_UPS_LABEL_CREATE_ERROR = 'LABELGEN_POST_UPS_LABEL_CREATE_ERROR';






export const LABELGEN_API_REQUEST_MAIN_GET = 'LABELGEN_API_REQUEST_MAIN_GET';
export const LABELGEN_API_REQUEST_MAIN_COMPLETE = 'LABELGEN_API_REQUEST_MAIN_COMPLETE';
export const LABELGEN_API_REQUEST_MAIN_ERROR = 'LABELGEN_API_REQUEST_MAIN_ERROR';





export const SHIP_REQUEST_GET = 'SHIP_REQUEST_GET';
export const SHIP_REQUEST_COMPLETE = 'SHIP_REQUEST_COMPLETE';
export const SHIP_REQUEST_ERROR = 'SHIP_REQUEST_ERROR';



export const BULK_SHIP_REQUEST_GET = 'BULK_SHIP_REQUEST_GET';
export const BULK_SHIP_REQUEST_COMPLETE = 'BULK_SHIP_REQUEST_COMPLETE';
export const BULK_SHIP_REQUEST_ERROR = 'BULK_SHIP_REQUEST_ERROR';

export const GET_LABEL_CREATE_HISTORY = 'GET_LABEL_CREATE_HISTORY';
export const COMPLETE_LABEL_CREATE_HISTORY = 'COMPLETE_LABEL_CREATE_HISTORY';
export const ERROR_LABEL_CREATE_HISTORY = 'ERROR_LABEL_CREATE_HISTORY';










export const LABELGEN_REQUEST_GET = 'LABELGEN_REQUEST_GET';
export const LABELGEN_REQUEST_COMPLETE = 'LABELGEN_REQUEST_COMPLETE';
export const LABELGEN_REQUEST_ERROR = 'LABELGEN_REQUEST_ERROR';

export const LABELGEN_RESPONSE_PACKAGE_LABELS_GET = 'LABELGEN_RESPONSE_PACKAGE_LABELS_GET';
export const LABELGEN_RESPONSE_PACKAGE_LABELS_COMPLETE = 'LABELGEN_RESPONSE_PACKAGE_LABELS_COMPLETE';
export const LABELGEN_RESPONSE_PACKAGE_LABELS_ERROR = 'LABELGEN_RESPONSE_PACKAGE_LABELS_ERROR';

export const LABELGEN_REQUEST_INWORK_GET = 'LABELGEN_REQUEST_INWORK_GET';
export const LABELGEN_REQUEST_INWORK_COMPLETE = 'LABELGEN_REQUEST_INWORK_COMPLETE';
export const LABELGEN_REQUEST_INWORK_ERROR = 'LABELGEN_REQUEST_INWORK_ERROR';

export const LABELGEN_REQUEST_PACKAGE_GET = 'LABELGEN_REQUEST_PACKAGE_GET';
export const LABELGEN_REQUEST_PACKAGE_COMPLETE = 'LABELGEN_REQUEST_PACKAGE_COMPLETE';
export const LABELGEN_REQUEST_PACKAGE_ERROR = 'LABELGEN_REQUEST_PACKAGE_ERROR';

export const LABELGEN_POST_REQUEST_PACKAGE_GET = 'LABELGEN_POST_REQUEST_PACKAGE_GET';
export const LABELGEN_POST_REQUEST_PACKAGE_COMPLETE = 'LABELGEN_POST_REQUEST_PACKAGE_COMPLETE';
export const LABELGEN_POST_REQUEST_PACKAGE_ERROR = 'LABELGEN_POST_REQUEST_PACKAGE_ERROR';

export const LABELGEN_REQUEST_PACKAGE_PRODUCTINFO_GET = 'LABELGEN_REQUEST_PACKAGE_PRODUCTINFO_GET';
export const LABELGEN_REQUEST_PACKAGE_PRODUCTINFO_COMPLETE = 'LABELGEN_REQUEST_PACKAGE_PRODUCTINFO_COMPLETE';
export const LABELGEN_REQUEST_PACKAGE_PRODUCTINFO_ERROR = 'LABELGEN_REQUEST_PACKAGE_PRODUCTINFO_ERROR';

export const LABELGEN_POST_REQUEST_PACKAGE_PRODUCTINFO_GET = 'LABELGEN_POST_REQUEST_PACKAGE_PRODUCTINFO_GET';
export const LABELGEN_POST_REQUEST_PACKAGE_PRODUCTINFO_COMPLETE = 'LABELGEN_POST_REQUEST_PACKAGE_PRODUCTINFO_COMPLETE';
export const LABELGEN_POST_REQUEST_PACKAGE_PRODUCTINFO_ERROR = 'LABELGEN_POST_REQUEST_PACKAGE_PRODUCTINFO_ERROR';

export const LABELGEN_POST_REQUEST_GET = 'LABELGEN_POST_REQUEST_GET';
export const LABELGEN_POST_REQUEST_COMPLETE = 'LABELGEN_POST_REQUEST_COMPLETE';
export const LABELGEN_POST_REQUEST_ERROR = 'LABELGEN_POST_REQUEST_ERROR';



export const LABELGEN_CUSTOMERWAREHOUSE_DEFAULTVALUES_GET = 'LABELGEN_CUSTOMERWAREHOUSE_DEFAULTVALUES_GET';
export const LABELGEN_CUSTOMERWAREHOUSE_DEFAULTVALUES_COMPLETE = 'LABELGEN_CUSTOMERWAREHOUSE_DEFAULTVALUES_COMPLETE';
export const LABELGEN_CUSTOMERWAREHOUSE_DEFAULTVALUES_ERROR = 'LABELGEN_CUSTOMERWAREHOUSE_DEFAULTVALUES_ERROR';


export const LABELGEN_CUSTOMERWAREHOUSE_USERSAVEDVALUES_GET = 'LABELGEN_CUSTOMERWAREHOUSE_USERSAVEDVALUES_GET';
export const LABELGEN_CUSTOMERWAREHOUSE_USERSAVEDVALUES_COMPLETE = 'LABELGEN_CUSTOMERWAREHOUSE_USERSAVEDVALUES_COMPLETE';
export const LABELGEN_CUSTOMERWAREHOUSE_USERSAVEDVALUES_ERROR = 'LABELGEN_CUSTOMERWAREHOUSE_USERSAVEDVALUES_ERROR';

export const LABELGEN_POST_CUSTOMERWAREHOUSE_USERSAVEDVALUES_GET = 'LABELGEN_POST_CUSTOMERWAREHOUSE_USERSAVEDVALUES_GET';
export const LABELGEN_POST_CUSTOMERWAREHOUSE_USERSAVEDVALUES_COMPLETE = 'LABELGEN_POST_CUSTOMERWAREHOUSE_USERSAVEDVALUES_COMPLETE';
export const LABELGEN_POST_CUSTOMERWAREHOUSE_USERSAVEDVALUES_ERROR = 'LABELGEN_POST_CUSTOMERWAREHOUSE_USERSAVEDVALUES_ERROR';

export const LABELGEN_PORT_REF_GET = 'LABELGEN_PORT_REF_GET';
export const LABELGEN_PORT_REF_COMPLETE = 'LABELGEN_PORT_REF_COMPLETE';
export const LABELGEN_PORT_REF_ERROR = 'LABELGEN_PORT_REF_ERROR';



export const LABELGEN_EXTERNAL_UPS_TRACKING_GET = 'LABELGEN_EXTERNAL_UPS_TRACKING_GET';
export const LABELGEN_EXTERNAL_UPS_TRACKING_COMPLETE = 'LABELGEN_EXTERNAL_UPS_TRACKING_COMPLETE';
export const LABELGEN_EXTERNAL_UPS_TRACKING_ERROR = 'LABELGEN_EXTERNAL_UPS_TRACKING_ERROR';
