import React from 'react';
import PropTypes from 'prop-types';
import {
    Drawer,
    Button,
    ButtonToolbar,
    Form,
    FormControl,
    FormGroup,
    ControlLabel,
    DatePicker,
    InputPicker,
    InputGroup,
    Row,
    Input

} from 'rsuite';
import { textNumbersSymbolsInputmask, limitCharacterLength, lettersNumbersDashesSpacesInputMask, textStringInputMask } from '../../../../resources/logic/inputMasks';
import DebouncedButton from '../../../componentsGeneral/Rsuite/Button/DebouncedButton';

const styles = {
    marginBottom: 10,
    width: '100%'
};

const mountRef = React.createRef();

const EditCaseDrawer = ({ show, onChangeEditCase, confirm, close, state, ...props }) => (
    
    show === false ?
    <div></div>:

    <Drawer show={show} onHide={close} style={props.isMobile ? { width: '350px' } : { width: '650px' }}    >
        <Drawer.Header>
            <Drawer.Title>Edit Case</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body> <div ref={mountRef}>

            <Form fluid>


                <div style={{ marginBottom: '5px', width: '100%' }}>
                    <InputPicker
    preventOverflow
                        container={() => mountRef.current}
                        block
                        creatable
                        placeholder={'Customer Ref...'}
                        value={state.CustomerRef}
                        onChange={(e) => { onChangeEditCase(e, 'CustomerRef') }}
                        data={props.InventoryCustomerRefData}
                        cleanable={true}
                    />
                </div>

                <div style={{ marginBottom: '5px', width: '100%' }}>
                    <InputPicker
    preventOverflow
                        container={() => mountRef.current}
                        block
                        creatable
                        placeholder={'Project...'}
                        value={state.Project}
                        onChange={(e) => { onChangeEditCase(e, 'Project') }}
                        data={props.InventoryProjectData}
                        cleanable={true}
                    />
                </div>


         
                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                        <InputGroup.Addon>Lot Code:</InputGroup.Addon>
                        <Input value={state.LotCode} className={'custom-input-number'} onChange={(e) => onChangeEditCase(e, 'LotCode')} scrollable={false} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                    </InputGroup>
       
                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                        <InputGroup.Addon>Serial Number:</InputGroup.Addon>
                        <Input value={state.SerialNumber} className={'custom-input-number'} onChange={(e) => onChangeEditCase(e, 'SerialNumber')} scrollable={false} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                    </InputGroup>
            


                <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                    <InputGroup.Addon>Notes:</InputGroup.Addon>
                    <Input value={state.Notes} onChange={(e) => { onChangeEditCase(e, 'Notes') }} onInput={(e) => e.target.value = limitCharacterLength(textNumbersSymbolsInputmask(e), 400)} />
                </InputGroup>


                <FormGroup>
                    <ButtonToolbar>
                        <DebouncedButton onClick={confirm} buttonText="Submit" appearance="primary" />
                        <Button onClick={close} appearance="default">Cancel</Button>
                    </ButtonToolbar>
                </FormGroup>
            </Form>

        </div> </Drawer.Body>
        <Drawer.Footer>
            <br />
        </Drawer.Footer>

    </Drawer>

);


EditCaseDrawer.defaultProps = {
    show: false
};



export default EditCaseDrawer;


