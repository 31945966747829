import { state } from 'aws-cognito-redux-saga';
import FlatButton from 'material-ui/FlatButton';
import PropTypes from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/Button';
import { Link, withRouter } from 'react-router-dom';
import { customerConfig } from '../../../app/appCustomerConfig';
import { appSettings } from '../../../app/appSettings';

class HeaderComponent extends React.Component {
  static propTypes = {
    isSignedIn: PropTypes.string,
    signUpError: PropTypes.bool,
    signOut: PropTypes.func,
    signIn: PropTypes.func,
    signUp: PropTypes.func,
    auth: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      width: window.innerWidth,
    };

  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  signOut = () => {
    this.props.signOut()
    this.props.appSignOut()
    this.props.history.push(`/${customerConfig.customerUrl}/signin`)
  };





  render() {
    const { auth, location } = this.props
    const { width } = this.state;
    const isMobile = width <= appSettings.mobileWidth;
    const showSignInSignUp = location.pathname.includes(`/${customerConfig.customerUrl}`);


    let mobileHeader;
    mobileHeader =
      <header
        className="header fixed--header">
        <a
          className="logo">
          <img src={appSettings.smallLogo} />
        </a>


        <header className="header--left">
          {auth.isSignedIn !== state.AUTH_SUCCESS ? (
            <div>

            </div>
          ) : (
            <div>

              <div className="mockverticalMenuButton"   >
                <Button variant="light" size="sm" title="Hide/Show Menu"   >
                  <i className="fa fa-bars"></i>
                </Button>
              </div>

            </div>
          )}
        </header>

        <header className="header--right">
          {
            !showSignInSignUp ?
              <div></div>
              :

              auth.isSignedIn !== state.AUTH_SUCCESS ? (
                <div>
                  <FlatButton
                    containerElement={<Link to={`/${customerConfig.customerUrl}/signin`} />}
                    label="Sign In"
                    onClick={this.signIn}
                  />
                  {/* <FlatButton
                    containerElement={<Link to={`/${customerConfig.customerUrl}/signup`} />}
                    label="Sign Up"
                  // onClick={this.signIn}
                  /> */}
                </div>
              ) : (
                <div>
                  <FlatButton label="Sign Out" onClick={this.signOut} />
                </div>
              )}
        </header>
        {/* <div style={{ 'border-bottom': '#E78200', 'border-bottom-style': 'solid'}}></div> */}
        <div style={{ 'border-bottom-width': 'thick', 'border-bottom': '#3396E5', 'border-bottom-style': 'solid' }}></div>
      </header>


    let fullScreenHeader;
    fullScreenHeader =
      <header
        className="header fixed--header">
        <a className="logo">
          <img src={appSettings.logo} />
        </a>


        <header className="header--left">
          {auth.isSignedIn !== state.AUTH_SUCCESS ? (
            <div>

            </div>
          ) : (
            <div>

              <div className="mockverticalMenuButton"   >
                <Button variant="light" size="sm" title="Hide/Show Menu"   >
                  <i className="fa fa-bars"></i>
                </Button>
              </div>

            </div>
          )}
        </header>



        <header className="header--right">
          {
            !showSignInSignUp ?
              <div></div>
              :
              auth.isSignedIn !== state.AUTH_SUCCESS ? (
                <div>
                  <FlatButton
                    containerElement={<Link to={`/${customerConfig.customerUrl}/signin`} />}
                    label="Sign In"
                    onClick={this.signIn}
                  />
                  {/* <FlatButton
                    containerElement={<Link to={`/${customerConfig.customerUrl}/signup`} />}
                    label="Sign Up"
                  // onClick={this.signIn}
                  /> */}
                </div>
              ) : (


                <div>
                  {
                    appSettings.devEnvironment === true ?
                      "Test Environment                 "
                      :
                      ""
                  }

                  <i className="fa fa-user" />
                  {" " + auth.info.idToken.payload.email + " "}
                  <FlatButton label="Sign Out" onClick={this.signOut} />

                </div>
              )}
        </header>


        {/* <div style={{ 'border-bottom': '#E78200', 'border-bottom-style': 'solid'}}></div> */}
        <div style={{ 'border-bottom-width': 'thick', 'border-bottom': '#3396E5', 'border-bottom-style': 'solid' }}></div>


      </header>



    if (isMobile) {
      return mobileHeader;
    }
    else {
      return fullScreenHeader;
    }






  }
}







export default withRouter(HeaderComponent)