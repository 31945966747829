import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions/actionsUser';
import SignInUserAuth from './SignInUserAuth';



const mapStateToProps = store =>
({

    isAppSigningInLoading: store.userServicesReducer.isAppSigningInLoading,


    userSignInData: store.userServicesReducer.userSignInData,
    currentUserEmail: store.userServicesReducer.currentUserEmail,


    isUserWarehouseLoading: store.userServicesReducer.isUserWarehouseLoading,
    UserWarehouseData: store.userServicesReducer.UserWarehouseData,

    isUserCustomerLoading: store.userServicesReducer.isUserCustomerLoading,
    UserCustomerData: store.userServicesReducer.UserCustomerData,

    isUserModuleLoading: store.userServicesReducer.isUserModuleLoading,
    UserModuleData: store.userServicesReducer.UserModuleData,

    isUserOrganizationLoading: store.userServicesReducer.isUserOrganizationLoading,


});

const mapDispatchToProps = dispatch =>
({


    userWarehousesGet: (currentUserEmail, otherUsersID) => {
        dispatch(actions.userWarehousesGet(currentUserEmail, otherUsersID))
    },
    userOrganizationGet: (currentUserEmail, otherUsersID) => {
        dispatch(actions.userOrganizationGet(currentUserEmail, otherUsersID))
    },
    userCustomersGet: (currentUserEmail, otherUsersID) => {
        dispatch(actions.userCustomersGet(currentUserEmail, otherUsersID))
    },
    userModulesGet: (currentUserEmail, otherUsersID) => {
        dispatch(actions.userModulesGet(currentUserEmail, otherUsersID))
    },
    userLabelGenCarrierGet: (currentUserEmail, otherUsersID) => {
        dispatch(actions.userLabelGenCarrierGet(currentUserEmail, otherUsersID))
    },
    userLabelGenCarrierServiceGet: (currentUserEmail, otherUsersID) => {
        dispatch(actions.userLabelGenCarrierServiceGet(currentUserEmail, otherUsersID))
    },
});


class SignInUserAuthContainer extends Component {
    constructor(props) {
        super(props);

    }


    componentDidMount() {

    }







    render() {

        return (

            <SignInUserAuth
                isAppSigningInLoading={this.props.isAppSigningInLoading}

                userSignInData={this.props.userSignInData}
                currentUserEmail={this.props.currentUserEmail}


                userWarehousesGet={this.props.userWarehousesGet}
                userCustomersGet={this.props.userCustomersGet}
                userOrganizationGet={this.props.userOrganizationGet}
                userModulesGet={this.props.userModulesGet}
                userLabelGenCarrierGet={this.props.userLabelGenCarrierGet}

                userLabelGenCarrierServiceGet={this.props.userLabelGenCarrierServiceGet}

                isUserWarehouseLoading={this.props.isUserWarehouseLoading}
                isUserCustomerLoading={this.props.isUserCustomerLoading}
                isUserModuleLoading={this.props.isUserModuleLoading}
                isUserOrganizationLoading={this.props.isUserOrganizationLoading}

            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignInUserAuthContainer);




