import React, {
    PureComponent
} from 'react';
import { withRouter } from 'react-router-dom';

import RTableGeneral from '../../../componentsGeneral/Rsuite/Table/RTableGeneral';
import { Row, Col, Panel, Loader, Button, ControlLabel, InputGroup, Input } from 'rsuite';
import RSelectPicker from '../../../componentsGeneral/Rsuite/InputPicker/RSelectPicker';
import queryString from 'query-string';
import { queryStringParse, formatStringForAPI, formatDateForUrlSearch, formatNumberForAPI, convertNanToZero, isNumeric, formatDateTimeForUrlSearch, formatTrueFalseToBit, formatAllTextNumbersSymbolsForAPI } from '../../../../resources/logic/functions';
import RButtonHideShowFilter from '../../../componentsGeneral/Rsuite/Button/RButtonHideShowFilter'
import ReactExport from "react-export-excel";
import * as moment from 'moment'
import { containerStatus } from '../../../../app/appConfig';
import AddNewContainerDrawer from './AddNewContainerDrawer';
import AddNewContainerEditableDrawer from './AddNewContainerEditableDrawer';
import ReceiveContainerDrawer from './ReceiveContainerDrawer';
import RSearchInputNumberAsString from '../../../componentsGeneral/Rsuite/SearchInput/RSearchInputNumberAsString';
import AddToContainerDrawer from './AddToContainerDrawer';
import { InventoryContainerDataElements } from '../../../dataComponentsInventory/InventoryContainerDataElements';

//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")

const tableIdCol = "ContainerID"

const mountRef = React.createRef();

class Inbound extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            //Warehouse & Customer Arrays
            warehouseArray: {},
            customerArray: {},

            // Table Check Box
            checkedKeys: [],

            //Search Values
            searchContainer: '',
            searchSKU: '',
            searchStatus: null,

            isFilterShown: true,

            //Add New Container Values
            unsavedNewContainerChanges: false,
            Container: null,
            Notes: null,
            LclFcl: null,
            DeliveryAppointment: null,
            ETD: null,
            ETA: null,

            CustomerID: null,
            WarehouseID: null,

            //Add To Container Values
            EditContainerID: null,
            SKU: null,

            selectedType: null,
            selectedLabel: null,
            selectedID: null,
            // selectedCustomerID: null,
            // selectedWarehouseID: null,
            addNewText: null,

            newSkuUnits: null,
            newSkuCases: null,
            newSkuPallets: null,
            newSkuNotes: null,
            newSkuCustomerRef: null,
            newSkuProject: null,

            selectedSkuUnits: null,
            selectedSkuCases: null,
            selectedSkuPallets: null,
            selectedSkuNotes: null,
            selectedSkuCustomerRef: null,
            selectedSkuProject: null,

            //Receive Container Drawer
            ReceiveDict: {},
            LocationDict: {},
            unsavedReceiveChanges: false,
            showReceiveDrawer: false,
            ReceiveDrawerContainerID: null,
            ReceiveDrawerContainer: null,
            ReceiveDrawerNo: null,
            ReceiveDrawerPMC: null,
            ReceiveDrawerPickupBy: null,
            ReceiveDrawerTrips: null,
            ReceiveDrawerArrivalTime: null,
            ReceiveDrawerLFD: null,
            ReceiveDrawerArrivalAtWarehouse: null,
            ReceiveDrawerDeliveryTime: null,
            ReceiveDrawerNotes: null,
            ReceiveDate: null,

        };
        // Table Check Box
        this.handleCheckAll = this.handleCheckAll.bind(this);
        this.handleCheck = this.handleCheck.bind(this);

        // Table Sort
        this.handleSortColumn = this.handleSortColumn.bind(this);

        //Filter hide Show
        this.onClickIsFilterShown = this.onClickIsFilterShown.bind(this);
        this.onClickResetFilters = this.onClickResetFilters.bind(this);

        //Add new drawer
        this.closeAddNewDrawer = this.closeAddNewDrawer.bind(this);
        this.onClickConfirmAddNewContainer = this.onClickConfirmAddNewContainer.bind(this);
        this.setStateCloseAddNewDrawer = this.setStateCloseAddNewDrawer.bind(this)
        this.onClickAddNewContainer = this.onClickAddNewContainer.bind(this);

        //Add To Container Drawer
        this.resetAddToContainerState = this.resetAddToContainerState.bind(this);
        this.closeAddToContainerDrawer = this.closeAddToContainerDrawer.bind(this);
        this.onClickConfirmAddToContainer = this.onClickConfirmAddToContainer.bind(this);
        this.onClickDeleteSku = this.onClickDeleteSku.bind(this);


        this.onChangeUnits = this.onChangeUnits.bind(this);
        this.onChangeCases = this.onChangeCases.bind(this);
        this.onChangePallets = this.onChangePallets.bind(this);
        this.onChangeNotes = this.onChangeNotes.bind(this);
        this.onChangeCustomerRef = this.onChangeCustomerRef.bind(this);
        this.onChangeProject = this.onChangeProject.bind(this);

        this.onClickTableLinkEditContainer = this.onClickTableLinkEditContainer.bind(this);

        this.setNewContainerID = this.setNewContainerID.bind(this)

        //Receive drawer
        // this.onClickAdd = this.onClickAdd.bind(this);
        this.onChangeReceiveDrawer = this.onChangeReceiveDrawer.bind(this);

        this.onClickReceive = this.onClickReceive.bind(this);
        this.onClickAddMissing = this.onClickAddMissing.bind(this);
        this.onChangePalletized = this.onChangePalletized.bind(this);
        this.closeReceiveDrawer = this.closeReceiveDrawer.bind(this);
        this.onClickReceiveDrawerSave = this.onClickReceiveDrawerSave.bind(this);
        this.onChangeReceiveDate = this.onChangeReceiveDate.bind(this);

        this.onClickAddLocation = this.onClickAddLocation.bind(this);

        this.handleAddNewLocation = this.handleAddNewLocation.bind(this);
        this.onClickRemoveFromAdd = this.onClickRemoveFromAdd.bind(this);


        //Search Values
        this.onChangeSearchContainer = this.onChangeSearchContainer.bind(this);
        this.onChangeSearchSKU = this.onChangeSearchSKU.bind(this);
        this.onChangeSearchStatus = this.onChangeSearchStatus.bind(this);


    }


    // Table Check Box
    handleCheckAll(value, checked, data) {
        const checkedKeys = checked ? [] : data.map(item => item[tableIdCol]);
        this.setState({
            checkedKeys
        });
    }
    handleCheck(value, checked) {
        const { checkedKeys } = this.state;
        const nextCheckedKeys = checked
            ? [...checkedKeys, value]
            : checkedKeys.filter(item => item !== value);

        this.setState({
            checkedKeys: nextCheckedKeys
        });
    }

    // Table Sort
    saveSortTosessionStorage(sortColumn, sortType) {
        try {
            sessionStorage.setItem('sortcolumn', sortColumn)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortType', sortType)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortURLPathname', this.props.location.pathname)
        }
        catch (e) { }
    }
    loadSortFromStorage() {
        var sortColumn = null
        var sortType = null
        var pathName = null
        try {
            sortColumn = sessionStorage.getItem('sortcolumn')
        }
        catch (e) { }
        try {
            sortType = sessionStorage.getItem('sortType')
        }
        catch (e) { }
        try {
            pathName = sessionStorage.getItem('sortURLPathname')
        }
        catch (e) { }
        if (pathName === this.props.location.pathname) {
            
            this.handleSortColumn(sortColumn, sortType)
        }
    }
    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });
        this.saveSortTosessionStorage(sortColumn, sortType)
        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 100);
    }



    componentDidMount() {
        this.loadSortFromStorage()
        this.updateParamsFromURL()
        this.getsessionStorageData() //this will preserve menu selection
        this.loadWarehouseToState()
        this.loadCustomerToState()

        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.state.WarehouseID) !== 'number') {
            this.setState({
                WarehouseID: this.props.warehouseSelection
            })
        }

        if (typeof (this.props.customerSelection) === 'number' && typeof (this.state.CustomerID) !== 'number') {
            this.setState({
                CustomerID: this.props.customerSelection
            })
        }

        this.props.userWarehouseLocationsGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection);
    }

    updateParamsFromURL = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramContainer = queryStringParse(values.container)
        }
        catch (e) {
            var paramContainer = null
        }
        if (paramContainer != null) {
            this.onChangeSearchContainer(paramContainer)
        }


        try {
            var paramSKU = queryStringParse(values.sku)
        }
        catch (e) {
            var paramSKU = null
        }
        if (paramSKU != null) {
            this.onChangeSearchSKU(paramSKU)
        }

        try {
            var paramStatus = queryStringParse(values.containerstatus)
        }
        catch (e) {
            var paramStatus = null
        }
        if (paramStatus != null) {
            this.onChangeSearchStatus(paramStatus)
        }

    }


    setNewContainerID(newID) {
        this.setState({
            EditContainerID: newID
        });
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.setState({
                checkedKeys: []
            });
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.setState({
                checkedKeys: []
            });
        }

        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.state.WarehouseID) !== 'number') {
                this.setState({
                    WarehouseID: this.props.warehouseSelection
                })
            }
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            if (typeof (this.props.customerSelection) === 'number' && typeof (this.state.CustomerID) !== 'number') {
                this.setState({
                    CustomerID: this.props.customerSelection
                })
            }
        }


        if (prevState.CustomerID !== this.state.CustomerID) {
            if (typeof (this.state.CustomerID) === 'number' && typeof (this.state.WarehouseID) === 'number') {
                this.props.inventoryCustomerRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.WarehouseID, this.state.CustomerID)
                this.props.inventoryProjectGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.WarehouseID, this.state.CustomerID)

                this.props.userWarehouseLocationsGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.WarehouseID, this.state.CustomerID);
            }
        }

        if (prevState.WarehouseID !== this.state.WarehouseID) {
            if (typeof (this.state.CustomerID) === 'number' && typeof (this.state.WarehouseID) === 'number') {
                this.props.inventoryCustomerRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.WarehouseID, this.state.CustomerID)
                this.props.inventoryProjectGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.WarehouseID, this.state.CustomerID)

                this.props.userWarehouseLocationsGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.WarehouseID, this.state.CustomerID);
            }
        }






        if (prevProps.InventorySkuRecoveryData !== this.props.InventorySkuRecoveryData) {
            if (this.props.InventorySkuRecoveryData.length > 0) {
                this.preloadReceiveState()
            }
            else {
                window.alert('Nothing to receive.')
            }
        }

        if (prevProps.isInventoryContainerLoading === true && this.props.isInventoryContainerLoading === false && this.state.searchContainer !== '' && this.props.InventoryContainerData.length == 0) {
            if (this.state.showAddToContainerDrawer) {
            }
            else {
                this.addContainer(this.state.searchContainer)
            }
        }

        if (prevProps.InventoryContainerPostSuccessful !== this.props.InventoryContainerPostSuccessful && this.props.InventoryContainerPostSuccessful && this.props.isInventoryContainerLoading === false && this.state.showReceiveDrawer === false) {
            // try 
            this.props.inventoryContainerSkuGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.NewContainerId, null)
            this.setNewContainerID(this.props.NewContainerId)
            // }
            // catch (e) { }

            this.setStateCloseAddNewDrawer()

            try {
                var searchCont = formatStringForAPI(this.state.Container)
                this.props.history.push({
                    search:
                        'container=' + searchCont

                });
            }
            catch (e) { }

            // if (window.confirm('Build new container?')) {
            this.onClickAddToContainer()
            // }

            this.setInitialTreeSelection(this.props.NewContainerId, this.state.Container)
        }

        if (prevProps.InventoryContainerSkuPostSuccessful !== this.props.InventoryContainerSkuPostSuccessful && this.props.InventoryContainerSkuPostSuccessful) {
            try {
                this.props.inventoryContainerSkuGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.EditContainerID, null)
                // this.props.transCustomerRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection);
                // this.props.transProjectGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection);
            }
            catch (e) { }
        }


        if (prevProps.InventorySkuWarehouseLocationPostSuccessful !== this.props.InventorySkuWarehouseLocationPostSuccessful && this.props.InventorySkuWarehouseLocationPostSuccessful) {
            this.setReceiveDrawerToClose()
        }

    }

    loadWarehouseToState() {
        if (typeof (this.props.warehouseSelection) === 'number') {
            this.setState({
                WarehouseID: this.props.warehouseSelection
            });
        }
    }

    loadCustomerToState() {
        if (typeof (this.props.customerSelection) === 'number') {
            this.setState({
                CustomerID: this.props.customerSelection
            });
        }
    }

    getsessionStorageData() {
        try {
            let warehouseMenuList = JSON.parse(sessionStorage.getItem('AppConfig.WarehouseArray'));
            if (warehouseMenuList != null && warehouseMenuList != undefined) {
                this.setState({
                    warehouseArray: warehouseMenuList
                })
            }
        }
        catch (e) { }
        try {
            let customerMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CustomerArray'));
            if (customerMenuList != null && customerMenuList != undefined) {
                this.setState({
                    customerArray: customerMenuList
                })
            }
        }
        catch (e) { }
        try {
            let carrierMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CarrierArray'));
            if (carrierMenuList != null && carrierMenuList != undefined) {
                this.setState({
                    carrierArray: carrierMenuList
                })
            }
        }
        catch (e) { }
        try {
            let carrierServiceMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CarrierServiceArray'));
            if (carrierServiceMenuList != null && carrierServiceMenuList != undefined) {
                this.setState({
                    carrierServiceArray: carrierServiceMenuList
                })
            }
        }
        catch (e) { }
    }


    onClickIsFilterShown(e, isFilterShown) {
        if (isFilterShown) {
            this.setState({
                isFilterShown: false
            });
        }
        else {
            this.setState({
                isFilterShown: true
            });
        }
    }

    onClickResetFilters(e) {
        this.setState({
            isFilterShown: false
        });
        this.setState({
            searchContainer: '',
            searchSKU: '',
            searchStatus: null,
            searchCarrier: null,
            searchMinETADischargeStart: null,
            searchMinETADischargeEnd: null,
            searchPlannedPickupStart: null,
            searchPlannedPickupEnd: null,
            serachAssignedDriver: ''

        });
        this.props.history.push({
            search: ''
        });

        setTimeout(() => {
            this.setState({
                isFilterShown: true
            });
        }, 500);
    }

    onClickApplySearch(e) {
        this.setState({
            searchContainer: '',
            searchSKU: '',
        });


        if (this.props.warehouseSelection == null) {
            window.alert('Please select a warehouse and customer.')
        }
        else if (this.props.customerSelection == null) {
            window.alert('Please select a customer.')
        }
        else {
            try {
                var searchStatus = this.state.searchStatus
                this.props.history.push({
                    search:
                        'containerstatus=' + searchStatus

                });
            }
            catch (e) { }
        }
    }


    onSearchClearOtherFilters() {
        this.setState({
            searchStatus: null,
            searchCarrier: null,
            searchMinETADischargeStart: null,
            searchMinETADischargeEnd: null,
            searchPlannedPickupStart: null,
            searchPlannedPickupEnd: null,
            serachAssignedDriver: ''
        });

    }

    onChangeSearchContainer(e) {
        this.onSearchClearOtherFilters()
        this.setState({
            searchContainer: e,
            searchSKU: ''
        });
    }

    onChangeSearchSKU(e) {
        this.onSearchClearOtherFilters()
        this.setState({
            searchSKU: e,
            searchContainer: ''
        });
    }

    onChangeSearchStatus(e) {
        this.setState({
            searchStatus: e
        });
    }

    onChangeSearchCarrier(e) {
        this.setState({
            searchCarrier: e
        });
    }


    onClickDetails(e, rowData, history) {
        try {
            var paramID = rowData[tableIdCol]
            history.push({
                pathname: 'containerdetails',
                search: 'containerid=\'' + paramID + '\''
            });
        }
        catch (e) {
        }
    }







    //Receive Container drawer
    onClickReceive(e, rowData, history) {

        this.props.inventorySkuRecoverGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, null, null, rowData.ContainerID, null)


        this.setState({
            ReceiveDict: {},
            LocationDict: {},
            unsavedReceiveChanges: false,
            receiveDataRowData: rowData,
            showReceiveDrawer: true,
            ReceiveDrawerContainerID: rowData.ContainerID,
            WarehouseID: rowData.WarehouseID,
            CustomerID: rowData.CustomerID,
            ReceiveDate: null,

            //Add Evolve Data Values
            ReceiveDrawerContainer: rowData.Container,
            ReceiveDrawerNotes: rowData.Notes
        });
    }
    preloadReceiveState() {
        var receivedDict = {};
        for (let k in this.props.InventorySkuRecoveryData) {
            // receivedDict[this.props.InventorySkuRecoveryData[k].HouseID] = { 'Cases': this.props.InventorySkuRecoveryData[k].Cartons - this.props.InventorySkuRecoveryData[k].RecoveredPCS, 'Pallets': null, 'Notes': '', 'Customs': false }
            if (this.props.InventorySkuRecoveryData[k].SkuID === null) {
            }
            else {
                receivedDict[this.props.InventorySkuRecoveryData[k].SkuID] = { 'Units': null, 'Cases': null, 'Pallets': null, 'Notes': '', 'Customs': false, 'Oversize': false }
            }
        }

        var locationDict = {};
        for (let k in this.props.InventorySkuRecoveryData) {
            if (this.props.InventorySkuRecoveryData[k].SkuID === null) {
            }
            else {
                // locationDict[this.props.InventorySkuRecoveryData[k].SkuID] = [{'WarehouseLocationID': null, 'WarehouseID': null, 'Cases': null, 'Pallets': null,  }]
                locationDict[this.props.InventorySkuRecoveryData[k].SkuID] = [{ 'WarehouseLocationID': null, 'WarehouseID': null, 'Units': null, 'Cases': null, 'Pallets': null, 'Oversize': false }]
            }
        }

        try {
            var receivedDictLength = Object.keys(receivedDict).length
        }
        catch (e) {
            var receivedDictLength = 0
        }
        if (typeof (receivedDict) === 'undefined' || receivedDict === null || receivedDictLength === 0) {
            window.alert('No SKUs exist for Receiving.')
        }
        else {
            this.setState({
                unsavedReceiveChanges: false,
                ReceiveDict: receivedDict,
                LocationDict: locationDict
            });
        }
    }
    onChangeReceiveDrawer = (event, item, label) => {
        var currentReceiveDict = this.state.ReceiveDict
        var skuID = item.SkuID;

        currentReceiveDict[skuID][label] = event

        this.setState({
            unsavedReceiveChanges: true,
            ReceiveDict: currentReceiveDict
        });

    };

    onChangeReceiveDate(e) {
        this.setState({
            unsavedReceiveChanges: true,
            ReceiveDate: e
        });

    }

    // check if same location but oversize
    onChangeReceiveDrawerLocations = (event, item, locationitem, label, existinglocationname) => {

        if (label === 'WarehouseLocationID') {
            // find new warehouse name in existing state:
            //This will be changed to dropdown choice:
            if (this.state.LocationDict[item.SkuID].filter(item => item.WarehouseLocationID === event).length > 1) {
                window.alert('Duplicate location entry, please use existing.')
            }
            else if (this.state.LocationDict[item.SkuID].filter(item => item.WarehouseLocationID === event).length > 0) {
                var existingLoc = this.state.LocationDict[item.SkuID].filter(item => item.WarehouseLocationID === event)
                if (existingLoc[0]['Oversize'] === true) {
                    var newOversize = false
                }
                else {
                    var newOversize = true
                }
                //save warehouse name:
                var warehouseLocationDictUpdated = this.state.LocationDict
                var keyToUpdate = warehouseLocationDictUpdated[item.SkuID].findIndex(item => item.WarehouseLocationID === existinglocationname && item.Oversize === locationitem['Oversize'])

                warehouseLocationDictUpdated[item.SkuID][keyToUpdate][label] = event
                warehouseLocationDictUpdated[item.SkuID][keyToUpdate]['Oversize'] = newOversize

                this.setState({
                    LocationDict: warehouseLocationDictUpdated,
                    unsavedReceiveChanges: true
                });
            }
            else {
                //save warehouse name:
                var warehouseLocationDictUpdated = this.state.LocationDict
                var keyToUpdate = warehouseLocationDictUpdated[item.SkuID].findIndex(item => item.WarehouseLocationID === existinglocationname && item.Oversize === locationitem['Oversize'])

                warehouseLocationDictUpdated[item.SkuID][keyToUpdate][label] = event

                this.setState({
                    LocationDict: warehouseLocationDictUpdated,
                    unsavedReceiveChanges: true
                });
            }
        }
        else if (label === 'Oversize' && (this.state.LocationDict[item.SkuID].filter(item => item.WarehouseLocationID === existinglocationname).length > 1)) {
            window.alert('Duplicate location and oversize entry, please use existing.')
        }
        else {
            var warehouseLocationDictUpdated = this.state.LocationDict
            var keyToUpdate = warehouseLocationDictUpdated[item.SkuID].findIndex(item => item.WarehouseLocationID === existinglocationname && item.Oversize === locationitem['Oversize'])

            warehouseLocationDictUpdated[item.SkuID][keyToUpdate][label] = event

            this.setState({
                LocationDict: warehouseLocationDictUpdated,
                unsavedReceiveChanges: true
            });

            //Use warehouseLocationDictUpdated to update totals of Pallets and cases
            var currentReceiveDict = this.state.ReceiveDict
            var skuID = item.SkuID;

            var sumNewValue = 0;

            for (var x in warehouseLocationDictUpdated[skuID]) {
                sumNewValue += convertNanToZero(parseInt(warehouseLocationDictUpdated[skuID][x][label]));
            }

            currentReceiveDict[skuID][label] = sumNewValue

            this.setState({
                ReceiveDict: currentReceiveDict
            });
        }
        this.forceUpdate()
    };



    handleAddNewLocation = (newLocationDict) => {
        this.setState({
            unsavedReceiveChanges: true,
            LocationDict: newLocationDict
        }, () => {
            // Do an API call with this.state.searchTerm

        });
    }
    onClickAddLocation = (event, item) => {
        //Check if any Warehouse names are null
        if (this.state.LocationDict[item.SkuID].filter(item => item.WarehouseLocationID === null || item.WarehouseLocationID === '').length > 0) {
            window.alert('Please enter all location values first.')
        }
        else {
            //save warehouse name
            var currentLocationDict = this.state.LocationDict

            currentLocationDict[item.SkuID].push({ 'WarehouseLocationID': null, 'WarehouseID': null, 'Units': null, 'Cases': null, 'Pallets': null, 'Oversize': false })

            this.handleAddNewLocation(currentLocationDict)

            this.forceUpdate()
        }
    }

    onClickRemoveFromAdd = (e, item, locationitem, warehouseLocationID) => {
        var currentList = JSON.parse(JSON.stringify(this.state.LocationDict));
        var skuID = item.SkuID
        if (currentList[skuID].length === 1) {
            currentList[skuID][0] = { 'WarehouseLocationID': null, 'WarehouseID': null, 'Units': 0, 'Cases': 0, 'Pallets': 0, 'Oversize': false }
        }
        else {
            var remainingItems = currentList[skuID].filter((item) => { return !(item.WarehouseLocationID === warehouseLocationID && item.Oversize === locationitem['Oversize']) });
            currentList[skuID] = JSON.parse(JSON.stringify(remainingItems));
        }

        var currentBuildLoadDict = this.state.ReceiveDict
        try {
            currentBuildLoadDict[skuID]['Units'] = (currentList[skuID].reduce((a, v) => a = convertNanToZero(a) + isNumeric(v.Units), 0))
            currentBuildLoadDict[skuID]['Cases'] = (currentList[skuID].reduce((a, v) => a = convertNanToZero(a) + isNumeric(v.Cases), 0))
            currentBuildLoadDict[skuID]['Pallets'] = (currentList[skuID].reduce((a, v) => a = convertNanToZero(a) + isNumeric(v.Pallets), 0))
        }
        catch (e) {
            // currentBuildLoadDict[skuID]['Cases'] = replaceNegativeWithZero(currentBuildLoadDict['CasesReceived'] - currentBuildLoadDict['CasesLoaded'])
            // currentBuildLoadDict[skuID]['Pallets'] = replaceNegativeWithZero(currentBuildLoadDict['PalletsReceived'] - currentBuildLoadDict['PalletsLoaded'])
        }
        this.setState({
            // unsavedBuildLoadChanges: true,
            ReceiveDict: currentBuildLoadDict,
            LocationDict: currentList,
        });

        this.forceUpdate()
    }
    onChangePalletized(checked) {
        var containerDataUpdated = this.state.receiveDataRowData
        containerDataUpdated['Palletized'] = checked
        this.setState({
            receiveDataRowData: containerDataUpdated
        });
        this.forceUpdate()
    }
    onClickReceiveDrawerSave(e) {

        try {
            this.props.inventoryContainerPost2(
                this.props.currentUserEmail,
                this.props.userSignInData.AuthToken,
                formatNumberForAPI(this.state.receiveDataRowData.ContainerID),
                formatStringForAPI(this.state.receiveDataRowData.Container),
                formatStringForAPI(this.state.receiveDataRowData.LclFcl),
                formatDateTimeForUrlSearch(this.state.receiveDataRowData.DeliveryAppointment),
                formatDateForUrlSearch(this.state.receiveDataRowData.ETD),
                formatDateForUrlSearch(this.state.receiveDataRowData.ETA),
                1,
                formatStringForAPI(this.state.receiveDataRowData.Notes),
                formatNumberForAPI(this.state.receiveDataRowData.WarehouseID),
                formatNumberForAPI(this.state.receiveDataRowData.CustomerID),
                formatTrueFalseToBit(this.state.receiveDataRowData.Palletized)
                // this.props.warehouseSelection,
                // this.props.customerSelection
            )
        }
        catch (e) { }


        // for (let k in this.state.ReceiveDict) {
        //     try { var unitsNum = this.state.ReceiveDict[k]['Units'] }
        //     catch (e) { var unitsNum = 0 }
        //     try { var recoveredNum = this.state.ReceiveDict[k]['Cases'] }
        //     catch (e) { var recoveredNum = 0 }
        //     try { var palletNum = this.state.ReceiveDict[k]['Pallets'] }
        //     catch (e) { var palletNum = 0 }

        //     var skuid = k


        //     if ((unitsNum === null || unitsNum == 0) && (recoveredNum === null || recoveredNum == 0) && (palletNum === null || palletNum == 0)) {
        //     }
        //     else {
        //         try {


        //             this.props.inventorySkuRecoveryPost(


        //                 this.props.currentUserEmail,
        //                 this.props.userSignInData.AuthToken,
        //                 formatNumberForAPI(skuid),
        //                 formatNumberForAPI(this.state.receiveDataRowData.ContainerID),
        //                 null,
        //                 formatStringForAPI(this.state.ReceiveDict[k]['SkuDescription']),
        //                 formatNumberForAPI(this.state.ReceiveDict[k]['Units']),
        //                 formatNumberForAPI(this.state.ReceiveDict[k]['Cases']),
        //                 formatNumberForAPI(this.state.ReceiveDict[k]['Pallets']),
        //                 formatTrueFalseToBit(this.state.ReceiveDict[k]['Oversize']),
        //                 null, //_CustomsCleared
        //                 formatDateTimeForUrlSearch(this.state.ReceiveDate)


        //                 // this.props.currentUserEmail,
        //                 // this.props.userSignInData.AuthToken,
        //                 // formatNumberForAPI(this.state.receiveDataRowData.SkuID),
        //                 // formatNumberForAPI(this.state.receiveDataRowData.ContainerID),
        //                 // null,
        //                 // null,
        //                 // formatNumberForAPI(k),
        //                 // null,
        //                 // formatNumberForAPI(this.state.ReceiveDict[k]['Cases']),
        //                 // formatNumberForAPI(this.state.ReceiveDict[k]['Pallets']),
        //                 // formatStringForAPI(this.state.ReceiveDict[k]['Notes']),
        //                 // null,
        //                 // this.props.warehouseSelection,
        //                 // this.props.customerSelection
        //             )
        //         }
        //         catch (e) { }
        //     }
        // }

        for (let l in this.state.LocationDict) {

            try { var skuID = l }
            catch (e) { var skuID = null }

            for (let i in this.state.LocationDict[l]) {

                // try { var SkuWarehouseLocationID = this.state.LocationDict[l][i]['SkuWarehouseLocationID'] }
                // catch (e) { var SkuWarehouseLocationID = 0 }

                try { var unitsLocNum = convertNanToZero(this.state.LocationDict[l][i]['Units']) }
                catch (e) { var unitsLocNum = 0 }

                try { var casesLocNum = convertNanToZero(this.state.LocationDict[l][i]['Cases']) }
                catch (e) { var casesLocNum = 0 }

                try { var palletsLocNum = convertNanToZero(this.state.LocationDict[l][i]['Pallets']) }
                catch (e) { var palletsLocNum = 0 }

                try { var WarehouseLocationIDNum = convertNanToZero(this.state.LocationDict[l][i]['WarehouseLocationID']) }
                catch (e) { var WarehouseLocationIDNum = 0 }

                try { var locOversize = formatTrueFalseToBit(this.state.LocationDict[l][i]['Oversize']) }
                catch (e) { var locOversize = null }




                if ((unitsLocNum === null || unitsLocNum == 0) && (casesLocNum === null || casesLocNum == 0) && (palletsLocNum === null || palletsLocNum == 0)) {
                }
                else {
                    try {
                        this.props.inventorySkuWarehouseLocationPost(
                            this.props.currentUserEmail,
                            this.props.userSignInData.AuthToken,
                            skuID,
                            null,
                            WarehouseLocationIDNum,
                            unitsLocNum,
                            casesLocNum,
                            palletsLocNum,
                            locOversize,
                            null,
                            this.state.WarehouseID,
                            this.state.CustomerID,
                            1,
                            formatNumberForAPI(this.state.receiveDataRowData.ContainerID),
                            formatDateTimeForUrlSearch(this.state.ReceiveDate),
                            formatAllTextNumbersSymbolsForAPI(this.state.ReceiveDict[skuID]['SkuDescription'])
                        )
                    }
                    catch (e) { }
                }

            }
        }

        // try {
        //     // check customs city api for events
        //     this.props.customsCityAPIMessages(
        //         this.props.currentUserEmail,
        //         this.props.userSignInData.AuthToken,
        //         null,
        //         this.state.receiveDataRowData.Container
        //     )
        // }
        // catch (e) { }

        // this.setState({
        //     unsavedReceiveChanges: false,
        //     showReceiveDrawer: false
        // });

    }

    closeReceiveDrawer() {
        if (this.state.unsavedReceiveChanges) {
            if (window.confirm('Leave without saving changes?')) {
                this.setReceiveDrawerToClose()
            }
        }
        else {
            this.setReceiveDrawerToClose()
        }
    }

    setReceiveDrawerToClose() {
        this.setState({
            ReceiveDict: {},
            LocationDict: {},
            unsavedReceiveChanges: false,

            showReceiveDrawer: false,

            ReceiveDrawerContainerID: null,
            ReceiveDrawerContainer: null,
            ReceiveDrawerNo: null,
            ReceiveDrawerPMC: null,
            ReceiveDrawerPickupBy: null,
            ReceiveDrawerTrips: null,
            ReceiveDrawerArrivalTime: null,
            ReceiveDrawerLFD: null,
            ReceiveDrawerArrivalAtWarehouse: null,
            ReceiveDrawerDeliveryTime: null,
            ReceiveDrawerNotes: null,

            WarehouseID: null,
            CustomerID: null,
        });
    }





    //Add new HAWB modal
    onClickAddMissing() {
        this.handleOpenAddNewModal()
    }
    handleOpenAddNewModal() {
        this.setState({
            addNewModalShown: true,

            newModalHouseRef: null,
            newModalConsigneeID: null,

            newModalCartons: null,

        });
    }
    onChangeAddNewModal = (event, field) => {
        var dict = {};
        dict[field] = event;
        this.setState(
            dict
        );
    };
    handleCloseAddNewModal() {
        this.setState({
            addNewModalShown: false,

            newModalHouseRef: null,
            newModalConsigneeID: null,

            newModalCartons: null,

        });
    }
    // handleSaveAddNewModal() {
    //     if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {

    //         if (window.confirm('Do you wish to add missing SKU?')) {
    //             this.props.hawbPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.receiveDataRowData.ContainerID, formatStringForAPI(this.state.newModalHouseRef), null, this.state.newModalConsigneeID, formatStringForAPI('Manual Add'), null, null, null, null, null, null, null, null, null, this.state.newModalCartons, null, null, null, null, this.props.warehouseSelection, this.props.customerSelection)
    //         }
    //         this.handleCloseAddNewModal()
    //     }
    //     else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    // }

    // if in the middle of building container do not prompt




    //Add new Container drawer

    onClickAddNewContainer(e) {
        this.addContainer(-1)
    }


    addContainer(searchValue) {
        this.loadWarehouseToState()
        this.loadCustomerToState()

        if (typeof (this.props.warehouseSelection) === 'number') {
            this.setState({
                WarehouseID: this.props.warehouseSelection
            });
        }
        else {
            this.setState({
                WarehouseID: null
            });
        }

        if (typeof (this.props.customerSelection) === 'number') {
            this.setState({
                CustomerID: this.props.customerSelection
            });
        }
        else {
            this.setState({
                CustomerID: null
            });
        }

        if (this.state.showAddToContainerDrawer) {

        }
        else if (searchValue === null || searchValue == 'null') {

        }
        else if (this.props.warehouseSelection == null) {
            window.alert('Please select a warehouse and customer.')
        }
        else if (this.props.customerSelection == null) {
            window.alert('Please select a customer.')
        }
        else if (searchValue === -1) {
            this.onClickAddNewEditable()
            this.setState({
                Container: '',
                unsavedNewContainerChanges: true,
            });

        }
        else if (window.confirm(searchValue + ' not found, do you wish to add?')) {
            this.onClickAddNew()
            this.setState({
                Container: searchValue,
                unsavedNewContainerChanges: true,
            });
        }
        this.forceUpdate()
    }
    onClickAddNew(placement) {
        this.setState({
            placement,
            showAddNewContainerDrawer: true,
            showAddNewContainerEditableDrawer: false,
            unsavedNewContainerChanges: true,
        });
    }
    onClickAddNewEditable(placement) {
        this.setState({
            placement,
            showAddNewContainerDrawer: false,
            showAddNewContainerEditableDrawer: true,
            unsavedNewContainerChanges: true,
        });
    }
    onChangeAddNewContainer = (event, field) => {
        var dict = {};
        dict[field] = event;
        this.setState(
            dict
        );
        this.setState({
            unsavedNewContainerChanges: true,
        });
    };
    onClickConfirmAddNewContainer(e) {

        if (typeof (this.state.WarehouseID) === 'number' && typeof (this.state.CustomerID) === 'number') {
            if (this.state.Container == null || this.state.Container == '') {
                window.alert('Please enter new Container.')
            }
            // else if (window.confirm('Add New Container?')) {
            else {
                // this.props.inventoryContainerPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, formatStringForAPI(this.state.Container), null, formatNumberForAPI(this.state.TotWeight), formatNumberForAPI(this.state.TotChargeable), formatNumberForAPI(this.state.TotVolume), formatDateForUrlSearch(this.state.MinETADischarge), formatStringForAPI(this.state.CarrierName), formatStringForAPI(this.state.FlightVoyage), formatStringForAPI(this.state.AgentNote), formatNumberForAPI(this.state.CurrentMawbStatusID ))
                this.props.inventoryContainerPost(
                    this.props.currentUserEmail,
                    this.props.userSignInData.AuthToken,
                    null,
                    formatStringForAPI(this.state.Container),
                    formatStringForAPI(this.state.LclFcl),
                    formatDateTimeForUrlSearch(this.state.DeliveryAppointment),
                    formatDateForUrlSearch(this.state.ETD),
                    formatDateForUrlSearch(this.state.ETA),
                    1,
                    formatStringForAPI(this.state.Notes),
                    this.state.WarehouseID,
                    this.state.CustomerID,
                    1
                    // this.props.warehouseSelection,
                    // this.props.customerSelection
                )
            }
        }
        else { window.alert('Please select one Warehouse and one Customer to save data.') }

    }
    closeAddNewDrawer() {
        if (this.state.unsavedNewContainerChanges) {
            if (window.confirm('Leave without saving changes?')) {
                this.setStateCloseAddNewDrawer()
            }
        }
        else {
            this.setStateCloseAddNewDrawer()
        }
    }
    setStateCloseAddNewDrawer() {
        this.setState({
            showAddNewContainerDrawer: false,
            showAddNewContainerEditableDrawer: false,
            unsavedNewContainerChanges: false,
            Container: null,
            LclFcl: null,
            Notes: null,
            DeliveryAppointment: null,
            ETD: null,
            ETA: null,
            // CustomerID: null,
            // WarehouseID: null
        });
    }
    setInitialTreeSelection(NewContainerId, NewContainerLabel) {
        this.setState({
            selectedType: 'container',
            selectedLabel: NewContainerLabel,
            selectedID: NewContainerId,
            addNewText: null,

        });
    }

    //Add To Container Drawer
    onClickAddToContainer(placement) {

        this.setState({
            placement,

            showAddToContainerDrawer: true,

            SKU: null,

            selectedType: null,
            selectedLabel: null,
            selectedID: null,
            // selectedWarehouseID: null,
            // selectedCustomerID: null,
            addNewText: null,

            newSkuUnits: null,
            newSkuCases: null,
            newSkuPallets: null,
            newSkuNotes: null,
            newSkuCustomerRef: null,
            newSkuProject: null,

            selectedSkuUnits: null,
            selectedSkuCases: null,
            selectedSkuPallets: null,
            selectedSkuNotes: null,
            selectedSkuCustomerRef: null,
            selectedSkuProject: null
        });
    }

    onClickTableLinkEditContainer(e, rowData, history) {
        var newContainerID = null
        var newContainer = null
        var newWarehouse = null
        var newCustomer = null
        try {
            newContainerID = rowData['ContainerID']
            newContainer = rowData['Container']
            newWarehouse = rowData['WarehouseID']
            newCustomer = rowData['CustomerID']

            this.props.inventoryContainerSkuGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, newContainerID, null)
        }
        catch (e) { }

        this.setState({
            // placement,
            EditContainerID: newContainerID,
            showAddToContainerDrawer: true,

            SKU: null,

            WarehouseID: newWarehouse,
            CustomerID: newCustomer,

            selectedType: 'container',
            selectedLabel: newContainer,
            selectedID: newContainerID,
            // selectedWarehouseID: newWarehouse,
            // selectedCustomerID: newCustomer,
            addNewText: null,

            newSkuUnits: null,
            newSkuCases: null,
            newSkuPallets: null,
            newSkuNotes: null,
            newSkuCustomerRef: null,
            newSkuProject: null,

            selectedSkuUnits: null,
            selectedSkuCases: null,
            selectedSkuPallets: null,
            selectedSkuNotes: null,
            selectedSkuCustomerRef: null,
            selectedSkuProject: null,
        });

    }
    onChangeAddToContainer = (e) => {
        this.setState({
            addNewText: e
        });
    }

    onChangeUnits = (e) => {
        this.setState({
            newSkuUnits: e
        });
    }
    onChangeCases = (e) => {
        this.setState({
            newSkuCases: e
        });
    }
    onChangePallets = (e) => {
        this.setState({
            newSkuPallets: e
        });
    }
    onChangeNotes = (e) => {
        this.setState({
            newSkuNotes: e
        });
    }
    onChangeCustomerRef = (e, value, item) => {
        this.setState({
            newSkuCustomerRef: e
        });
    }
    onChangeProject = (e, value, item) => {
        this.setState({
            newSkuProject: e
        });
    }


    onClickConfirmAddToContainer(e) {

        if (this.state.addNewText == null || this.state.addNewText == '') {
            window.alert('Please enter data.')
        }
        // else if (window.confirm('Confirm Save?')) {
        else {
            // save child of selected type
            // if (this.state.selectedType === 'container') {

            this.props.inventoryContainerSkuPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.WarehouseID, this.state.CustomerID, this.state.selectedID, null, formatStringForAPI(this.state.addNewText), this.state.newSkuUnits, this.state.newSkuCases, this.state.newSkuPallets, null, null, formatStringForAPI(this.state.newSkuCustomerRef), null, formatStringForAPI(this.state.newSkuProject), formatAllTextNumbersSymbolsForAPI(this.state.newSkuNotes))

            this.resetAddToContainerState()

        }
        // }

    }


    onClickDeleteSku(e, rowData, history) {
        if (window.confirm('Confirm Delete?')) {
            // this.setState({
            //     selectedType: null,
            //     selectedLabel: null,
            //     selectedID: null,
            // });
            this.props.inventoryContainerSkuPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.WarehouseID, this.state.CustomerID, rowData['ContainerID'], rowData['SkuID'], null, null, null, null, 1, null, null, null, null, null)
        }
    }



    resetAddToContainerState() {
        this.setState({
            addNewText: null,
            newSkuUnits: null,
            newSkuCases: null,
            newSkuPallets: null,
            newSkuNotes: null,
            newSkuCustomerRef: null,
            newSkuProject: null,
            selectedSkuUnits: null,
            selectedSkuCases: null,
            selectedSkuPallets: null,
            selectedSkuNotes: null,
            selectedSkuCustomerRef: null,
            selectedSkuProject: null
        });
    }

    closeAddToContainerDrawer() {
        this.setState({
            EditContainerID: null,
            showAddToContainerDrawer: false,

            SKU: null,

            selectedType: null,
            selectedLabel: null,
            selectedID: null,
            // selectedCustomerID: null,
            // selectedWarehouseID: null,
            addNewText: null,
            newSkuUnits: null,
            newSkuCases: null,
            newSkuPallets: null,
            newSkuNotes: null,
            newSkuCustomerRef: null,
            newSkuProject: null,
            selectedSkuUnits: null,
            selectedSkuCases: null,
            selectedSkuPallets: null,
            selectedSkuNotes: null,
            selectedSkuCustomerRef: null,
            selectedSkuProject: null
        });
    }



    render() {
        const { checkedKeys, sortColumn, sortType } = this.state;
        let hideShowSearch;
        hideShowSearch = <Row style={{ marginBottom: '5px' }}>

            <Col md={24} sm={24} >

                <p>
                    {RButtonHideShowFilter(this.props.isMobile, this.state.isFilterShown, 'Filters', this.onClickIsFilterShown, this.onClickResetFilters)}
                </p>

            </Col>
        </Row>

        let searchDiv;
        if (this.state.isFilterShown) {
            searchDiv = <Row  >
                <Col md={24} sm={24} >
                    <Panel bordered style={{ marginBottom: '10px' }}>

                        <Row>
                            <Col md={20} sm={20} >
                                {RSearchInputNumberAsString(
                                    this.props.isMobile,
                                    'Container',
                                    this.onChangeSearchContainer,
                                    this.props.history,
                                    'container',
                                    this.state.searchContainer
                                )}
                            </Col>
                            <Col md={4} sm={4} >
                                <div >
                                    <ControlLabel>&nbsp;</ControlLabel>
                                    <InputGroup style={{ marginBottom: 10 }}>
                                        <Button block onClick={(e) => { this.onClickAddNewContainer(e) }} > Add New </Button>
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={24} sm={24} >
                                {RSearchInputNumberAsString(
                                    this.props.isMobile,
                                    'SKU',
                                    this.onChangeSearchSKU,
                                    this.props.history,
                                    'sku',
                                    this.state.searchSKU
                                )}
                            </Col>
                        </Row>
                    </Panel>
                    <Panel bordered style={{ marginBottom: '10px' }}>
                        <Row>
                            {RSelectPicker(
                                this.props.isMobile,
                                containerStatus,
                                this.state.searchStatus,
                                'Status',
                                this.onChangeSearchStatus,
                                this.props.history,
                                mountRef
                            )}
                        </Row>

                        <Row>
                            <Button onClick={(e) => { this.onClickApplySearch(e) }} style={{ marginRight: '10px' }}> Apply </Button>
                            <Button onClick={(e) => { this.onClickResetFilters(e) }}> Reset </Button>
                        </Row>
                    </Panel>
                </Col>
            </Row>
        }
        else if (!this.state.isFilterShown) {
            searchDiv = <div></div>
        }

        let mawbDiv;
        let rTable;
        if (this.props.isInventoryContainerLoading) {

            mawbDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventoryContainerData) !== 'undefined' && this.props.InventoryContainerData != null) {



            rTable = RTableGeneral(
                tableIdCol,     //   keyColumn,
                this.props.InventoryContainerData,     //   tableData,
                InventoryContainerDataElements,       //   DataElements,
                this.props.isInventoryContainerLoading,        //   loading,

                this.props.isMobile,        //   isMobile,
                this.props.history,     //   history,

                sortColumn,     //   sortColumn,
                sortType,       //   sortType,
                this.handleSortColumn,      //   handleSortColumn,

                false, // checkColEntry,
                null,       //   checkedKeys,
                null,       //   handleCheckAll,
                null,       //   handleCheck,

                true,      //   boolSelectButton,
                this.onClickReceive,       //   onClickSelect,
                'Receive',       //   selectButtonText,
                null,       //   selectHighlightRowKey,

                true,       //   link1bool,
                'Details',     //   link1text,
                this.onClickDetails,     //   link1onClick,

                true,       //   link2bool,
                'Edit',       //   link2text,
                this.onClickTableLinkEditContainer  //   link2onClick

            )




            // rTable = RTableContainer(
            //     this.props.InventoryContainerData,        // tableData,
            //     InventoryContainerDataElements,  // DataElements,
            //     sortColumn,                 // sortColumn,
            //     sortType,                   // sortType,
            //     this.props.isInventoryContainerLoading,         // loading,
            //     this.handleSortColumn,      // handleSortColumn,
            //     this.onClickDetails,        // onClickDetails,
            //     false,                      // checkColEntry,
            //     tableIdCol,                 // keyColumn,
            //     checkedKeys,                       // checkedKeys,
            //     this.handleCheckAll,                       // handleCheckAll,
            //     this.handleCheck,                       // handleCheck,
            //     true,                      // boolSelectButton,
            //     this.onClickReceive,     // onClickSelect,
            //     'Receive',                       // selectButtonText,
            //     this.onClickAdd,     // onClickSelect,
            //     'Add',                       // selectButtonText,

            //     this.props.isMobile,        // isMobile,
            //     this.props.history,         // history,
            //     null,                        // highlightRowKey

            //     true,                           // editLink,
            //     'Edit',                         // editLinkText,
            //     this.onClickTableLinkEditContainer,                 // editLinkOnClick

            //     this.onChangeColor,
            //     this.state.userColorArray
            // )

            mawbDiv = <div>

                {(this.props.isMobile == true) ? <div></div> :
                    <div>
                        <Row>
                            <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Shipment_' + currentDateTime}>
                                <ExcelSheet data={this.props.InventoryContainerData} name="Shipment">
                                    {
                                        InventoryContainerDataElements.map(
                                            ({ dBKey, displayName }, idx) => {
                                                return (
                                                    <ExcelColumn label={displayName} value={dBKey} />
                                                );
                                            }
                                        )
                                    }
                                </ExcelSheet>
                            </ExcelFile>
                        </Row>
                    </div>
                }

            </div>
        }
        else {
            rTable = <div></div>
            mawbDiv = <div></div>
        }


        let currentReceiveContainer;
        try {
            if (typeof (this.state.receiveDataRowData.Container) !== 'undefined' && this.props.InventoryContainerData != null) {

                currentReceiveContainer = this.state.receiveDataRowData.Container
            }
            else {
                currentReceiveContainer = ''
            }
        }
        catch (e) {
            currentReceiveContainer = ''
        }



        let receiveDrawer;
        if (this.props.isInventorySkuRecoveryLoading) {
            receiveDrawer = <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>
        }
        // else if (this.props.isPoLocationLoading) {

        //     receiveDrawer = <div align="center">
        //         <section className="panel">
        //         </section>
        //         <Loader
        //             type="TailSpin"
        //             color="#3396E5"
        //             height="50"
        //             width="50"
        //         />
        //     </div>
        // }
        else if (!this.state.showReceiveDrawer) {
            receiveDrawer = <div></div>
        }
        else if (Object.keys(this.state.LocationDict).length > 0 && typeof (this.state.LocationDict) !== 'undefined' && this.state.LocationDict != null) {

            receiveDrawer = <ReceiveContainerDrawer {...this.props} state={this.state} onChange={this.onChangeReceiveDrawer} onChangeReceiveDate={this.onChangeReceiveDate} onChangeLocation={this.onChangeReceiveDrawerLocations} onClickAddLocation={this.onClickAddLocation} onClickRemoveFromAdd={this.onClickRemoveFromAdd} confirm={this.onClickReceiveDrawerSave} close={this.closeReceiveDrawer} onClickAddMissing={this.onClickAddMissing} onChangePalletized={this.onChangePalletized} masterLabel={currentReceiveContainer} />
        }



        let containerDrawer;
        if (this.props.isInventoryContainerSkuLoading) {
            containerDrawer = <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>
        }
        else {
            containerDrawer = <AddToContainerDrawer {...this.props} state={this.state} onChangeAddToContainer={this.onChangeAddToContainer} onClickTree={this.onClickTree} onClickDeleteSku={this.onClickDeleteSku} onChangeUnits={this.onChangeUnits} onChangeCases={this.onChangeCases} onChangePallets={this.onChangePallets} onChangeNotes={this.onChangeNotes} onChangeCustomerRef={this.onChangeCustomerRef} onChangeProject={this.onChangeProject} confirm={this.onClickConfirmAddToContainer} close={this.closeAddToContainerDrawer} />
        }

        return (
            <div>
                <h3>
                    Inventory Management - Inbound
                </h3>
                {hideShowSearch}
                {searchDiv}
                {mawbDiv}
                {rTable}



                <AddNewContainerDrawer {...this.props} state={this.state} onChangeAddNewContainer={this.onChangeAddNewContainer} confirm={this.onClickConfirmAddNewContainer} close={this.closeAddNewDrawer} />

                <AddNewContainerEditableDrawer {...this.props} state={this.state} onChangeAddNewContainer={this.onChangeAddNewContainer} confirm={this.onClickConfirmAddNewContainer} close={this.closeAddNewDrawer} />


                {containerDrawer}

                {receiveDrawer}

            </div>
        );

    }
}

export default withRouter(Inbound);