import React from 'react';
import PropTypes from 'prop-types';
import {
    Drawer,
    Paragraph,
    Button,
    ButtonToolbar,
    Form,
    FormControl,
    FormGroup,
    ControlLabel,
    HelpBlock,
    DatePicker,
    InputPicker,
    Tree,
    Panel,
    InputNumber,
    InputGroup,
    Input,
    Row,
    Col

} from 'rsuite';
import RFormControl from '../../../componentsGeneral/Rsuite/Form/RFormControl';
import { lettersNumbersDashesInputMask, numbersInputMask, textNumbersSymbolsInputmask, limitCharacterLength } from '../../../../resources/logic/inputMasks';
import RTableGeneral from '../../../componentsGeneral/Rsuite/Table/RTableGeneral';
import InventoryContainerSkuDataElements from '../../../dataComponentsInventory/InventoryContainerSkuDataElements';
import DebouncedButton from '../../../componentsGeneral/Rsuite/Button/DebouncedButton';

const tableIdCol = "SkuID"

const styles = {
    // width: 300,
    marginBottom: 10,
    width: '100%'
};

const dropDownLclFcl = [
    {
        "label": "LCL",
        "value": "LCL"
    },
    {
        "label": "FCL",
        "value": "FCL"
    }
]


// const treeRef = React.useRef();
// const [index, setIndex] = React.useState(1);

// function onClick={() => {
//     // https://github.com/bvaughn/react-virtualized/blob/master/docs/List.md#scrolltorow-index-number
//     treeRef.current.list.scrollToRow(index);
//   }}

const mountRef = React.createRef();

const EditSkuDetailsDrawer = ({ onChangeProject, onChangeNotes, onChangeCustomerRef, onChangeSKU, placement, confirm, close, state, ...props }) => (

    <Drawer show={state.showEditSkuDetailsDrawer} onHide={close} placement={placement} style={props.isMobile ? { width: '350px' } : { width: '650px' }}    >
        <Drawer.Header>
            <Drawer.Title>Edit SKU</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body> <div ref={mountRef}>

            <Form fluid>

                {/* { state.selectedType === null ? <div></div> : */}

                <Panel bordered style={{ height: '420px', marginBottom: 10 }} >
                    <div>

                        <FormGroup>

                        <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>SKU:</InputGroup.Addon>
                                <Input value={state.SKU} onChange={(e) => { onChangeSKU(e) }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} />
                            </InputGroup>

                            <div style={{ marginBottom: '5px', width: '100%' }}>
                                <InputPicker
    preventOverflow
                                    container={() => mountRef.current}
                                    block
                                    creatable
                                    placeholder={'Customer Ref...'}
                                    value={state.CustomerRef}
                                    onChange={(e, value, label) => { onChangeCustomerRef(e, value, label) }}
                                    data={props.InventoryCustomerRefData}
                                    cleanable={true}
                                />
                            </div>

                            <div style={{ marginBottom: '5px', width: '100%' }}>
                                <InputPicker
    preventOverflow
                                    container={() => mountRef.current}
                                    block
                                    creatable
                                    placeholder={'Project...'}
                                    value={state.Project}
                                    onChange={(e, value, label) => { onChangeProject(e, value, label) }}
                                    data={props.InventoryProjectData}
                                    cleanable={true}
                                />
                            </div>

                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>Notes:</InputGroup.Addon>
                                <Input value={state.Notes} onChange={(e) => { onChangeNotes(e) }} onInput={(e) => e.target.value = limitCharacterLength(textNumbersSymbolsInputmask(e), 400)} />
                            </InputGroup>

                        </FormGroup>

                        <FormGroup>
                            <ButtonToolbar>
                                <DebouncedButton onClick={confirm} appearance="primary" buttonText="Save" />
                                <Button onClick={close} appearance="default">Close</Button>
                            </ButtonToolbar>
                        </FormGroup>

                    </div>

                </Panel>

            </Form>

        </div> </Drawer.Body>
        <Drawer.Footer>
            <br />

        </Drawer.Footer>


    </Drawer >

);




export default EditSkuDetailsDrawer;


