import React from 'react';
import PropTypes from 'prop-types';
import {
    Drawer,
    Paragraph,
    Button,
    ButtonToolbar,
    Checkbox,
    Form,
    FormControl,
    FormGroup,
    ControlLabel,
    HelpBlock,
    DatePicker,

    Row,
    Col,
    Panel,
    InputGroup,
    InputNumber,
    Input,
    Loader

} from 'rsuite';
import RFormControl from '../../componentsGeneral/Rsuite/Form/RFormControl';
import { lettersNumbersDashesInputMask, numbersInputMask, lettersNumbersDashesSpacesInputMask, textStringInputMask } from '../../../resources/logic/inputMasks';
import RButtonSmall from '../../componentsGeneral/Rsuite/Button/RButtonSmall';
import RDatePicker from '../../componentsGeneral/Rsuite/DatePicker/RDatePicker';
import RSelectPicker from '../../componentsGeneral/Rsuite/InputPicker/RSelectPicker';

const yesNoDropDown = [
    {
        "label": "No",
        "value": 0
    },
    {
        "label": "Yes",
        "value": 1
    },

]


const styles = {
    width: '100%'
};

// style={{ overflowX: 'hidden!important' }} 

const mountRef = React.createRef();

const PickupDrawer = ({ onChange, show, placement, confirm, close, masterLabel, onChangePlannedPickupDT, onChangeLFD, onChangeAirlineRecoveryPMC, onChangeAirlineTripMade, onChangeAirlineFreightRecovered, onChangePickupDT, state, ...props }) => (

    props.isMawbLoading ?

        <div align="center">
            <section className="panel">
            </section>
            <Loader
                type="TailSpin"
                color="#3396E5"
                height="50"
                width="50"
            />
        </div>
        :

        <Drawer show={state.showPickupDrawer} onHide={close} placement={placement} style={props.isMobile ? { width: '350px' } : { width: '650px' }}    >
            <Drawer.Header>
                <Drawer.Title style={{ marginBottom: '15px' }}>Master: {masterLabel}</Drawer.Title>

                <FormGroup>
                    <ButtonToolbar >
                        <Button onClick={confirm} appearance="primary">Save</Button>
                        <Button onClick={close} appearance="default">Cancel</Button>

                    </ButtonToolbar>
                </FormGroup>
            </Drawer.Header>
            <Drawer.Body> <div ref={mountRef}>

                <Form fluid>


                    <Col md={24} sm={24}>
                        <Panel bordered style={{ marginBottom: 10 }} >
                            <FormGroup >

                                <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                    <InputGroup.Addon>Pickup Date:</InputGroup.Addon>
                                    <DatePicker
                                        container={() => mountRef.current}
                                        placeholder="Pickup Date"
                                        onChange={(e) => { onChangePickupDT(e) }}
                                        value={state.AirlineRecoveryPickupDate}
                                        format="YYYY-MM-DD"
                                        showMeridian
                                        // size="sm"
                                        style={{ width: '100%' }}
                                        ranges={[
                                            {
                                                label: 'Now',
                                                value: new Date()
                                            }
                                        ]}
                                    oneTap
                                    />
                                </InputGroup>

                                {/* <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                    <InputGroup.Addon>Cartons Recovered:</InputGroup.Addon>
                                    <InputNumber defaultValue={0} className={'custom-input-number'} onChange={(e) => onChange(e, 'AirlineRecoveryCartonsRecovered')} scrollable={false} />
                                </InputGroup> */}

                                <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                    <InputGroup.Addon>Picked Up By:</InputGroup.Addon>
                                    <Input onChange={(e) => { onChange(e, 'AirlineRecoveryPickedUpBy') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                </InputGroup>

                                <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                    <InputGroup.Addon>Carrier:</InputGroup.Addon>
                                    <Input onChange={(e) => { onChange(e, 'AirlineRecoveryCarrier') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                </InputGroup>

                                {/* <Checkbox onChange={(e, checked) => { onChange(checked, 'PartialPickup') }}>Partial Pickup</Checkbox> */}

                            </FormGroup>
                        </Panel>

                    </Col>

                </Form>

            </div> </Drawer.Body>



            <Drawer.Footer>



            </Drawer.Footer>


        </Drawer>

);


PickupDrawer.defaultProps = {
    show: false
};

export default PickupDrawer;


