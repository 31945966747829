import { appSettings } from "./appSettings";


const awsCognito = {
    EFW: {
        cognitoLogin: {
            Auth:
            //dev
            {
                region: 'us-east-1',
                IdentityPoolId: '',
                UserPoolId: 'us-east-1_NL06lTlQ9',
                ClientId: '7qhn8fr6r18dee67n2qm6b1ua0'
            }
        },
    },
    Evolve: {
        cognitoLogin: {
            Auth:
            //dev
            {
                region: 'us-west-1',
                IdentityPoolId: '',
                UserPoolId: 'us-west-1_zV1n8Xot8',
                ClientId: '5ru01nc3ct5bpt1rmoqnlnnnib'
            }
        },
    }
    // ... other customers ...
};

export const awsCognitoConfig = awsCognito[appSettings.wmsCustomer] || {};





// import { appSettings } from './appSettings';

// export const awsCognitoConfig = {
//     cognitoLogin: {
//         Auth: (appSettings.devEnvironment === true ?
//             //dev
//             {
//                 region: 'us-west-1',
//                 IdentityPoolId: '',
//                 UserPoolId: 'us-west-1_zV1n8Xot8',
//                 ClientId: '5ru01nc3ct5bpt1rmoqnlnnnib'
//             }
//             :
//             //prod VAS-Web_App
//             {
//                 region: 'us-east-1',
//                 IdentityPoolId: '',
//                 UserPoolId: 'us-east-1_Yu1gbDHLd',
//                 ClientId: '4g6euca7j2b0vtfbpgk9beg1lk'
//             }
//         )
//     },
// };

