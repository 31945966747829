import { connect } from 'react-redux';
import React, { Component } from 'react';
import Shipment from './Shipment'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as actions from '../../../redux/actions/vasActions';
import * as userActions from '../../../redux/actions/actionsUser';
import { queryStringParse, formatDateForAPI, formatStringForAPI } from '../../../resources/logic/functions';



const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isWarehouseLocationsLoading: store.userServicesReducer.isWarehouseLocationsLoading,
    WarehouseLocationsData: store.userServicesReducer.WarehouseLocationsData,


    isMawbLoading: store.vasReducer.isMawbLoading,
    MawbData: store.vasReducer.MawbData,

    MawbSaveSuccessful: store.vasReducer.MawbSaveSuccessful,

    MawbEvolveDataSaveSuccessful: store.vasReducer.MawbEvolveDataSaveSuccessful,

    MawbAddSuccessful: store.vasReducer.MawbAddSuccessful,

    isMawbCarrierLoading: store.vasReducer.isMawbCarrierLoading,
    MawbCarrierData: store.vasReducer.MawbCarrierData,


    isHawbLoading: store.vasReducer.isHawbLoading,
    HawbData: store.vasReducer.HawbData,

    HawbRecoverySaveSuccessful: store.vasReducer.HawbRecoverySaveSuccessful,

    isHawbConsigneeLoading: store.vasReducer.isHawbConsigneeLoading,
    HawbConsigneeData: store.vasReducer.HawbConsigneeData,

    PostHawbSuccessful: store.vasReducer.PostHawbSuccessful,

    MawbPickupSaveSuccessful: store.vasReducer.MawbPickupSaveSuccessful,


    PostUserColorSuccessful: store.vasReducer.PostUserColorSuccessful,

    // isMawbRecoverySummaryLoading: store.vasReducer.isMawbRecoverySummaryLoading,
    // MawbRecoverySummaryData: store.vasReducer.MawbRecoverySummaryData,

});

const mapDispatchToProps = dispatch =>
({

    userWarehouseLocationsGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(userActions.userWarehouseLocationsGet(_email, _authToken, _WarehouseID, _CustomerID))
    },


    mawbGet: (_email, _authToken, _MasterID, _Master, _MinETADischargeStart, _MinETADischargeEnd, _CarrierName, _MawbStatusID, _WarehouseID, _OrderNo, _PlannedPickupStart, _PlannedPickupEnd, _AssignedDriver, _MawbColorCode, _CustomerID) => {
        dispatch(actions.mawbGet(_email, _authToken, _MasterID, _Master, _MinETADischargeStart, _MinETADischargeEnd, _CarrierName, _MawbStatusID, _WarehouseID, _OrderNo, _PlannedPickupStart, _PlannedPickupEnd, _AssignedDriver, _MawbColorCode, _CustomerID))
    },
    mawbPost: (_email, _authToken, _MasterID, _Master, _TotCartons, _TotWeight, _TotChargeable, _TotVolume, _MinETADischarge, _CarrierName, _FlightVoyage, _AgentNote, _CurrentMawbStatusID, _WarehouseID, _CustomerID, _ExpectedCartons) => {
        dispatch(actions.mawbPost(_email, _authToken, _MasterID, _Master, _TotCartons, _TotWeight, _TotChargeable, _TotVolume, _MinETADischarge, _CarrierName, _FlightVoyage, _AgentNote, _CurrentMawbStatusID, _WarehouseID, _CustomerID, _ExpectedCartons))
    },

    mawbCarrierRefGet: (_email, _authToken) => {
        dispatch(actions.mawbCarrierRefGet(_email, _authToken))
    },

    mawbEvolveDataEntryPost: (_email, _authToken, _MasterID, _Master, _No, _PMC, _PickupBy, _Trips, _ArrivalTime, _LFD, _ArrivalAtWarehouse, _DeliveryTime, _Notes, _Recovered, _WarehouseID, _CustomerID) => {
        dispatch(actions.mawbEvolveDataEntryPost(_email, _authToken, _MasterID, _Master, _No, _PMC, _PickupBy, _Trips, _ArrivalTime, _LFD, _ArrivalAtWarehouse, _DeliveryTime, _Notes, _Recovered, _WarehouseID, _CustomerID))
    },
    // mawbRecoverySummaryGet:( _email, _authToken, _MasterID ) =>
    // {
    //     dispatch( actions.mawbRecoverySummaryGet( _email, _authToken, _MasterID ) )
    // },
    mawbAddPost: (_email, _authToken, _MasterID, _Master, _WarehouseID, _CustomerID) => {
        dispatch(actions.mawbAddPost(_email, _authToken, _MasterID, _Master, _WarehouseID, _CustomerID))
    },

    hawbGet: (_email, _authToken, _HouseID, _HouseRef, _MasterID, _Master, _ShipmentETDStart, _ShipmentETDEnd, _Origin, _LastDischargePort, _ETADischargeStart, _ETADischargeEnd, _EstCartageDeliveryStart, _EstCartageDeliveryEnd, _ActualCartageDeliveryStart, _ActualCartageDeliveryEnd, _HawbStatusID, _ConsigneeID, _Destination, _WarehouseID, _WarehousePort, _OrderNo, _CustomerID) => {
        dispatch(actions.hawbGet(_email, _authToken, _HouseID, _HouseRef, _MasterID, _Master, _ShipmentETDStart, _ShipmentETDEnd, _Origin, _LastDischargePort, _ETADischargeStart, _ETADischargeEnd, _EstCartageDeliveryStart, _EstCartageDeliveryEnd, _ActualCartageDeliveryStart, _ActualCartageDeliveryEnd, _HawbStatusID, _ConsigneeID, _Destination, _WarehouseID, _WarehousePort, _OrderNo, _CustomerID))
    },

    hawbRecoveryPost: (_email, _authToken, _MasterID, _HouseID, _HouseRef, _RecoveredPCS, _Pallets, _Notes, _CustomsCleared, _WarehouseID, _CustomerID) => {
        dispatch(actions.hawbRecoveryPost(_email, _authToken, _MasterID, _HouseID, _HouseRef, _RecoveredPCS, _Pallets, _Notes, _CustomsCleared, _WarehouseID, _CustomerID))
    },
    hawbConsigneeGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(actions.hawbConsigneeGet(_email, _authToken, _WarehouseID, _CustomerID))
    },

    hawbPost: (_email, _authToken, _MasterID, _HouseRef, _ConsigneeName, _ConsigneeID, _Notes, _Origin, _HAWBDestination, _LastDischargePort, _DeliverToAddress, _Orders, _ShipmentETD, _EstCartageDelivery, _ETADischarge, _ActualCartageDelivery, _Cartons, _NewPallets, _Weight, _Chargeable, _Volume, _WarehouseID, _CustomerID) => {
        dispatch(actions.hawbPost(_email, _authToken, _MasterID, _HouseRef, _ConsigneeName, _ConsigneeID, _Notes, _Origin, _HAWBDestination, _LastDischargePort, _DeliverToAddress, _Orders, _ShipmentETD, _EstCartageDelivery, _ETADischarge, _ActualCartageDelivery, _Cartons, _NewPallets, _Weight, _Chargeable, _Volume, _WarehouseID, _CustomerID))
    },

    customsCityAPIMessages: (_email, _authToken, _paramHouse, _paramMaster) => {
        dispatch(actions.customsCityAPIMessages(_email, _authToken, _paramHouse, _paramMaster))
    },

    transportationMawbPickupPost: (_email, _authToken, _MasterID, _PlannedPickupDT, _DriverAssigned, _Note, _TripMade, _FreightRecovered, _PickupDT, _CartonsRecovered, _PickedUpBy, _Carrier, _LFD, _PMC, _WarehouseID, _CustomerID, _PartialPickup) => {
        dispatch(actions.transportationMawbPickupPost(_email, _authToken, _MasterID, _PlannedPickupDT, _DriverAssigned, _Note, _TripMade, _FreightRecovered, _PickupDT, _CartonsRecovered, _PickedUpBy, _Carrier, _LFD, _PMC, _WarehouseID, _CustomerID, _PartialPickup))
    },

    userColorPost: (_email, _authToken, _MasterID, _MawbColorCode, _WarehouseID, _CustomerID) => {
        dispatch(actions.userColorPost(_email, _authToken, _MasterID, _MawbColorCode, _WarehouseID, _CustomerID))
    },

});

class ShipmentContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleUrlParam();
        // this.props.userWarehousesGet( this.props.currentUserEmail, this.props.userSignInData.AuthToken, null )
    }

    componentDidUpdate(prevProps, prevState) {
        //Add to every container
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }




        if (prevProps.MawbEvolveDataSaveSuccessful !== this.props.MawbEvolveDataSaveSuccessful && this.props.MawbEvolveDataSaveSuccessful) {
            this.handleUrlParam();
        }

        if (prevProps.HawbRecoverySaveSuccessful !== this.props.HawbRecoverySaveSuccessful && this.props.HawbRecoverySaveSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else {
        }

        if (prevProps.MawbAddSuccessful !== this.props.MawbAddSuccessful && this.props.MawbAddSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else {
        }
        if (prevProps.MawbSaveSuccessful !== this.props.MawbSaveSuccessful && this.props.MawbSaveSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else {
        }
        if (prevProps.PostHawbSuccessful !== this.props.PostHawbSuccessful && this.props.PostHawbSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else {
        }

        if (prevProps.MawbPickupSaveSuccessful !== this.props.MawbPickupSaveSuccessful && this.props.MawbPickupSaveSuccessful) {
            setTimeout(() => {
                const values = queryString.parse(this.props.location.search)
                try {
                    var paramMasterID = queryStringParse(values.masterid)
                }
                catch (e) {
                    var paramMasterID = null
                }
                this.handleUrlParam()
            }, 1000);
        }
        else {
        }

    }


    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramMasterID = queryStringParse(values.masterid)
        }
        catch (e) {
            var paramMasterID = null
        }
        try {
            var paramMaster = formatStringForAPI(values.master)
        }
        catch (e) {
            var paramMaster = null
        }
        try {
            var paramOrderNo = formatStringForAPI(values.orderno)
        }
        catch (e) {
            var paramOrderNo = null
        }
        try {
            var paramMinETADischargeStart = formatDateForAPI(values.etadischargestart)
        }
        catch (e) {
            var paramMinETADischargeStart = null
        }
        try {
            var paramMinETADischargeEnd = formatDateForAPI(values.etadischargeend)
        }
        catch (e) {
            var paramMinETADischargeEnd = null
        }
        try {
            var paramCarrier = queryStringParse(values.carrier)
        }
        catch (e) {
            var paramCarrier = null
        }
        try {
            var paramStatus = queryStringParse(values.masterstatus)
        }
        catch (e) {
            var paramStatus = null
        }

        try {
            var paramPlannedPickupStart = formatDateForAPI(values.plannedpickupstart)
        }
        catch (e) {
            var paramPlannedPickupStart = null
        }
        try {
            var paramPlannedPickupEnd = formatDateForAPI(values.plannedpickupend)
        }
        catch (e) {
            var paramPlannedPickupEnd = null
        }
        try {
            var paramAssignedDriver = formatStringForAPI(values.assigneddriver)
        }
        catch (e) {
            var paramAssignedDriver = null
        }

        try {
            var paramMawbColorCode = formatStringForAPI(values.mawbcolorcode)
        }
        catch (e) {
            var paramMawbColorCode = null
        }


        this.props.userWarehouseLocationsGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection);

        this.props.hawbConsigneeGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)

        this.props.mawbCarrierRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken)

        this.props.mawbGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramMasterID, paramMaster, paramMinETADischargeStart, paramMinETADischargeEnd, paramCarrier, paramStatus, this.props.warehouseSelection, paramOrderNo, paramPlannedPickupStart, paramPlannedPickupEnd, paramAssignedDriver, paramMawbColorCode, this.props.customerSelection);




    }



    render() {

        return (

            <Shipment
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}

                isWarehouseLocationsLoading={this.props.isWarehouseLocationsLoading}
                WarehouseLocationsData={this.props.WarehouseLocationsData}

                isMawbLoading={this.props.isMawbLoading}
                MawbData={this.props.MawbData}

                mawbPost={this.props.mawbPost}

                MawbSaveSuccessful={this.props.MawbSaveSuccessful}

                mawbAddPost={this.props.mawbAddPost}
                MawbAddSuccessful={this.props.MawbAddSuccessful}

                MawbEvolveDataSaveSuccessful={this.props.MawbEvolveDataSaveSuccessful}
                mawbEvolveDataEntryPost={this.props.mawbEvolveDataEntryPost}

                // isMawbRecoverySummaryLoading={this.props.isMawbRecoverySummaryLoading}
                // MawbRecoverySummaryData={this.props.MawbRecoverySummaryData}

                isHawbLoading={this.props.isHawbLoading}
                HawbData={this.props.HawbData}
                hawbGet={this.props.hawbGet}

                hawbRecoveryPost={this.props.hawbRecoveryPost}
                HawbRecoverySaveSuccessful={this.props.HawbRecoverySaveSuccessful}

                isMawbCarrierLoading={this.props.isMawbCarrierLoading}
                MawbCarrierData={this.props.MawbCarrierData}

                isHawbConsigneeLoading={this.props.isHawbConsigneeLoading}
                HawbConsigneeData={this.props.HawbConsigneeData}

                hawbPost={this.props.hawbPost}
                PostHawbSuccessful={this.props.PostHawbSuccessful}

                customsCityAPIMessages={this.props.customsCityAPIMessages}

                transportationMawbPickupPost={this.props.transportationMawbPickupPost}

                userColorPost={this.props.userColorPost}
                PostUserColorSuccessful={this.props.PostUserColorSuccessful}

                // userColorArray={this.newColorArray}
            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ShipmentContainer));



