import { reducer } from 'aws-cognito-redux-saga';
import React, {
  PureComponent
} from 'react';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Loader } from 'rsuite';
import { appConfig } from '../../../app/appConfig';
import { customerConfig } from '../../../app/appCustomerConfig';
import { appSettings } from '../../../app/appSettings';
import VasHome from '../../../components/componentsVas/VasHome/VasHome';
import * as actions from '../../../redux/actions/actionsUser';

const mapStateToProps = store =>
({
  currentUserEmail: store.userServicesReducer.currentUserEmail,
  userSignInData: store.userServicesReducer.userSignInData,

  isAppSigningInLoading: store.userServicesReducer.isAppSigningInLoading,
  isGetPageConfigLoading: store.userServicesReducer.isGetPageConfigLoading,


  isUserWarehouseLoading: store.userServicesReducer.isUserWarehouseLoading,
  UserWarehouseData: store.userServicesReducer.UserWarehouseData,

  isUserCustomerLoading: store.userServicesReducer.isUserCustomerLoading,
  UserCustomerData: store.userServicesReducer.UserCustomerData,

  isUserModuleLoading: store.userServicesReducer.isUserModuleLoading,
  UserModuleData: store.userServicesReducer.UserModuleData,


  isUserOrganizationLoading: store.userServicesReducer.isUserOrganizationLoading,

});

const mapDispatchToProps = dispatch =>
({
  signOut: () => {
    dispatch(reducer.signOut({ global: true }))
  },
  appSignOut: () => {
    dispatch(actions.appSignOut())
  },

  userWarehousesGet: (currentUserEmail, otherUsersID) => {
    dispatch(actions.userWarehousesGet(currentUserEmail, otherUsersID))
  },
  userCustomersGet: (currentUserEmail, otherUsersID) => {
    dispatch(actions.userCustomersGet(currentUserEmail, otherUsersID))
  },
  userOrganizationGet: (currentUserEmail, otherUsersID) => {
    dispatch(actions.userOrganizationGet(currentUserEmail, otherUsersID))
  },

  userModulesGet: (currentUserEmail, otherUsersID) => {
    dispatch(actions.userModulesGet(currentUserEmail, otherUsersID))
  },
  userLabelGenCarrierGet: (currentUserEmail, otherUsersID) => {
    dispatch(actions.userLabelGenCarrierGet(currentUserEmail, otherUsersID))
  },
  userLabelGenCarrierServiceGet: (currentUserEmail, otherUsersID) => {
    dispatch(actions.userLabelGenCarrierServiceGet(currentUserEmail, otherUsersID))
  },
});

class vasView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      warningTime: appSettings.warningTimeLogout,
      signoutTime: appSettings.signoutTimeLogout,

      // isConfigLoaded: false,

      isWarehouseLoaded: false,
      isCustomerLoaded: false,
      isModuleLoaded: false,
      isOrganizationLoaded: false,

      isSideMenuCollapsed: false,

      warehouseArray: {},

      warehouseSelection: null,
      warehouseMultiSelection: [],

      customerArray: {},

      customerSelection: null,
      customerMultiSelection: [],

      moduleArray: {},

      organizationSelection: null,


      // navSelection: 'Select Company',
      // nav: '',
      // role: ''
    };
    // this.dropDownOnChange = this.dropDownOnChange.bind(this);
    this.onChangeWarehouseSelection = this.onChangeWarehouseSelection.bind(this);
    this.onChangeCustomerSelection = this.onChangeCustomerSelection.bind(this);
  }

  setStateWarehouseLoaded(trueOrFalse) {
    this.setState({
      isWarehouseLoaded: trueOrFalse
    })
  }
  setStateCustomerLoaded(trueOrFalse) {
    this.setState({
      isCustomerLoaded: trueOrFalse
    })
  }
  setStateModuleLoaded(trueOrFalse) {
    this.setState({
      isModuleLoaded: trueOrFalse
    })
  }
  setStateOrganizationLoaded(trueOrFalse) {
    this.setState({
      isOrganizationLoaded: trueOrFalse
    })
  }



  getWarehouseSelectionFromSessionStorage(warehouseMenuList) {
    try {
      if (this.props.userSignInData.Admin && warehouseMenuList.length > 1) {
        // this is for multi select:
        var warehouseCurrent = sessionStorage.getItem('currentWarehouse')
        var warehouseValue = warehouseCurrent.split(',').map(Number);

        if (warehouseValue[0] !== 0 && warehouseValue !== null && warehouseValue !== undefined && !isNaN(warehouseValue[0])) {
          this.onChangeWarehouseSelection(warehouseValue)
        }
      }
      else {
        //this is for single select:
        var warehouseValue = JSON.parse(sessionStorage.getItem('currentWarehouse'));
        if (warehouseValue !== null && warehouseValue !== undefined) {
          this.onChangeWarehouseSelection([warehouseValue])
        }
      }

    }
    catch (e) { }
  }


  getOrganizationDataFromSessionStorage() {
    let organizationMenuList
    try {
      organizationMenuList = sessionStorage.getItem('currentOrganizationName')
      if (organizationMenuList !== null && organizationMenuList !== undefined) {
        this.setState({
          organizationSelection: organizationMenuList
        })
      }
    }
    catch (e) { }
    // this.forceUpdate()
  }


  getWarehouseDataFromSessionStorage() {
    //Warhouse
    let warehouseMenuList;
    try {
      warehouseMenuList = JSON.parse(sessionStorage.getItem('AppConfig.WarehouseArray'));
      if (warehouseMenuList !== null && warehouseMenuList !== undefined) {
        this.setState({
          warehouseArray: warehouseMenuList
        })
      }

      this.getWarehouseSelectionFromSessionStorage(warehouseMenuList)

      this.setStateWarehouseLoaded(true)


    }
    catch (e) { }


    // this.forceUpdate()
  }

  getCustomerSelectionFromSessionStorage(customerMenuList) {
    try {
      if (this.props.userSignInData.Admin && customerMenuList.length > 1) {
        // this is for multi select:
        var customerCurrent = sessionStorage.getItem('currentCustomer')
        var customerValue = customerCurrent.split(',').map(Number);
        if (customerValue[0] !== 0 && customerValue !== null && customerValue !== undefined && !isNaN(customerValue[0])) {
          this.onChangeCustomerSelection(customerValue)
        }
      }
      else {
        //this is for single select:
        var customerValue = JSON.parse(sessionStorage.getItem('currentCustomer'));
        if (customerValue !== null && customerValue !== undefined) {
          this.onChangeCustomerSelection([customerValue])
        }
      }

    }
    catch (e) { }
  }

  getCustomerDataFromSessionStorage() {
    //Customer
    let customerMenuList
    try {
      customerMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CustomerArray'));
      if (customerMenuList !== null && customerMenuList !== undefined) {
        this.setState({
          customerArray: customerMenuList
        })
      }

      this.getCustomerSelectionFromSessionStorage(customerMenuList)

      this.setStateCustomerLoaded(true)
    }
    catch (e) { }


    // this.forceUpdate()
  }

  getModulesFromSessionStorage() {
    try {
      let moduleMenuList = JSON.parse(sessionStorage.getItem('AppConfig.ModuleArray'));
      if (moduleMenuList != null && moduleMenuList != undefined) {
        this.setState({
          moduleArray: moduleMenuList
        })
      }
    }
    catch (e) { }
    this.setStateModuleLoaded(true)
    // this.forceUpdate()
  }

  getConfigDataFromsessionStorage() {
    this.getOrganizationDataFromSessionStorage()

    //Warhouse
    let warehouseMenuList;
    try {
      warehouseMenuList = JSON.parse(sessionStorage.getItem('AppConfig.WarehouseArray'));
      if (typeof (warehouseMenuList[0].value) !== 'undefined') {
        this.setState({
          warehouseArray: warehouseMenuList
        })
      }
      else {
        this.logout()
      }
    }
    catch (e) {
      this.logout()
      // this.props.userWarehousesGet(this.props.currentUserEmail, null)
    }

    this.getWarehouseSelectionFromSessionStorage(warehouseMenuList)
    // try {
    //   if (this.props.userSignInData.Admin && warehouseMenuList.length > 1) {
    //     // this is for multi select:
    //     var warehouseCurrent = sessionStorage.getItem('currentWarehouse')
    //     var warehouseValue = warehouseCurrent.split(',').map(Number);
    //   }
    //   else {


    //     var warehouseValue = JSON.parse(sessionStorage.getItem('currentWarehouse'));
    //   }
    //   if (warehouseValue !== null && warehouseValue !== undefined && warehouseValue !== NaN) {
    //     this.onChangeWarehouseSelection(warehouseValue)
    //   }

    // }
    // catch (e) { }

    this.setStateWarehouseLoaded(true)


    //Customer
    let customerMenuList
    try {
      customerMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CustomerArray'));

      if (typeof (customerMenuList[0].value) !== 'undefined') {
        this.setState({
          customerArray: customerMenuList
        })
      }
      else {
        this.logout()
      }
    }
    catch (e) {
      this.logout()
      // this.props.userCustomersGet(this.props.currentUserEmail, null)
    }
    this.getCustomerSelectionFromSessionStorage(customerMenuList)


    // try {

    //   let customerValue = JSON.parse(sessionStorage.getItem('currentCustomer'));
    //   if (customerValue != null && customerValue != undefined) {
    //     this.onChangeCustomerSelection(customerValue)
    //   }
    // }
    // catch (e) { }

    this.setStateCustomerLoaded(true)


    //Module
    try {
      let moduleMenuList = JSON.parse(sessionStorage.getItem('AppConfig.ModuleArray'));

      if (typeof (moduleMenuList[0].value) !== 'undefined') {
        this.setState({
          moduleArray: moduleMenuList
        })
      }
      else {
        this.logout()
      }

    }
    catch (e) {
      this.logout()
      // this.props.userModulesGet(this.props.currentUserEmail, null)
    }
    this.setStateModuleLoaded(true)


    this.forceUpdate()
  }

  componentDidMount() {

    this.events = [
      'load',
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keypress'
    ];

    for (var i in this.events) {
      window.addEventListener(this.events[i], this.resetTimeout);
    }
    this.setTimeout();

    // this.getsessionStorageWarehouseSelection() //this will preserve menu selection
    this.getConfigDataFromsessionStorage()
    // this.getsessionStorageRole()
  }

  componentWillUnmount() {
    this.events = [
      'load',
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keypress'
    ];

    for (var i in this.events) {
      window.addEventListener(this.events[i], this.resetTimeout);
    }
    this.setTimeout();

    this.clearTimeoutFunc()

  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isMobile) {
      if (this.props.location.pathname !== prevProps.location.pathname) {
        this.setState({ isSideMenuCollapsed: true });
      }
    }

    if (this.props.isUserWarehouseLoading) {
      this.setStateWarehouseLoaded(false)
    }
    if (this.props.isUserCustomerLoading) {
      this.setStateCustomerLoaded(false)
    }
    if (this.props.isUserModuleLoading) {
      this.setStateModuleLoaded(false)
    }
    if (this.props.isUserOrganizationLoading) {
      this.setStateOrganizationLoaded(false)
    }
    if (prevProps.isUserWarehouseLoading && !this.props.isUserWarehouseLoading) {
      this.getWarehouseDataFromSessionStorage()
    }
    if (prevProps.isUserCustomerLoading && !this.props.isUserCustomerLoading) {
      this.getCustomerDataFromSessionStorage()
    }
    if (prevProps.isUserModuleLoading && !this.props.isUserModuleLoading) {
      this.getModulesFromSessionStorage()
    }
    // if (prevProps.isUserOrganizationLoading && !this.props.isUserOrganizationLoading) {
    //   this.getOrganizationFromSessionStorage()
    // }



    if (prevProps.isAppSigningInLoading !== this.props.isAppSigningInLoading && !this.props.isAppSigningInLoading) {
      this.getConfigDataFromsessionStorage()
    }

    // if (prevProps.isUserWarehouseLoading !== this.props.isUserWarehouseLoading && !this.props.isUserWarehouseLoading) {
    //   this.getConfigDataFromsessionStorage()
    // }
    // if (prevProps.isUserCustomerLoading !== this.props.isUserCustomerLoading && !this.props.isUserCustomerLoading) {
    //   this.getConfigDataFromsessionStorage()
    // }
    // if (prevProps.isUserModuleLoading !== this.props.isUserModuleLoading && !this.props.isUserModuleLoading) {
    //   this.getConfigDataFromsessionStorage()
    // }


    // if(this.state.width <= appSettings.mobileWidth){
    //   if (this.props.location.pathname !== prevProps.location.pathname) {
    //     this.setState({ isSideMenuCollapsed: true });            
    //   }
    // }

    // if (prevProps.location.search !== this.props.location.search) {
    //   try{
    //     this.getsessionStorageWarehouseSelection()
    //   }
    //   catch(e){

    //   }
    // }

  }

  onClickSideMenu = () => {
    if (this.state.isSideMenuCollapsed) {
      this.setState({ isSideMenuCollapsed: false });
    }
    else {
      this.setState({ isSideMenuCollapsed: true });
    }
  }

  //save warehouse selection
  saveWarehouseSelectionTosessionStorage(warehouseToSave) {
    try {
      sessionStorage.setItem('currentWarehouse', warehouseToSave)
    }
    catch (e) {

    }
  }

  onChangeWarehouseSelection(value) {
    try {
      this.setState({
        warehouseSelection: value,
        warehouseMultiSelection: value
      });
      this.saveWarehouseSelectionTosessionStorage(value)
    }
    catch (e) { }
  }

  //save customer selection
  saveCustomerSelectionTosessionStorage(customerToSave) {
    try {
      sessionStorage.setItem('currentCustomer', customerToSave)
    }
    catch (e) {

    }
  }

  onChangeCustomerSelection(value) {
    try {
      this.setState({
        customerSelection: value,
        customerMultiSelection: value
      });
      this.saveCustomerSelectionTosessionStorage(value)
    }
    catch (e) { }
  }




  clearTimeoutFunc = () => {
    if (this.warnTimeout) clearTimeout(this.warnTimeout);

    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  };

  setTimeout = () => {
    this.warnTimeout = setTimeout(this.warn, this.state.warningTime);
    this.logoutTimeout = setTimeout(this.logout, this.state.signoutTime);
  };

  resetTimeout = () => {
    this.clearTimeoutFunc();
    this.setTimeout();
  };

  warn = () => {
    console.log('You will be logged out automatically in 1 minute.');
  };

  logout = () => {
    // Send a logout request to the API
    console.log('Auto Logout');

    this.props.signOut()
    this.props.appSignOut()
    this.props.history.push(`/${customerConfig.customerUrl}/signin`)
    window.location.reload();
  };

  destroy = () => {
    this.props.history.push(`/${customerConfig.customerUrl}/signin`)
  };

  signOut = () => {
    this.props.history.push(`/${customerConfig.customerUrl}/signin`)
  };

  state = {
    appLogo: appSettings.logo,
    appName: appConfig.APP_NAME,
    connectionStatus: appConfig.CONNECTION_STATUS,
    helloWord: appConfig.HELLO_WORD
  };

  render() {
    const { isSideMenuCollapsed, warehouseArray, warehouseSelection, warehouseMultiSelection, customerArray, customerSelection, customerMultiSelection, moduleArray, organizationSelection } = this.state;


    let vasHomeDiv;


    // if (!Array.isArray(this.state.warehouseArray) || this.state.warehouseArray.length < 1) {
    //   // !this.state.warehouseArray.length
    //   this.props.userWarehousesGet(this.props.currentUserEmail, null)
    //   vasHomeDiv = <div></div>
    // }

    // else if (!Array.isArray(this.state.customerArray) || this.state.customerArray.length < 1) {
    //   this.props.userCustomersGet(this.props.currentUserEmail, null)
    //   vasHomeDiv = <div></div>
    // }

    // else if (!Array.isArray(this.state.moduleArray) || this.state.moduleArray.length < 1) {
    //   this.props.userModulesGet(this.props.currentUserEmail, null)
    //   vasHomeDiv = <div></div>
    // }

    // if (!this.state.isConfigLoaded) {
    //   vasHomeDiv = <div></div>
    // }



    if (this.props.isUserWarehouseLoading) {
      vasHomeDiv = <div align="center">
        <section className="panel">
          <header className="panel-heading">
            { }
          </header>
        </section>
        <Loader
          type="TailSpin"
          color="#3396E5"
          height="50"
          width="50"
        />
      </div>
    }
    else if (this.props.isUserCustomerLoading) {
      vasHomeDiv = <div align="center">
        <section className="panel">
          <header className="panel-heading">
            { }
          </header>
        </section>
        <Loader
          type="TailSpin"
          color="#3396E5"
          height="50"
          width="50"
        />
      </div>
    }
    else if (this.props.isUserModuleLoading) {
      vasHomeDiv = <div align="center">
        <section className="panel">
          <header className="panel-heading">
            { }
          </header>
        </section>
        <Loader
          type="TailSpin"
          color="#3396E5"
          height="50"
          width="50"
        />
      </div>
    }
    else if (this.props.isUserOrganizationLoading) {
      vasHomeDiv = <div align="center">
        <section className="panel">
          <header className="panel-heading">
            { }
          </header>
        </section>
        <Loader
          type="TailSpin"
          color="#3396E5"
          height="50"
          width="50"
        />
      </div>
    }

    else if (this.state.isWarehouseLoaded && this.state.isCustomerLoaded && this.state.isModuleLoaded) {
      vasHomeDiv = <VasHome
        admin={this.props.userSignInData.Admin}
        isMobile={this.props.isMobile}
        sideMenuIsCollapsed={isSideMenuCollapsed}
        // navSelection={this.state.navSelection}
        // dropDownOnChange={this.dropDownOnChange}
        // role={this.state.role} 
        warehouseArray={warehouseArray}
        warehouseSelection={warehouseSelection}
        warehouseMultiSelection={warehouseMultiSelection}

        customerArray={customerArray}
        customerSelection={customerSelection}
        customerMultiSelection={customerMultiSelection}

        moduleArray={moduleArray}

        organizationSelection={organizationSelection}

        onChangeWarehouseSelection={this.onChangeWarehouseSelection}
        onChangeCustomerSelection={this.onChangeCustomerSelection}
        role={this.props.userSignInData.RoleName}
      />

    }
    else {
      vasHomeDiv = <div align="center">
        <section className="panel">
          <header className="panel-heading">
            { }
          </header>
        </section>
        <Loader
          type="TailSpin"
          color="#3396E5"
          height="50"
          width="50"
        />
      </div>
    }

    // else if (this.props.isUserWarehouseLoading) {
    //   vasHomeDiv = <div></div>
    // }
    // else if (this.props.isUserCustomerLoading) {
    //   vasHomeDiv = <div></div>
    // }
    // else if (this.props.isUserModuleLoading) {
    //   vasHomeDiv = <div></div>
    // }

    // else {
    //   vasHomeDiv = <VasHome
    //     admin={this.props.userSignInData.Admin}
    //     isMobile={this.props.isMobile}
    //     sideMenuIsCollapsed={isSideMenuCollapsed}
    //     // navSelection={this.state.navSelection}
    //     // dropDownOnChange={this.dropDownOnChange}
    //     // role={this.state.role} 
    //     warehouseArray={warehouseArray}
    //     warehouseSelection={warehouseSelection}

    //     customerArray={customerArray}
    //     customerSelection={customerSelection}
    //     moduleArray={moduleArray}

    //     onChangeWarehouseSelection={this.onChangeWarehouseSelection}
    //     onChangeCustomerSelection={this.onChangeCustomerSelection}
    //     role={this.props.userSignInData.RoleName}
    //   />
    // }


    return (
      <div>
        <div className="verticalMenuButton" onClick={() => this.onClickSideMenu()} >
          <Button variant="light" size="sm" title="Hide/Show Menu"   >
            <i className="fa fa-bars"></i>
          </Button>
        </div>
        {vasHomeDiv}
      </div>


    );
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(vasView));


