import React from 'react';
import PropTypes from 'prop-types';
import {
    Drawer,
    Paragraph,
    Button,
    ButtonToolbar,
    Form,
    FormControl,
    FormGroup,
    ControlLabel,
    HelpBlock,
    DatePicker,
    InputPicker,
    Tree,
    Panel,
    InputNumber,
    InputGroup,
    Input,
    Row,
    Col

} from 'rsuite';
import RFormControl from '../../componentsGeneral/Rsuite/Form/RFormControl';
import { lettersNumbersDashesInputMask, numbersInputMask, textNumbersSymbolsInputmask, textStringInputMask } from '../../../resources/logic/inputMasks';

const styles = {
    // width: 300,
    marginBottom: 10,
    width: '100%'
};

const dropDownLclFcl = [
    {
        "label": "LCL",
        "value": "LCL"
    },
    {
        "label": "FCL",
        "value": "FCL"
    }
]


// const treeRef = React.useRef();
// const [index, setIndex] = React.useState(1);

// function onClick={() => {
//     // https://github.com/bvaughn/react-virtualized/blob/master/docs/List.md#scrolltorow-index-number
//     treeRef.current.list.scrollToRow(index);
//   }}

const mountRef = React.createRef();

const AddToContainerDrawer = ({ onChangeAddToContainer, onClickTree, onClickDeleteMbol, onClickDeleteHbol, onChangeProject, onClickDeletePo, onChangeCases, onChangePallets, onChangeNotes, onChangeCustomerRef, show, placement, confirm, close, state, ...props }) => (

    <Drawer show={state.showAddToContainerDrawer} onHide={close} placement={placement} style={props.isMobile ? { width: '350px' } : { width: '650px' }}    >
        <Drawer.Header>
            <Drawer.Title>Build Container</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body> <div ref={mountRef}>

            <Form fluid>

                {/* { state.selectedType === null ? <div></div> : */}

                <Panel bordered style={{ height: '380px', marginBottom: 10 }} >
                    {
                        state.selectedType === null ? <div>
                            <h5 style={{ marginBottom: 5 }} >Make selection below: {state.selectedLabel}</h5>

                        </div> : <div></div>
                    }

                    {
                        state.selectedType === 'container' ? <div>
                            <h5 style={{ marginBottom: 5 }} >Add new MBOL to {state.selectedLabel}</h5>

                            <FormGroup>
                                <FormControl placeholder="MBOL" name="MBOL" value={state.addNewText} onChange={(e) => { onChangeAddToContainer(e) }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} />
                            </FormGroup>

                            <FormGroup>
                                <ButtonToolbar>
                                    <Button onClick={confirm} appearance="primary">Add</Button>
                                    <Button onClick={close} appearance="default">Close</Button>
                                </ButtonToolbar>
                            </FormGroup>

                        </div> : <div></div>
                    }

                    {
                        state.selectedType === 'mbol' ? <div>
                            <h5 style={{ marginBottom: 5 }}>Add new HBOL to {state.selectedLabel}</h5>

                            <FormGroup>
                                <FormControl placeholder="HBOL" name="HBOL" value={state.addNewText} onChange={(e) => { onChangeAddToContainer(e) }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} />
                            </FormGroup>

                            <FormGroup>
                                <ButtonToolbar>
                                    <Button onClick={confirm} appearance="primary">Add</Button>
                                    <Button onClick={close} appearance="default">Close</Button>

                                    <Button style={{ float: 'right' }} onClick={(e) => { onClickDeleteMbol(e) }} appearance="default">Delete MBOL</Button>
                                </ButtonToolbar>
                            </FormGroup>

                        </div> : <div></div>
                    }
                    {
                        state.selectedType === 'hbol' ? <div>
                            <h5 style={{ marginBottom: 5 }}>
                                Add new PO to {state.selectedLabel}
                            </h5>

                            <FormGroup>

                                <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                    <FormControl placeholder="PO" name="PO" value={state.addNewText} onChange={(e) => { onChangeAddToContainer(e) }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} />
                                </InputGroup>

                                <div style={{ marginBottom: '5px', width: '100%' }}>
                                    <InputPicker
    preventOverflow
                                        container={() => mountRef.current}
                                        block
                                        creatable
                                        placeholder={'Customer Ref...'}
                                        value={state.newPoCustomerRef}
                                        onChange={(e, value, label) => { onChangeCustomerRef(e, value, label) }}
                                        data={props.TransCustomerRefData}
                                        cleanable={true}
                                    />
                                </div>

                                <div style={{ marginBottom: '5px', width: '100%' }}>
                                    <InputPicker
    preventOverflow
                                        container={() => mountRef.current}
                                        block
                                        creatable
                                        placeholder={'Project...'}
                                        value={state.newPoProject}
                                        onChange={(e, value, label) => { onChangeProject(e, value, label) }}
                                        data={props.TransProjectData}
                                        cleanable={true}
                                    />
                                </div>

                                <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                    <InputGroup.Addon>Expected Cases:</InputGroup.Addon>
                                    {/* <InputNumber defaultValue={item.Cartons - item.RecoveredPCS } className={'custom-input-number'} onChange={(e) => onChange(e, item, 'Recovered')} scrollable={false} /> */}
                                    <InputNumber defaultValue={0} className={'custom-input-number'} onChange={(e) => onChangeCases(e)} scrollable={false} />
                                </InputGroup>
                                <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                    <InputGroup.Addon>Expected Pallets:</InputGroup.Addon>
                                    <InputNumber defaultValue={0} className={'custom-input-number'} onChange={(e) => onChangePallets(e)} scrollable={false} />
                                </InputGroup>

                                <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                    <InputGroup.Addon>Notes:</InputGroup.Addon>
                                    <Input onChange={(e) => { onChangeNotes(e) }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                </InputGroup>

                            </FormGroup>

                            <FormGroup>
                                <ButtonToolbar>
                                    <Button onClick={confirm} appearance="primary">Add</Button>
                                    <Button onClick={close} appearance="default">Close</Button>

                                    <Button style={{ float: 'right' }} onClick={(e) => { onClickDeleteHbol(e) }} appearance="default">Delete HBOL</Button>
                                </ButtonToolbar>
                            </FormGroup>

                        </div> : <div></div>
                    }

                    {
                        state.selectedType === 'po' ? <div>

                            <h5 style={{ marginBottom: 5 }}>
                                {state.selectedLabel}
                            </h5>

                            <div>
                                <Row >
                                    <Col md={24} sm={24}>
                                        <Panel bordered style={{ marginBottom: 10 }}>
                                            Expected Cases: <strong>{state.selectedPoCases}</strong>
                                            <br />
                                            Expected Pallets: <strong>{state.selectedPoPallets}</strong>
                                            <br />
                                            Notes: <strong>{state.selectedPoNotes}</strong>
                                            <br />
                                            {/* Customer Ref: <strong>{state.selectedPoCustomerRef}</strong>
                                            <br />
                                            Project: <strong>{state.selectedPoProject}</strong>
                                            <br /> */}
                                        </Panel>
                                    </Col>
                                </Row>
                            </div>

                            <Row>
                                <FormGroup>
                                    <ButtonToolbar>
                                        <Button style={{ float: 'right' }} onClick={(e) => { onClickDeletePo(e) }} appearance="default">Delete PO</Button>
                                    </ButtonToolbar>
                                </FormGroup>
                            </Row>
                        </div> : <div></div>
                    }

                </Panel>


                <FormGroup>
                    <Panel bordered  >
                        <Tree data={props.ContainerJsonTreeData} defaultExpandAll onSelect={(e) => { onClickTree(e) }} />
                        {/* defaultValue={state.selectedID} */}
                    </Panel>
                </FormGroup>


            </Form>

        </div> </Drawer.Body>
        <Drawer.Footer>
            <br />

        </Drawer.Footer>


    </Drawer >

);



AddToContainerDrawer.defaultProps = {
    show: false
};

export default AddToContainerDrawer;


