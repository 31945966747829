import React, {
    PureComponent
} from 'react';
import { Message } from 'rsuite';
import AnimatedView from '../../../../components/componentsGeneral/AnimatedView/AnimatedView';
import BarcodeScanningContainer from '../../../../components/componentsInventoryManagement/_Lot_Tracking_Components/BarcodeScanning/BarcodeScanningContainer';

class invManBarcodeScanningView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
    }

    render() {


        if (typeof this.props.warehouseSelection !== 'number') {
            return <AnimatedView><Message style={{ marginTop: '25px' }} type="info" description="Please only select only one warehouse" /></AnimatedView>;
        }
    
        if (typeof this.props.customerSelection !== 'number') {
            return <AnimatedView><Message style={{ marginTop: '25px' }} type="info" description="Please only select only one customer" /></AnimatedView>;
        }
    
        return (
            <AnimatedView>
                <BarcodeScanningContainer isMobile={this.props.isMobile} warehouseSelection={this.props.warehouseSelection} customerSelection={this.props.customerSelection} />
            </AnimatedView>

        );
    }
}

export default invManBarcodeScanningView;
