import React from 'react';
import { Button } from 'rsuite';



function RButtonPrimary(
    isMobile,
    label,
    onClick
    
  ) {

    let rButton;

    try{
        rButton =    
        <div >
            <Button appearance="primary" block onClick={(e) => onClick(e)} size={isMobile ? 'sm' : 'lg' } style={ isMobile ? {marginBottom:'5px'} : {marginBottom:'5px', fontWeight: 'bold'} } >{label}</Button>
        </div>
    }
    catch(e){}

    return (

        rButton

    )
    }


export default RButtonPrimary;


