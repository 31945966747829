export const InventoryCaseWarehouseGroupedDataElements = [
    {
        dBKey: 'WarehouseLocationName',
        displayName: 'Warehouse Location',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'CaseSKU',
        displayName: 'Case SKU',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 260
    },
    {
        dBKey: 'CaseCount',
        displayName: 'Case Count',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 110
    },
    {
        dBKey: 'CaseStatusName',
        displayName: 'Case Status',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'CreateDT',
        displayName: 'Min Recovery Date',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'WarehouseName',
        displayName: 'Warehouse',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'CustomerName',
        displayName: 'Customer',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'LotCode',
        displayName: 'Lot Code',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'SerialNumber',
        displayName: 'Serial Number',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },



    // {
    //     dBKey: 'SKU',
    //     displayName: 'Unit SKUs',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 250
    // },
    {
        dBKey: 'UnitsInWhse',
        displayName: 'Units In Whse',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 140
    },










]


export default InventoryCaseWarehouseGroupedDataElements

