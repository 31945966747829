import React from 'react';
import PropTypes from 'prop-types';
import {
    Drawer,
    Paragraph,
    Button,
    ButtonToolbar,
    ButtonGroup,
    Checkbox,
    Form,
    FormControl,
    FormGroup,
    ControlLabel,
    HelpBlock,
    DatePicker,
    InputPicker,
    Row,
    Col,
    Panel,
    InputGroup,
    InputNumber,
    Input,
    Divider,
    IconButton
} from 'rsuite';
import RFormControl from '../../../componentsGeneral/Rsuite/Form/RFormControl';
import { lettersNumbersDashesInputMask, numbersInputMask, lettersNumbersDashesSpacesInputMask } from '../../../../resources/logic/inputMasks';
import RButtonSmall from '../../../componentsGeneral/Rsuite/Button/RButtonSmall';
import * as moment from 'moment'
import { convertNanToZero } from '../../../../resources/logic/functions';
import CloseIcon from '@rsuite/icons/Close';
import debounceClick from '../../../../resources/logic/useability';
import DebouncedButton from '../../../componentsGeneral/Rsuite/Button/DebouncedButton';


const styles = {
    width: '100%'
};

function limitNumberToExistingUnitsValue(e, locationitem, item, originalProps) {
    var itemIndex = originalProps[item.SkuID].findIndex(({ SkuWarehouseLocationID }) => SkuWarehouseLocationID === locationitem['SkuWarehouseLocationID']);

    var nonNegativeValue
    nonNegativeValue = numbersInputMask(e)

    try {
        var limitValue = originalProps[item.SkuID][itemIndex]['Units']
    }
    catch (e) {
        var limitValue = 0
    }

    if (nonNegativeValue <= limitValue) {
        return nonNegativeValue
    }
    else {
        window.alert('Must not be greater than ' + limitValue.toString())
        return limitValue
    }
}

function limitNumberToExistingValue(e, locationitem, item, originalProps) {
    var itemIndex = originalProps[item.SkuID].findIndex(({ SkuWarehouseLocationID }) => SkuWarehouseLocationID === locationitem['SkuWarehouseLocationID']);

    var nonNegativeValue
    nonNegativeValue = numbersInputMask(e)

    try {
        var limitValue = originalProps[item.SkuID][itemIndex]['Cases']
    }
    catch (e) {
        var limitValue = 0
    }

    if (nonNegativeValue <= limitValue) {
        return nonNegativeValue
    }
    else {
        window.alert('Must not be greater than ' + limitValue.toString())
        return limitValue
    }
}

const mountRef = React.createRef();

const BuildPalletDrawer = ({ onChangePalletDrawer, removeCasePalletDrawer, closePalletDrawer, savePalletDrawer, placement, state, ...props }) => (

    <Drawer show={state.showBuildPalletDrawer} onHide={closePalletDrawer} placement={placement} style={props.isMobile ? { width: '350px' } : { width: '800px' }}    >
        <Drawer.Header>
            <Drawer.Title>Build New Pallet</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body > <div ref={mountRef}>

            <Panel bordered style={{ marginBottom: '15px' }}>

                <Row style={{ paddingRight: '10px' }}>
                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                        <InputPicker
    preventOverflow
                            style={{ width: '100%' }}
                            container={() => mountRef.current}
                            // placeholder={label}
                            placeholder={'Location...'}
                            value={state.NewPalletLocation}
                            onChange={(e) => { onChangePalletDrawer(e, 'NewPalletLocation') }}
                            data={props.WarehouseLocationsData}
                            block
                            cleanable={true}
                        />
                    </InputGroup>
                </Row>

                <Row style={{ paddingRight: '10px' }}>
                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                        <Checkbox checked={state.NewPalletOversize} onChange={(e, checked) => { onChangePalletDrawer(checked, 'NewPalletOversize') }} >Oversize</Checkbox>
                    </InputGroup>
                </Row>

                <Row >
                    <Col md={12} sm={24}>
                        <ButtonToolbar >
                            <DebouncedButton  onClick={(e) => { savePalletDrawer(e) }} appearance="primary" block={true} buttonText='Save' />

                        </ButtonToolbar>
                    </Col>
                    <Col md={12} sm={24}>
                        <ButtonToolbar >
                            <Button block onClick={(e) => { closePalletDrawer(e) }}  appearance="default">Cancel</Button>
                        </ButtonToolbar>
                    </Col>
                </Row>

            </Panel>





            {state.BuildNewPalletDict.map(item => (

                        <Row >
                            <Col md={24} sm={24}>
                                <Panel bordered style={{ marginBottom: '10px' }}>

                                    <Row>
                                        <Col md={14} sm={24}>
                                            Case Barcode: <strong>{item.CaseBarcode}</strong>
                                            <br />
                                            Pallet Barcode: <strong>{item.PalletBarcode}</strong>
                                            <br />
                                            Warehouse Location: <strong>{item.WarehouseLocationName}</strong>
                                        </Col>

                                        <Col md={6} sm={24}>

                                            {/* <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                                <InputGroup.Addon>Total Units:</InputGroup.Addon>
                                                <InputNumber readOnly value={item.UnitsInWhse} className={'custom-input-number'} scrollable={false} />
                                            </InputGroup> */}

                                        </Col>
                                        <Col md={4} sm={24}>
                                            <Button style={{ float: 'right' }} onClick={(e) => { removeCasePalletDrawer(e, item) }} appearance="primary">Remove</Button>
                                            <br />
                                        </Col>
                                    </Row>

                                    {/* <Divider /> */}

                                </Panel>
                            </Col>
                        </Row>
            ))}



        </div> </Drawer.Body>
        <Drawer.Footer>

            <br />

        </Drawer.Footer>
    </Drawer>
);



export default BuildPalletDrawer;

