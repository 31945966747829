import React, {
    PureComponent
} from 'react';
import { withRouter } from 'react-router-dom';
import {
    Form,
    Col,
    Loader,
    Row,
    Button,
    Panel
} from 'rsuite';
import { queryStringParse, formatDateForAPI, findLowestMissingNumber, formatDateForUrlSearch } from '../../../resources/logic/functions';
import StatsCard from '../../common/StatsCard/StatsCard';
// import { ResponsivePie } from '@nivo/pie' //npm i @nivo/pie
import { appSettings } from '../../../app/appSettings';
import RTableGeneral from '../../componentsGeneral/Rsuite/Table/RTableGeneral';
import ReactExport from "react-export-excel";
import * as moment from 'moment'
import CtrlTwrShipmentAlertsDataElements from '../../dataComponentsControlTower/CtrlTwrShipmentAlertsDataElements';
import DashboardShipListDrawer from './DashboardShipListDrawer';
import RButtonHideShowFilter from '../../componentsGeneral/Rsuite/Button/RButtonHideShowFilter'
import RDatePickerRange from '../../componentsGeneral/Rsuite/DatePicker/RDatePickerRange';
import CtrlTwrShipmentDeliveryDataElements from '../../dataComponentsControlTower/CtrlTwrShipmentDeliveryDataElements';
// import ShipmentDeliveryDateChartContainer from '../Charts/ShipmentDeliveryDateChart/ShipmentDeliveryDateChartContainer';
// import ShipmentDeliveryHistoryChartContainer from '../Charts/ShipmentDeliveryHistoryChart/ShipmentDeliveryHistoryChartContainer';
import GoogleMapTrackerContainer from '../../common/GoogleMap/GoogleMapTrackerContainer';
import CtrlTwrShipmentMilestoneAlertDataElements from '../../dataComponentsControlTower/CtrlTwrShipmentMilestoneAlertDataElements';
import VisibilityPackageDataElements from '../../dataComponentsVisibility/VisibilityPackageDataElements';
import queryString from 'query-string';

//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")

const tableIdCol = "PackageID"




class Dashboard extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

            //Shipment List Drawer
            ShipmentListDrawerData: {},
            shipListDrawerDataElements: null,
            showShipListDrawer: false,
            ShipListDrawerType: '',

            //Search Values
            isFilterShown: true,
            searchDateStart: null,
            searchDateEnd: null,

        };

        // Table Sort
        this.handleSortColumn = this.handleSortColumn.bind(this);

        this.onClickResetFilters = this.onClickResetFilters.bind(this);
        this.onClickIsFilterShown = this.onClickIsFilterShown.bind(this);

        //Search Values
        this.onChangeSearchDateStart = this.onChangeSearchDateStart.bind(this);
        this.onChangeSearchDateEnd = this.onChangeSearchDateEnd.bind(this);


        this.onClickShipDeliveryChart = this.onClickShipDeliveryChart.bind(this);
        this.onClickShipDeliveryHistoryChart = this.onClickShipDeliveryHistoryChart.bind(this);

        //Shipment List Drawer
        this.onClickShipDetails = this.onClickShipDetails.bind(this);
        this.setShipListDrawerToClose = this.setShipListDrawerToClose.bind(this);
        this.closeShipListDrawer = this.closeShipListDrawer.bind(this);

    }





    componentDidMount() {
        this.getsessionStorageWarehouse() //this will preserve menu selection
        this.loadSortFromStorage()

        this.updateParamsFromURL()

        this.setState({
            ShipmentListDrawerData: {},
            ShipListDrawerType: '',
            shipListDrawerDataElements: null
        });
    }

    getsessionStorageWarehouse() {
        try {
            let warehouseMenuList = JSON.parse(sessionStorage.getItem('AppConfig.WarehouseArray'));
            if (warehouseMenuList != null && warehouseMenuList != undefined) {
                this.setState({
                    warehouseArray: warehouseMenuList
                })
            }
        }
        catch (e) { }

        try {
            let customerMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CustomerArray'));
            if (customerMenuList != null && customerMenuList != undefined) {
                this.setState({
                    customerArray: customerMenuList
                })
            }
        }
        catch (e) { }
    }


    updateParamsFromURL = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var date = queryStringParse(values.datestart)
            if (date === null) {
                var paramDateStart = null
            }
            else {
                var dateValue = new Date(date);
                var dayAdd = moment(dateValue).add(1, 'days')
                var paramDateStart = dayAdd
            }
        }
        catch (e) {
            var paramDateStart = null
        }
        if (paramDateStart != null) {
            this.onChangeSearchDateStart(paramDateStart)
        }

        try {
            var date = queryStringParse(values.dateend)
            if (date === null) {
                var paramDateEnd = null
            }
            else {
                var dateValue = new Date(date);
                var dayAdd = moment(dateValue).add(1, 'days')
                var paramDateEnd = dayAdd
            }
        }
        catch (e) {
            var paramDateEnd = null
        }
        this.onChangeSearchDateEnd(paramDateEnd)

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.setState({
                // checkedKeys: []
            });

            this.setState({
                ShipmentListDrawerData: {},
                ShipListDrawerType: '',
                shipListDrawerDataElements: null
            });
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.setState({
                // checkedKeys: []
            });

            this.setState({
                ShipmentListDrawerData: {},
                ShipListDrawerType: '',
                shipListDrawerDataElements: null
            });
        }

        // isVisibilityDashboardPackageListLoading
        if (prevProps.VisibilityDashboardPackageListData !== this.props.VisibilityDashboardPackageListData) {
            var newdata = this.props.VisibilityDashboardPackageListData
            this.setState({
                ShipmentListDrawerData: newdata
            });
            this.forceUpdate()

        }

        // if (prevProps.CtrlTwrChartShipmentDeliveryDetailsData !== this.props.CtrlTwrChartShipmentDeliveryDetailsData) {
        //     var newdata = this.props.CtrlTwrChartShipmentDeliveryDetailsData
        //     this.setState({
        //         ShipmentListDrawerData: newdata
        //     });
        //     this.forceUpdate()
        // }

        // if (prevProps.CtrlTwrChartShipmentDeliveryHistoryDetailsData !== this.props.CtrlTwrChartShipmentDeliveryHistoryDetailsData) {
        //     var newdata = this.props.CtrlTwrChartShipmentDeliveryHistoryDetailsData
        //     this.setState({
        //         ShipmentListDrawerData: newdata
        //     });
        //     this.forceUpdate()
        // }



    }


    // Table Sort
    saveSortTosessionStorage(sortColumn, sortType) {
        try {
            sessionStorage.setItem('sortcolumn', sortColumn)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortType', sortType)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortURLPathname', this.props.location.pathname)
        }
        catch (e) { }
    }
    loadSortFromStorage() {
        var sortColumn = null
        var sortType = null
        var pathName = null
        try {
            sortColumn = sessionStorage.getItem('sortcolumn')
        }
        catch (e) { }
        try {
            sortType = sessionStorage.getItem('sortType')
        }
        catch (e) { }
        try {
            pathName = sessionStorage.getItem('sortURLPathname')
        }
        catch (e) { }
        if (pathName === this.props.location.pathname) {
            
            this.handleSortColumn(sortColumn, sortType)
        }
    }
    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });
        this.saveSortTosessionStorage(sortColumn, sortType)
        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 100);
    }


    // Search Filters
    onClickIsFilterShown(e, isFilterShown) {
        if (isFilterShown) {
            this.setState({
                isFilterShown: false
            });
        }
        else {
            this.setState({
                isFilterShown: true
            });
        }
    }

    onClickResetFilters(e) {
        this.setState({
            isFilterShown: false
        });
        this.setState({
            searchDateStart: null,
            searchDateEnd: null

        });
        this.props.history.push({
            search: ''
        });

        setTimeout(() => {
            this.setState({
                isFilterShown: true
            });
        }, 500);
    }

    onClickApplySearch(e) {
        try {
            var searchDateStart = this.state.searchDateStart
            var searchDateEnd = this.state.searchDateEnd

            if (searchDateStart == null) {
                window.alert('Please select Start Date.')
            }
            else {
                this.props.history.push({
                    search:
                        'datestart=' + formatDateForUrlSearch(searchDateStart) + '&' +
                        'dateend=' + formatDateForUrlSearch(searchDateEnd)
                });
            }
        }
        catch (e) { }

    }

    onSearchClearOtherFilters() {
        this.setState({
            searchDateStart: null,
            searchDateEnd: null

        });
    }
    onChangeSearchDateStart(e) {
        this.setState({
            searchDateStart: e
        });
    }
    onChangeSearchDateEnd(e) {
        this.setState({
            searchDateEnd: e
        });
    }




    onClickStatCard(e, statCard) {

        const values = queryString.parse(this.props.location.search)



        try {
            var paramDateStart = formatDateForAPI(values.datestart)
        }
        catch (e) {
            var paramDateStart = null
        }
        try {
            var paramDateEnd = formatDateForAPI(values.dateend)
        }
        catch (e) {
            var paramDateEnd = null
        }



        this.setState({
            ShipmentListDrawerData: {},
            ShipListDrawerType: '',
            shipListDrawerDataElements: VisibilityPackageDataElements
        });

        if (statCard === 'CountOfAddressUpdates') {
            this.setState({
                ShipListDrawerType: 'Address Updates'
            });
            this.props.visibilityDashboardPackageListGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.customerSelection, null, null, paramDateStart, paramDateEnd, 1, null, null, null, null, null, null)
        }
        else if (statCard === 'CountOfTransitExceptions') {
            this.setState({
                ShipListDrawerType: 'Transit Exceptions'
            });
            this.props.visibilityDashboardPackageListGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.customerSelection, null, null, paramDateStart, paramDateEnd, null, 1, null, null, null, null, null)
        }
        else if (statCard === 'CountOfDeliveryExceptions') {
            this.setState({
                ShipListDrawerType: 'Delivery Exceptions'
            });
            this.props.visibilityDashboardPackageListGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.customerSelection, null, null, paramDateStart, paramDateEnd, null, null, 1, null, null, null, null)
        }
        else if (statCard === 'CountOfDelivered') {
            this.setState({
                ShipListDrawerType: 'Delivered'
            });
            this.props.visibilityDashboardPackageListGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.customerSelection, null, null, paramDateStart, paramDateEnd, null, null, null, 1, null, null, null)
        }
        else if (statCard === 'CountOfDamaged') {
            this.setState({
                ShipListDrawerType: 'Damaged - RTS'
            });
            this.props.visibilityDashboardPackageListGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.customerSelection, null, null, paramDateStart, paramDateEnd, null, null, null, null, 1, null, null)
        }
        else if (statCard === 'CountOfInsufficientAdd') {
            this.setState({
                ShipListDrawerType: 'Insufficient Address'
            });
            this.props.visibilityDashboardPackageListGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.customerSelection, null, null, paramDateStart, paramDateEnd, null, null, null, null, null, 1, null)
        }
        else if (statCard === 'CountOfRTS') {
            this.setState({
                ShipListDrawerType: 'Return to Sender'
            });
            this.props.visibilityDashboardPackageListGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.customerSelection, null, null, paramDateStart, paramDateEnd, null, null, null, null, null, null, 1)
        }
        else if (statCard === 'CountOfInTransit') {
            this.setState({
                ShipListDrawerType: 'In Transit'
            });
            this.props.visibilityDashboardPackageListGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.customerSelection, null, null, paramDateStart, paramDateEnd, null, null, null, null, null, null, null)
        }

        this.setState({
            showShipListDrawer: true,
        });

    }



    onClickAlerts = (data, event) => {

        this.setState({
            ShipmentListDrawerData: {},
            ShipListDrawerType: '',
            shipListDrawerDataElements: CtrlTwrShipmentAlertsDataElements
        });

        var alertType = data.id + 'Hours'

        var newArrayTableData = []

        //this filters customers to be shown according to the app settings customer/product matrix
        for (let key in this.props.CtrlTwrAlertsShipmentDepartureData) {
            if (this.props.CtrlTwrAlertsShipmentDepartureData[key][alertType] > 0 && this.props.CtrlTwrAlertsShipmentDepartureData[key]['ActiveAlert'] === 1) {
                newArrayTableData.push({ Alert: "Departure", ProRef: this.props.CtrlTwrAlertsShipmentDepartureData[key]["ProRef"], Leg: this.props.CtrlTwrAlertsShipmentDepartureData[key]["LegNumber"], Mode: this.props.CtrlTwrAlertsShipmentDepartureData[key]["ModeName"], LegID: this.props.CtrlTwrAlertsShipmentDepartureData[key]["LegID"] })
            }
        }

        for (let key in this.props.CtrlTwrAlertsShipmentDeliveryData) {
            if (this.props.CtrlTwrAlertsShipmentDeliveryData[key][alertType] > 0 && this.props.CtrlTwrAlertsShipmentDeliveryData[key]['ActiveAlert'] === 1) {
                newArrayTableData.push({ Alert: "Delivery", ProRef: this.props.CtrlTwrAlertsShipmentDeliveryData[key]["ProRef"], Leg: this.props.CtrlTwrAlertsShipmentDeliveryData[key]["LegNumber"], Mode: this.props.CtrlTwrAlertsShipmentDeliveryData[key]["ModeName"], LegID: this.props.CtrlTwrAlertsShipmentDeliveryData[key]["LegID"] })
            }
        }


        this.setState({
            ShipListDrawerType: data.label,
            ShipmentListDrawerData: newArrayTableData
        });

        this.setState({
            showShipListDrawer: true,
        });

        this.forceUpdate()
    }




    onClickShipDeliveryChart = () => {

        this.setState({
            ShipListDrawerType: 'Shipment Schedule',
            shipListDrawerDataElements: CtrlTwrShipmentDeliveryDataElements
        });

        this.setState({
            showShipListDrawer: true,
        });

    }



    onClickShipDeliveryHistoryChart = () => {

        this.setState({
            ShipListDrawerType: 'Shipment History',
            shipListDrawerDataElements: CtrlTwrShipmentDeliveryDataElements
        });

        this.setState({
            showShipListDrawer: true,
        });

    }

    //Shipment List Drawer


    closeShipListDrawer() {
        this.setShipListDrawerToClose()
    }

    setShipListDrawerToClose() {
        this.setState({
            showShipListDrawer: false,
            ShipListDrawerType: '',
            ShipmentListDrawerData: {},
            ShipListDrawerType: '',
            shipListDrawerDataElements: null
        });
    }




    //Table on click details
    onClickShipDetails(e, rowData, history) {
        try {
            var paramID = rowData['PackageID']
            history.push({
                pathname: 'packagedetails',
                search: 'packageid=\'' + paramID + '\''
            });
        }
        catch (e) {
        }

    }

    returnColor(color, value) {
        try {
            if (Number(value) > 0) {
                return color
            }
            else {
                return 'white'
            }
        }
        catch (e) {
            return 'white'
        }
    }

    render() {
        const { sortColumn, sortType } = this.state;


        let hideShowSearch;
        hideShowSearch = <Row style={{ marginBottom: '5px' }}>

            <Col md={24} sm={24} >

                <p>
                    {RButtonHideShowFilter(this.props.isMobile, this.state.isFilterShown, 'Filters', this.onClickIsFilterShown, this.onClickResetFilters)}
                </p>

            </Col>
        </Row>

        let searchDiv;

        if (this.state.isFilterShown) {
            searchDiv = <Row  >
                <Col md={24} sm={24} >

                    <Panel bordered style={{ marginBottom: '10px' }}>
                        <Row>
                            {RDatePickerRange(
                                this.props.isMobile,
                                'Date',
                                this.onChangeSearchDateStart,
                                this.onChangeSearchDateEnd,
                                this.state.searchDateStart,
                                this.state.searchDateEnd
                            )}
                        </Row>

                        <Row>
                            <Button onClick={(e) => { this.onClickApplySearch(e) }} style={{ marginRight: '10px' }}> Apply </Button>
                            <Button onClick={(e) => { this.onClickResetFilters(e) }}> Reset </Button>
                        </Row>
                    </Panel>
                </Col>
            </Row>
        }
        else if (!this.state.isFilterShown) {
            searchDiv = <div></div>
        }


        let statCardsDiv;
        if (this.props.isVisibilityDashboardStatsLoading) {
            statCardsDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (this.props.isVisibilityDashboardPackageListLoading) {
            statCardsDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.VisibilityDashboardStatsData[0]) !== 'undefined' && this.props.VisibilityDashboardStatsData != null) {

            try {
                var CountOfInTransit = String(this.props.VisibilityDashboardStatsData[0].CountOfInTransit);
            }
            catch (e) {
                var CountOfInTransit = 0
            }

            try {
                var CountOfAddressUpdates = String(this.props.VisibilityDashboardStatsData[0].CountOfAddressUpdates);
            }
            catch (e) {
                var CountOfAddressUpdates = 0
            }

            try {
                var CountOfTransitExceptions = String(this.props.VisibilityDashboardStatsData[0].CountOfTransitExceptions);
            }
            catch (e) {
                var CountOfTransitExceptions = 0
            }

            try {
                var CountOfDeliveryExceptions = String(this.props.VisibilityDashboardStatsData[0].CountOfDeliveryExceptions);
            }
            catch (e) {
                var CountOfDeliveryExceptions = 0
            }

            try {
                var CountOfDelivered = String(this.props.VisibilityDashboardStatsData[0].CountOfDelivered);
            }
            catch (e) {
                var CountOfDelivered = 0
            }



            try {
                var CountOfDamaged = String(this.props.VisibilityDashboardStatsData[0].CountOfDamaged);
            }
            catch (e) {
                var CountOfDamaged = 0
            }


            try {
                var CountOfInsufficientAdd = String(this.props.VisibilityDashboardStatsData[0].CountOfInsufficientAdd);
            }
            catch (e) {
                var CountOfInsufficientAdd = 0
            }


            try {
                var CountOfRTS = String(this.props.VisibilityDashboardStatsData[0].CountOfRTS);
            }
            catch (e) {
                var CountOfRTS = 0
            }


            statCardsDiv = <Form fluid>
                <Row>



                    <Col md={6} sm={24}>
                        <StatsCard
                            statValue={CountOfInTransit}
                            statLabel={'In Transit'}
                            icon={<i className="fa fa-truck" />}
                            iconColor={this.returnColor('green', CountOfInTransit)}
                            allowClick={true}
                            onClickStatCard={(e) => { this.onClickStatCard(e, 'CountOfInTransit') }}
                            isMobile={this.props.isMobile}
                        />
                    </Col>


                    <Col md={6} sm={24}>
                        <StatsCard
                            statValue={CountOfDelivered}
                            statLabel={'Delivered'}
                            icon={<i className="fa fa-check-square" />}
                            iconColor={this.returnColor('green', CountOfDelivered)}
                            allowClick={true}
                            onClickStatCard={(e) => { this.onClickStatCard(e, 'CountOfDelivered') }}
                            isMobile={this.props.isMobile}
                        />
                    </Col>


                    <Col md={6} sm={24}>
                        <StatsCard
                            statValue={CountOfTransitExceptions}
                            statLabel={'Transit Exceptions'}
                            icon={<i className="fa fa-exclamation" />}
                            iconColor={this.returnColor('red', CountOfTransitExceptions)}
                            allowClick={true}
                            onClickStatCard={(e) => { this.onClickStatCard(e, 'CountOfTransitExceptions') }}
                            isMobile={this.props.isMobile}
                        />
                    </Col>
                    <Col md={6} sm={24}>
                        <StatsCard
                            statValue={CountOfDeliveryExceptions}
                            statLabel={'Delivery Exceptions'}
                            icon={<i className="fa fa-exclamation-triangle" />}
                            iconColor={this.returnColor('red', CountOfDeliveryExceptions)}
                            allowClick={true}
                            onClickStatCard={(e) => { this.onClickStatCard(e, 'CountOfDeliveryExceptions') }}
                            isMobile={this.props.isMobile}
                        />
                    </Col>




                </Row>

                <Row>


                    <Col md={6} sm={24}>
                        <StatsCard
                            statValue={CountOfDamaged}
                            statLabel={'Damaged - RTS'}
                            icon={<i className="fa fa-bolt" />}
                            iconColor={this.returnColor('red', CountOfDamaged)}
                            allowClick={true}
                            onClickStatCard={(e) => { this.onClickStatCard(e, 'CountOfDamaged') }}
                            isMobile={this.props.isMobile}
                        />
                    </Col>

                    <Col md={6} sm={24}>
                        <StatsCard
                            statValue={CountOfAddressUpdates}
                            statLabel={'Address Updates'}
                            icon={<i className="fa fa-address-card" />}
                            // iconColor={'yellow'}
                            iconColor={this.returnColor('yellow', CountOfAddressUpdates)}
                            allowClick={true}
                            onClickStatCard={(e) => { this.onClickStatCard(e, 'CountOfAddressUpdates') }}
                            isMobile={this.props.isMobile}
                        />
                    </Col>


                    <Col md={6} sm={24}>
                        <StatsCard
                            statValue={CountOfInsufficientAdd}
                            statLabel={'Insufficient Address'}
                            icon={<i className="fa fa-address-book" />}
                            iconColor={this.returnColor('red', CountOfInsufficientAdd)}
                            allowClick={true}
                            onClickStatCard={(e) => { this.onClickStatCard(e, 'CountOfInsufficientAdd') }}
                            isMobile={this.props.isMobile}
                        />
                    </Col>
                    <Col md={6} sm={24}>
                        <StatsCard
                            statValue={CountOfRTS}
                            statLabel={'Return to Sender'}
                            icon={<i className="fa fa-backward" />}
                            iconColor={this.returnColor('red', CountOfRTS)}
                            allowClick={true}
                            onClickStatCard={(e) => { this.onClickStatCard(e, 'CountOfRTS') }}
                            isMobile={this.props.isMobile}
                        />
                    </Col>



                    {/* <Col md={12} sm={24}>
                        <StatsCard
                            statValue={HotShipment}
                            statLabel={'Hot Shipments'}
                            icon={<i className="fa fa-exclamation-triangle" />}
                            iconColor={this.returnColor('red', CountOfAddressUpdates)}
                            allowClick={true}
                            onClickStatCard={(e) => { this.onClickStatCard(e, 'HotShipments') }}
                            isMobile={this.props.isMobile}
                        />
                    </Col> */}




                    {/* <Col md={6} sm={24}>
                        <StatsCard
                            statValue={0}
                            statLabel={'Carrier Actions'}
                            icon={<i className="fa fa-flag" />}
                            iconColor={'yellow'}
                            allowClick={true}
                            onClickStatCard={(e) => { this.onClickStatCard(e, 'CarrierActions')}}
                            isMobile={this.props.isMobile}
                        />
                    </Col> */}
                    {/* <Col md={6} sm={24}>
                        <StatsCard
                            statValue={1}
                            statLabel={'Customer Actions'}
                            icon={<i className="fa fa-flag" />}
                            iconColor={'blue'}
                            allowClick={true}
                            onClickStatCard={(e) => { this.onClickStatCard(e, 'CustomerActions')}}
                            isMobile={this.props.isMobile}
                        />
                    </Col> */}
                </Row>
            </Form>


        }





        // let alertPie;
        // if (this.props.isCtrlTwrAlertsShipmentSummaryLoading) {
        //     alertPie =
        //         <div align="center">
        //             <section className="panel">
        //             </section>
        //             <Loader
        //                 type="TailSpin"
        //                 color="#3396E5"
        //                 height="50"
        //                 width="50"
        //             />
        //         </div>
        // }
        // else if (this.props.CtrlTwrAlertsShipmentSummaryData.reduce((a, v) => a = a + v.value, 0) > 0) {
        //     alertPie =
        //         <Panel bordered>
        //             <section className="panel">
        //                 <header className="panel-heading">
        //                     {'Milestone Alerts Status'}
        //                 </header>
        //                 <div style={{ height: 400, background: "#FFF", cursor: "pointer" }}
        //                 >
        //                     <ResponsivePie
        //                         data={this.props.CtrlTwrAlertsShipmentSummaryData}
        //                         margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        //                         innerRadius={0.5}
        //                         padAngle={0.7}
        //                         cornerRadius={3}
        //                         colors={{ scheme: 'nivo' }}
        //                         borderWidth={0}
        //                         // borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
        //                         borderColor="black"
        //                         radialLabelsSkipAngle={10}
        //                         radialLabelsTextXOffset={6}
        //                         radialLabelsTextColor="#333333"
        //                         radialLabelsLinkOffset={0}
        //                         radialLabelsLinkDiagonalLength={16}
        //                         radialLabelsLinkHorizontalLength={24}
        //                         radialLabelsLinkStrokeWidth={1}
        //                         // radialLabelsLinkColor={{ from: 'color' }}
        //                         radialLabelsLinkColor="black"
        //                         slicesLabelsSkipAngle={10}
        //                         slicesLabelsTextColor="#333333"
        //                         animate={true}
        //                         motionStiffness={90}
        //                         motionDamping={15}
        //                         onClick={(data, event) => this.onClickAlerts(data, event)}
        //                         defs={[
        //                             {
        //                                 id: 'carrierred',
        //                                 type: 'patternLines',
        //                                 background: 'inherit',
        //                                 color: '#f43e1f',
        //                                 rotation: -45,
        //                                 lineWidth: 6,
        //                                 spacing: 10
        //                             },
        //                             {
        //                                 id: 'carrieryellow',
        //                                 type: 'patternLines',
        //                                 background: 'inherit',
        //                                 color: '#f1e15b',
        //                                 rotation: -45,
        //                                 lineWidth: 6,
        //                                 spacing: 10
        //                             },
        //                             {
        //                                 id: 'customerred',
        //                                 type: 'patternLines',
        //                                 background: '#f43e1f',
        //                                 color: '#f43e1f',
        //                                 // rotation: -45,
        //                                 // lineWidth: 30,
        //                                 // spacing: 1
        //                             },
        //                             {
        //                                 id: 'customeryellow',
        //                                 type: 'patternLines',
        //                                 background: '#f1e15b',
        //                                 color: '#f1e15b',
        //                                 // rotation: -45,
        //                                 // lineWidth: 30,
        //                                 // spacing: 1
        //                             },
        //                             {
        //                                 id: 'green',
        //                                 type: 'patternLines',
        //                                 background: 'inherit',
        //                                 color: '#37ba30',
        //                                 rotation: -45,
        //                                 lineWidth: 30,
        //                                 spacing: 1
        //                             },
        //                             {
        //                                 id: 'redstripe',
        //                                 type: 'patternLines',
        //                                 background: '#f4a91f',
        //                                 color: '#f43e1f',
        //                                 rotation: -45,
        //                                 lineWidth: 6,
        //                                 spacing: 10
        //                             },
        //                             {
        //                                 id: 'yellowstripe',
        //                                 type: 'patternLines',
        //                                 background: '#fbc5bb',
        //                                 color: '#f1e15b',
        //                                 rotation: -45,
        //                                 lineWidth: 6,
        //                                 spacing: 10
        //                             },

        //                         ]}
        //                         fill={[
        //                             // {
        //                             //     match: {
        //                             //         id: 'CarrierRed'
        //                             //     },
        //                             //     id: 'carrierred',
        //                             //     // label: 'test'
        //                             // },
        //                             // {
        //                             //     match: {
        //                             //         id: 'CarrierYellow'
        //                             //     },
        //                             //     id: 'carrieryellow'
        //                             // },
        //                             {
        //                                 match: {
        //                                     id: 'CustomerRed'
        //                                 },
        //                                 id: 'customerred'
        //                             },
        //                             {
        //                                 match: {
        //                                     id: 'CustomerYellow'
        //                                 },
        //                                 id: 'customeryellow'
        //                             }
        //                         ]}
        //                         legends={[
        //                             {
        //                                 anchor: 'bottom',
        //                                 direction: 'row',
        //                                 translateY: 56,
        //                                 itemWidth: 100,
        //                                 itemHeight: 18,
        //                                 itemTextColor: '#999',
        //                                 symbolSize: 18,
        //                                 symbolShape: 'circle',
        //                                 effects: [
        //                                     {
        //                                         on: 'hover',
        //                                         style: {
        //                                             itemTextColor: '#000'
        //                                         }
        //                                     }
        //                                 ]
        //                             }
        //                         ]}
        //                     />


        //                 </div>
        //             </section>
        //         </Panel>
        // }
        // else {
        //     alertPie =
        //         <Panel bordered>
        //             <div align="center">
        //                 <section className="panel">
        //                     <header className="panel-heading">
        //                         {'Milestone Alerts Status'}
        //                     </header>

        //                     <div className="panel-body table-responsive"
        //                         style={{
        //                             backgroundColor: '#FFF'
        //                             // , height: '400px' 
        //                         }}>


        //                         <i className="fa fa-check-square-o" style={{ color: `${appSettings.colorPalatte.CaribbeanGreen}` }} title="No milestone alerts"> &nbsp;</i>
        //                         No Milestone Alerts


        //                     </div>
        //                 </section>
        //             </div>
        //         </Panel>
        // }


        let ShipListDrawer;
        if (typeof (this.state.ShipmentListDrawerData.length) === 'undefined' || this.state.ShipmentListDrawerData.length < 1) {
            ShipListDrawer =
                <div align="center"></div>
        }
        else if (typeof (this.state.ShipmentListDrawerData) !== 'undefined' && this.state.ShipmentListDrawerData != null) {
            ShipListDrawer = <DashboardShipListDrawer {...this.props} state={this.state} shipListDrawerDataElements={this.state.shipListDrawerDataElements} onChangeMilestoneEventEntry={this.onChangeMilestoneEventEntry} onChangeWarehouse={this.onChangeWarehouse} onChangeCustomer={this.onChangeCustomer} onChangePO={this.onChangePO} onChangeOriginCountry={this.onChangeOriginCountry} onChangeETD={this.onChangeETD} onClickShipDetails={this.onClickShipDetails} close={this.closeShipListDrawer} />
        }
        else {
            ShipListDrawer = <div></div>
        }




        return (
            <div>
                <h3>
                    Visibility - Dashboard
                </h3>

                <br />
                <br />

                {hideShowSearch}
                {searchDiv}


                {statCardsDiv}

                <Form fluid>
                    <Row style={{ marginBottom: '10px' }}>
                        <Col md={24} sm={24}>
                            {/* {alertPie} */}
                        </Col>
                    </Row>
                    {/* <Row style={{ marginBottom: '10px' }}>
                        <Col md={12} sm={24}>
                            {ShipDeliveryChart}
                        </Col>
                        <Col md={12} sm={24}>
                            {ShipDeliveryHistoryChart}
                        </Col>
                    </Row> */}
                </Form>





                {/* <Panel bordered>
                    <div style={{ height: '600px', width: '100%', position: 'relative', marginRight: '30px', marginBottom: '40px' }} >
                        <GoogleMapTrackerContainer />
                    </div>
                </Panel> */}

                {ShipListDrawer}
            </div >
        );

    }
}

export default withRouter(Dashboard);


