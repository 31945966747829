import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './App.css';
import PropTypes from 'prop-types'
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import PrivateRoute from './components/componentsGeneral/PrivateRoute'
import vasView from './views/ViewsVas/vasView/vasView'
import CognitoAuth from './components/componentsUser/CognitoAuth'
import SignIn from './components/componentsUser/SignIn'
import { state } from 'aws-cognito-redux-saga'
import SignInAuthView from './views/viewsUser/SignInAuthView/SignInAuthView'
import Header from './components/componentsGeneral/Header'
import Footer from './components/componentsGeneral/Footer/Footer'
import userSignUpView from './views/viewsUser/userSignUp/userSignUpView';
import ResetPassword from './components/componentsUser/ResetPassword'
import ChangePassword from './components/componentsUser/ChangePassword'
import ForceChangePassword from './components/componentsUser/ForceChangePassword'
import { customerConfig } from './app/appCustomerConfig';
import homeView from './views/ViewsGeneral/LandingView/homeView';

class App extends React.Component {
  static propTypes = {
    auth: PropTypes.object
  }

  constructor(props) {
    super(props)
  }

  render() {
    const { auth } = this.props

    return (


      <div >
        <CognitoAuth />
        <Header />
        <Switch>
          {/* <Route path={`/`} component={homeView} /> */}
          <PrivateRoute path={`/${customerConfig.customerUrl}/wms`} component={vasView} />
          <PrivateRoute path={`/${customerConfig.customerUrl}/auth`} component={SignInAuthView} />
          <Route path={`/${customerConfig.customerUrl}/signin`} component={SignIn} />
          <Route path={`/${customerConfig.customerUrl}/signup`} component={userSignUpView} />
          <Route path={`/${customerConfig.customerUrl}/resetpassword`} component={ResetPassword} />
          <Route path={`/${customerConfig.customerUrl}/changepassword`} component={ChangePassword} />
          <Route path={`/${customerConfig.customerUrl}/forcechangepassword`} component={ForceChangePassword} />

          <Route
            // path="/"
            path={`/${customerConfig.customerUrl}/`}
            render={() =>
              auth.isSignedIn === state.AUTH_SUCCESS ? (
                <Redirect to={`/${customerConfig.customerUrl}/auth`} />
              ) : (
                <Redirect to={`/${customerConfig.customerUrl}/signin`} />
              )
            }
          />
        </Switch>
        <Footer />
      </div>






    )
  }
}

export default App