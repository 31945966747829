import * as types from '../constants/ctrlTwrActionTypes';
import { appSettings } from '../../app/appSettings'

const initialState = {

    isFileDownloadLoading: false,
    FileDownloadData: [],

    isCtrlTwrGenerateProLoading: false,
    CtrlTwrGenerateProData: [],

    isCtrlTwrModeRefLoading: false,
    CtrlTwrModeRefData: [],

    isCtrlTwrAirportLoading: false,
    CtrlTwrAirportData: [],

    isCtrlTwrSeaportLoading: false,
    CtrlTwrSeaportData: [],

    isCtrlTwrCountryLoading: false,
    CtrlTwrCountryData: [],

    isCtrlTwrStateLoading: false,
    CtrlTwrStateData: [],

    isCtrlTwrMilestoneRefLoading: false,
    CtrlTwrMilestoneRefData: [],

    isCtrlTwrShipmentLoading: false,
    CtrlTwrShipmentData: [],

    CtrlTwrShipmentPostSuccessful: false,

    isCtrlTwrQuotingShipmentLoading: false,
    CtrlTwrQuotingShipmentData: [],

    CtrlTwrQuotingShipmentPostSuccessful: false,

    CtrlTwrQuotingShipmentStatusActionPostSuccessful: false,

    CtrlTwrQuotingShipmentLegsPostSuccessful: false,

    isCtrlTwrQuotingShipmentLegsQuoteLoading: false,
    CtrlTwrQuotingShipmentLegsQuoteData: [],

    isCtrlTwrQuotingLineItemLoading: false,
    CtrlTwrQuotingLineItemData: [],

    isCtrlTwrQuotingLineItemUomLoading: false,
    CtrlTwrQuotingLineItemUomData: [],

    isCtrlTwrQuotingLineItemRateTypeLoading: false,
    CtrlTwrQuotingLineItemRateTypeData: [],

    isCtrlTwrQuotingShipmentLineItemsLoading: false,
    CtrlTwrQuotingShipmentLineItemsData: [],

    isCtrlTwrQuotingShipmentFileUploadLoading: false,
    CtrlTwrQuotingShipmentFileUploadData: [],

    CtrlTwrQuotingShipmentFileUploadPostSuccessful: false,

    CtrlTwrShipmentLegsLineItemPostSuccessful: false,

    isCtrlTwrQuotingShipmentNotesPrivateLoading: false,
    CtrlTwrQuotinghipmentNotesPrivateData: [],

    isCtrlTwrQuotingShipmentNotesPublicLoading: false,
    CtrlTwrQuotingShipmentNotesPublicData: [],

    isCtrlTwrQuotingReportLoading: false,
    CtrlTwrQuotingReportData: [],

    isCtrlTwrShipmentNotesPrivateLoading: false,
    CtrlTwrShipmentNotesPrivateData: [],

    CtrlTwrShipmentNotesPrivatePostSuccessful: false,

    isCtrlTwrShipmentNotesPublicLoading: false,
    CtrlTwrShipmentNotesPublicData: [],

    CtrlTwrShipmentNotesPublicPostSuccessful: false,

    isCtrlTwrShipmentLegsDataLoading: false,
    CtrlTwrShipmentLegsData: [],

    CtrlTwrShipmentLegsPostSuccessful: false,

    CtrlTwrShipmentLegsDataPostSuccessful: false,

    isCtrlTwrShipmentLegsMilestonesLoading: false,
    CtrlTwrShipmentLegsMilestonesData: [],

    CtrlTwrShipmentLegsMilestonesPostSuccessful: false,

    CtrlTwrShipmentLegsMilestonesMultiPostSuccessful: false,

    isCtrlTwrShipmentLegsMilestonesMultiLoading: false,
    CtrlTwrShipmentLegsMilestonesMultiData: [],

    CtrlTwrShipmentLegsCustomerRefPostSuccessful: false,

    isCtrlTwrShipmentLegsCustomerRefLoading: false,
    CtrlTwrShipmentLegsCustomerRefData: [],

    CtrlTwrShipmentLegsPoPostSuccessful: false,

    isCtrlTwrShipmentLegsPoLoading: false,
    CtrlTwrShipmentLegsPoData: [],

    CtrlTwrShipmentLegsHbolPostSuccessful: false,

    isCtrlTwrShipmentLegsHbolLoading: false,
    CtrlTwrShipmentLegsHbolData: [],

    isCtrlTwrTimezoneLoading: false,
    CtrlTwrTimezoneData: [],

    isCtrlTwrAlertsShipmentDepartureLoading: false,
    CtrlTwrAlertsShipmentDepartureData: [],

    isCtrlTwrAlertsShipmentDeliveryLoading: false,
    CtrlTwrAlertsShipmentDeliveryData: [],

    isCtrlTwrAlertsShipmentSummaryLoading: false,
    CtrlTwrAlertsShipmentSummaryData: [],

    isCtrlTwrAlertsStatsLoading: false,
    CtrlTwrAlertsStatsData: [],

    CtrlTwrHotShipmentPostSuccessful: false,

    CtrlTwrShipmentUserSubscriptionPostSuccessful: false,

    isCtrlTwrChartShipmentDeliveryLoading: false,
    CtrlTwrChartShipmentDeliveryData: [],

    isCtrlTwrChartShipmentDeliveryDetailsLoading: false,
    CtrlTwrChartShipmentDeliveryDetailsData: [],

    isCtrlTwrChartShipmentDeliveryHistoryLoading: false,
    CtrlTwrChartShipmentDeliveryHistoryData: [],


    isCtrlTwrChartShipmentDeliveryHistoryDetailsLoading: false,
    CtrlTwrChartShipmentDeliveryHistoryDetailsData: [],

    isCtrlTwrShipmentFileUploadLoading: false,
    CtrlTwrShipmentFileUploadData: [],

    CtrlTwrShipmentFileUploadPostSuccessful: false,

    isEdi990QueueLoading: false,
    Edi990QueueData: [],

    Edi990QueuePostSuccessful: false,

    isEdi204Loading: false,
    Edi204Data: [],

    isEdi204OIDLoading: false,
    Edi204OIDData: [],

    isEdi204L5Loading: false,
    Edi204L5Data: []

};

function checkStatusCode(payload) {
    var errorType = 'None'
    try {
        errorType = payload['errorType']
    }
    catch (e) {
    }
    if (typeof (errorType) !== 'undefined') {
        return false
    }
    else {
        var res = payload['statusCode']
        try {
            if (res === "200" || res == 200 || res == 600 || res === "600") { return true }
            else { return false }
        }
        catch (e) {
            return false
        }
    }
}

function actionOnError(proc) {
    // console.log('DB connection error')
    // sessionStorage.clear();
    // window.location.reload();
    // // window.alert('Session expired, please log in again.')

    return initialState


}

function checkErrorMessage(msg) {

    console.log('msg', msg)
    // if(res === "200"){ return true }
    // else { return false }
}


export const CtrlTwrReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.RESET_REDUX_STATE:
            state = initialState;
            return state


        case types.ON_ERROR:
            var errorstate = actionOnError(state)
            return errorstate;









        /* ################################### COMMON ACTIONS ################################### */

        case types.DOWNLOAD_FILE_FROM_S3_GET:
            return Object.assign({}, state, {
                isFileDownloadLoading: true
            });
        case types.DOWNLOAD_FILE_FROM_S3_ERROR:
            window.alert("Error Downloading File.")
            return Object.assign({}, state, {
                isFileDownloadLoading: false
            });
        case types.DOWNLOAD_FILE_FROM_S3_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                window.alert("Error Downloading File.")
                return state;
            }
            var newData = JSON.parse(action.payload.body);

            try {
                window.open(newData.url)
            }
            catch (e) {
                window.alert('Error downloading file.')
            }

            // try {
            //     var temporaryDownloadLink = document.createElement("a");
            //     temporaryDownloadLink.style.display = 'none';

            //     document.body.appendChild(temporaryDownloadLink);



            //     temporaryDownloadLink.setAttribute('href', newData.url);
            //     // temporaryDownloadLink.setAttribute( 'download', download.name );

            //     temporaryDownloadLink.click();


            //     document.body.removeChild(temporaryDownloadLink);
            // }
            // catch (e) {
            //     window.alert('Error downloading file.')
            // }


            // initiate download url
            // try {
            // window.location.href = newData.url;




            // // var windowName = 'userConsole';
            // var popUp = window.open(newData.url);
            // if (popUp == null || typeof (popUp) == 'undefined') {
            //     alert('Please disable your pop-up blocker and all files will download automatically next time.');

            //     if (window.confirm('Confirm download file.')) {
            //         window.location.href = newData.url;
            //       }


            // }
            // else {
            //     popUp.focus();

            //     // window.open(newData.url)
            // }
            // }
            // catch (e) { }
            return Object.assign({}, state, {
                isFileDownloadLoading: false,
                FileDownloadData: newData.url /////specific to filename url
            });



        /* ################################### CTRLTWR ACTIONS ################################### */


        case types.CTRLTWR_GENERATE_PRO_GET:
            return Object.assign({}, state, {
                isCtrlTwrGenerateProLoading: true
            });
        case types.CTRLTWR_GENERATE_PRO_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isCtrlTwrGenerateProLoading: false,
                CtrlTwrGenerateProData: newData.data
            });


        case types.CTRLTWR_MODE_REF_GET:
            return Object.assign({}, state, {
                isCtrlTwrModeRefLoading: true
            });
        case types.CTRLTWR_MODE_REF_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isCtrlTwrModeRefLoading: false,
                CtrlTwrModeRefData: newData.data
            });


        case types.CTRLTWR_AIRPORT_GET:
            return Object.assign({}, state, {
                isCtrlTwrAirportLoading: true
            });
        case types.CTRLTWR_AIRPORT_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isCtrlTwrAirportLoading: false,
                CtrlTwrAirportData: newData.data
            });


        case types.CTRLTWR_SEAPORT_GET:
            return Object.assign({}, state, {
                isCtrlTwrSeaportLoading: true
            });
        case types.CTRLTWR_SEAPORT_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isCtrlTwrSeaportLoading: false,
                CtrlTwrSeaportData: newData.data
            });


        case types.CTRLTWR_COUNTRY_GET:
            return Object.assign({}, state, {
                isCtrlTwrCountryLoading: true
            });
        case types.CTRLTWR_COUNTRY_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isCtrlTwrCountryLoading: false,
                CtrlTwrCountryData: newData.data
            });



        case types.CTRLTWR_STATE_GET:
            return Object.assign({}, state, {
                isCtrlTwrStateLoading: true
            });
        case types.CTRLTWR_STATE_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isCtrlTwrStateLoading: false,
                CtrlTwrStateData: newData.data
            });



        case types.CTRLTWR_MILESTONE_REF_GET:
            return Object.assign({}, state, {
                isCtrlTwrMilestoneRefLoading: true
            });
        case types.CTRLTWR_MILESTONE_REF_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isCtrlTwrMilestoneRefLoading: false,
                CtrlTwrMilestoneRefData: newData.data
            });



        case types.CTRLTWR_SHIPMENT_GET:
            return Object.assign({}, state, {
                isCtrlTwrShipmentLoading: true
            });
        case types.CTRLTWR_SHIPMENT_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isCtrlTwrShipmentLoading: false,
                CtrlTwrShipmentData: newData.data
            });


        case types.CTRLTWR_POST_SHIPMENT_GET:
            return Object.assign({}, state, {
                CtrlTwrShipmentPostSuccessful: false
            });
        case types.CTRLTWR_POST_SHIPMENT_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    CtrlTwrShipmentPostSuccessful: true

                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    CtrlTwrShipmentPostSuccessful: false
                });
            }


        case types.CTRLTWR_QUOTING_SHIPMENT_GET:
            return Object.assign({}, state, {
                isCtrlTwrQuotingShipmentLoading: true
            });
        case types.CTRLTWR_QUOTING_SHIPMENT_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isCtrlTwrQuotingShipmentLoading: false,
                CtrlTwrQuotingShipmentData: newData.data
            });



        case types.CTRLTWR_POST_QUOTING_SHIPMENT_GET:
            return Object.assign({}, state, {
                CtrlTwrQuotingShipmentPostSuccessful: false
            });
        case types.CTRLTWR_POST_QUOTING_SHIPMENT_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    CtrlTwrQuotingShipmentPostSuccessful: true

                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    CtrlTwrQuotingShipmentPostSuccessful: false
                });
            }


        case types.CTRLTWR_POST_QUOTING_SHIPMENT_STATUS_ACTION_GET:
            return Object.assign({}, state, {
                CtrlTwrQuotingShipmentStatusActionPostSuccessful: false
            });
        case types.CTRLTWR_POST_QUOTING_SHIPMENT_STATUS_ACTION_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    CtrlTwrQuotingShipmentStatusActionPostSuccessful: true

                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    CtrlTwrQuotingShipmentStatusActionPostSuccessful: false
                });
            }

        case types.CTRLTWR_POST_QUOTING_SHIPMENT_LEGS_GET:
            return Object.assign({}, state, {
                CtrlTwrQuotingShipmentLegsPostSuccessful: false
            });
        case types.CTRLTWR_POST_QUOTING_SHIPMENT_LEGS_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    CtrlTwrQuotingShipmentLegsPostSuccessful: true

                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    CtrlTwrQuotingShipmentLegsPostSuccessful: false
                });
            }


        case types.CTRLTWR_QUOTING_SHIPMENT_LEGS_QUOTE_GET:
            return Object.assign({}, state, {
                isCtrlTwrQuotingShipmentLegsQuoteLoading: true
            });
        case types.CTRLTWR_QUOTING_SHIPMENT_LEGS_QUOTE_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isCtrlTwrQuotingShipmentLegsQuoteLoading: false,
                CtrlTwrQuotingShipmentLegsQuoteData: newData.data
            });

        case types.CTRLTWR_QUOTING_LINE_ITEM_GET:
            return Object.assign({}, state, {
                isCtrlTwrQuotingLineItemLoading: true
            });
        case types.CTRLTWR_QUOTING_LINE_ITEM_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isCtrlTwrQuotingLineItemLoading: false,
                CtrlTwrQuotingLineItemData: newData.data
            });


        case types.CTRLTWR_QUOTING_LINE_ITEM_UOM_GET:
            return Object.assign({}, state, {
                isCtrlTwrQuotingLineItemUomLoading: true
            });
        case types.CTRLTWR_QUOTING_LINE_ITEM_UOM_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isCtrlTwrQuotingLineItemUomLoading: false,
                CtrlTwrQuotingLineItemUomData: newData.data
            });

        case types.CTRLTWR_QUOTING_LINE_ITEM_RATE_TYPE_GET:
            return Object.assign({}, state, {
                isCtrlTwrQuotingLineItemRateTypeLoading: true
            });
        case types.CTRLTWR_QUOTING_LINE_ITEM_RATE_TYPE_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isCtrlTwrQuotingLineItemRateTypeLoading: false,
                CtrlTwrQuotingLineItemRateTypeData: newData.data
            });

        case types.CTRLTWR_QUOTING_SHIPMENT_LEGS_LINE_ITEMS_GET:
            return Object.assign({}, state, {
                isCtrlTwrQuotingShipmentLineItemsLoading: true
            });
        case types.CTRLTWR_QUOTING_SHIPMENT_LEGS_LINE_ITEMS_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isCtrlTwrQuotingShipmentLineItemsLoading: false,
                CtrlTwrQuotingShipmentLineItemsData: newData.data
            });


        case types.CTRLTWR_POST_QUOTING_SHIPMENT_LEGS_LINE_ITEM_GET:
            return Object.assign({}, state, {
                CtrlTwrShipmentLegsLineItemPostSuccessful: false
            });
        case types.CTRLTWR_POST_QUOTING_SHIPMENT_LEGS_LINE_ITEM_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    CtrlTwrShipmentLegsLineItemPostSuccessful: true

                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    CtrlTwrShipmentLegsLineItemPostSuccessful: false
                });
            }



        case types.CTRLTWR_QUOTING_SHIPMENT_FILEUPLOAD_GET:
            return Object.assign({}, state, {
                isCtrlTwrQuotingShipmentFileUploadLoading: true
            });
        case types.CTRLTWR_QUOTING_SHIPMENT_FILEUPLOAD_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isCtrlTwrQuotingShipmentFileUploadLoading: false,
                CtrlTwrQuotingShipmentFileUploadData: newData.data
            });


        case types.CTRLTWR_POST_QUOTING_SHIPMENT_FILEUPLOAD_GET:
            return Object.assign({}, state, {
                CtrlTwrQuotingShipmentFileUploadPostSuccessful: false
            });
        case types.CTRLTWR_POST_QUOTING_SHIPMENT_FILEUPLOAD_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    CtrlTwrQuotingShipmentFileUploadPostSuccessful: true

                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    CtrlTwrQuotingShipmentFileUploadPostSuccessful: false
                });
            }



        case types.CTRLTWR_QUOTING_SHIPMENT_NOTES_PRIVATE_GET:
            return Object.assign({}, state, {
                isCtrlTwrQuotingShipmentNotesPrivateLoading: true
            });
        case types.CTRLTWR_QUOTING_SHIPMENT_NOTES_PRIVATE_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isCtrlTwrQuotingShipmentNotesPrivateLoading: false,
                CtrlTwrQuotinghipmentNotesPrivateData: newData.data
            });


        case types.CTRLTWR_QUOTING_SHIPMENT_NOTES_PUBLIC_GET:
            return Object.assign({}, state, {
                isCtrlTwrQuotingShipmentNotesPublicLoading: true
            });
        case types.CTRLTWR_QUOTING_SHIPMENT_NOTES_PUBLIC_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isCtrlTwrQuotingShipmentNotesPublicLoading: false,
                CtrlTwrQuotingShipmentNotesPublicData: newData.data
            });

        case types.CTRLTWR_QUOTING_REPORT_GET:
            return Object.assign({}, state, {
                isCtrlTwrQuotingReportLoading: true
            });
        case types.CTRLTWR_QUOTING_REPORT_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isCtrlTwrQuotingReportLoading: false,
                CtrlTwrQuotingReportData: newData.data
            });


        case types.CTRLTWR_SHIPMENT_NOTES_PRIVATE_GET:
            return Object.assign({}, state, {
                isCtrlTwrShipmentNotesPrivateLoading: true
            });
        case types.CTRLTWR_SHIPMENT_NOTES_PRIVATE_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isCtrlTwrShipmentNotesPrivateLoading: false,
                CtrlTwrShipmentNotesPrivateData: newData.data
            });



        case types.CTRLTWR_POST_SHIPMENT_NOTES_PRIVATE_GET:
            return Object.assign({}, state, {
                CtrlTwrShipmentNotesPrivatePostSuccessful: false
            });
        case types.CTRLTWR_POST_SHIPMENT_NOTES_PRIVATE_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    CtrlTwrShipmentNotesPrivatePostSuccessful: true

                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    CtrlTwrShipmentNotesPrivatePostSuccessful: false
                });
            }


        case types.CTRLTWR_SHIPMENT_NOTES_PUBLIC_GET:
            return Object.assign({}, state, {
                isCtrlTwrShipmentNotesPublicLoading: true
            });
        case types.CTRLTWR_SHIPMENT_NOTES_PUBLIC_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isCtrlTwrShipmentNotesPublicLoading: false,
                CtrlTwrShipmentNotesPublicData: newData.data
            });


        case types.CTRLTWR_POST_SHIPMENT_NOTES_PUBLIC_GET:
            return Object.assign({}, state, {
                CtrlTwrShipmentNotesPublicPostSuccessful: false
            });
        case types.CTRLTWR_POST_SHIPMENT_NOTES_PUBLIC_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    CtrlTwrShipmentNotesPublicPostSuccessful: true

                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    CtrlTwrShipmentNotesPublicPostSuccessful: false
                });
            }







        case types.CTRLTWR_POST_SHIPMENT_LEGS_GET:
            return Object.assign({}, state, {
                CtrlTwrShipmentLegsPostSuccessful: false
            });
        case types.CTRLTWR_POST_SHIPMENT_LEGS_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    CtrlTwrShipmentLegsPostSuccessful: true

                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    CtrlTwrShipmentLegsPostSuccessful: false
                });
            }


        case types.CTRLTWR_SHIPMENT_LEGS_DATA_GET:
            return Object.assign({}, state, {
                isCtrlTwrShipmentLegsDataLoading: true
            });
        case types.CTRLTWR_SHIPMENT_LEGS_DATA_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isCtrlTwrShipmentLegsDataLoading: false,
                CtrlTwrShipmentLegsData: newData.data
            });



        case types.CTRLTWR_POST_SHIPMENT_LEGS_DATA_GET:
            return Object.assign({}, state, {
                CtrlTwrShipmentLegsDataPostSuccessful: false
            });
        case types.CTRLTWR_POST_SHIPMENT_LEGS_DATA_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    CtrlTwrShipmentLegsDataPostSuccessful: true

                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    CtrlTwrShipmentLegsDataPostSuccessful: false
                });
            }


        case types.CTRLTWR_SHIPMENT_LEGS_MILESTONES_GET:
            return Object.assign({}, state, {
                isCtrlTwrShipmentLegsMilestonesLoading: true
            });
        case types.CTRLTWR_SHIPMENT_LEGS_MILESTONES_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isCtrlTwrShipmentLegsMilestonesLoading: false,
                CtrlTwrShipmentLegsMilestonesData: newData.data
            });


        case types.CTRLTWR_POST_SHIPMENT_LEGS_MILESTONES_GET:
            return Object.assign({}, state, {
                CtrlTwrShipmentLegsMilestonesPostSuccessful: false
            });
        case types.CTRLTWR_POST_SHIPMENT_LEGS_MILESTONES_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    CtrlTwrShipmentLegsMilestonesPostSuccessful: true

                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    CtrlTwrShipmentLegsMilestonesPostSuccessful: false
                });
            }


        case types.CTRLTWR_POST_SHIPMENT_LEGS_MILESTONES_MULTI_GET:
            return Object.assign({}, state, {
                CtrlTwrShipmentLegsMilestonesMultiPostSuccessful: false
            });
        case types.CTRLTWR_POST_SHIPMENT_LEGS_MILESTONES_MULTI_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    CtrlTwrShipmentLegsMilestonesMultiPostSuccessful: true

                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    CtrlTwrShipmentLegsMilestonesMultiPostSuccessful: false
                });
            }


        case types.CTRLTWR_SHIPMENT_LEGS_MILESTONES_MULTI_DATA_GET:
            return Object.assign({}, state, {
                isCtrlTwrShipmentLegsMilestonesMultiLoading: true
            });
        case types.CTRLTWR_SHIPMENT_LEGS_MILESTONES_MULTI_DATA_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isCtrlTwrShipmentLegsMilestonesMultiLoading: false,
                CtrlTwrShipmentLegsMilestonesMultiData: newData.data
            });






        case types.CTRLTWR_SHIPMENT_LEGS_CUSTOMERREF_GET:
            return Object.assign({}, state, {
                isCtrlTwrShipmentLegsCustomerRefLoading: true
            });
        case types.CTRLTWR_SHIPMENT_LEGS_CUSTOMERREF_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isCtrlTwrShipmentLegsCustomerRefLoading: false,
                CtrlTwrShipmentLegsCustomerRefData: newData.data
            });



        case types.CTRLTWR_SHIPMENT_LEGS_PO_GET:
            return Object.assign({}, state, {
                isCtrlTwrShipmentLegsPoLoading: true
            });
        case types.CTRLTWR_SHIPMENT_LEGS_PO_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isCtrlTwrShipmentLegsPoLoading: false,
                CtrlTwrShipmentLegsPoData: newData.data
            });


        case types.CTRLTWR_SHIPMENT_LEGS_HBOL_GET:
            return Object.assign({}, state, {
                isCtrlTwrShipmentLegsHbolLoading: true
            });
        case types.CTRLTWR_SHIPMENT_LEGS_HBOL_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isCtrlTwrShipmentLegsHbolLoading: false,
                CtrlTwrShipmentLegsHbolData: newData.data
            });


        case types.CTRLTWR_POST_SHIPMENT_LEGS_CUSTOMERREF_GET:
            return Object.assign({}, state, {
                CtrlTwrShipmentLegsCustomerRefPostSuccessful: false
            });
        case types.CTRLTWR_POST_SHIPMENT_LEGS_CUSTOMERREF_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    CtrlTwrShipmentLegsCustomerRefPostSuccessful: true

                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    CtrlTwrShipmentLegsCustomerRefPostSuccessful: false
                });
            }


        case types.CTRLTWR_POST_SHIPMENT_LEGS_HBOL_GET:
            return Object.assign({}, state, {
                CtrlTwrShipmentLegsHbolPostSuccessful: false
            });
        case types.CTRLTWR_POST_SHIPMENT_LEGS_HBOL_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    CtrlTwrShipmentLegsHbolPostSuccessful: true

                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    CtrlTwrShipmentLegsHbolPostSuccessful: false
                });
            }

        case types.CTRLTWR_POST_SHIPMENT_LEGS_PO_GET:
            return Object.assign({}, state, {
                CtrlTwrShipmentLegsPoPostSuccessful: false
            });
        case types.CTRLTWR_POST_SHIPMENT_LEGS_PO_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    CtrlTwrShipmentLegsPoPostSuccessful: true

                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    CtrlTwrShipmentLegsPoPostSuccessful: false
                });
            }


        case types.CTRLTWR_TIMEZONE_GET:
            return Object.assign({}, state, {
                isCtrlTwrTimezoneLoading: true
            });
        case types.CTRLTWR_TIMEZONE_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isCtrlTwrTimezoneLoading: false,
                CtrlTwrTimezoneData: newData.data
            });

        case types.CTRLTWR_ALERTS_SHIPMENT_DEPARTURE_GET:
            return Object.assign({}, state, {
                isCtrlTwrAlertsShipmentDepartureLoading: true
            });
        case types.CTRLTWR_ALERTS_SHIPMENT_DEPARTURE_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isCtrlTwrAlertsShipmentDepartureLoading: false,
                CtrlTwrAlertsShipmentDepartureData: newData.data
            });

        case types.CTRLTWR_ALERTS_SHIPMENT_DELIVERY_GET:
            return Object.assign({}, state, {
                isCtrlTwrAlertsShipmentDeliveryLoading: true
            });
        case types.CTRLTWR_ALERTS_SHIPMENT_DELIVERY_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isCtrlTwrAlertsShipmentDeliveryLoading: false,
                CtrlTwrAlertsShipmentDeliveryData: newData.data
            });

        case types.CTRLTWR_ALERTS_SHIPMENT_SUMMARY_GET:
            return Object.assign({}, state, {
                isCtrlTwrAlertsShipmentSummaryLoading: true
            });
        case types.CTRLTWR_ALERTS_SHIPMENT_SUMMARY_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isCtrlTwrAlertsShipmentSummaryLoading: false,
                CtrlTwrAlertsShipmentSummaryData: newData.data
            });

        case types.CTRLTWR_ALERTS_STATS_GET:
            return Object.assign({}, state, {
                isCtrlTwrAlertsStatsLoading: true
            });
        case types.CTRLTWR_ALERTS_STATS_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isCtrlTwrAlertsStatsLoading: false,
                CtrlTwrAlertsStatsData: newData.data
            });


        case types.CTRLTWR_POST_SHIPMENT_HOTSHIPMENT_GET:
            return Object.assign({}, state, {
                CtrlTwrHotShipmentPostSuccessful: false
            });
        case types.CTRLTWR_POST_SHIPMENT_HOTSHIPMENT_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    CtrlTwrHotShipmentPostSuccessful: true

                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    CtrlTwrHotShipmentPostSuccessful: false
                });
            }


        case types.CTRLTWR_POST_SHIPMENT_USER_SUBSCRIPTION_GET:
            return Object.assign({}, state, {
                CtrlTwrShipmentUserSubscriptionPostSuccessful: false
            });
        case types.CTRLTWR_POST_SHIPMENT_USER_SUBSCRIPTION_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    CtrlTwrShipmentUserSubscriptionPostSuccessful: true

                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    CtrlTwrShipmentUserSubscriptionPostSuccessful: false
                });
            }


        case types.CTRLTWR_CHART_SHIPMENT_DELIVERY_GET:
            return Object.assign({}, state, {
                isCtrlTwrChartShipmentDeliveryLoading: true
            });
        case types.CTRLTWR_CHART_SHIPMENT_DELIVERY_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isCtrlTwrChartShipmentDeliveryLoading: false,
                CtrlTwrChartShipmentDeliveryData: newData.data
            });

        case types.CTRLTWR_CHART_SHIPMENT_DELIVERY_DETAILS_GET:
            return Object.assign({}, state, {
                isCtrlTwrChartShipmentDeliveryDetailsLoading: true
            });
        case types.CTRLTWR_CHART_SHIPMENT_DELIVERY_DETAILS_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isCtrlTwrChartShipmentDeliveryDetailsLoading: false,
                CtrlTwrChartShipmentDeliveryDetailsData: newData.data
            });

        case types.CTRLTWR_CHART_SHIPMENT_DELIVERY_HISTORY_GET:
            return Object.assign({}, state, {
                isCtrlTwrChartShipmentDeliveryHistoryLoading: true
            });
        case types.CTRLTWR_CHART_SHIPMENT_DELIVERY_HISTORY_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isCtrlTwrChartShipmentDeliveryHistoryLoading: false,
                CtrlTwrChartShipmentDeliveryHistoryData: newData.data
            });

        case types.CTRLTWR_CHART_SHIPMENT_DELIVERY_HISTORY_DETAILS_GET:
            return Object.assign({}, state, {
                isCtrlTwrChartShipmentDeliveryHistoryDetailsLoading: true
            });
        case types.CTRLTWR_CHART_SHIPMENT_DELIVERY_HISTORY_DETAILS_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isCtrlTwrChartShipmentDeliveryHistoryDetailsLoading: false,
                CtrlTwrChartShipmentDeliveryHistoryDetailsData: newData.data
            });

        case types.CTRLTWR_SHIPMENT_FILEUPLOAD_GET:
            return Object.assign({}, state, {
                isCtrlTwrShipmentFileUploadLoading: true
            });
        case types.CTRLTWR_SHIPMENT_FILEUPLOAD_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isCtrlTwrShipmentFileUploadLoading: false,
                CtrlTwrShipmentFileUploadData: newData.data
            });


        case types.CTRLTWR_POST_SHIPMENT_FILEUPLOAD_GET:
            return Object.assign({}, state, {
                CtrlTwrShipmentFileUploadPostSuccessful: false
            });
        case types.CTRLTWR_POST_SHIPMENT_FILEUPLOAD_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    CtrlTwrShipmentFileUploadPostSuccessful: true

                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    CtrlTwrShipmentFileUploadPostSuccessful: false
                });
            }








        case types.EDI_990_QUEUE_GET:
            return Object.assign({}, state, {
                isEdi990QueueLoading: true
            });
        case types.EDI_990_QUEUE_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isEdi990QueueLoading: false,
                Edi990QueueData: newData.data
            });


        case types.EDI_POST_990_QUEUE_GET:
            return Object.assign({}, state, {
                Edi990QueuePostSuccessful: false
            });
        case types.EDI_POST_990_QUEUE_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    Edi990QueuePostSuccessful: true

                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    Edi990QueuePostSuccessful: false
                });
            }




        case types.EDI_204_GET:
            return Object.assign({}, state, {
                isEdi204Loading: true
            });
        case types.EDI_204_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isEdi204Loading: false,
                Edi204Data: newData.data
            });

        case types.EDI_204_OID_GET:
            return Object.assign({}, state, {
                isEdi204OIDLoading: true
            });
        case types.EDI_204_OID_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isEdi204OIDLoading: false,
                Edi204OIDData: newData.data
            });

        case types.EDI_204_L5_GET:
            return Object.assign({}, state, {
                isEdi204L5Loading: true
            });
        case types.EDI_204_L5_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isEdi204L5Loading: false,
                Edi204L5Data: newData.data
            });


        default:
            return state;
    }
};





export default CtrlTwrReducer;
