import React from 'react';
import PropTypes from 'prop-types';
import {
    Drawer,
    Button,
    ButtonToolbar,
    Form,
    FormGroup,
    ControlLabel,
    InputPicker,
    DatePicker,
    Loader,
    Row,
    InputGroup,
    Panel,
    Input,
    Col,
    InputNumber,
    ButtonGroup,
    Divider
} from 'rsuite';
import { lettersNumbersDashesInputMask, numbersInputMask, hundredthsDecimalInputMask, limitCharacterLength, textNumbersSymbolsInputmask } from '../../../resources/logic/inputMasks';
import { isNumeric, replaceNegativeWithZero, formatNumberForAPI, formatStringForAPI, findLowestMissingNumber, formatDateForUrlSearch } from '../../../resources/logic/functions';
import { weightUnits, LabelGenLengthUnits } from '../../../app/appConfig';


const styles = {
    marginTop: '5px',
    width: '100%'
};


const tableIdCol = "ShipmentLegMilestoneID"


const mountRef = React.createRef();


const ShipmentDetailsManagementEditDrawer = ({ onChange, placement, onClickSaveShipDetailsEdit, close, masterLabel, onChangeWarehouse, onChangeCustomer, onChangePO, onChangeOriginCountry, onChangeDestinationCountry, onChangeETD, onChangePallets, onChangeCases, onChangeUnitCount, onChangeWeightUnits, onChangeFunc, onClickDelete, state, ...props }) => (

    state.showQuoteShipmentDetailsEditDrawer === false ?
        <div align="center">
        </div>

        :

        props.isCtrlTwrShipmentLegsMilestonesLoading ?

            <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>

            :

            <Drawer show={state.showQuoteShipmentDetailsEditDrawer} onHide={close} placement={placement} style={props.isMobile ? { width: '350px' } : { width: '650px' }}    >
                <Drawer.Header>
                </Drawer.Header>
                <Drawer.Body> <div ref={mountRef}>

                    <Form fluid>

                        <Col md={24} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }} >
                                <FormGroup >

                                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                        <InputGroup.Addon>PRO:</InputGroup.Addon>
                                        <Input value={props.CtrlTwrQuotingShipmentData[0].ProRef} readOnly />
                                    </InputGroup>


                                    <div style={{ marginBottom: '10px', width: '100%' }}>
                                        <InputPicker
                                            preventOverflow
                                            placeholder={'Customer...'}
                                            value={state.ShipEntryCustomer}
                                            onChange={(e) => { onChangeCustomer(e) }}
                                            data={state.customerArray}
                                            cleanable={true}
                                            block
                                        />
                                    </div>

                                    <div style={{ marginBottom: '10px', width: '100%' }}>
                                        <InputPicker
                                            preventOverflow
                                            placeholder={'Origin Country...'}
                                            value={state.ShipEntryOriginCountry}
                                            onChange={(e) => { onChangeOriginCountry(e) }}
                                            data={props.CtrlTwrCountryData}
                                            cleanable={true}
                                            block
                                        />
                                    </div>

                                    <div style={{ marginBottom: '10px', width: '100%' }}>
                                        <InputPicker
                                            preventOverflow
                                            placeholder={'Desintaion Country (Optional)...'}
                                            value={state.ShipEntryDestinationCountry}
                                            onChange={(e) => { onChangeDestinationCountry(e) }}
                                            data={props.CtrlTwrCountryData}
                                            cleanable={true}
                                            block
                                        />
                                    </div>


                                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                        <InputGroup.Addon>PO (Optional):</InputGroup.Addon>
                                        <Input value={state.ShipEntryPO} onChange={(e) => { onChangePO(e) }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} />
                                    </InputGroup>

                                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                        <InputGroup.Addon>Planned Pickup (Optional):</InputGroup.Addon>
                                        <DatePicker
                                            placeholder="Planned Pickup"
                                            onChange={(e) => { onChangeETD(e) }}
                                            value={state.ShipEntryETD}
                                            format="YYYY-MM-DD"
                                            showMeridian
                                            size="md"
                                            ranges={[
                                                {
                                                    label: 'Now',
                                                    value: new Date()
                                                }
                                            ]}
                                            style={{ width: '100%' }}
                                            oneTap
                                        />
                                    </InputGroup>


                                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                        <InputGroup.Addon>Commodity:</InputGroup.Addon>
                                        <Input value={state.Commodity} onChange={(e) => { onChangeFunc(e, 'Commodity') }} onInput={(e) => e.target.value = limitCharacterLength(textNumbersSymbolsInputmask(e), 300)} />
                                    </InputGroup>

                                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                        <InputGroup.Addon>Special Requirements:</InputGroup.Addon>
                                        <Input value={state.SpecialRequirements} onChange={(e) => { onChangeFunc(e, 'SpecialRequirements') }} onInput={(e) => e.target.value = limitCharacterLength(textNumbersSymbolsInputmask(e), 2000)} />
                                    </InputGroup>



                                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                        <InputGroup.Addon>Pallets (Optional):</InputGroup.Addon>
                                        <InputNumber style={{ width: '100%' }} value={state.ShipEntryPallets} onChange={(e) => onChangePallets(e)} className={'custom-input-number'} scrollable={false} step={1} onInput={(e) => e.target.value = numbersInputMask(e)} />
                                    </InputGroup>

                                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                        <InputGroup.Addon>Cases (Optional):</InputGroup.Addon>
                                        <InputNumber style={{ width: '100%' }} value={state.ShipEntryCases} onChange={(e) => onChangeCases(e)} className={'custom-input-number'} scrollable={false} step={1} onInput={(e) => e.target.value = numbersInputMask(e)} />
                                    </InputGroup>

                                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                        <InputGroup.Addon>Units (Optional):</InputGroup.Addon>
                                        <InputNumber style={{ width: '100%' }} value={state.ShipEntryUnitCount} onChange={(e) => onChangeUnitCount(e)} className={'custom-input-number'} scrollable={false} step={1} onInput={(e) => e.target.value = numbersInputMask(e)} />
                                    </InputGroup>




                                    <Row>
                                        <Col md={12} sm={24}>
                                            <Panel bordered style={{ marginBottom: 10 }} >
                                                <FormGroup>
                                                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                        <InputGroup.Addon>Length:</InputGroup.Addon>
                                                        <InputNumber style={{ width: '100%' }} value={state.Length} onChange={(e) => onChangeFunc(e, 'Length')} className={'custom-input-number'} onInput={(e) => e.target.value = hundredthsDecimalInputMask(e)} scrollable={false} />
                                                    </InputGroup>

                                                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                        <InputGroup.Addon>Width:</InputGroup.Addon>
                                                        <InputNumber style={{ width: '100%' }} value={state.Width} onChange={(e) => onChangeFunc(e, 'Width')} className={'custom-input-number'} onInput={(e) => e.target.value = hundredthsDecimalInputMask(e)} scrollable={false} />
                                                    </InputGroup>

                                                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                        <InputGroup.Addon>Height:</InputGroup.Addon>
                                                        <InputNumber style={{ width: '100%' }} value={state.Height} onChange={(e) => onChangeFunc(e, 'Height')} className={'custom-input-number'} onInput={(e) => e.target.value = hundredthsDecimalInputMask(e)} scrollable={false} />
                                                    </InputGroup>

                                                    <InputGroup style={styles}>
                                                        <InputGroup.Addon>Units:</InputGroup.Addon>
                                                        <InputPicker
                                                            preventOverflow
                                                            value={state.DimUnits}
                                                            onChange={(e) => { onChangeFunc(e, 'DimUnits') }}
                                                            data={LabelGenLengthUnits}
                                                            block
                                                            style={{ width: '100%' }}
                                                            cleanable={true}
                                                        />
                                                    </InputGroup>

                                                </FormGroup>
                                            </Panel>
                                        </Col>
                                        <Col md={12} sm={24}>
                                            <Panel bordered style={{ marginBottom: 10 }} >
                                                <FormGroup>

                                                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                        <InputGroup.Addon>Gross Weight:</InputGroup.Addon>
                                                        <InputNumber style={{ width: '100%' }} value={state.GrossWeight} onChange={(e) => onChangeFunc(e, 'GrossWeight')} className={'custom-input-number'} onInput={(e) => e.target.value = hundredthsDecimalInputMask(e)} scrollable={false} />
                                                    </InputGroup>

                                                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                        <InputGroup.Addon>Chargeable Weight:</InputGroup.Addon>
                                                        <InputNumber style={{ width: '100%' }} value={state.ChargeableWeight} onChange={(e) => onChangeFunc(e, 'ChargeableWeight')} className={'custom-input-number'} onInput={(e) => e.target.value = hundredthsDecimalInputMask(e)} scrollable={false} />
                                                    </InputGroup>

                                                    <InputGroup style={styles}>
                                                        <InputGroup.Addon>Weight Units:</InputGroup.Addon>
                                                        <InputPicker
                                                            preventOverflow
                                                            // placeholder={label}
                                                            // placeholder={'Weight Units...'}
                                                            value={state.ShipEntryWeightUnits}
                                                            onChange={(e) => { onChangeWeightUnits(e) }}
                                                            data={weightUnits}
                                                            block
                                                            style={{ width: '100%' }}
                                                            cleanable={true}
                                                        />
                                                    </InputGroup>

                                                </FormGroup>
                                            </Panel>
                                        </Col>

                                    </Row>








                                </FormGroup>
                            </Panel>

                            <FormGroup>

                                <ButtonToolbar >
                                    <Button onClick={(e) => { onClickSaveShipDetailsEdit(e) }} appearance="primary">Save</Button>
                                    <Button onClick={(e) => { close(e) }} appearance="default">Close</Button>


                                </ButtonToolbar>

                            </FormGroup>
                        </Col>

                    </Form >
                </div> </Drawer.Body>
                <Drawer.Footer>

                    <br />

                </Drawer.Footer>


            </Drawer >

);



export default ShipmentDetailsManagementEditDrawer;


