//TODO update with mobile view

import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'

import { TextField } from 'redux-form-material-ui'

import { Paper, RaisedButton } from 'material-ui'

import { state } from 'aws-cognito-redux-saga'

// import { state as awsState } from 'aws-cognito-redux-saga'
import { withRouter } from 'react-router-dom';

import * as AmazonCognitoIdentity from "amazon-cognito-identity-js"
import { customerConfig } from '../../../app/appCustomerConfig'
import { awsCognitoConfig } from '../../../app/awsCognitoConfig'

const required = value => (value ? undefined : 'Required')
const passwordMatch = (value, values) =>
  values.password !== values.passwordMatch && 'Passwords must match'
const minLength = value => (value.length >= 8 ? undefined : 'Min Length 8')
// const email = value =>
//   validator.isEmail(value) ? undefined : 'Not Valid Email'


const poolData = { UserPoolId: awsCognitoConfig.cognitoLogin.Auth.UserPoolId, ClientId: awsCognitoConfig.cognitoLogin.Auth.ClientId };
const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

const cognitoUser = userPool.getCurrentUser();



const style = {
  // paper: { padding: '16px' },
  layout: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 56px)'
  },
  title: {
    fontSize: '32px',
    textAlign: 'center',
    margin: '16px'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    margin: '16px',
    width: '80%'
  },
  field: {
    margin: '8px 32px'
  },
  error: {
    width: '80%',
    color: 'rgb(200,0,0)',
    margin: '8px',
    height: '32px'
  }
}

class UserChangePassword extends Component {
  static propTypes = {
    history: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    changePassword: PropTypes.func.isRequired,
    auth: PropTypes.object,
    // init: PropTypes.func
  }

  // componentWillMount() {
  //   // this.props.init()
  // }



  changePassword = values => {
    if (cognitoUser === null) { window.location.reload(); }
    cognitoUser.getSession((err, data) => {
      if (err) {
        console.log('Session expired, auto reload page')
        window.location.reload();
      } else {
        cognitoUser.changePassword(values.oldpassword, values.password, (err, result) => {
          if (err) {
            this.setState({ error: err.message });
            return;
          }

          if (result === 'SUCCESS') {
            alert('Password changed successfully.')
            this.props.history.push(`/${customerConfig.customerUrl}/wms/myprofile`) 
          }
          else {
            this.setState({ error: 'Password change failed, please try again.' });
          }
        });
      }
    });
  }


  // changePassword = values => {


  //   // this.props.changePassword(
  //   //   this.props.auth.info.idToken.payload.email.toLowerCase(),
  //   //   'x',
  //   //   values.password
  //   // )

  //   // signOut = () => {
  //   //   this.props.signOut()
  //   //   this.props.appSignOut()
  //   //   this.props.history.push('/signin')
  //   // };


  //   if (cognitoUser === null) { window.location.reload(); }
  //   cognitoUser.getSession(function (err, data) {
  //     if (err) {
  //       // Prompt the user to reauthenticate by hand...
  //       // window.alert("Session expired, please reload page.")
  //       console.log('Session expired, auto reload page')
  //       window.location.reload();
  //     } else {
  //       const cognitoUserSession = data;
  //       // yourIdToken = cognitoUserSession.getIdToken().jwtToken;
  //       //   const yourAccessToken = cognitoUserSession.getAccessToken().jwtToken;


  //       cognitoUser.changePassword(values.oldpassword, values.password, function (err, result) {
  //         if (err) {
  //           alert(err);
  //           return;
  //         }

  //         if (result === 'SUCCESS') {
  //           alert('Password changed successfully.')
  //           // window.location.reload();

  //           this.props.history.push(`/${customerConfig.customerUrl}/myprofile`)


  //         }
  //         else {
  //           alert('Password change failed, please try again.')
  //         }


  //       });




  //     }
  //   });



  // }

  signIn = () => {
    this.props.history.push(`/${customerConfig.customerUrl}/signin`)
  }

  renderChangePassword() {
    const { handleSubmit, auth } = this.props


    return (
      <div style={style.layout}>
        <Paper style={style.paper} zDepth={5}>
          <form style={style.form}>
            <div style={style.title}>Change Password</div>

            <Field
              style={style.field}
              name="oldpassword"
              validate={[required]}
              component={TextField}
              type="password"
              floatingLabelText="Old Password"
            />


            <Field
              style={style.field}
              name="password"
              validate={[required, minLength]}
              component={TextField}
              type="password"
              floatingLabelText="Password"
            />

            <Field
              style={style.field}
              name="passwordMatch"
              validate={[required, passwordMatch, minLength]}
              component={TextField}
              type="password"
              floatingLabelText="Password"
            />

            <div style={style.error}>
              {(auth.error && auth.error.message) || (this.state && this.state.error)}
            </div>

            <RaisedButton
              style={style.button}
              onClick={handleSubmit(this.changePassword)}
              primary
            >
              Change Password
            </RaisedButton>
          </form>
        </Paper>
      </div>
    )
  }

  renderSignIn() {
    const { handleSubmit } = this.props
    return (
      <div style={style.layout}>
        <Paper style={style.paper} zDepth={5}>
          <form style={style.form}>
            <div style={style.title}>Password Changed</div>

            <RaisedButton
              style={style.button}
              onClick={handleSubmit(this.signIn)}
              primary
            >
              Sign In
            </RaisedButton>
          </form>
        </Paper>
      </div>
    )
  }

  render() {
    const { auth } = this.props
    return auth.hasChangedPassword === state.AUTH_SUCCESS
      ? this.renderSignIn()
      : this.renderChangePassword()
  }
}

export default withRouter(reduxForm({
  form: 'changePassword'
})(UserChangePassword));