import { reducer } from 'aws-cognito-redux-saga';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions/actionsUser';
import HeaderComponent from './Header';

const mapStatetoProps = state => {
  return {
    auth: state.auth
  }
}

const mapDispatchToProps = dispatch => {
  return {
    signedIn: () => {
      dispatch(reducer.signedIn())
    },
    signOut: () => {
      dispatch(reducer.signOut())
    },
    signIn: (username, password) => {
      dispatch(reducer.signIn(username, password))
    },
    appSignOut: () => {
      dispatch( actions.appSignOut() )
    },
  }
}

export default connect(mapStatetoProps, mapDispatchToProps)(HeaderComponent)
