import * as moment from 'moment';
import React, {
    PureComponent
} from 'react';
import ReactExport from "react-export-excel";
import Loader from 'react-loader-spinner';
import { withRouter } from 'react-router-dom';
import { Button, Col, Panel, PanelGroup, Row, Timeline } from 'rsuite';
import { convertNanToZero, formatNumberForAPI, formatStringForAPI, formatTrueFalseToBit } from '../../../../resources/logic/functions';
import RTableGeneral from '../../../componentsGeneral/Rsuite/Table/RTableGeneral';
import InventoryLoadSkuDetailsDataElements from '../../../dataComponentsInventory/InventoryLoadSkuDetailsDataElements';
import InventorySkuWarehouseLocationDataElements from '../../../dataComponentsInventory/InventorySkuWarehouseLocationDataElements';
// import EditSingleWarehouseLocationsDrawer from './EditSingleWarehouseLocationsDrawer';
// import EditSkuDetailsDrawer from './EditSkuDetailsDrawer';
// import InventorySkuWarehouseDataElements from '../../../dataComponentsInventory/InventorySkuWarehouseDataElements';
// import InventoryCaseWarehouseDataElements from '../../../dataComponentsInventory/InventoryCaseWarehouseDataElements';
// import InventorySkuWarehouseGroupedDataElements from '../../dataComponentsInventory/InventorySkuWarehouseGroupedDataElements';
// import Barcode from 'react-barcode';
import InventoryUnitWarehouseGroupedDataElements from '../../../dataComponentsInventory/InventoryUnitWarehouseGroupedDataElements';
import CaseHistoryDiv from '../../_Divs_Lot_History/CaseHistoryDiv';
import CaseSKUsHistoryDiv from '../../_Divs_Lot_History/CaseSKUsHistoryDiv';
import LoadCaseHistoryDiv from '../../_Divs_Lot_History/LoadCaseHistoryDiv';
import PalletWarehouseLocationHistoryDiv from '../../_Divs_Lot_History/PalletWarehouseLocationHistoryDiv';
import BarcodeCard from '../Barcode/BarcodeCard';
import CaseContents from '../Barcode/CaseContents';
import MoveCaseDrawer from '../PalletDetail/MoveCaseDrawer';
import HistoryTypeModal from '../_GeneralLotComponents/HistoryTypeModal';
import EditCaseDrawer from './EditCaseDrawer';


const styles = {
    marginBottom: '10px',
    width: '100%'
};

const tableIdCol = "SkuID"

//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")

const mountRef = React.createRef();

class CaseDetail extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

            //Barcode
            showPrintableContent: false,
            showPrintableBarcode: false,

            //Edit Warehouse Location
            showEditSingleWarehouseLocationsDrawer: false,
            unsavedEditWarehouseLocationValues: false,
            LocationOriginalDict: {},
            LocationToEditDict: {},
            newWarehouseLocationID: null,
            newUnits: 0,
            newCases: 0,
            newPallets: 0,
            newOversize: 0,


            //Edit Case
            showEditCaseDrawer: false,
            unsavedEditCaseValues: false,
            LotCode: null,
            SerialNumber: null,
            CustomerRef: null,
            SKU: null,
            Project: null,
            Notes: null,


            //Move Case 
            isMoveCaseDrawerOpen: false,
            CaseIDToMove: null,

            //History Type Modal
            historyTypeModalOpen: false,
            historyTypeID: null,





        };


        // Table Sort
        this.handleSortColumn = this.handleSortColumn.bind(this);

        // Warehouse Location Details
        this.onClickWarehouseLocationDetails = this.onClickWarehouseLocationDetails.bind(this);

        this.onClickLoadDetails = this.onClickLoadDetails.bind(this);




        //Edit Warehouse Location
        this.onclickEditWarehouseLocation = this.onclickEditWarehouseLocation.bind(this);
        this.closeEditSingleWarehouseLocationsDrawer = this.closeEditSingleWarehouseLocationsDrawer.bind(this);
        this.setStateCloseEditSingleWarehouseLocationsDrawer = this.setStateCloseEditSingleWarehouseLocationsDrawer.bind(this);
        // this.onClickAddLocation = this.onClickAddLocation.bind(this);
        // this.handleAddNewLocation = this.handleAddNewLocation.bind(this);
        this.onClickSaveEditWarehouseLocation = this.onClickSaveEditWarehouseLocation.bind(this);


        //Edit Case Drawer
        this.closeEditCaseDrawer = this.closeEditCaseDrawer.bind(this);
        this.onClickConfirmEditCase = this.onClickConfirmEditCase.bind(this);
        this.setStateCloseEditCaseDrawer = this.setStateCloseEditCaseDrawer.bind(this)
        this.onChangeEditCase = this.onChangeEditCase.bind(this)


        //Move Case
        this.onClickMoveCase = this.onClickMoveCase.bind(this);
        this.toggleMoveCaseDrawer = this.toggleMoveCaseDrawer.bind(this);
        this.onClickSelectPalletMoveCase = this.onClickSelectPalletMoveCase.bind(this);


        this.onClickUnitDetails = this.onClickUnitDetails.bind(this);
        this.onClickPalletDetails = this.onClickPalletDetails.bind(this);
    }




    componentDidMount() {
        this.loadSortFromStorage()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.setState({
                checkedKeys: []
            });
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.setState({
                checkedKeys: []
            });
        }


        if (prevProps.InventoryCaseWarehouseData !== this.props.InventoryCaseWarehouseData) {
            try {
                var skuWarehouseID = this.props.InventoryCaseWarehouseData[0].WarehouseID
                var skuCustomerID = this.props.InventoryCaseWarehouseData[0].CustomerID

                this.props.inventoryCustomerRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, skuWarehouseID, skuCustomerID)
                this.props.inventoryProjectGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, skuWarehouseID, skuCustomerID)
            }
            catch (e) { }
        }


        if (prevProps.InventoryLotCaseRefEditPostSuccessful !== this.props.InventoryLotCaseRefEditPostSuccessful && this.props.InventoryLotCaseRefEditPostSuccessful) {
            this.setStateCloseEditCaseDrawer()
        }

        if (prevProps.InventoryCaseWarehouseLocationPostSuccessful !== this.props.InventoryCaseWarehouseLocationPostSuccessful && this.props.InventoryCaseWarehouseLocationPostSuccessful) {
            this.toggleMoveCaseDrawer();
        }



    }


    // Table Sort
    saveSortTosessionStorage(sortColumn, sortType) {
        try {
            sessionStorage.setItem('sortcolumn', sortColumn)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortType', sortType)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortURLPathname', this.props.location.pathname)
        }
        catch (e) { }
    }
    loadSortFromStorage() {
        var sortColumn = null
        var sortType = null
        var pathName = null
        try {
            sortColumn = sessionStorage.getItem('sortcolumn')
        }
        catch (e) { }
        try {
            sortType = sessionStorage.getItem('sortType')
        }
        catch (e) { }
        try {
            pathName = sessionStorage.getItem('sortURLPathname')
        }
        catch (e) { }
        if (pathName === this.props.location.pathname) {
            
            this.handleSortColumn(sortColumn, sortType)
        }
    }
    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });
        this.saveSortTosessionStorage(sortColumn, sortType)
        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 100);
    }



    //Table buttons
    onClickWarehouseLocationDetails(e, rowData, history) {
        try {
            var paramID = rowData["WarehouseLocationID"]
            var paramWarehouseID = rowData["WarehouseID"]
            history.push({
                pathname: 'warehouselocationdetails',
                search: 'warehouselocationid=' + paramID + '&' +
                    'warehouseid=' + paramWarehouseID
            });
        }
        catch (e) {
        }
    }



    //On Click Details
    onClickLoadDetails(e, rowData, history) {
        try {
            var paramID = rowData["LoadID"]
            var paramWarehouseID = rowData["WarehouseID"]
            var paramCustomerID = rowData["CustomerID"]
            history.push({
                pathname: 'lotloaddetails',
                search: 'loadid=\'' + paramID + '\'' + '&' +
                    'warehouseid=' + paramWarehouseID + '&' +
                    'customerid=' + paramCustomerID
            });
        }
        catch (e) {
        }
    }

    onClickSkuDetails(e, rowData, history) {
        // try {
        //     var paramID = rowData["SkuID"]
        //     history.push({
        //         pathname: 'unitdetails',
        //         search: 'skuid=\'' + paramID
        //     });
        // }
        // catch (e) {
        // }
    }



    //Move Case
    toggleMoveCaseDrawer() {
        this.setState(prevState => ({
            isMoveCaseDrawerOpen: !prevState.isMoveCaseDrawerOpen,
        }));
    }


    onClickMoveCase(e) {
        this.setState({
            CaseIDToMove: this.props.InventoryCaseWarehouseData[0].CaseID
        });

        const warehouseId = this.props.InventoryCaseWarehouseData[0].WarehouseID;
        const customerID = this.props.InventoryCaseWarehouseData[0].CustomerID;

        this.props.inventoryPalletWarehouseLocationGet2(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, warehouseId, customerID, "'1,2'", null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)

        this.toggleMoveCaseDrawer();
    };

    onClickSelectPalletMoveCase(e, rowData, history) {
        if (window.confirm('Move case to this pallet?')) {
            var newPalletID = formatNumberForAPI(rowData['PalletID'])
            var oldPalletID = formatNumberForAPI(this.props.InventoryPalletWarehouseData[0].PalletID)
            var warehouseID = formatNumberForAPI(this.props.InventoryPalletWarehouseData[0].WarehouseID)
            var customerID = formatNumberForAPI(this.props.InventoryPalletWarehouseData[0].CustomerID)
            var caseIDtoMove = formatNumberForAPI(this.state.CaseIDToMove)
            if (this.props.InventoryPalletWarehouseData.length == 1) {
                if (this.props.InventoryPalletWarehouseData[0].CasesInWhse == 1) {
                    if (window.confirm('After this case is moved, nothing will remain on pallet.  Click OK to set pallet to closed, cancel to remain open.')) {
                        this.props.inventoryPalletWarehouseLocationPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, warehouseID, customerID, oldPalletID, null, null, 1, 5, null, null, null)
                    }
                }
                else {
                }
            }
            this.props.inventoryCaseWarehouseLocationPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, caseIDtoMove, null, newPalletID, null)
        }
    }



    //Warehouse Location Drawer Buttons
    onclickEditWarehouseLocation(e, rowData, history) {
        this.props.userWarehouseLocationsGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, rowData.WarehouseID, rowData.CustomerID);

        var origRowOversize = rowData['Oversize']

        this.setState({
            showEditSingleWarehouseLocationsDrawer: true,
            unsavedEditWarehouseLocationValues: false,

            LocationToEditDict: rowData,
            LocationOriginalDict: rowData,
            newWarehouseLocationID: null,
            newUnits: 0,
            newCases: 0,
            newPallets: 0,
            newOversize: origRowOversize
        });

    }





    // check if same location but oversize
    onChangeEditLocationDrawer = (event, label) => {
        if (label === 'newWarehouseLocationID') {
            // find new warehouse name in existing state:
            //This will be changed to dropdown choice:
            if (this.state.LocationOriginalDict['WarehouseLocationID'] === event) {
                if (this.state.LocationOriginalDict['Oversize'] === true) {
                    var newOversize = false
                }
                else {
                    var newOversize = true
                }
                this.setState({
                    newWarehouseLocationID: event,
                    newOversize: newOversize,
                    unsavedEditWarehouseLocationValues: true
                });
            }
            else {
                this.setState({
                    newWarehouseLocationID: event,
                    unsavedEditWarehouseLocationValues: true
                });
            }
        }
        else if (label === 'newOversize') {
            if (this.state.LocationOriginalDict['WarehouseLocationID'] === this.state.newWarehouseLocationID) {
                if (this.state.LocationOriginalDict['Oversize'] === true) {
                    var newOversize = false
                }
                else {
                    var newOversize = true
                }
                this.setState({
                    newOversize: newOversize,
                    unsavedEditWarehouseLocationValues: true
                });
            }
            else {
                this.setState({
                    newOversize: event,
                    unsavedEditWarehouseLocationValues: true
                });
            }
        }
        else {
            this.setState({
                [label]: event,
                unsavedEditWarehouseLocationValues: true
            });
        }
        this.forceUpdate()
    };


    // handleAddNewLocation = (newLocationDict) => {
    //     this.setState({
    //         unsavedEditWarehouseLocationValues: true,
    //         LocationDict: newLocationDict
    //     }, () => {
    //         // Do an API call with this.state.searchTerm
    //     });
    // }

    // onClickAddLocation = (event) => {
    //     //Check if any Warehouse names are null
    //     if (this.state.LocationDict[this.state.LocationToEditDict.SkuID].filter(item => item.WarehouseLocationID === null || item.WarehouseLocationID === '').length > 0) {
    //         window.alert('Please enter all location values first.')
    //     }
    //     else {
    //         //save warehouse name
    //         var currentLocationDict = this.state.LocationDict

    //         currentLocationDict[this.state.LocationToEditDict.SkuID].push({ 'WarehouseLocationID': null, 'Units': null, 'WarehouseID': null, 'Cases': null, 'Pallets': null, 'Oversize': null })

    //         this.handleAddNewLocation(currentLocationDict)

    //         this.forceUpdate()
    //     }
    // }



    onClickSaveEditWarehouseLocation(e) {

        try {
            var warehouseID = this.state.LocationOriginalDict.WarehouseID
            var customerID = this.state.LocationOriginalDict.CustomerID

            var skuID = this.state.LocationOriginalDict.SkuID

            var originalLocationWarehouseID = formatNumberForAPI(this.state.LocationOriginalDict.WarehouseLocationID)
            var originalLocationSkuWarehouseLocID = formatNumberForAPI(this.state.LocationOriginalDict.SkuWarehouseLocationID)
            var originalLocationUnits = convertNanToZero(this.state.LocationOriginalDict.Units) - convertNanToZero(this.state.newUnits)
            var originalLocationCases = convertNanToZero(this.state.LocationOriginalDict.Cases) - convertNanToZero(this.state.newCases)
            var originalLocationPallets = convertNanToZero(this.state.LocationOriginalDict.Pallets) - convertNanToZero(this.state.newPallets)
            var originalLocationOversize = formatTrueFalseToBit(this.state.LocationOriginalDict.Oversize)

            var newLocationID = formatNumberForAPI(this.state.newWarehouseLocationID)
            var newLocationOversize = formatTrueFalseToBit(this.state.newOversize)
            var newLocationUnits = convertNanToZero(this.state.newUnits)
            var newLocationCases = convertNanToZero(this.state.newCases)
            var newLocationPallets = convertNanToZero(this.state.newPallets)

            if ((newLocationUnits === null || newLocationUnits == 0) && (newLocationCases === null || newLocationCases == 0) && (newLocationPallets === null || newLocationPallets == 0)) {
                window.alert('Please select Units, Cases, Pallets to move.')
            }
            else if (newLocationID === null) {
                window.alert('Please select new location.')
            }
            else {
                try {
                    // call Inventory_SKU_Warehouse_Location_Post twice
                    // first time call with old location and send new totals for old location
                    this.props.inventorySkuWarehouseLocationPost(
                        this.props.currentUserEmail,
                        this.props.userSignInData.AuthToken,
                        skuID,
                        originalLocationSkuWarehouseLocID,
                        originalLocationWarehouseID,
                        convertNanToZero(originalLocationUnits),
                        convertNanToZero(originalLocationCases),
                        convertNanToZero(originalLocationPallets),
                        originalLocationOversize,
                        originalLocationWarehouseID,
                        warehouseID,
                        customerID,
                        null,
                        null,
                        null,
                        null
                    )
                    // second time call with new location and new counts
                    this.props.inventorySkuWarehouseLocationPost(
                        this.props.currentUserEmail,
                        this.props.userSignInData.AuthToken,
                        skuID,
                        null,
                        newLocationID,
                        convertNanToZero(newLocationUnits),
                        convertNanToZero(newLocationCases),
                        convertNanToZero(newLocationPallets),
                        newLocationOversize,
                        originalLocationWarehouseID,
                        warehouseID,
                        customerID,
                        null,
                        null,
                        null,
                        null
                    )

                    this.setStateCloseEditSingleWarehouseLocationsDrawer()
                }
                catch (e) { }
            }
        }
        catch (e) {

        }


    }
    closeEditSingleWarehouseLocationsDrawer() {
        if (this.state.unsavedEditWarehouseLocationValues) {
            if (window.confirm('Leave without saving changes?')) {
                this.setStateCloseEditSingleWarehouseLocationsDrawer()
                window.location.reload();
            }
        }
        else {
            this.setStateCloseEditSingleWarehouseLocationsDrawer()
        }
    }
    setStateCloseEditSingleWarehouseLocationsDrawer() {
        this.setState({
            showEditSingleWarehouseLocationsDrawer: false,
            unsavedEditWarehouseLocationValues: false,
            LocationOriginalDict: {},
            LocationToEditDict: {},
            newWarehouseLocationID: null,
            newUnits: 0,
            newCases: 0,
            newPallets: 0,
            newOversize: 0,

        });
    }





    //Edit Case Drawer
    onClickEditCase() {
        this.setState({
            LotCode: this.props.InventoryCaseWarehouseData[0].LotCode,
            SerialNumber: this.props.InventoryCaseWarehouseData[0].SerialNumber,
            CustomerRef: this.props.InventoryCaseWarehouseData[0].CustomerRefID,
            SKU: null,
            Project: this.props.InventoryCaseWarehouseData[0].ProjectID,
            Notes: this.props.InventoryCaseWarehouseData[0].Notes,
            showEditCaseDrawer: true,
        });

        // this.openEditSku()
    }
    openEditSku(placement) {
        this.setState({
            placement,
            showEditCaseDrawer: true,

        });
    }


    onChangeEditCase = (event, label) => {
        this.setState({
            [label]: event,
            unsavedEditCaseValues: true
        });
    }

    onClickConfirmEditCase(e) {
        this.props.inventoryLotCaseRefEditPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.InventoryCaseWarehouseData[0].CaseID, null, null, null, formatStringForAPI(this.state.Notes), formatStringForAPI(this.state.LotCode), formatStringForAPI(this.state.SerialNumber), null, formatStringForAPI(this.state.CustomerRef), null, formatStringForAPI(this.state.Project))
    }
    closeEditCaseDrawer() {
        if (this.state.unsavedEditCaseValues) {
            if (window.confirm('Leave without saving changes?')) {
                this.setStateCloseEditCaseDrawer()
                // window.location.reload();
            }
        }
        else {
            this.setStateCloseEditCaseDrawer()
        }
    }
    setStateCloseEditCaseDrawer() {
        this.setState({
            showEditCaseDrawer: false,
            unsavedEditCaseValues: false,

            LotCode: null,
            SerialNumber: null,
            CustomerRef: null,
            SKU: null,
            Project: null,
            Notes: null,
        });
    }


    // onPrintContent = () => {

    //     this.setState({ showPrintableContent: true }, () => {

    //         var container = document.getElementById("div-svg");
    //         var mySVG = document.getElementById("barcode-canvas");
    //         var width = "100%";
    //         var height = "100%";
    //         var printWindow = window.open('', 'PrintMap',
    //             'width=' + width + ',height=' + height);
    //         printWindow.document.writeln(container.innerHTML);
    //         printWindow.document.close();
    //         printWindow.print();
    //         printWindow.close();


    //         this.setState({ showPrintableContent: false });


    //     });

    // }

    onPrintContent = () => {
        this.setState({ showPrintableContent: true }, () => {
            var container = document.getElementById("div-svg");
            var mySVG = document.getElementById("barcode-canvas");
            var printWindow = window.open('', 'PrintMap',
                'width=' + window.innerWidth + ',height=' + window.innerHeight);
            printWindow.document.writeln(container.innerHTML);
            printWindow.document.close();
            printWindow.print();
            printWindow.close();

            this.setState({ showPrintableContent: false });
        });
    }




    onPrintBarcode = () => {
        this.setState({ showPrintableBarcode: true }, () => {

            // var container = document.getElementById("div-svg");
            // var mySVG = document.getElementById("barcode-canvas");
            // var width = "100%";
            // var height = "100%";
            // var printWindow = window.open('', 'PrintMap',
            //     'width=' + width + ',height=' + height);
            // printWindow.document.writeln(container.innerHTML);
            // printWindow.document.close();
            // printWindow.print();
            // printWindow.close();


            // var container = document.getElementById("div-svg");
            // var mySVG = document.getElementById("barcode-canvas");
            // var width = "100%";
            // var height = "100%";
            // var printWindow = window.open('', 'PrintMap',
            //     'width=' + width + ',height=' + height);
            // printWindow.document.writeln(container.innerHTML);
            // printWindow.document.close();
            // printWindow.print();
            // printWindow.close();


            var container = document.getElementById("div-svg");
            var printWindow = window.open('', 'PrintMap', 'width=' + window.innerWidth + ',height=' + window.innerHeight);
            printWindow.document.writeln(container.innerHTML);
            printWindow.document.close();
            printWindow.print();
            printWindow.close();


            this.setState({ showPrintableBarcode: false });

        });

    }

    //save customer selection
    saveCustomerSelectionTosessionStorage(customerToSave) {
        try {
            sessionStorage.setItem('currentCustomer', customerToSave)
        }
        catch (e) {

        }
    }


    onClickUnitDetails(e, rowData, history) {

        var newCustID = rowData.CustomerID
        this.saveCustomerSelectionTosessionStorage(newCustID)

        var newSku = rowData.SKU
        var newLotCode = rowData.LotCode
        var newSerial = rowData.SerialNumber
        var selectedWarehouseID = rowData.WarehouseLocationID
        var selectedStatusID = rowData.SkuStatusID

        let searchParams = new URLSearchParams(this.props.location.search);
        searchParams.set('warehouselocationid', selectedWarehouseID);
        searchParams.set('unitsku', newSku);
        searchParams.set('unitlotcode', newLotCode);
        searchParams.set('unitserial', newSerial);
        searchParams.set('reportnav', 'sku');
        searchParams.set('statusid', selectedStatusID);
        searchParams.set('filtershown', false);


        // searchParams.set('unitsku', this.props.InventorySkuRefData[0].SKU.toString());
        searchParams.set('caseid', this.props.InventoryCaseWarehouseData[0].CaseID);

        let newSearchString = searchParams.toString();

        try {
            history.push({
                pathname: 'inventorypcs',
                search: newSearchString
            });

            // window.location.reload();
        }
        catch (e) {
        }
    }



    onClickPalletDetails = (e) => {
        var paramID = this.props.InventoryPalletWarehouseData[0].PalletID
        this.props.history.push({
            pathname: 'palletdetails',
            search: 'palletid=\'' + paramID + '\''
        });
    }


    //History Type Modal
    handleModalOpen = () => {

        this.setState({ historyTypeModalOpen: true });

    };

    handleModalClose = () => {
        this.setState({ historyTypeModalOpen: false });
    };

    handleModalSelect = (value) => {
        if (window.confirm('Are you sure you want to delete this case?  This will also remove contents from inventory.')) {
            this.props.inventoryLotDeleteContainerPalletCaseUnitPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, value, null, null, null, formatStringForAPI(this.props.InventoryCaseWarehouseData[0].CaseID), null, null, null)
        }
    };

    onClickInventoryDelete = (e) => {
        this.handleModalOpen();
    };


    render() {
        const { sortColumn, sortType } = this.state;
        const { showPrintableContent, showPrintableBarcode } = this.state;


        if (this.props.isInventoryLotCaseRefEditLoading) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                    <p style={{ marginTop: '20px', fontSize: '20px', color: '#3396E5' }}>Please wait, updating...</p>
                </div>
            );
        }



        let caseLabel;
        let caseContents;
        let skuDetailsDiv;
        let pageHeader = <div>Inventory Management - Case</div>
        if (this.props.isInventoryCaseWarehouseLoading) {
            skuDetailsDiv = <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>

        }

        else if (typeof (this.props.InventoryCaseWarehouseData[0]) !== 'undefined' && this.props.InventoryCaseWarehouseData != null && typeof (this.props.InventoryCaseWarehouseData[0]) !== 'undefined' && this.props.InventoryCaseWarehouseData != null) {
            try {
                var skuName = this.props.InventoryCaseWarehouseData[0].CaseBarcode.toString()
                pageHeader = <div style={{ marginBottom: '15px' }}>  {'Inventory Management - Case: ' + skuName} </div>
            }
            catch (e) {

            }



            if (this.props.isInventorySkuWarehouseLoading) {
                caseLabel = <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
                caseContents = <div></div>

            }
            else {


                let caseLabelContents = <div>
                    <p style={{ fontSize: '12px', margin: '0', textAlign: 'left' }}>
                        Case SKU: {this.props.InventoryCaseWarehouseData[0].CaseSKU}
                    </p>
                    {this.props.InventoryCaseWarehouseData[0].LotCode ?
                        <p style={{ fontSize: '12px', margin: '0', textAlign: 'left' }}>
                            Lot: {this.props.InventoryCaseWarehouseData[0].LotCode}
                        </p>
                        : null}
                    <p style={{ fontSize: '12px', margin: '0', textAlign: 'left' }}>
                        Pallet Barcode: {this.props.InventoryCaseWarehouseData[0].PalletBarcode}
                    </p>
                </div>


                caseLabel = <BarcodeCard barcode={this.props.InventoryCaseWarehouseData[0].CaseBarcode} barcodeContent={caseLabelContents}/>
                caseContents = <CaseContents barcode={this.props.InventoryCaseWarehouseData[0].CaseBarcode} content={this.props.InventorySkuWarehouseData} />
            }





            skuDetailsDiv =
                <div>
                    <Row >



                        <Col md={24} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }} header={
                                <div style={{ float: 'right' }}>
                                    {
                                        [0, 1, 2].includes(this.props.InventoryCaseWarehouseData[0].CaseStatusID) ?
                                            <Button style={{ float: 'right', marginRight: '10px' }} onClick={(e) => { this.onClickInventoryDelete(e) }} >Delete Case</Button>
                                            : null
                                    }

                                    <Button style={{ marginRight: '10px' }} onClick={(e) => { this.onClickEditCase(e) }} >Edit Case</Button>

                                    <Button style={{ marginRight: '10px' }} onClick={(e) => { this.onClickMoveCase(e) }} >Move Case</Button>
                                </div>
                            }>

                                <Col md={12} sm={12}>

                                    Warehouse Location: <strong>{this.props.InventoryCaseWarehouseData[0].WarehouseLocationName}</strong>
                                    <br />
                                    Warehouse: <strong>{this.props.InventoryCaseWarehouseData[0].WarehouseName}</strong>
                                    <br />
                                    Customer: <strong>{this.props.InventoryCaseWarehouseData[0].CustomerName}</strong>
                                    <br />
                                    Case Barcode: <strong>{this.props.InventoryCaseWarehouseData[0].CaseBarcode}</strong>
                                    <br />
                                    Case SKU: <strong>{this.props.InventoryCaseWarehouseData[0].CaseSKU}</strong>
                                    <br />
                                    Case Status: <strong>{this.props.InventoryCaseWarehouseData[0].CaseStatusName}</strong>
                                    <br />
                                    Pallet Barcode: <strong>{this.props.InventoryCaseWarehouseData[0].PalletBarcode}</strong>
                                    <br />
                                    <br />

                                </Col>
                                <Col md={12} sm={12}>
                                    Lot Code: <strong>{this.props.InventoryCaseWarehouseData[0].LotCode}</strong>
                                    <br />
                                    Serial Number: <strong>{this.props.InventoryCaseWarehouseData[0].SerialNumber}</strong>
                                    <br />
                                    Project: <strong>{this.props.InventoryCaseWarehouseData[0].Project}</strong>
                                    <br />
                                    Customer Ref: <strong>{this.props.InventoryCaseWarehouseData[0].CustomerRef}</strong>
                                    <br />
                                    Notes: <strong>{this.props.InventoryCaseWarehouseData[0].Notes}</strong>
                                    <br />
                                    <br />
                                </Col>

                            </Panel>
                        </Col>

                    </Row>
                    <Row >
                        <Col md={24} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }} header={
                                <div >
                                    <Col md={12} sm={12}>
                                        <Button block onClick={(e) => { this.onPrintBarcode(e) }} >Print Case Label</Button>
                                    </Col>
                                    <Col md={12} sm={12}>
                                        <Button block onClick={(e) => { this.onPrintContent(e) }} >Print Case Contents</Button>
                                    </Col>
                                </div>
                            }>

                                <div>

                                    {showPrintableContent && (
                                        <div >

                                            {caseContents}
                                        </div>
                                    )}

                                    {showPrintableBarcode && (
                                        <div >
                                            {caseLabel}

                                        </div>
                                    )}

                                </div>
                            </Panel>
                        </Col>
                    </Row>
                </div>



        }
        else {
            skuDetailsDiv = <div></div>
        }






        let unitDiv;

        if (this.props.isInventorySkuWarehouseGroupedLoading) {
            unitDiv = <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>

        }

        else if (typeof (this.props.InventorySkuWarehouseGroupedData[0]) !== 'undefined' && this.props.InventorySkuWarehouseGroupedData != null) {
            // var CasesInWhse = this.props.InventorySkuWarehouseGroupedData.map(({CasesInWhse})=> CasesInWhse).reduce((a, b)=> a+b, 0);
            // var CasesLoaded = this.props.InventorySkuWarehouseGroupedData.map(({CasesLoaded})=> CasesLoaded).reduce((a, b)=> a+b, 0);
            // var CasesReceived = this.props.InventorySkuWarehouseGroupedData.map(({CasesReceived})=> CasesReceived).reduce((a, b)=> a+b, 0);

            var UnitsInWhse = this.props.InventorySkuWarehouseGroupedData.map(({ UnitsInWhse }) => UnitsInWhse).reduce((a, b) => a + b, 0);
            var UnitsLoaded = this.props.InventorySkuWarehouseGroupedData.map(({ UnitsLoaded }) => UnitsLoaded).reduce((a, b) => a + b, 0);
            var UnitsRecovered = this.props.InventorySkuWarehouseGroupedData.map(({ UnitsRecovered }) => UnitsRecovered).reduce((a, b) => a + b, 0);



            unitDiv =
                <div>
                    <Row>

                        {/* <Col md={6} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }}>
                                Units Expected: <strong>{this.props.InventorySkuWarehouseGroupedData[0].UnitsExpected}</strong>
                                <br />
                                Cases Expected: <strong>{this.props.InventorySkuWarehouseGroupedData[0].CasesExpected}</strong>
                                <br />
                                Pallets Expected: <strong>{this.props.InventorySkuWarehouseGroupedData[0].PalletsExpected}</strong>
                                <br />
                            </Panel>
                        </Col> */}
                        <Col md={8} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }}>
                                Units Received: <strong>{UnitsRecovered}</strong>
                                <br />
                                {/* Cases Received: <strong>{CasesReceived}</strong>
                                <br /> */}
                                {/* Pallets Received: <strong>{this.props.InventorySkuWarehouseGroupedData[0].PalletsReceived}</strong> */}
                                {/* <br /> */}
                            </Panel>
                        </Col>
                        <Col md={8} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }}>
                                Units In Whse: <strong>{UnitsInWhse}</strong>
                                <br />
                                {/* Cases In Whse: <strong>{CasesInWhse}</strong>
                                <br /> */}
                                {/* Pallets In Whse: <strong>{this.props.InventorySkuWarehouseGroupedData[0].Pallets}</strong> */}
                                {/* <br /> */}
                            </Panel>
                        </Col>
                        <Col md={8} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }}>
                                Units Shipped: <strong>{UnitsLoaded}</strong>
                                <br />
                                {/* Cases Shipped: <strong>{CasesLoaded}</strong>
                                <br /> */}
                                {/* Pallets Shipped: <strong>{this.props.InventorySkuWarehouseGroupedData[0].PalletsLoaded}</strong> */}
                                {/* <br /> */}
                            </Panel>
                        </Col>

                    </Row>
                </div>



        }
        else {
            unitDiv = <div></div>
        }




        // let skuDetailsDiv;
        // let pageHeader;
        // if (this.props.isInventoryLotSkuLoading) {
        //     pageHeader = <div>Inventory Management - SKU</div>

        //     skuDetailsDiv = <div align="center">
        //         <section className="panel">
        //         </section>
        //         <Loader
        //             type="TailSpin"
        //             color="#3396E5"
        //             height="50"
        //             width="50"
        //         />
        //     </div>

        // }
        // else if (typeof (this.props.InventoryLotSkuData[0]) !== 'undefined' && this.props.InventoryLotSkuData != null) {
        //     try {
        //         var skuName = this.props.InventoryLotSkuData[0].SKU.toString()
        //         pageHeader = <div style={{ marginBottom: '15px' }}>  {'Inventory Management - SKU: ' + skuName} </div>
        //     }
        //     catch (e) {
        //         pageHeader = <div>Inventory Management - SKU</div>
        //     }

        //     skuDetailsDiv =
        //         <div>
        //             <Row >
        //                 <Col md={24} sm={24}>
        //                     <Panel bordered style={{ marginBottom: 10 }} header={
        //                         <div style={{ float: 'right' }}>
        //                             <Button style={{ float: 'right' }} onClick={(e) => { this.onClickEditCase(e) }} >Edit</Button>
        //                         </div>
        //                     }>





        //                         Warehouse: <strong>{this.props.InventoryLotSkuData[0].WarehouseName}</strong>
        //                         <br />
        //                         Customer: <strong>{this.props.InventoryLotSkuData[0].CustomerName}</strong>
        //                         <br />
        //                         Customer Ref: <strong>{this.props.InventoryLotSkuData[0].CustomerRef}</strong>
        //                         <br />
        //                         Project: <strong>{this.props.InventoryLotSkuData[0].Project}</strong>
        //                         <br />
        //                         Status: <strong>{this.props.InventoryLotSkuData[0].SkuStatusName}</strong>
        //                         <br />
        //                         Notes: <strong>{this.props.InventoryLotSkuData[0].Notes}</strong>
        //                         <br />
        //                     </Panel>
        //                 </Col>
        //             </Row>
        //             <Row>

        //             InventorySkuWarehouseGroupedData

        //                 {/* <Col md={6} sm={24}>
        //                     <Panel bordered style={{ marginBottom: 10 }}>
        //                         Units Expected: <strong>{this.props.InventoryLotSkuData[0].UnitsExpected}</strong>
        //                         <br />
        //                         Cases Expected: <strong>{this.props.InventoryLotSkuData[0].CasesExpected}</strong>
        //                         <br />
        //                         Pallets Expected: <strong>{this.props.InventoryLotSkuData[0].PalletsExpected}</strong>
        //                         <br />
        //                     </Panel>
        //                 </Col> */}
        //                 <Col md={8} sm={24}>
        //                     <Panel bordered style={{ marginBottom: 10 }}>
        //                         Units Received: <strong>{this.props.InventoryLotSkuData[0].UnitsReceived}</strong>
        //                         <br />
        //                         Cases Received: <strong>{this.props.InventoryLotSkuData[0].CasesReceived}</strong>
        //                         <br />
        //                         Pallets Received: <strong>{this.props.InventoryLotSkuData[0].PalletsReceived}</strong>
        //                         <br />
        //                     </Panel>
        //                 </Col>
        //                 <Col md={8} sm={24}>
        //                     <Panel bordered style={{ marginBottom: 10 }}>
        //                         Units In Whse: <strong>{this.props.InventoryLotSkuData[0].Units}</strong>
        //                         <br />
        //                         Cases In Whse: <strong>{this.props.InventoryLotSkuData[0].Cases}</strong>
        //                         <br />
        //                         Pallets In Whse: <strong>{this.props.InventoryLotSkuData[0].Pallets}</strong>
        //                         <br />
        //                     </Panel>
        //                 </Col>
        //                 <Col md={8} sm={24}>
        //                     <Panel bordered style={{ marginBottom: 10 }}>
        //                         Units Shipped: <strong>{this.props.InventoryLotSkuData[0].UnitsLoaded}</strong>
        //                         <br />
        //                         Cases Shipped: <strong>{this.props.InventoryLotSkuData[0].CasesLoaded}</strong>
        //                         <br />
        //                         Pallets Shipped: <strong>{this.props.InventoryLotSkuData[0].PalletsLoaded}</strong>
        //                         <br />
        //                     </Panel>
        //                 </Col>

        //             </Row>
        //         </div>



        // }
        // else {
        //     pageHeader = <div>Inventory Management - SKU</div>
        //     skuDetailsDiv = <div></div>
        // }


        let shipmentPoTable;
        let tableDataDiv;
        if (this.props.isInventorySkuWarehouseLocationLoading) {
            shipmentPoTable = <div></div>
            tableDataDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventorySkuWarehouseLocationData) !== 'undefined' && this.props.InventorySkuWarehouseLocationData != null) {
            shipmentPoTable = RTableGeneral(
                "SkuWarehouseLocationID",     //   keyColumn,
                this.props.InventorySkuWarehouseLocationData,     //   tableData,
                InventorySkuWarehouseLocationDataElements,       //   DataElements,
                this.props.isInventorySkuWarehouseLocationLoading,        //   loading,

                this.props.isMobile,        //   isMobile,
                this.props.history,     //   history,

                sortColumn,     //   sortColumn,
                sortType,       //   sortType,
                this.handleSortColumn,      //   handleSortColumn,

                false, // checkColEntry,
                null,       //   checkedKeys,
                null,       //   handleCheckAll,
                null,       //   handleCheck,

                false,      //   boolSelectButton,
                null,       //   onClickSelect,
                null,       //   selectButtonText,
                null,       //   selectHighlightRowKey,

                true,       //   link1bool,
                'Details',     //   link1text,
                this.onClickWarehouseLocationDetails,     //   link1onClick,

                true,       //   link2bool,
                'Edit',     //   link2text,
                this.onclickEditWarehouseLocation,     //   link2onClick,
            )

            tableDataDiv = <Row>
                <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Warehouse_' + currentDateTime}>
                    <ExcelSheet data={this.props.InventorySkuWarehouseLocationData} name="Warehouse">
                        {
                            InventorySkuWarehouseLocationDataElements.map(
                                ({ dBKey, displayName }, idx) => {
                                    return (
                                        <ExcelColumn label={displayName} value={dBKey} />
                                    );
                                }
                            )
                        }
                    </ExcelSheet>
                </ExcelFile>
            </Row>
        }
        else {
            shipmentPoTable = <div></div>
            tableDataDiv = <div></div>
        }







        let skuRecoveryTimeline;
        if (this.props.isInventorySkuRecoveryHistoryLoading) {
            skuRecoveryTimeline =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventorySkuRecoveryHistoryData[0]) !== 'undefined' && this.props.InventorySkuRecoveryHistoryData != null) {
            skuRecoveryTimeline =
                <div>
                    <Row >
                        <Col md={24} sm={24}>
                            <Panel bordered header="SKU Recovery History" style={{ marginBottom: 10 }}>
                                <Timeline>
                                    {this.props.InventorySkuRecoveryHistoryData.map(item => (
                                        <Timeline.Item>
                                            {item.SkuRecoveryDT}  &nbsp;
                                            {item.EmailAddr}
                                            <l></l>
                                            {/* {item.SKU != null ? <l><br /> SKU:  {item.SKU} </l> : ' '} */}
                                            {item.Container != null ? <l><br /> Received From Container:  {item.Container} </l> : ' '}
                                            {item.UnitsReceived != null ? <l><br /> Units Received:  {item.UnitsReceived} </l> : ' '}
                                            {item.CasesReceived != null ? <l><br /> Cases Received:  {item.CasesReceived} </l> : ' '}
                                            {item.PalletsReceived != null ? <l><br /> Pallets Received:  {item.PalletsReceived} </l> : ' '}
                                            {/* {item.Oversize != null ? <l><br /> Oversize:  {item.Oversize === true ? "True" : "False"} </l> : ' '} */}
                                            {item.SkuDescription != null ? <l><br /> Description:  {item.SkuDescription} </l> : ' '}

                                        </Timeline.Item>
                                    ))}
                                </Timeline>

                            </Panel>
                        </Col>
                    </Row>

                </div>
        }
        else {
            skuRecoveryTimeline = <div></div>
        }



        let skuWarehouseLocationHistoryTimeline;
        if (this.props.isInventorySkuWarehouseLocationHistoryLoading) {
            skuWarehouseLocationHistoryTimeline =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventorySkuWarehouseLocationHistoryData[0]) !== 'undefined' && this.props.InventorySkuWarehouseLocationHistoryData != null) {
            skuWarehouseLocationHistoryTimeline =
                <div>
                    <Row >
                        <Col md={24} sm={24}>
                            <Panel bordered header="Warehouse Location History" style={{ marginBottom: 10 }}>
                                <Timeline>
                                    {this.props.InventorySkuWarehouseLocationHistoryData.map(item => (
                                        <Timeline.Item>
                                            {item.CreatedDT}  &nbsp;
                                            {item.EmailAddr}
                                            <l><br />{item.Notes} &nbsp;
                                            </l>
                                            {item.WarehouseLocationName != null ? <l><br /> Warehouse Location:  {item.WarehouseLocationName} </l> : ' '}
                                            {item.PreviousWarehouseLocationName != null ? <l><br /> Previous Warehouse Location:  {item.PreviousWarehouseLocationName} </l> : ' '}
                                            {item.Units != null ? <l><br /> Units:  {item.Units} </l> : ' '}
                                            {item.Cases != null ? <l><br /> Cases:  {item.Cases} </l> : ' '}
                                            {item.Pallets != null ? <l><br /> Pallets:  {item.Pallets} </l> : ' '}
                                            {item.Oversize != null ? <l><br /> Oversize:  {item.Oversize === true ? "True" : "False"} </l> : ' '}


                                        </Timeline.Item>
                                    ))}
                                </Timeline>

                            </Panel>
                        </Col>
                    </Row>

                </div>
        }
        else {
            skuWarehouseLocationHistoryTimeline = <div></div>
        }




        let loadDiv;
        let rTable;
        let loadsDataDiv;
        if (this.props.isInventoryLoadLotLoading) {
            rTable = <div></div>
            loadsDataDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventoryLoadLotData) !== 'undefined' && this.props.InventoryLoadLotData != null) {

            if (this.props.InventoryLoadLotData.length === 0) {
                loadsDataDiv = <div></div>
            }
            else {

                // let rTable;
                rTable = RTableGeneral(
                    'LoadID',     //   keyColumn,
                    this.props.InventoryLoadLotData,     //   tableData,
                    InventoryLoadSkuDetailsDataElements,       //   DataElements,
                    this.props.isInventoryLoadLotLoading,        //   loading,

                    this.props.isMobile,        //   isMobile,
                    this.props.history,     //   history,

                    sortColumn,     //   sortColumn,
                    sortType,       //   sortType,
                    this.handleSortColumn,      //   handleSortColumn,

                    false, // checkColEntry,
                    null,       //   checkedKeys,
                    null,       //   handleCheckAll,
                    null,       //   handleCheck,

                    false,      //   boolSelectButton,
                    null,       //   onClickSelect,
                    null,       //   selectButtonText,
                    null,       //   selectHighlightRowKey,

                    true,       //   link1bool,
                    'Details',     //   link1text,
                    this.onClickLoadDetails,     //   link1onClick,

                    false,       //   link2bool,
                    null,       //   link2text,
                    null  //   link2onClick
                )

                loadDiv = <div>


                    {(this.props.isMobile == true) ? <div></div> :
                        <div>
                            <Row>
                                <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Load_' + currentDateTime}>
                                    <ExcelSheet data={this.props.InventoryLoadLotData} name="Load">

                                        {
                                            InventoryLoadSkuDetailsDataElements.map(
                                                ({ dBKey, displayName }, idx) => {
                                                    return (
                                                        <ExcelColumn label={displayName} value={dBKey} />
                                                    );
                                                }
                                            )
                                        }

                                    </ExcelSheet>
                                </ExcelFile>
                            </Row>
                        </div>
                    }

                </div>




                loadsDataDiv = <Row >
                <Col md={24} sm={24}>
                    <Panel bordered header="Loads" style={{ marginBottom: 10 }}>
                        {loadDiv}
                        {rTable}
                    </Panel>
                </Col>
                </Row>
            }
        }
        else {
            rTable = <div></div>
            loadDiv = <div></div>
            loadsDataDiv = <div></div>
        }



        // let editWarehouseLocationDrawerDiv;
        // let unsavedLocationChanges
        // if (!this.state.showEditSingleWarehouseLocationsDrawer) {
        //     editWarehouseLocationDrawerDiv = <div></div>
        //     unsavedLocationChanges = false
        // }
        // else {
        //     unsavedLocationChanges = this.state.unsavedEditWarehouseLocationValues
        //     editWarehouseLocationDrawerDiv = <EditSingleWarehouseLocationsDrawer {...this.props} state={this.state} onChangeLocation={this.onChangeEditLocationDrawer} onClickAddLocation={this.onClickAddLocation} confirm={this.onClickSaveEditWarehouseLocation} close={this.closeEditSingleWarehouseLocationsDrawer} unsavedChanges={unsavedLocationChanges} />
        // }







        // let shipmentSkuTable;
        // let tableSkuDataDiv;
        // if (this.props.isInventorySkuWarehouseLoading) {
        //     shipmentSkuTable = <div></div>
        //     tableSkuDataDiv =
        //         <div align="center">
        //             <section className="panel">
        //             </section>
        //             <Loader
        //                 type="TailSpin"
        //                 color="#3396E5"
        //                 height="50"
        //                 width="50"
        //             />
        //         </div>
        // }
        // else if (typeof (this.props.InventorySkuWarehouseData) !== 'undefined' && this.props.InventorySkuWarehouseData != null) {
        //     const values = queryString.parse(this.props.location.search)
        //     try {
        //         var paramPoStatusID = values.statusid.split(',').map(Number);
        //     }
        //     catch (e) {
        //         var paramPoStatusID = []
        //     }
        //     shipmentSkuTable = RTableGeneral(
        //         'CaseSkuID',     //   keyColumn,
        //         this.props.InventorySkuWarehouseData,     //   tableData,
        //         InventorySkuWarehouseDataElements,       //   DataElements,
        //         this.props.isInventorySkuWarehouseLoading,        //   loading,

        //         this.props.isMobile,        //   isMobile,
        //         this.props.history,     //   history,

        //         null, //sortColumn,     //   sortColumn,
        //         null, //sortType,       //   sortType,
        //         null, //this.handleSortColumn,      //   handleSortColumn,
        //         false, // checkColEntry,
        //         null,       //   checkedKeys,
        //         null,       //   handleCheckAll,
        //         null,       //   handleCheck,

        //         false,      //   boolSelectButton,
        //         null,       //   onClickSelect,
        //         null,       //   selectButtonText,
        //         null,       //   selectHighlightRowKey,

        //         true,       //   link1bool,
        //         'Details',     //   link1text,
        //         this.onClickSkuDetails,     //   link1onClick,

        //         false,       //   link2bool,
        //         null,       //   link2text,
        //         null  //   link2onClick
        //     )
        //     tableSkuDataDiv = <div>
        //         {(this.props.isMobile == true) ? <div></div> :
        //             <div>
        //                 <Row>
        //                     {/* {paramPoStatusID.includes(1) || paramPoStatusID.includes(2) || paramPoStatusID == null ? <Button style={{ float: 'left' }} onClick={(e) => { this.onClickBuildLoad(e) }}>Build Load Selected</Button> : ''} */}
        //                     <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Warehouse_' + currentDateTime}>
        //                         <ExcelSheet data={this.props.InventorySkuWarehouseData} name="Warehouse">
        //                             {
        //                                 InventorySkuWarehouseDataElements.map(
        //                                     ({ dBKey, displayName }, idx) => {
        //                                         return (
        //                                             <ExcelColumn label={displayName} value={dBKey} />
        //                                         );
        //                                     }
        //                                 )
        //                             }
        //                         </ExcelSheet>
        //                     </ExcelFile>
        //                 </Row>
        //             </div>
        //         }
        //     </div>
        // }
        // else {
        //     shipmentSkuTable = <div></div>
        //     tableSkuDataDiv = <div></div>
        // }







        let lotSkuTable;
        let lotSkuDataDiv;
        let unitGroupedDiv;
        if (this.props.isInventoryUnitWarehouseGroupedLoading) {
            unitGroupedDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventoryUnitWarehouseGroupedData) !== 'undefined' && this.props.InventoryUnitWarehouseGroupedData != null) {

            lotSkuTable = RTableGeneral(
                'ID',     //   keyColumn,
                this.props.InventoryUnitWarehouseGroupedData,     //   tableData,
                InventoryUnitWarehouseGroupedDataElements,       //   DataElements,
                this.props.isInventoryUnitWarehouseGroupedLoading,        //   loading,

                this.props.isMobile,        //   isMobile,
                this.props.history,     //   history,

                sortColumn,     //   sortColumn,
                sortType,       //   sortType,
                this.handleSortColumn,      //   handleSortColumn,
                false, // checkColEntry,
                null,       //   checkedKeys,
                null,       //   handleCheckAll,
                null,       //   handleCheck,

                false,      //   boolSelectButton,
                null,       //   onClickSelect,
                null,       //   selectButtonText,
                null,       //   selectHighlightRowKey,

                true,       //   link1bool,
                'Units',     //   link1text,
                this.onClickUnitDetails,     //   link1onClick,

                false,       //   link2bool,
                null,       //   link2text,
                null  //   link2onClick
            )
            lotSkuDataDiv = <div>
                {(this.props.isMobile == true) ? <div></div> :
                    <div>
                        <Row>
                            <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Units_' + currentDateTime}>
                                <ExcelSheet data={this.props.InventoryUnitWarehouseGroupedData} name="Units">
                                    {
                                        InventoryUnitWarehouseGroupedDataElements.map(
                                            ({ dBKey, displayName }, idx) => {
                                                return (
                                                    <ExcelColumn label={displayName} value={dBKey} />
                                                );
                                            }
                                        )
                                    }
                                </ExcelSheet>
                            </ExcelFile>
                        </Row>
                    </div>
                }
            </div>

            if (this.props.InventoryUnitWarehouseGroupedData.length > 0) {
                unitGroupedDiv = <Row >
                    <Col md={24} sm={24}>
                        <Panel bordered header="Units" style={{ marginBottom: 10 }}>
                            {lotSkuDataDiv}
                            {lotSkuTable}
                        </Panel>
                    </Col>
                </Row>

            }
            else {
                unitGroupedDiv = <div></div>
            }
        }
        else {
            unitGroupedDiv = <div></div>
        }


        let palletDetails;

        if (this.props.isInventoryPalletWarehouseLoading) {
            palletDetails =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>

        }

        else if (typeof (this.props.InventoryPalletWarehouseData[0]) !== 'undefined' && this.props.InventoryPalletWarehouseData != null) {
            let pageHeader
            let palletLabel;
            let palletContents;
            try {
                var skuName = this.props.InventoryPalletWarehouseData[0].PalletBarcode.toString()
                pageHeader = <div style={{ marginBottom: '15px' }}>  {'Pallet: ' + skuName} </div>
            }
            catch (e) {

            }
            palletDetails = <div>

                <Row >
                    <Col md={24} sm={24}>
                        <Panel bordered style={{ marginBottom: 10 }} header={
                            <div>
                                {pageHeader}
                                {/* <div style={{ float: 'right' }}> */}
                                <Button style={{ float: 'right' }} onClick={(e) => { this.onClickPalletDetails(e) }} >Pallet Details</Button>
                                {/* </div> */}
                            </div>
                        }>
                            Warehouse Location: <strong>{this.props.InventoryPalletWarehouseData[0].WarehouseLocationName}</strong>
                            <br />
                            Warehouse: <strong>{this.props.InventoryPalletWarehouseData[0].WarehouseName}</strong>
                            <br />
                            Customer: <strong>{this.props.InventoryPalletWarehouseData[0].CustomerName}</strong>
                            <br />
                            Pallet Barcode: <strong>{this.props.InventoryPalletWarehouseData[0].PalletBarcode}</strong>
                            <br />
                            Pallet Status: <strong>{this.props.InventoryPalletWarehouseData[0].PalletStatusName}</strong>
                            <br />
                        </Panel>
                    </Col>
                </Row>




            </div>
        }
        else {
            palletDetails = <div></div>
        }

        return (
            <div>
                <h3>
                    {pageHeader}
                </h3>

                <PanelGroup>
                    {skuDetailsDiv}
                </PanelGroup>


                {unitDiv}


                {palletDetails}

                {unitGroupedDiv}


                {loadsDataDiv}

                <CaseHistoryDiv
                    isInventoryCaseHistoryLoading={this.props.isInventoryCaseHistoryLoading}
                    InventoryCaseHistoryData={this.props.InventoryCaseHistoryData}
                />


                <PalletWarehouseLocationHistoryDiv
                    isInventoryPalletWarehouseLocationHistoryLoading={this.props.isInventoryPalletWarehouseLocationHistoryLoading}
                    InventoryPalletWarehouseLocationHistoryData={this.props.InventoryPalletWarehouseLocationHistoryData}
                />

                <CaseSKUsHistoryDiv
                    isInventoryCaseSkusHistoryLoading={this.props.isInventoryCaseSkusHistoryLoading}
                    InventoryCaseSkusHistoryData={this.props.InventoryCaseSkusHistoryData}
                />

                <LoadCaseHistoryDiv
                    isInventoryLoadCasesHistoryLoading={this.props.isInventoryLoadCasesHistoryLoading}
                    InventoryLoadCasesHistoryData={this.props.InventoryLoadCasesHistoryData}
                />


                {/* {skuRecoveryTimeline}
                {skuWarehouseLocationHistoryTimeline} */}


                {/* {editWarehouseLocationDrawerDiv} */}

                {/* <EditSkuDetailsDrawer {...this.props} state={this.state} onChangeProject={this.onChangeProject} onChangeNotes={this.onChangeNotes} onChangeCustomerRef={this.onChangeCustomerRef} onChangeSKU={this.onChangeSKU} confirm={this.onClickConfirmEditCase} close={this.closeEditCaseDrawer} unsavedChanges={this.state.unsavedEditCaseValues} /> */}


                <MoveCaseDrawer
                    {...this.props}
                    {...this.state}
                    isOpen={this.state.isMoveCaseDrawerOpen}
                    toggleDrawer={this.toggleMoveCaseDrawer}
                    sortColumn={sortColumn}
                    sortType={sortType}
                    handleSortColumn={this.handleSortColumn}
                    onClickSelectPalletMoveCase={this.onClickSelectPalletMoveCase}
                />

                <HistoryTypeModal
                    open={this.state.historyTypeModalOpen}
                    handleClose={this.handleModalClose}
                    data={this.props.InventoryLotHistoryTypeData}
                    loading={this.props.isInventoryLotHistoryTypeLoading}
                    handleSelect={this.handleModalSelect}
                />

                <EditCaseDrawer {...this.props} state={this.state} show={this.state.showEditCaseDrawer} onChangeEditCase={this.onChangeEditCase} confirm={this.onClickConfirmEditCase} close={this.closeEditCaseDrawer} />

            </div>
        );

    }
}

export default withRouter(CaseDetail);
