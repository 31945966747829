import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, ButtonGroup, Col, Form, List, Panel, Row, Steps } from 'rsuite';
import { checkForNotNull, findLowestMissingNumber, formatStringForAPI, formatTrueFalseToBit, removeDuplicates, replaceNullWithEmpty } from '../../../resources/logic/functions';
import AddPackageModal from './AddPackageModal';
import AddProductModal from './AddProductModal';
import StepAddress from './StepAddress';
import StepCarrier from './StepCarrier';
import StepLabel from './StepLabel';
import StepPackage from './StepPackage';

import { appAPI } from '../../../app/appConfig';

const styles = {
  marginBottom: '10px',
  width: '100%'
};


const initState = {
  //Step Navigation
  step: 0,

  //Warehouse & Customer Arrays
  warehouseArray: {},
  customerArray: {},
  carrierArray: {},
  carrierServiceArray: {},

  CarrierName: null,
  CarrierServiceName: null,

  //Ship Request Input
  LabelRequestID: null,
  CustomerID: null,
  WarehouseID: null,
  CarrierID: null,
  CarrierServiceID: null,
  ProdEnvironment: true,
  RequestDT: '',
  ResponseSuccess: '',
  Shipper_Name: '',
  Shipper_AttentionName: '',
  Shipper_CompanyDisplayableName: '',
  Shipper_TaxIdentificationNumber: '',
  Shipper_Phone_Number: '',
  Shipper_Phone_Extension: '',
  Shipper_ShipperNumber: '',
  Shipper_FaxNumber: '',
  Shipper_EMailAddress: '',
  Shipper_Address_AddressLine1: '',
  Shipper_Address_AddressLine2: '',
  Shipper_Address_AddressLine3: '',
  Shipper_Address_City: '',
  Shipper_Address_StateProvinceCode: '',
  Shipper_Address_PostalCode: '',
  Shipper_Address_CountryCode: '',
  ShipTo_Name: '',
  ShipTo_AttentionName: '',
  ShipTo_CompanyDisplayableName: '',
  ShipTo_TaxIdentificationNumber: '',
  ShipTo_Phone_Number: '',
  ShipTo_Phone_Extension: '',
  ShipTo_FaxNumber: '',
  ShipTo_EMailAddress: '',
  ShipTo_AddressLine1: '',
  ShipTo_AddressLine2: '',
  ShipTo_AddressLine3: '',
  ShipTo_Address_City: '',
  ShipTo_Address_StateProvinceCode: '',
  ShipTo_Address_PostalCode: '',
  ShipTo_Address_CountryCode: '',
  ShipFrom_Name: '',
  ShipFrom_AttentionName: '',
  ShipFrom_CompanyDisplayableName: '',
  ShipFrom_TaxIdentificationNumber: '',
  ShipFrom_Phone_Number: '',
  ShipFrom_Phone_Extension: '',
  ShipFrom_FaxNumber: '',
  ShipFrom_Address_AddressLine1: '',
  ShipFrom_Address_AddressLine2: '',
  ShipFrom_Address_AddressLine3: '',
  ShipFrom_Address_City: '',
  ShipFrom_Address_StateProvinceCode: '',
  ShipFrom_Address_PostalCode: '',
  ShipFrom_Address_CountryCode: '',

  ResidentialAddressIndicator: false,
  AllowEditShipperAddress: true,

  LabelPortID: null,

  UpsAccountInfoID: null,
  UpsAccountID: null,


  // User saved values
  LabelSpec_LabelImageFormat_Code: null,
  LabelSpec_LabelStockSize_Height: '',
  LabelSpec_LabelStockSize_Width: '',
  PrinterIP: '',
  PrinterPort: '',


  //Package Info
  PackageDict: [],

  //Product Info
  ProductDict: [],


  //Add new Package Modal
  addNewPackageModalShown: false,
  new_RequestPackageID: null,
  new_PackageIndex: null,
  new_Package_Description: '',
  new_Package_Packaging_Code: '02',
  new_Package_Dimensions_UOM_Code: '',
  new_Package_Dimensions_Length: '',
  new_Package_Dimensions_Width: '',
  new_Package_Dimensions_Height: '',
  new_Package_PackageWeight_UOM_Code: '',
  new_Package_PackageWeight_UOM_Description: '',
  new_Package_PackageWeight_Weight: '',
  new_Package_PkgSO_DeliveryConfirmation_DCISType: '',
  new_Package_PkgSO_DeclaredValue_CurrencyCode: 'USD',
  new_Package_PkgSO_DeclaredValue_MonetaryValue: '',

  //Add new Product Modal
  addNewProductModalShown: false,
  new_RequestPackageID: null,
  new_RequestProductInfoID: null,
  new_ProductPackageIndex: null,
  new_ProductInfoIndex: null,
  new_ProductCode: '',
  new_ProductDescription: '',
  new_ProductUnitValue: '',
  new_CurrencyCode: 'USD',
  new_ProductItemOrigin: '',
  new_ProductHarmonizedCode: '',
  new_ProductUnitWeight: '',
  new_ProductQuantity: '',
  new_ProductFabricContent: '',
  new_Hazmat: '',
  new_HazmatCode: '',
  new_FDAFlag: '',
  new_FDACode: '',

  //Edit Shipper Modal
  editShipperModalShown: false,

  //Edit ShipFrom Modal
  editShipFromModalShown: false,

  NewlyCreatedTracking: []

}


class LabelGeneration extends React.Component {
  constructor() {
    super();
    this.state = initState;

    //Warehouse and Customer
    this.loadWarehouseToState = this.loadWarehouseToState.bind(this);
    this.loadCustomerToState = this.loadCustomerToState.bind(this);

    //Step Navigation
    this.decline = this.decline.bind(this);
    this.increase = this.increase.bind(this);
    this.onClickStartNewLabel = this.onClickStartNewLabel.bind(this);

    //Input Functions
    this.handleChangeFunc = this.handleChangeFunc.bind(this);

    // Step 0 Carrier
    this.handleChangeCarrier = this.handleChangeCarrier.bind(this);
    this.onClickCarrierMethod = this.onClickCarrierMethod.bind(this);
    this.checkForRequiredCarrier = this.checkForRequiredCarrier.bind(this);

    //Step 1 Package
    this.onClickAddPackage = this.onClickAddPackage.bind(this);
    this.onClickEditPackage = this.onClickEditPackage.bind(this);
    this.onClickDeletePackage = this.onClickDeletePackage.bind(this);
    this.onClickAddProduct = this.onClickAddProduct.bind(this);
    this.onClickEditProduct = this.onClickEditProduct.bind(this);
    this.onClickDeleteProduct = this.onClickDeleteProduct.bind(this);
    this.onClickSaveAndAddPackage = this.onClickSaveAndAddPackage.bind(this);
    this.onClickSaveAndAddProduct = this.onClickSaveAndAddProduct.bind(this);
    this.handleSaveAddNewPackageModal = this.handleSaveAddNewPackageModal.bind(this);
    this.handleSaveAddNewProductModal = this.handleSaveAddNewProductModal.bind(this);
    this.handleCloseAddNewPackageModal = this.handleCloseAddNewPackageModal.bind(this);
    this.handleCloseAddNewProductModal = this.handleCloseAddNewProductModal.bind(this);
    this.checkForRequiredPackage = this.checkForRequiredPackage.bind(this);

    //Step 2 Address
    this.checkForRequiredShipToAddress = this.checkForRequiredShipToAddress.bind(this);
    this.checkIfProductsCreated = this.checkIfProductsCreated.bind(this);

    // Step 3 Label
    this.handleEnvToggleChange = this.handleEnvToggleChange.bind(this);
    this.onClickGenerateLabel = this.onClickGenerateLabel.bind(this);

    //Preload Values
    this.preloadUserSavedValues = this.preloadUserSavedValues.bind(this);

  }


  componentDidMount() {
    this.getsessionStorageData() //this will preserve menu selection
    this.loadWarehouseToState()
    this.loadCustomerToState()

    if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number' && typeof (this.state.CarrierID) === 'number' && typeof (this.state.CarrierServiceID) === 'number') {
      this.setState({
        step: 1
      })
    }

  }

  componentDidUpdate(prevProps, prevState) {

    if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
      if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.state.WarehouseID) !== 'number') {
        this.setState({
          WarehouseID: this.props.warehouseSelection,
          unsavedChanges: true
        })
      }
      if (typeof (this.state.CustomerID) === 'number' && typeof (this.state.WarehouseID) === 'number') {
      }
      else if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {
        this.props.labelGenRequestCustomerWarehouseDefaultValuesGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.customerSelection, this.props.warehouseSelection, null, this.state.LabelPortID)
      }
    }
    if (prevProps.customerSelection !== this.props.customerSelection) {
      if (typeof (this.props.customerSelection) === 'number' && typeof (this.state.CustomerID) !== 'number') {
        // this.props.labelGenPortRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.customerSelection)
        this.setState({
          CustomerID: this.props.customerSelection,
          unsavedChanges: true,
          LabelPortID: null,
        })
      }
      if (typeof (this.state.CustomerID) === 'number' && typeof (this.state.WarehouseID) === 'number') {
      }
      else if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {
        this.props.labelGenRequestCustomerWarehouseDefaultValuesGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.customerSelection, this.props.warehouseSelection, null, this.state.LabelPortID)
      }
    }
    if (prevState.CustomerID !== this.state.CustomerID && this.state.CustomerID !== null) {
      this.setState({
        LabelPortID: null,
      })

      // this.props.labelGenPortRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.state.CustomerID)
      if (typeof (this.state.CustomerID) === 'number' && typeof (this.state.WarehouseID) === 'number') {
        this.props.labelGenRequestCustomerWarehouseDefaultValuesGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.CustomerID, this.state.WarehouseID, null, this.state.LabelPortID)
      }
    }
    if (prevState.WarehouseID !== this.state.WarehouseID && this.state.WarehouseID !== null) {
      if (typeof (this.state.CustomerID) === 'number' && typeof (this.state.WarehouseID) === 'number') {
        this.props.labelGenRequestCustomerWarehouseDefaultValuesGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.CustomerID, this.state.WarehouseID, null, this.state.LabelPortID)
      }
    }


    if (prevState.LabelPortID !== this.state.LabelPortID && this.state.LabelPortID !== null) {
      // this.props.labelGenPortRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.state.CustomerID)
      if (typeof (this.state.LabelPortID) === 'number') {
        this.props.labelGenRequestCustomerWarehouseDefaultValuesGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.CustomerID, this.state.WarehouseID, null, this.state.LabelPortID)
      }
    }



    // if (prevProps.LabelGenRequestCustomerWarehouseDefaultValuesData !== this.props.LabelGenRequestCustomerWarehouseDefaultValuesData && this.props.LabelGenRequestCustomerWarehouseDefaultValuesData.length > 0) {
    //   this.preloadUserSavedValues()
    // }
    if (prevProps.LabelGenRequestCustomerWarehouseDefaultValuesData !== this.props.LabelGenRequestCustomerWarehouseDefaultValuesData ) {
      this.preloadUserSavedValues()
    }


    if (prevState.CarrierID !== this.state.CarrierID) {
      if (typeof (this.state.CarrierID) === 'number') {
        let CarrierArray = this.state.carrierArray.filter(x => x.value === this.state.CarrierID)
        var CarrierName = CarrierArray[0]['label']
      }
      else {
        var CarrierName = null
      }
      this.setState({
        CarrierName: CarrierName
      })
    }

    if (prevState.CarrierServiceID !== this.state.CarrierServiceID) {
      if (typeof (this.state.CarrierServiceID) === 'number') {
        let CarrierServiceArray = this.state.carrierServiceArray.filter(x => x.value === this.state.CarrierServiceID)
        var CarrierServiceName = CarrierServiceArray[0]['label']
      }
      else {
        var CarrierServiceName = null
      }
      this.setState({
        CarrierServiceName: CarrierServiceName
      })
    }



    if (prevState.new_Package_Dimensions_UOM_Code !== this.state.new_Package_Dimensions_UOM_Code && typeof (this.state.new_Package_Dimensions_UOM_Code) !== '') {
      if (this.state.new_Package_Dimensions_UOM_Code === 'IN' && this.state.new_Package_PackageWeight_UOM_Code !== 'LBS') {
        this.setState({
          new_Package_PackageWeight_UOM_Code: 'LBS'
        })
      }
      else if (this.state.new_Package_Dimensions_UOM_Code === 'CM' && this.state.new_Package_PackageWeight_UOM_Code !== 'KGS') {
        this.setState({
          new_Package_PackageWeight_UOM_Code: 'KGS'
        })
      }
    }

    if (prevState.new_Package_PackageWeight_UOM_Code !== this.state.new_Package_PackageWeight_UOM_Code && typeof (this.state.new_Package_PackageWeight_UOM_Code) !== '') {
      if (this.state.new_Package_PackageWeight_UOM_Code === 'LBS' && this.state.new_Package_Dimensions_UOM_Code !== 'IN') {
        this.setState({
          new_Package_Dimensions_UOM_Code: 'IN'
        })
      }
      else if (this.state.new_Package_PackageWeight_UOM_Code === 'KGS' && this.state.new_Package_Dimensions_UOM_Code !== 'CM') {
        this.setState({
          new_Package_Dimensions_UOM_Code: 'CM'
        })
      }
    }



    if (prevProps.LabelGenApiRequestSuccessful !== this.props.LabelGenApiRequestSuccessful && this.props.LabelGenApiRequestSuccessful) {
      try {
        try {
          var isMultiPackage = this.props.LabelGenApiRequestJson.multiPackage
        }
        catch (e) {
          var isMultiPackage = false
        }

        // Multi Package
        if (isMultiPackage === true) {

          var newTrackingNumArray = ''
          var newTrackingDiv
          for (let p in this.props.LabelGenApiRequestJson['multiLabelDict']) {

            var newTrackingNumber = this.props.LabelGenApiRequestJson['multiLabelDict'][p].TrackingNumber
            var labelUploadedFilename = this.props.LabelGenApiRequestJson['multiLabelDict'][p].labelUploadedFilename
            var LabelFormat = this.props.LabelGenApiRequestJson['multiLabelDict'][p].LabelFormat
            // var packageIndex = this.props.LabelGenApiRequestJson['multiLabelDict'][p].packageIndex

            var fileNameToDownload = newTrackingNumber + '.' + LabelFormat
            this.props.downloadFileFromS3(this.props.currentUserEmail, this.props.userSignInData.AuthToken, labelUploadedFilename, fileNameToDownload, appAPI.LabelGenDownloadS3, null)


            newTrackingNumArray += ' ' + newTrackingNumber.toString()


            newTrackingDiv += <a href='/somefile.txt' download>Click to download</a>


          }

          this.setState({ NewlyCreatedTracking: [...this.state.NewlyCreatedTracking, newTrackingNumArray] })

          // if (this.state.ProdEnvironment) {
          //   this.setState({
          //     // step: 2,
          //     ShipTo_Name: '',
          //     ShipTo_AttentionName: '',
          //     ShipTo_CompanyDisplayableName: '',
          //     ShipTo_TaxIdentificationNumber: '',
          //     ShipTo_Phone_Number: '',
          //     ShipTo_Phone_Extension: '',
          //     ShipTo_FaxNumber: '',
          //     ShipTo_EMailAddress: '',
          //     ShipTo_AddressLine1: '',
          //     ShipTo_AddressLine2: '',
          //     ShipTo_AddressLine3: '',
          //     ShipTo_Address_City: '',
          //     ShipTo_Address_StateProvinceCode: '',
          //     ShipTo_Address_PostalCode: '',
          //     ShipTo_Address_CountryCode: '',
          //   })
          // }

          window.alert('Downloading new labels...')
        }

        // Single package
        else {
          var fileNameToDownload = this.props.LabelGenApiRequestJson.ShipmentIdentificationNumber + '.' + this.props.LabelGenApiRequestJson.LabelFormat
          this.props.downloadFileFromS3(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.LabelGenApiRequestJson.labelUploadedFilename, fileNameToDownload, appAPI.LabelGenDownloadS3)

          window.alert('Label Downloading.  Tracking Number: ' + formatStringForAPI(this.props.LabelGenApiRequestJson["ShipmentIdentificationNumber"]))
          // if (this.state.ProdEnvironment) {
          //   this.setState({
          //     // step: 2,
          //     ShipTo_Name: '',
          //     ShipTo_AttentionName: '',
          //     ShipTo_CompanyDisplayableName: '',
          //     ShipTo_TaxIdentificationNumber: '',
          //     ShipTo_Phone_Number: '',
          //     ShipTo_Phone_Extension: '',
          //     ShipTo_FaxNumber: '',
          //     ShipTo_EMailAddress: '',
          //     ShipTo_AddressLine1: '',
          //     ShipTo_AddressLine2: '',
          //     ShipTo_AddressLine3: '',
          //     ShipTo_Address_City: '',
          //     ShipTo_Address_StateProvinceCode: '',
          //     ShipTo_Address_PostalCode: '',
          //     ShipTo_Address_CountryCode: '',
          //   })
          // }

          var newTrackingString = this.props.LabelGenApiRequestJson["ShipmentIdentificationNumber"]

          this.setState({ NewlyCreatedTracking: [...this.state.NewlyCreatedTracking, newTrackingString] })
        }


      }
      catch (e) {
        window.alert('Error Downloading Label.')
      }

    }



    // if (prevProps.LabelGenApiRequestSuccessful !== this.props.LabelGenApiRequestSuccessful && this.props.LabelGenApiRequestSuccessful) {
    //   window.alert('Tracking Number: ' + formatStringForAPI( this.props.LabelGenApiRequestJson["ShipmentIdentificationNumber"] ) )
    //   if( this.state.ProdEnvironment ){
    //     this.setState({
    //       step: 2,
    //       ShipTo_Name: '',
    //       ShipTo_AttentionName: '',
    //       ShipTo_CompanyDisplayableName: '',
    //       ShipTo_TaxIdentificationNumber: '',
    //       ShipTo_Phone_Number: '',
    //       ShipTo_Phone_Extension: '',
    //       ShipTo_FaxNumber: '',
    //       ShipTo_EMailAddress: '',
    //       ShipTo_AddressLine1: '',
    //       ShipTo_AddressLine2: '',
    //       ShipTo_AddressLine3: '',
    //       ShipTo_Address_City: '',
    //       ShipTo_Address_StateProvinceCode: '',
    //       ShipTo_Address_PostalCode: '',
    //       ShipTo_Address_CountryCode: '',
    //     })
    //   }
    // }



  }

  loadWarehouseToState() {
    if (typeof (this.props.warehouseSelection) === 'number') {
      this.setState({
        WarehouseID: this.props.warehouseSelection
      });
    }
  }

  loadCustomerToState() {
    if (typeof (this.props.customerSelection) === 'number') {
      this.setState({
        CustomerID: this.props.customerSelection
      });
    }
  }

  getsessionStorageData() {
    try {
      let warehouseMenuList = JSON.parse(sessionStorage.getItem('AppConfig.WarehouseArray'));
      if (warehouseMenuList != null && warehouseMenuList != undefined) {
        this.setState({
          warehouseArray: warehouseMenuList
        })
      }
    }
    catch (e) { }
    try {
      let customerMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CustomerArray'));
      if (customerMenuList != null && customerMenuList != undefined) {
        this.setState({
          customerArray: customerMenuList
        })
      }
    }
    catch (e) { }
    try {
      let carrierMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CarrierArray'));
      if (carrierMenuList != null && carrierMenuList != undefined) {
        this.setState({
          carrierArray: carrierMenuList
        })
      }
    }
    catch (e) { }
    try {
      let carrierServiceMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CarrierServiceArray'));
      if (carrierServiceMenuList != null && carrierServiceMenuList != undefined) {
        this.setState({
          carrierServiceArray: carrierServiceMenuList
        })
      }
    }
    catch (e) { }
  }


  //Step Navigation
  onClickStartNewLabel() {
    this.changePercent(0)

    this.setState({

      // CarrierName: null,
      // CarrierServiceName: null,

      //Ship Request Input
      LabelRequestID: null,
      // CustomerID: null,
      // WarehouseID: null,
      // CarrierID: null,
      // CarrierServiceID: null,
      ProdEnvironment: true,
      RequestDT: '',
      ResponseSuccess: '',
      Shipper_Name: '',
      Shipper_AttentionName: '',
      Shipper_CompanyDisplayableName: '',
      Shipper_TaxIdentificationNumber: '',
      Shipper_Phone_Number: '',
      Shipper_Phone_Extension: '',
      Shipper_ShipperNumber: '',
      Shipper_FaxNumber: '',
      Shipper_EMailAddress: '',
      Shipper_Address_AddressLine1: '',
      Shipper_Address_AddressLine2: '',
      Shipper_Address_AddressLine3: '',
      Shipper_Address_City: '',
      Shipper_Address_StateProvinceCode: '',
      Shipper_Address_PostalCode: '',
      Shipper_Address_CountryCode: '',
      ShipTo_Name: '',
      ShipTo_AttentionName: '',
      ShipTo_CompanyDisplayableName: '',
      ShipTo_TaxIdentificationNumber: '',
      ShipTo_Phone_Number: '',
      ShipTo_Phone_Extension: '',
      ShipTo_FaxNumber: '',
      ShipTo_EMailAddress: '',
      ShipTo_AddressLine1: '',
      ShipTo_AddressLine2: '',
      ShipTo_AddressLine3: '',
      ShipTo_Address_City: '',
      ShipTo_Address_StateProvinceCode: '',
      ShipTo_Address_PostalCode: '',
      ShipTo_Address_CountryCode: '',
      ShipFrom_Name: '',
      ShipFrom_AttentionName: '',
      ShipFrom_CompanyDisplayableName: '',
      ShipFrom_TaxIdentificationNumber: '',
      ShipFrom_Phone_Number: '',
      ShipFrom_Phone_Extension: '',
      ShipFrom_FaxNumber: '',
      ShipFrom_Address_AddressLine1: '',
      ShipFrom_Address_AddressLine2: '',
      ShipFrom_Address_AddressLine3: '',
      ShipFrom_Address_City: '',
      ShipFrom_Address_StateProvinceCode: '',
      ShipFrom_Address_PostalCode: '',
      ShipFrom_Address_CountryCode: '',

      ResidentialAddressIndicator: false,
      AllowEditShipperAddress: true,

      LabelPortID: null,

      UpsAccountInfoID: null,
      UpsAccountID: null,

      // User saved values
      LabelSpec_LabelImageFormat_Code: null,
      LabelSpec_LabelStockSize_Height: '',
      LabelSpec_LabelStockSize_Width: '',
      PrinterIP: '',
      PrinterPort: '',

      //Package Info
      PackageDict: [],

      //Product Info
      ProductDict: [],

      //Add new Package Modal
      addNewPackageModalShown: false,
      new_RequestPackageID: null,
      new_PackageIndex: null,
      new_Package_Description: '',
      new_Package_Packaging_Code: '02',
      new_Package_Dimensions_UOM_Code: '',
      new_Package_Dimensions_Length: '',
      new_Package_Dimensions_Width: '',
      new_Package_Dimensions_Height: '',
      new_Package_PackageWeight_UOM_Code: '',
      new_Package_PackageWeight_UOM_Description: '',
      new_Package_PackageWeight_Weight: '',
      new_Package_PkgSO_DeliveryConfirmation_DCISType: '',
      new_Package_PkgSO_DeclaredValue_CurrencyCode: 'USD',
      new_Package_PkgSO_DeclaredValue_MonetaryValue: '',

      //Add new Product Modal
      addNewProductModalShown: false,
      new_RequestPackageID: null,
      new_RequestProductInfoID: null,
      new_ProductPackageIndex: null,
      new_ProductInfoIndex: null,
      new_ProductCode: '',
      new_ProductDescription: '',
      new_ProductUnitValue: '',
      new_CurrencyCode: 'USD',
      new_ProductItemOrigin: '',
      new_ProductHarmonizedCode: '',
      new_ProductUnitWeight: '',
      new_ProductQuantity: '',
      new_ProductFabricContent: '',
      new_Hazmat: '',
      new_HazmatCode: '',
      new_FDAFlag: '',
      new_FDACode: '',

      //Edit Shipper Modal
      editShipperModalShown: false,

      //Edit ShipFrom Modal
      editShipFromModalShown: false,
    })

  }






  changePercent(nextstep) {
    const step = nextstep < 0 ? 0 : nextstep > 3 ? 3 : nextstep;
    this.setState({
      step
    });
  }
  decline() {
    this.changePercent(this.state.step - 1);
  }
  increase() {
    var currentStep = this.state.step
    var proceedToNextStep = true



    //Package Carrier complete:
    if (currentStep == 0) {
      if (this.checkForRequiredCarrier()) { }
      else { proceedToNextStep = false }
    }



    //Package Step complete:
    if (currentStep == 1) {
      if (this.state.PackageDict.length < 1) {
        if (this.checkForRequiredPackage()) {
          this.handleSaveAddNewPackageModal()
          window.alert('Please enter Product Data.') //added this to check if product not added
          proceedToNextStep = false//added this to check if product not added
        }
        else { proceedToNextStep = false }
      }
      else if (!this.checkIfProductsCreated()) {
        window.alert('Please enter Product Data for each Pacakge.')
        proceedToNextStep = false
      }

    }

    //Address Step complete:
    if (currentStep == 2) {
      if (this.checkForRequiredShipToAddress()) { }
      else { proceedToNextStep = false }
    }

    if (proceedToNextStep) {
      this.changePercent(this.state.step + 1);
    }
  }

  //Input Functions
  handleChangeFunc(e, statename) {
    this.setState({
      [statename]: e,
      unsavedChanges: true
    });

  }

  //Preload Values
  preloadUserSavedValues() {
    console.log("preload values")
    try {
      var defaultValues = this.props.LabelGenRequestCustomerWarehouseDefaultValuesData

      this.setState({
        unsavedChanges: false,
        DefaultValuesID: defaultValues[0].DefaultValuesID,
        CarrierID: defaultValues[0].CarrierID,
        CarrierServiceID: defaultValues[0].CarrierServiceID,
        Shipper_Name: defaultValues[0].Shipper_Name,
        Shipper_AttentionName: defaultValues[0].Shipper_AttentionName,
        Shipper_CompanyDisplayableName: defaultValues[0].Shipper_CompanyDisplayableName,
        Shipper_TaxIdentificationNumber: defaultValues[0].Shipper_TaxIdentificationNumber,
        Shipper_Phone_Number: defaultValues[0].Shipper_Phone_Number,
        Shipper_Phone_Extension: defaultValues[0].Shipper_Phone_Extension,
        Shipper_ShipperNumber: defaultValues[0].Shipper_ShipperNumber,
        Shipper_FaxNumber: defaultValues[0].Shipper_FaxNumber,
        Shipper_EMailAddress: defaultValues[0].Shipper_EMailAddress,
        Shipper_Address_AddressLine1: defaultValues[0].Shipper_Address_AddressLine1,
        Shipper_Address_AddressLine2: defaultValues[0].Shipper_Address_AddressLine2,
        Shipper_Address_AddressLine3: defaultValues[0].Shipper_Address_AddressLine3,
        Shipper_Address_City: defaultValues[0].Shipper_Address_City,
        Shipper_Address_StateProvinceCode: defaultValues[0].Shipper_Address_StateProvinceCode,
        Shipper_Address_PostalCode: defaultValues[0].Shipper_Address_PostalCode,
        Shipper_Address_CountryCode: defaultValues[0].Shipper_Address_CountryCode,
        ShipFrom_Name: defaultValues[0].ShipFrom_Name,
        ShipFrom_AttentionName: defaultValues[0].ShipFrom_AttentionName,
        ShipFrom_CompanyDisplayableName: defaultValues[0].ShipFrom_CompanyDisplayableName,
        ShipFrom_TaxIdentificationNumber: defaultValues[0].ShipFrom_TaxIdentificationNumber,
        ShipFrom_Phone_Number: defaultValues[0].ShipFrom_Phone_Number,
        ShipFrom_Phone_Extension: defaultValues[0].ShipFrom_Phone_Extension,
        ShipFrom_FaxNumber: defaultValues[0].ShipFrom_FaxNumber,
        ShipFrom_Address_AddressLine1: defaultValues[0].ShipFrom_Address_AddressLine1,
        ShipFrom_Address_AddressLine2: defaultValues[0].ShipFrom_Address_AddressLine2,
        ShipFrom_Address_AddressLine3: defaultValues[0].ShipFrom_Address_AddressLine3,
        ShipFrom_Address_City: defaultValues[0].ShipFrom_Address_City,
        ShipFrom_Address_StateProvinceCode: defaultValues[0].ShipFrom_Address_StateProvinceCode,
        ShipFrom_Address_PostalCode: defaultValues[0].ShipFrom_Address_PostalCode,
        ShipFrom_Address_CountryCode: defaultValues[0].ShipFrom_Address_CountryCode,
        // ProdEnvironment: defaultValues[0].ProdEnvironment,
        LabelSpec_LabelImageFormat_Code: defaultValues[0].LabelSpec_LabelImageFormat_Code,
        LabelSpec_LabelStockSize_Height: defaultValues[0].LabelSpec_LabelStockSize_Height,
        LabelSpec_LabelStockSize_Width: defaultValues[0].LabelSpec_LabelStockSize_Width,


        new_Package_Dimensions_UOM_Code: defaultValues[0].Package_Dimensions_UOM_Code,
        new_Package_PackageWeight_UOM_Code: defaultValues[0].Package_PackageWeight_UOM_Code,

        new_ProductItemOrigin: defaultValues[0].ProductItemOrigin,

        ShipTo_Address_CountryCode: defaultValues[0].ShipTo_Address_CountryCode,

        ResidentialAddressIndicator: defaultValues[0].ResidentialAddressIndicator,
        AllowEditShipperAddress: defaultValues[0].AllowEditShipperAddress,


        UpsAccountInfoID: defaultValues[0].UpsAccountInfoID,
        UpsAccountID: defaultValues[0].UpsAccountID,

        // LabelPortID: defaultValues[0].LabelPortID,
        // PrinterIP: defaultValues[0].PrinterIP,
        // PrinterPort: defaultValues[0].PrinterPort,


        // envToggleValue: (defaultValues[0].ProdEnvironment === true ? 'Production' : 'Test')

      });
      this.forceUpdate()


    }
    catch (e) {
      this.onClickStartNewLabel()
      window.alert('Label Generation not configured for this Customer/Warhouse.')
    }
  }






  //Step 0 Carrier
  handleChangeCarrier(e) {
    this.setState({
      CarrierID: e,
      CarrierServiceID: null,
      unsavedChanges: true
    });
  }
  onClickCarrierMethod(name, value) {
    this.setState({
      carrierMethod: value,
      unsavedChanges: true
    });
  }
  checkForRequiredCarrier() {
    if (
      checkForNotNull(this.state.CustomerID) &&
      checkForNotNull(this.state.WarehouseID) &&
      checkForNotNull(this.state.CarrierID) &&
      checkForNotNull(this.state.CarrierServiceID)
    ) {

      var filteredPortList = []
      filteredPortList = this.props.LabelGenPortRefData.filter(x => x.CustomerID === this.state.CustomerID)

      if (filteredPortList.length > 0) {
        if (checkForNotNull(this.state.LabelPortID)) {
          return true
        }
        else {
          window.alert('Please enter all required Carrier info.')
          return false
        }

      }

      return true
    }
    else {
      window.alert('Please enter all required Carrier info.')
      return false
    }
  }



  //Step 1 Package
  onClickAddPackage(e) {
    try {
      var defaultPackage_Dimensions_UOM_Code = this.props.LabelGenRequestCustomerWarehouseDefaultValuesData[0].Package_Dimensions_UOM_Code
    }
    catch (e) {
      var defaultPackage_Dimensions_UOM_Code = ''
    }
    try {
      var defaultPackage_PackageWeight_UOM_Code = this.props.LabelGenRequestCustomerWarehouseDefaultValuesData[0].Package_PackageWeight_UOM_Code
    }
    catch (e) {
      var defaultPackage_PackageWeight_UOM_Code = ''
    }

    this.setState({
      addNewPackageModalShown: true,
      new_PackageIndex: null,
      new_RequestPackageID: null,
      // new_LabelRequestID: null,
      new_Package_Description: '',
      new_Package_Packaging_Code: '02',
      new_Package_Dimensions_UOM_Code: defaultPackage_Dimensions_UOM_Code,
      new_Package_Dimensions_Length: '',
      new_Package_Dimensions_Width: '',
      new_Package_Dimensions_Height: '',
      new_Package_PackageWeight_UOM_Code: defaultPackage_PackageWeight_UOM_Code,
      new_Package_PackageWeight_UOM_Description: '',
      new_Package_PackageWeight_Weight: '',
      new_Package_PkgSO_DeliveryConfirmation_DCISType: '',
      new_Package_PkgSO_DeclaredValue_CurrencyCode: 'USD',
      new_Package_PkgSO_DeclaredValue_MonetaryValue: ''
    });
    this.forceUpdate()
  }

  onClickEditPackage(e, item) {
    this.setState({
      addNewPackageModalShown: true,
      new_PackageIndex: item.PackageIndex,
      new_RequestPackageID: item.RequestPackageID,
      new_Package_Description: item.Package_Description,
      new_Package_Packaging_Code: item.Package_Packaging_Code,
      new_Package_Dimensions_UOM_Code: item.Package_Dimensions_UOM_Code,
      new_Package_Dimensions_Length: item.Package_Dimensions_Length,
      new_Package_Dimensions_Width: item.Package_Dimensions_Width,
      new_Package_Dimensions_Height: item.Package_Dimensions_Height,
      new_Package_PackageWeight_UOM_Code: item.Package_PackageWeight_UOM_Code,
      new_Package_PackageWeight_UOM_Description: item.Package_PackageWeight_UOM_Description,
      new_Package_PackageWeight_Weight: item.Package_PackageWeight_Weight,
      new_Package_PkgSO_DeliveryConfirmation_DCISType: item.Package_PkgSO_DeliveryConfirmation_DCISType,
      new_Package_PkgSO_DeclaredValue_CurrencyCode: item.Package_PkgSO_DeclaredValue_CurrencyCode,
      new_Package_PkgSO_DeclaredValue_MonetaryValue: item.Package_PkgSO_DeclaredValue_MonetaryValue
    });
    this.forceUpdate()
  }

  handleCloseAddNewPackageModal() {
    this.setState({
      addNewPackageModalShown: false,
      new_PackageIndex: null,
      new_RequestPackageID: null,
      // new_LabelRequestID: null,
      new_Package_Description: '',
      new_Package_Packaging_Code: '02',
      new_Package_Dimensions_UOM_Code: '',
      new_Package_Dimensions_Length: '',
      new_Package_Dimensions_Width: '',
      new_Package_Dimensions_Height: '',
      new_Package_PackageWeight_UOM_Code: '',
      new_Package_PackageWeight_UOM_Description: '',
      new_Package_PackageWeight_Weight: '',
      new_Package_PkgSO_DeliveryConfirmation_DCISType: '',
      new_Package_PkgSO_DeclaredValue_CurrencyCode: 'USD',
      new_Package_PkgSO_DeclaredValue_MonetaryValue: ''
    });
  }

  checkForRequiredPackage() {
    if (
      checkForNotNull(this.state.new_Package_Packaging_Code) &&
      checkForNotNull(this.state.new_Package_Dimensions_UOM_Code) &&
      checkForNotNull(this.state.new_Package_Dimensions_Length) &&
      checkForNotNull(this.state.new_Package_Dimensions_Width) &&
      checkForNotNull(this.state.new_Package_Dimensions_Height) &&
      checkForNotNull(this.state.new_Package_PackageWeight_UOM_Code) &&
      checkForNotNull(this.state.new_Package_PackageWeight_Weight)
    ) {
      // check max weight, max dimensions
      if (this.state.new_Package_Dimensions_UOM_Code === 'IN') {
        if (this.state.new_Package_Dimensions_Length < 99 &&
          this.state.new_Package_Dimensions_Width < 99 &&
          this.state.new_Package_Dimensions_Height < 99 &&
          this.state.new_Package_PackageWeight_Weight < 149
        ) {
          return true
        }
        else {
          window.alert('Package exceeds dimenions or weight limit.')
          return false
        }
      }
      else {
        if (this.state.new_Package_Dimensions_Length < 251 &&
          this.state.new_Package_Dimensions_Width < 251 &&
          this.state.new_Package_Dimensions_Height < 251 &&
          this.state.new_Package_PackageWeight_Weight < 67
        ) {
          return true
        }
        else {
          window.alert('Package exceeds dimenions or weight limit.')
          return false
        }
      }

    }
    else {
      window.alert('Please enter all required Package data.')
      return false
    }
  }

  checkIfProductsCreated() {

    const productsIdArray = this.state.ProductDict.map(item => item.ProductPackageIndex);
    const uniqueProducts = removeDuplicates(productsIdArray)

    const packageIdArray = this.state.PackageDict.map(item => item.PackageIndex);
    // const uniquePackage = uniqueBy(this.state.PackageDict, 'PackageIndex')
    const uniquePackage = removeDuplicates(packageIdArray)

    if (uniqueProducts.sort().join(',') === uniquePackage.sort().join(',')) {
      return true
    }
    else {
      return false
    }

  }

  onClickSaveAndAddPackage() {
    var { funcSuccess, packageIndex } = this.handleSaveAddNewPackageModal()
    if (funcSuccess) {
      this.onClickAddPackage()
    }
  }



  onClickSaveAndAddProduct() {
    var { funcSuccess, packageIndex } = this.handleSaveAddNewPackageModal()
    if (funcSuccess) {
      this.onClickAddProduct(null, packageIndex)
    }
  }

  handleSaveAddNewPackageModal() {

    var packageIndex = null
    var funcSuccess = true
    if (this.checkForRequiredPackage()) {
      if (this.state.new_PackageIndex === null) {
        var PackageInfoDictUpdated = this.state.PackageDict.slice(); // Create a copy
        var allIndexs = PackageInfoDictUpdated.map(item => item.PackageIndex);
        var nextIndex = findLowestMissingNumber(allIndexs)
        packageIndex = nextIndex
        var newPackage = this.state.PackageDict.slice()
        newPackage.push(
          {
            'RequestPackageID': this.state.new_RequestPackageID,
            'PackageIndex': nextIndex,
            'Package_Description': this.state.new_Package_Description,
            'Package_Packaging_Code': this.state.new_Package_Packaging_Code,
            'Package_Dimensions_UOM_Code': this.state.new_Package_Dimensions_UOM_Code,
            'Package_Dimensions_Length': this.state.new_Package_Dimensions_Length,
            'Package_Dimensions_Width': this.state.new_Package_Dimensions_Width,
            'Package_Dimensions_Height': this.state.new_Package_Dimensions_Height,
            'Package_PackageWeight_UOM_Code': this.state.new_Package_PackageWeight_UOM_Code,
            'Package_PackageWeight_UOM_Description': this.state.new_Package_PackageWeight_UOM_Description,
            'Package_PackageWeight_Weight': this.state.new_Package_PackageWeight_Weight,
            'Package_PkgSO_DeliveryConfirmation_DCISType': this.state.new_Package_PkgSO_DeliveryConfirmation_DCISType,
            'Package_PkgSO_DeclaredValue_CurrencyCode': this.state.new_Package_PkgSO_DeclaredValue_CurrencyCode,
            'Package_PkgSO_DeclaredValue_MonetaryValue': this.state.new_Package_PkgSO_DeclaredValue_MonetaryValue
          }
        )
        this.setState({
          PackageDict: newPackage
        });
        this.forceUpdate()
      }
      else {
        var packageDictIndex = this.state.PackageDict.findIndex(item => item.PackageIndex === this.state.new_PackageIndex)
        var packageToEdit = this.state.PackageDict

        packageIndex = this.state.new_PackageIndex

        packageToEdit[packageDictIndex]['RequestPackageID'] = this.state.new_RequestPackageID
        // packageToEdit[packageDictIndex]['PackageIndex'] = nextIndex
        packageToEdit[packageDictIndex]['Package_Description'] = this.state.new_Package_Description
        packageToEdit[packageDictIndex]['Package_Packaging_Code'] = this.state.new_Package_Packaging_Code
        packageToEdit[packageDictIndex]['Package_Dimensions_UOM_Code'] = this.state.new_Package_Dimensions_UOM_Code
        packageToEdit[packageDictIndex]['Package_Dimensions_Length'] = this.state.new_Package_Dimensions_Length
        packageToEdit[packageDictIndex]['Package_Dimensions_Width'] = this.state.new_Package_Dimensions_Width
        packageToEdit[packageDictIndex]['Package_Dimensions_Height'] = this.state.new_Package_Dimensions_Height
        packageToEdit[packageDictIndex]['Package_PackageWeight_UOM_Code'] = this.state.new_Package_PackageWeight_UOM_Code
        packageToEdit[packageDictIndex]['Package_PackageWeight_UOM_Description'] = this.state.new_Package_PackageWeight_UOM_Description
        packageToEdit[packageDictIndex]['Package_PackageWeight_Weight'] = this.state.new_Package_PackageWeight_Weight
        packageToEdit[packageDictIndex]['Package_PkgSO_DeliveryConfirmation_DCISType'] = this.state.new_Package_PkgSO_DeliveryConfirmation_DCISType
        packageToEdit[packageDictIndex]['Package_PkgSO_DeclaredValue_CurrencyCode'] = this.state.new_Package_PkgSO_DeclaredValue_CurrencyCode
        packageToEdit[packageDictIndex]['Package_PkgSO_DeclaredValue_MonetaryValue'] = this.state.new_Package_PkgSO_DeclaredValue_MonetaryValue
        this.setState({
          PackageDict: packageToEdit
        });
      }

      this.handleCloseAddNewPackageModal()
      funcSuccess = true
      return { funcSuccess, packageIndex };
    }
    else {
      funcSuccess = false
      return { funcSuccess, packageIndex };
    }
  }

  onClickDeletePackage(e, packageItem) {

    if (this.state.PackageDict.length < 2) {
      window.alert('Cannot delete all Packages')
    }
    else {
      if (window.confirm('Delete Package?')) {
        var prevPackage = {}
        prevPackage = this.state.PackageDict
        var packID = packageItem.PackageIndex;
        var packIndex = this.state.PackageDict.findIndex(({ PackageIndex }) => PackageIndex === packID);
        prevPackage.splice(packIndex, 1);


        const { ProductDict } = this.state;
        // Step 1: Find the indexes of objects that match the criteria using .map() and .filter()
        const indexesToDelete = ProductDict
          .map((item, index) => (item.ProductPackageIndex === packID ? index : -1))
          .filter(index => index !== -1);
        // Step 2: Delete the objects with the found indexes using .filter()
        const updatedItems = ProductDict.filter((_, index) => !indexesToDelete.includes(index));
        // Step 3: Update the state with the new array
        this.setState({
          PackageDict: prevPackage,
          ProductDict: updatedItems
        });
      };
    }

  }

  //Add new Product Modal
  onClickAddProduct(e, packageID) {
    try {
      var defaultProductItemOrigin = this.props.LabelGenRequestCustomerWarehouseDefaultValuesData[0].ProductItemOrigin
    }
    catch (e) {
      var defaultProductItemOrigin = ''
    }

    this.setState({
      addNewProductModalShown: true,
      new_RequestPackageID: null,
      new_RequestProductInfoID: null,
      new_ProductPackageIndex: packageID,
      // new_LabelRequestID: null,
      new_ProductInfoIndex: null,
      new_ProductCode: '',
      new_ProductDescription: '',
      new_ProductUnitValue: '',
      new_CurrencyCode: 'USD',
      new_ProductItemOrigin: defaultProductItemOrigin,
      new_ProductHarmonizedCode: '',
      new_ProductUnitWeight: '',
      new_ProductQuantity: '',
      new_ProductFabricContent: '',
      new_Hazmat: '',
      new_HazmatCode: '',
      new_FDAFlag: '',
      new_FDACode: '',
    });
    this.forceUpdate()
  }




  onClickEditProduct(e, item) {
    this.setState({
      addNewProductModalShown: true,
      new_RequestPackageID: item.RequestPackageID,
      new_RequestProductInfoID: item.RequestProductInfoID,
      new_ProductPackageIndex: item.ProductPackageIndex,
      new_ProductInfoIndex: item.ProductInfoIndex,
      new_ProductCode: item.ProductCode,
      new_ProductDescription: item.ProductDescription,
      new_ProductUnitValue: item.ProductUnitValue,
      new_CurrencyCode: item.CurrencyCode,
      new_ProductItemOrigin: item.ProductItemOrigin,
      new_ProductHarmonizedCode: item.ProductHarmonizedCode,
      new_ProductUnitWeight: item.ProductUnitWeight,
      new_ProductQuantity: item.ProductQuantity,
      new_ProductFabricContent: item.ProductFabricContent,
      new_Hazmat: item.Hazmat,
      new_HazmatCode: item.HazmatCode,
      new_FDAFlag: item.FDAFlag,
      new_FDACode: item.FDACode,
    });
    this.forceUpdate()
  }


  handleCloseAddNewProductModal() {
    this.setState({
      addNewProductModalShown: false,
      new_RequestPackageID: null,
      new_RequestProductInfoID: null,
      new_ProductPackageIndex: null,
      // new_LabelRequestID: null,
      new_ProductInfoIndex: null,
      new_ProductCode: '',
      new_ProductDescription: '',
      new_ProductUnitValue: '',
      new_CurrencyCode: 'USD',
      new_ProductItemOrigin: '',
      new_ProductHarmonizedCode: '',
      new_ProductUnitWeight: '',
      new_ProductQuantity: '',
      new_ProductFabricContent: '',
      new_Hazmat: '',
      new_HazmatCode: '',
      new_FDAFlag: '',
      new_FDACode: '',

    });
  }


  checkForRequiredProduct() {
    if (
      checkForNotNull(this.state.new_ProductCode) &&
      checkForNotNull(this.state.new_ProductDescription) &&
      checkForNotNull(this.state.new_ProductUnitValue) &&
      checkForNotNull(this.state.new_CurrencyCode) &&
      checkForNotNull(this.state.new_ProductItemOrigin) &&
      checkForNotNull(this.state.new_ProductHarmonizedCode) &&
      checkForNotNull(this.state.new_ProductUnitWeight) &&
      checkForNotNull(this.state.new_ProductQuantity)
    ) {

      if (this.state.new_ProductHarmonizedCode.length === 10) {
        return true
      }
      else {
        window.alert('Harmonized Code must be 10 digits.')
        return false
      }
    }
    else {
      window.alert('Please enter all required Product data.')
      return false
    }
  }



  handleSaveAddNewProductModal() {


    if (this.checkForRequiredProduct()) {

      if (this.state.new_ProductInfoIndex === null) {
        var ProductInfoDictUpdated = this.state.ProductDict.slice(); // Create a copy
        var allIndexs = ProductInfoDictUpdated.map(item => item.ProductInfoIndex);
        var nextIndex = findLowestMissingNumber(allIndexs)
        var newProduct = this.state.ProductDict.slice()
        newProduct.push(
          {

            'RequestProductInfoID': this.state.new_RequestProductInfoID,
            // 'LabelRequestID': this.state.new_LabelRequestID,
            'ProductPackageIndex': this.state.new_ProductPackageIndex,
            'ProductInfoIndex': nextIndex,
            'ProductCode': this.state.new_ProductCode,
            'ProductDescription': this.state.new_ProductDescription,
            'ProductUnitValue': this.state.new_ProductUnitValue,
            'CurrencyCode': this.state.new_CurrencyCode,
            'ProductItemOrigin': this.state.new_ProductItemOrigin,
            'ProductHarmonizedCode': this.state.new_ProductHarmonizedCode,
            'ProductUnitWeight': this.state.new_ProductUnitWeight,
            'ProductQuantity': this.state.new_ProductQuantity,
            'ProductFabricContent': this.state.new_ProductFabricContent,
            'Hazmat': this.state.new_Hazmat,
            'HazmatCode': this.state.new_HazmatCode,
            'FDAFlag': this.state.new_FDAFlag,
            'FDACode': this.state.new_FDACode
          }
        )
        this.setState({
          ProductDict: newProduct
        });
        this.forceUpdate()
      }
      else {
        var productDictIndex = this.state.ProductDict.findIndex(item => item.ProductInfoIndex === this.state.new_ProductInfoIndex)
        var productToEdit = this.state.ProductDict

        productToEdit[productDictIndex]['RequestProductInfoID'] = this.state.new_RequestProductInfoID
        productToEdit[productDictIndex]['ProductPackageIndex'] = this.state.new_ProductPackageIndex
        productToEdit[productDictIndex]['ProductInfoIndex'] = this.state.new_ProductInfoIndex
        productToEdit[productDictIndex]['ProductCode'] = this.state.new_ProductCode
        productToEdit[productDictIndex]['ProductDescription'] = this.state.new_ProductDescription
        productToEdit[productDictIndex]['ProductUnitValue'] = this.state.new_ProductUnitValue
        productToEdit[productDictIndex]['CurrencyCode'] = this.state.new_CurrencyCode
        productToEdit[productDictIndex]['ProductItemOrigin'] = this.state.new_ProductItemOrigin
        productToEdit[productDictIndex]['ProductHarmonizedCode'] = this.state.new_ProductHarmonizedCode
        productToEdit[productDictIndex]['ProductUnitWeight'] = this.state.new_ProductUnitWeight
        productToEdit[productDictIndex]['ProductQuantity'] = this.state.new_ProductQuantity
        productToEdit[productDictIndex]['ProductFabricContent'] = this.state.new_ProductFabricContent
        productToEdit[productDictIndex]['Hazmat'] = this.state.new_Hazmat
        productToEdit[productDictIndex]['HazmatCode'] = this.state.new_HazmatCode
        productToEdit[productDictIndex]['FDAFlag'] = this.state.new_FDAFlag
        productToEdit[productDictIndex]['FDACode'] = this.state.new_FDACode
        this.setState({
          ProductDict: productToEdit
        });
      }
      this.handleCloseAddNewProductModal()

    }

  }

  onClickDeleteProduct(e, productItem) {
    if (window.confirm('Delete Product?')) {
      var prevProduct = {}
      prevProduct = this.state.ProductDict
      var prodID = productItem.ProductInfoIndex;
      var prodIndex = this.state.ProductDict.findIndex(({ ProductInfoIndex }) => ProductInfoIndex === prodID);
      prevProduct.splice(prodIndex, 1);
      setTimeout(() => {
        this.setState({
          ProductDict: prevProduct
        });
      }, 50);
      this.forceUpdate()
    }
  }




  //Step 2 Address
  checkForRequiredShipToAddress() {
    if (
      checkForNotNull(this.state.ShipTo_Name) &&
      checkForNotNull(this.state.ShipTo_AddressLine1) &&
      checkForNotNull(this.state.ShipTo_Address_City) &&
      checkForNotNull(this.state.ShipTo_Address_StateProvinceCode) &&
      checkForNotNull(this.state.ShipTo_Address_PostalCode) &&
      checkForNotNull(this.state.ShipTo_Address_CountryCode) &&
      checkForNotNull(this.state.ShipTo_Phone_Number) &&

      checkForNotNull(this.state.Shipper_Name) &&
      checkForNotNull(this.state.Shipper_Phone_Number) &&
      checkForNotNull(this.state.Shipper_Address_AddressLine1) &&
      checkForNotNull(this.state.Shipper_Address_City) &&
      checkForNotNull(this.state.Shipper_Address_StateProvinceCode) &&
      checkForNotNull(this.state.Shipper_Address_PostalCode) &&
      checkForNotNull(this.state.Shipper_Address_CountryCode) &&

      checkForNotNull(this.state.ShipFrom_Name) &&
      checkForNotNull(this.state.ShipFrom_Address_AddressLine1) &&
      checkForNotNull(this.state.ShipFrom_Address_City) &&
      checkForNotNull(this.state.ShipFrom_Address_StateProvinceCode) &&
      checkForNotNull(this.state.ShipFrom_Address_PostalCode) &&
      checkForNotNull(this.state.ShipFrom_Address_CountryCode)
    ) {
      return true
    }
    else {
      window.alert('Please enter all required info.')
      return false
    }
  }



  // Step 3 Label
  handleEnvToggleChange(statevalue) {
    if (statevalue == true) {
      this.setState({
        envToggleValue: 'Production',
        ProdEnvironment: statevalue,
        unsavedChanges: true
      });
    }
    else if (statevalue == false) {
      this.setState({
        envToggleValue: 'Test',
        ProdEnvironment: statevalue,
        unsavedChanges: true
      });
    }
  }

  onClickGenerateLabel(e) {

    if (
      checkForNotNull(this.state.LabelSpec_LabelImageFormat_Code)
    ) {




      var labelPortCode = null
      try {
        var labelPortCodeArray = this.props.LabelGenPortRefData.filter(x => x.LabelPortID === this.state.LabelPortID)
        labelPortCode = labelPortCodeArray[0].PortCode
      }
      catch (e) { }

      var RequestDict = []

      RequestDict[0] = {
        'LabelRequestID': this.state.LabelRequestID,
        'CustomerID': this.state.CustomerID,
        'WarehouseID': this.state.WarehouseID,
        'CarrierID': this.state.CarrierID,
        'CarrierName': this.state.CarrierName,
        'ProdEnvironment': formatTrueFalseToBit(this.state.ProdEnvironment),
        'RequestDT': this.state.RequestDT,
        'ResponseSuccess': this.state.ResponseSuccess,
        'Shipper_Name': this.state.Shipper_Name,
        'Shipper_AttentionName': this.state.Shipper_AttentionName,
        'Shipper_CompanyDisplayableName': this.state.Shipper_CompanyDisplayableName,
        'Shipper_TaxIdentificationNumber': this.state.Shipper_TaxIdentificationNumber,
        'Shipper_Phone_Number': this.state.Shipper_Phone_Number,
        'Shipper_Phone_Extension': this.state.Shipper_Phone_Extension,
        'Shipper_ShipperNumber': this.state.Shipper_ShipperNumber,
        'Shipper_FaxNumber': this.state.Shipper_FaxNumber,
        'Shipper_EMailAddress': this.state.Shipper_EMailAddress,
        'Shipper_Address_AddressLine1': this.state.Shipper_Address_AddressLine1,
        'Shipper_Address_AddressLine2': this.state.Shipper_Address_AddressLine2,
        'Shipper_Address_AddressLine3': this.state.Shipper_Address_AddressLine3,
        'Shipper_Address_City': this.state.Shipper_Address_City,
        'Shipper_Address_StateProvinceCode': this.state.Shipper_Address_StateProvinceCode,
        'Shipper_Address_PostalCode': this.state.Shipper_Address_PostalCode,
        'Shipper_Address_CountryCode': this.state.Shipper_Address_CountryCode,
        'ShipTo_Name': this.state.ShipTo_Name,
        'ShipTo_AttentionName': this.state.ShipTo_AttentionName,
        'ShipTo_CompanyDisplayableName': this.state.ShipTo_CompanyDisplayableName,
        'ShipTo_TaxIdentificationNumber': this.state.ShipTo_TaxIdentificationNumber,
        'ShipTo_Phone_Number': this.state.ShipTo_Phone_Number,
        'ShipTo_Phone_Extension': this.state.ShipTo_Phone_Extension,
        'ShipTo_FaxNumber': this.state.ShipTo_FaxNumber,
        'ShipTo_EMailAddress': this.state.ShipTo_EMailAddress,
        'ShipTo_AddressLine1': this.state.ShipTo_AddressLine1,
        'ShipTo_AddressLine2': this.state.ShipTo_AddressLine2,
        'ShipTo_AddressLine3': this.state.ShipTo_AddressLine3,
        'ShipTo_Address_City': this.state.ShipTo_Address_City,
        'ShipTo_Address_StateProvinceCode': this.state.ShipTo_Address_StateProvinceCode,
        'ShipTo_Address_PostalCode': this.state.ShipTo_Address_PostalCode,
        'ShipTo_Address_CountryCode': this.state.ShipTo_Address_CountryCode,
        'ShipFrom_Name': this.state.ShipFrom_Name,
        'ShipFrom_AttentionName': this.state.ShipFrom_AttentionName,
        'ShipFrom_CompanyDisplayableName': this.state.ShipFrom_CompanyDisplayableName,
        'ShipFrom_TaxIdentificationNumber': this.state.ShipFrom_TaxIdentificationNumber,
        'ShipFrom_Phone_Number': this.state.ShipFrom_Phone_Number,
        'ShipFrom_Phone_Extension': this.state.ShipFrom_Phone_Extension,
        'ShipFrom_FaxNumber': this.state.ShipFrom_FaxNumber,
        'ShipFrom_Address_AddressLine1': this.state.ShipFrom_Address_AddressLine1,
        'ShipFrom_Address_AddressLine2': this.state.ShipFrom_Address_AddressLine2,
        'ShipFrom_Address_AddressLine3': this.state.ShipFrom_Address_AddressLine3,
        'ShipFrom_Address_City': this.state.ShipFrom_Address_City,
        'ShipFrom_Address_StateProvinceCode': this.state.ShipFrom_Address_StateProvinceCode,
        'ShipFrom_Address_PostalCode': this.state.ShipFrom_Address_PostalCode,
        'ShipFrom_Address_CountryCode': this.state.ShipFrom_Address_CountryCode,
        'CarrierServiceID': this.state.CarrierServiceID,
        'CarrierServiceName': this.state.CarrierServiceName,

        'LabelSpec_LabelImageFormat_Code': this.state.LabelSpec_LabelImageFormat_Code,
        'LabelSpec_LabelStockSize_Height': this.state.LabelSpec_LabelStockSize_Height,
        'LabelSpec_LabelStockSize_Width': this.state.LabelSpec_LabelStockSize_Width,

        'LabelPortID': this.state.LabelPortID,
        'LabelPortCode': labelPortCode,

        'ResidentialAddressIndicator': formatTrueFalseToBit(this.state.ResidentialAddressIndicator),

        'UpsAccountInfoID': this.state.UpsAccountInfoID,
        'UpsAccountID': this.state.UpsAccountID,
      }


      var param = [
        {
          'currentUserEmail': this.props.currentUserEmail,
          'AuthToken': this.props.userSignInData.AuthToken,
          // 'PackageIndex': this.state.LabelRequestID,
          'RequestData': RequestDict,
          'PackageData': this.state.PackageDict,
          'ProductData': this.state.ProductDict
        }
      ]

      this.props.labelGenApiRequestMainGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, param)

    }
    else {
      window.alert('Please select label format.')
    }
  }




  render() {
    const { step } = this.state;
    let step0Carrier;
    if (typeof (this.state.carrierServiceArray) !== 'undefined' && this.state.carrierServiceArray != null && this.state.carrierServiceArray.length > 0) {
      step0Carrier = <StepCarrier {...this.props} state={this.state}
        handleChangeFunc={this.handleChangeFunc}
        onClickCarrierMethod={this.onClickCarrierMethod}
        handleChangeCarrier={this.handleChangeCarrier} />
    }
    else {
      step0Carrier = <div></div>
    }



    let CustomerName;
    let WarehouseName;
    if (typeof (this.state.CustomerID) === 'number') {
      let CustomerArray = this.state.customerArray.filter(x => x.value === this.state.CustomerID)
      CustomerName = 'Customer: ' + CustomerArray[0]['label']
    }
    else {
      CustomerName = null
    }

    if (typeof (this.state.WarehouseID) === 'number') {
      let WarehouseArray = this.state.warehouseArray.filter(x => x.value === this.state.WarehouseID)
      WarehouseName = 'Warehouse: ' + WarehouseArray[0]['label']
    }
    else {
      WarehouseName = null
    }

    let Carrier;
    if (checkForNotNull(this.state.CarrierName)) {
      Carrier = 'Carrier: ' + replaceNullWithEmpty(this.state.CarrierName) + ' ' + replaceNullWithEmpty(this.state.CarrierServiceName)
    }


    let shipToName;
    if (checkForNotNull(this.state.ShipTo_Name)) {
      shipToName = 'Ship To: ' + this.state.ShipTo_Name
    }
    else {
      shipToName = null
    }

    let packageDims;
    try {
      if (this.state.PackageDict.length > 1) {
        packageDims = 'Multiple Packages'
      }
      else {
        if (checkForNotNull(this.state.PackageDict[0].Package_Dimensions_Length) && checkForNotNull(this.state.PackageDict[0].Package_Dimensions_Width) && checkForNotNull(this.state.PackageDict[0].Package_Dimensions_Height)) {
          packageDims = this.state.PackageDict[0]['Package_Dimensions_Length'] + ' x ' + this.state.PackageDict[0]['Package_Dimensions_Width'] + ' x ' + this.state.PackageDict[0]['Package_Dimensions_Height'] + ' ' + this.state.PackageDict[0]['Package_Dimensions_UOM_Code']
        }
        else {
          packageDims = null
        }
      }
    }
    catch (e) { packageDims = null }

    let packageWeight;
    try {
      if (this.state.PackageDict.length > 1) {
        packageWeight = ''
      }
      else {
        if (checkForNotNull(this.state.PackageDict[0].Package_PackageWeight_Weight) && checkForNotNull(this.state.PackageDict[0].Package_PackageWeight_UOM_Code)) {
          packageWeight = this.state.PackageDict[0]['Package_PackageWeight_Weight'] + ' ' + this.state.PackageDict[0]['Package_PackageWeight_UOM_Code']
        }
        else {
          packageWeight = null
        }
      }
    }
    catch (e) { packageWeight = null }





    let newlyCreatedTrackingList;
    if (this.state.NewlyCreatedTracking.length > 0) {
      newlyCreatedTrackingList =
        <Form fluid>

          <Col md={24} sm={24}>
            <Panel bordered style={{ marginBottom: '15px' }} header="New Tracking Numbers:" >
              <List>
                {this.state.NewlyCreatedTracking.map(item => (
                  <List.Item>{item}</List.Item>
                ))
                }
              </List>
            </Panel>
          </Col>
        </Form>
    }
    else {
      newlyCreatedTrackingList = <div></div>
    }


    return (
      <div>
        <h3>
          Label Generation
        </h3>

        <br />
        <br />

        <Steps current={step}>
          <Steps.Item title="Carrier" description={<div> {CustomerName} <br />  {WarehouseName} <br /> {Carrier} </div>} />
          <Steps.Item title="Package" description={<div> {packageDims} <br />  {packageWeight} </div>} />
          <Steps.Item title="Address" description={<div> {shipToName} </div>} />
          <Steps.Item title="Label" />
        </Steps>
        <hr />
        <Row>
          <ButtonGroup >
            <Button onClick={this.decline} disabled={step === 0} appearance="primary">
              Previous
            </Button>
            <Button onClick={this.increase} disabled={step === 3} appearance="primary">
              Next
            </Button>
          </ButtonGroup>


          <ButtonGroup style={{ float: 'right' }}>

            <Button onClick={this.onClickStartNewLabel} disabled={step === 0} >
              Start New Label
            </Button>
          </ButtonGroup>

        </Row>
        <hr />



        {(() => {
          switch (step) {
            case 0: return (
              <div>
                <StepCarrier {...this.props} state={this.state}
                  handleChangeFunc={this.handleChangeFunc}
                  onClickCarrierMethod={this.onClickCarrierMethod}
                  handleChangeCarrier={this.handleChangeCarrier} />
              </div>
            );
            case 1: return (
              <div>

                <StepPackage {...this.props} state={this.state}
                  handleChangeFunc={this.handleChangeFunc}

                  onClickAddPackage={this.onClickAddPackage}
                  onClickEditPackage={this.onClickEditPackage}
                  onClickDeletePackage={this.onClickDeletePackage}

                  onClickAddProduct={this.onClickAddProduct}
                  onClickEditProduct={this.onClickEditProduct}
                  onClickDeleteProduct={this.onClickDeleteProduct}
                  onClickSaveAndAddPackage={this.onClickSaveAndAddPackage}
                  onClickSaveAndAddProduct={this.onClickSaveAndAddProduct}
                  handleSaveAddNewPackageModal={this.handleSaveAddNewPackageModal}
                />
              </div>
            );
            case 2: return (
              <div>
                <StepAddress {...this.props} state={this.state} handleChangeFunc={this.handleChangeFunc} />
              </div>
            );
            case 3: return (
              <div>

                <StepLabel {...this.props} state={this.state}
                  handleChangeFunc={this.handleChangeFunc}
                  handleEnvToggleChange={this.handleEnvToggleChange}
                  onClickGenerateLabel={this.onClickGenerateLabel}
                />
              </div>
            )
            default: return;
          }
        })()}




        {newlyCreatedTrackingList}


        <AddPackageModal {...this.props} state={this.state} addNewPackageModalShown={this.state.addNewPackageModalShown} onChange={this.handleChangeFunc} handleClose={this.handleCloseAddNewPackageModal} handleSave={this.handleSaveAddNewPackageModal} />

        <AddProductModal {...this.props} state={this.state} addNewProductModalShown={this.state.addNewProductModalShown} onChange={this.handleChangeFunc} handleClose={this.handleCloseAddNewProductModal} handleSave={this.handleSaveAddNewProductModal} />



      </div>
    );
  }
}


export default withRouter(LabelGeneration);
