import React from 'react';
import { Link } from 'react-router-dom';
import { customerConfig } from '../../../app/appCustomerConfig';
// import PageNotFound from '../assets/images/PageNotFound';
const pageNotFound = () => (
<div>
{/* <img src={PageNotFound} style={{width: 400, height: 400, display: 'block', margin: 'auto', position: 'relative' }} /> */}

<Link to={`/${customerConfig.customerUrl}/home`}>
<div
className="row">
<div className="col-md-12" >


<span >
<i className={'fa fa-chain-broken'}
style={{   
    width: '60px',
    height: '60px',
    display: 'inline-block',
    lineHeight: '60px',
    textAlign: 'center',
    fontSize: '30px',
    background:  'grey' ,
    borderRadius: '5px',
    float: 'center',
    verticalAlign: 'center',
    margin: '15px',
    color: '#fff',
    cursor: "pointer"
    }}
></i> 
Return Home
</span>

</div>

</div>

</Link>


</div>
);
export default pageNotFound;





