import Auth from '@aws-amplify/auth';
import Amplify from "@aws-amplify/core";
import React from 'react';
import {
  Redirect,
  Route,
  withRouter
} from 'react-router-dom';
import { customerConfig } from '../../../app/appCustomerConfig';
import { appSettings } from '../../../app/appSettings';
import { awsCognitoConfig } from '../../../app/awsCognitoConfig';
import { formatArrayAsStringForAPI } from '../../../resources/logic/functions';
const waitFor = delay => new Promise(resolve => setTimeout(resolve, delay));

class PrivateRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      isAuthenticated: false,
      width: window.innerWidth,
      warehouseSelection: null,
      customerSeletion: null,
      organizationSelection: null,
    };
  }
  //Mobile View
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  // check width for mobile device
  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }
  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  async waitSignin() {

    await waitFor(1200);
    try{
    this.setState({ loaded: true, isAuthenticated: true })
    }
    catch(e){}
  }


  componentDidMount() {

    Amplify.configure({
      Auth: {
        mandatorySignIn: true,
        region: awsCognitoConfig.cognitoLogin.Auth.region,
        userPoolId: awsCognitoConfig.cognitoLogin.Auth.UserPoolId,
        identityPoolId: awsCognitoConfig.cognitoLogin.Auth.IdentityPoolId,
        userPoolWebClientId: awsCognitoConfig.cognitoLogin.Auth.ClientId
      }
    });
    this.authenticate()
    this.unlisten = this.props.history.listen(() => {
      Auth.currentAuthenticatedUser()
        .then(user => {
        }
        )
        .catch(() => {
          if (this.state.isAuthenticated) this.setState({ isAuthenticated: false })
          if (this.state.isAuthenticated === false) {
            this.navigateToSignin()
          }

        })
    });
  }

  componentWillUnmount() {
    this.unlisten()
  }

  authenticate() {
    Auth.currentAuthenticatedUser()
      .then(user => {
        var useremail = user.signInUserSession.idToken.payload.email
        this.props.appSignIn(useremail)
        this.waitSignin();
      }
      )
      .catch(() => this.props.history.push(`/${customerConfig.customerUrl}/auth`))
  }

  navigateToSignin() {
    this.props.history.push(`/${customerConfig.customerUrl}/signin`)
  }



  render() {
    const { width } = this.state;
    const isMobile = width <= appSettings.mobileWidth;
    const { component: Component, ...rest } = this.props
    const { loaded, isAuthenticated } = this.state

    // let warehouseValue;
    // try{
    //   warehouseValue = JSON.parse(sessionStorage.getItem('currentWarehouse'));
    // }
    // catch(e){
    //   warehouseValue = null
    // }

    // let customerValue;
    // try{
    //   customerValue = JSON.parse(sessionStorage.getItem('currentCustomer'));
    // }
    // catch(e){
    //   customerValue = null
    // }




    // var testWarhouse = sessionStorage.getItem('currentWarehouse')


    // let warehouseMenuList = {}
    // try {
    //   warehouseMenuList = JSON.parse(sessionStorage.getItem('AppConfig.WarehouseArray'));
    // }
    // catch (e) { }


    // // try {
    //   if (this.props.userSignInData.Admin && warehouseMenuList.length > 1) {
    //     // this is for multi select:
    //     var warehouseCurrent = sessionStorage.getItem('currentWarehouse')
    //     var warehouseSplit = warehouseCurrent.split(',').map(Number);

    //     if (warehouseSplit[0] !== 0 && warehouseSplit !== null && warehouseSplit !== undefined && !isNaN(warehouseSplit[0])) {
    //       warehouseValue = warehouseSplit
    //     }
    //   }
    //   else {
    //     //this is for single select:
    //     var warehouseCurrent = JSON.parse(sessionStorage.getItem('currentWarehouse'));
    //     if (warehouseCurrent !== null && warehouseCurrent !== undefined) {
    //       warehouseValue = warehouseCurrent
    //     }
    //   }

    // // }
    // // catch (e) { 
    // //   warehouseValue = null
    // // }



    // let customerMenuList = {}
    // try {
    //   customerMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CustomerArray'));
    // }
    // catch (e) { }




    // try {
    //   if (this.props.userSignInData.Admin && customerMenuList.length > 1) {
    //     // this is for multi select:
    //     var customerCurrent = sessionStorage.getItem('currentCustomer')
    //     var customerSplit = customerCurrent.split(',').map(Number);

    //     if (customerSplit[0] !== 0 && customerSplit !== null && customerSplit !== undefined && !isNaN(customerSplit[0])) {
    //       customerValue = customerSplit
    //     }
    //   }
    //   else {
    //     //this is for single select:
    //     var customerCurrent = JSON.parse(sessionStorage.getItem('currentCustomer'));
    //     if (customerCurrent !== null && customerCurrent !== undefined) {
    //       customerValue = customerCurrent
    //     }
    //   }

    // }
    // catch (e) { 
    //   customerValue = null
    // }






    let warehouseValue;
    try {

      warehouseValue = JSON.parse(sessionStorage.getItem('currentWarehouse'));
      // warehouseValue = sessionStorage.getItem('currentWarehouse');

    }
    catch (e) {
      try {
        var warehouseCurrent = sessionStorage.getItem('currentWarehouse')
        var warehouseSplit = warehouseCurrent.split(',').map(Number);

        if (warehouseSplit[0] !== 0 && warehouseSplit !== null && warehouseSplit !== undefined && !isNaN(warehouseSplit[0])) {
          warehouseValue = formatArrayAsStringForAPI(String(warehouseSplit))
        }
      }
      catch (e) {
        warehouseValue = null
      }

    }

    if (typeof (warehouseValue) === 'undefined') {
      warehouseValue = null
    }




    let organizationValue;
    try {

      organizationValue = JSON.parse(sessionStorage.getItem('currentOrganization'));
      // organizationValue = sessionStorage.getItem('currentOrganization');

    }
    catch (e) {
      try {
        var organizationCurrent = sessionStorage.getItem('currentOrganization')
        var organizationSplit = organizationCurrent.split(',').map(Number);

        if (organizationSplit[0] !== 0 && organizationSplit !== null && organizationSplit !== undefined && !isNaN(organizationSplit[0])) {
          organizationValue = formatArrayAsStringForAPI(String(organizationSplit))
        }
      }
      catch (e) {
        organizationValue = null
      }

    }

    if (typeof (organizationValue) === 'undefined') {
      organizationValue = null
    }


    

    let customerValue;
    try {

      customerValue = JSON.parse(sessionStorage.getItem('currentCustomer'));
      // customerValue = sessionStorage.getItem('currentCustomer');

    }
    catch (e) {
      try {
        var customerCurrent = sessionStorage.getItem('currentCustomer')
        var customerSplit = customerCurrent.split(',').map(Number);

        if (customerSplit[0] !== 0 && customerSplit !== null && customerSplit !== undefined && !isNaN(customerSplit[0])) {
          customerValue = formatArrayAsStringForAPI(String(customerSplit))
        }

      }
      catch (e) {
        customerValue = null
      }

    }

    if (typeof (customerValue) === 'undefined') {
      customerValue = null
    }





    if (!loaded) return null


    return (
      <Route
        render={() => {
          return isAuthenticated ? (
            <this.props.component isMobile={isMobile} warehouseSelection={warehouseValue} customerSelection={customerValue} organizationSelection={organizationValue}/>
          ) : (
            <Redirect to="/signin" />
          )
        }}
      />
    )
  }
}


export default withRouter(PrivateRoute)












