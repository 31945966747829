import React from 'react';
import PropTypes from 'prop-types';
import {
    Drawer,
    Paragraph,
    Button,
    ButtonToolbar,
    Form,
    FormControl,
    FormGroup,
    ControlLabel,
    HelpBlock,
    DatePicker,
    InputPicker
} from 'rsuite';
import RFormControl from '../../componentsGeneral/Rsuite/Form/RFormControl';
import { lettersNumbersDashesInputMask, numbersInputMask, lettersNumbersDashesSpacesInputMask, textStringInputMask } from '../../../resources/logic/inputMasks';

const styles = {
    // width: 300,
    marginBottom: 10,
    width: '100%'
};

const dropDownLclFcl = [
    {
        "label": "LCL",
        "value": "LCL"
    },
    {
        "label": "FCL",
        "value": "FCL"
    }
]

const mountRef = React.createRef();

const EditContainerDrawer = ({ onChangeEditContainer, show, placement, confirm, close, unsavedChanges, state, ...props }) => (

    <Drawer show={state.showEditContainerDrawer} onHide={close} placement={placement} style={props.isMobile ? { width: '350px' } : { width: '650px' }}    >
        <Drawer.Header>
            <Drawer.Title>Edit Container</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body> <div ref={mountRef}>

            <Form fluid>

                <FormGroup>
                    <ControlLabel>Container</ControlLabel>
                    <FormControl name="Container" value={state.Container} onChange={(e) => { onChangeEditContainer(e, 'Container') }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} />
                </FormGroup>

                <FormGroup>
                    <ControlLabel>LCL FCL</ControlLabel>

                    <InputPicker
    preventOverflow
                        container={() => mountRef.current}

                        placeholder={'Select...'}
                        value={state.LclFcl}
                        onChange={(e) => { onChangeEditContainer(e, 'LclFcl') }}
                        data={dropDownLclFcl}
                        block
                        cleanable={true}
                    />
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Delivery Appointment</ControlLabel>
                    <DatePicker
                        container={() => mountRef.current}
                        placeholder="Delivery Appointment"
                        onChange={(e) => { onChangeEditContainer(e, 'DeliveryAppointment') }}
                        value={state.DeliveryAppointment}
                        format="YYYY-MM-DD hh:mm aa"
                        defaultValue={ new Date(new Date().setHours(0, 0, 0, 0)) }
                        showMeridian
                        ranges={[
                            {
                                label: 'Now',
                                value: new Date()
                            }
                        ]}
                        style={styles}
                    />
                </FormGroup>

                <FormGroup>
                    <ControlLabel>ETD</ControlLabel>
                    <DatePicker
                        container={() => mountRef.current} oneTap style={styles} placeholder="ETD" value={state.ETD} onChange={(e) => { onChangeEditContainer(e, 'ETD') }} />
                </FormGroup>

                <FormGroup>
                    <ControlLabel>ETA</ControlLabel>
                    <DatePicker
                        container={() => mountRef.current} oneTap style={styles} placeholder="ETA" value={state.ETA} onChange={(e) => { onChangeEditContainer(e, 'ETA') }} />
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Notes</ControlLabel>
                    <FormControl name="Notes" value={state.Notes} onChange={(e) => { onChangeEditContainer(e, 'Notes') }} onInput={(e) => e.target.value = textStringInputMask(e)} />
                </FormGroup>

                <FormGroup>
                    <ButtonToolbar>
                        {unsavedChanges ? <Button onClick={(e) => { confirm(e) }} appearance="primary">Save</Button> : <div></div>}
                        <Button onClick={(e) => { close(e) }} appearance="default">Cancel</Button>
                    </ButtonToolbar>
                </FormGroup>
            </Form>

        </div> </Drawer.Body>
        <Drawer.Footer>
            <br />
        </Drawer.Footer>

    </Drawer>

);



// EditContainerDrawer.defaultProps = {
//     show: false
// };

export default EditContainerDrawer;


