import React from 'react';

import { CheckPicker } from 'rsuite';



const footerStyles = {
  padding: '10px 2px',
  borderTop: '1px solid #e5e5e5'
};

const footerButtonStyle = {
  float: 'right',
  marginRight: 10,
  marginTop: 2
};



class RCheckPicker extends React.Component {
  constructor() {
    super();
    this.state = {
      indeterminate: false,
      checkAll: false,
      // value: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckAll = this.handleCheckAll.bind(this);


   
  }


  

  handleChange(value) {
    var allValue = this.props.data.map(item => item.value);

    this.setState({
      // value,
      indeterminate: value.length > 0 && value.length < allValue.length,
      checkAll: value.length === allValue.length
    });
    this.props.setCheckedState(value)
  }

  handleCheckAll(value, checked) {
    var allValue = this.props.data.map(item => item.value);
    const nextValue = checked ? allValue : [];
    this.setState({
      // value: nextValue,
      indeterminate: false,
      checkAll: checked
    });
    this.props.setCheckedState(nextValue)
  }
  render() {
    const { checkAll, indeterminate } = this.state;

    let checkDiv;
    try{
        checkDiv = <CheckPicker
        data={this.props.data}
        placeholder="Select"
        ref={ref => {
          this.picker = ref;
        }}
        // style={{ width: 224 }}
        value={this.props.value}
        // onChange={this.handleChange}
        onChange={(e) => { this.handleChange(e) }}
        block
        // renderExtraFooter={() => (
        //   <div style={footerStyles}>
        //     <Checkbox
        //       inline
        //       indeterminate={indeterminate}
        //       checked={checkAll}
        //       onChange={this.handleCheckAll}
        //     >
        //       Check all
        //     </Checkbox>

        //     <Button
        //       style={footerButtonStyle}
        //       appearance="primary"
        //       size="sm"
        //       onClick={() => {
        //         this.picker.close();
        //       }}
        //     >
        //       Ok
        //     </Button>
        //   </div>
        // )}
        />
    }
    catch(e){

    }
    return (
      <div>
        {checkDiv}
      </div>
    );
  }
}


export default RCheckPicker;



// props:
// data
// value
// setCheckedState





// import React, {
//   PureComponent
// } from 'react';
// import { CheckPicker, Checkbox, Button, InputPicker, ControlLabel, Tooltip } from 'rsuite';
// import { queryStringParse } from '../../../../resources/logic/functions';

// const styles = {
//   // width: 300,
//   marginBottom: 10
// };

// const tooltipSearch = (
//   <Tooltip>
//     Search
//   </Tooltip>
// );

// const tooltipClear = (
//   <Tooltip>
//     Clear
//   </Tooltip>
// );

// function onClickSearch(e, history, querySearch, searchValue) {
//   if (searchValue != '') {
//     var param = null
//     param = queryStringParse(searchValue)
//     try {
//       history.push({
//         search: querySearch + '=\'' + param + '\''
//       });
//     }
//     catch (e) { }
//   }
//   else {
//     window.alert('Enter Search Value')
//   }
// }

// // function onClickReset(e, history, onChange) {   
// //   onChange('')   
// //   try{
// //       history.push({
// //       }); 
// //   }
// //   catch(e){}
// // }

// function RCheckPicker(
//   isMobile,
//   data,
//   value,
//   label,
//   onChange,
//   history,
//   mountRef
//   // querySearch,
//   // searchValue,
//   // inputMask
// ) {

//   let rInput;

//   try {
//     rInput = <div style={styles} >
//       <ControlLabel>{label}</ControlLabel>

//       <InputPicker
    // preventOverflow
//         placeholder={'Select...'}
//         value={value}
//         onChange={(e) => { onChange(e) }}
//         data={data}
//         block
//         cleanable={true}
//         container={() => mountRef.current}

//       />

//     </div>
//   }
//   catch (e) { }

//   return (

//     rInput

//   )
// }


// export default RCheckPicker;










// import React, {
//   PureComponent
// } from 'react';

// import { CheckPicker, Checkbox, Button } from 'rsuite';

// // const data = ['Eugenia', 'Bryan', 'Linda', 'Nancy', 'Lloyd', 'Alice', 'Julia', 'Albert'].map(
// //   item => ({ label: item, value: item })
// // );

// const styles = {
//   // width: '100%',
//   marginBottom: 10
// };

// const footerStyles = {
//   padding: '10px 2px',
//   borderTop: '1px solid #e5e5e5'
// };

// const footerButtonStyle = {
//   float: 'right',
//   marginRight: 10,
//   marginTop: 2,
//   marginBottom: 15
// };


// const RCheckPicker = ({
//   dropDownData,
//   placeholder,
//   selectedValue,
//   onChange,
//   mountRef
// }
// ) => {

//   // const picker = React.useRef();
//   // const [value, setValue] = React.useState([]);

//   // const handleChange = value => {
//   //   setValue(value);
//   // };

//   // const handleCheckAll = (value, checked) => {
//   //   setValue(checked ? allValue : []);
//   // };



  

//   let rInput;


//   if (typeof (dropDownData) !== 'undefined' && dropDownData != null && typeof (dropDownData.length) !== 'undefined') {

//     var allValue = dropDownData.map(item => item.value);

//     var selectedValueLength = 0;
//     try{
//       selectedValueLength = selectedValue.length
//     }
//     catch(e) {}
    

//     try {
//       rInput = <div style={styles} >
//                 <CheckPicker
//                   data={dropDownData}
//                   placeholder={placeholder}
//                   // ref={ref => {
//                   //   this.picker = ref;
//                   // }}
//                   style={{ width: 224 }}
//                   value={selectedValue}
//                   // onChange={this.handleChange}
//                   onChange={(e) => { onChange(e) }}
//                   container={() => mountRef.current}
//                   renderExtraFooter={() => (
//                     <div style={footerStyles}>
//                       <Checkbox
//                         inline

//                         indeterminate={selectedValueLength > 0 && selectedValueLength < allValue.length}
//                         checked={selectedValueLength === allValue.length}

//                         // onChange={this.handleCheckAll}
//                         // onChange={(e) => { onChange(allValue) }}


//                         onChange={(e) => { (selectedValueLength === allValue.length ? onChange([]): onChange(allValue))  }}

//                       >
//                         Select all
//                       </Checkbox>

//                       <Button
//                         style={footerButtonStyle}
//                         appearance="primary"
//                         size="sm"
//                         onClick={() => {
//                           // this.picker.close();
//                         }}
//                       >
//                         Ok
//                       </Button>
//                     </div>
//                   )}
//                 />
//               </div>

//       }
//       catch (e) { }

//   }
//   else{
//     rInput = <div></div>
//   }



//   return (

//     rInput

//   )


// };

// // ReactDOM.render(<RCheckPicker />, document.getElementById('root'));

// export default RCheckPicker;






// import React, { Component } from 'react';

// const footerStyles = {
//   padding: '10px 2px',
//   borderTop: '1px solid #e5e5e5'
// };

// const footerButtonStyle = {
//   float: 'right',
//   marginRight: 10,
//   marginTop: 2
// };

// const allValue = data.map(item => item.value);

// class RCheckPicker extends React.Component {
//   constructor() {
//     super();
//     this.state = {
//       indeterminate: false,
//       checkAll: false,
//       value: []
//     };
//     this.handleChange = this.handleChange.bind(this);
//     this.handleCheckAll = this.handleCheckAll.bind(this);
//   }
//   handleChange(value) {
//     this.setState({
//       value,
//       indeterminate: value.length > 0 && value.length < allValue.length,
//       checkAll: value.length === allValue.length
//     });
//   }

//   handleCheckAll(value, checked) {
//     const nextValue = checked ? allValue : [];
//     this.setState({
//       value: nextValue,
//       indeterminate: false,
//       checkAll: checked
//     });
//   }
//   render() {
//     const { checkAll, indeterminate } = this.state;
//     return (
//       <div className="example-item">
//         <CheckPicker
//           data={data}
//           placeholder="Select"
//           ref={ref => {
//             this.picker = ref;
//           }}
//           style={{ width: 224 }}
//           value={this.state.value}
//           onChange={this.handleChange}
//           renderExtraFooter={() => (
//             <div style={footerStyles}>
//               <Checkbox
//                 inline
//                 indeterminate={indeterminate}
//                 checked={checkAll}
//                 onChange={this.handleCheckAll}
//               >
//                 Check all
//               </Checkbox>

//               <Button
//                 style={footerButtonStyle}
//                 appearance="primary"
//                 size="sm"
//                 onClick={() => {
//                   this.picker.close();
//                 }}
//               >
//                 Ok
//               </Button>
//             </div>
//           )}
//         />
//       </div>
//     );
//   }
// }

// export default RCheckPicker;


//https://master--rsuite.netlify.app/en/components/check-picker












// import React, {
//   PureComponent
// } from 'react';

// import { CheckPicker, Checkbox, Button } from 'rsuite';

// // const data = ['Eugenia', 'Bryan', 'Linda', 'Nancy', 'Lloyd', 'Alice', 'Julia', 'Albert'].map(
// //   item => ({ label: item, value: item })
// // );

// const styles = {
//   // width: 300,
//   marginBottom: 10
// };

// const footerStyles = {
//   padding: '10px 2px',
//   borderTop: '1px solid #e5e5e5'
// };

// const footerButtonStyle = {
//   float: 'right',
//   marginRight: 10,
//   marginTop: 2,
//   marginBottom: 15
// };


// const RCheckPicker = ({
//   dropDownData,
//   allValue,
//   placeholder,
//   selectedValue,
//   onChange,
//   handleCheckAll,
//   mountRef
// }
// ) => {
  


//   const picker = React.useRef();
//   // const [value, setValue] = React.useState([]);

//   // const handleChange = value => {
//   //   setValue(value);
//   // };

//   // const handleCheckAll = (value, checked) => {
//   //   setValue(checked ? allValue : []);
//   // };



  

//   let rInput;

//   try {
    
    
//     rInput = <div style={styles} >

//               <div className="example-item">
//                     <CheckPicker
//                       data={dropDownData}
//                       placeholder={placeholder}
//                       ref={picker}
//                       style={{ width: 224 }}
//                       value={selectedValue}
//                       onChange={(e) => { onChange(e) }}
//                       container={() => mountRef.current}

//                       renderExtraFooter={() => (
//                         <div style={footerStyles}>
//                           <Checkbox
//                             indeterminate={selectedValue.length > 0 && selectedValue.length < allValue.length}
//                             checked={selectedValue.length === allValue.length}
//                             onChange={(e) => { handleCheckAll(e) }}
//                           >
//                             Select all
//                           </Checkbox>

//                           <Button
//                             style={footerButtonStyle}
//                             appearance="primary"
//                             size="sm"
//                             onClick={() => {
//                               picker.current.close();
//                             }}
//                           >
//                             Ok
//                           </Button>
//                         </div>
//                       )}
//                     />
//                   </div>

//                   </div>
//   }
//   catch (e) { }

//   return (

//     rInput

//   )


// };

// // ReactDOM.render(<RCheckPicker />, document.getElementById('root'));

// export default RCheckPicker;









// import React, {
//   PureComponent
// } from 'react';
// import { CheckPicker, Checkbox, Button } from 'rsuite';


// const styles = {
//   // width: 300,
//   marginBottom: 10
// };


// const footerStyles = {
//   padding: '10px 2px',
//   borderTop: '1px solid #e5e5e5'
// };

// const footerButtonStyle = {
//   float: 'right',
//   marginRight: 10,
//   marginTop: 2
// };

  

// const RCheckPicker = (
//   isMobile,
//   data,
//   value,
//   label,
//   onChange,
//   mountRef,
//   handleCheckAll,
//   allValueLength
// ) => {
//   // const allValue = data.map(item => item.value);

//   const picker = React.useRef();
//   // const [value, setValue] = React.useState([]);


//   // const handleCheckAll = (value, checked) => {
//   //   setValue(checked ? allValue : []);
//   // };


//   return (
//     <div style={styles} >
//       <CheckPicker
//         data={data}
//         ref={picker}
//         placeholder={'Select...'}
//         value={value}
//         onChange={(e) => { onChange(e) }}
//         block
//         cleanable={true}
//         container={() => mountRef.current}
//         renderExtraFooter={() => (
//           <div style={footerStyles}>
//             <Checkbox
//               indeterminate={value.length > 0 && value.length < allValueLength}
//               checked={value.length === allValueLength}
//               onChange={handleCheckAll}
//             >
//               Check all
//             </Checkbox>

//             <Button
//               style={footerButtonStyle}
//               appearance="primary"
//               size="sm"
//               onClick={() => {
//                 picker.current.close();
//               }}
//             >
//               Ok
//             </Button>
//           </div>
//         )}
//       />
//     </div>

//   );
// };



// export default RCheckPicker;



