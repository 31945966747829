import React, {
  PureComponent
} from 'react';
import Loader from 'react-loader-spinner';
import { withRouter } from 'react-router-dom';
import { customerConfig } from '../../../app/appCustomerConfig';

class SignInUserAuth extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      warehouseLoaded: false,
      customersLoaded: false,
      modulesLoaded: false,
      organizationLoaded: false,
    };
  }



  componentDidMount() {
    if (this.props.isAppSigningInLoading) {
    }
    else if (this.props.currentUserEmail !== null && this.props.currentUserEmail !== '') {
      this.props.userWarehousesGet(this.props.currentUserEmail, null)
      this.props.userOrganizationGet(this.props.currentUserEmail, null)
      this.props.userCustomersGet(this.props.currentUserEmail, null)
      this.props.userModulesGet(this.props.currentUserEmail, null)
      this.props.userLabelGenCarrierGet(this.props.currentUserEmail, null)
      this.props.userLabelGenCarrierServiceGet(this.props.currentUserEmail, null)
    }



  }



  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isAppSigningInLoading && !this.props.isAppSigningInLoading) {
      this.props.userWarehousesGet(this.props.currentUserEmail, null)
      this.props.userOrganizationGet(this.props.currentUserEmail, null)
      this.props.userCustomersGet(this.props.currentUserEmail, null)
      this.props.userModulesGet(this.props.currentUserEmail, null)
      this.props.userLabelGenCarrierGet(this.props.currentUserEmail, null)
      this.props.userLabelGenCarrierServiceGet(this.props.currentUserEmail, null)
    }

    if ((prevProps.currentUserEmail !== this.props.currentUserEmail) && this.props.currentUserEmail !== null && this.props.currentUserEmail !== '') {
      this.props.userWarehousesGet(this.props.currentUserEmail, null)
      this.props.userOrganizationGet(this.props.currentUserEmail, null)
      this.props.userCustomersGet(this.props.currentUserEmail, null)
      this.props.userModulesGet(this.props.currentUserEmail, null)
      this.props.userLabelGenCarrierGet(this.props.currentUserEmail, null)
      this.props.userLabelGenCarrierServiceGet(this.props.currentUserEmail, null)
    }


    if (prevProps.isUserWarehouseLoading && !this.props.isUserWarehouseLoading) {
      this.setState({
        warehouseLoaded: true
      })
    }
    if (prevProps.isUserCustomerLoading && !this.props.isUserCustomerLoading) {
      this.setState({
        customersLoaded: true
      })
    }
    if (prevProps.isUserModuleLoading && !this.props.isUserModuleLoading) {
      this.setState({
        modulesLoaded: true
      })
    }

    if (prevProps.isUserOrganizationLoading && !this.props.isUserOrganizationLoading) {
      this.setState({
        organizationLoaded: true
      })
    }

    if (this.state.warehouseLoaded && this.state.customersLoaded && this.state.modulesLoaded && this.state.organizationLoaded) {
      this.props.history.push(`/${customerConfig.customerUrl}/wms`)
    }

  }



  render() {


    return (

      <div align="center">
        <section className="panel">
          <header className="panel-heading">
            { }
          </header>
        </section>
        <Loader
          type="TailSpin"
          color="#3396E5"
          height="50"
          width="50"
        />
      </div>

    );
  }
}


export default withRouter(SignInUserAuth);

