import React, {
    PureComponent
} from 'react';
// import Loader from 'react-loader-spinner'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { withRouter } from 'react-router-dom';
import { Button, Col, InputPicker, Loader, Panel, PanelGroup, Row } from 'rsuite';
import { customerConfig } from '../../../app/appCustomerConfig';
import RButtonPrimary from '../../componentsGeneral/Rsuite/Button/RButtonPrimary';
import RTableGeneral from '../../componentsGeneral/Rsuite/Table/RTableGeneral';
import UserAccountCustomerDataElements from '../../dataComponentsUser/UserAccountCustomerDataElements';
import UserAccountModuleDataElements from '../../dataComponentsUser/UserAccountModuleDataElements';
import UserAccountWarehouseDataElements from '../../dataComponentsUser/UserAccountWarehouseDataElements';
import AccountDetailsEditDrawer from './AccountDetailsEditDrawer';
import CustomerEditDrawer from './CustomerEditDrawer';
import ModuleEditDrawer from './ModuleEditDrawer';
import WarehouseEditDrawer from './WarehouseEditDrawer';
import { Message } from 'rsuite';
import AnimatedView from '../../componentsGeneral/AnimatedView/AnimatedView';


const tableIdCol = "WarehouseID"


class AccountDetails extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            //Add New Master Values
            showAccountDetailsEditDrawer: false,
            AccountAdmin: null,


            //Warehouse Edit Drawer:
            showWarehouseEditDrawer: false,
            warehouseIdEditDrawer: null,
            warehouseNameEditDrawer: null,
            WarehouseAdmin: null,
            WarehouseReadWrite: null,

            //Add new warehouse
            warehouseArray: {},
            addWarehouseSelection: null,


            //Customer Edit Drawer:
            showCustomerEditDrawer: false,
            customerIdEditDrawer: null,
            customerNameEditDrawer: null,
            CustomerAdmin: null,
            CustomerReadWrite: null,

            //Add new customer
            customerArray: {},
            addCustomerSelection: null,

            //Module Edit Drawer:
            showModuleEditDrawer: false,
            moduleIdEditDrawer: null,
            moduleNameEditDrawer: null,
            ModuleAdmin: null,
            ModuleReadWrite: null,

            //Add new module
            moduleArray: {},
            addModuleSelection: null,

        };
        // Table Sort
        this.handleSortColumn = this.handleSortColumn.bind(this);


        this.onClickButtonDelete = this.onClickButtonDelete.bind(this);
        this.onClickButtonEdit = this.onClickButtonEdit.bind(this);

        //Edit Account drawer
        this.closeAccountEditDrawer = this.closeAccountEditDrawer.bind(this);
        this.onClickConfirmAccountEdit = this.onClickConfirmAccountEdit.bind(this);

        //Warehouse Edit drawer
        this.onClickOpenWarehouseDrawer = this.onClickOpenWarehouseDrawer.bind(this);
        this.closeWarehouseEditDrawer = this.closeWarehouseEditDrawer.bind(this);
        this.onClickConfirmWarehouseEdit = this.onClickConfirmWarehouseEdit.bind(this);
        this.onClickDeleteWarehouse = this.onClickDeleteWarehouse.bind(this);

        //Add new warehouse
        this.onChangeSelectAddWarehouse = this.onChangeSelectAddWarehouse.bind(this);


        //Customer Edit drawer
        this.onClickOpenCustomerDrawer = this.onClickOpenCustomerDrawer.bind(this);
        this.closeCustomerEditDrawer = this.closeCustomerEditDrawer.bind(this);
        this.onClickConfirmCustomerEdit = this.onClickConfirmCustomerEdit.bind(this);
        this.onClickDeleteCustomer = this.onClickDeleteCustomer.bind(this);

        //Add new customer
        this.onChangeSelectAddCustomer = this.onChangeSelectAddCustomer.bind(this);

        //Module Edit drawer
        this.onClickOpenModuleDrawer = this.onClickOpenModuleDrawer.bind(this);
        this.closeModuleEditDrawer = this.closeModuleEditDrawer.bind(this);
        this.onClickConfirmModuleEdit = this.onClickConfirmModuleEdit.bind(this);
        this.onClickDeleteModule = this.onClickDeleteModule.bind(this);

        //Add new module
        this.onChangeSelectAddModule = this.onChangeSelectAddModule.bind(this);
    }


    componentDidMount() {

        this.getsessionStorageWarehouse() //this will preserve menu selection

    }

    getsessionStorageWarehouse() {

        try {
            let warehouseMenuList = JSON.parse(sessionStorage.getItem('AppConfig.WarehouseArray'));
            if (warehouseMenuList != null && warehouseMenuList != undefined) {
                this.setState({
                    warehouseArray: warehouseMenuList
                })
            }
        }
        catch (e) { }

        try {
            let customerMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CustomerArray'));
            if (customerMenuList != null && customerMenuList != undefined) {
                this.setState({
                    customerArray: customerMenuList
                })
            }
        }
        catch (e) { }

        try {
            let moduleMenuList = JSON.parse(sessionStorage.getItem('AppConfig.ModuleArray'));
            if (moduleMenuList != null && moduleMenuList != undefined) {
                this.setState({
                    moduleArray: moduleMenuList
                })
            }
        }
        catch (e) { }
    }

    onClickButtonDelete = (e) => {
        if (window.confirm('Delete User Account?')) {

            this.props.userAwsCognitoDeleteUserPost(this.props.AllUsersData[0].EmailAddr);

            this.props.postDeleteUser(this.props.userSignInData.EmailAddr, this.props.userSignInData.AuthToken, this.props.AllUsersData[0].UserID)
        }
    }

    onClickChangePW = (e) => {
        if (window.confirm('Change Password?')) {
            this.props.history.push(`/${customerConfig.customerUrl}/wms/userchangepassword`)
        }
    }

    // Table Sort
    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });
        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 500);
    }




    //Edit Account drawer
    onClickButtonEdit(placement) {
        var editAccountAdmin = this.props.AllUsersData[0].Admin


        this.setState({
            placement,
            showAccountDetailsEditDrawer: true,
            AccountAdmin: editAccountAdmin,
        });
    }

    onChangeAccountEdit = (event, field) => {
        var dict = {};
        dict[field] = event;
        this.setState(
            dict
        );
    };

    onClickConfirmAccountEdit(e) {
        var setAdmin = null
        var setPermissions = null

        try {
            if (this.state.AccountAdmin == true) {
                setAdmin = 1
            }
            else {
                setAdmin = 0
            }
        }
        catch (e) { }




        this.props.postAccountEdit(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.AllUsersData[0].UserID, null, null, null, setAdmin, null)


        this.closeAccountEditDrawer()

        // if (this.state.Master == null || this.state.Master == '') {
        //     window.alert('Please enter new Master.')
        // }
        // else if (window.confirm('Add New Master?')) {
        //     // this.props.mawbPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, formatStringForAPI(this.state.Master), null, formatNumberForAPI(this.state.TotWeight), formatNumberForAPI(this.state.TotChargeable), formatNumberForAPI(this.state.TotVolume), formatDateForUrlSearch(this.state.MinETADischarge), formatStringForAPI(this.state.CarrierName), formatStringForAPI(this.state.FlightVoyage), formatStringForAPI(this.state.AgentNote), formatNumberForAPI(this.state.CurrentMawbStatusID ))
        //     // this.props.mawbPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, formatStringForAPI(this.state.Master), null, null, null, null, formatDateForUrlSearch(this.state.MinETADischarge), formatStringForAPI(this.state.CarrierName), formatStringForAPI(this.state.FlightVoyage), null, 1)
        // }
    }
    closeAccountEditDrawer() {
        this.setState({
            showAccountDetailsEditDrawer: false,
            AccountAdmin: null,
        });
    }





    //Warehouse Edit drawer
    onClickOpenWarehouseDrawer(e, rowData, history) {

        this.setState({
            showWarehouseEditDrawer: true,
            warehouseIdEditDrawer: rowData['WarehouseID'],
            warehouseNameEditDrawer: rowData['WarehouseName'],
            WarehouseAdmin: rowData['Admin'],
            WarehouseReadWrite: rowData['ReadWrite'],
        });
    }


    onChangeWarehouseEdit = (event, field) => {
        var dict = {};
        dict[field] = event;
        this.setState(
            dict
        );
    };

    onClickConfirmWarehouseEdit(e) {
        var setAdmin = null
        var setPermissions = null

        try {
            if (this.state.WarehouseAdmin == true) {
                setAdmin = 1
            }
            else {
                setAdmin = 0
            }
        }
        catch (e) { }
        try {
            if (this.state.WarehouseReadWrite == true) {
                setPermissions = 1
            }
            else {
                setPermissions = 0
            }
        }
        catch (e) { }

        this.props.userWarehousesPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.AllUsersData[0].UserID, this.state.warehouseIdEditDrawer, setAdmin, setPermissions, null)

        this.closeWarehouseEditDrawer()

    }
    closeWarehouseEditDrawer() {
        this.setState({
            showWarehouseEditDrawer: false,
            warehouseIdEditDrawer: null,
            warehouseNameEditDrawer: null,
            WarehouseAdmin: null,
            WarehouseReadWrite: null
        });
    }


    onClickDeleteWarehouse(e, rowData, keyColumn) {
        if (window.confirm('Confirm: Delete user from warehouse?')) {
            this.props.userWarehousesPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.AllUsersData[0].UserID, rowData['WarehouseID'], null, null, 1)
        }
    }

    //Add warehouse
    onChangeSelectAddWarehouse(e) {
        this.setState({
            addWarehouseSelection: e
        });
    }

    onClickAddWarehouse(e) {
        if (this.state.addWarehouseSelection == null) {
            window.alert('Choose Warehouse.')

        }

        else {
            let newWarehouseName = this.state.warehouseArray.filter(x => x.value === this.state.addWarehouseSelection)

            this.setState({
                showWarehouseEditDrawer: true,
                warehouseIdEditDrawer: this.state.addWarehouseSelection,
                warehouseNameEditDrawer: newWarehouseName[0]['label'],
                WarehouseAdmin: false,
                WarehouseReadWrite: false,
            });
        }
    }







    //Customer Edit drawer
    onClickOpenCustomerDrawer(e, rowData, history) {

        this.setState({
            showCustomerEditDrawer: true,
            customerIdEditDrawer: rowData['CustomerID'],
            customerNameEditDrawer: rowData['CustomerName'],
            CustomerAdmin: rowData['Admin'],
            CustomerReadWrite: rowData['ReadWrite'],
        });
    }


    onChangeCustomerEdit = (event, field) => {
        var dict = {};
        dict[field] = event;
        this.setState(
            dict
        );
    };

    onClickConfirmCustomerEdit(e) {
        var setAdmin = null
        var setPermissions = null

        try {
            if (this.state.CustomerAdmin == true) {
                setAdmin = 1
            }
            else {
                setAdmin = 0
            }
        }
        catch (e) { }
        try {
            if (this.state.CustomerReadWrite == true) {
                setPermissions = 1
            }
            else {
                setPermissions = 0
            }
        }
        catch (e) { }

        this.props.userCustomersPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.AllUsersData[0].UserID, this.state.customerIdEditDrawer, setAdmin, setPermissions, null)

        this.closeCustomerEditDrawer()

    }
    closeCustomerEditDrawer() {
        this.setState({
            showCustomerEditDrawer: false,
            customerIdEditDrawer: null,
            customerNameEditDrawer: null,
            CustomerAdmin: null,
            CustomerReadWrite: null
        });
    }


    onClickDeleteCustomer(e, rowData, keyColumn) {
        if (window.confirm('Confirm: Delete user from customer?')) {
            this.props.userCustomersPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.AllUsersData[0].UserID, rowData['CustomerID'], null, null, 1)
        }
    }

    //Add customer
    onChangeSelectAddCustomer(e) {
        this.setState({
            addCustomerSelection: e
        });
    }

    onClickAddCustomer(e) {
        if (this.state.addCustomerSelection == null) {
            window.alert('Choose Customer.')

        }

        else {
            let newCustomerName = this.state.customerArray.filter(x => x.value === this.state.addCustomerSelection)

            this.setState({
                showCustomerEditDrawer: true,
                customerIdEditDrawer: this.state.addCustomerSelection,
                customerNameEditDrawer: newCustomerName[0]['label'],
                CustomerAdmin: false,
                CustomerReadWrite: false,
            });
        }
    }







    //Module Edit drawer
    onClickOpenModuleDrawer(e, rowData, history) {

        this.setState({
            showModuleEditDrawer: true,
            moduleIdEditDrawer: rowData['ModuleID'],
            moduleNameEditDrawer: rowData['ModuleName'],
            ModuleAdmin: rowData['Admin'],
            ModuleReadWrite: rowData['ReadWrite'],
        });
    }


    onChangeModuleEdit = (event, field) => {
        var dict = {};
        dict[field] = event;
        this.setState(
            dict
        );
    };

    onClickConfirmModuleEdit(e) {
        var setAdmin = null
        var setPermissions = null

        try {
            if (this.state.ModuleAdmin == true) {
                setAdmin = 1
            }
            else {
                setAdmin = 0
            }
        }
        catch (e) { }
        try {
            if (this.state.ModuleReadWrite == true) {
                setPermissions = 1
            }
            else {
                setPermissions = 0
            }
        }
        catch (e) { }

        this.props.userModulesPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.AllUsersData[0].UserID, this.state.moduleIdEditDrawer, setAdmin, setPermissions, null)

        this.closeModuleEditDrawer()

    }
    closeModuleEditDrawer() {
        this.setState({
            showModuleEditDrawer: false,
            moduleIdEditDrawer: null,
            moduleNameEditDrawer: null,
            ModuleAdmin: null,
            ModuleReadWrite: null
        });
    }


    onClickDeleteModule(e, rowData, keyColumn) {
        if (window.confirm('Confirm: Delete user from module?')) {
            this.props.userModulesPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.AllUsersData[0].UserID, rowData['ModuleID'], null, null, 1)
        }
    }

    //Add module
    onChangeSelectAddModule(e) {
        this.setState({
            addModuleSelection: e
        });
    }

    onClickAddModule(e) {
        if (this.state.addModuleSelection == null) {
            window.alert('Choose Module.')

        }

        else {
            let newModuleName = this.state.moduleArray.filter(x => x.value === this.state.addModuleSelection)

            this.setState({
                showModuleEditDrawer: true,
                moduleIdEditDrawer: this.state.addModuleSelection,
                moduleNameEditDrawer: newModuleName[0]['label'],
                ModuleAdmin: false,
                ModuleReadWrite: false,
            });
        }
    }







    render() {
        const { checkedKeys, sortColumn, sortType } = this.state;


        if (typeof (this.props.AllUsersData[0]) === 'undefined' || this.props.AllUsersData === null || this.props.AllUsersData.length == 0) {
            return <AnimatedView><Message style={{ marginTop: '25px' }} type="info" description="User does not exist or has been deleted." /></AnimatedView>;
        }




        let AccountDetailsDiv;

        if (this.props.isAllUsersLoading) {
            AccountDetailsDiv = <div align="center"><Loader /></div>
        }
        else if (typeof (this.props.AllUsersData[0]) !== 'undefined' && this.props.AllUsersData != null) {


            let adminButtons;
            if (this.props.userSignInData.Admin) {
                adminButtons = <div>
                    <Col md={12} sm={24} >
                        {RButtonPrimary(this.props.isMobile, 'Edit Account', this.onClickButtonEdit)}
                    </Col>
                    <Col md={12} sm={24} >
                        {RButtonPrimary(this.props.isMobile, 'Delete Account', this.onClickButtonDelete)}
                    </Col>
                </div>
            }
            else {
                adminButtons = <div>
                    <Col md={24} sm={24} >
                        {RButtonPrimary(this.props.isMobile, 'Delete Account', this.onClickButtonDelete)}
                    </Col>
                </div>
            }



            AccountDetailsDiv = <div>

                <Row>
                    <Col md={24} sm={24} >
                        <Panel bordered style={{ marginBottom: '10px' }}>
                            <Row>
                                {adminButtons}
                            </Row>
                        </Panel>
                    </Col>
                </Row>

                <PanelGroup>
                    <Row style={{ marginBottom: 10 }}>
                        <Col md={12} sm={24}>
                            <Panel bordered header="Personal Information" style={{ marginBottom: '10px' }}>
                                First: {this.props.AllUsersData[0].FirstName}
                                <br />
                                Last: {this.props.AllUsersData[0].LastName}
                                <br />
                            </Panel>
                        </Col>
                        <Col md={12} sm={24}>
                            <Panel bordered header="Account Information" style={{ marginBottom: '10px' }}>
                                Email: {this.props.AllUsersData[0].EmailAddr}
                                <br />
                                Admin: {this.props.AllUsersData[0].Admin === true ? "True" : (this.props.AllUsersData[0].Admin === false ? "False" : this.props.AllUsersData[0].Admin)}
                                <br />
                                {/* Permissions: {this.props.AllUsersData[0].RoleName} */}
                            </Panel>
                        </Col>
                    </Row>
                </PanelGroup>
            </div>
        }

        else {
            AccountDetailsDiv = <div></div>
        }


        let addWarehouseDiv;
        let warehouseDiv;
        if (this.props.isAccountDetailsUserWarehouseLoading) {
            warehouseDiv = <div align="center"><Loader /></div>
        }
        else if (typeof (this.props.AccountDetailsUserWarehouseData) !== 'undefined' && this.props.AccountDetailsUserWarehouseData != null) {

            var userWarehouseIDs = [];
            for (var key in this.props.AccountDetailsUserWarehouseData) {
                userWarehouseIDs.push(this.props.AccountDetailsUserWarehouseData[key]['value']);
            }
            var newWarehouseIDs = [];
            var newKey = 0
            for (var key in this.state.warehouseArray) {
                if (userWarehouseIDs.includes(this.state.warehouseArray[key]['value'])) {
                }
                else {
                    newWarehouseIDs[newKey] = this.state.warehouseArray[key]
                    newKey += 1
                }
            }


            if (newWarehouseIDs.length > 0) {
                addWarehouseDiv = <div style={{ float: 'right' }}>
                    <InputPicker
                        preventOverflow
                        placeholder={'Select...'}
                        value={this.state.addWarehouseSelection}
                        onChange={(e) => { this.onChangeSelectAddWarehouse(e) }}
                        data={newWarehouseIDs}
                        cleanable={true}
                    />
                    <Button style={{ marginLeft: '10px' }} onClick={(e) => { this.onClickAddWarehouse(e) }} >Add Warehouse</Button>
                </div>
            }
            else {
                addWarehouseDiv = <div></div>
            }

            warehouseDiv = <div style={{ marginBottom: '10px' }}>
                <Panel bordered style={{ marginBottom: '10px' }} header={<Row>Warehouse {addWarehouseDiv}</Row>}>

                    {RTableGeneral(
                        tableIdCol,     //   keyColumn,
                        this.props.AccountDetailsUserWarehouseData,     //   tableData,
                        UserAccountWarehouseDataElements,       //   DataElements,
                        this.props.isAccountDetailsUserWarehouseLoading,        //   loading,

                        this.props.isMobile,        //   isMobile,
                        this.props.history,     //   history,

                        sortColumn,     //   sortColumn,
                        sortType,       //   sortType,
                        this.handleSortColumn,      //   handleSortColumn,

                        false,      //   checkColEntry,
                        null,       //   checkedKeys,
                        null,       //   handleCheckAll,
                        null,       //   handleCheck,

                        false,      //   boolSelectButton,
                        null,       //   onClickSelect,
                        null,       //   selectButtonText,
                        null,       //   selectHighlightRowKey,

                        true,       //   link1bool,
                        'Edit',     //   link1text,
                        this.onClickOpenWarehouseDrawer,     //   link1onClick,

                        true,       //   link2bool,
                        'Delete',       //   link2text,
                        this.onClickDeleteWarehouse  //   link2onClick

                    )}

                </Panel>
            </div>
        }
        else {
            warehouseDiv = <div></div>
        }





        let addCustomerDiv;
        let customerDiv;
        if (this.props.isAccountDetailsUserCustomerLoading) {
            customerDiv = <div align="center"><Loader /></div>
        }
        else if (typeof (this.props.AccountDetailsUserCustomerData) !== 'undefined' && this.props.AccountDetailsUserCustomerData != null) {

            var userCustomerIDs = [];
            for (var key in this.props.AccountDetailsUserCustomerData) {
                userCustomerIDs.push(this.props.AccountDetailsUserCustomerData[key]['value']);
            }
            var newCustomerIDs = [];
            var newKey = 0
            for (var key in this.state.customerArray) {
                if (userCustomerIDs.includes(this.state.customerArray[key]['value'])) {
                }
                else {
                    newCustomerIDs[newKey] = this.state.customerArray[key]
                    newKey += 1
                }
            }


            if (newCustomerIDs.length > 0) {
                addCustomerDiv = <div style={{ float: 'right' }}>
                    <InputPicker
                        preventOverflow
                        placeholder={'Select...'}
                        value={this.state.addCustomerSelection}
                        onChange={(e) => { this.onChangeSelectAddCustomer(e) }}
                        data={newCustomerIDs}
                        cleanable={true}
                    />
                    <Button style={{ marginLeft: '10px' }} onClick={(e) => { this.onClickAddCustomer(e) }} >Add Customer</Button>
                </div>
            }
            else {
                addCustomerDiv = <div></div>
            }

            customerDiv = <div style={{ marginBottom: '10px' }}>
                <Panel bordered style={{ marginBottom: '10px' }} header={<Row>Customer {addCustomerDiv}</Row>}>

                    {RTableGeneral(
                        tableIdCol,     //   keyColumn,
                        this.props.AccountDetailsUserCustomerData,     //   tableData,
                        UserAccountCustomerDataElements,       //   DataElements,
                        this.props.isAccountDetailsUserCustomerLoading,        //   loading,

                        this.props.isMobile,        //   isMobile,
                        this.props.history,     //   history,

                        sortColumn,     //   sortColumn,
                        sortType,       //   sortType,
                        this.handleSortColumn,      //   handleSortColumn,

                        false,      //   checkColEntry,
                        null,       //   checkedKeys,
                        null,       //   handleCheckAll,
                        null,       //   handleCheck,

                        false,      //   boolSelectButton,
                        null,       //   onClickSelect,
                        null,       //   selectButtonText,
                        null,       //   selectHighlightRowKey,

                        true,       //   link1bool,
                        'Edit',     //   link1text,
                        this.onClickOpenCustomerDrawer,     //   link1onClick,

                        true,       //   link2bool,
                        'Delete',       //   link2text,
                        this.onClickDeleteCustomer  //   link2onClick

                    )}

                </Panel>
            </div>
        }
        else {
            customerDiv = <div></div>
        }




        let addModuleDiv;
        let moduleDiv;
        if (this.props.isAccountDetailsUserModuleLoading) {
            moduleDiv = <div align="center"><Loader /></div>
        }
        else if (typeof (this.props.AccountDetailsUserModuleData) !== 'undefined' && this.props.AccountDetailsUserModuleData != null) {

            var userModuleIDs = [];
            for (var key in this.props.AccountDetailsUserModuleData) {
                userModuleIDs.push(this.props.AccountDetailsUserModuleData[key]['value']);
            }
            var newModuleIDs = [];
            var newKey = 0
            for (var key in this.state.moduleArray) {
                if (userModuleIDs.includes(this.state.moduleArray[key]['value'])) {
                }
                else {
                    newModuleIDs[newKey] = this.state.moduleArray[key]
                    newKey += 1
                }
            }


            if (newModuleIDs.length > 0) {
                addModuleDiv = <div style={{ float: 'right' }}>
                    <InputPicker
                        preventOverflow
                        placeholder={'Select...'}
                        value={this.state.addModuleSelection}
                        onChange={(e) => { this.onChangeSelectAddModule(e) }}
                        data={newModuleIDs}
                        cleanable={true}
                    />
                    <Button style={{ marginLeft: '10px' }} onClick={(e) => { this.onClickAddModule(e) }} >Add Module</Button>
                </div>
            }
            else {
                addModuleDiv = <div></div>
            }

            moduleDiv = <div style={{ marginBottom: '10px' }}>
                <Panel bordered style={{ marginBottom: '10px' }} header={<Row>Module {addModuleDiv}</Row>}>

                    {RTableGeneral(
                        tableIdCol,     //   keyColumn,
                        this.props.AccountDetailsUserModuleData,     //   tableData,
                        UserAccountModuleDataElements,       //   DataElements,
                        this.props.isAccountDetailsUserModuleLoading,        //   loading,

                        this.props.isMobile,        //   isMobile,
                        this.props.history,     //   history,

                        sortColumn,     //   sortColumn,
                        sortType,       //   sortType,
                        this.handleSortColumn,      //   handleSortColumn,

                        false,      //   checkColEntry,
                        null,       //   checkedKeys,
                        null,       //   handleCheckAll,
                        null,       //   handleCheck,

                        false,      //   boolSelectButton,
                        null,       //   onClickSelect,
                        null,       //   selectButtonText,
                        null,       //   selectHighlightRowKey,

                        true,       //   link1bool,
                        'Edit',     //   link1text,
                        this.onClickOpenModuleDrawer,     //   link1onClick,

                        true,       //   link2bool,
                        'Delete',       //   link2text,
                        this.onClickDeleteModule  //   link2onClick

                    )}

                </Panel>
            </div>
        }
        else {
            moduleDiv = <div></div>
        }



        return (
            <div>

                {AccountDetailsDiv}

                {moduleDiv}

                {warehouseDiv}

                {customerDiv}



                <AccountDetailsEditDrawer {...this.props} state={this.state} title={'Edit Account'} onChangeAccountEdit={this.onChangeAccountEdit} confirm={this.onClickConfirmAccountEdit} close={this.closeAccountEditDrawer} />

                <WarehouseEditDrawer {...this.props} state={this.state} title={this.state.warehouseNameEditDrawer} onChangeAccountEdit={this.onChangeWarehouseEdit} confirm={this.onClickConfirmWarehouseEdit} close={this.closeWarehouseEditDrawer} />

                <CustomerEditDrawer {...this.props} state={this.state} title={this.state.customerNameEditDrawer} onChangeAccountEdit={this.onChangeCustomerEdit} confirm={this.onClickConfirmCustomerEdit} close={this.closeCustomerEditDrawer} />

                <ModuleEditDrawer {...this.props} state={this.state} title={this.state.moduleNameEditDrawer} onChangeAccountEdit={this.onChangeModuleEdit} confirm={this.onClickConfirmModuleEdit} close={this.closeModuleEditDrawer} />

            </div>

        );
    }
}

export default withRouter(AccountDetails);






















