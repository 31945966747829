import * as types from '../constants/inventoryActionTypes';

/* ################################### INVENTORY ACTIONS ################################### */

export const inventoryDestinationGet = (_email, _authToken, _WarehouseID, _CustomerID,) =>
({
    type: types.INVENTORY_DESTINATION_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
    }
});

export const inventoryCustomerRefGet = (_email, _authToken, _WarehouseID, _CustomerID,) =>
({
    type: types.INVENTORY_CUSTOMERREF_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
    }
});

export const inventoryProjectGet = (_email, _authToken, _WarehouseID, _CustomerID,) =>
({
    type: types.INVENTORY_PROJECT_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
    }
});

export const inventorySkuGet = (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID,) =>
({
    type: types.INVENTORY_SKU_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        SkuID: _SkuID,
        SKU: _SKU,
        ContainerID: _ContainerID,
        Container: _Container,
        SkuStatusID: _SkuStatusID,
        WarehouseLocationID: _WarehouseLocationID,
    }
});

export const inventoryLotSkuGet = (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID,) =>
({
    type: types.INVENTORY_LOT_SKU_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        SkuID: _SkuID,
        SKU: _SKU,
        ContainerID: _ContainerID,
        Container: _Container,
        SkuStatusID: _SkuStatusID,
        WarehouseLocationID: _WarehouseLocationID,
    }
});


export const inventorySkuPost = (_email, _authToken, _SkuID, _SKU, _ContainerID, _WarehouseID, _CustomerID, _CurrentSkuStatusID, _CustomerRefID, _CustomerRef, _ProjectID, _Project, _Notes) =>
({
    type: types.INVENTORY_POST_SKU_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        SkuID: _SkuID,
        SKU: _SKU,
        ContainerID: _ContainerID,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        CurrentSkuStatusID: _CurrentSkuStatusID,

        CustomerRefID: _CustomerRefID,
        CustomerRef: _CustomerRef,
        ProjectID: _ProjectID,
        Project: _Project,
        Notes: _Notes

    }
});

export const inventoryLotContainerGet = (_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _Container, _ContainerStatusID, _SkuID, _SKU, _WarehouseLocationID,) =>
({
    type: types.INVENTORY_LOT_CONTAINER_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        ContainerID: _ContainerID,
        Container: _Container,
        ContainerStatusID: _ContainerStatusID,
        SkuID: _SkuID,
        SKU: _SKU,
        WarehouseLocationID: _WarehouseLocationID,
    }
});


export const inventoryContainerGet = (_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _Container, _ContainerStatusID, _SkuID, _SKU, _WarehouseLocationID,) =>
({
    type: types.INVENTORY_CONTAINER_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        ContainerID: _ContainerID,
        Container: _Container,
        ContainerStatusID: _ContainerStatusID,
        SkuID: _SkuID,
        SKU: _SKU,
        WarehouseLocationID: _WarehouseLocationID,
    }
});

export const inventoryContainerPost = (_email, _authToken, _ContainerID, _Container, _LCL_FCL, _DeliveryAppointment, _ETD, _ETA, _CurrentContainerStatusID, _Notes, _WarehouseID, _CustomerID, _Palletized) =>
({
    type: types.INVENTORY_POST_CONTAINER_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        ContainerID: _ContainerID,
        Container: _Container,
        LCL_FCL: _LCL_FCL,
        DeliveryAppointment: _DeliveryAppointment,
        ETD: _ETD,
        ETA: _ETA,
        CurrentContainerStatusID: _CurrentContainerStatusID,
        Notes: _Notes,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        Palletized: _Palletized
    }
});

export const inventoryContainerPost2 = (_email, _authToken, _ContainerID, _Container, _LCL_FCL, _DeliveryAppointment, _ETD, _ETA, _CurrentContainerStatusID, _Notes, _WarehouseID, _CustomerID, _Palletized) =>
({
    type: types.INVENTORY_POST_CONTAINER_GET2,
    payload: {
        email: _email,
        authToken: _authToken,

        ContainerID: _ContainerID,
        Container: _Container,
        LCL_FCL: _LCL_FCL,
        DeliveryAppointment: _DeliveryAppointment,
        ETD: _ETD,
        ETA: _ETA,
        CurrentContainerStatusID: _CurrentContainerStatusID,
        Notes: _Notes,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        Palletized: _Palletized
    }
});


export const inventoryContainerStatusPost = (_email, _authToken, _ContainerID, _ContainerStatusID) =>
({
    type: types.INVENTORY_POST_CONTAINER_STATUS_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        ContainerID: _ContainerID,
        ContainerStatusID: _ContainerStatusID,
    }
});


export const inventoryContainerHistoryGet = (_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _Container, _SkuID, _SKU,) =>
({
    type: types.INVENTORY_CONTAINER_HISTORY_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        ContainerID: _ContainerID,
        Container: _Container,
        SkuID: _SkuID,
        SKU: _SKU,
    }
});

export const inventoryContainerSkuGet = (_email, _authToken, _ContainerID, _SkuID,) =>
({
    type: types.INVENTORY_CONTAINER_SKU_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        ContainerID: _ContainerID,
        SkuID: _SkuID,
    }
});

export const inventoryContainerSkuPost = (_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _SkuID, _SKU, _Units, _Cases, _Pallets, _Delete, _CustomerRefID, _CustomerRef, _ProjectID, _Project, _Notes) =>
({
    type: types.INVENTORY_POST_CONTAINER_SKU_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        ContainerID: _ContainerID,
        SkuID: _SkuID,
        SKU: _SKU,
        Units: _Units,
        Cases: _Cases,
        Pallets: _Pallets,
        Delete: _Delete,

        CustomerRefID: _CustomerRefID,
        CustomerRef: _CustomerRef,
        ProjectID: _ProjectID,
        Project: _Project,
        Notes: _Notes
    }
});



export const inventorySkuRecoverGet = (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container,) =>
({
    type: types.INVENTORY_SKU_RECOVERY_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        SkuID: _SkuID,
        SKU: _SKU,
        ContainerID: _ContainerID,
        Container: _Container
    }
});

export const inventorySkuRecoverHistoryGet = (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container,) =>
({
    type: types.INVENTORY_SKU_RECOVERY_HISTORY_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        SkuID: _SkuID,
        SKU: _SKU,
        ContainerID: _ContainerID,
        Container: _Container
    }
});

export const inventorySkuRecoveryPost = (_email, _authToken, _SkuID, _ContainerID, _Container, _SkuDescription, _UnitsReceived, _CasesReceived, _PalletsReceived, _Oversize, _CustomsCleared, _RecoveryDate) =>
({
    type: types.INVENTORY_POST_SKU_RECOVERY_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        SkuID: _SkuID,
        ContainerID: _ContainerID,
        Container: _Container,
        SkuDescription: _SkuDescription,
        UnitsReceived: _UnitsReceived,
        CasesReceived: _CasesReceived,
        PalletsReceived: _PalletsReceived,
        Oversize: _Oversize,
        CustomsCleared: _CustomsCleared,
        RecoveryDate: _RecoveryDate
    }
});



export const inventorySkuWarehouseLocGet = (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID,) =>
({
    type: types.INVENTORY_SKU_WAREHOUSE_LOC_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        SkuID: _SkuID,
        SKU: _SKU,
        ContainerID: _ContainerID,
        Container: _Container,
        SkuStatusID: _SkuStatusID,
        WarehouseLocationID: _WarehouseLocationID,
    }
});


export const inventorySkuWarehouseLocationPost = (_email, _authToken, _SkuID, _SkuWarehouseLocationID, _WarehouseLocationID, _Units, _Cases, _Pallets, _Oversize, _PreviousWarehouseLocationID, _WarehouseID, _CustomerID, _SkuRecovery, _RecoveryContainerID, _RecoveryDate, _SkuDescription) =>
({
    type: types.INVENTORY_POST_SKU_WAREHOUSE_LOCATION_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        SkuID: _SkuID,
        SkuWarehouseLocationID: _SkuWarehouseLocationID,
        WarehouseLocationID: _WarehouseLocationID,
        Units: _Units,
        Cases: _Cases,
        Pallets: _Pallets,
        Oversize: _Oversize,
        PreviousWarehouseLocationID: _PreviousWarehouseLocationID,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        SkuRecovery: _SkuRecovery,
        RecoveryContainerID: _RecoveryContainerID,
        RecoveryDate: _RecoveryDate,
        SkuDescription: _SkuDescription
    }
});


export const inventorySkuWarehouseLocationHistoryGet = (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID,) =>
({
    type: types.INVENTORY_SKU_WAREHOUSE_LOCATION_HISTORY_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        SkuID: _SkuID,
        SKU: _SKU,
        ContainerID: _ContainerID,
        Container: _Container,
        SkuStatusID: _SkuStatusID,
        WarehouseLocationID: _WarehouseLocationID,
    }
});

export const inventoryWarehouseLocationGet = (_email, _authToken, _WarehouseID, _CustomerID, _WarehouseLocationID) =>
({
    type: types.INVENTORY_WAREHOUSE_LOCATION_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        WarehouseLocationID: _WarehouseLocationID,
    }
});


export const inventoryLoadGet = (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _LoadStatusID, _ContainerID, _SkuID, _CustomerRefID, _PickupDateStart, _PickupDateEnd, _DeliveryDateStart, _DeliveryDateEnd, _Carrier, _BOL, _Trailer, _Seal, _DestinationID,) =>
({
    type: types.INVENTORY_LOAD_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        LoadID: _LoadID,
        LoadStatusID: _LoadStatusID,
        ContainerID: _ContainerID,
        SkuID: _SkuID,
        CustomerRefID: _CustomerRefID,
        PickupDateStart: _PickupDateStart,
        PickupDateEnd: _PickupDateEnd,
        DeliveryDateStart: _DeliveryDateStart,
        DeliveryDateEnd: _DeliveryDateEnd,
        Carrier: _Carrier,
        BOL: _BOL,
        Trailer: _Trailer,
        Seal: _Seal,
        DestinationID: _DestinationID,
    }
});


export const inventoryLoadPost = (_email, _authToken, _LoadID, _PickupDate, _DeliveryDate, _Carrier, _BOL, _Trailer, _Seal, _TotalUnits, _TotalCases, _TotalPallets, _Destination, _DestinationID, _LoadStatusID, _LoadSkuData, _LocationSkuData, _WarehouseLocationID, _SkuWarehouseLocationID, _LoadSkuID, _SkuID, _Units, _Cases, _Pallets, _Oversize, _WarehouseID, _CustomerID,) =>
({
    type: types.INVENTORY_POST_LOAD_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        LoadID: _LoadID,
        PickupDate: _PickupDate,
        DeliveryDate: _DeliveryDate,
        Carrier: _Carrier,
        BOL: _BOL,
        Trailer: _Trailer,
        Seal: _Seal,
        TotalUnits: _TotalUnits,
        TotalCases: _TotalCases,
        TotalPallets: _TotalPallets,
        Destination: _Destination,
        DestinationID: _DestinationID,
        LoadStatusID: _LoadStatusID,
        LoadSkuData: _LoadSkuData,
        LocationSkuData: _LocationSkuData,

        WarehouseLocationID: _WarehouseLocationID,
        SkuWarehouseLocationID: _SkuWarehouseLocationID,

        LoadSkuID: _LoadSkuID,
        SkuID: _SkuID,
        Units: _Units,
        Cases: _Cases,
        Pallets: _Pallets,
        Oversize: _Oversize,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
    }
});

export const inventoryLoadRemoveSkuPost = (_email, _authToken, _WarehouseID, _CustomerID, _LoadSkuID, _LoadID, _SkuID, _Units, _Cases, _Pallets, _Oversize, _WarehouseLocationID, _SkuWarehouseLocationID, _WarehouseLocations,) =>
({
    type: types.INVENTORY_POST_LOAD_REMOVE_SKU_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        LoadSkuID: _LoadSkuID,
        LoadID: _LoadID,
        SkuID: _SkuID,
        Units: _Units,
        Cases: _Cases,
        Pallets: _Pallets,
        Oversize: _Oversize,
        WarehouseLocationID: _WarehouseLocationID,
        SkuWarehouseLocationID: _SkuWarehouseLocationID,
        WarehouseLocations: _WarehouseLocations,
    }
});

export const inventoryLoadSkuGet = (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _SkuID) =>
({
    type: types.INVENTORY_LOAD_SKU_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        LoadID: _LoadID,
        SkuID: _SkuID
    }
});

export const inventoryLoadSkuHistoryGet = (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _SkuID) =>
({
    type: types.INVENTORY_LOAD_SKU_HISTORY_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        LoadID: _LoadID,
        SkuID: _SkuID,
    }
});

export const inventoryLoadSkuLocationHistoryGet = (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _SkuID, _Oversize) =>
({
    type: types.INVENTORY_LOAD_SKU_LOCATION_HISTORY_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        LoadID: _LoadID,
        SkuID: _SkuID,
        Oversize: _Oversize
    }
});


export const inventoryOutboundGet = (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _ContainerID, _Container, _SkuID, _SKU, _DepartDateStart, _DepartDateEnd, _DestinationID, _DestinationName, _Seal, _Carrier, _Trailer, _BOL,) =>
({
    type: types.INVENTORY_OUTBOUND_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        LoadID: _LoadID,
        ContainerID: _ContainerID,
        Container: _Container,
        SkuID: _SkuID,
        SKU: _SKU,
        DepartDateStart: _DepartDateStart,
        DepartDateEnd: _DepartDateEnd,
        DestinationID: _DestinationID,
        DestinationName: _DestinationName,
        Seal: _Seal,
        Carrier: _Carrier,
        Trailer: _Trailer,
        BOL: _BOL,
    }
});


export const inventoryReportingInboundGet = (_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _Container, _ContainerStatusID, _SkuID, _SKU, _StartRecoveryDt, _EndRecoveryDt,) =>
({
    type: types.INVENTORY_REPORTING_INBOUND_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        ContainerID: _ContainerID,
        Container: _Container,
        ContainerStatusID: _ContainerStatusID,
        SkuID: _SkuID,
        SKU: _SKU,
        StartRecoveryDt: _StartRecoveryDt,
        EndRecoveryDt: _EndRecoveryDt,
    }
});


export const inventoryReportingStorageGet = (_email, _authToken, _WarehouseID, _CustomerID, _StartDT, _EndDT,) =>
({
    type: types.INVENTORY_REPORTING_STORAGE_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        StartDT: _StartDT,
        EndDT: _EndDT,
    }
});



export const inventoryRecoveryPost = (_email, _authToken, _WarehouseID, _CustomerID, _DataJson, _ContainerID, _CustomsCleared, _Palletized, _RecoveryDate,) =>
({
    type: types.INVENTORY_POST_RECOVERY_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        DataJson: _DataJson,
        ContainerID: _ContainerID,
        CustomsCleared: _CustomsCleared,
        Palletized: _Palletized,
        RecoveryDate: _RecoveryDate,
    }
});


export const inventorySkuWarehouseLocationGroupedGet = (_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _CaseOrUnitSkuID) =>
({
    type: types.INVENTORY_SKU_WAREHOUSE_LOCATION_GROUPED_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseLocationID: _WarehouseLocationID,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        PalletStatusID: _PalletStatusID,
        CaseStatusID: _CaseStatusID,
        SkuStatusID: _SkuStatusID,
        PalletID: _PalletID,
        PalletBarcode: _PalletBarcode,
        CaseID: _CaseID,
        CaseBarcode: _CaseBarcode,
        CaseSkuID: _CaseSkuID,
        CaseSku: _CaseSku,
        CaseLotCode: _CaseLotCode,
        CaseSerialNumber: _CaseSerialNumber,
        SkuID: _SkuID,
        SKU: _SKU,
        SkuLotCode: _SkuLotCode,
        SkuSerialNumber: _SkuSerialNumber,
        ContainerID: _ContainerID,
        Container: _Container,
        RecoveryDateStart: _RecoveryDateStart,
        RecoveryDateEnd: _RecoveryDateEnd,
        CaseOrUnitSkuID: _CaseOrUnitSkuID
    }
});

export const inventoryLotSkuWarehouseLocationGet = (_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _CaseOrUnitSkuID) =>
({
    type: types.INVENTORY_SKU_WAREHOUSE_LOCATION_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseLocationID: _WarehouseLocationID,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        PalletStatusID: _PalletStatusID,
        CaseStatusID: _CaseStatusID,
        SkuStatusID: _SkuStatusID,
        PalletID: _PalletID,
        PalletBarcode: _PalletBarcode,
        CaseID: _CaseID,
        CaseBarcode: _CaseBarcode,
        CaseSkuID: _CaseSkuID,
        CaseSku: _CaseSku,
        CaseLotCode: _CaseLotCode,
        CaseSerialNumber: _CaseSerialNumber,
        SkuID: _SkuID,
        SKU: _SKU,
        SkuLotCode: _SkuLotCode,
        SkuSerialNumber: _SkuSerialNumber,
        ContainerID: _ContainerID,
        Container: _Container,
        RecoveryDateStart: _RecoveryDateStart,
        RecoveryDateEnd: _RecoveryDateEnd,
        CaseOrUnitSkuID: _CaseOrUnitSkuID
    }
});


export const inventoryUnitWarehouseLocationGroupedGet = (_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _CaseOrUnitSkuID) =>
({
    type: types.INVENTORY_UNIT_WAREHOUSE_LOCATION_GROUPED_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseLocationID: _WarehouseLocationID,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        PalletStatusID: _PalletStatusID,
        CaseStatusID: _CaseStatusID,
        SkuStatusID: _SkuStatusID,
        PalletID: _PalletID,
        PalletBarcode: _PalletBarcode,
        CaseID: _CaseID,
        CaseBarcode: _CaseBarcode,
        CaseSkuID: _CaseSkuID,
        CaseSku: _CaseSku,
        CaseLotCode: _CaseLotCode,
        CaseSerialNumber: _CaseSerialNumber,
        SkuID: _SkuID,
        SKU: _SKU,
        SkuLotCode: _SkuLotCode,
        SkuSerialNumber: _SkuSerialNumber,
        ContainerID: _ContainerID,
        Container: _Container,
        RecoveryDateStart: _RecoveryDateStart,
        RecoveryDateEnd: _RecoveryDateEnd,
        CaseOrUnitSkuID: _CaseOrUnitSkuID
    }
});

export const inventoryCaseWarehouseLocationGroupedGet = (_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd,) =>
({
    type: types.INVENTORY_CASE_WAREHOUSE_LOCATION_GROUPED_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseLocationID: _WarehouseLocationID,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        PalletStatusID: _PalletStatusID,
        CaseStatusID: _CaseStatusID,
        SkuStatusID: _SkuStatusID,
        PalletID: _PalletID,
        PalletBarcode: _PalletBarcode,
        CaseID: _CaseID,
        CaseBarcode: _CaseBarcode,
        CaseSkuID: _CaseSkuID,
        CaseLotCode: _CaseLotCode,
        CaseSerialNumber: _CaseSerialNumber,
        SkuID: _SkuID,
        SKU: _SKU,
        SkuLotCode: _SkuLotCode,
        SkuSerialNumber: _SkuSerialNumber,
        ContainerID: _ContainerID,
        Container: _Container,
        RecoveryDateStart: _RecoveryDateStart,
        RecoveryDateEnd: _RecoveryDateEnd,
    }
});

export const inventoryCaseWarehouseLocationGet = (_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd,) =>
({
    type: types.INVENTORY_CASE_WAREHOUSE_LOCATION_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseLocationID: _WarehouseLocationID,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        PalletStatusID: _PalletStatusID,
        CaseStatusID: _CaseStatusID,
        SkuStatusID: _SkuStatusID,
        PalletID: _PalletID,
        PalletBarcode: _PalletBarcode,
        CaseID: _CaseID,
        CaseBarcode: _CaseBarcode,
        CaseSkuID: _CaseSkuID,
        CaseLotCode: _CaseLotCode,
        CaseSerialNumber: _CaseSerialNumber,
        SkuID: _SkuID,
        SKU: _SKU,
        SkuLotCode: _SkuLotCode,
        SkuSerialNumber: _SkuSerialNumber,
        ContainerID: _ContainerID,
        Container: _Container,
        RecoveryDateStart: _RecoveryDateStart,
        RecoveryDateEnd: _RecoveryDateEnd,
    }
});



export const inventoryCaseWarehouseLocationPost = (_email, _authToken, _WarehouseID, _CustomerID, _CaseID, _CaseBarcode, _NewPalletID, _NewPalletBarcode,) =>
({
    type: types.INVENTORY_POST_CASE_WAREHOUSE_LOCATION_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        CaseID: _CaseID,
        CaseBarcode: _CaseBarcode,
        NewPalletID: _NewPalletID,
        NewPalletBarcode: _NewPalletBarcode,
    }
});


export const inventoryPalletWarehouseLocationGroupedGet = (_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _PalletOversize) =>
({
    type: types.INVENTORY_PALLET_WAREHOUSE_LOCATION_GROUPED_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseLocationID: _WarehouseLocationID,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        PalletStatusID: _PalletStatusID,
        CaseStatusID: _CaseStatusID,
        SkuStatusID: _SkuStatusID,
        PalletID: _PalletID,
        PalletBarcode: _PalletBarcode,
        CaseID: _CaseID,
        CaseBarcode: _CaseBarcode,
        CaseSkuID: _CaseSkuID,
        CaseLotCode: _CaseLotCode,
        CaseSerialNumber: _CaseSerialNumber,
        SkuID: _SkuID,
        SKU: _SKU,
        SkuLotCode: _SkuLotCode,
        SkuSerialNumber: _SkuSerialNumber,
        ContainerID: _ContainerID,
        Container: _Container,
        RecoveryDateStart: _RecoveryDateStart,
        RecoveryDateEnd: _RecoveryDateEnd,
        PalletOversize: _PalletOversize
    }
});


export const inventoryPalletWarehouseLocationGet = (_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _PalletOversize) =>
({
    type: types.INVENTORY_PALLET_WAREHOUSE_LOCATION_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseLocationID: _WarehouseLocationID,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        PalletStatusID: _PalletStatusID,
        CaseStatusID: _CaseStatusID,
        SkuStatusID: _SkuStatusID,
        PalletID: _PalletID,
        PalletBarcode: _PalletBarcode,
        CaseID: _CaseID,
        CaseBarcode: _CaseBarcode,
        CaseSkuID: _CaseSkuID,
        CaseLotCode: _CaseLotCode,
        CaseSerialNumber: _CaseSerialNumber,
        SkuID: _SkuID,
        SKU: _SKU,
        SkuLotCode: _SkuLotCode,
        SkuSerialNumber: _SkuSerialNumber,
        ContainerID: _ContainerID,
        Container: _Container,
        RecoveryDateStart: _RecoveryDateStart,
        RecoveryDateEnd: _RecoveryDateEnd,
        PalletOversize: _PalletOversize
    }
});



export const inventoryPalletWarehouseLocationGet2 = (_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _PalletOversize) =>
({
    type: types.INVENTORY_PALLET_WAREHOUSE_LOCATION_GET2,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseLocationID: _WarehouseLocationID,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        PalletStatusID: _PalletStatusID,
        CaseStatusID: _CaseStatusID,
        SkuStatusID: _SkuStatusID,
        PalletID: _PalletID,
        PalletBarcode: _PalletBarcode,
        CaseID: _CaseID,
        CaseBarcode: _CaseBarcode,
        CaseSkuID: _CaseSkuID,
        CaseLotCode: _CaseLotCode,
        CaseSerialNumber: _CaseSerialNumber,
        SkuID: _SkuID,
        SKU: _SKU,
        SkuLotCode: _SkuLotCode,
        SkuSerialNumber: _SkuSerialNumber,
        ContainerID: _ContainerID,
        Container: _Container,
        RecoveryDateStart: _RecoveryDateStart,
        RecoveryDateEnd: _RecoveryDateEnd,
        PalletOversize: _PalletOversize
    }
});

export const inventoryPalletWarehouseLocationPost = (_email, _authToken, _WarehouseID, _CustomerID, _PalletID, _PalletBarcode, _NewWarehouseLocationID, _Departed, _PalletStatusID, _Oversize, _NewWarehouseLocationBarcode, _NewWarehouseLocationBarcodeNoSpaces) =>
({
    type: types.INVENTORY_POST_PALLET_WAREHOUSE_LOCATION_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        PalletID: _PalletID,
        PalletBarcode: _PalletBarcode,
        NewWarehouseLocationID: _NewWarehouseLocationID,
        Departed: _Departed,
        PalletStatusID: _PalletStatusID,
        Oversize: _Oversize,
        NewWarehouseLocationBarcode: _NewWarehouseLocationBarcode,
        NewWarehouseLocationBarcodeNoSpaces: _NewWarehouseLocationBarcodeNoSpaces

    }
});



export const inventoryLoadPalletCaseSkuPost = (_email, _authToken, _WarehouseID, _CustomerID, _DataJson, _DataType, _LoadID, _PickupDate, _DeliveryDate, _Carrier, _BOL, _Trailer, _Seal, _TotalUnits, _TotalCases, _TotalPallets, _Destination, _DestinationID, _LoadStatusID,) =>
({
    type: types.INVENTORY_POST_LOAD_PALLET_CASE_SKU_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        DataJson: _DataJson,
        DataType: _DataType,
        LoadID: _LoadID,
        PickupDate: _PickupDate,
        DeliveryDate: _DeliveryDate,
        Carrier: _Carrier,
        BOL: _BOL,
        Trailer: _Trailer,
        Seal: _Seal,
        TotalUnits: _TotalUnits,
        TotalCases: _TotalCases,
        TotalPallets: _TotalPallets,
        Destination: _Destination,
        DestinationID: _DestinationID,
        LoadStatusID: _LoadStatusID,
    }
});


export const inventoryLoadPalletCaseUnitGet = (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _SkuID, _CaseSkuID,) =>
({
    type: types.INVENTORY_LOAD_PALLET_CASE_UNIT_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        LoadID: _LoadID,
        PalletID: _PalletID,
        PalletBarcode: _PalletBarcode,
        CaseID: _CaseID,
        CaseBarcode: _CaseBarcode,
        SkuID: _SkuID,
        CaseSkuID: _CaseSkuID,
    }
});


export const inventoryLoadLotGet = (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _LoadStatusID, _ContainerID, _Container, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _CustomerRefID, _PickupDateStart, _PickupDateEnd, _DeliveryDateStart, _DeliveryDateEnd, _Carrier, _BOL, _Trailer, _Seal, _DestinationID,) =>
({
    type: types.INVENTORY_LOAD_LOT_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        LoadID: _LoadID,
        LoadStatusID: _LoadStatusID,
        ContainerID: _ContainerID,
        Container: _Container,
        PalletID: _PalletID,
        PalletBarcode: _PalletBarcode,
        CaseID: _CaseID,
        CaseBarcode: _CaseBarcode,
        CaseSkuID: _CaseSkuID,
        CaseSku: _CaseSku,
        CaseLotCode: _CaseLotCode,
        CaseSerialNumber: _CaseSerialNumber,
        SkuID: _SkuID,
        SKU: _SKU,
        SkuLotCode: _SkuLotCode,
        SkuSerialNumber: _SkuSerialNumber,
        CustomerRefID: _CustomerRefID,
        PickupDateStart: _PickupDateStart,
        PickupDateEnd: _PickupDateEnd,
        DeliveryDateStart: _DeliveryDateStart,
        DeliveryDateEnd: _DeliveryDateEnd,
        Carrier: _Carrier,
        BOL: _BOL,
        Trailer: _Trailer,
        Seal: _Seal,
        DestinationID: _DestinationID,
    }
});





export const inventoryPalletContentsGet = (_email, _authToken, _ContainerID, _PalletID,) =>
({
    type: types.INVENTORY_PALLET_CONTENTS_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        ContainerID: _ContainerID,
        PalletID: _PalletID,
    }
});




export const inventoryLoadBarcodeScanPost = (_email, _authToken, _WarehouseID, _CustomerID, _PalletData, _CaseData, _UnitData, _LoadID, _PickupDate, _DeliveryDate, _Carrier, _BOL, _Trailer, _Seal, _TotalUnits, _TotalCases, _TotalPallets, _Destination, _DestinationID, _LoadStatusID,) =>
({
    type: types.INVENTORY_POST_LOAD_BARCODE_SCAN_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        PalletData: _PalletData,
        CaseData: _CaseData,
        UnitData: _UnitData,
        LoadID: _LoadID,
        PickupDate: _PickupDate,
        DeliveryDate: _DeliveryDate,
        Carrier: _Carrier,
        BOL: _BOL,
        Trailer: _Trailer,
        Seal: _Seal,
        TotalUnits: _TotalUnits,
        TotalCases: _TotalCases,
        TotalPallets: _TotalPallets,
        Destination: _Destination,
        DestinationID: _DestinationID,
        LoadStatusID: _LoadStatusID,
    }
});


export const inventoryPalletCreateNewPost = (_email, _authToken, _WarehouseID, _CustomerID, _DataJson, _WarehouseLocationID, _Oversize, _Notes,) =>
({
    type: types.INVENTORY_POST_PALLET_CREATE_NEW_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        DataJson: _DataJson,
        WarehouseLocationID: _WarehouseLocationID,
        Oversize: _Oversize,
        Notes: _Notes,
    }
});



export const inventorySkuRefGet = (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _SkuStatusID, _ProjectID, _SkuLotCode, _SkuSerialNumber,) =>
({
    type: types.INVENTORY_SKU_REF_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        SkuID: _SkuID,
        SKU: _SKU,
        SkuStatusID: _SkuStatusID,
        ProjectID: _ProjectID,
        SkuLotCode: _SkuLotCode,
        SkuSerialNumber: _SkuSerialNumber,
    }
});


export const inventoryCaseHistoryGet = (_email, _authToken, _WarehouseID, _CustomerID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _ContainerID) =>
({
    type: types.INVENTORY_CASE_HISTORY_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        PalletID: _PalletID,
        PalletBarcode: _PalletBarcode,
        CaseID: _CaseID,
        CaseBarcode: _CaseBarcode,
        CaseSkuID: _CaseSkuID,
        ContainerID: _ContainerID
    }
});

export const inventoryCaseSkusHistoryGet = (_email, _authToken, _WarehouseID, _CustomerID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _UnitSkuID, _ContainerID) =>
({
    type: types.INVENTORY_CASE_SKUS_HISTORY_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        PalletID: _PalletID,
        PalletBarcode: _PalletBarcode,
        CaseID: _CaseID,
        CaseBarcode: _CaseBarcode,
        CaseSkuID: _CaseSkuID,
        UnitSkuID: _UnitSkuID,
        ContainerID: _ContainerID
    }
});

export const inventoryLoadCasesHistoryGet = (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID,) =>
({
    type: types.INVENTORY_LOAD_CASES_HISTORY_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        LoadID: _LoadID,
        PalletID: _PalletID,
        PalletBarcode: _PalletBarcode,
        CaseID: _CaseID,
        CaseBarcode: _CaseBarcode,
        CaseSkuID: _CaseSkuID,
    }
});

export const inventoryLoadPalletsHistoryGet = (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _PalletID, _PalletBarcode,) =>
({
    type: types.INVENTORY_LOAD_PALLETS_HISTORY_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        LoadID: _LoadID,
        PalletID: _PalletID,
        PalletBarcode: _PalletBarcode,
    }
});



export const inventoryLoadSKUsHistoryGet = (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _UnitSkuID,) =>
({
    type: types.INVENTORY_LOAD_SKUS_HISTORY_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        LoadID: _LoadID,
        PalletID: _PalletID,
        PalletBarcode: _PalletBarcode,
        CaseID: _CaseID,
        CaseBarcode: _CaseBarcode,
        CaseSkuID: _CaseSkuID,
        UnitSkuID: _UnitSkuID,
    }
});


export const inventoryPalletWarehouseLocationHistoryGet = (_email, _authToken, _WarehouseID, _CustomerID, _PalletID, _PalletBarcode, _CaseID, _ContainerID) =>
({
    type: types.INVENTORY_PALLET_WAREHOUSE_LOCATION_HISTORY_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        PalletID: _PalletID,
        PalletBarcode: _PalletBarcode,
        CaseID: _CaseID,
        ContainerID: _ContainerID
    }
});


export const inventoryLoadRemovePalletCaseSkuPost = (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _NewPalletID, _CaseSkuID, _UnitsToRemove, _NewCaseID, _DeleteLoad,) =>
({
    type: types.INVENTORY_POST_LOAD_REMOVE_PALLET_CASE_SKU_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        LoadID: _LoadID,
        PalletID: _PalletID,
        PalletBarcode: _PalletBarcode,
        CaseID: _CaseID,
        CaseBarcode: _CaseBarcode,
        NewPalletID: _NewPalletID,
        CaseSkuID: _CaseSkuID,
        UnitsToRemove: _UnitsToRemove,
        NewCaseID: _NewCaseID,
        DeleteLoad: _DeleteLoad,
    }
});









export const inventoryLotBarcodeScanValidateGet = (_email, _authToken, _WarehouseID, _CustomerID, _Barcode) =>
({
    type: types.INVENTORY_LOT_BARCODE_SCAN_VALIDATE_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        Barcode: _Barcode
    }
});

export const inventoryLotOutboundGet = (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _DepartDateStart, _DepartDateEnd, _DestinationID, _DestinationName, _Seal, _Carrier, _Trailer, _BOL, _CaseSku, _UnitSKU, _CaseOrUnitSKU, _CaseOrUnitSkuID,) =>
({
    type: types.INVENTORY_LOT_OUTBOUND_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        LoadID: _LoadID,
        DepartDateStart: _DepartDateStart,
        DepartDateEnd: _DepartDateEnd,
        DestinationID: _DestinationID,
        DestinationName: _DestinationName,
        Seal: _Seal,
        Carrier: _Carrier,
        Trailer: _Trailer,
        BOL: _BOL,
        CaseSku: _CaseSku,
        UnitSKU: _UnitSKU,
        CaseOrUnitSKU: _CaseOrUnitSKU,
        CaseOrUnitSkuID: _CaseOrUnitSkuID,
    }
});




export const inventoryLotReportingInboundGet = (_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _Container, _ContainerStatusID, _SkuID, _SKU, _StartRecoveryDt, _EndRecoveryDt,) =>
({
    type: types.INVENTORY_LOT_REPORTING_INBOUND_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        ContainerID: _ContainerID,
        Container: _Container,
        ContainerStatusID: _ContainerStatusID,
        SkuID: _SkuID,
        SKU: _SKU,
        StartRecoveryDt: _StartRecoveryDt,
        EndRecoveryDt: _EndRecoveryDt,
    }
});

export const inventoryLotReportingStorageGet = (_email, _authToken, _WarehouseID, _CustomerID, _StartDT, _EndDT,) =>
({
    type: types.INVENTORY_LOT_REPORTING_STORAGE_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        StartDT: _StartDT,
        EndDT: _EndDT,
    }
});

export const inventoryLotHistoryTypeGet = (_email, _authToken, _InventoryDelete) =>
({
    type: types.INVENTORY_LOT_HISTORY_TYPE_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        InventoryDelete: _InventoryDelete,
    }
});



export const inventoryLotDeleteContainerPalletCaseUnitPost = (_email, _authToken, _WarehouseID, _CustomerID, _HistoryTypeID, _ContainerIdList, _PalletIdList, _PalletBarcode, _CaseIdList, _CaseBarcode, _UnitIdList, _UnitsToRemove,) =>
({
    type: types.INVENTORY_LOT_POST_DELETE_CONTAINER_PALLET_CASE_UNIT_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        HistoryTypeID: _HistoryTypeID,
        ContainerIdList: _ContainerIdList,
        PalletIdList: _PalletIdList,
        PalletBarcode: _PalletBarcode,
        CaseIdList: _CaseIdList,
        CaseBarcode: _CaseBarcode,
        UnitIdList: _UnitIdList,
        UnitsToRemove: _UnitsToRemove,
    }
});


export const inventoryLotCaseRefEditPost = (_email, _authToken, _CaseID, _CaseBarcode, _SKU, _SkuID, _Notes, _LotCode, _SerialNumber, _CustomerRefID, _CustomerRef, _ProjectID, _Project,) =>
({
    type: types.INVENTORY_LOT_POST_CASE_REF_EDIT_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        CaseID: _CaseID,
        CaseBarcode: _CaseBarcode,
        SKU: _SKU,
        SkuID: _SkuID,
        Notes: _Notes,
        LotCode: _LotCode,
        SerialNumber: _SerialNumber,
        CustomerRefID: _CustomerRefID,
        CustomerRef: _CustomerRef,
        ProjectID: _ProjectID,
        Project: _Project,
    }
});



export const inventoryLotUnitCaseSkuRefEditPost = (_email, _authToken, _CaseSkuID, _SKU, _SkuID, _LotCode, _SerialNumber, _CustomerRefID, _CustomerRef, _ProjectID, _Project, _UnitsRecovered, _Notes) =>
({
    type: types.INVENTORY_LOT_POST_UNIT_CASESKU_REF_EDIT_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        CaseSkuID: _CaseSkuID,
        SKU: _SKU,
        SkuID: _SkuID,
        LotCode: _LotCode,
        SerialNumber: _SerialNumber,
        CustomerRefID: _CustomerRefID,
        CustomerRef: _CustomerRef,
        ProjectID: _ProjectID,
        Project: _Project,
        UnitsRecovered: _UnitsRecovered,
        Notes: _Notes,
    }
});

export const inventoryLotPalletRefEditPost = (_email, _authToken, _PalletID, _PalletBarcode, _Notes, _Oversize, _Length, _Width,) =>
({
    type: types.INVENTORY_LOT_POST_PALLET_REF_EDIT_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        PalletID: _PalletID,
        PalletBarcode: _PalletBarcode,
        Notes: _Notes,
        Oversize: _Oversize,
        Length: _Length,
        Width: _Width,
    }
});


export const inventoryLotContainerPost = (_email, _authToken, _ContainerID, _Container, _LCL_FCL, _DeliveryAppointment, _ETD, _ETA, _CurrentContainerStatusID, _Notes, _WarehouseID, _CustomerID, _Palletized, _ContainerSize,) =>
({
    type: types.INVENTORY_LOT_POST_CONTAINER_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        ContainerID: _ContainerID,
        Container: _Container,
        LCL_FCL: _LCL_FCL,
        DeliveryAppointment: _DeliveryAppointment,
        ETD: _ETD,
        ETA: _ETA,
        CurrentContainerStatusID: _CurrentContainerStatusID,
        Notes: _Notes,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        Palletized: _Palletized,
        ContainerSize: _ContainerSize,
    }
});

