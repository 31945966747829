import PropTypes from 'prop-types';
import React from 'react';
import { Panel } from 'rsuite';

const StatsCard = ({
  statValue,
  statLabel,
  icon,
  iconColor,
  allowClick,
  onClickStatCard,
  isMobile
}) => (

  <div>
    {(() => {
      switch (isMobile) {
        case true: return (
          <Panel bordered style={{ marginBottom: 10, cursor: (allowClick ? 'pointer' : '') }} onClick={allowClick ? (e) => { onClickStatCard(e) } : undefined}  >
            <span className={`sm-st-icon-mobile st-${iconColor}`} style={{ marginBottom: '5px' }} >
              {icon}
            </span>
            <div className="sm-st-info-mobile">
              <span>
                {statValue}
              </span>
              <div>
                {statLabel}
              </div>
            </div>
          </Panel>
        );

        case false: return (
          <Panel bordered style={{ marginBottom: 10, cursor: (allowClick ? 'pointer' : '') }} onClick={allowClick ? (e) => { onClickStatCard(e) } : undefined} >
            <span className={`sm-st-icon st-${iconColor}`} style={{ marginBottom: '20px' }} >
              {icon}
            </span>
            <div className="sm-st-info">
              <span>
                {statValue}
              </span>
              <div style={{ maxHeight: '20px' }}>
                {statLabel}
              </div>
            </div>
          </Panel>
        );
        default: return (
          <Panel bordered style={{ marginBottom: 10, cursor: (allowClick ? 'pointer' : '') }} onClick={allowClick ? (e) => { onClickStatCard(e) } : undefined} >
            <span className={`sm-st-icon st-${iconColor}`} style={{ marginBottom: '20px' }} >
              {icon}
            </span>
            <div className="sm-st-info">
              <span>
                {statValue}
              </span>
              <div style={{ maxHeight: '20px' }}>
                {statLabel}
              </div>
            </div>
          </Panel>
        )
      }
    })()}
  </div >

);

StatsCard.propTypes = {
  statValue: PropTypes.string,
  statLabel: PropTypes.string,
  icon: PropTypes.node,
  iconColor: PropTypes.oneOf([
    'red',
    'blue',
    'violet',
    'green',
    'yellow',
    'grey',
    'darkblue'
  ]),
  isMobile: PropTypes.bool
};

StatsCard.defaultProps = {
  allowClick: false,
  statValue: '0',
  statLabel: '',
  icon: (<i className="fa fa-check-square-o"></i>),
  iconColor: 'blue',
  isMobile: false
};

export default StatsCard;


























// import React from 'react';
// import PropTypes from 'prop-types';
// import classnames from 'classnames';
// // import checkProps from '@jam3/react-check-extra-props';
// import styles from './StatsCard.module.scss';

// function StatsCard({ icon, title, text }) {
//   return (
//     <div className={classnames(styles.card)}>
//       <div className={styles.iconBox}>{icon}</div>
//       <div className={styles.textBox}>
//         <div>
//           <strong>{title}</strong>
//         </div>
//         <div>{text}</div>
//       </div>
//     </div>
//   );
// }

// // StatsCard.propTypes = checkProps({ icon: PropTypes.element, title: PropTypes.string, text: PropTypes.string });

// StatsCard.defaultProps = {};

// export default StatsCard;
