import React, {
    PureComponent
} from 'react';
import { Modal, Button, Row, Col, InputGroup, InputNumber, Panel, Form, InputPicker } from 'rsuite';


const styles = {
    // width: 300,
    marginBottom: 10,
    width: '100%'
};

function ShipmentManagementAddNewLegModal({
    addNewLegModalShown,
    handleSave,
    handleClose,
    onChangeAddNewLegModal,
    resetRows,
    state, ...props

}) {
    return (

        <div className="modal-container">
            <Modal backdrop={'static'} keyboard={false} show={addNewLegModalShown} onHide={handleClose} onExited={resetRows}>
                <Modal.Header>
                    <Modal.Title>Add New Leg:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Panel bordered style={{ marginBottom: '15px' }}>
                        <Row>
                            <Form fluid>
                                <Col md={24} sm={24} >




                                    <InputGroup style={styles}>
                                        <InputGroup.Addon>Leg:</InputGroup.Addon>
                                        <InputNumber
                                            placeholder={'Leg...'}
                                            value={state.newLegModalLegNumber}
                                            onChange={(e) => { onChangeAddNewLegModal(e, 'Leg') }}
                                            // onInput={(e) => e.target.value = numbersInputMask(e)}
                                            min={1}
                                            block
                                            step={1}
                                            // defaultValue={1}
                                            cleanable={false}
                                        />
                                    </InputGroup>

                                    <InputPicker
    preventOverflow
                                        placeholder={'Mode...'}
                                        value={state.newLegModalMode}
                                        onChange={(e) => { onChangeAddNewLegModal(e, 'Mode') }}
                                        data={props.CtrlTwrModeRefData}
                                        block
                                        cleanable={true}
                                    />


                                </Col>
                            </Form>
                        </Row>
                    </Panel>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleSave} appearance="primary">
                        Save
                    </Button>
                    <Button onClick={handleClose} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}


export default ShipmentManagementAddNewLegModal;