import React, {
    PureComponent
} from 'react';
import Loader from 'react-loader-spinner'
import { withRouter } from 'react-router-dom';
import { Row, Col, Panel, PanelGroup, Button, Nav, InputGroup, Input, ControlLabel, ButtonGroup, ButtonToolbar, InputPicker } from 'rsuite';
import ReactExport from "react-export-excel";
import * as moment from 'moment'
import RTableGeneral from '../../../componentsGeneral/Rsuite/Table/RTableGeneral';
import RButtonHideShowFilter from '../../../componentsGeneral/Rsuite/Button/RButtonHideShowFilter';
import { palletCaseSkuStatus } from '../../../../app/appConfig';
import RSearchInputNumberAsString from '../../../componentsGeneral/Rsuite/SearchInput/RSearchInputNumberAsString';
import RSelectPicker from '../../../componentsGeneral/Rsuite/InputPicker/RSelectPicker';
import RDatePickerRange from '../../../componentsGeneral/Rsuite/DatePicker/RDatePickerRange';
import queryString from 'query-string';
import { queryStringParse, replaceNegativeWithZero, isNumeric, formatStringForAPI, uniqueArray, convertNanToZero, formatTrueFalseToBit } from '../../../../resources/logic/functions';
import PalletBuildLoadDrawer from './PalletBuildLoadDrawer';
import RSelectPickerCreatable from '../../../componentsGeneral/Rsuite/InputPicker/RSelectPickerCreatable';
import InventoryPalletWarehouseDataElements from '../../../dataComponentsInventory/InventoryPalletWarehouseDataElements';
import InventoryCaseWarehouseDataElements from '../../../dataComponentsInventory/InventoryCaseWarehouseDataElements';
import InventorySkuWarehouseGroupedDataElements from '../../../dataComponentsInventory/InventorySkuWarehouseGroupedDataElements';
import RCheckPicker from '../../../componentsGeneral/Rsuite/InputPicker/RCheckPicker';
import { textNumbersSymbolsInputmask } from '../../../../resources/logic/inputMasks';

// const tableIdCol = "SkuID"

const footerStyles = {
    padding: '10px 2px',
    borderTop: '1px solid #e5e5e5'
};


//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")

const styles = {
    marginBottom: 15
};

const mountRef = React.createRef();

class InventorySkuLevel extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            // Table Check Box
            checkedKeys: [],

            //Search Values
            isFilterShown: true,
            searchContainer: '',
            searchSKU: '',

            searchWarehouseLocation: null,
            searchPalletBarcode: '',
            searchCaseSku: '',
            searchCaseBarcode: '',
            searchCaseSerial: '',
            searchCaseLotCode: '',
            searchUnitSku: '',
            searchUnitSerial: '',
            searchUnitLotCode: '',

            searchStatus: [1, 2],

            searchCustomerRef: null,
            searchRecoveryDateStart: null,
            searchRecoveryDateEnd: null,


            //Build Load Drawer
            BuildLoadDict: {},
            LocationDict: {},
            NewLoadData: {},
            unsavedBuildLoadChanges: false,
            showBuildLoadDrawer: false,
            existingDataChangeLoading: false


        };

        // Table Check Box
        this.handleCheckAll = this.handleCheckAll.bind(this);
        this.handleCheck = this.handleCheck.bind(this);

        // Table Sort
        this.handleSortColumn = this.handleSortColumn.bind(this);

        //Filter hide Show
        this.onClickIsFilterShown = this.onClickIsFilterShown.bind(this);
        this.onClickResetFilters = this.onClickResetFilters.bind(this);

        //Search Values
        this.onChangeState = this.onChangeState.bind(this);
        this.onChangeSearchContainer = this.onChangeSearchContainer.bind(this);
        this.onChangeSearchSKU = this.onChangeSearchSKU.bind(this);
        this.onChangeSearchStatus = this.onChangeSearchStatus.bind(this);
        this.onChangeSearchCustomerRef = this.onChangeSearchCustomerRef.bind(this)
        this.onChangeSearchRecoveryDateStart = this.onChangeSearchRecoveryDateStart.bind(this);
        this.onChangeSearchRecoveryDateEnd = this.onChangeSearchRecoveryDateEnd.bind(this);



        //Table buttons
        this.onClickDetails = this.onClickDetails.bind(this);


        //Build Load Drawer
        this.onChangeBuildLoadDrawer = this.onChangeBuildLoadDrawer.bind(this);
        this.onChangeLoad = this.onChangeLoad.bind(this);
        this.onChangeLocation = this.onChangeLocation.bind(this);
        this.onClickBuildLoad = this.onClickBuildLoad.bind(this);
        this.closeBuildLoadDrawer = this.closeBuildLoadDrawer.bind(this);
        this.onClickBuildLoadDrawerSave = this.onClickBuildLoadDrawerSave.bind(this);
        this.onClickRemoveFromBuild = this.onClickRemoveFromBuild.bind(this);

        this.onClickRemoveFromAdd = this.onClickRemoveFromAdd.bind(this);
    }




    componentDidMount() {
        this.loadSortFromStorage()
        this.updateParamsFromURL()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.setState({
                checkedKeys: []
            });
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.setState({
                checkedKeys: []
            });
        }
    }

    updateParamsFromURL = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramContainer = queryStringParse(values.container)
        }
        catch (e) {
            var paramContainer = null
        }
        if (paramContainer != null) {
            this.onChangeSearchContainer(paramContainer)
        }
        try {
            var paramSKU = queryStringParse(values.sku)
        }
        catch (e) {
            var paramSKU = null
        }
        if (paramSKU != null) {
            this.onChangeSearchSKU(paramSKU)
        }

        try {
            var paramStatus = values.statusid.split(',').map(Number);
        }
        catch (e) {
            var paramStatus = null
        }
        if (paramStatus != null) {
            this.onChangeSearchStatus(paramStatus)
        }


        try {
            var paramCustomerRefID = queryStringParse(values.customerrefid)
        }
        catch (e) {
            var paramCustomerRefID = null
        }
        if (paramCustomerRefID != null) {
            this.onChangeSearchCustomerRef(paramCustomerRefID)
        }

        try {
            var date = queryStringParse(values.recoverydtstart)
            if (date === null) {
                var paramRecoveryDateStart = null
            }
            else {
                var dateValue = new Date(date);
                var dayAdd = moment(dateValue).add(1, 'days')
                var paramRecoveryDateStart = dayAdd
            }
        }
        catch (e) {
            var paramRecoveryDateStart = null
        }
        if (paramRecoveryDateStart != null) {
            this.onChangeSearchRecoveryDateStart(paramRecoveryDateStart)
        }

        try {
            var date = queryStringParse(values.recoverydtend)
            if (date === null) {
                var paramRecoveryDateEnd = null
            }
            else {
                var dateValue = new Date(date);
                var dayAdd = moment(dateValue).add(1, 'days')
                var paramRecoveryDateEnd = dayAdd
            }
        }
        catch (e) {
            var paramRecoveryDateEnd = null
        }
        this.onChangeSearchRecoveryDateEnd(paramRecoveryDateEnd)


        try {
            var paramWarehouselocationid = queryStringParse(values.warehouselocationid)
            this.onChangeState(paramWarehouselocationid, 'searchWarehouseLocation')
        }
        catch (e) { }

        try {
            var paramPalletbarcode = queryStringParse(values.palletbarcode)
            this.onChangeState(paramPalletbarcode, 'searchPalletBarcode')
        }
        catch (e) { }

        try {
            var paramCasesku = queryStringParse(values.casesku)
            this.onChangeState(paramCasesku, 'searchCaseSku')
        }
        catch (e) { }
        try {
            var paramcasebarcode = queryStringParse(values.casebarcode)
            this.onChangeState(paramcasebarcode, 'searchCaseBarcode')
        }
        catch (e) { }
        try {
            var paramcaseserial = queryStringParse(values.caseserial)
            this.onChangeState(paramcaseserial, 'searchCaseSerial')
        }
        catch (e) { }
        try {
            var paramcaselotcode = queryStringParse(values.caselotcode)
            this.onChangeState(paramcaselotcode, 'searchCaseLotCode')
        }
        catch (e) { }
        try {
            var paramunitsku = queryStringParse(values.unitsku)
            this.onChangeState(paramunitsku, 'searchUnitSku')
        }
        catch (e) { }
        try {
            var paramunitserial = queryStringParse(values.unitserial)
            this.onChangeState(paramunitserial, 'searchUnitSerial')
        }
        catch (e) { }
        try {
            var paramunitlotcode = queryStringParse(values.unitlotcode)
            this.onChangeState(paramunitlotcode, 'searchUnitLotCode')
        }
        catch (e) { }


    }


    //Change State
    onChangeState(e, statename) {
        this.setState({
            [statename]: e
        });
    }


    //Table on click details
    onClickDetails(e, rowData, history) {
        var rowID
        rowID = 'SkuID'


        try {
            var paramID = rowData[rowID]

            if (paramID != null){
            history.push({
                pathname: 'lotskudetails',
                search: 'skuid=\'' + paramID + '\''
            });
        }
        }
        catch (e) {
        }



        // if (rowData['SkuType'] == 'Case') {
        //     try {
        //         var paramID = rowData[rowID]
    
        //         if (paramID != null){
        //         history.push({
        //             pathname: 'skucasedetails',
        //             search: 'caseskuid=\'' + paramID + '\''
        //         });
        //     }
        //     }
        //     catch (e) {
        //     }
        // }
        // else{
        //     try {
        //         var paramID = rowData[rowID]
    
        //         if (paramID != null){
        //         history.push({
        //             pathname: 'unitdetails',
        //             search: 'skuid=\'' + paramID + '\''
        //         });
        //     }
        //     }
        //     catch (e) {
        //     }
        // }


    }



    // Table Sort
    saveSortTosessionStorage(sortColumn, sortType) {
        try {
            sessionStorage.setItem('sortcolumn', sortColumn)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortType', sortType)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortURLPathname', this.props.location.pathname)
        }
        catch (e) { }
    }
    loadSortFromStorage() {
        var sortColumn = null
        var sortType = null
        var pathName = null
        try {
            sortColumn = sessionStorage.getItem('sortcolumn')
        }
        catch (e) { }
        try {
            sortType = sessionStorage.getItem('sortType')
        }
        catch (e) { }
        try {
            pathName = sessionStorage.getItem('sortURLPathname')
        }
        catch (e) { }
        if (pathName === this.props.location.pathname) {
            
            this.handleSortColumn(sortColumn, sortType)
        }
    }
    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });
        this.saveSortTosessionStorage(sortColumn, sortType)
        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 100);
    }


    // Table Check Box
    handleCheckAll(value, checked, data) {
        var rowID
        rowID = 'SkuID'


        const checkedKeys = checked ? [] : data.map(item => item[rowID]);
        this.setState({
            checkedKeys
        });
    }
    handleCheck(value, checked) {
        var rowID
        var currentCheckedRow

        rowID = 'SkuID'
        currentCheckedRow = this.props.InventorySkuWarehouseGroupedData.filter(x => value === x[rowID])


        var currentStatusID
        currentStatusID = currentCheckedRow[0]['SkuStatusID']


        if (currentStatusID == '1' || currentStatusID == '2') {
            const { checkedKeys } = this.state;
            const nextCheckedKeys = checked
                ? [...checkedKeys, value]
                : checkedKeys.filter(item => item !== value);
            this.setState({
                checkedKeys: nextCheckedKeys
            });
        }
        else {
            window.alert('Nothing remaining to load.')
        }

        // if (remainingToLoad < 1) {
        //     window.alert('Nothing remaining to load.')
        // }
        // else {

        //     const { checkedKeys } = this.state;
        //     const nextCheckedKeys = checked
        //         ? [...checkedKeys, value]
        //         : checkedKeys.filter(item => item !== value);
        //     this.setState({
        //         checkedKeys: nextCheckedKeys
        //     });
        // }
    }


    //Filter
    onClickIsFilterShown(e, isFilterShown) {
        if (isFilterShown) {
            this.setState({
                isFilterShown: false
            });
        }
        else {
            this.setState({
                isFilterShown: true
            });
        }
    }
    onClickResetFilters(e) {
        this.setState({
            isFilterShown: false
        });
        this.setState({
            searchContainer: '',
            searchSKU: '',
            searchWarehouseLocation: null,
            searchPalletBarcode: '',
            searchCaseSku: '',
            searchCaseBarcode: '',
            searchCaseSerial: '',
            searchCaseLotCode: '',
            searchUnitSku: '',
            searchUnitSerial: '',
            searchUnitLotCode: '',

            searchStatus: [1, 2],
            searchCustomerRef: null,
            searchCarrier: null,
            searchMinETADischargeStart: null,
            searchMinETADischargeEnd: null,
            searchRecoveryDateStart: null,
            searchRecoveryDateEnd: null,
            serachAssignedDriver: '',
            searchMawbColorCode: null

        });
        var searchStatus = [1, 2]
        this.props.history.push({
            search: 'statusid=' + searchStatus
        });


        setTimeout(() => {
            this.setState({
                isFilterShown: true
            });
        }, 500);
    }

    onClickApplySearch(e) {

        var sDT = null
        var eDT = null


        if (this.state.searchRecoveryDateStart === null) {
            sDT = null
        }
        else {
            sDT = "'" + moment(this.state.searchRecoveryDateStart).format('YYYY-MM-DD') + "'"
        }
        if (this.state.searchRecoveryDateEnd === null) {
            eDT = null
        }
        else {
            eDT = "'" + moment(this.state.searchRecoveryDateEnd).format('YYYY-MM-DD') + "'"
        }

        if (this.props.warehouseSelection == null) {
            window.alert('Please select a warehouse and customer.')
        }
        else if (this.props.customerSelection == null) {
            window.alert('Please select a customer.')
        }
        else {
            try {
                var searchStatus = this.state.searchStatus
                var searchCustomerRef = this.state.searchCustomerRef
                // var searchCarrier = this.state.searchCarrier
                // var serachAssignedDriver = this.state.serachAssignedDriver
                // var searchMawbColorCode = this.state.searchMawbColorCode

                var searchWarehouseLocation = this.state.searchWarehouseLocation
                var searchContainer = this.state.searchContainer
                var searchPalletBarcode = this.state.searchPalletBarcode
                var searchCaseSku = this.state.searchCaseSku
                var searchCaseBarcode = this.state.searchCaseBarcode
                var searchCaseSerial = this.state.searchCaseSerial
                var searchCaseLotCode = this.state.searchCaseLotCode
                var searchUnitSku = this.state.searchUnitSku
                var searchUnitSerial = this.state.searchUnitSerial
                var searchUnitLotCode = this.state.searchUnitLotCode

                // container

                this.props.history.push({
                    search:
                        'warehouselocationid=' + searchWarehouseLocation + '&' +
                        'statusid=' + searchStatus + '&' +
                        'customerrefid=' + searchCustomerRef + '&' +
                        // 'carrier=' + searchCarrier + '&' +
                        // 'assigneddriver=' + serachAssignedDriver + '&' +
                        // 'mawbcolorcode=' + searchMawbColorCode + '&' +
                        'recoverydtstart=' + sDT + '&' +
                        'recoverydtend=' + eDT + '&' +
                        'container=' + searchContainer + '&' +
                        'palletbarcode=' + searchPalletBarcode + '&' +
                        'casesku=' + searchCaseSku + '&' +
                        'casebarcode=' + searchCaseBarcode + '&' +
                        'caseserial=' + searchCaseSerial + '&' +
                        'caselotcode=' + searchCaseLotCode + '&' +
                        'unitsku=' + searchUnitSku + '&' +
                        'unitserial=' + searchUnitSerial + '&' +
                        'unitlotcode=' + searchUnitLotCode

                });
            }
            catch (e) { }
        }
    }


    onSearchClearOtherFilters() {
        this.setState({
            searchStatus: null,
            searchCustomerRef: null,
            searchCarrier: null,
            searchMinETADischargeStart: null,
            searchMinETADischargeEnd: null,
            searchRecoveryDateStart: null,
            searchRecoveryDateEnd: null,
            serachAssignedDriver: '',
            searchMawbColorCode: null
        });

    }






    onChangeSearchContainer(e) {
        this.onSearchClearOtherFilters()
        this.setState({
            searchContainer: e,
            searchSKU: '',
        });
    }

    onChangeSearchSKU(e) {
        this.onSearchClearOtherFilters()
        this.setState({
            searchSKU: e,
            searchContainer: '',
        });
    }

    onChangeSearchStatus(e) {
        this.setState({
            searchStatus: e
        });
    }

    onChangeSearchCustomerRef(e) {
        this.setState({
            searchCustomerRef: e
        });
    }


    onChangeSearchCarrier(e) {
        this.setState({
            searchCarrier: e
        });
    }

    onChangeSearchMinETADischargeStart(e) {
        this.setState({
            searchMinETADischargeStart: e
        });
    }
    onChangeSearchMinETADischargeEnd(e) {
        this.setState({
            searchMinETADischargeEnd: e
        });
    }

    onChangeSearchRecoveryDateStart(e) {
        this.setState({
            searchRecoveryDateStart: e
        });
    }
    onChangeSearchRecoveryDateEnd(e) {
        this.setState({
            searchRecoveryDateEnd: e
        });
    }
    onChangeSearchAssigedDriver(e) {
        this.setState({
            serachAssignedDriver: e
        });
    }
    onChangeSearchMawbColorCode(e) {
        this.setState({
            searchMawbColorCode: e
        });
    }




    //Build Load Drawer
    onClickBuildLoad(e) {

        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {

            if (this.state.checkedKeys.length > 0) {
                var buildLoadArray = {};

                var rowID;
                rowID = 'SkuID'
                // currentCheckedRow = this.props.InventorySkuWarehouseGroupedData.filter(x => value === x[rowID])

                buildLoadArray = this.props.InventorySkuWarehouseGroupedData.filter(x => this.state.checkedKeys.includes(x.SkuID))



                var newLoadDict = {}
                newLoadDict['PickupDate'] = null
                newLoadDict['DeliveryDate'] = null
                newLoadDict['Carrier'] = null
                newLoadDict['BOL'] = null
                newLoadDict['Trailer'] = null
                newLoadDict['Seal'] = null


                this.setState({
                    // unsavedBuildLoadChanges: true,
                    BuildLoadDict: buildLoadArray,
                    // LocationDict: newLocationDict,
                    NewLoadData: newLoadDict,
                    showBuildLoadDrawer: true,
                });
            }
            else {
                window.alert('Please select rows.')
            }
        }
        else {
            window.alert('Please select one warehouse and one customer to build a load.')
        }
    }


    onChangeBuildLoadDrawer = (e, item, label) => {

        var rowID
        var currentCheckedRow
        var currentItem

        rowID = 'SkuID'



        currentItem = item[rowID]

        var currentBuildDict = this.state.BuildLoadDict
        var buildKeyToUpdate = currentBuildDict.findIndex(item => item[rowID] === currentItem)


        // currentCheckedRow = this.state.BuildLoadDict.filter(x => currentItem === x[rowID])

        currentBuildDict[buildKeyToUpdate][label] = e
        // this.handleSetStatePallet(currentPalletDict)

        this.setState({
            unsavedBuildLoadChanges: true,
            NewLoadData: currentBuildDict
        });

        this.forceUpdate()


    };

    onChangeSku(e, pallet, cases, skus, label) {

        var palletSeq = pallet['PalletSeq']
        var caseSeq = cases['CaseSeq']
        var skuSeq = skus['SkuSeq']
        var currentPalletDict = this.state.ReceivePalletDict
        var palletKeyToUpdate = currentPalletDict.findIndex(item => item.PalletSeq === palletSeq)
        var caseKeyToUpdate = currentPalletDict[palletKeyToUpdate]['Cases'].findIndex(item => item.CaseSeq === caseSeq)

        var skuKeyToUpdate = currentPalletDict[palletKeyToUpdate]['Cases'][caseKeyToUpdate]['Skus'].findIndex(item => item.SkuSeq === skuSeq)

        currentPalletDict[palletKeyToUpdate]['Cases'][caseKeyToUpdate]['Skus'][skuKeyToUpdate][label] = e
        this.handleSetStatePallet(currentPalletDict)

    }



    onChangeLoad = (e, label) => {
        var loadDict = this.state.NewLoadData
        loadDict[label] = e
        this.setState({
            unsavedBuildLoadChanges: true,
            NewLoadData: loadDict
        });

        this.forceUpdate()



        // setTimeout(() => {
        //     this.setState({
        //         existingDataChangeLoading: true
        //     });
        // }, 50);


        // var skuId = item.SkuID;
        // var itemIndex = this.state.BuildLoadDict.findIndex(({ SkuID }) => SkuID === skuId);
        // var currentBuildLoadDict = this.state.BuildLoadDict

        // currentBuildLoadDict[itemIndex][label] = isNumeric(event)

        // this.setState({
        //     unsavedBuildLoadChanges: true,
        //     BuildLoadDict: currentBuildLoadDict,

        //     existingDataChangeLoading: false
        // });
    };
    onChangeLocation = (e, item, locationitem, label, skuWarehouseLocationID) => {
        setTimeout(() => {
            this.setState({
                existingDataChangeLoading: true
            });
        }, 50);

        var skuId = item.SkuID;
        var itemIndex = this.state.LocationDict[skuId].findIndex(({ SkuWarehouseLocationID }) => SkuWarehouseLocationID === skuWarehouseLocationID);
        var newLocationBuildDict = this.state.LocationDict

        newLocationBuildDict[skuId][itemIndex][label] = isNumeric(e)

        var currentBuildLoadDictIndex = this.state.BuildLoadDict.findIndex(({ SkuID }) => SkuID === skuId);
        var currentBuildLoadDict = this.state.BuildLoadDict

        try {
            currentBuildLoadDict[currentBuildLoadDictIndex]['NewUnitsLoaded'] = replaceNegativeWithZero((newLocationBuildDict[currentBuildLoadDict[currentBuildLoadDictIndex]['SkuID']].reduce((a, v) => a = a + v.Units, 0)))
            currentBuildLoadDict[currentBuildLoadDictIndex]['NewCasesLoaded'] = replaceNegativeWithZero((newLocationBuildDict[currentBuildLoadDict[currentBuildLoadDictIndex]['SkuID']].reduce((a, v) => a = a + v.Cases, 0)))
            currentBuildLoadDict[currentBuildLoadDictIndex]['NewPallets'] = replaceNegativeWithZero((newLocationBuildDict[currentBuildLoadDict[currentBuildLoadDictIndex]['SkuID']].reduce((a, v) => a = a + v.Pallets, 0)))
        }
        catch (e) {
            currentBuildLoadDict[currentBuildLoadDictIndex]['NewUnitsLoaded'] = replaceNegativeWithZero(currentBuildLoadDict[currentBuildLoadDictIndex]['UnitsReceived'] - currentBuildLoadDict[currentBuildLoadDictIndex]['UnitsLoaded'])
            currentBuildLoadDict[currentBuildLoadDictIndex]['NewCasesLoaded'] = replaceNegativeWithZero(currentBuildLoadDict[currentBuildLoadDictIndex]['CasesReceived'] - currentBuildLoadDict[currentBuildLoadDictIndex]['CasesLoaded'])
            currentBuildLoadDict[currentBuildLoadDictIndex]['NewPallets'] = replaceNegativeWithZero(currentBuildLoadDict[currentBuildLoadDictIndex]['PalletsReceived'] - currentBuildLoadDict[currentBuildLoadDictIndex]['PalletsLoaded'])
        }

        this.setState({
            unsavedBuildLoadChanges: true,
            BuildLoadDict: currentBuildLoadDict,
            LocationDict: newLocationBuildDict,
            existingDataChangeLoading: false
        });
    }

    onClickRemoveFromAdd = (e, item, locationitem, skuWarehouseLocationID) => {

        this.onChangeLocation(0, item, locationitem, 'Units', skuWarehouseLocationID)
        this.onChangeLocation(0, item, locationitem, 'Cases', skuWarehouseLocationID)
        this.onChangeLocation(0, item, locationitem, 'Pallets', skuWarehouseLocationID)

        this.forceUpdate()
    }

    onClickRemoveFromBuild(e, item) {

        var rowID;
        rowID = 'SkuID'


        var prevBuild = {}
        prevBuild = this.state.BuildLoadDict

        var idToRemove = item[rowID];

        var itemIndex = this.state.BuildLoadDict.findIndex((row) => row[rowID] === idToRemove);
        // var itemIndex = this.state.BuildLoadDict.findIndex(({ rowID }) => rowID === idToRemove);
        prevBuild.splice(itemIndex, 1);

        setTimeout(() => {
            this.setState({
                BuildLoadDict: prevBuild
            });
        }, 50);



        this.forceUpdate()

    }
    onClickBuildLoadDrawerSave(e, totalUnitsNumber, totalRecoveredNum, totalPalleteNum) {

        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {


            var loadPalletDict = this.state.BuildLoadDict
            var LoadPalletDictString = JSON.stringify(loadPalletDict)

            try {
                var paramPostLoadPalletDict = "'" + LoadPalletDictString + "'"
            }
            catch (e) {
                var paramPostLoadPalletDict = null
            }


            try {
                if (this.state.NewLoadData.PickupDate != null) {
                    var paramPostPickupDate = "'" + moment(this.state.NewLoadData.PickupDate).format('YYYY-MM-DD') + "'"
                }
                else {
                    var paramPostPickupDate = null
                }
            }
            catch (e) {
                var paramPostPickupDate = null
            }
            try {
                if (this.state.NewLoadData.DeliveryDate != null) {
                    var paramPostDeliveryDate = "'" + moment(this.state.NewLoadData.DeliveryDate).format('YYYY-MM-DD') + "'"
                }
                else {
                    var paramPostDeliveryDate = null
                }
            }
            catch (e) {
                var paramPostDeliveryDate = null
            }
            try {
                // var paramPostCarrier = "'" + this.state.NewLoadData.Carrier + "'"
                var paramPostCarrier = formatStringForAPI(this.state.NewLoadData.Carrier)
            }
            catch (e) {
                var paramPostCarrier = null
            }
            try {
                // var paramPostBOL = "'" + this.state.NewLoadData.BOL + "'"
                var paramPostBOL = formatStringForAPI(this.state.NewLoadData.BOL)
            }
            catch (e) {
                var paramPostBOL = null
            }
            try {
                // var paramPostTrailer = "'" + this.state.NewLoadData.Trailer + "'"
                var paramPostTrailer = formatStringForAPI(this.state.NewLoadData.Trailer)
            }
            catch (e) {
                var paramPostTrailer = null
            }
            try {
                // var paramPostSeal = "'" + this.state.NewLoadData.Seal + "'"
                var paramPostSeal = formatStringForAPI(this.state.NewLoadData.Seal)
            }
            catch (e) {
                var paramPostSeal = null
            }



            try {
                var paramPostDesintation = formatStringForAPI(this.state.NewLoadData.Destination)
            }
            catch (e) {
                var paramPostDesintation = null
            }
            try {
                var paramPostLoadStatusID = 1
            }
            catch (e) {
                var paramPostLoadStatusID = 1
            }


            this.props.inventoryLoadPalletCaseSkuPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, paramPostLoadPalletDict, 'sku', null, paramPostPickupDate, paramPostDeliveryDate, paramPostCarrier, paramPostBOL, paramPostTrailer, paramPostSeal, null, null, null, paramPostDesintation, null, paramPostLoadStatusID)


            this.setState({
                unsavedBuildLoadChanges: false,
                showBuildLoadDrawer: false
            });
            // }
        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }
    closeBuildLoadDrawer() {
        if (this.state.unsavedBuildLoadChanges) {
            if (window.confirm('Leave without saving changes?')) {
                this.setBuildLoadDrawerToClose()
            }
        }
        else {
            this.setBuildLoadDrawerToClose()
        }
    }

    setBuildLoadDrawerToClose() {
        this.setState({
            BuildLoadDict: {},
            LocationDict: {},
            NewLoadData: {},
            unsavedBuildLoadChanges: false,
            showBuildLoadDrawer: false
        });
    }



    render() {
        const { checkedKeys, sortColumn, sortType } = this.state;

        let hideShowSearch;
        hideShowSearch = <Row style={{ marginBottom: '5px' }}>

            <Col md={24} sm={24} >

                <p>
                    {RButtonHideShowFilter(this.props.isMobile, this.state.isFilterShown, 'Filters', this.onClickIsFilterShown, this.onClickResetFilters)}
                </p>

            </Col>
        </Row>


        let warehouseLocationSearch;
        if (this.props.isWarehouseLocationsLoading) {
            warehouseLocationSearch =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.WarehouseLocationsData) !== 'undefined' && this.props.WarehouseLocationsData != null) {
            warehouseLocationSearch = <Row>
                <div style={{ marginBottom: '15px', width: '100%' }}>
                    <ControlLabel>Warehouse Location</ControlLabel>
                    <InputPicker
    preventOverflow
                        placeholder={'Warehouse Location...'}
                        value={this.state.searchWarehouseLocation}
                        onChange={(e) => { this.onChangeState(e, 'searchWarehouseLocation') }}
                        data={this.props.WarehouseLocationsData}
                        cleanable={true}
                        block
                    />
                </div>
            </Row>
        }
        else {
            warehouseLocationSearch = <div></div>
        }


        let searchDiv;
        if (this.state.isFilterShown) {
            // && !this.props.isTransConsigneeLoading) {
            searchDiv = <Row  >
                <Col md={24} sm={24} >

                    <Panel bordered style={{ marginBottom: '10px' }}>

                        <Col md={24} sm={24} >

                            <Row style={{ marginBottom: '10px' }}>
                                <ControlLabel>Status</ControlLabel>
                                <RCheckPicker
                                    data={palletCaseSkuStatus}
                                    value={this.state.searchStatus}
                                    setCheckedState={this.onChangeSearchStatus}
                                />
                            </Row>

                            {warehouseLocationSearch}

                            <Row>
                                {RDatePickerRange(
                                    this.props.isMobile,
                                    'Recovery Date',
                                    this.onChangeSearchRecoveryDateStart,
                                    this.onChangeSearchRecoveryDateEnd,
                                    this.state.searchRecoveryDateStart,
                                    this.state.searchRecoveryDateEnd
                                )}
                            </Row>



                            <Row>
                                <div >
                                    <ControlLabel>Container</ControlLabel>
                                    <InputGroup style={{ marginBottom: 10 }}>
                                        <Input value={this.state.searchContainer} onChange={(e) => { this.onChangeState(e, 'searchContainer') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                    </InputGroup>
                                </div>
                            </Row>



                            <Row>
                                <div >
                                    <ControlLabel>Pallet Barcode</ControlLabel>
                                    <InputGroup style={{ marginBottom: 10 }}>
                                        <Input value={this.state.searchPalletBarcode} onChange={(e) => { this.onChangeState(e, 'searchPalletBarcode') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                    </InputGroup>
                                </div>
                            </Row>

                            <Row >

                                <ControlLabel>Case Barcode</ControlLabel>
                                <InputGroup style={{ marginBottom: 10 }}>
                                    <Input value={this.state.searchCaseBarcode} onChange={(e) => { this.onChangeState(e, 'searchCaseBarcode') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                </InputGroup>

                            </Row>


                        </Col>

                        <Col md={12} sm={24} style={{ marginBottom: '10px' }}>


                            <Row style={{ marginRight: 10 }}>

                                <ControlLabel>Case SKU</ControlLabel>
                                <InputGroup style={{ marginBottom: 10 }}>
                                    <Input value={this.state.searchCaseSku} onChange={(e) => { this.onChangeState(e, 'searchCaseSku') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                </InputGroup>

                            </Row>

                            <Row style={{ marginRight: 10 }}>

                                <ControlLabel>Case Serial Number</ControlLabel>
                                <InputGroup style={{ marginBottom: 10 }}>
                                    <Input value={this.state.searchCaseSerial} onChange={(e) => { this.onChangeState(e, 'searchCaseSerial') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                </InputGroup>

                            </Row>

                            <Row style={{ marginRight: 10 }}>

                                <ControlLabel>Case Lot Code</ControlLabel>
                                <InputGroup style={{ marginBottom: 10 }}>
                                    <Input value={this.state.searchCaseLotCode} onChange={(e) => { this.onChangeState(e, 'searchCaseLotCode') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                </InputGroup>

                            </Row>



                        </Col>
                        <Col md={12} sm={24} style={{ marginBottom: '10px' }}>

                            <Row>

                                <ControlLabel>Unit SKU</ControlLabel>
                                <InputGroup style={{ marginBottom: 10 }}>
                                    <Input value={this.state.searchUnitSku} onChange={(e) => { this.onChangeState(e, 'searchUnitSku') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                </InputGroup>

                            </Row>

                            <Row>

                                <ControlLabel>Unit Serial Number</ControlLabel>
                                <InputGroup style={{ marginBottom: 10 }}>
                                    <Input value={this.state.searchUnitSerial} onChange={(e) => { this.onChangeState(e, 'searchUnitSerial') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                </InputGroup>

                            </Row>

                            <Row>

                                <ControlLabel>Unit Lot Code</ControlLabel>
                                <InputGroup style={{ marginBottom: 10 }}>
                                    <Input value={this.state.searchUnitLotCode} onChange={(e) => { this.onChangeState(e, 'searchUnitLotCode') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                </InputGroup>

                            </Row>



                        </Col>


                        <Row>

                            {/* <ButtonToolbar style={{ marginBottom: 10, textAlignLast: 'right' }}> */}
                            <Button onClick={(e) => { this.onClickApplySearch(e) }} style={{ marginRight: '10px' }}> Apply </Button>
                            <Button onClick={(e) => { this.onClickResetFilters(e) }}> Reset </Button>
                            {/* </ButtonToolbar> */}
                        </Row>

                        {/* <Row>
                            {RSelectPicker(
                                this.props.isMobile,
                                this.props.TransCustomerRefData,
                                this.state.searchCustomerRef,
                                'Customer Ref',
                                this.onChangeSearchCustomerRef,
                                this.props.history,
                                mountRef
                            )}
                        </Row> */}








                    </Panel>
                </Col>
            </Row >
        }
        else if (!this.state.isFilterShown) {
            searchDiv = <div></div>
        }






        let shipmentSkuTable;
        let tableSkuDataDiv;
        if (this.props.isInventorySkuWarehouseGroupedLoading) {
            shipmentSkuTable = <div></div>
            tableSkuDataDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventorySkuWarehouseGroupedData) !== 'undefined' && this.props.InventorySkuWarehouseGroupedData != null) {
            const values = queryString.parse(this.props.location.search)
            try {
                var paramPoStatusID = values.statusid.split(',').map(Number);
            }
            catch (e) {
                var paramPoStatusID = []
            }
            shipmentSkuTable = RTableGeneral(
                'SkuID',     //   keyColumn,
                this.props.InventorySkuWarehouseGroupedData,     //   tableData,
                InventorySkuWarehouseGroupedDataElements,       //   DataElements,
                this.props.isInventorySkuWarehouseGroupedLoading,        //   loading,

                this.props.isMobile,        //   isMobile,
                this.props.history,     //   history,

                sortColumn,     //   sortColumn,
                sortType,       //   sortType,
                this.handleSortColumn,      //   handleSortColumn,
                false, //(paramPoStatusID.includes(1) || paramPoStatusID.includes(2) || paramPoStatusID == null ? true : false), // checkColEntry,
                null, //checkedKeys,       //   checkedKeys,
                null, //this.handleCheckAll,       //   handleCheckAll,
                null, //this.handleCheck,       //   handleCheck,

                false,      //   boolSelectButton,
                null,       //   onClickSelect,
                null,       //   selectButtonText,
                null,       //   selectHighlightRowKey,

                true,       //   link1bool,
                'Details',     //   link1text,
                this.onClickDetails,     //   link1onClick,

                false,       //   link2bool,
                null,       //   link2text,
                null  //   link2onClick
            )
            tableSkuDataDiv = <div>
                {(this.props.isMobile == true) ? <div></div> :
                    <div>
                        <Row>
                            {/* {paramPoStatusID.includes(1) || paramPoStatusID.includes(2) || paramPoStatusID == null ? <Button style={{ float: 'left' }} onClick={(e) => { this.onClickBuildLoad(e) }}>Build Load Selected</Button> : ''} */}
                            <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Warehouse_' + currentDateTime}>
                                <ExcelSheet data={this.props.InventorySkuWarehouseGroupedData} name="Warehouse">
                                    {
                                        InventorySkuWarehouseGroupedDataElements.map(
                                            ({ dBKey, displayName }, idx) => {
                                                return (
                                                    <ExcelColumn label={displayName} value={dBKey} />
                                                );
                                            }
                                        )
                                    }
                                </ExcelSheet>
                            </ExcelFile>
                        </Row>
                    </div>
                }
            </div>
        }
        else {
            shipmentSkuTable = <div></div>
            tableSkuDataDiv = <div></div>
        }




        let totalUnitsNumber;
        let totalRecoveredNumber;
        let totalPalletNumber;
        if (!this.state.existingDataChangeLoading) {
            try {
                totalUnitsNumber = this.state.BuildLoadDict.reduce((a, v) => a = a + v.NewUnitsLoaded, 0)
            }
            catch (e) {
                totalUnitsNumber = 0
            }

            try {
                totalRecoveredNumber = this.state.BuildLoadDict.reduce((a, v) => a = a + v.NewCasesLoaded, 0)
            }
            catch (e) {
                totalRecoveredNumber = 0
            }

            try {
                totalPalletNumber = this.state.BuildLoadDict.reduce((a, v) => a = a + v.NewPallets, 0)
            }
            catch (e) {
                totalPalletNumber = 0
            }
        }
        else {
            try {
                totalUnitsNumber = this.state.BuildLoadDict.reduce((a, v) => a = a + v.NewUnitsLoaded, 0)
            }
            catch (e) {
                totalUnitsNumber = 0
            }
            try {
                totalRecoveredNumber = this.state.BuildLoadDict.reduce((a, v) => a = a + v.NewCasesLoaded, 0)
            }
            catch (e) {
                totalRecoveredNumber = 0
            }
            try {
                totalPalletNumber = this.state.BuildLoadDict.reduce((a, v) => a = a + v.NewPallets, 0)
            }
            catch (e) {
                totalPalletNumber = 0
            }
        }




        let buildLoadDrawer;
        if (this.props.isInventoryLotSkuLoading) {
            totalRecoveredNumber = 0
            totalUnitsNumber = 0
            buildLoadDrawer = <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>
        }
        else if (this.props.isInventorySkuWarehouseLocationLoading) {
            totalRecoveredNumber = 0
            totalUnitsNumber = 0
            buildLoadDrawer = <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>
        }
        else if (typeof (this.state.BuildLoadDict) !== 'undefined' && this.state.BuildLoadDict != null && this.state.BuildLoadDict.length > 0 && this.state.showBuildLoadDrawer) {
            // totalRecoveredNumber = this.state.BuildLoadDict.reduce((a, v) => a = a + v.CasesReceived, 0)

            buildLoadDrawer = <PalletBuildLoadDrawer {...this.props} state={this.state} totalUnitsNumber={totalUnitsNumber} totalRecoveredNum={totalRecoveredNumber} totalPalleteNum={totalPalletNumber} onChange={this.onChangeBuildLoadDrawer} onChangeLoad={this.onChangeLoad} onChangeLocation={this.onChangeLocation} onClickRemoveFromAdd={this.onClickRemoveFromAdd} confirm={this.onClickBuildLoadDrawerSave} close={this.closeBuildLoadDrawer} remove={this.onClickRemoveFromBuild} />
        }


        let tableDiv;
        tableDiv = <div>
            {tableSkuDataDiv}
            {shipmentSkuTable}
        </div>



        return (
            <div>
                <h3>
                    Inventory Management - SKU Level
                </h3>

                {hideShowSearch}
                {searchDiv}

                <PanelGroup>

                </PanelGroup>


                {tableDiv}

                {buildLoadDrawer}

            </div>
        );

    }
}

export default withRouter(InventorySkuLevel);














// <Panel bordered style={{ marginBottom: '10px' }}>


// <div>
//     {/* {(this.state.reportNav === 'storage') ?
//         storageSearch :
//         inboundSearch
//     } */}
// </div>


// <Row>
//     {RSearchInputNumberAsString(
//         this.props.isMobile,
//         'Container',
//         this.onChangeSearchContainer,
//         this.props.history,
//         'container',
//         this.state.searchContainer
//     )}
// </Row>


// <Row>
//     {RSearchInputNumberAsString(
//         this.props.isMobile,
//         'Pallet Barcode',
//         this.onChangeSearchContainer,
//         this.props.history,
//         'palletbarcode',
//         this.state.searchContainer
//     )}
// </Row>

// <Row>
//     {RSearchInputNumberAsString(
//         this.props.isMobile,
//         'Case Barcode',
//         this.onChangeSearchContainer,
//         this.props.history,
//         'casebarcode',
//         this.state.searchContainer
//     )}
// </Row>

// <Row>
//     {RSearchInputNumberAsString(
//         this.props.isMobile,
//         'SKU',
//         this.onChangeSearchSKU,
//         this.props.history,
//         'sku',
//         this.state.searchSKU
//     )}
// </Row>
// </Panel>