import React from 'react';
import { Drawer, Button, Loader, Input, InputGroup } from 'rsuite';
import RTableGeneral from '../../../componentsGeneral/Rsuite/Table/RTableGeneral';
import InventoryPalletWarehouseDataElements from '../../../dataComponentsInventory/InventoryPalletWarehouseDataElements';

class MoveCaseDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            filteredData: []
        };

        //Detect barcode scanning
        this.scannedInput = '';


    }

    handleSearch = (value) => {
        this.setState({ search: value });
    };




    componentDidMount() {
        this.setState({ filteredData: this.props.InventoryPalletWarehouseData2 });

        window.addEventListener('keypress', this.handleGlobalKeyPress);

    }

    componentDidUpdate(prevProps, prevState) {


        if (prevState.search !== this.state.search || prevProps.InventoryPalletWarehouseData2 !== this.props.InventoryPalletWarehouseData2) {
            let palletid = this.props.InventoryPalletWarehouseData[0].PalletID
            let palletData = this.props.InventoryPalletWarehouseData2.filter(item => item.PalletID != palletid);
            let filteredData;
            
            if (this.state.search === '') {
                // If the search input is empty, display all data
                filteredData = palletData;
            } else {
                // If the search input is not empty, filter the data
                filteredData = palletData.filter(item =>
                    item.PalletBarcode.toLowerCase().includes(this.state.search.toLowerCase())
                );
            }
            this.setState({ filteredData });
        }
    }


    componentWillUnmount() {
        window.removeEventListener('keypress', this.handleGlobalKeyPress);
    }

    //Detect barcode scanning
    handleGlobalKeyPress = (e) => {
        // Ignore paste events and events when the input is focused
        if (e.ctrlKey || e.metaKey || document.activeElement.id === "barcodeinput") {
            return;
        }

        if (e.key === 'Enter') {
            this.setState({ search: this.scannedInput }
                // , () => {
                // this.handleInputSubmit();
                // }
            );
            this.scannedInput = '';
        } else {
            this.scannedInput += e.key;
        }
    };



    // onClickSelectPalletMoveCase = (e, rowData, history) => {
    //     this.props.onClickSelectPalletMoveCase(e, rowData, history);
    // };
    // handleSortColumn = () => {
    //     this.props.handleSortColumn();
    // };
    //     handleSort = () => {
    //     const sortedData = this.props.data.sort(this.props.sortColumn);
    //     // Do something with sortedData...
    // };

    // isOpen={this.state.isMoveCaseDrawerOpen}
    // toggleDrawer={this.toggleMoveCaseDrawer} 
    // sortColumn={sortColumn}
    // sortType={sortType}



    render() {

        let shipmentPalletTable;
        if (this.props.isInventoryPalletWarehouseLoading2) {
            shipmentPalletTable =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventoryPalletWarehouseData2) !== 'undefined' && this.props.InventoryPalletWarehouseData2 != null) {


            shipmentPalletTable = RTableGeneral(
                'PalletID',     //   keyColumn,
                // this.props.InventoryPalletWarehouseData2,     //   tableData,
                this.state.filteredData,     //   tableData,
                InventoryPalletWarehouseDataElements,       //   DataElements,
                this.props.isInventoryPalletWarehouseLoading2,        //   loading,

                this.props.isMobile,        //   isMobile,
                this.props.history,     //   history,

                this.props.sortColumn,     //   sortColumn,
                this.props.sortType,       //   sortType,
                this.props.handleSortColumn,      //   handleSortColumn,

                false, // checkColEntry,
                null,       //   checkedKeys,
                null,       //   handleCheckAll,
                null,       //   handleCheck,

                true,      //   boolSelectButton,
                this.props.onClickSelectPalletMoveCase,       //   onClickSelect,
                'Select',       //   selectButtonText,
                null,       //   selectHighlightRowKey,

                false,       //   link1bool,
                null,     //   link1text,
                null,     //   link1onClick,

                false,       //   link2bool,
                null,       //   link2text,
                null  //   link2onClick
            )

        }
        else {
            shipmentPalletTable = <div></div>
        }


        return (

            <div>


                <Drawer
                    show={this.props.isOpen}
                    onHide={this.props.toggleDrawer}
                    // size="lg"
                    style={this.props.isMobile ? { width: '350px' } : { width: '800px' }}
                >
                    <Drawer.Header>
                        <Drawer.Title>Move Case</Drawer.Title>
                    </Drawer.Header>
                    <Drawer.Body>

                        <InputGroup style={{ marginBottom: '10px' }}>
                            <InputGroup.Addon>Search Pallet Barcode:</InputGroup.Addon>
                            <Input
                                id="barcodeinput"
                                placeholder="Barcode"
                                value={this.state.search}
                                onChange={this.handleSearch}
                                clearable
                            />
                        </InputGroup>

                        {shipmentPalletTable}
                    </Drawer.Body>
                    <Drawer.Footer>
                        <Button onClick={this.props.toggleDrawer} appearance="primary">Close</Button>
                    </Drawer.Footer>
                </Drawer>
            </div>
        );
    }
}

export default MoveCaseDrawer;