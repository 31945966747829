import { appSettings } from "./appSettings";


const customerAttributes = {
  EFW: {
    customerUrl: 'EFW',
    customerName: 'Empire',
    customerCode: 'EFW',
    adminAccessCustomer: 'EFW',
    organizationID: 1
  },
  Evolve: {
    customerUrl: 'Evolve',
    customerName: 'Evolve',
    customerCode: 'Evolve',
    adminAccessCustomer: 'Evolve',
    organizationID: 1
  },
  // ... other customers ...
};

export const customerConfig = customerAttributes[appSettings.wmsCustomer] || {};