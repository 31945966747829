import React, {
    PureComponent
} from 'react';
import { Modal, Button, Row, Col, FormGroup, InputGroup, InputNumber, Panel, ControlLabel, FormControl, DatePicker, Form, InputPicker } from 'rsuite';
import { lettersNumbersDashesInputMask, lettersNumbersDashesSpacesInputMask, numbersInputMask } from '../../../resources/logic/inputMasks';

function limitNumberToExistingValue(e, item) {
    try {
        var limitValue = item['RecoveredPCS'] - item['LoadedPCS']
    }
    catch (e) {
        var limitValue = 0
    }
    if (e.target.value <= limitValue) {
        return e.target.value
    }
    else {
        window.alert('Must not be greater than ' + limitValue.toString())
        return limitValue
    }
}

const styles = {
    // width: 300,
    marginBottom: 10,
    width: '100%'
};

function AddNewHawbModal({
    addNewModalShown,
    handleSave,
    handleClose,
    onChangeExisting,
    resetRows,
    state, ...props

}) {
    return (

        <div className="modal-container">
            <Modal backdrop={'static'} keyboard={false} show={addNewModalShown} onHide={handleClose} onExited={resetRows}>
                <Modal.Header>
                    <Modal.Title>Add Missing House:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Panel bordered style={{ marginBottom: '15px' }}>
                        <Row>
                            <Form fluid>
                                <Col md={24} sm={24} >
                                    <FormGroup style={{ marginBottom: '5px' }}>
                                        <ControlLabel>House Ref</ControlLabel>
                                        <FormControl style={{ width: '100%' }} name="HouseRef" value={state.newModalHouseRef} onChange={(e) => { onChangeExisting(e, 'newModalHouseRef') }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} />
                                    </FormGroup>

                                    <FormGroup style={{ marginBottom: '25px' }}>
                                        <ControlLabel>Consignee Name</ControlLabel>
                                        <InputPicker
                                            preventOverflow
                                            placeholder={'Select...'}
                                            value={state.newModalConsigneeID}
                                            onChange={(e) => { onChangeExisting(e, 'newModalConsigneeID') }}
                                            data={props.HawbConsigneeData}
                                            block
                                            cleanable={true}
                                        />
                                    </FormGroup>

                                    {/* <FormGroup style={{ marginBottom: '25px' }}>
                                        <ControlLabel>Notes</ControlLabel>
                                        <FormControl style={{ width: '100%' }} name="Notes" value={state.newModalNotes} onChange={(e) => { onChangeExisting(e, 'newModalNotes') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                    </FormGroup> */}

                                    <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                        <InputGroup.Addon>Cartons:</InputGroup.Addon>
                                        <InputNumber defaultValue={0} className={'custom-input-number'} onChange={(e) => onChangeExisting(e, 'newModalCartons')} scrollable={false} onInput={(e) => e.target.value = numbersInputMask(e)} />
                                    </InputGroup>

                                    {/* <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                        <InputGroup.Addon>Pallets:</InputGroup.Addon>
                                        <InputNumber defaultValue={0} className={'custom-input-number'} onChange={(e) => onChangeExisting(e, 'newModalNewPallets')} scrollable={false} onInput={(e) => e.target.value = numbersInputMask(e)} />
                                    </InputGroup> */}

                                </Col>
                            </Form>
                        </Row>
                    </Panel>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleSave} appearance="primary">
                        Save
                    </Button>
                    <Button onClick={handleClose} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}


export default AddNewHawbModal;










// import React, {
//     PureComponent
// } from 'react';
// import { Modal, Button, Row, Col, FormGroup, InputGroup, InputNumber, Panel, ControlLabel, FormControl, DatePicker, Form, InputPicker } from 'rsuite';
// import { lettersNumbersDashesInputMask, lettersNumbersDashesSpacesInputMask, numbersInputMask } from '../../../resources/logic/inputMasks';

// function limitNumberToExistingValue(e, item) {
//     try {
//         var limitValue = item['RecoveredPCS'] - item['LoadedPCS']
//     }
//     catch (e) {
//         var limitValue = 0
//     }
//     if (e.target.value <= limitValue) {
//         return e.target.value
//     }
//     else {
//         window.alert('Must not be greater than ' + limitValue.toString())
//         return limitValue
//     }

// }

// const styles = {
//     // width: 300,
//     marginBottom: 10,
//     width: '100%'
// };


// function AddNewHawbModal({
//     addNewModalShown,
//     handleSave,
//     handleClose,
//     onChangeExisting,
//     resetRows,
//     state, ...props


// }) {
//     return (

//         <div className="modal-container">

//             <Modal backdrop={'static'} keyboard={false} show={addNewModalShown} onHide={handleClose} onExited={resetRows}>
//                 <Modal.Header>
//                     <Modal.Title>Add New House: </Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>

//                     <Panel bordered style={{ marginBottom: '15px' }}>
//                         <Row>
//                             {/* <Col md={24} sm={24}> */}
//                             {/* <FormGroup > */}

//                             <Form fluid>

//                                 <Col md={12} sm={24} >

//                                     <FormGroup style={{ marginBottom: '5px' }}>
//                                         <ControlLabel>House Ref</ControlLabel>
//                                         <FormControl style={{ width: '100%' }} name="HouseRef" value={state.newModalHouseRef} onChange={(e) => { onChangeExisting(e, 'newModalHouseRef') }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} />
//                                     </FormGroup>
//                                     <FormGroup style={{ marginBottom: '5px' }}>
//                                         <ControlLabel>Consignee Name</ControlLabel>
//                                         <FormControl style={{ width: '100%' }} name="ConsigneeName" value={state.newModalConsigneeName} onChange={(e) => { onChangeExisting(e, 'newModalConsigneeName') }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} />
//                                     </FormGroup>

//                                     <FormGroup style={{ marginBottom: '5px' }}>
//                                         <ControlLabel>Consignee Name</ControlLabel>

//                                         <InputPicker
    // preventOverflow
//                                             // placeholder={label}
//                                             placeholder={'Select...'}
//                                             value={state.newModalConsigneeName}
//                                             onChange={(e) => { onChangeExisting(e, 'newModalConsigneeName') }}
//                                             // data={data}
//                                             block
//                                             cleanable={true}
                           
//                                         />

//                                     </FormGroup>




//                                     <FormGroup style={{ marginBottom: '5px' }}>
//                                         <ControlLabel>Notes</ControlLabel>
//                                         <FormControl style={{ width: '100%' }} name="Notes" value={state.newModalNotes} onChange={(e) => { onChangeExisting(e, 'newModalNotes') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
//                                     </FormGroup>

//                                 </Col>
//                                 <Col md={12} sm={24} >

//                                     <FormGroup style={{ marginBottom: '5px' }}>
//                                         <ControlLabel>Origin</ControlLabel>
//                                         <FormControl style={{ width: '100%' }} name="Origin" value={state.newModalOrigin} onChange={(e) => { onChangeExisting(e, 'newModalOrigin') }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} />
//                                     </FormGroup>
//                                     <FormGroup style={{ marginBottom: '5px' }}>
//                                         <ControlLabel>HAWB Destination</ControlLabel>
//                                         <FormControl style={{ width: '100%' }} name="HAWBDestination" value={state.newModalHAWBDestination} onChange={(e) => { onChangeExisting(e, 'newModalHAWBDestination') }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} />
//                                     </FormGroup>
//                                     <FormGroup style={{ marginBottom: '5px' }}>
//                                         <ControlLabel>Last Discharge Port</ControlLabel>
//                                         <FormControl style={{ width: '100%' }} name="LastDischargePort" value={state.newModalLastDischargePort} onChange={(e) => { onChangeExisting(e, 'newModalLastDischargePort') }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} />
//                                     </FormGroup>

//                                 </Col>


//                                 <Col md={24} sm={24} >
//                                     <FormGroup style={{ marginBottom: '5px' }}>
//                                         <ControlLabel>Deliver To Address</ControlLabel>
//                                         <FormControl style={{ width: '100%' }} name="DeliverToAddress" value={state.newModalDeliverToAddress} onChange={(e) => { onChangeExisting(e, 'newModalDeliverToAddress') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
//                                     </FormGroup>

//                                     <FormGroup style={{ marginBottom: '25px' }}>
//                                         <ControlLabel>Orders</ControlLabel>
//                                         <FormControl style={{ width: '100%' }} name="Orders" value={state.newModalOrders} onChange={(e) => { onChangeExisting(e, 'newModalOrders') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
//                                     </FormGroup>

//                                 </Col>

//                                 <Col md={12} sm={24} >
//                                     <FormGroup style={{ marginBottom: '5px' }}>
//                                         <ControlLabel>Shipment ETD</ControlLabel>
//                                         <DatePicker oneTap style={{ width: '100%' }} placeholder="Shipment ETD" value={state.newModalShipmentETD} onChange={(e) => { onChangeExisting(e, 'newModalShipmentETD') }} />
//                                     </FormGroup>

//                                     <FormGroup style={{ marginBottom: '25px' }}>
//                                         <ControlLabel>Est Cartage Delivery</ControlLabel>
//                                         <DatePicker oneTap style={{ width: '100%' }} placeholder="Est Cartage Delivery" value={state.newModalEstCartageDelivery} onChange={(e) => { onChangeExisting(e, 'newModalEstCartageDelivery') }} />
//                                     </FormGroup>
//                                 </Col>

//                                 <Col md={12} sm={24} >
//                                     <FormGroup style={{ marginBottom: '5px' }}>
//                                         <ControlLabel>ETA Discharge</ControlLabel>
//                                         <DatePicker oneTap style={{ width: '100%' }} placeholder="ETA Discharge" value={state.newModalETADischarge} onChange={(e) => { onChangeExisting(e, 'newModalETADischarge') }} />
//                                     </FormGroup>

//                                     <FormGroup style={{ marginBottom: '25px' }}>
//                                         <ControlLabel>Actual Cartage Delivery</ControlLabel>
//                                         <DatePicker oneTap style={{ width: '100%' }} placeholder="Actual Cartage Delivery" value={state.newModalActualCartageDelivery} onChange={(e) => { onChangeExisting(e, 'newModalActualCartageDelivery') }} />
//                                     </FormGroup>
//                                 </Col>

//                                 <Col md={12} sm={24} >

//                                     <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
//                                         <InputGroup.Addon>Cartons:</InputGroup.Addon>
//                                         <InputNumber defaultValue={0} className={'custom-input-number'} onChange={(e) => onChangeExisting(e, 'newModalCartons')} scrollable={false} onInput={(e) => e.target.value = numbersInputMask(e)} />
//                                     </InputGroup>

//                                     <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
//                                         <InputGroup.Addon>Pallets:</InputGroup.Addon>
//                                         <InputNumber defaultValue={0} className={'custom-input-number'} onChange={(e) => onChangeExisting(e, 'newModalNewPallets')} scrollable={false} onInput={(e) => e.target.value = numbersInputMask(e)} />
//                                     </InputGroup>
//                                 </Col>
//                                 <Col md={12} sm={24} >

//                                     <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
//                                         <InputGroup.Addon>Weight:</InputGroup.Addon>
//                                         <InputNumber defaultValue={0} className={'custom-input-number'} onChange={(e) => onChangeExisting(e, 'newModalWeight')} scrollable={false} />
//                                     </InputGroup>

//                                     <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
//                                         <InputGroup.Addon>Chargeable:</InputGroup.Addon>
//                                         <InputNumber defaultValue={0} className={'custom-input-number'} onChange={(e) => onChangeExisting(e, 'newModalChargeable')} scrollable={false} />
//                                     </InputGroup>

//                                 </Col>

//                                 <Col md={24} sm={24} >
//                                     <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
//                                         <InputGroup.Addon>Volume:</InputGroup.Addon>
//                                         <InputNumber defaultValue={0} className={'custom-input-number'} onChange={(e) => onChangeExisting(e, 'newModalVolume')} scrollable={false} />
//                                     </InputGroup>
//                                 </Col>







//                             </Form>
//                             {/* </FormGroup> */}
//                             {/* </Col> */}
//                         </Row>
//                     </Panel>

//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button onClick={handleSave} appearance="primary">
//                         Save
//                     </Button>
//                     <Button onClick={handleClose} appearance="subtle">
//                         Cancel
//                     </Button>
//                 </Modal.Footer>
//             </Modal>
//         </div>



//     );
// }


// export default AddNewHawbModal;

